import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateContestHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create Contest</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 4 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Contests” link. This will take you to the Contest Listing Page. <br />
                                    <img src="/img/documentation-img/ContestImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create a contest, click on “Create Contest” in the top right corner of the Contest Listing Page. <br />
                                    <img src="/img/documentation-img/ContestImg/listing_create.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Create Contest Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Contest Code
                                        </li>
                                        <li>
                                            Contest Name
                                        </li>
                                        <li>
                                            Contest Category
                                        </li>
                                        <li>
                                            Contest Start
                                        </li>
                                        <li>
                                            Contest End
                                        </li>
                                        <li>
                                            Display Start
                                        </li>
                                        <li>
                                            Display End
                                        </li>
                                        <li>
                                            Contest Description
                                        </li>
                                        <li>
                                            Contest Terms and Conditions
                                        </li>
                                        <li>
                                            Contest Image
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Contest Q&A Link
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start Date should always be earlier than Display End Date.<br />
                                            - The value in Display Start must precede value in Contest Start.<br />
                                            - The value in Contest End must precede value in Display End.<br />
                                            - Contest Description and Contest Terms and Conditions should be in HTML format.<br />
                                            - Contest Description and Contest Terms and Conditions have a maximum of 12000 characters.
                                </div>
                            </li>
                                        <li class="createList">
                                            To preview the contest, click on “Preview” in the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/ContestImg/create_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/ContestImg/preview_cancelpreview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner. <br />
                                            <img src="/img/documentation-img/ContestImg/create_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm". <br />
                                            <img src="/img/documentation-img/ContestImg/create_cancel_confirm.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create the contest, click on “Create” at the bottom right corner of the page. <br />
                                    <img src="/img/documentation-img/ContestImg/create_create.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To confirm that you would like to create the contest, click on the “Confirm” button.<br />
                                    <img src="/img/documentation-img/ContestImg/create_create_confirm.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Upon successful creation, a confirmation message will appear at the bottom right of the screen. <br />
                                    <img src="/img/documentation-img/ContestImg/create_success_toaster.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    When you return to the contests listing page, you will see the changes made.  <br />
                                    <img src="/img/documentation-img/ContestImg/create_success_endpoint.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/contests/ViewContestHelp" className="btn btn-primary">&laquo; View Contests</a>&nbsp;
                                <a href="/documentation/contests/EditContestHelp" className="btn btn-primary">Edit Contests &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}