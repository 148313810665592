import _ from "lodash";

export class VoucherRequestStatus {
  static Pending = 1;
  static Completed = 2;
  static Rejected = 3;

  static All = [
    {
      name: "Pending",
      value: this.Pending,
    },
    {
      name: "Completed",
      value: this.Completed,
    },
    {
      name: "Rejected",
      value: this.Rejected,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class GroupCode {
  static CB_BRA = "CB-BRA";
}

export class PersonInChargeQuery {
  static Exact = 0;
  static Unassigned = 1;
  static Everyone = 2;
}
