import { useReducer } from "react";

const defaultReducer = (state, action) => {
  return {
    ...state,
    ...action,
  };
};

const useVisibleModal = (props) => {
  const { reducer, defaultState, intReducer, action } = props;
  let initState = defaultState;

  if (action) {
    initState = Object.keys(action).reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: false,
      }),
      {}
    );
  }

  const [visibleState, dispatchVisibleState] = useReducer(
    reducer || defaultReducer,
    initState || {},
    intReducer
  );

  const changeVisibleState = (name, state) => {
    console.log(name, state)
    dispatchVisibleState({ [name]: state });
  };

  return {
    visibleState,
    changeVisibleState,
  };
};

export default useVisibleModal;
