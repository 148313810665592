import React, { useEffect, useState } from "react";
import RestClient from "../../../common/RestClient";
import { DateHelper } from "../../../common/Helpers";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignGlobalRefreshAtom,
  createPerformanceConditionSelector,
  isSelectedPerformanceConditionsAtom,
  locationsAtom,
  moviesAtom,
  validForSpecificPerformanceSelector,
} from "../CampaignState";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { PerformanceConditionDialog } from "../dialogs/PerformanceConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const PerformanceConditionCard = (props) => {
  const [refresh, setRefresh] = useState(0);
  const [data, setData] = useState([]);
  const [performanceConditions, setPerformanceConditions] = useState([]);
  const campaign = useRecoilValue(campaignAtom);
  const [showSingleDeleteConfirmation, setShowSingleDeleteConfirmation] =
    useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const locations = useRecoilValue(locationsAtom);
  const movies = useRecoilValue(moviesAtom);
  const [showTable, setShowTable] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showWarningModal, setShowWaringModal] = useState(false);
  const { isEdit } = props;
  const setSelectedPerformanceConditions = useSetRecoilState(
    isSelectedPerformanceConditionsAtom
  );
  const onCreatePerformanceCondition = useSetRecoilState(
    createPerformanceConditionSelector
  );

  const validForSpecificPerformance = useSetRecoilState(
    validForSpecificPerformanceSelector
  );

  const setGlobalRefresh = useSetRecoilState(campaignGlobalRefreshAtom);

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificPerformance);
    setSelectedPerformanceConditions(campaign.validForSpecificPerformance);
  }, [campaign, setSelectedPerformanceConditions]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/performances",
      { campaignId: campaign.id },
      (response) => {
        setPerformanceConditions(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh]);

  useEffect(() => {
    const results = performanceConditions.map((item) => {
      const location = locations.find((x) => x.id === item.locationId);
      const movie = movies.find((x) => x.id === item.movieId);
      const locationVenue = location?.locationVenues?.find(
        (x) => x.id === item.locationVenueId
      );
      return {
        id: item.id,
        primaryTitle: movie?.primaryTitle,
        locationVenueName: locationVenue?.name,
        locationName: location?.name,
        actualDateTime: item.actualDateTime,
      };
    });
    setData(results);
  }, [performanceConditions, locations, movies]);

  const onCreate = (performanceCondition) => {
    const params = {
      ...performanceCondition,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/performances",
      params,
      (_) => {
        toast.success(
          "The campaign performance condition has been created successful."
        );
        setRefresh((ref) => ref + 1);
        setGlobalRefresh((ref) => ref + 1);
        onCreatePerformanceCondition(false);
        setShowCreateModal(false);
      },
      handleError
    );
  };

  const onSingleDelete = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/performances/${selectedId}`,
      () => {
        toast.success("The performance condition has been deleted successful.");
        setRefresh((ref) => ref + 1);
        validForSpecificPerformance(data.length > 1);
      },
      handleError
    );
    setShowSingleDeleteConfirmation(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_performance_conditions`,
      () => {
        validForSpecificPerformance(false);
        toast.success(
          "All performance conditions have been deleted successful."
        );
        setRefresh((ref) => ref + 1);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label htmlFor="input1" className="col-sm-2 col-form-label">
          Showtime Condition
        </label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="PerformanceCondition"
              value="No"
              checked={!showTable}
              onClick={() => {
                setShowTable(false);
                setSelectedPerformanceConditions(false);
                if (performanceConditions && performanceConditions.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="PerformanceConditionRbNo"
            >
              No performance restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="PerformanceCondition"
              value="Yes"
              checked={showTable}
              onClick={() => {
                setShowTable(true);
                setSelectedPerformanceConditions(true);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="PerformanceConditionRbYes"
            >
              Valid for specific performances
            </label>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowWaringModal(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
          <tr>
            <th>Location</th>
            <th>Location Hall</th>
            <th>Movie Title</th>
            <th>Movie Date</th>
            <th>Movie Time</th>
            <th style={{ width: "10%" }} />
          </tr>
          </thead>
          <tbody>
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.locationName}</td>
                  <td>{item.locationVenueName}</td>
                  <td>{item.primaryTitle}</td>
                  <td>
                    {DateHelper.toDisplayDateFormat(item.actualDateTime)}
                  </td>
                  <td>
                    {DateHelper.toDisplayTimeFormat(item.actualDateTime)}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        setSelectedId(item.id);
                        setShowSingleDeleteConfirmation(true);
                      }}
                      className="btn btn-sm btn-danger"
                      disabled={!isEdit}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showWarningModal}
        onProceed={() => {
          setShowWaringModal(false);
          setShowCreateModal(true);
        }}
        onCancel={() => setShowWaringModal(false)}
        message="Performance condition creation that causes the brand format, movie, location venue, classification, daytime conditions are deleted. Are you sure to perform this action?"
      />
      <ConfirmDialog
        visible={showSingleDeleteConfirmation}
        onProceed={onSingleDelete}
        onCancel={() => setShowSingleDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
      <PerformanceConditionDialog
        visible={showCreateModal}
        onConfirm={onCreate}
        onCancel={() => setShowCreateModal(false)}
      />
    </>
  );
};
