import RestClient from "../common/RestClient";
import { AuthenticationManager } from "../common/AuthenticationManager";
import { toast } from "react-toastify";

export class SurchargeService {
  constructor() {
    this.baseApi = "/api/v1/surcharges";
  }

  get(id, onSuccess, onError) {
    return RestClient.sendGetRequestWithParameters(
      `${this.baseApi}/${id}`,
      { includes: "SurchargePrices" },
      (service) => {
        if (onSuccess) {
          onSuccess(service);
        }
      },
      (createError) => {
        if (onError) {
          onError();
        }
        toast.error(createError.message);
      }
    );
  }
  
  update(id, data, onSuccess, onError) {
    return RestClient.sendPutRequest(
      `${this.baseApi}/${id}`,
      data,
      (service) => {
        if (onSuccess) {
          onSuccess(service);
        }
      },
      (error) => {
        if (onError) {
          onError();
        }
        toast.error(error.message);
      }
    );
  }

  updateContent(id, surcharge, onSuccess) {
    const data = {
      surchargePrices: surcharge.surchargePrices,
    };
    return RestClient.sendPatchRequest(
      `${this.baseApi}/${id}`,
      data,
      (result) => {
        if (onSuccess) {
          onSuccess(result);
        }
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }
}
