import React from "react";
import {
  EnumHelper,
  NumberHelper,
  DateHelper,
} from "../../../../common/Helpers";
import {
  SystemSettingKeys,
  ProgrammeAvailabilitiesEnum,
} from "../../../../common/Constants";
import RestClient from "../../../../common/RestClient";
import "./FilmFestivalPreview.scss";
import { SystemSettings } from "../../../../common/SystemSettings";
export class ProgrammeCardPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cachedReleases: [], program: props.programmes };
  }

  componentDidMount() {
    this.loadMovieReleaseDetails(this.state.program?.movieReleaseId);
  }

  loadMovieReleaseDetails(id) {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/movie_releases/summary`,
      { movieReleaseIds: `${id}` },
      (response) => {
        let { cachedReleases } = this.state;
        response.data.forEach((item) => {
          if (
            cachedReleases.findIndex(
              (evt) => evt.movieReleaseId === item.movieReleaseId
            ) === -1
          ) {
            cachedReleases.push(item);
          }
        });
        this.setState({ cachedReleases });
      }
    );
  }

  render() {
    const { programmes } = this.props;
    const { cachedReleases } = this.state;
    const movieRelease = cachedReleases?.[0];
    const enableInCinema = EnumHelper.hasFlag(
      programmes?.programmeAvailabilities,
      ProgrammeAvailabilitiesEnum.Cinema
    );
    const enableKinoLounge = EnumHelper.hasFlag(
      programmes?.programmeAvailabilities,
      ProgrammeAvailabilitiesEnum.KinoLounge
    );
    return (
      <div className="card p-1 mr-5 card-festival-program">
        <div className="card-body row">
          <div className="col-2" style={{ marginRight: "-54px" }}>
            <img style={{ height: "140px" }} src={movieRelease?.poster} />
          </div>
          <div className="col-6" id="#filmMovies">
            <strong>{movieRelease?.name}</strong>
            <p>
              <span>
                <span className="sprite-legends-rating-pg mr-1 float-left" />
              </span>
              <span className="movie-duration">
                | {NumberHelper.formatRuntime(movieRelease?.duration)}{" "}
              </span>
            </p>
            <div
              className="mt-2 description-card"
              dangerouslySetInnerHTML={{ __html: movieRelease?.plotSummary }}
            />
            <a
              href={`${
                SystemSettings.get(SystemSettingKeys.PublicWebsiteUrl) || ""
              }/movie-details/${movieRelease?.movieReleaseId}`}
              target="_blank"
            >
              (Details)
            </a>
          </div>
          <div className="col">
            <label className="form-control-label pl-0 col-5">
              Sales Start:
            </label>
            <label className="form-check-label pl-0 col-6">
              {DateHelper.formatDateTimeString(programmes?.salesStartTime)}
            </label>
            <label className="form-control-label pl-0 col-5">Sales End:</label>
            <label className="form-check-label pl-0 col-6">
              {DateHelper.formatDateTimeString(programmes?.salesEndTime)}
            </label>
            <div className="row">
              <label className="form-control-label col-5">
                Film Availability:
              </label>
              <ul className="col-5" style={{ paddingLeft: "30px" }}>
                {enableInCinema && <li>Cinema</li>}
                {enableKinoLounge && <li>KinoLounge</li>}
              </ul>
            </div>
            {enableKinoLounge && (
              <React.Fragment>
                <div className="row">
                  <label className="form-control-label col-5">
                    KinoLounge Link:
                  </label>
                  <a
                    className="col-6 short-link"
                    href={programmes?.kinoLoungeUrl}
                    target="_blank"
                  >
                    {programmes?.kinoLoungeUrl}
                  </a>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}
