import React from "react";
import { Link } from "react-router-dom";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import {
  GenericMessages,
  PassStatus,
  ObjectType,
} from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { HistoryDialog } from "../../common/HistoryDialog";
import { StaffDetailCard } from "./cards/StaffDetailCard";
import { ExtendPassDialog } from "./dialogs/ExtendPassDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class PassDetail extends React.Component {
  static DetailPageName = "View Pass";
  static EditPageName = "Edit Pass";

  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      generalInfo: null,
      generalInfoValidations: [],
      pass: null,
      passId: "",
      showCancelDialog: false,
      showConfirmDialog: false,
      showDeactiveDialog: false,
      showExtendDialog: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const passId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || passId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ passId }, this.refreshData);
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadPass(id) {
    RestClient.sendGetRequest(
      `/api/v1/passes/${id}`,
      (response) => {
        this.setState({ pass: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  onEditContestClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const startDate = StringHelper.toDateTimeString(model.startDateStr);
    const expiryDate = StringHelper.toDateTimeString(model.expiryDateStr);
    const passProgramId = model.passProgramId;
    const name = model.name;
    const code = model.code;
    const newModel = Object.assign(model, { startDate, expiryDate, passProgramId, name, code });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  handleSuccess = (message) => {
    toast.success(message);
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHideDeactiveDialog = () => {
    this.setState({ showDeactiveDialog: false });
  };

  onHideExtendDialog = () => {
    this.setState({ showExtendDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      pass: Object.assign({}, this.state.pass),
      showCancelDialog: false,
    });
  };

  onProceedDeactiveDialog = () => {
    const { pass, passId } = this.state;
    const newStatus =
      pass.status === PassStatus.Active
        ? PassStatus.Inactive
        : PassStatus.Active;
    const request = {
      passId,
      status: newStatus,
    };
    RestClient.sendPutRequest(
      `/api/v1/passes/update_status`,
      request,
      (response) => {
        const message =
          response.status === PassStatus.Active
            ? "Pass has been activated successfully."
            : "Pass has been deactivated successfully.";
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          pass: response,
          showDeactiveDialog: false,
        });
        this.handleSuccess(message);
      },
      (error) => {
        this.setState({ showDeactiveDialog: false });
        this.handleError(error);
      }
    );
  };

  onProceedExtendDialog = (e) => {
    const { passId, pass } = this.state;
    const request = {
      expiryDate: e.expiryDate,
      startDate: pass.startDate,
      staffEmail: pass.staffEmail,
    };
    RestClient.sendPutRequest(
      `/api/v1/passes/${passId}`,
      request,
      (response) => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          pass: response,
          showExtendDialog: false,
        });
        this.handleSuccess(GenericMessages.SaveChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({ showExtendDialog: false });
        this.handleError(error);
      }
    );
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { passId, generalInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/passes/${passId}`,
      request,
      (response) => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          pass: response,
          showConfirmDialog: false,
        });
        this.handleSuccess(GenericMessages.SaveChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({ showConfirmDialog: false });
        this.handleError(error);
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowDeactiveDialog = () => {
    this.setState({ showDeactiveDialog: true });
  };

  onShowExtendDialog = () => {
    this.setState({ showExtendDialog: true });
  };

  refreshData() {
    const { passId } = this.state;
    this.loadPass(passId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/passes/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "pass-details",
        name: CardMode.isViewMode(cardMode)
          ? PassDetail.DetailPageName
          : PassDetail.EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      pass,
      passId,
      showCancelDialog,
      showConfirmDialog,
      showDeactiveDialog,
      showExtendDialog,
      showHistoryDialog,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-outline-secondary"
                      data-target="#HistoryPopup"
                      data-toggle="modal"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item>
                      <Link
                        className="btn btn-outline-primary w-100"
                        style={{ fontSize: "1rem" }}
                        to={`/passes/${passId}/usage`}
                      >
                        Usage History
                      </Link>
                    </ButtonActionDetailGroup.Item>
                    {AuthenticationManager.isAuthorized(
                      ResourceID.PassEditButtonPage
                    ) && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-primary"
                        value="Edit"
                        disabled={pass ? undefined : "disabled"}
                        onClick={this.onEditContestClick}
                      >
                        <i className="fas fa-edit"></i> Edit
                      </ButtonActionDetailGroup.Item>
                    )}
                  </ButtonActionDetailGroup>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <StaffDetailCard
                  cardMode={cardMode}
                  defaultValue={pass}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isViewMode(cardMode) && pass && (
              <ButtonActionDetailGroup>
                {AuthenticationManager.isAuthorized(
                  ResourceID.PassExtendButton
                ) &&
                  pass.status !== PassStatus.Inactive && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success"
                      onClick={this.onShowExtendDialog}
                    >
                      Extend
                    </ButtonActionDetailGroup.Item>
                  )}
                {AuthenticationManager.isAuthorized(
                  ResourceID.PassActiveDeactiveButton
                ) &&
                  (pass.status === PassStatus.Active ? (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      onClick={this.onShowDeactiveDialog}
                    >
                      Deactivate
                    </ButtonActionDetailGroup.Item>
                  ) : (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success"
                      onClick={this.onShowDeactiveDialog}
                    >
                      Activate
                    </ButtonActionDetailGroup.Item>
                  ))}
              </ButtonActionDetailGroup>
            )}
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && pass}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && pass}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        <ConfirmDialog
          visible={
            showDeactiveDialog && pass && pass.status === PassStatus.Active
          }
          onCancel={this.onHideDeactiveDialog}
          onProceed={this.onProceedDeactiveDialog}
          message="Are you sure you want to deactivate pass?"
          title="Deactivate pass"
        />
        <ConfirmDialog
          visible={
            showDeactiveDialog && pass && pass.status !== PassStatus.Active
          }
          onCancel={this.onHideDeactiveDialog}
          onProceed={this.onProceedDeactiveDialog}
          message="Are you sure you want to activate pass?"
          title="Activate pass"
        />
        {showExtendDialog && pass && (
          <ExtendPassDialog
            onCancel={this.onHideExtendDialog}
            onProceed={this.onProceedExtendDialog}
            message=""
            title="Extend Pass"
          />
        )}
        {showHistoryDialog && (
          <HistoryDialog
            objectId={passId}
            onClose={() => this.setState({ showHistoryDialog: false })}
            url="/api/v1/history_logs"
            objectType={ObjectType.Pass}
          />
        )}
      </div>
    );
  }
}
