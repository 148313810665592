import React from 'react';
import { StringHelper } from '../../../../common/Helpers';

export const StaffCardPreview = (props) => {
    const { pass, program } = props;
    return (
        <div className="card" id="StaffCard" style={{ borderRadius: '20px' }}>
            <div className="card-body pb-1">
                <div className="d-flex flex-row justify-content-center">
                    <img src="/icons/Shaw.png" style={{ height: '27px' }} />
                    <h2 className="text-uppercase" style={{ fontSize: '21px' }}>Shaw Organization</h2>
                </div>
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-8" style={{ lineHeight: '1' }}>
                        <label className="form-control-label row m-0 Name" style={{ fontSize: '13px' }}>{pass.name}</label>
                        <label className="form-control-label m-0 row" style={{ fontSize: '13px' }}>Shaw Theatres Pte Ltd</label>
                        <label className="form-control-label m-0 row" style={{ fontSize: '13px' }}>{program.name}</label>
                        <label id="passcode" className="form-control-label row mt-1 m-0 PassCode" style={{ fontSize: '13px' }}>{pass.code}</label>
                        <label className="form-control-label my-0 float-right ExpiryDate" style={{ fontSize: '12px' }}>{StringHelper.asDateStringToMembershipCardDateFormat(pass.expiryDateStr)}</label><label className="form-control-label my-0 float-right mr-1" style={{ fontSize: '12px' }}>EXP</label>
                    </div>
                    <div className="col-4">
                        <img className="float-right" src="/icons/formal_prof.jpg" style={{ maxHeight: '69px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}
