import { RestService } from "./RestService";

export class BlobStorageRestService extends RestService {
  constructor() {
    super("/api/v1/blob_storages");
  }

  async uploadFile(base64Image, fileName, storageModule) {
    return await this.postImpl('upload', { base64Image, fileName, storageModule });
  }

  async deleteFile(fileName, storageModule) {
    return await this.postImpl('delete', { fileName, storageModule });
  }
}