import React from "react";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { RowFormField } from "../../../layout/FormLayout";
import { CheckboxInput } from "../../../campaigns/CheckboxInput";
import { FieldWrapper } from "../../../campaigns/FieldWrapper";
import _ from "lodash";

export class FilmFestivalDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      code: value.code || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      name: value.name || "",
      planToPublishStr: value.planToPublish || "",
      poster: value.poster || "",
      displayWeight: value.displayWeight || 0,
      isActive: value.isActive || false,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let value = e.target.value;

    if (_.isEqual("boolean", valueType)) {
      value = e.target.checked;
    }

    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  onKeyPressDisplayWeight(event) {
    if (event.key === "." || event.key === "e") {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
      if (model.departmentId) {
        this.getDesignationOptions(model.departmentId);
      }
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    return (
      <Card title="Film Festival Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const { cardMode } = this.state;
    const {
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      code,
      planToPublishStr,
      poster,
      displayWeight,
      isActive,
    } = this.state.model;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="FestivalTitle">
                  Film Festival Title:
                </label>
                <input
                  className="form-control display-input"
                  id="FestivalTitle"
                  value={name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="StartDate">
                Starts On:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDate"
                  type="datetime-local"
                  value={displayStartTimeStr}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="EndDate">
                Ends On:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="EndDate"
                  type="datetime-local"
                  value={displayEndTimeStr}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row ">
              <label className="col form-control-label" htmlFor="Weight">
                Display Weight:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Weight"
                  type="number"
                  placeholder="Enter Display Weight"
                  min="1"
                  max="125"
                  value={displayWeight}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <label className="col form-control-label" htmlFor="Desc">
                Film Festival Description:
              </label>

              <div className="col-11">
                <textarea
                  className="form-control m-r-15 resize-none display-input"
                  maxLength="12000"
                  rows="2"
                  id="Desc"
                  placeholder="Description"
                  readOnly={true}
                  value={description}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <img
                src={poster}
                style={{ maxHeight: "230px" }}
                id="img"
                className="float-right m-l-15"
                alt=""
              />
            </div>
            <div className="row form-group">
              <div className="col">
                <label className="form-control-label" htmlFor="ImgUrl">
                  Festival Image:
                </label>
                <textarea
                  className="form-control display-input resize-none"
                  rows="3"
                  readOnly={true}
                  value={poster}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col">
                <label className="form-control-label" htmlFor="Code">
                  Film Festival Code:
                </label>
                <input
                  className="form-control display-input"
                  id="Code"
                  type="text"
                  placeholder="Enter Code"
                  min="1"
                  max="100"
                  value={code}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col">
                <label className="form-control-label" htmlFor="Code">
                  Is Active:
                </label>
                <p style={{ color: "black" }}>{isActive ? "Yes" : "No"}</p>
              </div>
            </div>
            {CardMode.isScheduleMode(cardMode) && (
              <div className="row form-group">
                <label
                  className="col form-control-label"
                  htmlFor="PlanToPublish"
                >
                  Plan to Publish On:
                </label>
                <div className="col-11">
                  <input
                    className="form-control display-input"
                    id="PlanToPublish"
                    type="datetime-local"
                    value={planToPublishStr}
                    readOnly={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { cardMode } = this.state;
    const {
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      code,
      planToPublishStr,
      poster,
      displayWeight,
      isActive,
    } = this.state.model;
    return (
      <div className="details row">
        <div className="col">
          <RowFormField
            label={
              <>
                Film Festival Title:<span className="color-red">*</span>
              </>
            }
            htmlFor="FestivalTitle"
          >
            <input
              id="FestivalTitle"
              placeholder="Enter Film Festival Title"
              className={this.getControlClassName("name")}
              name="name"
              onChange={this.onInputChange}
              value={name}
              readOnly={false}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="name"
              isEnabled={isSubmit}
              property="Film Festival Title"
              value={name}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Start DateTime:<span className="color-red">*</span>
              </>
            }
            htmlFor="StartDate"
          >
            <input
              id="StartDate"
              type="datetime-local"
              className={this.getControlClassName("displayStartTimeStr")}
              name="displayStartTimeStr"
              onChange={this.onInputChange}
              value={displayStartTimeStr}
              readOnly={false}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayStartTimeStr"
              isEnabled={isSubmit}
              property="Display Start DateTime"
              value={displayStartTimeStr}
            />
          </RowFormField>
          <RowFormField label="Display End DateTime:" htmlFor="EndDate">
            <input
              id="EndDate"
              type="datetime-local"
              className={this.getControlClassName("displayEndTimeStr")}
              name="displayEndTimeStr"
              onChange={this.onInputChange}
              value={displayEndTimeStr}
              readOnly={false}
            />
            <Validators.CompareDateValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndTimeStrRange"
              isEnabled={isSubmit}
              startDate={displayStartTimeStr}
              endDate={displayEndTimeStr}
              property="Display End Time"
              value={displayEndTimeStr}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Weight:<span className="color-red">*</span>
              </>
            }
            htmlFor="Weight"
          >
            <input
              id="Weight"
              type="number"
              placeholder="Enter Display Weight"
              min="1"
              max="125"
              className={this.getControlClassName("displayWeight")}
              name="displayWeight"
              onChange={this.onInputChange}
              onKeyDown={this.onKeyPressDisplayWeight}
              value={displayWeight}
              readOnly={false}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayWeight"
              isEnabled={isSubmit}
              min="1"
              max="125"
              property="Display Weight"
              value={displayWeight}
              allowDecimal={true}
            />
          </RowFormField>
          {CardMode.isScheduleMode(cardMode) && (
            <RowFormField label="Plan to Publish On:">
              <input
                id="PlanToPublish"
                type="datetime-local"
                className={this.getControlClassName("planToPublishStr")}
                name="planToPublishStr"
                onChange={this.onInputChange}
                value={planToPublishStr}
                readOnly={false}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="planToPublishStr"
                isEnabled={isSubmit}
                property="Plan to Publish On"
                value={planToPublishStr}
              />
            </RowFormField>
          )}
          <RowFormField label="Film Festival Image:" htmlFor="ImgUrl">
            <textarea
              rows="2"
              id="ImgUrl"
              placeholder="Enter Film Festival Image Link"
              className={this.getControlClassName("poster")}
              name="poster"
              onChange={this.onInputChange}
              value={poster}
              readOnly={false}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Film Festival Code:<span className="color-red">*</span>
              </>
            }
            htmlFor="FestivalCode"
          >
            <input
              id="FestivalCode"
              placeholder="Enter Film Festival Code"
              className={this.getControlClassName("code")}
              name="code"
              onChange={this.onInputChange}
              value={code}
              readOnly={false}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="code"
              isEnabled={isSubmit}
              property="Film Festival Code"
              value={code}
            />
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="code"
              isEnabled={isSubmit}
              property="Film Festival Code"
              value={code}
              maxLength="12"
            />
          </RowFormField>
        </div>
        <div className="col">
          <div className="row m-0">
            <label className="form-control-label" htmlFor="Desc">
              Film Festival Description:
            </label>
            <textarea
              maxLength="8000"
              rows="10"
              id="Desc"
              placeholder="Description"
              className={this.getControlClassName("description")}
              name="description"
              onChange={this.onInputChange}
              value={description}
              readOnly={false}
            />
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="description"
              isEnabled={isSubmit}
              maxLength="12000"
              property="Description"
              value={description}
            />
            <label className="form-control-label ml-auto m-r-15" htmlFor="Desc">
              <small>
                <span id="DescCount">{description.length}</span>/8000
              </small>
            </label>
          </div>
          <FieldWrapper
            label="Is Active"
            input={
              <CheckboxInput
                name="isActive"
                value={isActive}
                onChange={this.onInputChange}
                checked={isActive}
              />
            }
          />
        </div>
      </div>
    );
  }
}
