import React from "react";
import { Availability, ProductCategory } from "../Constants";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { GeneralInformationCard } from "../products/cards/GeneralInformationCard";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { ApplicationID, DefaultSelection } from "../../../common/Constants";
import { CardMode } from "../CardMode";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ProductService } from "../../../services/ProductComboService";

export class ProductMenuCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      locationId: DefaultSelection.NotSelect,
      locationName: "",
      applicationId: DefaultSelection.NotSelect,
      applicationName: "",
      categoryOptions: [],
      products: [],
      selectedProductId: DefaultSelection.NotSelect,
      selectedProduct: {},
      isShowDetailInfo: false,
      cardMode: CardMode.ProductMenuView,
      selectedAvailability: Availability.Available,
      model: {
        locationId: DefaultSelection.NotSelect,
        appId: DefaultSelection.NotSelect,
        productId: DefaultSelection.NotSelect,
        availability: DefaultSelection.NotSelect,
      },
      showConfirmDialog: false,
      showCancelDialog: false,
      showClearDialog: false,
      selectedCategory: DefaultSelection.NotSelect,
    };
  }
  componentDidMount = () => {
    const locationId = this.props.match.params.location;
    const appId = this.props.match.params.app;
    this.setState({ locationId: locationId, appId: appId });
    this.getLocationById(locationId);
    this.getAppById(appId);
    this.getProductCategories();
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "products_menu",
        name: "Products Menu",
      }),
      activeModule: new NavigationItem({
        identifier: "products",
        name: "Creation",
      }),
    });
  };

  getLocationById = (id) => {
    const url = `api/v1/locations/${id}`;
    RestClient.sendGetRequest(
      url,
      (response) => {
        this.setState({ locationName: response.name });
      },
      (error) => {
        console.error(error);
        toast.error(error.message);
      }
    );
  };

  getAppById = (id) => {
    this.setState({ applicationName: ApplicationID.getName(+id) });
  };

  getProductCategories = () => {
    this.setState({ categoryOptions: ProductCategory.All });
  };

  getProductByCategoryId = (categoryId) => {
    const request = { category: categoryId };
    RestClient.sendGetRequestWithParameters(
      "/api/v1/products",
      request,
      (response) => {
        this.setState({ products: response.data });
      },
      (error) => {
        console.error(error);
        toast.error(error.message);
      }
    );
  };

  onCategoryChange = (e) => {
    const value = parseInt(e.target.value);
    this.setState({
      selectedCategory: value,
      selectedProductId: 0,
      isShowDetailInfo: false,
    });
    this.getProductByCategoryId(value);
  };

  onProductChange = (e) => {
    const value = parseInt(e.target.value);
    this.setState({ selectedProductId: value });
  };

  onRetrieve = async () => {
    const selectedProduct = this.state.products.find(
      (x) => x.id === this.state.selectedProductId
    );
    const { selectedCategory } = this.state;
    if (selectedProduct && selectedCategory === ProductCategory.Combos) {
      selectedProduct.productCombos = await this.getProductComboContent(selectedProduct.id);
    }
    this.setState({ selectedProduct: selectedProduct, isShowDetailInfo: true });
  };

  async getProductComboContent(productId) {
    const { data } = await new ProductService().getProductCombos(productId);
    return data;
  }

  onChangeAvailabitity = (model) => {
    const availability = model;
    this.setState({ selectedAvailability: availability });
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    this.setState({ showConfirmDialog: true });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProcessCancelDialog = () => {
    this.props.history.push("/product_menus");
  }

  onHideClearDialog = () => {
    this.setState({ showClearDialog: false });
  }

  onShowClearDialog = () => {
    this.setState({ showClearDialog: true });
  };

  onProcessClearDialog = () => {
    this.setState({ selectedProduct: {}, isShowDetailInfo: false, showClearDialog: false });
  }

  onProceedConfirmDialog = () => {
    const model = {
      productId: this.state.selectedProductId,
      locationId: this.state.locationId,
      appId: parseInt(this.state.appId),
      availability: this.state.selectedAvailability,
    };
    const request = Object.assign(
      { },
      model
    );
    RestClient.sendPostRequest(
      "api/v1/product_menus",
      request,
      () => {
        this.setState({ showConfirmDialog: false });
        toast.success("The product menu has been created successful.");
        this.props.history.push("/product_menus");
      },
      (error) => {
        console.error(error);
        toast.error(error.message);
      }
    );
  };

  render() {
    const {
      locationName,
      applicationName,
      categoryOptions,
      products,
      selectedProductId,
      isShowDetailInfo,
      cardMode,
      selectedProduct,
      showConfirmDialog,
      showCancelDialog,
      selectedCategory,
      showClearDialog
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="card">
                <div className="card-body row">
                  <div className="col-md-5">
                    <div className="row form-group">
                      <label className="col-4 form-control-label">
                        Location:
                      </label>
                      <div className="col-7 pl-0">
                        <input
                          className="form-control display-input"
                          id="locationName"
                          type="text"
                          value={locationName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-4 form-control-label">
                        Category:
                      </label>
                      <div className="col-5 pl-0">
                        <select
                          className="form-control"
                          value={selectedCategory}
                          onChange={this.onCategoryChange}
                        >
                          <option value={0} disabled>
                            Select a category
                          </option>
                          {categoryOptions &&
                            categoryOptions.map((item, index) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="row form-group">
                      <label className="col-4 form-control-label">
                        Sales Channel:
                      </label>
                      <div className="col-5 pl-0">
                        <input
                          className="form-control display-input"
                          id="ProductCode"
                          type="text"
                          value={applicationName}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-4 form-control-label">
                        Product:
                      </label>
                      <div className="col-5 pl-0">
                        <select
                          className="form-control"
                          value={selectedProductId}
                          onChange={this.onProductChange}
                        >
                          <option value={0} disabled>
                            Select a product
                          </option>
                          {products &&
                            products.map((item, index) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <button
                        type="button"
                        disabled={selectedProductId === 0}
                        className="btn btn-secondary col-3"
                        onClick={this.onRetrieve}
                      >
                        Retrieve
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {isShowDetailInfo && (
                <div>
                  <div>
                    <GeneralInformationCard
                      cardMode={cardMode}
                      model={selectedProduct}
                      defaultValue={selectedProduct}
                      onChangeAvailability={this.onChangeAvailabitity}
                    />
                  </div>
                  <div>
                    <div className="d-flex flex-row justify-content-end pb-3">
                      <button
                        className="btn btn-success mr-1"
                        onClick={this.onShowConfirmDialog}
                      >
                        Add Product
                      </button>
                      <button
                        className="btn btn-danger mr-1"
                        onClick={this.onShowClearDialog}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={this.onShowCancelDialog}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <ConfirmDialog
            visible={showCancelDialog}
            onCancel={this.onHideCancelDialog}
            onProceed={this.onProcessCancelDialog}
            message="Are you sure you want to cancel the edit of product?"
            title="Cancel Product edit"
          />
          <ConfirmDialog
            visible={showClearDialog}
            onCancel={this.onHideClearDialog}
            onProceed={this.onProcessClearDialog}
            message="Are you sure you want to clear the edit of product?"
            title="Clear Product edit"
          />
          <ConfirmDialog
            visible={showConfirmDialog}
            onCancel={this.onHideConfirmDialog}
            onProceed={this.onProceedConfirmDialog}
            message="Are you sure you want to edit product?"
            title="Add product"
          />
        </div>
      </>
    );
  }
}
