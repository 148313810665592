import React, { useState } from "react";
import { Card } from "../../common/Card";
import { LocationSpecificCard } from "./cards/LocationSpecificCard";
import { ApplicationSpecificCard } from "./cards/ApplicationSpecificCard";
import { ApplicationLocationSpecificCard } from "./cards/ApplicationLocationSpecificCard";
import { useRecoilState, useRecoilValue } from "recoil";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  CampaignActivePanel,
  campaignActivePanelAtom,
  campaignAtom,
  campaignExtensionPropertiesSelector,
  campaignRedemptionLocationsSelector,
  campaignSalesChannelsSelector,
  locationsAtom,
} from "../CampaignState";
import { BundleSalesChannels } from "../bundles/CampaignBundleItem";
import { ApplicationID } from "../../../common/Constants";
import {
  CumulativeQuantityType,
  Others,
  SpecialDays,
  SpecialShows,
} from "../../../constants/CampaignConstants";
import _ from "lodash";
import { NumberInput } from "../NumberInput";
import { SharedQuantityCard } from "./cards/SharedQuantityCard";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const specialDayMapping = {
  [SpecialDays.PublicHolidays]: "validOnPublicHoliday",
  [SpecialDays.EveofPublicHolidays]: "validOnPublicHolidayEve",
  [SpecialDays.BlackoutHolidays]: "validOnBlackoutHoliday",
  [SpecialDays.SubstituteHolidays]: "validOnSubstituteHoliday",
};

const specialShowMapping = {
  [SpecialShows.OpeningShows]: "validForOpeningShows",
  [SpecialShows.MidnightShows]: "validForMidnightShows",
};

const otherMapping = {
  [Others.CrossSales]: "validForCrossSale",
  [Others.AdvanceSales]: "validForAdvanceSales"
};

export const CampaignAvailabilityCard = () => {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showSaveChangeDialog, setShowSaveChangeDialog] = useState(false);
  const [activePanel, setActivePanel] = useRecoilState(campaignActivePanelAtom);

  const campaign = useRecoilValue(campaignAtom);
  const locations = useRecoilValue(locationsAtom);
  const [campaignExtensionProperties, setCampaignExtensionProperties] =
    useRecoilState(campaignExtensionPropertiesSelector);
  const [salesChannels, setSalesChannels] = useRecoilState(
    campaignSalesChannelsSelector
  );
  const [redemptionLocations, setRedemptionLocations] = useRecoilState(
    campaignRedemptionLocationsSelector
  );

  const onSelectSalesChannel = (e) => {
    let channels = _.clone(salesChannels);
    if (e.target.checked) {
      channels.push(+e.target.value);
    } else {
      channels = channels.filter((x) => x !== +e.target.value);
    }
    setSalesChannels(channels);
  };

  const onSelectLocation = (e) => {
    let clonedLocations = _.clone(redemptionLocations);
    if (e.target.checked) {
      clonedLocations.push(+e.target.value);
    } else {
      clonedLocations = clonedLocations.filter((x) => x !== +e.target.value);
    }
    setRedemptionLocations(clonedLocations);
  };

  const onCampaignExtensionPropertiesChange = (e) => {
    const valueType = e.target.getAttribute("valuetype");
    const fieldName = e.target.getAttribute("name");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }
    setCampaignExtensionProperties((instance) => {
      return {
        ...instance,
        [fieldName]: selectValue,
      };
    });
  };

  const onSave = () => {
    if (campaignExtensionProperties.quantityLimitType === 0) {
      toast.error("Quantity limit type is required.");
      setShowSaveChangeDialog(false);
      return;
    }
    RestClient.sendPutRequest(
      `/api/v1/campaigns/${campaign.id}`,
      {
        ...campaignExtensionProperties,
      },
      () => {
        RestClient.sendPostRequest(
          `/api/v1/campaign_availability`,
          {
            campaignId: campaign.id,
            locationIds: redemptionLocations,
            appIds: salesChannels,
            quantityItems: campaign.campaignQuantityAvailability,
          },
          () => {
            setActivePanel("");
            toast.success(
              "The campaign availability has been updated successful."
            );
          },
          (error) => {
            toast.error(error.message);
          }
        );
      },
      (error) => {
        toast.error(error.message);
      }
    );
    setShowSaveChangeDialog(false);
  };

  const renderSpecialDays = () => {
    return (
      <div className="form-group row ">
        <div className="col-md-3">
          <label className="form-control-label">Special Days:</label>
        </div>
        <div className="col-md-9">
          <div className="row">
            {SpecialDays.All.map((x, index) => {
              const fieldName = specialDayMapping[x.value];
              return (
                <div className="col-md-6" key={index}>
                  <input
                    className="mr-1"
                    valuetype="boolean"
                    type="checkbox"
                    name={fieldName}
                    checked={campaignExtensionProperties[fieldName]}
                    onChange={onCampaignExtensionPropertiesChange}
                    disabled={activePanel !== CampaignActivePanel.Availability}
                  />
                  <label className="form-check-label d-inline">
                    Applicable on {x.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderSpecialShow = () => {
    return (
      <div className="form-group row ">
        <div className="col-md-3">
          <label className="col-form-label">Special Shows</label>
        </div>

        <div className="col-md-9">
          <div className="row">
            {SpecialShows.All.map((x, index) => {
              const fieldName = specialShowMapping[x.value];
              return (
                <div className="col-md-6" key={index}>
                  <input
                    className="mr-1"
                    valuetype="boolean"
                    type="checkbox"
                    name={fieldName}
                    checked={campaignExtensionProperties[fieldName]}
                    onChange={onCampaignExtensionPropertiesChange}
                    disabled={activePanel !== CampaignActivePanel.Availability}
                  />
                  <label className="form-check-label d-inline">
                    Applicable on {x.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderCumulativeQuantity = () => {
    return (
      <div className="row form-group">
        <div className="col-md-3">
          <label className="form-control-label">
            Cumulative Quantity:
            <span className="color-red font-weight-bold">*</span>
          </label>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-4">
              <div className="">
                <input
                  className="mr-1"
                  type="radio"
                  value={CumulativeQuantityType.Limited}
                  name="cumulativeQuantityType"
                  onChange={() => {
                    setCampaignExtensionProperties((instance) => {
                      return {
                        ...instance,
                        cumulativeQuantityLimit: 1,
                        isUnlimited: false,
                      };
                    });
                  }}
                  checked={!campaign.isUnlimited}
                  disabled={activePanel !== CampaignActivePanel.Availability}
                />
                <label className="form-check-label">Limited</label>
              </div>
              <div className="">
                <input
                  className="mr-1"
                  type="radio"
                  value={CumulativeQuantityType.Unlimited}
                  name="cumulativeQuantityType"
                  onChange={() => {
                    setCampaignExtensionProperties((instance) => {
                      return {
                        ...instance,
                        cumulativeQuantityLimit: 0,
                        isUnlimited: true,
                      };
                    });
                  }}
                  checked={campaign.isUnlimited}
                  disabled={activePanel !== CampaignActivePanel.Availability}
                />
                <label className="form-check-label">Unlimited</label>
              </div>
            </div>
            {!campaign.isUnlimited && (
              <div className="col-md-8">
                <div className="row form-group">
                  <div className="col-md-4">
                    <label className="form-control-label">
                      Global Quantity
                    </label>
                  </div>
                  <div className="col-md-8">
                    <NumberInput
                      name="cumulativeQuantityLimit"
                      value={
                        campaignExtensionProperties.cumulativeQuantityLimit
                      }
                      onChange={onCampaignExtensionPropertiesChange}
                      min={0}
                      disabled={
                        activePanel !== CampaignActivePanel.Availability
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderQuantityMechanic = () => {
    const isDisabled = activePanel !== CampaignActivePanel.Availability;
    return (
      <div className="row form-group">
        <div className="col-md-3">
          <label className="form-control-label">
            Quantity Mechanics:
            <span className="color-red font-weight-bold">*</span>
          </label>
        </div>
        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">
              <SharedQuantityCard disabled={isDisabled} />
              <LocationSpecificCard disabled={isDisabled} />
              <ApplicationSpecificCard disabled={isDisabled} />
              <ApplicationLocationSpecificCard disabled={isDisabled} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRedemptionLocation = () => {
    return (
      <div className="row form-group">
        <div className="col-md-3">
          <label className="form-control-label">
            Redemption Location:
            <span className="color-red font-weight-bold">*</span>
          </label>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setRedemptionLocations(locations.map((x) => x.id))}
            disabled={activePanel !== CampaignActivePanel.Availability}
          >
            Select all
          </button>
        </div>
        <div className="col-md-9">
          <div className="row">
            {locations.map((x, index) => {
              return (
                <div className="col-md-6" key={index}>
                  <input
                    className="mr-1"
                    valuetype="boolean"
                    type="checkbox"
                    value={x.id}
                    onChange={onSelectLocation}
                    checked={redemptionLocations.includes(x.id)}
                    disabled={activePanel !== CampaignActivePanel.Availability}
                  />
                  <label className="form-check-label">{x.name}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderSalesChannels = () => {
    return (
      <div className="row form-group">
        <div className="col-md-3">
          <label className="form-control-label">
            Sales Channels:<span className="color-red font-weight-bold">*</span>
          </label>
          <button
            type="button"
            className="btn btn-link"
            onClick={() => setSalesChannels(BundleSalesChannels.map((x) => x))}
            disabled={activePanel !== CampaignActivePanel.Availability}
          >
            Select all
          </button>
        </div>
        <div className="col-md-9">
          <div className="row">
            {ApplicationID.All.filter(
              (x) => BundleSalesChannels.indexOf(x.value) >= 0
            ).map((x, index) => {
              return (
                <div className="col-sm-3" key={index}>
                  <div key={index}>
                    <input
                      className="mr-1"
                      valuetype="boolean"
                      type="checkbox"
                      value={x.value}
                      onChange={onSelectSalesChannel}
                      checked={salesChannels.includes(x.value)}
                      disabled={
                        activePanel !== CampaignActivePanel.Availability
                      }
                    />
                    <label className="form-check-label">{x.name}</label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderOthers = () => {
    return (
      <div className="form-group row ">
        <div className="col-md-3">
          <label className="col-form-label">Others</label>
        </div>
        <div className="col-md-9">
          <div className="row">
            {Others.All.map((x, index) => {
              const fieldName = otherMapping[x.value];
              return (
                <div className="col-md-6" key={index}>
                  <input
                    className="mr-1"
                    valuetype="boolean"
                    type="checkbox"
                    name={fieldName}
                    checked={campaignExtensionProperties[fieldName]}
                    onChange={onCampaignExtensionPropertiesChange}
                    disabled={activePanel !== CampaignActivePanel.Availability}
                  />
                  <label className="form-check-label">
                    Applicable on {x.name}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card
      title="Campaign Availability"
      isActive={activePanel === CampaignActivePanel.Availability}
    >
      <ButtonActionDetailGroup>
        {activePanel !== CampaignActivePanel.Availability &&
          AuthenticationManager.isAuthorized(
            ResourceID.CampaignsViewPageEditFeatures
          ) && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => setActivePanel(CampaignActivePanel.Availability)}
              disabled={!_.isEmpty(activePanel)}
              title={
                !_.isEmpty(activePanel) &&
                activePanel !== CampaignActivePanel.Availability
                  ? `The panel ${activePanel} is being edit. Please complete the current editing panel first.`
                  : ""
              }
            >
              <i className="fas fa-edit" /> Edit
            </ButtonActionDetailGroup.Item>
          )}
      </ButtonActionDetailGroup>
      <div className="card card-body">
        {renderSalesChannels()}
        {renderRedemptionLocation()}
        {renderCumulativeQuantity()}
        {renderQuantityMechanic()}
      </div>
      {renderSpecialDays()}
      {renderSpecialShow()}
      {renderOthers()}
      {activePanel === CampaignActivePanel.Availability && (
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-success edit"
            onClick={() => setShowSaveChangeDialog(true)}
          >
            Save Changes
          </ButtonActionDetailGroup.Item>
          <ButtonActionDetailGroup.Item
            className="btn btn-danger edit"
            onClick={() => {
              setShowCancelDialog(true);
              setActivePanel("");
            }}
          >
            Cancel
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      )}
      <ConfirmDialog
        message="Are you sure you want to save these changes?"
        visible={showSaveChangeDialog}
        onProceed={onSave}
        onCancel={() => setShowSaveChangeDialog(false)}
        title="Confirm Saved Changes"
      />
      <ConfirmDialog
        message="Are you sure you want to cancel these changes?"
        visible={showCancelDialog}
        onCancel={() => setShowCancelDialog(false)}
        onProceed={() => {
          setActivePanel("");
          setShowCancelDialog(false);
        }}
        title="Cancel Saved Changes"
      />
    </Card>
  );
};
