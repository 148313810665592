import React from "react";
import { NumberHelper } from "../../../../common/Helpers";
import { MovieFormat, MovieBrand } from "../../../../constants/MovieConstants";
import { Card } from "../../../common/Card";
import { SystemSettings } from "../../../../common/SystemSettings";
import { SystemSettingKeys } from "../../../../common/Constants";

export const PromotionMovieReleaseListing = ({ listMovieReleases }) => {
  return (
    <>
      <Card title="Movies">
        <div className="row">
          <div className="col" id="filmMovies">
            {listMovieReleases.map((movie, key) => (
              <div className="card" key={key} id={key}>
                <div className="card-body row">
                  <div className="col-12 col-md-3 col-xl-2 mb-3 mb-md-0" style={{ marginRight: '-54px' }}>
                    <img style={{ height: '140px' }} src={movie.posterUrl} />
                  </div>
                  <div className="col-12 col-md-9 col-xl-6" id="#filmMovies">
                    <strong>{movie.primaryTitle}</strong>
                    <p>
                      <span>
                        <span className="sprite-legends-rating-pg mr-1 float-left" />
                      </span>
                      <span className="movie-duration">
                        | {NumberHelper.formatRuntime(movie.duration)}{" "}
                      </span>
                    </p>
                    <div
                      className="mt-2"
                      dangerouslySetInnerHTML={{ __html: movie.shortSynopsis }}
                    />
                    <a
                      href={`${
                        SystemSettings.get(
                          SystemSettingKeys.PublicWebsiteUrl
                        ) || ""
                      }/movie-details/${movie.id}`}
                      target="_blank"
                    >
                      (Details)
                    </a>
                  </div>
                  <div className="col">
                    <label className="form-control-label pl-0 col-5">
                      Brand:
                    </label>
                    <label className="form-check-label pl-0 col-6">
                      {MovieBrand.getName(movie.brand)}
                    </label>
                    <label className="form-control-label pl-0 col-5">
                      Format:
                    </label>
                    <label className="form-check-label pl-0 col-6">
                      {MovieFormat.getName(movie.format)}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
    </>
  );
};
