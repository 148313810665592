import React from "react";
import { Card } from "../../../common/Card";
import RestClient from "../../../../common/RestClient";
import { DateHelper } from "../../../../common/Helpers";
import { toast } from "react-toastify";

export default class ProgramInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programInformation: null,
    };
  }

  componentDidMount() {
    const { evoucherProgramId } = this.props;
    RestClient.sendGetRequest(
      `/api/v1/evoucher_programs/${evoucherProgramId}`,
      (response) => {
        this.setState({ programInformation: response });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  renderViewModeFormControl(label, value) {
    return (
      <div className="col-md-6">
        <label className="col-12 form-control-label">{label}:</label>
        <div className="col-12 color-black">
          <span>{value}</span>
        </div>
      </div>
    );
  }

  renderViewMode() {
    const { programInformation } = this.state;
    return (
      <div className="details row">
        <div className="col-md-9">
          <div className="row form-group">
            {this.renderViewModeFormControl("Id", programInformation.id)}
            {this.renderViewModeFormControl("Name", programInformation.name)}
          </div>
          <div className="row form-group">
            {this.renderViewModeFormControl(
              "Short Name",
              programInformation.shortName
            )}
            {this.renderViewModeFormControl(
              "Prefix",
              programInformation.prefix
            )}
          </div>
          <div className="row form-group">
            {this.renderViewModeFormControl(
              "Program Start",
              DateHelper.toDisplayDateTimeFormat(
                programInformation.programStart
              )
            )}
            {this.renderViewModeFormControl(
              "Program End",
              DateHelper.toDisplayDateTimeFormat(programInformation.programEnd)
            )}
          </div>
          <div className="row form-group">
            {this.renderViewModeFormControl(
              "Description",
              programInformation.description
            )}
            {this.renderViewModeFormControl(
              "Term Condition",
              programInformation.termsAndCondition
            )}
          </div>
          <div className="row form-group">
            {this.renderViewModeFormControl(
              "Label Display",
              programInformation.labelDisplay
            )}
            {this.renderViewModeFormControl(
              "Label Print",
              programInformation.labelPrint
            )}
          </div>
        </div>
        <div className="col-md-3">
          <div className="row form-group">
            <img
              id="image"
              className="img-thumbnail mx-auto p-2"
              src={programInformation.imageUrl}
              alt="eVoucher"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { programInformation } = this.state;
    return (
      <>
        {programInformation && (
          <Card title="Program Information">{this.renderViewMode()}</Card>
        )}
      </>
    );
  }
}
