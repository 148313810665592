import React from "react";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { DateHelper } from "../../../common/Helpers";
import { PerformanceSelectorsType } from "../../../constants/PerformanceSelectorsConstants";
import { getModalInstant, isEmpty } from "../../../common/Common";
import { LocationService } from "../../../services/LocationService";
import PerformanceSelectorsRestrictionModal from "./PerformanceSelectorsRestrictionModal";
import { RowFormField } from "../../layout/FormLayout";
import { TicketingApplication } from "../../../constants/PerformanceConstants";
import { CustomSelect } from "../../common/CustomSelect";

class PerformanceSelectorListing extends BaseListing {
  constructor(props) {
    super(props);
    this.restrictionModal = null;
  }

  getInitState() {
    const baseState = super.getInitState();
    return {
      ...baseState,
      type: this.getQueryParamValue("type", -1),
      showPerformanceSelectorRestriction: false,
      name: "",
    };
  }

  componentDidMount() {
    super.componentDidMount();
    new LocationService()
      .list()
      .then((response) => this.setState({ locations: response.data.data }));
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "performance-selectors",
        name: "Performance Selectors",
      }),
    });
  };

  getApiPath = () => "/api/v1/performance_selectors";

  getDefaultSort = () => "-Name";

  onSelectChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const value = e.target.value;
    this.setState({ [fieldName]: value }, () => {
      const { type } = this.state;
      const params = {
        type,
      };
      const normalized = Object.fromEntries(
        Object.entries(params).filter(([, v]) => {
          if (!Number.isNaN(+v)) {
            return +v >= 0;
          }

          return !isEmpty(v);
        })
      );
      const additionalParams = new URLSearchParams(normalized).toString();
      this.props.history.push(`/performance-selectors?${additionalParams}`);
      this.resetPageBounds();
      this.loadData(0);
    });
  };

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  injectSearchTerm(queryParameters) {
    const { type, name, locationId, applications } = this.state;
    const customParameters = {
      locationId,
      includes: "PerformanceSelectorRestrictions",
    };
    if (type !== -1) {
      customParameters.type = type;
    }
    if (name) {
      customParameters.name = name;
    }
    if (applications && applications.length) {
      customParameters.applications = applications.reduce(
        (acc, cur) => acc + cur,
        0
      );
    }
    return super.injectSearchTerm(queryParameters, customParameters);
  }

  rowChecked = (item, index) => {
    const { data } = this.state;
    item.checked = !item.checked;
    data.splice(index, 1, item);
    this.setState({ data });
  };

  checkAllRowChange = (value) => {
    const { data } = this.state;
    data.forEach((x) => (x.checked = value));
    this.setState({ data });
  };

  generateTableFilter() {
    const { name, data, locationId, locations, applications } = this.state;
    const isAnyRowChecked = data.some((x) => x.checked);
    return (
      <>
        <div className="row">
          <div className="col-12 mb-3">
            <strong>Restrictions:</strong>
          </div>
          <div className="col-md-6 mb-3">
            <RowFormField label="Station Location:">
              <select
                className="form-control"
                name="locationId"
                value={locationId}
                onChange={(e) => this.onSelectChange(e)}
              >
                <option value={0}>Select All</option>
                {(locations || []).map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </RowFormField>
          </div>
          <div className="col-md-6 mb-3">
            <RowFormField label="Ticketing Applications:">
              <CustomSelect
                data={TicketingApplication.All.filter((x) => x.value)}
                label="name"
                value="value"
                isMulti={true}
                onChange={(e) => {
                  this.setState({ applications: e.map((x) => x.value) }, () =>
                    this.search()
                  );
                }}
                selectedValue={applications}
              />
            </RowFormField>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-md-6 mb-3">
            <RowFormField label="Type">
              <select
                className="form-control col-xl-4"
                name="type"
                onChange={this.onSelectChange}
                value={this.getQueryParamValue("type", -1)}
              >
                <option value={-1}>All Type</option>
                {PerformanceSelectorsType.All.map((x) => (
                  <option value={x.value} key={x.value}>
                    {x.name}
                  </option>
                ))}
              </select>
            </RowFormField>
          </div>
          <div className="col-md-6 mb-3">
            <div className="input-group">
              <input
                type="text"
                id="input1-group2"
                name="name"
                placeholder="Search"
                className="form-control"
                onKeyDown={this.onKeyDownSearchInput}
                onChange={this.onTextBoxChange}
                defaultValue={name}
              />
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-3 justify-content-end">
          <div className="col-md-5 col-xl-3 d-flex">
            <button
              type="button"
              className="btn btn-success flex-fill"
              onClick={() => {
                if (!this.restrictionModal) {
                  this.restrictionModal = getModalInstant(
                    "performance-restriction-modal"
                  );
                }
                this.restrictionModal.show();
              }}
              disabled={!isAnyRowChecked}
            >
              <i className="fas fa-edit" aria-hidden="true" /> Update
              Restrictions
            </button>
          </div>
        </div>
      </>
    );
  }

  generateTableContent() {
    const { data } = this.state;
    const isAllChecked = (data || []).every((x) => !!x.checked);
    return (
      <>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={(e) => this.checkAllRowChange(e.target.checked)}
              />
            </th>
            <th style={{ width: "6%" }}>Id</th>
            <th style={{ width: "15%" }} onClick={this.onColumnClick}>
              Name
              <i
                className="fas fa-sort-alpha-down"
                id="Name"
                onClick={this.toggleSort}
              />
            </th>
            <th style={{ width: "15%" }}>Type</th>
            <th onClick={this.onColumnClick}>
              Display Weight
              <i
                className="fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Start Date
              <i
                className="fas fa-sort-alpha-down"
                id="StartDate"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              End Date
              <i
                className="fas fa-sort-alpha-down"
                id="EndDate"
                onClick={this.toggleSort}
              />
            </th>
            <th>Restricted</th>
            <th>IsActive</th>
          </tr>
        </thead>
        <tbody id="data">
          {data &&
            data.map((item, index) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                  <td onClick={(e) => e.stopPropagation()}>
                    <input
                      type="checkbox"
                      checked={!!item.checked}
                      onChange={() => this.rowChecked(item, index)}
                    />
                  </td>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{PerformanceSelectorsType.getName(item.type)}</td>
                  <td>{item.displayWeight}</td>
                  <td>{DateHelper.formatDateTimeString(item.startDate)}</td>
                  <td>{DateHelper.formatDateTimeString(item.endDate)}</td>
                  <td>
                    {item.performanceSelectorRestrictions?.length ? (
                      <span className="text-success">Yes</span>
                    ) : (
                      <span className="text-danger">No</span>
                    )}
                  </td>
                  <td>{item.isActive ? "Yes" : "No"}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  restrictModalSuccessHandler = (results) => {
    const { data } = this.state;

    data.forEach((selector) => {
      const newRestriction = results.find(
        (x) => x.performanceSelectorId === selector.id
      );
      if(newRestriction) {
        selector.performanceSelectorRestrictions = [
          newRestriction,
          ...(selector.performanceSelectorRestrictions || []),
        ];
      }
    });
    this.setState({ data });
    this.checkAllRowChange(false);
    this.restrictionModal.hide();
  };

  generateModals() {
    const { locations, data } = this.state;
    const selectedSelectorIds = data.filter((x) => x.checked).map((x) => x.id);
    return (
      <PerformanceSelectorsRestrictionModal
        locations={locations}
        selectedSelectorIds={selectedSelectorIds}
        onSuccess={results => this.restrictModalSuccessHandler(results)}
      />
    );
  }
}

export default PerformanceSelectorListing;
