import React from 'react';
import Validators from '../../../common/Validators';
import Select from 'react-select';
import { Movie } from "../../../../models/Movie";
import { MovieService } from "../../../../services/MovieService";
import { MovieFormat } from "../../../../constants/MovieConstants";
import _ from 'lodash';
import { toast } from "react-toastify";

export class PromotionCardMovieDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movieReleaseOptions: [],
      movieInstance: new Movie(),
      movieOptions: [],
      isDirty: false,
      isSubmit: false,
      validations: [],
      model: this.mergeDefaultValue(),
    };
    this.movieService = new MovieService();
  }

  componentDidMount() {
    this.generateReleaseOptions();
  }

  buildModel() {
    const { movieId, selectedMovieReleaseIds } = this.state.model;
    const { movieReleaseOptions } = this.state;
    let selectedMovieReleases = [];

    selectedMovieReleaseIds.forEach(item => {
      const movieRelease = movieReleaseOptions.find(val => val.id === item);
      if (!_.isNaN(movieRelease)) {
        selectedMovieReleases.push(movieRelease);
      }
    });


    return {
      movieId,
      selectedMovieReleases
    }
  }

  loadMovieReleases = (id) => {
    const { movieInstance } = this.state;
    this.movieService.get(
      id,
      { includes: "Company, MovieReleases" },
      (response) => {
        this.setState({
          movieInstance: new Movie(response),
          movieReleaseOptions: response.movieReleases
        });
      },
      (error) => {
        this.setState({
          movieInstance: new Movie(),
          movieReleaseOptions: [],
        });
        toast.error(error.message);
      }
    );
  };

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(val => val.fieldName === fieldName && !val.isValid) && this.state.isSubmit;
    return `form-control ${isError ? 'is-invalid' : ''}`;
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every(val => val.isValid);
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      movieId: value.movieId || '',
      selectedMovieRelease: value.selectedMovieRelease || null,
      selectedMovieReleaseIds: value.selectedMovieReleaseIds || [],
    };
  }

  onCheckBoxChange = (e, useNumberValue) => {
    const fieldName = e.target.getAttribute('field-name');
    const { model } = this.state;
    const { checked, value } = e.target;
    const options = model[fieldName];
    const convertedValue = useNumberValue ? value - 0 : value;
    const newOptions = checked
      ? [...options, convertedValue]
      : options.filter(opt => opt !== convertedValue);
    const newModel = Object.assign({}, model, { [fieldName]: newOptions });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model)
      onModelChange(model);
    }
  }

  onProceedClick = () => {
    const { selectedMovieReleaseIds } = this.state.model;

    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }

    if (selectedMovieReleaseIds.length <= 0) {
      toast.error("Please select at least one of the movie release.");
      return;
    }
    const model = this.buildModel();
    if (this.props.onProceed) {
      this.props.onProceed(model);
    }
  }

  onTextBoxChange = (e) => {
    const { model } = this.state;
    const fieldName = e.target.getAttribute('fieldname');
    const value = e.target.value;
    const newModel = Object.assign({}, model, { [fieldName]: value });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  onMovieChange = (e) => {
    const { model } = this.state;
    const newModel = Object.assign({}, model, { movieId: e.value, selectedMovieRelease: e });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(val => val.fieldName === e.fieldName && val.type === e.type);
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  }

  generateReleaseOptions() {
    const movieOptions = this.props.movieOptions || [];
    const generatedOptions = [];
    movieOptions.map(item => {
      generatedOptions.push({ value: item.id, label: item.labelDisplay });
    });

    this.setState({ movieOptions: generatedOptions });
  }

  isFilmFormatChecked(code) {
    const { selectedMovieReleaseIds } = this.state.model;
    return selectedMovieReleaseIds.indexOf(code) > -1;
  }

  onFilmFormatChange = (e) => {
    this.onCheckBoxChange(e, true);
  }

  onRetrieveMovie = () => {
    const movieId = this.state.model.movieId;
    if (movieId) {
      this.loadMovieReleases(movieId);
    }
  }

  render() {
    const movieDict = this.props.movieDict || null;
    const { movieReleaseOptions, isSubmit, movieOptions } = this.state;
    const { movieId, selectedMovieRelease } = this.state.model;

    return (
      <React.Fragment>
        <div className="modal fade show" id="resetPassword" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block' }}>
          <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Add Movie</h5>
                <button type="button" className="close" aria-label="Close" onClick={this.props.onCancel}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid text-left">
                  <div className="row form-group">
                    <label className="col-3 form-control-label">Movie:<span className="color-red">*</span></label>
                    <div className="col-9 col-lg-6 mb-3">
                      <Select id="FilmTitle" name="addFilm" onChange={this.onMovieChange} value={selectedMovieRelease} options={movieOptions} />
                      <Validators.RequiredValidator onValidationStatusChange={this.onValidationStatusChange} fieldName="movieId" isEnabled={isSubmit} property="Film Title" value={movieId}></Validators.RequiredValidator>
                    </div>
                    <div className="col-md-3">
                      <button type="button" className="btn btn-outline-secondary" onClick={this.onRetrieveMovie}>Retrieve Movie</button>
                    </div>
                  </div>
                  <div className={`accordion-collapse collapse ${movieReleaseOptions.length > 0 ? "show" : ""}`}>
                    <div className="accordion-body">
                      <div className="row form-group">
                        <label className="col-md-3 text-nowrap form-control-label">Movie Releases:<span className="color-red">*</span></label>
                        <div className="col-md-6">
                          <div className="form-check">
                            {
                              movieReleaseOptions.map((option, index) =>
                                <div key={option.id} id={`id_${option.id}`}>
                                  <div className="checkbox" id={`chk_${option.id}`}>
                                    <label htmlFor={`id_${option.id}`} className="form-check-label">
                                      <input type="checkbox" id={`id_${option.id}`} name={option.id} value={option.id} className="mr-1" field-name="selectedMovieReleaseIds" checked={this.isFilmFormatChecked(option.id)} onChange={this.onFilmFormatChange} />{option.format}({MovieFormat.getCode(option.format)}-{MovieFormat.getName(option.format)})
                                    </label>
                                  </div>
                                  <Validators.DuplicateValidator
                                    onValidationStatusChange={this.onValidationStatusChange}
                                    fieldName={`id_${option.id}`}
                                    isEnabled={this.isFilmFormatChecked(option.id)}
                                    property={MovieFormat.getName(option.format)}
                                    objectKey={movieId}
                                    value={option.id}
                                    data={movieDict}
                                    checked={this.isFilmFormatChecked(option.id)}>
                                  </Validators.DuplicateValidator>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={this.props.onCancel}>Cancel</button>
                <button type="button" className="btn btn-success" disabled={!this.isValidated()} onClick={this.onProceedClick}>Add Movie</button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    )
  }
}
