import _ from "lodash";
import { Transaction } from "./Transaction";

export class RefundRequest {
  constructor(data) {
    this.id = data.id ?? 0;
    this.reason = data.reason ?? '';
    this.status = data.status ?? '';
    this.amount = data.amount ?? 0;
    this.approvedOn = data.approvedOn ?? '';
    this.refundedOn = data.refundedOn ?? '';
    this.createdOn = data.createdOn ?? '';
    this.transactionId = data.transactionId ?? 0;
    this.actions = data.actions ?? [];
    this.createdBy = data.createdBy ?? '';
    this.transactionReferenceNumber = data.transactionReferenceNumber ?? '';
    this.appId = data.appId ?? 0;
    if (!_.isNil(data.transaction)) {
      this.transaction = new Transaction(data.transaction);
    }
  }
}
