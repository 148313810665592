import React, { useEffect, useRef, useState } from "react";
import { SettingsRestService } from "../../services/SettingsRestService";
import { SystemSettings } from "../../common/SystemSettings";
import { SystemSettingKeys } from "../../common/Constants";

const DEFAULT_POOLING_INTERVAL = 60000;

export const NewVersionNotice = (props) => {
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const foundNewVersion = useRef(false);
  const firstLoad = useRef(false);

  useEffect(() => {
    const poolingVersionIntervalInSecond = SystemSettings.get(
      SystemSettingKeys.PoolingVersionIntervalInSecond
    );
    const interval =
      poolingVersionIntervalInSecond > 0
        ? poolingVersionIntervalInSecond
        : DEFAULT_POOLING_INTERVAL;
    if (!firstLoad.current) {
      console.log(
        `Skipped very first attempt. Will be triggered after ${interval} seconds`
      );
      setTimeout(() => setRefresh(refresh + 1), interval);
      firstLoad.current = true;
      return;
    }
    new SettingsRestService()
      .get()
      .then((response) => {
        const localVersion = SystemSettings.get(SystemSettingKeys.Version);
        const { version: serverVersion } = response.data;
        if (!localVersion || localVersion === serverVersion) {
          console.log("New version not found. Continue pooling new version.");
          return;
        }
        console.log("New version found.");
        foundNewVersion.current = true;
        setVisible(true);
      })
      .finally(() => {
        if (foundNewVersion.current) return;
        console.log(`Will trigger next attempt after ${interval} seconds`);
        setTimeout(() => setRefresh(refresh + 1), interval);
      });
  }, [refresh]);
    
  const reload = () => window.location.reload();

  return (
    <div className={`app-wrapper ${visible ? "show-notice" : ""}`}>
      {visible && (
        <>
          <div className="notice-wrapper-background" />
          <div className="notice-wrapper">
            <p>
              We've made some updates! Please empty cache and hard reload if
              you're not using incognitor mode, &nbsp;
              <u onClick={reload} className="btn-refresh">
                refresh your browser
              </u>
              &nbsp; to use the latest version.
            </p>
            <button className="btn-close" onClick={() => setVisible(false)}>
              &#10005;
            </button>
          </div>
        </>
      )}
      {props.children}
    </div>
  );
};
