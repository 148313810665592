import React from "react";
import { Card } from "../../common/Card";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import { CorporateClient } from "../../../models/CorporateClient";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { RowFormField } from "../../layout/FormLayout";
import { CompanyType } from "../../../constants/CompanyType";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class CorporateClientDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      corporateClient: null,
      corporateClientId: "",
      showHistoryDialog: false,
      instance: new CorporateClient(),
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_clients",
        name: "Corporate Clients",
      }),
      activeModule: new NavigationItem({
        identifier: "create-corporate-details",
        name: "Corporate Clients Details",
      }),
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const corporateClientId = StringHelper.toNumber(id, 0);
    if (corporateClientId > 0) {
      this.setState({ corporateClientId }, this.refreshData);
    }
  }

  refreshData() {
    const { instance } = this.state;
    const { id } = this.props.match.params;
    RestClient.sendGetRequest(
      `/api/v1/corporate_booking_companies/${id}`,
      (response) => {
        instance.handleResponse(response);
        this.setState({ instance });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  onViewHistoryClick = () => {
    this.setState({ showHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showHistoryDialog: false });
  };

  render() {
    const { instance, showHistoryDialog } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                data-target="#UserHistory"
                onClick={this.onViewHistoryClick}
                data-toggle="modal"
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <Card title="Client Details">
              <div className="col-md-8">
                <RowFormField htmlFor="CompanyCode" label="Company Code:">
                  <input
                    className="form-control display-input"
                    id="CompanyCode"
                    value={instance?.code}
                    placeholder="Enter Company Code"
                    readOnly
                  />
                </RowFormField>
                <RowFormField htmlFor="CompanyName" label="Company Name:">
                  <input
                    className="form-control display-input"
                    id="CompanyName"
                    value={instance?.name}
                    placeholder="Enter Company Name"
                    readOnly
                  />
                </RowFormField>
                <RowFormField htmlFor="CompanyType" label="Company Type:">
                  <input
                    className="form-control display-input"
                    id="CompanyType"
                    value={
                      CompanyType.getName(
                        instance?.companyType
                      ) ?? ""
                    }
                    placeholder="Enter Company Type"
                    readOnly
                  />
                </RowFormField>
                <RowFormField htmlFor="ContactPerson" label="Client Name:">
                  <input
                    className="form-control display-input"
                    id="ContactPerson"
                    value={instance?.clientName}
                    placeholder="Enter Company Name"
                    readOnly
                  />
                </RowFormField>
                <RowFormField htmlFor="ContactNumber" label="Contact Number:">
                  <input
                    className="form-control display-input"
                    type="tel"
                    inputMode="tel"
                    id="ContactNumber"
                    value={instance?.contactNumber}
                    placeholder="Enter Contact number"
                    readOnly
                  />
                </RowFormField>
                <RowFormField label="Email:" htmlFor="Email">
                  <input
                    className="form-control display-input"
                    id="Email"
                    type="email"
                    value={instance?.emailAddress}
                    placeholder="Enter Email Address"
                    readOnly
                  />
                </RowFormField>
                <RowFormField label="Ticket Type:" htmlFor="CompanyType">
                  <input
                    className="form-control display-input"
                    type="text"
                    readOnly
                    value={instance?.ticketTypeId}
                  />
                </RowFormField>
                <RowFormField label="Prepaid Quantity:" htmlFor="ContactPerson">
                  <input
                    className="form-control display-input"
                    type="text"
                    readOnly
                    value={instance?.prepaidTicketQuantity}
                  />
                </RowFormField>
                <RowFormField
                  label="Used Ticket Quantity:"
                  htmlFor="ContactNumber"
                >
                  <input
                    className="form-control display-input"
                    type="text"
                    readOnly
                    value={instance?.usedTicketQuantity}
                  />
                </RowFormField>
                <RowFormField
                  label="Remaining Ticket Quantity:"
                  htmlFor="Email"
                >
                  <input
                    className="form-control display-input"
                    type="text"
                    readOnly
                    value={instance?.remainingTicketQuantity}
                  />
                </RowFormField>
              </div>
            </Card>
          </div>
        </div>
        {showHistoryDialog && (
          <HistoryDialog
            objectId={instance.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.CorporateClient}
          />
        )}
      </div>
    );
  }
}
