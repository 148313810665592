import React, { Component } from "react";
import '../css/documentation.scss';

export class ViewScheduledFilmFestivalHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To View Scheduled Film Festival</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 2 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Film Festivals" link. This will take you to the Film Festivals Listing Page.<br />
                                    <img src="/img/documentation-img/FilmImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To view scheduled film festival, click on “Scheduled Publishing” in the top right corner of the Film Festivals Listing Page. <br />
                                    <img src="/img/documentation-img/FilmImg/list_scheduled.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Scheduled Film Festivals Listing Page, click on any the scheduled film festival listing of your choice. This will take you to the View Scheduled Film Festivals Details.	 <br />
                                    <img src="/img/documentation-img/FilmImg/scheduled_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Scheduled Film Festivals Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Film Festival Title
                                        </li>
                                        <li>Film Festival Code</li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Film Festival Description
                                        </li>
                                        <li>
                                            Festival Image
                                        </li>
                                        <li>
                                            Film Festival Programmes
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Film Festival Description should be in HTML format.
                                </div>
                            </li>

                            </ol>
                            <div className="text-right">
                                <a href="/documentation/filmfestivals/EditFilmFestivalHelp" className="btn btn-primary">&laquo; Edit Film Festival</a>&nbsp;
                                <a href="/documentation/filmfestivals/EditScheduledFilmFestivalHelp" className="btn btn-primary">Edit Scheduled Film Festival &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}