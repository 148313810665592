import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { BreadCrumb } from "../../common/BreadCrumb";
import { CampaignCategory } from "../../../constants/CampaignConstants";
import { MoviePromotionValidityService } from "../../../services/MoviePromotionValidityService";
import { MoviePromotionValidity } from "../../../models/MoviePromotionValidity";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { DateTimeInput } from "../../campaigns/DateTimeInput";
import { CustomSelect } from "../../common/CustomSelect";
import RestClient from "../../../common/RestClient";
import { useHistory } from "react-router-dom";

export const MoviePromotionValidityCreation = () => {
  const [moviePromotionValidity, setMoviePromotionValidity] = useState(
    new MoviePromotionValidity({})
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const moviePromotionValidityService = useMemo(
    () => new MoviePromotionValidityService(),
    []
  );
  const [movieReleases, setMovieReleases] = useState([]);
  const history = useHistory();

  useEffect(() => {
    RestClient.sendGetRequest(
      "/api/v1/movie_releases/options",
      (response) => {
        setMovieReleases(response.data);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  const onSelectChange = (e, name) => {
    const value = e.value;
    setMoviePromotionValidity((data) => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  const onShowConfirmDialog = () => {
    setShowConfirmModal(true);
  };

  const onProceedCreateConfirmation = () => {
    setShowConfirmModal(false);
    moviePromotionValidityService
      .post(moviePromotionValidity)
      .then((response) => {
        if(!response) return;
        history.push(`/movie-promotion-validity/${response.data.id}`);
        setMoviePromotionValidity((prev) => ({
          ...prev,
          ...response.data,
        }));
        toast.success("Create movie promotion validity success");
      })
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setMoviePromotionValidity((moviePromotionValidity) => {
      return {
        ...moviePromotionValidity,
        [fieldName]: selectValue,
      };
    });
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-promotion-validity-creation",
        name: "Create Movie Promotion Validity",
      }),
    });
  };

  if (!moviePromotionValidity) return <></>;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Create Movie Promotion Validity
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row form-group">
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Movie Release"
                            require={true}
                            input={
                              <CustomSelect
                                data={movieReleases}
                                label="name"
                                value="id"
                                onChange={(e) =>
                                  onSelectChange(e, "movieReleaseId")
                                }
                                selectedValue={
                                  moviePromotionValidity.movieReleaseId
                                }
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Campaign Category"
                            input={
                              <CustomSelect
                                data={CampaignCategory.All}
                                label="name"
                                value="value"
                                onChange={(e) =>
                                  onSelectChange(e, "campaignCategory")
                                }
                                selectedValue={
                                  moviePromotionValidity.campaignCategory
                                }
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Start On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="startOn"
                                value={moviePromotionValidity.startOn}
                                onChange={onInputChange}
                                placeholder="Enter Start On"
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="End On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="endOn"
                                value={moviePromotionValidity.endOn}
                                onChange={onInputChange}
                                placeholder="Enter End On"
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end pb-3">
                <button
                  className="btn btn-success mr-1"
                  onClick={onShowConfirmDialog}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showConfirmModal}
        onCancel={() => setShowConfirmModal(false)}
        onProceed={onProceedCreateConfirmation}
        title="Create confirmation"
        message="Are you sure want to create movie promotion validity?"
      />
    </>
  );
};
