import React from "react";
import { StringHelper } from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { SearchPassMode } from "../../common/Constants";
import { RowFormField } from "../../../layout/FormLayout";

export class PassFilterSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passProgramId: "",
      programTypeOptions: [],
      searchBy: SearchPassMode.None,
      serialNumbers: "",
      serialNumberStart: "",
      serialNumberEnd: "",
    };
  }

  componentDidMount() {
    this.loadProgramTypeOptions();
    this.onModelChange();
  }

  isValidQuery() {
    const {
      passProgramId,
      searchBy,
      serialNumbers,
      serialNumberStart,
      serialNumberEnd,
    } = this.state;
    if (
      searchBy === SearchPassMode.Single &&
      !StringHelper.isNullOrEmpty(serialNumbers)
    ) {
      return true;
    }
    return (
      searchBy === SearchPassMode.Range &&
      passProgramId &&
      serialNumberStart &&
      serialNumberEnd
    );
  }

  loadProgramTypeOptions() {
    RestClient.sendGetRequest("/api/v1/pass_programs/options", (response) => {
      this.setState({ programTypeOptions: response });
    });
  }

  onModelChange = () => {
    if (this.props.onModelChange) {
      const {
        passProgramId,
        searchBy,
        serialNumbers,
        serialNumberStart,
        serialNumberEnd,
      } = this.state;
      const model = {
        passProgramId,
        searchBy,
        serialNumbers,
        serialNumberStart,
        serialNumberEnd,
      };
      this.props.onModelChange(model);
    }
  };

  onSearchByChange = (e) => {
    const value = StringHelper.toNumber(e.target.value, 0);
    this.setState({ searchBy: value }, this.onModelChange);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("data-field-name");
    const value = e.target.value;
    this.setState({ [fieldName]: value }, this.onModelChange);
  };

  render() {
    const {
      passProgramId,
      programTypeOptions,
      searchBy,
      serialNumbers,
      serialNumberStart,
      serialNumberEnd,
    } = this.state;
    const enableSinglePassCode = searchBy === SearchPassMode.Single;
    const enablePassCodeRange = searchBy === SearchPassMode.Range;
    return (
      <div className="row">
        <div className="col-xl-2 text-nowrap">
          <label className="form-control-label">Search By:</label>
        </div>
        <div className="col-xl-10 mb-3">
          <RowFormField
            label={
              <>
                <input
                  type="radio"
                  id="SingleSN"
                  name="SearchBy"
                  className="mr-3"
                  checked={enableSinglePassCode}
                  onChange={this.onSearchByChange}
                  value={SearchPassMode.Single}
                />
                Single Pass Code
              </>
            }
            htmlFor="SingleSN"
          >
            <input
              className="form-control SingleSN"
              id="SingleText"
              type="text"
              data-toggle="tooltip"
              data-placement="right"
              title="To add additional single serial number, it must be comma separated. A maximum of 10 single serial numbers are allowed."
              placeholder="Enter Single Serial Number"
              disabled={enableSinglePassCode ? undefined : "disabled"}
              data-field-name="serialNumbers"
              onChange={this.onTextBoxChange}
              value={serialNumbers}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                <input
                  type="radio"
                  id="SNRange"
                  name="SearchBy"
                  className="mr-3"
                  checked={enablePassCodeRange}
                  onChange={this.onSearchByChange}
                  value={SearchPassMode.Range}
                />
                Pass Code Range
              </>
            }
            htmlFor="SNRange"
          >
            <select
              className="form-control SNRange"
              id="Type"
              disabled={!enablePassCodeRange && "disabled"}
              data-field-name="passProgramId"
              onChange={this.onTextBoxChange}
              value={passProgramId}
            >
              <option value="">Select Pass Program type</option>
              {programTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </RowFormField>
          <RowFormField>
            <div className="d-flex gap-6">
              <input
                className="form-control SNRange"
                type="text"
                id="SNStart"
                placeholder="Serial Number Start"
                disabled={!enablePassCodeRange && "disabled"}
                data-field-name="serialNumberStart"
                onChange={this.onTextBoxChange}
                value={serialNumberStart}
              />
              <input
                className="form-control SNRange"
                type="text"
                id="SNEnd"
                placeholder="Serial Number End"
                disabled={enablePassCodeRange ? undefined : "disabled"}
                data-field-name="serialNumberEnd"
                onChange={this.onTextBoxChange}
                value={serialNumberEnd}
              />
            </div>
          </RowFormField>
          <RowFormField>
            <button
              className="btn btn-primary"
              id="btnSearch"
              style={{ marginRight: "-0.25rem" }}
              disabled={this.isValidQuery() ? undefined : "disabled"}
              onClick={this.props.onSearch}
            >
              <i className="fa fa-search" aria-hidden="true"></i> Search
            </button>
          </RowFormField>
        </div>
      </div>
    );
  }
}
