export class TicketingApplication {
  static None = 0;
  static Counter = 1;
  static Kiosk = 2;
  static PublicWebsite = 4;
  static GPayApi = 8;

  static All = [
    { name: "All", value: this.None },
    { name: "Counter", value: this.Counter },
    { name: "Kiosk", value: this.Kiosk },
    { name: "Public website", value: this.PublicWebsite },
    { name: "GPay", value: this.GPayApi },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (!found) return "";
    return found.name;
  }

  static hasFlag = (value, flag) => {
    const bitWise = value & flag;
    return bitWise > 0;
  };

  static getPossibleValue(sum) {
    if (sum === 0) return [{ name: "All", value: this.None }];
    return this.All.filter((x) => x.value && this.hasFlag(sum, x.value));
  }
}
