export class CampaignConditionPerformance {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.campaignId = data?.campaignId ?? "";
    this.locationVenueId = data?.locationVenueId ?? "";
    this.movieId = data?.movieId ?? "";
    this.performanceIds = data?.performanceIds ?? [];
  }
}

export class CampaignConditionDateTime {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.campaignId = data?.campaignId ?? "";
    this.dayOfWeek = data?.dayOfWeek ?? -1;
    this.timeStart = data?.timeStart ?? "";
    this.timeEnd = data?.timeEnd ?? "";
  }
}

export class CampaignConditionMovie {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.campaignId = data?.campaignId ?? "";
    this.movieId = data?.movieId ?? "";
  }
}
