import _ from "lodash";

export class VoucherStatus {
  static Registered = 1;
  static Activated = 2;
  static Used = 3;
  static Expired = 4;

  static All = [
    {
      name: "Registered",
      value: this.Registered,
    },
    {
      name: "Activated",
      value: this.Activated,
    },
    {
      name: "Used",
      value: this.Used,
    },
    {
      name: "Expired",
      value: this.Expired,
    },
  ];

  static InternalStatus = [this.Used, this.Expired];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class VoucherProgramCategory {
  static GiftPersonalVouchers = 1;
  static CorporateVouchers = 2;
  static CashVouchers = 3;

  static All = [
    {
      name: "Gift Personal Vouchers",
      value: this.GiftPersonalVouchers,
    },
    {
      name: "Corporate Vouchers",
      value: this.CorporateVouchers,
    },
    {
      name: "Cash Vouchers",
      value: this.CashVouchers,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class VoucherProgramType {
  static Internal = 1;
  static External = 2;

  static All = [
    { value: this.Internal, name: "Shaw Internal Vouchers" },
    { value: this.External, name: "External Vouchers" },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class SpecialDayType {
  static PublicHoliday = 1;
  static EveOfPublicHoliday = 2;
  static BlackoutDay = 3;
  static SubstituteHoliday = 4;

  static All = [
    { value: this.PublicHoliday, name: "Public Holiday" },
    { value: this.EveOfPublicHoliday, name: "Eve of Public Holiday" },
    { value: this.BlackoutDay, name: "Blackout Day" },
    { value: this.SubstituteHoliday, name: "Substitute Holiday" },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}
