import { LocationVenue } from "./LocationVenue";
import _ from "lodash";

export class Performance {
  constructor() {
    this.id = 0;
    this.actualDateTime = "";
    this.salesStartOn = "";
    this.type = 0;
    this.movieId = 0;
    this.movieReleaseId = 0;
    this.primaryTitle = "";
    this.secondaryTitle = "";
    this.duration = 0;
    this.displayWeight = 0;
    this.labelPrint = "";
    this.classify = 0;
    this.locationVenueBrand = 0;
    this.movieFormat = 0;
    this.movieBrand = 0;
    this.movieLanguage = 0;
    this.movieSubtitle = 0;
    this.restriction = 0;
    this.locationId = 0;
    this.locationVenueId = 0;
    this.serviceId = 0;
    this.serviceCode = "";
    this.posterUrl = "";
    this.seatingStatus = 0;
    this.isLayoutPublished = false;
    this.isOpening = false;
    this.status = 0;
    this.isFreeStand = false;
    this.isLimited = false;
    this.isMidnight = false;
    this.publishedOn = "";
    this.seatingStatusUpdatedOn = "";
    this.movieReleaseWeek = "";
    this.adviceName = "";
    this.isVaccinatedHall = false;
    this.isPartial = false;
    this.isPrivate = false;
    this.campaignId = 0;
    this.locationVenue = new LocationVenue();
    this.isDtsx = false;
  }

  handleResponse(data) {
    this.id = data.id;
    this.actualDateTime = data.actualDateTime;
    this.salesStartOn = data.salesStartOn;
    this.type = data.type;
    this.movieId = data.movieId;
    this.movieReleaseId = data.movieReleaseId;
    this.primaryTitle = data.primaryTitle;
    this.secondaryTitle = data.secondaryTitle;
    this.duration = data.duration;
    this.displayWeight = data.displayWeight;
    this.labelPrint = data.labelPrint;
    this.classify = data.classify;
    this.locationVenueBrand = data.locationVenueBrand;
    this.movieFormat = data.movieFormat;
    this.movieBrand = data.movieBrand;
    this.movieLanguage = data.movieLanguage;
    this.movieSubtitle = data.movieSubtitle;
    this.restriction = data.restriction;
    this.locationId = data.locationId;
    this.locationVenueId = data.locationVenueId;
    this.serviceId = data.serviceId;
    this.serviceCode = data.serviceCode;
    this.posterUrl = data.posterUrl;
    this.seatingStatus = data.seatingStatus;
    this.isLayoutPublished = data.isLayoutPublished;
    this.isOpening = data.isOpening;
    this.status = data.status;
    this.isFreeStand = data.isFreeStand;
    this.isLimited = data.isLimited;
    this.isMidnight = data.isMidnight;
    this.publishedOn = data.publishedOn;
    this.seatingStatusUpdatedOn = data.seatingStatusUpdatedOn;
    this.movieReleaseWeek = data.movieReleaseWeek ?? this.movieReleaseWeek;
    this.adviceName = data.adviceName ?? this.adviceName;
    this.isVaccinatedHall = data.isVaccinatedHall;
    this.isPartial = data.isPartial;
    this.isPrivate = data.isPrivate;
    this.campaignId = data.campaignId;
    this.isDtsx = data.isDtsx ?? false;
    if (!_.isNil(data.locationVenue)) {
      this.locationVenue.handleResponse(data.locationVenue);
    }
  }
}