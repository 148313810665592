import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateCareerHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create New Career</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Careers” link. This will take you to the Careers Listing Page.   <br />
                                    <img src="/img/documentation-img/CareersImg/nav_careers.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create a new career listing, click on the “Create New Career” button in the top right corner of the page. <br />
                                    <img src="/img/documentation-img/CareersImg/career_create.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    In Careers Details Page, you will be able to see the following editable fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Career Title
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Display Start
                                        </li>
                                        <li>
                                            Display End
                                        </li>
                                        <li>
                                            Short Description
                                        </li>
                                        <li>
                                            Responsibilities
                                        </li>
                                        <li>
                                            Requirements
                                        </li>
                                        <li>
                                            Long Description
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Short Description, Responsbilities, Requirements, and Long Description should be in HTML format.<br />
                                            - Short Description, Responsibilities, Requirements, and Benefits have a maximum of 4000 characters.<br />
                                            - Long Description has a maximum of 12000 characters.
                                </div>
                            </li>

                                        <li class="createList">
                                            To preview the career listing, click “Preview” at the bottom right corner of the page. <br />
                                    <img src="/img/documentation-img/CareersImg/create_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page. <br />
                                    <img src="/img/documentation-img/CareersImg/cancel_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To save your changes, click on “Create” at the bottom right corner of the page.<br />
                                    <img src="/img/documentation-img/CareersImg/create_create.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to create the career, click on the “Confirm” button <br />
                                    <img src="/img/documentation-img/CareersImg/create_create_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful save, a confirmation toaster will appear at the bottom right of the screen and you will see the new listing. <br />
                                    <img src="/img/documentation-img/CareersImg/success_endpoint.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/careers/ViewCareerHelp" className="btn btn-primary">&laquo; View Careers</a>&nbsp;
                                <a href="/documentation/careers/EditCareerHelp" className="btn btn-primary">Edit Careers &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}