import React, { useState } from "react";
import { Pricing } from "../../../models/Pricing";
import _ from "lodash";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import { RowFormField } from "../../layout/FormLayout";

export const AddSurchargePricingModal = (props) => {
  const [instance, setInstance] = useState(new Pricing());
  const { onCancel, visible, onProceed, surchargeId } = props;

  const onAdd = () => {
    let data = {
      effectiveStart: instance.effectiveStart,
      surchargeId,
      salesAmount: instance.salesAmount,
    };
    return RestClient.sendPostRequest(
      "/api/v1/surcharge_prices",
      data,
      (response) => {
        onProceed(response);
        toast.success("The surcharge price has been created successful");
        onCancel();
      },
      (error) => toast.error(error.message)
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let value = e.target.value;
    if (value && "number" === valueType && !isNaN(+value)) {
      value = +value;
    }
    const cloned = _.cloneDeep(instance);
    cloned[fieldName] = value;
    cloned.onChange();
    setInstance(cloned);
  };

  if (_.isNil(visible) || !visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        id="AddPricingModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Pricing
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField label="Sales Amount:">
                  <input
                    className="form-control"
                    type="number"
                    min="1"
                    placeholder="Enter Sales Amount"
                    valuetype="number"
                    value={instance.salesAmount}
                    name="salesAmount"
                    onChange={onInputChange}
                  />
                </RowFormField>
                <RowFormField label="Effective Start:">
                  <input
                    className="form-control"
                    type="datetime-local"
                    value={instance.effectiveStart}
                    name="effectiveStart"
                    onChange={onInputChange}
                  />
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onAdd}
                  data-dismiss="modal"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
