import React from "react";
import { VoucherProgramType } from "../../../../common/Constants";
import { StringHelper } from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { BaseListing } from "../../../common/BaseListing";
import { VoucherStatus } from "../../../../constants/VoucherContants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../../common/NavigationSettings";

export default class InternalVoucherUsageListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      serialNumber: "",
      status: 0,
      voucherProgramId: 0,
      voucherStatuses: [],
      voucherPrograms: [],
      voucherType: VoucherProgramType.Internal,
    };
  }

  async componentDidMount() {
    await this.loadData(0);
    this.getVoucherStatuses();
    this.getVoucherPrograms();
  }

  getVoucherStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/vouchers/status_dropdowns",
      (response) => {
        this.setState({ voucherStatuses: response.filter(({id}) => VoucherStatus.InternalStatus.includes(id)) });
      },
      this.handleError
    );
  };

  getVoucherPrograms = () => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/vouchers/program_dropdowns",
      { type: VoucherProgramType.Internal },
      ({ data }) => {
        this.setState({ voucherPrograms: data });
      },
      this.handleError
    );
  };

  getApiPath = () => "/api/v1/voucher_usages/internals";

  getDefaultSort = () => "-UsedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "internals",
        name: "Internal Voucher Usage Listing",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const { serialNumber, status, voucherProgramId } = this.state;
    Object.assign(queryParameters, { includes: "VoucherProgram" });
    return super.injectSearchTerm(queryParameters, {
      serialNumber,
      status,
      voucherProgramId,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onRowClick = (id) => {
    this.props.history.push(`internal_usages/${id}`);
  };

  onEnter = async (e) => {
    if (e.keyCode === 13) {
      await this.search();
    }
  };

  generateExtendedComponents() {
    const { voucherStatuses, voucherPrograms, serialNumber } = this.state;
    return (
      <>
        <div className="row form-group">
          <label className="form-control-label col-md-2">Search By:</label>
          <div className="col-md-4 col-xl-3">
            <input
              className="form-control SingleSN col"
              id="SingleText"
              type="text"
              placeholder="Enter Single Serial Number"
              value={serialNumber}
              name="serialNumber"
              onChange={this.onInputChange}
              onKeyDown={this.onEnter}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-md-2 form-control-label">Status:</label>
          <div className="col-md-4 col-xl-3">
            <select
              className="form-control"
              name="status"
              onChange={this.onInputChange}
              required
            >
              <option value={0}>All Status</option>
              {voucherStatuses &&
                voucherStatuses.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <label className="form-control-label col-md-2">Voucher Program:</label>
          <div className="col-md-4 col-xl-3">
            <select
              className="form-control"
              id="SelectVoucher"
              name="voucherProgramId"
              onChange={this.onInputChange}
              required
            >
              <option value={0}>All Voucher</option>
              {voucherPrograms &&
                voucherPrograms.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 col-xl-5">
            <button
              className="btn btn-primary float-right"
              onClick={this.search}
            >
              <i className="fa fa-search" /> Search
            </button>
          </div>
        </div>
      </>
    );
  }

  getColor = (status) => {
    if (status === VoucherStatus.Registered) {
      return "color-orange";
    }

    if (status === VoucherStatus.Activated) {
      return "color-green";
    }

    return "color-red";
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th onClick={this.onColumnClick}>
              Serial Number{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="SerialNumber"
                onClick={this.toggleSort}
              />
            </th>
            <th>Voucher Program</th>
            <th onClick={this.onColumnClick}>
              Validity Start{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityStart"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Validity End{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityEnd"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th onClick={this.onColumnClick}>
              Used On{" "}
              <i
                className="fas fa-sort-alpha-down"
                id="UsedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <tr key={item.id} onClick={(e) => this.onRowClick(item.id)}>
                  <td>{item.serialNumber}</td>
                  <td>{item.voucherProgram?.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.validityStart
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.validityEnd
                    )}
                  </td>
                  <td>
                    <span className={this.getColor(item.status)}>
                      {VoucherStatus.getName(item.status)}
                    </span>
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.usedOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }
}
