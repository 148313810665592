import React, { useState } from "react";
import { Card } from "../../../common/Card";
import { ServiceTicketItem } from "./ServiceTicketItem";
import { AddTicketTypeModal } from "../modals/AddTicketTypeModal";
import { modeAtom, serviceTicketsSelector } from "../state";
import { useRecoilValue } from "recoil";
import { ViewMode } from "../../../../constants/PricingConstants";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";

export const ServiceTicketPanel = () => {
  const [showAddTicketTypeModal, setShowAddTicketTypeModal] = useState(false);
  const serviceTickets = useRecoilValue(serviceTicketsSelector);
  const mode = useRecoilValue(modeAtom);

  return (
    <>
      <Card title="Ticket Type Information" wrapperCss="ticket">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            data-toggle="modal"
            disabled={mode === ViewMode.Readonly}
            onClick={() => setShowAddTicketTypeModal(true)}
          >
            Add Ticket Type
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {serviceTickets.map((serviceTicket, index) => (
          <ServiceTicketItem key={index} serviceTicket={serviceTicket} />
        ))}
      </Card>
      <AddTicketTypeModal
        visible={showAddTicketTypeModal}
        onCancel={() => setShowAddTicketTypeModal(false)}
      />
    </>
  );
};
