import React from "react";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { formatPassDiscountType } from "../common/Constants";

export class PassUsageListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      pass: null,
      passId: null,
      locations: [],
      venues: [],
    };
  }

  async componentDidMount() {
    const { pid } = this.props.match.params;
    this.state.passId = pid;
    await super.componentDidMount();
    if (pid) {
      await this.loadPass(pid);
    }
    this.getLocations();
    this.getLocationsVenues();
  }

  getApiPath = () => "/api/v1/passes/usage";

  getDefaultSort = () => "-UsedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "usage",
        name: "Pass Usage",
      }),
    });
  };

  injectSearchTerm(queryParameters) {
    const { name, passId } = this.state;
    let customParameters = { includes: "Pass" };
    if (!StringHelper.isNullOrEmpty(name)) {
      customParameters.name = name;
    }
    if (!StringHelper.isNullOrEmpty(passId) && passId !== 0) {
      customParameters.passId = passId;
    }
    return super.injectSearchTerm(queryParameters, customParameters);
  }

  async loadPass(id) {
    RestClient.sendGetRequest(
      `/api/v1/passes/${id}`,
      (response) => {
        this.setState({ pass: response, passId: id });
      },
      (error) => toast.error(error.message)
    );
  }

  onRowClick = (id) => {
    this.props.history.push(`/passes/usage/${id}`);
  };

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getLocations = () => {
    RestClient.sendGetRequest(
      "/api/v1/locations",
      (response) => {
        this.setState({ locations: response.data });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  getLocationsVenues = () => {
    RestClient.sendGetRequest(
      "/api/v1/locations/venues",
      (response) => {
        this.setState({ venues: response.data });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  getLocationName = (locationCode) => {
    const { locations } = this.state;
    const location = locations.find((x) => x.code === locationCode);
    return location ? location.name : "-";
  };

  getLocationVenueName = (locationVenueCode) => {
    const { venues } = this.state;
    const venue = venues.find((x) => x.code === locationVenueCode);
    return venue ? venue.name : "-";
  };

  generateTableFilter() {
    const { name, pass, passId } = this.state;
    return (
      <>
        {passId && pass && (
          <div className="row mb-3">
            <div className="col-md-3">
              <label className="form-control-label">Pass Code: </label>
              <label
                className="form-control-label px-3 px-md-0 px-xl-3"
                id="PassCode"
              >
                {pass.code}
              </label>
            </div>
            <div className="col-md-4">
              <label className="form-control-label">Pass Name: </label>
              <label className="form-control-label px-3 px-md-0" id="Name">
                {pass.name}
              </label>
            </div>
          </div>
        )}
        {!passId && (
          <div className="d-flex flex-row justify-content-end input-group mb-3">
            <input
              type="text"
              id="input1-group2"
              name="name"
              placeholder="Search"
              className="form-control col-md-4"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onTextBoxChange}
              value={name}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </div>
          </div>
        )}
      </>
    );
  }

  generateTableContent() {
    const { passId, isSort } = this.state;
    const classNameSort = isSort
      ? "fa-sort-alpha-up color-light-blue"
      : "fa-sort-alpha-down";
    return (
      <>
        <thead>
          <tr>
            {!passId && (
              <React.Fragment>
                <th>Pass Code</th>
                <th>Pass Name</th>
              </React.Fragment>
            )}
            <th>Movie Title</th>
            <th>Movie Location</th>
            <th>Discount Type</th>
            {passId && <th>LocationHall</th>}
            <th>
              Used On{" "}
              <i
                className={`sort-button fas ${classNameSort}`}
                id="UsedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item) => {
                return (
                  <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                    {!passId && (
                      <React.Fragment>
                        <td>{item.passCode}</td>
                        <td>{item.passName}</td>
                      </React.Fragment>
                    )}
                    <td>{item.movieTitle}</td>
                    <td>{this.getLocationName(item.locationCode)}</td>
                    <td>{formatPassDiscountType(item.discountType)}</td>
                    {passId && (
                      <td>
                        {this.getLocationVenueName(item.locationVenueCode)}
                      </td>
                    )}
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.usedOn
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }
}
