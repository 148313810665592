import { toast } from "react-toastify";

const DEFAULT_ERROR_MESSAGE =
  "Unable to establish a connection with the printer";
const DEFAULT_CONNECTION = "wss://localhost:8787";

export class WebServiceClient {
  sendRequest = (data, onMessage) => {
    const ws = new WebSocket(DEFAULT_CONNECTION);

    ws.onopen = () => {
      ws.send(JSON.stringify(data));
    };

    ws.onerror = (e) => {
      if (this.onErrorAction) {
        this.onErrorAction(e);
      } else {
        toast.error(DEFAULT_ERROR_MESSAGE);
      }
    };

    ws.onmessage = (event) => {
      console.log(`Got message: ${event}`);
      if (onMessage) {
        onMessage(JSON.parse(event.data));
      } else {
        ws.close();
      }
      this.onSuccessAction();
    };

    ws.onclose = () => {
      console.log("Closed connection");
    };
  };

  noop = (_) => {};

  onSuccess = (action) => {
    this.onSuccessAction = action || this.noop;
  };

  onError = (action) => {
    this.onErrorAction = action;
  };
}
