import React, { useEffect, useState } from "react";
import {
  RegistrationType,
  VoucherProgramDurationType,
  VoucherProgramType,
} from "../../../common/Constants";
import { ObjectHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import Validators from "../../common/Validators";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { getDateTimeLocal } from "../../../common/Common";
import moment from "moment/moment";

const DURATION_TYPE_TO_MOMENT_UNIT = {
  [VoucherProgramDurationType.Day]: "days",
  [VoucherProgramDurationType.Month]: "months",
  [VoucherProgramDurationType.Year]: "years",
};

const formatValidityEndDate = (date) =>
  date
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format("YYYY-MM-DDTHH:mm");

const getValidityEndDate = (date, voucherProgram) => {
  if (!voucherProgram) {
    return formatValidityEndDate(moment(date));
  }
  return formatValidityEndDate(
    moment(date).add(
      voucherProgram.validityDuration,
      DURATION_TYPE_TO_MOMENT_UNIT[voucherProgram.validityDurationType]
    )
  );
};

export const VoucherRegistration = () => {
  const [model, setModel] = useState({
    serialNumber: null,
    serialNumberStart: null,
    serialNumberEnd: null,
    isActive: false,
    validityStart: null,
    validityEnd: null,
  });
  const [registrationType, setRegistrationType] = useState(
    RegistrationType.Individual
  );
  const [voucherPrograms, setVoucherPrograms] = useState([]);
  const [voucherImage, setVoucherImage] = useState("");
  const [validations, setValidations] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/vouchers/programs",
      { type: VoucherProgramType.Internal },
      (response) => {
        if (response.data && response.data.length > 0) {
          setVoucherPrograms(response.data);
          const voucherProgramId = response.data[0].id;
          setModel((data) => ({ ...data, voucherProgramId }));
          setVoucherImage(
            response.data.find((x) => x.id === voucherProgramId)?.voucherImage
          );
        }
      },
      (err) => toast.error(err.message)
    );
  }, []);

  const onInputSerialNumberChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    onInputChange({ target: { name, value: `${value}`.toUpperCase() } });
  };

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setModel((model) => ({ ...model, [name]: value }));
  };

  const predictValidityEndDate = (validityStart, voucherProgramId) => {
    const voucherProgram = voucherPrograms.find(
      (x) => voucherProgramId === x.id
    );
    return getValidityEndDate(validityStart, voucherProgram);
  };

  const onValidityStartChange = (e) => {
    const validityStart = e.target.value;
    const validityEnd =
      model.validityEnd ??
      predictValidityEndDate(validityStart, model.voucherProgramId);
    setModel({ ...model, validityStart, validityEnd });
  };

  const updateVoucherImage = (voucherProgramId) => {
    setVoucherImage(
      voucherPrograms.find((x) => x.id === voucherProgramId)?.voucherImage
    );
  };

  const onVoucherProgramChange = (e) => {
    const voucherProgramId = parseInt(e.target.value);
    const validityEnd = predictValidityEndDate(
      model.validityStart,
      voucherProgramId
    );
    setModel({ ...model, voucherProgramId, validityEnd });
    updateVoucherImage(voucherProgramId);
  };

  const onRegistrationTypeChange = (e) => {
    setRegistrationType(e.target.value);
    setModel((m) => ({
      ...m,
      serialNumber: "",
      serialNumberStart: "",
      serialNumberEnd: "",
      validityStart: null,
    }));
  };

  const onIsActiveChange = (e) => {
    const isActive = e.target.value === "Yes";
    setModel({ ...model, isActive });
  };

  const onValidationStatusChange = (e) => {
    let newValidations = validations;
    let validation = newValidations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      newValidations.push(e);
    }
    setValidations(newValidations);
  };

  const isValidated = () => {
    if (registrationType === RegistrationType.Individual) {
      return validations.some(
        (val) =>
          val.fieldName ===
            ObjectHelper.getPropertyName(model, model.serialNumber) &&
          val.isValid
      );
    } else {
      return (
        validations.some(
          (val) =>
            val.fieldName ===
              ObjectHelper.getPropertyName(model, model.serialNumberStart) &&
            val.isValid
        ) &&
        validations.some(
          (val) =>
            val.fieldName ===
              ObjectHelper.getPropertyName(model, model.serialNumberEnd) &&
            val.isValid
        )
      );
    }
  };

  const getControlClassName = (fieldName) => {
    const isError =
      isSubmit &&
      validations.some((val) => val.fieldName === fieldName && !val.isValid);
    return `form-control ${isError ? "is-invalid" : ""}`;
  };

  const onShowConfirmDialog = () => {
    if (!isValidated()) {
      setIsSubmit(true);
      return;
    }
    RestClient.sendPostRequest(
      "api/v1/vouchers/validate",
      { ...model },
      (response) => {
        setMessage(
          `You are register ${response} voucher. Please click 'Confirm' button to proceed.`
        );
        setShowConfirmDialog(true);
      },
      (err) => toast.error(err.message)
    );
  };

  const onHideConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const register = () => {
    const body = { ...model };
    if (!body.validityStart) {
      delete body.validityStart;
    }
    RestClient.sendPostRequest(
      "api/v1/vouchers",
      body,
      () => {
        toast.success(
          "The vouchers will be registered shortly. Please check in a few minutes"
        );
      },
      (error) => toast.error(error.message)
    );
    setShowConfirmDialog(false);
  };

  const onClear = () => {
    const voucherProgramId = voucherPrograms[0].id;
    setModel((m) => ({
      ...m,
      serialNumber: "",
      serialNumberStart: "",
      serialNumberEnd: "",
      validityStart: "",
      voucherProgramId,
      isActive: false,
    }));
    updateVoucherImage(voucherProgramId);
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "registration",
        name: "Registration",
      }),
    });
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-9">
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label" htmlFor="SNumber">
                        Registration Type:
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control"
                        id="RegisterSelect"
                        onChange={onRegistrationTypeChange}
                        value={registrationType}
                      >
                        <option value={RegistrationType.Individual}>
                          Register Voucher Individually
                        </option>
                        <option value={RegistrationType.Multiple}>
                          Register Voucher By Batch
                        </option>
                      </select>
                    </div>
                  </div>
                  {registrationType === RegistrationType.Individual && (
                    <div className="row form-group Serial Single">
                      <div className="col-md-4">
                        <label className="form-control-label" htmlFor="SNumber">
                          Serial Number:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          className={getControlClassName("serialNumber")}
                          placeholder="Enter Serial Number"
                          id="SNumber"
                          name="serialNumber"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="To add additional single serial number, it must be comma separated. A maximum of 10 single serial numbers are allowed."
                          value={model.serialNumber ?? ""}
                          onChange={onInputSerialNumberChange}
                          required
                        />
                        <Validators.RequiredValidator
                          onValidationStatusChange={onValidationStatusChange}
                          fieldName="serialNumber"
                          isEnabled={isSubmit}
                          property="Serial Number"
                          value={model.serialNumber}
                        />
                      </div>
                    </div>
                  )}

                  {registrationType === RegistrationType.Multiple && (
                    <div>
                      <div className="row form-group Serial Multi">
                        <div className="col-md-4">
                          <label
                            className="form-control-label"
                            htmlFor="StartSN"
                          >
                            Starting Serial Number:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            className={getControlClassName("serialNumberStart")}
                            placeholder="Enter Starting Serial Number"
                            id="StartSN"
                            name="serialNumberStart"
                            value={model.serialNumberStart ?? ""}
                            onChange={onInputSerialNumberChange}
                            required
                          />
                          <Validators.RequiredValidator
                            onValidationStatusChange={onValidationStatusChange}
                            fieldName="serialNumberStart"
                            isEnabled={isSubmit}
                            property="Starting Serial Number"
                            value={model.serialNumberStart}
                          />
                        </div>
                      </div>
                      <div className="row form-group Serial Multi">
                        <div className="col-md-4">
                          <label className="form-control-label" htmlFor="EndSN">
                            Ending Serial Number:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            className={getControlClassName("serialNumberEnd")}
                            placeholder="Enter Ending Serial Number"
                            id="EndSN"
                            name="serialNumberEnd"
                            value={model.serialNumberEnd ?? ""}
                            onChange={onInputSerialNumberChange}
                            required
                          />
                          <Validators.RequiredValidator
                            onValidationStatusChange={onValidationStatusChange}
                            fieldName="serialNumberEnd"
                            isEnabled={isSubmit}
                            property="Ending Serial Number"
                            value={model.serialNumberEnd}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label" htmlFor="VProgram">
                        Voucher Program:
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control"
                        id="VProgram"
                        name="voucherProgramId"
                        value={model.voucherProgramId}
                        onChange={onVoucherProgramChange}
                      >
                        {voucherPrograms &&
                          voucherPrograms.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label" htmlFor="SNumber">
                        Do you want the voucher to be activated now?
                      </label>
                    </div>
                    <div className="col-md-2">
                      <div className="form-check-inline form-check">
                        <label htmlFor="yes" className="form-check-label mr-2">
                          <input
                            type="radio"
                            id="yes"
                            name="isActive"
                            value="Yes"
                            className="form-check-input"
                            checked={model.isActive}
                            onChange={onIsActiveChange}
                          />
                          Yes
                        </label>
                        <label htmlFor="no" className="form-check-label">
                          <input
                            type="radio"
                            id="no"
                            name="isActive"
                            value="No"
                            className="form-check-input"
                            checked={!model.isActive}
                            onChange={onIsActiveChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {model.isActive && (
                    <div className="row">
                      <label
                        htmlFor="ValidityStart"
                        className="col-md-4 form-check-label "
                      >
                        Validity Start:
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control mr-1 ValidityStart"
                          type="datetime-local"
                          id="validityStart"
                          placeholder="Enter Validity Start Date"
                          value={model.validityStart || ""}
                          name="validityStart"
                          onChange={onValidityStartChange}
                          required={model.isActive}
                        />
                        <Validators.RequiredValidator
                          onValidationStatusChange={onValidationStatusChange}
                          fieldName="validityStart"
                          isEnabled={isSubmit}
                          property="Validity Start"
                          value={model.validityStart}
                        />
                      </div>
                    </div>
                  )}
                  {model.validityStart && (
                    <div className="row mt-4">
                      <label
                        htmlFor="ValidityEnd"
                        className="col-md-4 form-check-label"
                      >
                        Validity End:
                      </label>
                      <div className="col-md-6">
                        <input
                          className="form-control mr-1 ValidityEnd"
                          type="datetime-local"
                          id="validityEnd"
                          placeholder="Enter Validity Start End"
                          value={model.validityEnd}
                          min={getDateTimeLocal(model.validityStart)}
                          disabled={!model.validityStart}
                          name="validityEnd"
                          onChange={onInputChange}
                          required={model.isActive}
                        />
                        <Validators.RequiredValidator
                          onValidationStatusChange={onValidationStatusChange}
                          fieldName="validityEnd"
                          isEnabled={isSubmit}
                          property="Validity End"
                          value={model.validityEnd}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-3">
                  <img
                    id="image"
                    className="img-thumbnail"
                    src={voucherImage}
                    alt="Voucher"
                  />
                </div>
              </div>
            </div>
          </div>
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-success"
              type="submit"
              id="btnRegister"
              data-toggle="modal"
              data-target="#Confirm"
              onClick={onShowConfirmDialog}
            >
              {model.isActive ? "Register & Activate" : "Register"}
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-danger"
              id="btnClear"
              onClick={onClear}
            >
              Clear
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </div>
      </div>
      <ConfirmDialog
        visible={showConfirmDialog}
        onCancel={onHideConfirmDialog}
        onProceed={register}
        message={message}
        title="Register Voucher"
      />
    </div>
  );
};
