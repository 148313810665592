import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BreadCrumb } from "../../common/BreadCrumb";
import { Movie } from "../../../models/Movie";
import {
  Country,
  CrewRole,
  MovieCategory,
  MovieDisplayWeight,
  MovieOrigin,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { MovieService } from "../../../services/MovieService";
import _ from "lodash";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { MovieReleaseListing } from "./MovieReleaseListing";
import { AddCrewModal } from "../modals/AddCrewModal";
import "../../css/components/accordion.css";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { toast } from "react-toastify";
import { CustomSelect } from "../../common/CustomSelect";
import { CompanyRestService } from "../../../services/CompanyRestService";
import { CompanyType } from "../../../constants/CompanyType";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import MovieDetailCard from "./MovieDetailCard";

export class MovieDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: new Movie({}),
      companies: [],
      languages: [],
      mode: Mode.Readonly,
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
      showAddCrewModal: false,
      showHistoryDialog: false,
    };
    this.movieService = new MovieService();
    this.companyService = new CompanyRestService();
  }

  loadData = () => {
    const id = this.props.match.params.id;
    this.movieService.get(
      id,
      { includes: "Company, MovieReleases" },
      (response) => {
        this.setState({ instance: new Movie(response) });
      },
      (error) => {
        toast.success(error.message);
      }
    );
  };

  componentDidMount() {
    this.loadData();
  }

  onModeChange = () => {
    const { mode } = this.state;
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    if (newMode === Mode.Editable) {
      this.companyService
        .list({ type: CompanyType.Distributors })
        .then((response) => {
          this.setState({ companies: response.data.data });
        });

      this.movieService.getLanguages((response) => {
        this.setState({ languages: response });
      });
    }
    this.setState({ mode: newMode });
  };

  onSubmit = () => {
    const { instance } = this.state;
    this.movieService.update(
      instance,
      (_) => {
        toast.success(`The movie has been updated successful.`);
        this.setState({
          mode: Mode.Readonly,
        });
      },
      (error) => {
        toast.error(error.message);
      }
    );
    this.closeAllModals();
  };

  onCancel = () => {
    this.loadData();
    this.setState({ mode: Mode.Readonly });
    this.closeAllModals();
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  onCompanyChange = (e) => {
    const instance = this.state.instance;
    instance.company.name = e.label;
    instance.company.id = e.value;
    instance.companyId = instance.company.id;
    this.setState({ instance });
  };

  renderTopButtons = () => {
    const { mode, instance } = this.state;
    if (mode !== Mode.Readonly) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-primary"
          onClick={() => {
            this.props.history.push(
              `/movies/${instance.id}/movie_releases/create`
            );
          }}
        >
          Add Movie Release
        </ButtonActionDetailGroup.Item>
        {AuthenticationManager.isAuthorized(ResourceID.MovieEditButtonPage) && (
          <ButtonActionDetailGroup.Item
            className="btn btn-success"
            onClick={this.onModeChange}
          >
            <i className="fas fa-edit" aria-hidden="true" />
            Edit Movie
          </ButtonActionDetailGroup.Item>
        )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          data-target="#HistoryPopup"
          data-toggle="modal"
          onClick={() => this.setState({ showHistoryDialog: true })}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  renderBottomButtons = () => {
    const { mode } = this.state;
    if (mode !== Mode.Editable) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showSaveChangesConfirmation: true })}
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showCancelConfirmation: true })}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  closeAllModals = () => {
    this.setState({
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
    });
  };

  onAddCrew = (crew) => {
    const { instance } = this.state;
    const found = instance.crews.find(
      (x) => x.name === crew.name && x.role === crew.role
    );
    if (!_.isNil(found)) {
      return;
    }
    instance.crews.push(crew);
    this.setState({ instance });
  };

  onRemoveCrew = (crew) => {
    const { instance } = this.state;
    instance.crews = instance.crews.filter(
      (x) => x.name !== crew.name || x.role !== crew.role
    );
    this.setState({ instance });
  };

  renderMovieCrew = () => {
    const { instance, mode } = this.state;
    return (
      <div className="card mb-3">
        <div className="card-body Movie Crew-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Movie Crew</span>
            {mode === Mode.Editable && (
              <button
                className="ml-auto btn btn-sm btn-success"
                onClick={() => this.setState({ showAddCrewModal: true })}
                data-toggle="modal"
              >
                Add
              </button>
            )}
          </div>
          {!_.isNil(instance.crews) &&
            instance.crews.map((x, index) => {
              return (
                <div key={index} className="card card-body bg-light mb-1 py-1">
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew:</label>
                    </div>
                    <div className="col-md-6 mb-3 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">{x.name}</label>
                    </div>
                  </div>
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew Role:</label>
                    </div>
                    <div className="col-md-6 mb-3 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">
                        {CrewRole.getName(x.role)}
                      </label>
                    </div>
                    <div className="col-md-2 py-1">
                      {mode === Mode.Editable && (
                        <button
                          className="float-right btn btn-sm btn-danger"
                          onClick={() => this.onRemoveCrew(x)}
                        >
                          <i className="fas fa-trash-alt" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderReadonlyView = () => {
    const { instance } = this.state;
    return (
      <>
        <div className="row">
          <div className="col">
            <MovieDetailCard movie={instance}>
              {this.renderMovieCrew()}
            </MovieDetailCard>
          </div>
        </div>
        <MovieReleaseListing data={instance.movieReleases} />
      </>
    );
  };

  renderEditableView = () => {
    const { instance, companies, languages } = this.state;
    return (
      <>
        <div className="row">
          <div className="col">
            <div className="card" id="movieInfo">
              <div className="card-header accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseMovieInfo"
                  aria-expanded="true"
                  aria-controls="collapseMovieInfo"
                >
                  Movie Information
                </button>
              </div>
              <div
                className="card-body info accordion-collapse collapse show"
                id="collapseMovieInfo"
                aria-labelledby="movieInfoHeader"
                data-bs-parent="#movieInfo"
              >
                <div className="accordion-body">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        ID:<span className="color-red">*</span>
                      </label>
                      <input
                        className="form-control display-input"
                        placeholder="ID"
                        name="primaryTitle"
                        value={instance.id}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Primary Title:<span className="color-red">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter Primary Title"
                        name="primaryTitle"
                        onChange={this.onInputChange}
                        value={instance.primaryTitle}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Secondary Title:<span className="color-red">*</span>
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter Secondary Title"
                        name="secondaryTitle"
                        onChange={this.onInputChange}
                        value={instance.secondaryTitle}
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Movie Category:<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        valuetype="number"
                        name="movieCategory"
                        onChange={this.onInputChange}
                        value={instance.movieCategory}
                      >
                        {MovieCategory.All.map((x) => {
                          return (
                            <option key={x.value} value={x.value}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-md-6 mb-3"
                      title="You may key in # 1 if the Duration is not yet final"
                    >
                      <label className="form-control-label d-flex justify-content-between">
                        <span>
                          Duration: <span className="color-red">*</span>
                        </span>
                        <i
                          class="fa fa-exclamation-circle"
                          aria-hidden="true"
                        ></i>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min="1"
                        max="200"
                        valuetype="number"
                        name="duration"
                        onChange={this.onInputChange}
                        value={instance.duration}
                        placeholder="Enter Duration"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Movie Origin Country:
                        <span className="color-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="movieOriginCountry"
                        valuetype="number"
                        onChange={this.onInputChange}
                        value={instance.movieOriginCountry}
                      >
                        {Country.All.map((x) => {
                          return (
                            <option key={x.value} value={x.value}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Movie Origin:<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="movieOrigin"
                        valuetype="number"
                        onChange={this.onInputChange}
                        value={instance.movieOrigin}
                      >
                        {MovieOrigin.All.map((x) => {
                          return (
                            <option key={x.value} value={x.value}>
                              {x.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Movie Distribution Company:
                        <span className="color-red">*</span>
                      </label>
                      <CustomSelect
                        selectedValue={instance.companyId}
                        data={companies}
                        label="name"
                        value="id"
                        compareFunc={(x, y) => x.value - y.value}
                        onChange={this.onCompanyChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Movie Language:<span className="color-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="language"
                        onChange={this.onInputChange}
                        value={instance.language}
                      >
                        {languages.map((x) => (
                          <option key={x.id} value={x.id}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Label Display:
                      </label>
                      <input
                        className="form-control"
                        name="labelDisplay"
                        onChange={this.onInputChange}
                        value={instance.labelDisplay}
                        placeholder="Enter Label Display"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">Label Print:</label>
                      <input
                        className="form-control"
                        name="labelPrint"
                        onChange={this.onInputChange}
                        value={instance.labelPrint}
                        placeholder="Enter print label"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">
                        Display Weight:<span className="color-red">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        min={MovieDisplayWeight.Length}
                        valuetype="number"
                        name="displayWeight"
                        onChange={this.onInputChange}
                        value={instance.displayWeight}
                        max="150"
                        placeholder="Enter Display Weight"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-control-label">Website Url:</label>
                      <input
                        className="form-control"
                        name="websiteUrl"
                        onChange={this.onInputChange}
                        value={instance.websiteUrl}
                        placeholder="Enter website"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-check-label">Notice:</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="notice"
                        onChange={this.onInputChange}
                        value={instance.notice}
                        placeholder="Enter notice"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-check-label">
                        Short synopsis:
                      </label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="shortSynopsis"
                        onChange={this.onInputChange}
                        value={instance.shortSynopsis}
                        placeholder="Enter short synopsis"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label className="form-check-label">Full synopsis:</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        name="fullSynopsis"
                        onChange={this.onInputChange}
                        value={instance.fullSynopsis}
                        placeholder="Enter full synopsis"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      {this.renderMovieCrew()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  getNavigationSettings = () => {
    const { mode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-details",
        name: mode === Mode.Editable ? "Edit Movie" : "View Movie",
      }),
    });
  };

  render() {
    const {
      instance,
      mode,
      showSaveChangesConfirmation,
      showCancelConfirmation,
      showHistoryDialog,
      showAddCrewModal,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.renderTopButtons()}
              {mode === Mode.Readonly
                ? this.renderReadonlyView()
                : this.renderEditableView()}
              {this.renderBottomButtons()}
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showSaveChangesConfirmation}
          title={GenericMessages.SaveChangesDialogTitle}
          onProceed={this.onSubmit}
          onCancel={() => this.setState({ showSaveChangesConfirmation: false })}
          message="The changes will effect on the corresponding Movie Releases and Performances. Are you sure you want to save changes?"
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          title={GenericMessages.CancelChangesDialogTitle}
          onProceed={this.onCancel}
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          message={GenericMessages.CancelChangesDialogMessage}
        />
        <AddCrewModal
          visible={showAddCrewModal}
          onCancel={() => this.setState({ showAddCrewModal: false })}
          onProceed={(crew) => this.onAddCrew(crew)}
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId={instance.id}
            onClose={() => this.setState({ showHistoryDialog: false })}
            url="/api/v1/history_logs"
            objectType={ObjectType.Movie}
          />
        )}
      </>
    );
  }
}
