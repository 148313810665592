import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledKinoLoungeMovieListing extends BaseListing {
  getApiPath = () => "/api/v1/scheduled_kinolounge_movies";

  getDefaultSort = () => "-PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "schedule_kinolounge_movies",
        name: "Kinolounge Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "schedule-kinolounge-movies-details",
        name: "Scheduled Kinolounge Movies",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/scheduled_kinolounge_movies/schedules/${id}`);
  };

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Film Id</th>
            <th>Movie Title</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>Display Start On</th>
            <th>Display End On</th>
            <th>
              Created On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Published On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PublishedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.movieId}</td>
                  <td>{item.movieTitle}</td>
                  <td>{item.displayWeight}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.createdOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.publishedOn
                    )}
                    </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </React.Fragment>
    );
  }
}
