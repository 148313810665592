import React from "react";
import {
  DateHelper,
  ImageHelper,
  StringHelper,
} from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { toast } from "react-toastify";
import { PreviewImageDialog } from "../../../common/PreviewImageDialog";
import _ from "lodash";
import { Classify } from "../../../../constants/MovieConstants";
import { RowFormField } from "../../../layout/FormLayout";

export class KinoLoungeMovieDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      instance: this.mergeDefaultValue(props.instance),
      validations: [],
      showPreview: false,
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  loadMovieDetail(movieId) {
    RestClient.sendGetRequest(
      `https://kinolounge.shaw.sg/services/meta/v2/film/${movieId}/show_multiple`,
      (response) => {
        if (response.length === 0) {
          toast.error("Movie not found.");
          return;
        }
        const movie = response[0];
        const classify = Classify.All.find(
          (x) => x.code === movie.classifications.sg.label
        );
        const newModel = Object.assign(this.state.instance, {
          classify: classify.value,
          duration: movie.runtime,
          movieTitle: movie.title,
          posterUrl: movie.image_urls.portrait,
        });
        toast.success(`Movie ${movieId} info filled.`);
        this.setState({
          state: newModel,
        });
      },
      (error) => toast.error(error.message)
    );
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      classify: value.classify || 0,
      collectionDescription: value.collectionDescription || "",
      displayEndOn: value.displayEndOn || "",
      displayStartOn: value.displayStartOn || "",
      displayWeight: value.displayWeight || 0,
      isCollection: value.isCollection || false,
      movieId: value.movieId || 0,
      duration: value.duration || 0,
      movieTitle: value.movieTitle || "",
      movieUrl: value.movieUrl || "",
      posterUrl: value.posterUrl || "",
    };
  }

  onFetchClick = () => {
    const { instance } = this.state;
    const { movieId } = instance;
    if (!movieId) {
      toast.error("Movie ID is required to fetch.");
      return;
    }
    this.loadMovieDetail(movieId);
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const instance = Object.assign({}, this.state.instance);
      onModelChange(instance);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const type = e.target.getAttribute("type");
    let value = e.target.value;
    if (type === "number") {
      value = +value;
    }
    const newModel = Object.assign({}, this.state.instance, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, instance: newModel }, this.onModelChange);
  };

  onIsCollectionChange = (e) => {
    const fieldName = e.target.getAttribute("fieldName");
    const value = e.target.checked;
    const { instance } = this.state;
    const ignoreFields = ["movieId", "duration"];
    let { validations } = this.state;
    if (value) {
      validations = validations.filter(
        (item) => !ignoreFields.includes(item.fieldName)
      );
    }
    instance[fieldName] = value;
    this.setState({ instance, validations }, this.onValidationsChange);
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("fieldName");
    const valueType = e.target.getAttribute("valuetype");
    const { instance } = this.state;
    let selectValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    if ("number" === valueType && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    instance[fieldName] = selectValue;
    this.setState({ instance }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const instance = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          instance,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    return (
      <Card
        title={
          this.state.cardMode === CardMode.Create
            ? "Kinolounge Movie Creation"
            : "Kinolounge Movie Details"
        }
      >
        {this.renderCardSwitch()}
      </Card>
    );
  }

  renderCardSwitch() {
    switch (this.state.cardMode) {
      case CardMode.View:
        return this.renderViewMode();
      case CardMode.Create:
        return this.renderCreateMode();
      case CardMode.Update:
        return this.renderUpdateMode();
    }
  }

  renderCreateMode() {
    const { isSubmit } = this.props;
    const { showPreview } = this.state;
    const {
      collectionDescription,
      classify,
      displayEndOn,
      displayStartOn,
      displayWeight,
      isCollection,
      movieId,
      duration,
      movieTitle,
      movieUrl,
      posterUrl,
    } = this.state.instance;

    return (
      <div className="row">
        <div className="col-md-6">
          <RowFormField label="Is Collection:" htmlFor="checkbox1">
            <input
              type="checkbox"
              name="isCollection"
              id="isCollection"
              className="form-check-input checkbox1"
              fieldname="isCollection"
              onChange={this.onIsCollectionChange}
              checked={isCollection}
            />
          </RowFormField>
          {!isCollection && (
            <RowFormField
              label={
                <>
                  Movie ID:<span className="color-red">*</span>
                </>
              }
              htmlFor="movieId"
            >
              <div className="d-flex">
                <div className="flex-fill">
                  <input
                    className={this.getControlClassName("movieId")}
                    id="movieId"
                    type="text"
                    placeholder="Enter Movie ID"
                    fieldname="movieId"
                    onChange={this.onTextBoxChange}
                    value={movieId}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="movieId"
                    isEnabled={isSubmit}
                    property="Movie ID"
                    value={movieId}
                  />
                </div>
                <button
                  className="btn btn-primary float-right"
                  onClick={this.onFetchClick}
                >
                  Fetch
                </button>
              </div>
            </RowFormField>
          )}
          <RowFormField
            label={isCollection ? "Title:" : "Movie Title:"}
            htmlFor="movieTitle"
          >
            <input
              className={this.getControlClassName("movieTitle")}
              id="movieTitle"
              type="text"
              placeholder={isCollection ? "Enter Title" : "Enter Movie Title"}
              fieldname="movieTitle"
              onChange={this.onTextBoxChange}
              value={movieTitle}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="movieTitle"
              isEnabled={isSubmit}
              property="Movie Title"
              value={movieTitle}
            />
          </RowFormField>
          {isCollection && (
            <RowFormField label="Collection Description:">
              <textarea
                id="description"
                className={this.getControlClassName(
                  "collectionDescription",
                  "resize-none"
                )}
                rows="8"
                maxLength="250"
                placeholder="Enter Description"
                fieldname="collectionDescription"
                onChange={this.onTextBoxChange}
                value={collectionDescription}
              />
              <label className="form-control-label float-right" htmlFor="Desc">
                <small>
                  <span className="counter">
                    {collectionDescription.length}
                  </span>
                  /250
                </small>
              </label>
              <Validators.MaxLengthValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="collectionDescription"
                isEnabled={isSubmit}
                maxLength="250"
                property="Collection Description"
                value={collectionDescription}
              />
            </RowFormField>
          )}
          <RowFormField
            label={
              <>
                Display Start On:<span className="color-red">*</span>
              </>
            }
            htmlFor="StartDatetime"
          >
            <input
              className={this.getControlClassName("displayStartOn")}
              id="StartDatetime"
              type="datetime-local"
              fieldname="displayStartOn"
              onChange={this.onTextBoxChange}
              value={displayStartOn}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayStartOn"
              isEnabled={isSubmit}
              property="Display Start On"
              value={displayStartOn}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display End On:<span className="color-red">*</span>
              </>
            }
            htmlFor="EndDatetime"
          >
            <input
              className={this.getControlClassName("displayEndOn")}
              id="EndDatetime"
              type="datetime-local"
              fieldname="displayEndOn"
              onChange={this.onTextBoxChange}
              value={displayEndOn}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndOn"
              isEnabled={isSubmit}
              property="Display End On"
              value={displayEndOn}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndOn"
              isEnabled={isSubmit}
              fromProperty="Display End On"
              fromValue={displayStartOn}
              toProperty="Display Start On"
              toValue={displayEndOn}
            />
          </RowFormField>
          {!isCollection && (
            <>
              <RowFormField
                label={
                  <>
                    Movie Runtime:<span className="color-red">*</span>
                  </>
                }
                htmlFor="Runtime"
              >
                <input
                  className={this.getControlClassName("duration")}
                  id="Runtime"
                  type="number"
                  placeholder="Enter Movie Runtime"
                  fieldname="duration"
                  onChange={this.onTextBoxChange}
                  value={duration}
                />
                <Validators.RangeValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="duration"
                  isEnabled={isSubmit}
                  min="1"
                  max="999"
                  property="Movie Runtime"
                  value={duration}
                  allowDecimal={true}
                />
              </RowFormField>
              <RowFormField label="Movie Rating:" htmlFor="Rating">
                <select
                  className={this.getControlClassName("classify")}
                  id="Rating"
                  type="number"
                  fieldname="classify"
                  onChange={this.onTextBoxChange}
                  value={classify}
                >
                  <option value={0}>Select Movie Rating</option>
                  {Classify.All.map((x) => (
                    <option value={x.value} key={x.value}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </RowFormField>
            </>
          )}
        </div>
        <div className="col-md-6">
          <div className="row form-group">
            <label
              className="col-12 col-lg-4 form-control-label"
              htmlFor="posterUrl"
            >
              Poster Url:<span className="color-red">*</span>
            </label>
            <div className="col pr-0">
              <input
                className={this.getControlClassName("posterUrl")}
                type="text"
                id="posterUrl"
                placeholder="Enter Poster Url"
                fieldname="posterUrl"
                onChange={this.onTextBoxChange}
                value={posterUrl}
              />
            </div>
            <div className="col-4 col-md-3 col-lg-2">
              <button
                className="btn btn-primary float-right"
                onClick={() => this.setState({ showPreview: true })}
                disabled={_.isEmpty(posterUrl)}
              >
                Preview
              </button>
            </div>
          </div>
          <RowFormField
            label={isCollection ? "Collection Url:" : "Movie Url:"}
            htmlFor="movieUrl"
          >
            <input
              className={this.getControlClassName("movieUrl")}
              id="movieUrl"
              type="text"
              placeholder={
                isCollection ? "Enter Collection Url" : "Enter Movie Url"
              }
              fieldname="movieUrl"
              onChange={this.onTextBoxChange}
              value={movieUrl}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="movieUrl"
              isEnabled={isSubmit}
              property="Movie Url"
              value={movieUrl}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Weight:<span className="color-red">*</span>
              </>
            }
            htmlFor="Displayweight"
          >
            <input
              className={this.getControlClassName("displayWeight")}
              id="Displayweight"
              type="number"
              placeholder="Enter Display Weight"
              fieldname="displayWeight"
              onChange={this.onTextBoxChange}
              value={displayWeight}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayWeight"
              isEnabled={isSubmit}
              min="1"
              max="100"
              property="Display Weight"
              value={displayWeight}
              allowDecimal={true}
            />
          </RowFormField>
        </div>
        <PreviewImageDialog
          url={posterUrl}
          visible={showPreview}
          onClose={() => this.setState({ showPreview: false })}
        />
      </div>
    );
  }

  renderUpdateMode() {
    const { isSubmit } = this.props;
    const { showPreview } = this.state;
    const {
      collectionDescription,
      classify,
      displayEndOn,
      displayStartOn,
      displayWeight,
      isCollection,
      movieId,
      duration,
      movieTitle,
      movieUrl,
      posterUrl,
    } = this.state.instance;
    return (
      <div className="row">
        <div className="col-md-6">
          <RowFormField label="Is Collection:" htmlFor="checkbox1">
            <input
              type="checkbox"
              className="form-check-input checkbox1 ml-0"
              fieldName="isCollection"
              onChange={this.onInputChange}
              checked={isCollection}
            />
          </RowFormField>
          {!isCollection && (
            <RowFormField label="Movie ID:" htmlFor="movieId">
              <input
                className={this.getControlClassName("movieId")}
                id="movieId"
                type="text"
                placeholder="Enter Movie ID"
                fieldname="movieId"
                onChange={this.onTextBoxChange}
                value={movieId}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="movieId"
                isEnabled={isSubmit}
                property="Film ID"
                value={movieId}
              />
            </RowFormField>
          )}
          <RowFormField
            labe={isCollection ? "Title:" : "Movie Title:"}
            htmlFor="movieTitle"
          >
            <input
              className={this.getControlClassName("movieTitle")}
              id="movieTitle"
              type="text"
              placeholder="Enter Movie Title"
              fieldname="movieTitle"
              onChange={this.onTextBoxChange}
              value={movieTitle}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="movieTitle"
              isEnabled={isSubmit}
              property="Movie Title"
              value={movieTitle}
            />
          </RowFormField>
          {isCollection && (
            <RowFormField label="Collection Description:">
              <textarea
                id="description"
                className={this.getControlClassName(
                  "collectionDescription",
                  "resize-none"
                )}
                rows="8"
                maxLength="250"
                placeholder="Enter Description"
                fieldname="collectionDescription"
                onChange={this.onTextBoxChange}
                value={collectionDescription}
              />
              <label className="form-control-label float-right" htmlFor="Desc">
                <small>
                  <span className="counter">
                    {collectionDescription.length}
                  </span>
                  /250
                </small>
              </label>
              <Validators.MaxLengthValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="collectionDescription"
                isEnabled={isSubmit}
                maxLength="250"
                property="Collection Description"
                value={collectionDescription}
              />
            </RowFormField>
          )}
          <RowFormField label="Display Start On:" htmlFor="StartDatetime">
            <input
              className={this.getControlClassName("displayStartOn")}
              id="StartDatetime"
              type="datetime-local"
              fieldname="displayStartOn"
              onChange={this.onTextBoxChange}
              value={displayStartOn}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayStartOn"
              isEnabled={isSubmit}
              property="Display Start On"
              value={displayStartOn}
            />
          </RowFormField>
          <RowFormField label="Display End On:" htmlFor="EndDatetime">
            <input
              className={this.getControlClassName("displayEndOn")}
              id="EndDatetime"
              type="datetime-local"
              fieldname="displayEndOn"
              onChange={this.onTextBoxChange}
              value={displayEndOn}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndOn"
              isEnabled={isSubmit}
              property="Display End On"
              value={displayEndOn}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndOn"
              isEnabled={isSubmit}
              fromProperty="Display Start On"
              fromValue={displayEndOn}
              toProperty="Display End On"
              toValue={displayEndOn}
            />
          </RowFormField>
          {!isCollection && (
            <>
              <RowFormField label="Movie Runtime:" htmlFor="Runtime">
                <input
                  className={this.getControlClassName("duration")}
                  id="Runtime"
                  type="text"
                  placeholder="Enter Movie Runtime"
                  fieldname="duration"
                  onChange={this.onTextBoxChange}
                  value={duration}
                />
                <Validators.RangeValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="duration"
                  isEnabled={isSubmit}
                  min="1"
                  max="999"
                  property="Movie Runtime"
                  value={duration}
                  allowDecimal={true}
                />
              </RowFormField>
              <RowFormField label="Movie Rating:" htmlFor="Rating">
                <select
                  className={this.getControlClassName("classify")}
                  id="Rating"
                  type="number"
                  fieldname="classify"
                  onChange={this.onTextBoxChange}
                  value={classify}
                >
                  {Classify.All.map((x) => (
                    <option key={x.code} value={x.value}>
                      {x.name}
                    </option>
                  ))}
                </select>
              </RowFormField>
            </>
          )}
        </div>
        <div className="col-md-6">
          <div className="row form-group">
            <label
              className="col-12 col-lg-4 form-control-label"
              htmlFor="posterUrl"
            >
              Poster Url:
            </label>
            <div className="col pr-0">
              <input
                className={this.getControlClassName("posterUrl")}
                type="text"
                id="posterUrl"
                placeholder="Enter Poster Url"
                fieldname="posterUrl"
                onChange={this.onTextBoxChange}
                value={posterUrl}
              />
            </div>
            <div className="col-4 col-md-3 col-lg-2">
              <button
                className="btn btn-primary float-right"
                onClick={() => this.setState({ showPreview: true })}
                disabled={_.isEmpty(posterUrl)}
              >
                Preview
              </button>
            </div>
          </div>
          <RowFormField
            label={isCollection ? "Collection Url:" : "Movie Url:"}
            htmlFor="movieUrl"
          >
            <input
              className={this.getControlClassName("movieUrl")}
              id="movieUrl"
              type="text"
              placeholder="Enter Movie Url"
              fieldname="movieUrl"
              onChange={this.onTextBoxChange}
              value={movieUrl}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="movieUrl"
              isEnabled={isSubmit}
              property="Movie Url"
              value={movieUrl}
            />
          </RowFormField>
          <RowFormField label="Display Weight:" htmlFor="Displayweight">
            <input
              className={this.getControlClassName("displayWeight")}
              id="Displayweight"
              type="text"
              placeholder="Enter Display Weight"
              fieldname="displayWeight"
              onChange={this.onTextBoxChange}
              value={displayWeight}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayWeight"
              isEnabled={isSubmit}
              min="1"
              max="100"
              property="Display Weight"
              value={displayWeight}
              allowDecimal={true}
            />
          </RowFormField>
        </div>
        <PreviewImageDialog
          url={posterUrl}
          visible={showPreview}
          onClose={() => this.setState({ showPreview: false })}
        />
      </div>
    );
  }

  renderViewMode() {
    const {
      collectionDescription,
      classify,
      displayEndOn,
      displayStartOn,
      displayWeight,
      isCollection,
      movieId,
      duration,
      movieTitle,
      movieUrl,
      posterUrl,
    } = this.state.instance;
    return (
      <div className="row">
        <div className="col-md-6">
          <RowFormField label="Is Collection:">
            <input
              type="checkbox"
              name="checkbox5"
              className="form-check-input"
              readOnly={true}
              checked={isCollection}
            />
          </RowFormField>
          {!isCollection && (
            <RowFormField label="Movie ID:">
              <input
                className="form-control display-input"
                type="text"
                placeholder="Enter Movie ID"
                readOnly={true}
                value={movieId}
              />
            </RowFormField>
          )}
          <RowFormField label="Movie Title:">
            <input
              className="form-control display-input"
              type="text"
              placeholder="Enter Movie Title"
              readOnly={true}
              value={movieTitle}
            />
          </RowFormField>
          <RowFormField label="Display Start On:">
            <input
              className="form-control display-input"
              type="text"
              readOnly={true}
              placeholder={DateHelper.DisplayDateTimePlaceholder}
              value={StringHelper.asDateStringToDisplayDateTimeFormat(
                displayStartOn
              )}
            />
          </RowFormField>
          <RowFormField label="Display End On:">
            <input
              className="form-control display-input"
              type="text"
              readOnly={true}
              placeholder={DateHelper.DisplayDateTimePlaceholder}
              value={StringHelper.asDateStringToDisplayDateTimeFormat(
                displayEndOn
              )}
            />
          </RowFormField>
          {!isCollection && (
            <>
              <RowFormField label="Movie Runtime:">
                <input
                  className="form-control display-input"
                  type="text"
                  placeholder="Enter Movie Runtime"
                  readOnly={true}
                  value={duration}
                />
              </RowFormField>
              <RowFormField label="Movie Rating:">
                <input
                  className="form-control display-input"
                  type="text"
                  placeholder="Enter Rating"
                  readOnly={true}
                  value={Classify.getName(classify)}
                />
              </RowFormField>
            </>
          )}
          <RowFormField label="Display Weight:">
            <input
              className="form-control display-input"
              type="text"
              placeholder="Enter Display Weight"
              readOnly={true}
              value={displayWeight}
            />
          </RowFormField>
          {isCollection && (
            <RowFormField label="Collection Description:">
              <textarea
                className="form-control display-input resize-none"
                rows="6"
                placeholder="Enter Description"
                maxlength="250"
                readOnly={true}
                value={collectionDescription}
              />
            </RowFormField>
          )}
        </div>
        <div className="col-md-6">
          <div className="row form-group">
            <div className="col-4">
              <img
                className="posterImg"
                src={ImageHelper.getPosterUrl(posterUrl)}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
          <div className="row form-group">
            <label className="col-4 form-control-label">Poster Url:</label>
            <div className="col-8 pl-0">
              <textarea
                className="form-control display-input resize-none"
                rows="5"
                placeholder="Enter Description"
                readOnly={true}
                value={posterUrl}
              />
            </div>
          </div>
          <div className="row form-group">
            <label className="col-4 form-control-label">
              {isCollection ? "Collection Url" : "Movie Url"}:
            </label>
            <div className="col-8 pl-0">
              <textarea
                className="form-control display-input resize-none"
                rows="5"
                placeholder="Enter Description"
                readOnly={true}
                value={movieUrl}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
