import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignExtensionPropertiesSelector,
  campaignRedemptionLocationsSelector,
  locationsAtom,
  quantityLocationSpecificSelector,
} from "../../CampaignState";
import { QuantityMechanicsType } from "../../../../constants/CampaignConstants";
import _ from "lodash";
import { NumberInput } from "../../NumberInput";
import { QuantityLimitTypeCard } from "./QuantityLimitTypeCard";

export const LocationSpecificCard = (props) => {
  const [data, setData] = useRecoilState(quantityLocationSpecificSelector);
  const campaignRedemptionLocations = useRecoilValue(
    campaignRedemptionLocationsSelector
  );
  const setCampaign = useSetRecoilState(campaignAtom);
  const campaignExtensionProperties = useRecoilValue(
    campaignExtensionPropertiesSelector
  );
  const locations = useRecoilValue(locationsAtom);

  const { disabled } = props;

  const onInputChange = (e) => {
    let clonedData = _.clone(data);
    const key = `${e.target.value}`;
    if (e.target.checked) {
      clonedData[key] = 0;
    } else {
      delete clonedData[key];
    }
    setData(clonedData);
  };

  const onInputValueChange = (e) => {
    let clonedData = _.clone(data);
    const fieldName = e.target.getAttribute("name");
    clonedData[fieldName] = +e.target.value;
    setData(clonedData);
  };

  return (
    <>
      <div>
        <input
          className="mr-1"
          type="radio"
          valuetype="number"
          name="quantityMechanicsType"
          value={QuantityMechanicsType.LocationSpecificQuantity}
          onChange={(e) => {
            setCampaign((instance) => {
              return {
                ...instance,
                quantityMechanicsType: +e.target.value,
                campaignQuantityAvailability: [],
              };
            });
          }}
          checked={
            QuantityMechanicsType.LocationSpecificQuantity ===
            campaignExtensionProperties.quantityMechanicsType
          }
          disabled={disabled}
        />
        <label className="form-check-label">Location Specific Quantity</label>
      </div>
      {campaignExtensionProperties.quantityMechanicsType ===
        QuantityMechanicsType.LocationSpecificQuantity && (
        <>
          <div className="row mt-3">
            <div className="col-md-6">
              <QuantityLimitTypeCard disabled={disabled} />
            </div>
          </div>
          <div className="row">
            {locations.map((x, index) => {
              const locationId = `${x.id}`;
              return (
                <div className="col-md-6 mb-2" key={index}>
                  <div className="row">
                    <div className="col-md-8">
                      <input
                        className="mr-1"
                        type="checkbox"
                        value={locationId}
                        onChange={onInputChange}
                        disabled={
                          disabled ||
                          !campaignRedemptionLocations.includes(x.id)
                        }
                        checked={locationId in data}
                      />
                      <label className="form-check-label">{x.name}</label>
                    </div>
                    <div className="col-md-4">
                      <NumberInput
                        name={x.id}
                        value={data[locationId] ?? 0}
                        onChange={onInputValueChange}
                        placeholder="Value"
                        disabled={disabled || !(locationId in data)}
                        min={0}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
