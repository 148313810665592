import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import { formatPromotionCategoryName } from "../common/WebsiteManagementConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledPromotionListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
  }

  getApiPath = () => "/api/v1/scheduled_promotions";

  getDefaultSort = () => "+PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "promotions",
        name: "Promotions",
      }),
      activeModule: new NavigationItem({
        identifier: "scheduled-promotion",
        name: "Scheduled Promotion",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/promotions/schedules/${id}`);
  };

  injectSearchTerm(queryParameters) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : this.state.name;
    return super.injectSearchTerm(queryParameters, { name });
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>Display Weight</th>
            <th>Updated On</th>
            <th>
              Published On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PublishedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>{formatPromotionCategoryName(item.category)}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndTime
                    )}
                  </td>
                  <td>{item.weightDisplay}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.publishedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </React.Fragment>
    );
  }

  generateExtendedComponents() {
    return (
      <div className="row mb-3">
        <div className="col-md-6 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="name"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onTextBoxChange}
            value={this.state.name}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true"></i> Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}
