import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledLocationListing extends BaseListing {
  getApiPath = () => "/api/v1/scheduled_locations";

  getDefaultSort = () => "-PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "locations",
        name: "Locations",
      }),
      activeModule: new NavigationItem({
        identifier: "schedule-location-details",
        name: "Scheduled Location",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/locations/schedules/${id}`);
  };

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Location Name</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>Display Weight</th>
            <th>Updated By</th>
            <th>Updated On</th>
            <th>
              Published On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PublishedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.displayStartTime
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.displayEndTime
                      )}
                    </td>
                    <td>{item.displayWeight}</td>
                    <td>{item.updatedBy}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.updatedOn
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.publishedOn
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </React.Fragment>
    );
  }
}
