export class Constants {
    static SearchBy = {
        ReferenceNumber: 1,
        CreditCard: 2,
        ContactNumber: 3,
        EmailAddress: 4
    }
    
    static SearchFrom = {
        Live: 1,
        Historical: 2
    }
    
}