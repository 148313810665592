import React from "react";
import RestClient from "../../../../common/RestClient";
import { CardMode } from "../../CardMode";
import { ProductComboCategories } from "../../Constants";

export class ProductCombos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      cardMode: CardMode.View,
      productCombos: [],
      selectedProductId: 0,
      quantity: 0,
      productContentName: "",
      backUpProducts: [],
    };
  }

  resetModel() {
    this.setState({
      selectedProductId: 0,
      quantity: 0,
      productContentName: "",
    });
  }

  createNewContent = () => {
    return {
      productId: this.props.productId,
      productContentId: this.state.selectedProductId,
      quantity: this.state.quantity,
      productContentName: this.state.productContentName,
    };
  };

  componentDidMount = () => {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    if (this.props.model) {
      this.setState({ productCombos: this.props.model });
    }

    this.getProductList();
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.cardMode !== nextProps.cardMode) {
      this.setState({ cardMode: nextProps.cardMode });
    }
    if (this.props.productCombos?.length !== nextProps.productCombos?.length) {
      this.setState({ productCombos: nextProps.productCombos })
    }
    return true;
  }

  removeComboContent = (index) => {
    let comboContentList = this.state.productCombos;
    comboContentList.splice(index, 1);
    this.setState(
      { productCombos: comboContentList },
      this.onComboContentChange
    );
    this.reverseProductList();
  };

  getProductList = () => {
    const queryParameter = { categories: ProductComboCategories.ComboRange };
    RestClient.sendGetRequestWithParameters(
      "/api/v1/products",
      queryParameter,
      (response) => {
        this.setState({
          products: [...response.data],
          backUpProducts: [...response.data],
        });
        this.reverseProductList();
      }
    );
  };

  reverseProductList = () => {
    const productList = this.state.backUpProducts;
    const comboContentList = this.state.productCombos;

    if (comboContentList.length === 0) {
      this.setState({ products: [...productList] });
    } else {
      const resultList = productList.filter(
        (x) => !comboContentList.some((y) => y.productContentId === x.id)
      );
      this.setState({ products: resultList });
    }
  };

  onProductChanged = (e) => {
    const value = parseInt(e.target.value);
    const productName = this.state.products.find((x) => x.id === value)?.name;

    this.setState({
      selectedProductId: value,
      productContentName: productName,
    });
  };

  onQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    this.setState({ quantity: value });
  };

  onAddProductContent = (e) => {
    const newContent = this.createNewContent();
    const selectedProdId = newContent.productContentId;
    let productCombos = this.state.productCombos;
    productCombos.push(newContent);
    this.setState({ productCombos: productCombos }, this.onComboContentChange);

    const productList = this.state.products;
    const indexOfSelected = productList.findIndex(
      (x) => x.id === selectedProdId
    );
    if (indexOfSelected >= 0) {
      productList.splice(indexOfSelected, 1);
      this.setState({ products: productList });
    }
    this.resetModel();
  };

  onComboContentChange = () => {
    const productCombos = Object.assign([], this.state.productCombos);
    this.props.onComboContentChange(productCombos);
  };

  render() {
    const { productCombos } = this.state;
    return (
      <>
        <div className="row form-group">
          <div className="col-3 p-l-30">
            <strong>Product</strong>
          </div>
          <div className="col-1">
            <strong>Quantity</strong>
          </div>
        </div>

        {productCombos &&
          productCombos.map((item, index) => {
            return (
              <div
                key={`${item.id}_${index}`}
                id="BundleContentDv"
                className="row form-group"
              >
                <div className="col-3 p-l-30">{item.productContentName}</div>
                <div className="col-1 p-l-30">{item.quantity}</div>
                {CardMode.isEditMode(this.state.cardMode) && (
                  <button
                    className="btn btn-danger"
                    onClick={() => this.removeComboContent(index)}
                  >
                    <i className="far fa-trash-alt" />
                  </button>
                )}
              </div>
            );
          })}

        {CardMode.isEditMode(this.state.cardMode) && (
          <div className="row form-group">
            <div className="col-3">
              <select
                className="form-control"
                id="SelectBundleContent"
                value={this.state.selectedProductId}
                onChange={this.onProductChanged}
              >
                <option key={0} value={0}>
                  Select a combo content
                </option>
                {this.state.products &&
                  this.state.products.map((item, index) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="col-1 pl-0">
              <input
                className="form-control"
                placeholder="Quantity"
                id="contentqty"
                value={this.state.quantity}
                onChange={this.onQuantityChange}
                type="number"
                min="1"
              />
            </div>
            <button
              className="btn btn-primary"
              disabled={
                this.state.selectedProductId === 0 || this.state.quantity === 0
              }
              onClick={this.onAddProductContent}
            >
              <i className="fas fa-plus-circle"></i> Add
            </button>
          </div>
        )}
      </>
    );
  }
}
