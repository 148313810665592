import _ from "lodash";
import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import { CardMode } from "./CardMode";
import { toast } from "react-toastify";

export class UserProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      departmentOptions: [],
      designationOptions: [],
      isDirty: false,
      model: this.mergeDefaultValue(),
      validations: [],
    };
  }

  componentDidMount() {
    const { cardMode } = this.props;
    if (cardMode) {
      this.setState({ cardMode });
    }
    this.getDepartmentOptions("");
    this.getDesignationOptions(AuthenticationManager.departmentId());
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    const isEnabled =
      CardMode.isEditMode(this.state.cardMode) && this.props.isSubmit;
    return isEnabled && isError ? "form-control is-invalid" : "form-control";
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      id: value.id || "",
      departmentId: value.departmentId || "",
      designationId: value.designationId || "",
      effectiveEndDateStr: value.effectiveEndDate || "",
      effectiveStartDateStr: value.effectiveStartDate || "",
      email: value.email || "",
      firstName: value.firstName || "",
      lastName: value.lastName || "",
      middleName: value.middleName || "",
      userName: value.username || "",
      personalEmail: value.personalEmail || "",
      personalPhoneNumber: value.personalPhoneNumber || "",
      phoneNumber: value.phoneNumber || "",
      remark: value.remark || "",
      salutation: value.salutation || "",
      roles: value.roles || [],
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.designationId !== nextProps.designationId) {
      const useDefault = this.state.cardMode !== CardMode.View;
      this.getRoleOptions(
        nextProps.departmentId,
        nextProps.designationId,
        useDefault
      );
    }
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  getRoleOptions(departmentId, designationId, useDefault) {
    const request = { departmentId, designationId };
    if (!AuthenticationManager.isAuthorized(ResourceID.AccessAllDepartments)) {
      request.departmentId = AuthenticationManager.departmentId();
    }
    RestClient.sendGetRequestWithParameters(
      "/api/v1/roles/options",
      request,
      (response) => {
        let newState = { roleOptions: response.data, selectedRoleId: "" };
        if (useDefault) {
          newState.model = Object.assign({}, this.state.model, {
            roles: response.defaults,
          });
        }
        this.setState(newState, this.onModelChange);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  getDepartmentOptions(departmentId) {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/departments/options",
      { departmentId },
      (response) => {
        this.setState({ departmentOptions: response.data });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  getDesignationOptions(departmentId) {
    if (_.isNaN(departmentId) || departmentId === "null") {
      return [];
    }
    RestClient.sendGetRequestWithParameters(
      "/api/v1/designations/options",
      { departmentId },
      (response) => {
        this.setState({ designationOptions: response.data });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  getDepartmentName(id) {
    if (this.state.departmentOptions?.length <= 0 || id === "") {
      return null;
    }
    const option = this.state.departmentOptions.find(
      (option) => option.id === id
    );
    return _.isNaN(option) ? null : option.name;
  }

  getDesignationName(id) {
    if (this.state.designationOptions?.length <= 0 || id === "") {
      return null;
    }
    const option = this.state.designationOptions.find(
      (option) => option.id === id
    );
    return _.isNaN(option) ? null : option.name;
  }

  getFullName(firstName, lastName, middleName) {
    return firstName + " " + middleName + " " + lastName;
  }

  render() {
    const { model } = this.state;
    return (
      <Card title="Profile Details">
        <div className="row form-group">
          <div className="col-md-4">
            <img
              src={`/img/users/profiles/${model.id}.png`}
              className="user-profile-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  "https://shaw.sg/Content/Images/common/default/en-SG/noimage.png";
              }}
              alt=""
            />
          </div>
          <div className="col-md-8">
            <p className="user-name">
              {this.getFullName(
                model.firstName,
                model.lastName,
                model.middleName
              )}
            </p>
            <p>{this.getDepartmentName(model.departmentId) || ""}</p>
            <p>{this.getDesignationName(model.designationId) || ""}</p>
          </div>
        </div>
        <hr />
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">User Name</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">{model.userName}</label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Personal Email</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">{model.personalEmail}</label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Work Email</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">{model.email}</label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Contact Number</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">{model.phoneNumber}</label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Effective Start Date</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">
              {model.effectiveStartDateStr}
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Effective End Date</label>
          </div>
          <div className="col-md-4">
            <label className="form-control-label">
              {model.effectiveEndDateStr}
            </label>
          </div>
        </div>
        <hr />
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Permissions</label>
          </div>
          <div className="col-md-4">
            {model.roles && (
              <div id="StaffRoles">
                <ul>
                  {model.roles.map((role) => (
                    <li key={role.id}>{role.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}
