import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import Validators from "../../common/Validators";
import { toast } from "react-toastify";

export class ResetPasswordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmPassword: "",
      isSubmit: false,
      password: "",
      showPassword: false,
      showConfirmPassword: false,
      validations: [],
    };
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const { isSubmit, validations } = this.state;
    const isError = validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return isSubmit && isError ? "form-control is-invalid" : "form-control";
  }

  onCancelClick = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onGeneratePasswordClick = () => {
    const password = StringHelper.randomPassword(12);
    this.setState({ password, showPassword: true });
  };

  onSubmitClick = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    const { password } = this.state;
    const request = {
      username: this.props.username,
      password
    };
    RestClient.sendPostRequest(
      "/api/v1/users/reset_password",
      request,
      () => {
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
      },
      (error) => toast.error(error.message)
    );
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    this.setState({ [fieldName]: e.target.value });
  };

  onToggleShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  onToggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  render() {
    const {
      confirmPassword,
      isSubmit,
      password,
      showConfirmPassword,
      showPassword,
    } = this.state;
    return (
      <React.Fragment>
        <div
          className="modal fade show"
          id="resetPassword"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.onCancelClick}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row form-group">
                  <label
                    htmlFor="Password"
                    className="form-control-label col-md-3"
                  >
                    Password
                    <span className="color-red font-weight-bold">*</span>:
                  </label>
                  <div className="col-md-5 wrapper">
                    <input
                      id="Password"
                      type={showPassword ? "text" : "password"}
                      className={this.getControlClassName("password")}
                      placeholder="Enter Password"
                      fieldname="password"
                      onChange={this.onTextBoxChange}
                      value={password}
                    />
                    <i
                      id="eyeIcon"
                      className={`fas ${
                        showPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={this.onToggleShowPassword}
                    />
                  </div>
                  <div className="d-inline-flex">
                    <button
                      className="btn btn-secondary"
                      onClick={this.onGeneratePasswordClick}
                    >
                      Generate Password
                    </button>
                  </div>
                </div>
                <div className="row form-group">
                  <label
                    htmlFor="Password"
                    className="form-control-label col-md-3"
                  >
                    Confirm Password
                    <span className="color-red font-weight-bold">*</span>:
                  </label>
                  <div className="col-md-5 wrapper">
                    <input
                      id="Password1"
                      type={showConfirmPassword ? "text" : "password"}
                      className={this.getControlClassName("confirmPassword")}
                      placeholder="Enter Confirm Password"
                      fieldname="confirmPassword"
                      onChange={this.onTextBoxChange}
                      value={confirmPassword}
                    />
                    <i
                      id="eyeIcon"
                      className={`fas ${
                        showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                      }`}
                      onClick={this.onToggleShowConfirmPassword}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3" />
                  <div className="col-md-5">
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="password"
                      isEnabled={isSubmit}
                      property="Password"
                      value={password}
                    />
                    <Validators.PasswordValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="password"
                      isEnabled={isSubmit}
                      property="Password"
                      value={password}
                    />
                    <Validators.CompareValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="confirmPassword"
                      isEnabled={isSubmit}
                      property="Confirm Password"
                      value={confirmPassword}
                      valueToCompare={password}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onSubmitClick}
                >
                  Reset Password
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.onCancelClick}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    );
  }
}
