import React from "react";
import RestClient from "../../../common/RestClient";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { ApplicationID, DefaultSelection } from "../../../common/Constants";
import { BaseListing } from "../../common/BaseListing";
import { Availability, ProductCategory } from "../Constants";
import { Link } from "react-router-dom";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { StringHelper } from "../../../common/Helpers";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
export class ProductMenuListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      locations: [],
      selectedLocation: DefaultSelection.NotSelect,
      selectedSaleChannel: DefaultSelection.NotSelect,
      selectedCategory: "",
      name: "",
      isShowData: false,
      data: [],
      productCategories: [],
      showConfirmDialog: false,
      updatedProductMenu: {},
      availabilities: [],
      showHistoryDialog: false
    };
    this.sortBy = "-UpdatedOn";
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
      activeModule: new NavigationItem({
        identifier: "product_menus",
        name: "Product Menu",
      }),
    });
  };

  componentDidMount = () => {
    this.getLocations();
    this.getApplications();
    this.getProductCategories();
    this.getProductStatus();
  };

  getProductCategories = () => {
    const productCategories = ProductCategory.All;
    if (productCategories) {
      this.setState({ productCategories });
    }
  };

  getLocations = () => {
    RestClient.sendGetRequest(
      "/api/v1/locations",
      (response) => {
        this.setState({ locations: response.data });
      },
      (error) => {
        console.error(error);
        toast.error(error.message);
      }
    );
  };

  getProductStatus = () => {
    const availabilities = Availability.All;
    if (availabilities) {
      this.setState({ availabilities });
    }
  };

  getProductCategoryName = (id) => {
    return this.state.productCategories.find((x) => x.value === id)?.name;
  };

  onTextBoxChange = (e) => {
    const value = e.target.value;
    this.setState({ name: value });
  };

  getBackgroundColor = (statusId) => {
    let resultColor = "";
    const availabilities = this.state.availabilities;
    const availability = availabilities.find((x) => x.id === statusId);
    if (availability) {
      resultColor = availability.backgroundColor;
    }
    return resultColor;
  };

  getApplications = () => {
    const applications = ApplicationID.All;
    if (applications) {
      this.setState({ applications: applications });
    }
  };

  getApiPath = () => `/api/v1/product_menus`;

  injectSearchTerm = (queryParameters, searchBy) => {
    const locationId = this.state.selectedLocation;
    const appId = this.state.selectedSaleChannel;
    const category = this.state.selectedCategory;
    const search = this.state.name;
    return super.injectSearchTerm(queryParameters, {
      locationId,
      appId,
      category,
      search,
    });
  };

  onLocationChange = (e) => {
    const value = parseInt(e.target.value);
    this.setState({ selectedLocation: value });
  };

  onSaleChannelChange = (e) => {
    const value = parseInt(e.target.value);
    this.setState({ selectedSaleChannel: value });
  };

  onProductCategoryChange = (e) => {
    const value = isNaN(e.target.value) ? "" : e.target.value;
    this.setState({ selectedCategory: value });
  };

  onItemStatusChange = (e, index) => {
    const value = parseInt(e.target.value);
    const updatedListData = Object.assign([], this.state.data);
    let selectedProductMenu = updatedListData[index];
    selectedProductMenu.availability = value;
    selectedProductMenu.enableUpdate = true;
    this.setState({ data: updatedListData });
  };

  onUpdateProductMenu = (updatedItem) => {
    this.onShowConfirmDialog(updatedItem);
  };

  onRetrieveMenu = () => {
    this.setState({ isShowData: true });
    this.search();
  };

  onShowConfirmDialog = (updatedItem) => {
    this.setState({ showConfirmDialog: true, updatedProductMenu: updatedItem });
  };

  onProceedConfirmDialog = () => {
    const productMenu = this.state.updatedProductMenu;
    const model = {
      id: productMenu.id,
      productId: productMenu.productId,
      locationId: productMenu.locationId,
      appId: productMenu.appId,
      availability: productMenu.availability,
      isActive: true,
    };
    const request = Object.assign(
      {},
      model
    );
    RestClient.sendPutRequest(
      `/api/v1/product_menus/${model.id}`,
      request,
      (response) => {
        this.setState({
          showConfirmDialog: false,
          selectedLocation: productMenu.locationId,
          selectedSaleChannel: productMenu.appId,
        });
        toast.success("Product has been updated successfully.");
        this.search();
      },
      (error) => {
        console.error(error);
        toast.error(error.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onViewHistoryClick = () => {
    this.setState({ showHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showHistoryDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  getURLAddProduct = () =>
    `/product_menus/${this.state.selectedLocation}/${this.state.selectedSaleChannel}`;

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col row">
          <label
            className="form-control-label mt-1 m-l-15 mr-2"
            htmlFor="Status"
          >
            Category:{" "}
          </label>
          <select
            disabled={!this.state.isShowData}
            className="form-control col-5"
            name="status"
            id="Status"
            onChange={this.onProductCategoryChange}
            value={this.state.selectedCategory}
          >
            <option value="">All Category</option>
            {this.state.productCategories
              ? this.state.productCategories.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })
              : null}
          </select>
        </div>
        <div className="col-md-6 input-group ml-auto">
          <input
            disabled={!this.state.isShowData}
            type="text"
            id="input1-group2"
            name="search"
            placeholder="Search"
            className="form-control"
            onChange={this.onTextBoxChange}
            value={this.state.name}
          />
          <span>&nbsp;</span>
          <div className="input-group-btn">
            <button
              disabled={!this.state.isShowData}
              className="btn btn-primary"
              onClick={this.search}
            >
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
          <span>&nbsp;</span>
          {AuthenticationManager.isAuthorized(
            ResourceID.ProductMenuAddingButtonPage
          ) && (
              <Link
                className={
                  this.state.isShowData
                    ? "btn btn-primary"
                    : "btn btn-primary disabled"
                }
                to={this.state.isShowData ? this.getURLAddProduct() : ""}
                style={{ fontSize: "1rem" }}
              >
                <i className="fas fa-plus-circle" /> Add a product
              </Link>)}
        </div>
      </div>
    );
  }

  generateExtendedComponents = () => {
    const {
      locations,
      selectedLocation,
      showHistoryDialog,
      selectedSaleChannel,
      showConfirmDialog,
    } = this.state;
    const isDisableRetrieveMenuBtn =
      selectedLocation === DefaultSelection.NotSelect ||
      selectedSaleChannel === DefaultSelection.NotSelect;
    return (
      <>
        <div className="row mb-3">
          <label
            className="align-self-center m-l-15 pl-0 form-control-label mr-2"
            htmlFor="location"
          >
            Location:{" "}
          </label>
          <select
            className="form-control col-2"
            id="location"
            name="location"
            onChange={this.onLocationChange}
            value={selectedLocation}
          >
            <option disabled value={DefaultSelection.NotSelect}>
              Select a location
            </option>
            {locations &&
              locations.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <label
            className="align-self-center m-l-15 pl-0 form-control-label mr-2"
            htmlFor="saleChannel"
          >
            Sales Channel:{" "}
          </label>
          <select
            className="form-control col-2"
            name="saleChannel"
            id="saleChannel"
            onChange={this.onSaleChannelChange}
            value={selectedSaleChannel}
          >
            <option disabled value={DefaultSelection.NotSelect}>
              Select a Sales Channel
            </option>
            {ApplicationID.SaleChannels.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
          </select>
          <button
            disabled={isDisableRetrieveMenuBtn}
            className="btn btn-secondary ml-3"
            onClick={this.onRetrieveMenu}
          >
            Retrieve Menu
          </button>
          <div className="col d-flex flex-row justify-content-end">
            <button
              className="btn btn-outline-secondary ml-1"
              data-toggle="modal"
              data-target="#UserHistory"
              onClick={this.onViewHistoryClick}
            >
              History
            </button>
          </div>
        </div>

        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to update product status?"
          title="Update product status"
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId="0"
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.ProductMenu}
          />
        )}
      </>
    );
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Product Code</th>
            <th style={{ width: "20%" }}>Prouduct Name</th>
            <th style={{ width: "10%" }}>Category</th>
            <th>
              Updated On
              <i
                  className="fas fa-sort-alpha-down"
                  id="UpdatedOn"
                  onClick={this.toggleSort}
                />  
            </th>
            <th>Updated By</th>
            <th style={{ width: "30%" }}>Status</th>
          </tr>
        </thead>
        <tbody id="data">
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <tr
                  style={{
                    backgroundColor: this.getBackgroundColor(item.availability),
                  }}
                  key={item.id}
                >
                  <td>{item.productCode}</td>
                  <td>{item.productName}</td>
                  <td>{this.getProductCategoryName(item.productCategory)}</td>
                  <td>
                    {StringHelper.asDateStringToDefaultFormat(item.updatedOn)}
                  </td>
                  <td>{item.updatedBy}</td>
                  <td>
                    {
                      <div className="row">
                        <select
                          className="form-control col-5"
                          name="status"
                          id="Status"
                          onChange={(e, val) =>
                            this.onItemStatusChange(e, index)
                          }
                          value={item.availability}
                        >
                          {this.state.availabilities
                            ? this.state.availabilities.map((item, index) => {
                              return (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              );
                            })
                            : null}
                        </select>
                        <div className="col-md-3">
                          <button
                            disabled={!item.enableUpdate}
                            className="btn btn-primary mr-0"
                            onClick={(e) => this.onUpdateProductMenu(item)}
                            id="Update1"
                          >
                            Update
                          </button>
                        </div>
                        <div className="col-md-3">
                          <Link
                            className="btn btn-primary mr-1 px-3"
                            to={`/products/${item.productId}`}
                          >
                            View
                          </Link>
                        </div>
                      </div>
                    }
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }
}
