import React from "react";
import { BaseListing } from "../../common/BaseListing";
import { Strings } from "../../../common/Common";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";
import { TicketTypes } from "../../../constants/PricingConstants";
import { ApplicationID } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class SurchargesListing extends BaseListing {
  constructor() {
    super();
    this.state = {};
    this.searchText = "";
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "surcharges",
        name: "Surcharges",
      }),
    });
  };

  getApiPath = () => "/api/v1/surcharges";

  getDefaultSort = () => "-UpdatedOn";

  getTicketTypeNames = (service) => {
    if (_.isNil(service) || _.isNil(service.serviceTickets)) {
      return "";
    }
    return service.serviceTickets
      .map((x) => TicketTypes.getDisplay(x.ticketType))
      .join(", ");
  };

  getSalesChannel(item) {
    if (!item) {
      return "";
    }
    const foundItem = ApplicationID.All.find((x) => x.value === item.appId);
    return foundItem?.name;
  }

  injectSearchTerm(queryParameters, searchBy) {
    return super.injectSearchTerm(
      queryParameters,
      this.createSearchParameters()
    );
  }

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col row" />
        <div className="col-md-6 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="name"
            placeholder="Enter surcharge name"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={(e) => (this.searchText = e.target.value)}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  createSearchParameters() {
    let data = {};
    if (!Strings.isNullOrEmpty(this.searchText)) {
      data.name = this.searchText;
    }
    return data;
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Name</th>
            <th>Sales Channel</th>
            <th>Amount</th>
            <th>
              Updated On
              <i
                className="fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>{this.getSalesChannel(item)}</td>
                    <td>{item.amount.toFixed(6)}</td>
                    <td>{DateHelper.formatDateTimeString(item.updatedOn)}</td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }
}
