export class UserStatus {
    static NotSpecified = 0;
    static Active = 1;
    static Locked = 50;
    static Inactive = 99;
}

export function formatStatus(statusCode) {
    const statusMappings = {
        [UserStatus.Active]: 'Active',
        [UserStatus.Locked]: 'Locked',
        [UserStatus.Inactive]: 'Deactivated',
    }
    return statusMappings[statusCode];
}

export function formatStatusClassName(statusCode) {
    const statusMappings = {
        [UserStatus.Active]: 'status--process',
        [UserStatus.Locked]: 'status--denied',
        [UserStatus.Inactive]: 'status--denied',
    }
    return statusMappings[statusCode];
}
