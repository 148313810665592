import { toast } from "react-toastify";
import { RestService } from "./RestService";
export class SpecialPriceService extends RestService {
  constructor() {
    super("/api/v1/special_prices");
  }

  async get(id, onSuccess, onError) {
    return this.getImpl(
      id,
      { includes: "MovieRelease,Service" },
      (service) => {
        if (onSuccess) {
          onSuccess(service);
        }
      },
      (createError) => {
        if (onError) {
          onError();
        }
        toast.error(createError.message);
      }
    );
  }
}
