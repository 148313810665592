import { RestService } from "./RestService";

export class PerformanceRestService extends RestService {
  constructor() {
    super("api/v1/performances");
  }

  async applySaleRestrictions(payload) {
    return await this.postImpl("apply_sale_restrictions", payload);
  }
}
