import React from "react";
import { StringHelper } from "../../common/Helpers";
import RestClient from "../../common/RestClient";
import { BaseListing } from "../common/BaseListing";
import _ from "lodash";
import {
  BookingStatus,
  GroupCode,
  PersonInChargeQuery,
} from "../../constants/CorporateBookingConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";

export class BookingRequestListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      users: [],
      statistic: {
        pending: 0,
        completed: 0,
        rejected: 0,
      },
      criteria: {
        search: "",
        status: 0,
        personInCharge: "",
        personInChargeQuery: 0,
      },
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "booking_requests",
        name: "Booking Requests",
      }),
    });
  };

  getApiPath = () => "/api/v1/corporate_booking_requests";

  getDefaultSort = () => "-CreatedOn";

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const criteria = this.state.criteria;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    criteria[fieldName] = selectValue;
    this.setState({ criteria });
  };

  onRowClick = (id) => {
    this.props.history.push(`booking_requests/${id}`);
  };

  loadUsers = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/user_groups`,
      { code: GroupCode.CB_BRA },
      (response) => {
        const users = response.data.map((x) => x.name);
        this.setState({ users });
      },
      this.handleError
    );
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Full Name</th>
            <th onClick={this.onColumnClick}>
              Event Date
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="EventDate"
                onClick={this.toggleSort}
              />
            </th>
            <th>Preferred Theatre</th>
            <th>Film Title</th>
            <th>Status</th>
            <th>PIC</th>
            <th onClick={this.onColumnClick}>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              const status = BookingStatus.getName(item.status);
              return (
                <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateFormat(
                      item.eventDate
                    )}
                  </td>
                  <td>{item.firstTheatre}</td>
                  <td>{item.moviePrimaryTitle}</td>
                  <td>
                    <span className={this.getStatusStyle(status)}>
                      {_.capitalize(status)}
                    </span>
                  </td>
                  <td>{item.personInCharge}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  async componentDidMount() {
    await RestClient.sendGetRequest(
      this.getApiPath() + "/statistic",
      (response) => {
        this.setState({
          statistic: response,
        });
        this.loadUsers();
      },
      this.handleError
    );
    await super.componentDidMount();
  }

  injectSearchTerm(queryParameters) {
    const { criteria } = this.state;
    return super.injectSearchTerm(queryParameters, { ...criteria });
  }

  generateExtendedComponents() {
    const { statistic } = this.state;
    return (
      <div className="row">
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.pending}</h2>
            <span className="desc">Pending Requests</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.completed}</h2>
            <span className="desc">Completed Requests</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.rejected}</h2>
            <span className="desc">Rejected Requests</span>
          </div>
        </div>
      </div>
    );
  }
  
  onChangePersonInCharge = (e) => {
    const criteria = this.state.criteria
    const value = e.target.value;
    if (Number.isNaN(+value)) {
      criteria.personInCharge = value;
      criteria.personInChargeQuery = PersonInChargeQuery.Everyone;
    } else {
      criteria.personInCharge = "";
      criteria.personInChargeQuery = +value;
    }
    this.setState({ criteria });
    this.search();
  }

  generateTableFilter() {
    const { users } = this.state;
    return (
      <div className="row mb-3">
        <div className="col-12 col-md-6 col-xl-4 row mb-2 mx-0 align-items-center">
          <label
            className="form-control-label col-6 px-0"
            htmlFor="Status"
          >
            Request Status:{" "}
          </label>
          <select
            className="form-control col-6"
            name="status"
            id="Status"
            valuetype="number"
            required
            onChange={async (e) => {
              this.onInputChange(e);
              await this.search();
            }}
          >
            <option value={0}>All Status</option>
            {BookingStatus.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 col-xl-4 row mb-2 mx-0 align-items-center">
          <label
            className="form-control-label col-6 px-0"
            htmlFor="personInCharge"
          >
            Person In Charge:{" "}
          </label>
          <select
            className="form-control col-6"
            name="personInCharge"
            id="personInCharge"
            required
            onChange={this.onChangePersonInCharge}
          >
            <option value={PersonInChargeQuery.Everyone}>Everyone</option>
            <option value={PersonInChargeQuery.Unassigned}>Unassigned</option>
            {users.map((user, index) => (
              <option value={user} key={index}>
                {user}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 col-xl-4 row mx-0 mb-2 input-group ml-auto">
          <input
            type="text"
            id="search"
            name="search"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onInputChange}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  getStatusStyle(status) {
    status = status ?? "";
    const map = {
      pending: "color-orange",
      rejected: "color-red",
    };
    if (!status) return "color-gray";
    let normalizedStatus = status.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  }
}
