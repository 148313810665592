import { Mode } from "../../../constants/Mode";
import React from "react";

const MovieReleaseLayoutAttribute = (props) => {
  const { header, mode } = props;
  return (
    <div className="card mb-3">
      <div className="card-body Movie Restriction-0">
        <div className="row mx-0 mb-3">
          <span style={{ fontSize: "20px" }}>{header.title}</span>
          {mode === Mode.Editable && (
            <button
              className="ml-auto btn btn-sm btn-success"
              onClick={header.onClick}
              data-toggle="modal"
            >
              Add
            </button>
          )}
        </div>
        {props.children}
      </div>
    </div>
  );
};

const Body = (props) => {
  return (
    <div className="card card-body bg-light mb-1 py-1">
      <div className="row">
        <div className="col-10">{props.children}</div>
        <div className="col-2 py-1">
          {props.mode === Mode.Editable && (
            <button
              className="float-right btn btn-sm btn-danger"
              onClick={props.onDelete}
            >
              <i className="fas fa-trash-alt" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

MovieReleaseLayoutAttribute.Body = Body;

export default MovieReleaseLayoutAttribute;