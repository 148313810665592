import React from "react";
import RestClient from "../../../../common/RestClient";
import { DateHelper } from "../../../../common/Helpers";
import { ApplicationID } from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import { RowFormField } from "../../../layout/FormLayout";

export class RedemptionDetailsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { redemptionDetail: null };
  }

  componentDidMount() {
    this.loadRedemptionDetail();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eVoucherId !== this.props.eVoucherId) {
      this.loadRedemptionDetail();
    }
  }

  getEnumName = (value) => {
    const foundItem = ApplicationID.All.find((x) => x.value === value);
    return foundItem?.name;
  };

  loadRedemptionDetail = () => {
    const eVoucherId = this.props.eVoucherId;
    RestClient.sendGetRequest(
      `/api/v1/evouchers/${eVoucherId}/redemption`,
      (response) => {
        this.setState({ redemptionDetail: response });
      }
    );
  };

  renderViewMode() {
    const { redemptionDetail } = this.state;
    return (
      <div className="row">
        <div className="col-md-6">
          <RowFormField label="Movie Title:">
            <input
              className="form-control display-input"
              type="text"
              id="Code"
              value={redemptionDetail.movieTitle}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Movie DateTime:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={DateHelper.toDisplayDateTimeFormat(
                redemptionDetail.movieDateTime
              )}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Movie Location:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={redemptionDetail.movieLocation}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Movie Location Hall:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={redemptionDetail.movieLocationHall}
              readOnly
            />
          </RowFormField>
        </div>
        <div className="col-md-6">
          <RowFormField label="Used On:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={DateHelper.toDisplayDateTimeFormat(
                redemptionDetail.usedOn
              )}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Redemption Mode:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={this.getEnumName(redemptionDetail.redemptionMode)}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Redemption Location:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={redemptionDetail.redemptionLoaction}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Redemption Counter:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={redemptionDetail.redemptionCounter}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Transaction ID:">
            <input
              className="form-control display-input"
              type="text"
              id="usedon"
              value={redemptionDetail.transactionId}
              readOnly
            />
          </RowFormField>
        </div>
      </div>
    );
  }

  render() {
    const { redemptionDetail } = this.state;
    return (
      <>
        {redemptionDetail && (
          <Card title="Redemption Details">{this.renderViewMode()}</Card>
        )}
      </>
    );
  }
}
