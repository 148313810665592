import _ from "lodash"

export class LocationVenue {
    constructor() {
        this.id = 0;
        this.code = "";
        this.name = "";
        this.location = new Location()
    }
    
    handleResponse(data) {
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        if(!_.isNil(data.location)) {
            this.location.handleResponse(data.location)
        }
    }
}

export class Location {
    constructor() {
        this.id = 0;
        this.name = "";
    }

    handleResponse(data) {
        this.id = data.id;
        this.name = data.name;
    }
}