import _ from "lodash";
import { MovieRelease } from "./MovieRelease";
import { MovieCrew } from "./MovieCrew";
import {
  Country,
  MovieCategory,
  MovieDisplayWeight,
  MovieOrigin,
} from "../constants/MovieConstants";

export class Movie {
  constructor(data) {
    data = data || {};
    this.id = data.id ?? 0;
    this.primaryTitle = data.primaryTitle ?? "";
    this.secondaryTitle = data.secondaryTitle ?? "";
    this.movieCategory = data.movieCategory ?? MovieCategory.Films;
    this.duration = data.duration ?? 1;
    this.movieOrigin = data.movieOrigin ?? MovieOrigin.NonAsianFilms;
    this.movieOriginCountry = data.movieOriginCountry ?? Country.Singapore;
    this.language = data.language ?? "";
    this.shortSynopsis = data.shortSynopsis ?? "";
    this.fullSynopsis = data.fullSynopsis ?? "";
    this.displayWeight = data.displayWeight ?? MovieDisplayWeight.Length;
    this.labelDisplay = data.labelDisplay ?? "";
    this.labelPrint = data.labelPrint ?? "";
    this.websiteUrl = data.websiteUrl ?? "";
    this.company = new Company(data.company || {});
    this.companyId = data.company?.id ?? "";
    this.notice = data.notice ?? "";
    if (!_.isNil(data.movieReleases)) {
      this.movieReleases = data.movieReleases.map((x) => new MovieRelease(x));
    } else {
      this.movieReleases = [];
    }

    if (!_.isNil(data.crews)) {
      this.crews = data.crews.map((x) => new MovieCrew(x));
    } else {
      this.crews = [];
    }
  }
}

export class Company {
  constructor(data) {
    this.id = data.id ?? 0;
    this.name = data.name ?? "";
  }
}