import { useResetRecoilState, atom, selector } from "recoil";
import { Service } from "../../../models/Service";
import _ from "lodash";
import { useEffect } from "react";
import { ViewMode } from "../../../constants/PricingConstants";

export const serviceAtom = atom({
  key: "service",
  default: new Service({}),
});

export const modeAtom = atom({
  key: "serviceMode",
  default: ViewMode.Readonly,
});

export const serviceIdSelector = selector({
  key: "serviceId",
  get: ({ get }) => {
    const service = get(serviceAtom);
    return service.id;
  },
});

export const serviceNameSelector = selector({
  key: "serviceName",
  get: ({ get }) => {
    const service = get(serviceAtom);
    return service.name;
  },
});

export const serviceTicketsSelector = selector({
  key: "serviceTickets",
  get: ({ get }) => {
    const service = get(serviceAtom);
    return service.serviceTickets ?? [];
  },
  set: ({ get, set }, newServiceTickets) => {
    const service = get(serviceAtom);
    const editable = _.cloneDeep(service);
    editable.serviceTickets = newServiceTickets;
    set(serviceAtom, editable);
  },
});

export const activeServiceTicketIdAtom = atom({
  key: "activeServiceTicket",
  default: 0,
});

export function useResetStatesOnDestroy() {
  const resetService = useResetRecoilState(serviceAtom);
  const resetMode = useResetRecoilState(modeAtom);

  useEffect(() => {
    _.noop();

    return () => {
      console.log("RESETTING STATES+++++");
      resetService();
      resetMode();
    };
  }, [resetService]);
}
