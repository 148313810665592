import React, { useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { MovieCrewService } from "../../services/MovieCrewService";
import { Constants } from "./Constant";

const CrewSelect = ({ onChange, selectedValue }) => {
  const [crews, setCrews] = useState({
    data: [],
    hasMore: false,
    total: 0,
    currentPage: 0,
    name: "",
  });

  const fetchCrew = async (name = "", currentPage = 0) => {
    const params = {
      name,
      maxResults: Constants.MaxNumberOfPagination,
      startAt: currentPage * Constants.PageSizes[0],
    };
    const response = await new MovieCrewService().list(params);
    const { data } = response;
    const result = {
      ...data,
      currentPage: currentPage + 1,
      name,
    };
    setCrews(result);
    return data;
  };

  const loadOptionHandle = (name, callback) => {
    (async () => {
      const { data } = await fetchCrew(name);
      const options = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      callback(options);
    })();
  };
  const value = (crews.data || []).find((crew) => crew.id === selectedValue);
  return (
    <AsyncCreatableSelect
      isClearable
      defaultOptions={true}
      loadOptions={loadOptionHandle}
      onChange={(value, actionMeta) => {
        onChange({
          ...value,
          isNew: actionMeta.action === "create-option",
        });
      }}
      value={value}
    />
  );
};

export default CrewSelect;
