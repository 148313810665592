import { RestService } from "./RestService";

export class TransactionRestService extends RestService {
  constructor() {
    super("/api/v1/transactions");
  }

  getChargedStatus(id) {
    return this.getImpl(`${id}/charged_status`).then(
      (response) => response.data
    );
  }
}
