import React from "react";
import $ from "jquery";
import { DateHelper } from "../../../common/Helpers";
import {
  Classify,
  MovieFormat,
  MovieSubtitle,
} from "../../../constants/MovieConstants";
import { useHistory } from "react-router-dom";
import "../../css/components/accordion.css";

export const MovieReleaseListing = ({ data }) => {
  const history = useHistory();

  const resetSortedColumn = (target) => {
    const sortButtons = $(".sort-button");
    for (let index = 0; index < sortButtons.length; index++) {
      const element = $(sortButtons[index]);
      if ($(target).attr("id") === element.attr("id")) {
        continue;
      }

      if (element.hasClass("color-light-blue")) {
        element.removeClass("color-light-blue");
      }

      if (element.hasClass("fa-sort-alpha-down-alt")) {
        element.removeClass("fa-sort-alpha-down-alt");
      }

      if (!element.hasClass("fa-sort-alpha-down")) {
        element.addClass("fa-sort-alpha-down");
      }
    }
  };

  const onRowClick = (id) => {
    history.push(`movie_releases/${id}`);
  };

  const toggleSort = async (e) => {
    const target = e.target;
    resetSortedColumn(target);
    e.stopPropagation();
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card" id="batchTable">
          <div className="card-header accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseMovieRelease"
              aria-expanded="true"
              aria-controls="collapseMovieRelease"
            >
              Movie Releases
            </button>
          </div>
          <div
            className="card-body batchInfo accordion-collapse collapse show"
            id="collapseMovieRelease"
            aria-labelledby="movieReleaseHeader"
            data-bs-parent="#batchTable"
          >
            <div className="accordion-body">
              <div className="table-responsive m-b-10 table-px-15">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Movie Format</th>
                      <th>Classification</th>
                      <th>Subtitle</th>
                      <th>
                        Updated On{" "}
                        <i
                          className="fas fa-sort-alpha-down"
                          aria-hidden="true"
                          onClick={toggleSort}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody id="data">
                    {data && data.map((item, index) => {
                        const id = item.id;
                        const format = MovieFormat.getName(item.format);
                        const classify = Classify.getName(item.classify);
                        const subtitle = MovieSubtitle.getName(item.subtitle);
                        const updatedOn = DateHelper.formatDateTimeString(
                          item.updatedOn
                        );
                        return (
                          <tr
                            className="Pending blue"
                            key={index}
                            onClick={() => onRowClick(id)}
                          >
                            <td title={id}>{id}</td>
                            <td title={format}>{format}</td>
                            <td title={classify}>{classify}</td>
                            <td title={subtitle}>{subtitle}</td>
                            <td title={updatedOn}>{updatedOn}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
