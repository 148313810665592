import _ from "lodash";

export class MovieCategory {
  static Films = 1;
  static Plays = 2;
  static Concerts = 3;
  static Broadcasts = 4;

  static All = [
    {
      name: "Films",
      value: this.Films,
    },
    {
      name: "Plays",
      value: this.Plays,
    },
    {
      name: "Concerts",
      value: this.Concerts,
    },
    {
      name: "Broadcasts",
      value: this.Broadcasts,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class Country {
  static Singapore = 1;
  static UnitedStatesOfAmerica = 2;
  static UnitedKingdom = 3;
  static Argentina = 4;

  static All = [
    {
      name: "Singapore",
      value: this.Singapore,
    },
    {
      name: "United States of America",
      value: this.UnitedStatesOfAmerica,
    },
    {
      name: "United Kingdom",
      value: this.UnitedKingdom,
    },
    {
      name: "Argentina",
      value: this.Argentina,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieOrigin {
  static AsianFilms = 1;
  static NonAsianFilms = 2;

  static All = [
    {
      name: "Asian Films",
      value: this.AsianFilms,
    },
    {
      name: "Non-Asian Films",
      value: this.NonAsianFilms,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieBrand {
  static Digital = 1;
  static IMAX = 2;
  static Analog = 3;

  static All = [
    {
      name: "Digital",
      value: this.Digital,
    },
    {
      name: "IMAX",
      value: this.IMAX,
    },
    {
      name: "Analog",
      value: this.Analog,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieFormat {
  static DigitalStandard = 1;
  static Digital3D = 2;
  static DigitalHFR3D = 3;
  static AnalogStandard = 4;
  static IMAXStandard = 5;
  static IMAX3D = 6;
  static IMAXHFR = 7;
  static IMAXHFR3D = 8;
  static IMAXLaser = 9;
  static IMAXLaser3D = 10;
  static IMAXLaserHFR3D = 11;

  static All = [
    {
      code: "DGSD",
      name: "Digital Standard",
      value: this.DigitalStandard,
    },
    {
      code: "DG3D",
      name: "Digital 3D",
      value: this.Digital3D,
    },
    {
      code: "DGH3",
      name: "Digital HFR 3D",
      value: this.DigitalHFR3D,
    },
    {
      code: "ANSD",
      name: "Analog Standard",
      value: this.AnalogStandard,
    },
    {
      code: "IMSD",
      name: "IMAX Standard",
      value: this.IMAXStandard,
    },
    {
      code: "IM3D",
      name: "IMAX 3D",
      value: this.IMAX3D,
    },
    {
      code: "IMHD",
      name: "IMAX High Frame Rate",
      value: this.IMAXHFR,
    },
    {
      code: "IMH3",
      name: "IMAX High Frame Rate 3D",
      value: this.IMAXHFR3D,
    },
    {
      code: "IMLS",
      name: "IMAX Laser",
      value: this.IMAXLaser,
    },
    {
      code: "IML3",
      name: "IMAX Laser 3D",
      value: this.IMAXLaser3D,
    },
    {
      code: "IMLH3",
      name: "IMAX Laser HFR 3D",
      value: this.IMAXLaserHFR3D,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }
}

export class LocationBrand {
  static None = 0;
  static Digital = 1;
  static IMAX = 2;
  static Premiere = 4;
  static Lumiere = 8;
  static Dreamers = 16;
  static DreamersBalestier = 32;

  static All = [
    {
      name: "None",
      value: this.None,
    },
    {
      code: "DGTL",
      name: "Shaw Theatres Digital",
      value: this.Digital,
    },
    {
      code: "IMAX",
      name: "Shaw Theatres IMAX",
      value: this.IMAX,
    },
    {
      code: "PREM",
      name: "Shaw Theatres Premiere",
      value: this.Premiere,
    },
    {
      code: "LUMR",
      name: "Shaw Theatres Lumiere",
      value: this.Lumiere,
    },
    {
      code: "DREM",
      name: "Shaw Theatres Dreamers",
      value: this.Dreamers,
    },
    {
      code: "PDREM",
      name: "Shaw Theatres Dreamers Balestier",
      value: this.DreamersBalestier,
    },
  ];

  static Mapping = {
    [this.Digital]: [
      MovieFormat.DigitalStandard,
      MovieFormat.Digital3D,
      MovieFormat.DigitalHFR3D,
      MovieFormat.AnalogStandard,
    ],
    [this.IMAX]: [
      MovieFormat.IMAXStandard,
      MovieFormat.IMAX3D,
      MovieFormat.IMAXHFR,
      MovieFormat.IMAXHFR3D,
      MovieFormat.IMAXLaser,
      MovieFormat.IMAXLaser3D,
      MovieFormat.IMAXLaserHFR3D,
    ],
    [this.Premiere]: [MovieFormat.DigitalStandard, MovieFormat.Digital3D],
    [this.Lumiere]: [MovieFormat.DigitalStandard, MovieFormat.Digital3D],
    [this.Dreamers]: [MovieFormat.DigitalStandard],
    [this.DreamersBalestier]: [MovieFormat.DigitalStandard],
  };

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }

  static getMovieFormats(brand) {
    if (_.isNil(brand) || _.isNil(this.Mapping[brand])) {
      return [];
    }
    const definitions = this.Mapping[brand];
    return MovieFormat.All.filter((x) => definitions.indexOf(x.value) >= 0);
  }
}

export class Classify {
  static General = 1;
  static ParentalGuidance = 2;
  static ParentalGuidance13 = 3;
  static NoChildrenBelow16 = 4;
  static Mature18 = 5;
  static Restricted21 = 6;
  static RatingToBeAdvised = 7;
  static NotApplicable = 8;
  static Exempted = 9;

  static All = [
    {
      code: "G",
      name: "General",
      value: this.General,
    },
    {
      code: "PG",
      name: "Parental Guidance",
      value: this.ParentalGuidance,
    },
    {
      code: "PG13",
      name: "Parental Guidance 13",
      value: this.ParentalGuidance13,
    },
    {
      code: "NC16",
      name: "No Children Below 16 Years Old",
      value: this.NoChildrenBelow16,
    },
    {
      code: "M18",
      name: "Mature 18 for Persons 18 Years and Above",
      value: this.Mature18,
    },
    {
      code: "R21",
      name: "Restricted To Person 21 Years and Above",
      value: this.Restricted21,
    },
    {
      code: "TBA",
      name: "Rating To Be Advised",
      value: this.RatingToBeAdvised,
    },
    {
      code: "NA",
      name: "Not Applicable",
      value: this.NotApplicable,
    },
    {
      code: "EX",
      name: "Exempted",
      value: this.Exempted,
    },
  ];

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieSubtitle {
  static Chinese = 1;
  static English = 2;
  static NoSub = 3;
  static Malay = 4;
  static EnglishAndChinese = 5;
  static EnglishChineseAndMalay = 6;
  static ChineseMalay = 7;
  static Others = 10;
  static EnglishAndMalay = 11;

  static All = [
    {
      code: "CHSB",
      name: "With Chinese Subtitles",
      value: this.Chinese,
    },
    {
      code: "ENSB",
      name: "With English Subtitles",
      value: this.English,
    },
    {
      code: "NOSB",
      name: "No Subtitles Available",
      value: this.NoSub,
    },
    {
      code: "MYSB",
      name: "With Malay Subtitles",
      value: this.Malay,
    },
    {
      code: "ECSB",
      name: "With English & Chinese Subtitles",
      value: this.EnglishAndChinese,
    },
    {
      code: "CMSB",
      name: "With Malay & Chinese Subtitles",
      value: this.ChineseMalay,
    },
    {
      code: "ECMSB",
      name: "With English, Chinese & Malay Subtitles",
      value: this.EnglishChineseAndMalay,
    },
    {
      code: "EMSB",
      name: "With English & Malay Subtitles",
      value: this.EnglishAndMalay,
    },
    {
      code: "OTHER",
      name: "Others",
      value: this.Others,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }
}

export class Languages {
  static Arabic = 1;
  static Bengali = 2;
  static Bosnian = 3;
  static Bulgarian = 4;
  static Burmese = 5;
  static Chinese = 6;
  static Czech = 7;
  static Danish = 8;
  static Dutch = 9;
  static English = 10;
  static Estonian = 11;
  static Finnish = 12;
  static French = 13;
  static German = 14;
  static Irish = 15;
  static Greek = 16;
  static Hebrew = 17;
  static Hindi = 18;
  static Hungarian = 19;
  static Indonesian = 20;
  static Italian = 21;
  static Japanese = 22;
  static Kannada = 23;
  static Korean = 24;
  static Latin = 25;
  static Malayalam = 26;
  static Marathi = 27;
  static MALAY = 28;
  static Mongolian = 29;
  static MultipleLanguages = 30;
  static Mayan = 31;
  static NotApplicable = 32;
  static Nepali = 33;
  static Norwegian = 34;
  static Polish = 35;
  static Portuguese = 36;
  static Russian = 37;
  static Spanish = 38;
  static Swedish = 39;
  static Tamil = 40;
  static Telugu = 41;
  static Tagalog = 42;
  static Thai = 43;
  static Tibetan = 44;
  static Turkish = 45;
  static Welsh = 46;
  static Cantonese = 47;
  static Croatian = 48;
  static EnglishChineseMalay = 49;
  static Icelandic = 50;
  static Luxembourgish = 51;
  static Romanian = 52;
  static Slovak = 53;
  static Ukrainian = 54;
  static Vietnamese = 55;
  static MandarinKoreanEnglish = 56;
  static HebrewRussian = 57;
  static KoreanEnglish = 58;
  static Amharic = 59;
  static NoLanguage = 60;
  static Mandarin = 61;
  static Hokkien = 62;
  static EnglishMandarinHokkien = 63;
  static JapaneseEnglish = 64;

  static All = [
    {
      name: "Arabic",
      value: this.Arabic,
    },
    {
      name: "Bengali",
      value: this.Bengali,
    },
    {
      name: "Bosnian",
      value: this.Bosnian,
    },
    {
      name: "Bulgarian",
      value: this.Bulgarian,
    },
    {
      name: "Burmese",
      value: this.Burmese,
    },
    {
      name: "Chinese",
      value: this.Chinese,
    },
    {
      name: "Czech",
      value: this.Czech,
    },
    {
      name: "Danish",
      value: this.Danish,
    },
    {
      name: "Dutch",
      value: this.Dutch,
    },
    {
      name: "English",
      value: this.English,
    },
    {
      name: "Estonian",
      value: this.Estonian,
    },
    {
      name: "Finnish",
      value: this.Finnish,
    },
    {
      name: "French",
      value: this.French,
    },
    {
      name: "German",
      value: this.German,
    },
    {
      name: "Irish",
      value: this.Irish,
    },
    {
      name: "Greek",
      value: this.Greek,
    },
    {
      name: "Hebrew",
      value: this.Hebrew,
    },
    {
      name: "Hindi",
      value: this.Hindi,
    },
    {
      name: "Hungarian",
      value: this.Hungarian,
    },
    {
      name: "Indonesian",
      value: this.Indonesian,
    },
    {
      name: "Italian",
      value: this.Italian,
    },
    {
      name: "Japanese",
      value: this.Japanese,
    },
    {
      name: "Kannada",
      value: this.Kannada,
    },
    {
      name: "Korean",
      value: this.Korean,
    },
    {
      name: "Latin",
      value: this.Latin,
    },
    {
      name: "Malayalam",
      value: this.Malayalam,
    },
    {
      name: "Marathi",
      value: this.Marathi,
    },
    {
      name: "MALAY",
      value: this.MALAY,
    },
    {
      name: "Mongolian",
      value: this.Mongolian,
    },
    {
      name: "MultipleLanguages",
      value: this.MultipleLanguages,
    },
    {
      name: "Mayan",
      value: this.Mayan,
    },
    {
      name: "NotApplicable",
      value: this.NotApplicable,
    },
    {
      name: "Nepali",
      value: this.Nepali,
    },
    {
      name: "Norwegian",
      value: this.Norwegian,
    },
    {
      name: "Polish",
      value: this.Polish,
    },
    {
      name: "Portuguese",
      value: this.Portuguese,
    },
    {
      name: "Russian",
      value: this.Russian,
    },
    {
      name: "Spanish",
      value: this.Spanish,
    },
    {
      name: "Swedish",
      value: this.Swedish,
    },
    {
      name: "Tamil",
      value: this.Tamil,
    },
    {
      name: "Telugu",
      value: this.Telugu,
    },
    {
      name: "Tagalog",
      value: this.Tagalog,
    },
    {
      name: "Thai",
      value: this.Thai,
    },
    {
      name: "Tibetan",
      value: this.Tibetan,
    },
    {
      name: "Turkish",
      value: this.Turkish,
    },
    {
      name: "Welsh",
      value: this.Welsh,
    },
    {
      name: "Cantonese",
      value: this.Cantonese,
    },
    {
      name: "Croatian",
      value: this.Croatian,
    },
    {
      name: "EnglishChineseMalay",
      value: this.EnglishChineseMalay,
    },
    {
      name: "Icelandic",
      value: this.Icelandic,
    },
    {
      name: "Luxembourgish",
      value: this.Luxembourgish,
    },
    {
      name: "Romanian",
      value: this.Romanian,
    },
    {
      name: "Slovak",
      value: this.Slovak,
    },
    {
      name: "Ukrainian",
      value: this.Ukrainian,
    },
    {
      name: "Vietnamese",
      value: this.Vietnamese,
    },
    {
      name: "Mandarin, Korean, English",
      value: this.MandarinKoreanEnglish,
    },
    {
      name: "Hebrew, Russian",
      value: this.HebrewRussian,
    },
    {
      name: "Korean, English",
      value: this.KoreanEnglish,
    },
    {
      name: "Amharic",
      value: this.Amharic,
    },
    {
      name: "No Language",
      value: this.NoLanguage,
    },
    {
      name: "Mandarin",
      value: this.Mandarin,
    },
    {
      name: "Hokkien",
      value: this.Hokkien,
    },
    {
      name: "English, Mandarin and Hokkien",
      value: this.EnglishMandarinHokkien,
    },
    {
      name: "Japanese, English",
      value: this.JapaneseEnglish,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieGenre {
  static Action = 1;
  static Adventure = 2;
  static Animation = 3;
  static Biography = 4;
  static Comedy = 5;
  static Concert = 6;
  static Crime = 7;
  static Documentary = 8;
  static Drama = 9;
  static Dramedy = 10;
  static Eroticism = 11;
  static Family = 12;
  static Fantasy = 13;
  static History = 14;
  static Horror = 15;
  static MartialArts = 16;
  static Music = 17;
  static Musical = 18;
  static Mystery = 19;
  static Opera = 20;
  static Romance = 21;
  static School = 22;
  static ScienceFiction = 23;
  static Sport = 24;
  static Suspense = 25;
  static Teen = 26;
  static Thriller = 27;
  static War = 28;
  static NotApplicable = 29;
  static Ballet = 30;

  static All = [
    {
      name: "Action",
      value: this.Action,
    },
    {
      name: "Adventure",
      value: this.Adventure,
    },
    {
      name: "Animation",
      value: this.Animation,
    },
    {
      name: "Biography",
      value: this.Biography,
    },
    {
      name: "Comedy",
      value: this.Comedy,
    },
    {
      name: "Concert",
      value: this.Concert,
    },
    {
      name: "Crime",
      value: this.Crime,
    },
    {
      name: "Documentary",
      value: this.Documentary,
    },
    {
      name: "Drama",
      value: this.Drama,
    },
    {
      name: "Dramedy",
      value: this.Dramedy,
    },
    {
      name: "Eroticism",
      value: this.Eroticism,
    },
    {
      name: "Family",
      value: this.Family,
    },
    {
      name: "Fantasy",
      value: this.Fantasy,
    },
    {
      name: "History",
      value: this.History,
    },
    {
      name: "Horror",
      value: this.Horror,
    },
    {
      name: "MartialArts",
      value: this.MartialArts,
    },
    {
      name: "Music",
      value: this.Music,
    },
    {
      name: "Musical",
      value: this.Musical,
    },
    {
      name: "Mystery",
      value: this.Mystery,
    },
    {
      name: "Opera",
      value: this.Opera,
    },
    {
      name: "Romance",
      value: this.Romance,
    },
    {
      name: "School",
      value: this.School,
    },
    {
      name: "ScienceFiction",
      value: this.ScienceFiction,
    },
    {
      name: "Sport",
      value: this.Sport,
    },
    {
      name: "Suspense",
      value: this.Suspense,
    },
    {
      name: "Teen",
      value: this.Teen,
    },
    {
      name: "Thriller",
      value: this.Thriller,
    },
    {
      name: "War",
      value: this.War,
    },
    {
      name: "NotApplicable",
      value: this.NotApplicable,
    },
    {
      name: "Ballet",
      value: this.Ballet,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class Restriction {
  static NoFreeTickets = 1;
  static NoRestriction = 2;
  static StrictlyNoFreeTickets = 3;

  static All = [
    {
      name: "No Free Tickets",
      value: this.NoFreeTickets,
    },
    {
      name: "No Restriction",
      value: this.NoRestriction,
    },
    {
      name: "Strictly No Free Tickets",
      value: this.StrictlyNoFreeTickets,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class PerformanceStatus {
  static Draft = 1;
  static Pending = 2;
  static Public = 3;
  static Blocked = 4;
  static Cancelled = 5;

  static All = [
    {
      name: "Draft",
      value: this.Draft,
      color: "#808080",
    },
    {
      name: "Pending",
      value: this.Pending,
      color: "#FFFF00",
    },
    {
      name: "Public",
      value: this.Public,
      color: "#00FF00",
    },
    {
      name: "Blocked",
      value: this.Blocked,
      color: "#FFA500",
    },
    {
      name: "Cancelled",
      value: this.Cancelled,
      color: "#FF0000",
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }

  static getColor(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.color;
  }
}

export class SeatingStatus {
  static Available = 1;
  static SellingFast = 2;
  static SoldOut = 3;

  static All = [
    {
      name: "Available",
      value: this.Available,
    },
    {
      name: "Selling Fast",
      value: this.SellingFast,
    },
    {
      name: "Sold Out",
      value: this.SoldOut,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MediaType {
  static Poster = 1;
  static Trailer = 2;
  static Photo = 3;

  static All = [
    {
      name: "Poster",
      value: this.Poster,
    },
    {
      name: "Trailer",
      value: this.Trailer,
    },
    {
      name: "Photo",
      value: this.Photo,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MediaLinkPlaceHolder {
  static Video = 1;
  static All = [
    {
      name: "https://youtube.com/embed/xxxxx",
      value: this.Video,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CrewRole {
  static None = 0;
  static Writer = 1;
  static Producer = 2;
  static Director = 4;
  static Cast = 8;
  static Studio = 16;

  static All = [
    {
      name: "Writer",
      value: this.Writer,
    },
    {
      name: "Producer",
      value: this.Producer,
    },
    {
      name: "Director",
      value: this.Director,
    },
    {
      name: "Cast",
      value: this.Cast,
    },
    {
      name: "Studio",
      value: this.Studio,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class MovieDisplayWeight {
  static Length = 125;
}

export class MovieReleaseLabel {
  static Length = 25;
}
