import React from "react";
import { BaseListing } from "../../common/BaseListing";
import { Constants } from "./Constants";
import { StringHelper } from "../../../common/Helpers";
import _ from "lodash";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {
  CreditCardProgram,
  PaymentStatus,
  PaymentType,
  TransactionStatus,
} from "../../../constants/TransactionConstants";
import { ApplicationID } from "../../../common/Constants";

export class TransactionListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      search: "",
      selectedSearchBy: Constants.SearchBy.ReferenceNumber,
      selectedSearchFrom: Constants.SearchFrom.Live,
      includeHistorical: false,
      appId: this.getQueryParam("appId") || 0,
      status: parseInt(this.getQueryParam("status")) || 0,
      paymentType: this.getQueryParam("paymentType") || 0,
      paymentStatus: this.getQueryParam("paymentStatus") || 0,
      creditCardProgram: this.getQueryParam("creditCardProgram") || 0,
    };
  }

  updateUrlParams = () => {
    const { appId, status, paymentType, paymentStatus, creditCardProgram } = this.state;
    const queryParams = new URLSearchParams(window.location.search);
    if (appId !== 0) {
      queryParams.set("appId", appId);
    }
    if (status !== 0) {
      queryParams.set("status", status);
    }
    if (paymentType !== 0) {
      queryParams.set("paymentType", paymentType);
    }
    if (paymentStatus !== 0) {
      queryParams.set("paymentStatus", paymentStatus);
    }
    if(creditCardProgram !== 0) {
      queryParams.set("creditCardProgram", creditCardProgram);
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${queryParams.toString()}`
    );
  };

  getQueryParam = (name) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(name);
  };

  onIconClick = (id) => {
    const { activeModule } = this.getNavigationSettings();
    return `${activeModule.identifier}/${id}`;
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "transactions",
        name: "Transaction Management",
      }),
      activeModule: new NavigationItem({
        identifier: "transactions",
        name: "Transactions",
      }),
    });
  };

  getDefaultSort = () => "-CreatedOn";

  getApiPath = () => "/api/v1/transactions";

  injectSearchTerm = (queryParameters) => {
    let searchTerm = super.injectSearchTerm(
      queryParameters,
      this.createSearchByParam()
    );
    const { includeHistorical, appId, paymentType, status, paymentStatus, creditCardProgram } =
      this.state;
    searchTerm = {
      ...searchTerm,
      includeHistorical,
      includes: "PaymentCredits",
    };

    const criteria = { appId, paymentType, status, paymentStatus, creditCardProgram };

    Object.entries(criteria).forEach(([key, value]) => {
      if (value) {
        searchTerm[key] = value;
      }
    });

    return searchTerm;
  };

  onResetFilter = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("appId");
    url.searchParams.delete("status");
    url.searchParams.delete("paymentType");
    url.searchParams.delete("paymentStatus");
    url.searchParams.delete("creditCardProgram");
    this.setState(
      {
        appId: 0,
        status: 0,
        paymentType: 0,
        paymentStatus: 0,
        creditCardProgram: 0,
      },
      () => {
        window.history.replaceState({}, "", url.toString());
        this.updateUrlParams();
        this.search();
      }
    );
  };

  onCriteriaChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value }, () => {
      this.updateUrlParams();
      this.search();
    });
  };

  generateTableContent = () => {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th></th>
            <th>Reference Number</th>
            <th>Payment Status</th>
            <th>Status</th>
            <th>Sales Channel</th>
            <th>Contact Number</th>
            <th>Email Address</th>
            <th>Payment Type</th>
            <th>Amount</th>
            <th className="pointer" onClick={this.onColumnClick}>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index} style={{backgroundColor: TransactionStatus.getBackgroundColor(item.status)}}>
                <td>
                  <a href={this.onIconClick(item.id)} target={"_blank"}>
                    {" "}
                    <i class="fas fa-external-link-alt"></i>
                  </a>
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {item.referenceNumber}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  <span
                    className={`${PaymentStatus.getColor(item.paymentStatus)}`}
                  >
                    {PaymentStatus.getName(item.paymentStatus)}
                  </span>
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  <span
                    className={`${TransactionStatus.getColor(item.status)}`}
                  >
                    {TransactionStatus.getName(item.status)}
                  </span>
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {item.salesChannel}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {item.contactNumber}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {item.emailAddress}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {PaymentType.getName(item.paymentType)} {item.paymentCredits?.length ? `[${CreditCardProgram.getName(item.paymentCredits[0].creditCardProgram)}]` : ""}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  ${item.grossAmount.toFixed(6)}
                </td>
                <td onClick={() => this.onRowClick(item.id)}>
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    item.updatedOn
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  };

  generateExtendedComponents = () => {
    return (
      <>
        <div className="d-block d-xl-flex flex-row-reverse">
          {this.generateCriteriaFilter()}
          {this.generateSearchFunctions()}
        </div>
        {this.generateActions()}
      </>
    );
  };

  generateActions = () => {
    return (
      <div className="row form-group input-group mx-0 px-0 col-md-6">
        <input
          className="form-control search"
          id="SearchTxt"
          type="Text"
          placeholder={this.getPlaceHolder()}
          onChange={(e) => this.setState({ search: e.target.value })}
          onKeyDown={this.onKeyDownSearchInput}
        />
        <button className="btn btn-primary search" onClick={this.search}>
          <i className="fas fa-search" /> Search{" "}
        </button>
      </div>
    );
  };

  generateCriteriaFilter() {
    const { appId, status, paymentType, paymentStatus, creditCardProgram } = this.state;
    return (
      <div className="row px-0 col-xl-2 mx-xl-0">
        <div className="col-md-4 mb-3 col-xl-12">
          <label htmlFor="appId">Sales Channel</label>
          <select
            name="appId"
            id="appId"
            className="form-control"
            value={appId}
            onChange={this.onCriteriaChange}
          >
            <option value="0">All Sales Channel</option>
            {ApplicationID.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mb-3 col-xl-12">
          <label htmlFor="status">Status</label>
          <select
            name="status"
            id="status"
            className="form-control"
            value={status}
            onChange={this.onCriteriaChange}
          >
            <option value="">All Status</option>
            {TransactionStatus.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mb-3 col-xl-12">
          <label htmlFor="paymentType">Payment Type</label>
          <select
            name="paymentType"
            id="paymentType"
            className="form-control"
            value={paymentType}
            onChange={this.onCriteriaChange}
          >
            <option value="0">All Payment Type</option>
            {PaymentType.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mb-3 col-xl-12">
          <label htmlFor="paymentType">Payment Status</label>
          <select
            name="paymentStatus"
            id="paymentStatus"
            className="form-control"
            value={paymentStatus}
            onChange={this.onCriteriaChange}
          >
            <option value="">All Payment Status</option>
            {PaymentStatus.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mb-3 col-xl-12">
          <label htmlFor="creditCardProgram">Credit Card Program</label>
          <select
            name="creditCardProgram"
            id="creditCardProgram"
            className="form-control"
            value={creditCardProgram}
            onChange={this.onCriteriaChange}
          >
            <option value="">All Card Program</option>
            {CreditCardProgram.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-4 mb-3 col-xl-12">
          <button className="btn btn-primary" onClick={this.onResetFilter}>
            Reset Filter
          </button>
        </div>
      </div>
    );
  }

  getPlaceHolder = () => {
    const { selectedSearchBy } = this.state;
    if (selectedSearchBy === Constants.SearchBy.ReferenceNumber) {
      return "Enter Transaction Number";
    }

    if (selectedSearchBy === Constants.SearchBy.CreditCard) {
      return "Enter Credit Card Reference";
    }

    if (selectedSearchBy === Constants.SearchBy.ContactNumber) {
      return "Enter Customer Contact Number";
    }

    return "Enter Customer Email Address";
  };

  generateSearchFunctions = () => {
    const { includeHistorical } = this.state;
    return (
      <div className="row form-group px-0 col-xl-10 mx-xl-0">
        <label className="form-control-label col-md-2">Search By:</label>
        <div className="col-md-4">
          <div className="form-check">
            <div className="radio">
              <label htmlFor="TransNo" className="form-check-label ">
                <input
                  type="radio"
                  id="TransNo"
                  name="SearchBy"
                  value={Constants.SearchBy.ReferenceNumber}
                  checked={
                    this.state.selectedSearchBy ===
                    Constants.SearchBy.ReferenceNumber
                  }
                  className="mr-1"
                  onChange={() =>
                    this.selectSearchBy(Constants.SearchBy.ReferenceNumber)
                  }
                />
                Transaction Number
              </label>
            </div>
            <div className="radio">
              <label htmlFor="CCRef" className="form-check-label ">
                <input
                  type="radio"
                  id="CCRef"
                  name="SearchBy"
                  value={Constants.SearchBy.CreditCard}
                  checked={
                    this.state.selectedSearchBy ===
                    Constants.SearchBy.CreditCard
                  }
                  className="mr-1"
                  placeholder="Enter Credit Card Reference"
                  onChange={() =>
                    this.selectSearchBy(Constants.SearchBy.CreditCard)
                  }
                />
                Credit Card Reference
              </label>
            </div>
            <div className="radio">
              <label htmlFor="ContactNo" className="form-check-label ">
                <input
                  type="radio"
                  id="ContactNo"
                  name="SearchBy"
                  value={Constants.SearchBy.ContactNumber}
                  checked={
                    this.state.selectedSearchBy ===
                    Constants.SearchBy.ContactNumber
                  }
                  className="mr-1"
                  placeholder="Enter Customer Contact Number"
                  onChange={() =>
                    this.selectSearchBy(Constants.SearchBy.ContactNumber)
                  }
                />
                Customer Contact Number
              </label>
            </div>
            <div className="radio">
              <label htmlFor="Email" className="form-check-label ">
                <input
                  type="radio"
                  id="Email"
                  name="SearchBy"
                  value={Constants.SearchBy.EmailAddress}
                  checked={
                    this.state.selectedSearchBy ===
                    Constants.SearchBy.EmailAddress
                  }
                  className="mr-1"
                  placeholder="Enter Customer Email Address"
                  onChange={() =>
                    this.selectSearchBy(Constants.SearchBy.EmailAddress)
                  }
                />
                Customer Email Address
              </label>
            </div>
          </div>
        </div>
        <label className="form-control-label col-md-2">Search From:</label>
        <div className="col-md-4">
          <div className="row">
            <div className="col-10">
              <label htmlFor="Live" className="form-check-label ">
                Today And Upcoming Transaction
              </label>
            </div>
            <div className="col-2">
              <input
                type="radio"
                className="mr-1"
                name="searchFrom"
                value={includeHistorical}
                onChange={() => this.setState({ includeHistorical: false })}
                checked={!includeHistorical}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-10">
              <label htmlFor="Historical" className="form-check-label ">
                Past Transactions
              </label>
            </div>
            <div className="col-2">
              <input
                type="radio"
                className="mr-1"
                name="searchFrom"
                value={includeHistorical}
                onChange={() => this.setState({ includeHistorical: true })}
                checked={includeHistorical}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  selectSearchBy = (e) => {
    this.setState({
      selectedSearchBy: e,
    });
  };

  createSearchByParam = () => {
    const { search } = this.state;
    if (_.isEmpty(search)) {
      return null;
    }

    if (this.state.selectedSearchBy === Constants.SearchBy.CreditCard) {
      return { creditCardReference: search };
    }

    if (this.state.selectedSearchBy === Constants.SearchBy.ContactNumber) {
      return { contactNumber: search };
    }

    if (this.state.selectedSearchBy === Constants.SearchBy.EmailAddress) {
      return { emailAddress: search };
    }

    return { referenceNumber: search };
  };
}
