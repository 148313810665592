import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { ReportRequestStatus } from "../common/Constants";

export const RequestReportDetailCard = (props) => {
  const { requestReport, onCancel, onDownload } = props;

  const isDisabled =
    requestReport.requestStatus !== ReportRequestStatus.ProcessedSuccessful;

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Requested Report
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="container-fluid">
                <div className="row form-group">
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Name:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={requestReport.reportRegister.reportName}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Code:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={requestReport.reportRegister.reportCode}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Format:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={requestReport.requestFormat}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Status:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={ReportRequestStatus.getName(
                        requestReport.requestStatus
                      )}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Started By:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={requestReport.requestStartedBy}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Ended By:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={requestReport.requestEndedBy ?? ""}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Started On:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={StringHelper.asDateStringToDisplayDateTimeFormat(
                        requestReport.requestStartedOn
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className=" form-control-label text-nowrap">
                      Report Ended On:
                    </label>
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={StringHelper.asDateStringToDisplayDateTimeFormat(
                        requestReport.requestEndedOn
                      )}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-md-12">
                    <label className=" form-control-label text-nowrap">
                      Report Parameters:
                    </label>
                    <textarea
                      className="form-control display-input"
                      readOnly={true}
                      rows={
                        requestReport.requestParameters.toString().split("|")
                          .length
                      }
                      style={{ resize: "none" }}
                      value={requestReport.requestParameters
                        .toString()
                        .replaceAll("|", "\n")
                        .replaceAll("::", ": ")}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                title={
                  isDisabled
                    ? "Cannot download the report that is not completed yet."
                    : ""
                }
                disabled={isDisabled}
                onClick={onDownload}
              >
                Download
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
