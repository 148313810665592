import _ from "lodash";

export class ReportCategory {
  static Theatre = 1;
  static Internal = 2;

  static All = [
    {
      name: "Theatre",
      code: "TRPT",
      value: this.Theatre,
    },
    {
      name: "Internal",
      code: "INRP",
      value: this.Internal,
    },
  ];

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.code;
  }
}

export class ReportParameterValueType {
  static Date = "DA";
  static Decimal = "DM";
  static Datetime = "DT";
  static Numeric = "NM";
  static String = "ST";
  static Time = "TM";

  static All = [
    {
      name: "Date",
      value: this.Date,
    },
    {
      name: "Decimal",
      value: this.Decimal,
    },
    {
      name: "Datetime",
      value: this.Datetime,
    },
    {
      name: "Numeric",
      value: this.Numeric,
    },
    {
      name: "String",
      value: this.String,
    },
    {
      name: "Time",
      value: this.Time,
    },
  ];
}

export class ReportRequestStatus {
  static AbortedSuccessful = "ABS";
  static Aborting = "ABT";
  static AbortedByUser = "ABU";
  static CancelledByUser = "CNU";
  static NewRequest = "NEW";
  static ProcessingError = "PSE";
  static Processing = "PRS";
  static ProcessedSuccessful = "PSS";
  static Processed = "PSU";
  static PendingProcessing = "PPS";

  static All = [
    {
      name: "Aborted Successful",
      value: this.AbortedSuccessful,
      color: "color-gray",
    },
    {
      name: "Aborting",
      value: this.Aborting,
      color: "color-orange",
    },
    {
      name: "Aborted By User",
      value: this.AbortedByUser,
      color: "color-red",
    },
    {
      name: "Cancelled By User",
      value: this.CancelledByUser,
      color: "color-red",
    },
    {
      name: "Processing",
      value: this.Processing,
      color: "color-orange",
    },
    {
      name: "Pending Processing",
      value: this.PendingProcessing,
      color: "color-blue",
    },
    {
      name: "New Request",
      value: this.NewRequest,
      color: "color-blue",
    },
    {
      name: "Processed Successful",
      value: this.ProcessedSuccessful,
      color: "color-green",
    },
    {
      name: "Processed",
      value: this.Processed,
      color: "color-gray",
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}
