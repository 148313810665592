import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ViewMode } from "../../../constants/PricingConstants";
import { CampaignCategory } from "../../../constants/CampaignConstants";
import { MoviePromotionValidityService } from "../../../services/MoviePromotionValidityService";
import { MoviePromotionValidity } from "../../../models/MoviePromotionValidity";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { DateTimeInput } from "../../campaigns/DateTimeInput";
import { CustomSelect } from "../../common/CustomSelect";
import { MovieFormat } from "../../../constants/MovieConstants";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const MoviePromotionValidityDetail = ({ match: { params } }) => {
  const { id } = params;
  const [viewMode, setViewMode] = useState(ViewMode.Readonly);
  const [moviePromotionValidity, setMoviePromotionValidity] = useState(new MoviePromotionValidity({}));
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const moviePromotionValidityService = useMemo(() => new MoviePromotionValidityService(), []);
  const history = useHistory();

  useEffect(() => {
    moviePromotionValidityService.get(id, { includes: "MovieRelease" }).then((response) => {
      setMoviePromotionValidity(new MoviePromotionValidity(response.data));
    });
  }, [id, moviePromotionValidityService, viewMode]);

  const onDelete = () => {
    moviePromotionValidityService
      .delete(id)
      .then(() => {
        history.push("/movie-promotion-validity");
        toast.success("The movie promotion validity has been deleted successful.");
      })
      .catch((error) => {
        toast.error(error.message);
      }).finally(() => {
        setShowDeleteModal(false);
      });
  };

  const onUpdate = () => {
    setShowUpdateConfirmation(false);
    moviePromotionValidityService
      .put(moviePromotionValidity.id, moviePromotionValidity)
      .then((response) => {
        if (response) {
          toast.success("Update movie promotion validity information success");
          setViewMode(ViewMode.Readonly);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const onSelectChange = (e, name) => {
    const value = e.value;
    setMoviePromotionValidity((data) => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  const onCancelUpdate = () => {
    setShowUpdateConfirmation(false);
  };

  const onCancel = () => {
    setViewMode(ViewMode.Readonly);
    setShowCancelConfirmation(false);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setMoviePromotionValidity((moviePromotionValidity) => {
      return {
        ...moviePromotionValidity,
        [fieldName]: selectValue,
      };
    });
  };

  const onEdit = () => {
    setViewMode(ViewMode.Editable);
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-promotion-validity-detail",
        name:
          viewMode === ViewMode.Readonly
            ? "View Movie Promotion Validity"
            : "Edit Movie Promotion Validity",
      }),
    });
  };

  if (!moviePromotionValidity) return <></>;
  const movieReleaseIdentifier = `${moviePromotionValidity?.movieRelease?.primaryTitle}-${moviePromotionValidity.movieRelease?.id
    }-${MovieFormat.getName(moviePromotionValidity.movieRelease?.format)}`;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="container-fluid">
              {viewMode === ViewMode.Readonly && (
                <ButtonActionDetailGroup>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.MovieReleasePromotionValidityEditButtonPage
                  ) && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-success"
                        onClick={onEdit}
                      >
                        <i className="fas fa-edit" aria-hidden="true" /> Edit
                      </ButtonActionDetailGroup.Item>
                    )}
                  {AuthenticationManager.isAuthorized(
                    ResourceID.MovieReleasePromotionValidityEditButtonPage
                  ) && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-danger"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        Delete
                      </ButtonActionDetailGroup.Item>
                    )}
                </ButtonActionDetailGroup>
              )}
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Movie Promotion Validity Details
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row form-group">
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Movie Release"
                            require={true}
                            input={
                              <input
                                className="form-control display-input"
                                value={movieReleaseIdentifier}
                                id="moviereleaseId"
                                name="moviereleaseId"
                                disabled={true}
                                onChange={onInputChange}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Campaign Category"
                            input={
                              <CustomSelect
                                data={CampaignCategory.All}
                                label="name"
                                value="value"
                                onChange={(e) =>
                                  onSelectChange(e, "campaignCategory")
                                }
                                disabled={viewMode === ViewMode.Readonly}
                                selectedValue={
                                  moviePromotionValidity.campaignCategory
                                }
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Start On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="startOn"
                                value={moviePromotionValidity.startOn}
                                onChange={onInputChange}
                                placeholder="Enter Start On"
                                readOnly={viewMode === ViewMode.Readonly}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="End On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="endOn"
                                value={moviePromotionValidity.endOn}
                                onChange={onInputChange}
                                placeholder="Enter End On"
                                readOnly={viewMode === ViewMode.Readonly}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewMode === ViewMode.Editable && (
                <ButtonActionDetailGroup>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    onClick={() => setShowUpdateConfirmation(true)}
                  >
                    Update
                  </ButtonActionDetailGroup.Item>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    onClick={() => setShowCancelConfirmation(true)}
                  >
                    Cancel
                  </ButtonActionDetailGroup.Item>
                </ButtonActionDetailGroup>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showDeleteModal}
        message="Are you sure you want to delete the movie promotion validity?"
        onProceed={onDelete} onCancelUpdate
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        onCancel={() => setShowCancelConfirmation(false)}
        onProceed={() => onCancel()}
        title="Cancel confirmation"
      />
      <ConfirmDialog
        visible={showUpdateConfirmation}
        onCancel={() => onCancelUpdate()}
        onProceed={() => onUpdate()}
        title="Update confirmation"
        message="Are you sure want to update movie promotion validity information?"
      />
    </>
  );
};
