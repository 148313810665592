import React from "react";
import { StringHelper } from "../../../../common/Helpers";
import { ExtensionBy, ExtensionMode } from "../../common/Constants";

export class PassExtendSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extensionBy: ExtensionBy.None,
      extensionMode: ExtensionMode.BySpecifiedDate,
      extensionQuantity: 0,
      newExpiryDateStr: "",
      quantityOfDate: 0,
    };
  }

  componentDidMount() {
    this.onModelChange();
  }

  onExtensionByChange = (e) => {
    const value = StringHelper.toNumber(e.target.value, 0);
    this.setState(
      {
        extensionBy: value,
        extensionMode:
          value === ExtensionBy.Custom
            ? ExtensionMode.ByNumberOfDays
            : ExtensionMode.BySpecifiedDate,
      },
      this.onModelChange
    );
  };

  onModelChange = () => {
    if (this.props.onModelChange) {
      const {
        extensionBy,
        extensionMode,
        extensionQuantity,
        newExpiryDateStr,
      } = this.state;
      const model = {
        extensionBy,
        extensionMode,
        extensionQuantity,
        newExpiryDate: StringHelper.toDateTimeString(newExpiryDateStr),
      };
      this.props.onModelChange(model);
    }
  };

  clear = () => {
    const newModel = {
      extensionBy: ExtensionBy.None,
      extensionMode: ExtensionMode.BySpecifiedDate,
      extensionQuantity: 0,
      newExpiryDateStr: "",
      quantityOfDate: 0,
    };
    this.setState(newModel);
  };

  onNumericBoxChange = (e) => {
    const fieldName = e.target.getAttribute("data-field-name");
    const value = StringHelper.toNumber(e.target.value, 0);
    this.setState({ [fieldName]: value }, this.onModelChange);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("data-field-name");
    const value = e.target.value;
    this.setState({ [fieldName]: value }, this.onModelChange);
  };

  render() {
    const { extensionBy, extensionMode, extensionQuantity, newExpiryDateStr } =
      this.state;
    const enableSpecifiedDate = extensionBy === ExtensionBy.Date;
    const enableCustomDate = extensionBy === ExtensionBy.Custom;
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <label className="form-control-label d-inline-flex m-l-15">
              Extension Criteria:
            </label>
            <div className="col-md-4 mb-3">
              <div className="form-check">
                <div className="radio">
                  <label htmlFor="predefDate" className="form-check-label ">
                    <input
                      type="radio"
                      id="predefDate"
                      name="Extension"
                      className="form-check-input"
                      value="Date"
                      checked={enableSpecifiedDate}
                      onChange={this.onExtensionByChange}
                      value={ExtensionBy.Date}
                    />
                    By pre-defined date
                  </label>
                  <input
                    className="form-control Date"
                    name="Date"
                    type="date"
                    placeholder="Enter pre-defined date"
                    data-field-name="newExpiryDateStr"
                    disabled={enableSpecifiedDate ? undefined : "disabled"}
                    onChange={this.onTextBoxChange}
                    value={newExpiryDateStr}
                  />
                </div>
                <div className="radio">
                  <label htmlFor="DurationField" className="form-check-label">
                    <input
                      type="radio"
                      id="DurationField"
                      name="Extension"
                      className="form-check-input"
                      value="SNrange"
                      checked={enableCustomDate}
                      onChange={this.onExtensionByChange}
                      value={ExtensionBy.Custom}
                    />
                    By adding duration to existing validity end
                  </label>
                  <br />
                  <label
                    className="form-control-label row mt-2 DurationField"
                    style={{ padding: "0 15px" }}
                  >
                    Type:
                    <select
                      className="form-control ml-3 col DurationField"
                      id="durationType"
                      data-field-name="extensionMode"
                      onChange={this.onNumericBoxChange}
                      value={extensionMode}
                    >
                      <option value={ExtensionMode.ByNumberOfDays}>Days</option>
                      <option value={ExtensionMode.ByNumberOfMonths}>
                        Months
                      </option>
                      <option value={ExtensionMode.ByNumberOfYears}>
                        Years
                      </option>
                    </select>
                  </label>
                  <label
                    className="form-control-label row mt-2 DurationField"
                    style={{ padding: "0 15px" }}
                  >
                    Value:
                    <input
                      id="Duration"
                      className="form-control ml-2 col DurationField"
                      placeholder="Enter number of days"
                      type="number"
                      min="1"
                      max="100"
                      data-field-name="extensionQuantity"
                      onChange={this.onNumericBoxChange}
                      value={extensionQuantity}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
