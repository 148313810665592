import React from "react";
import _ from "lodash";
import { DateHelper } from "../../common/Helpers";

export const DateTimeInput = (props) => {
  const { name, value, onChange, placeholder, readOnly } = props;

  if (!_.isNil(readOnly) && readOnly) {
    return (
      <input className="form-control display-input" value={DateHelper.toDisplayDateTimeFormat(value)} readOnly />
    );
  }
  return (
    <input
      type="datetime-local"
      className="form-control"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};
