import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import {reportingMovieLanguagesAtom} from "./Report.state";

const QUERY_KEY_REPORTING_MOVIE_LANGUAGES = "QUERY_KEY_REPORTING_MOVIE_LANGUAGES";

export function useReportingMovieLanguages() {
  const [languages, setLanguages] = useRecoilState(reportingMovieLanguagesAtom);
  useQuery(
      QUERY_KEY_REPORTING_MOVIE_LANGUAGES,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/safra_campaigns"
      ).list();
    },
    {
      onSuccess: (response) => setLanguages(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return languages;
}
