import React from "react";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { EvoucherOrderCard } from "./cards/EvoucherOrderCard";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { EvoucherOrderMode } from "../common/EvoucherOrderMode";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class EvoucherOrderCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherOrderMode: EvoucherOrderMode.Create,
      evoucherOrder: null,
      evoucherOrderValidations: [],
      redirect: false,
      showCancelDialog: false,
      showConfirmDialog: false,
      isClear: false,
    };
  }

  isValidated() {
    const { evoucherOrderValidations } = this.state;
    return evoucherOrderValidations.every((val) => val.isValid);
  }

  onEvoucherOrderModelChange = (model) => {
    this.setState({ evoucherOrder: model });
  };

  onEvoucherOrderValidationsChange = (validations) => {
    this.setState({ evoucherOrderValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onClearClick = () => {
    this.setState({ evoucherOrder: null, isClear: !this.state.isClear });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { evoucherOrder } = this.state;
    const request = Object.assign({}, evoucherOrder);
    RestClient.sendPostRequest(
      "/api/v1/evoucher_orders/",
      request,
      () => {
        this.setState({
          showConfirmDialog: false,
        });
        toast.success("eVoucher Order have been created successfully.");
        this.redirectToListingPage(
          "?created=eVoucherOrders have been created successfully."
        );
      },
      (errorResponse) => {
        this.setState({
          showConfirmDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/evouchers/orders/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-order-creation",
        name: "Create eVoucher Order",
      }),
    });
  };

  render() {
    const { isSubmit, evoucherOrder, showCancelDialog, showConfirmDialog } =
      this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <EvoucherOrderCard
                  isClear={this.state.isClear}
                  cardMode={this.state.evoucherOrderMode}
                  model={evoucherOrder}
                  isSubmit={isSubmit}
                  onModelChange={this.onEvoucherOrderModelChange}
                  onValidationsChange={this.onEvoucherOrderValidationsChange}
                />
              </div>
            </div>
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-success"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={this.onClearClick}
              >
                Clear
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message="Are you sure you want to cancel the creation of eVoucher Order?"
          title="Cancel eVoucher Order creation"
        />
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to create eVoucher Order?"
          title="Create eVoucher Order"
        />
      </div>
    );
  }
}
