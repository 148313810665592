import React from "react";
import { showLoading } from "../../../common/Common";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import { DateHelper } from "../../../common/Helpers";
import {
  EVoucherStatus,
  GenericMessages,
  SearchEvoucherBy,
} from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { RowFormField } from "../../layout/FormLayout";
import { ExtendValidityDialog } from "./dialogs/ExtendValidityDialog";
import { BaseConfirmReasonDialog } from "../common/BaseConfirmReasonDialog";
import { FileDownloadModal } from "../batches/dialogs/FileDownloadModal";
import { EVoucherService } from "../../../services/EVoucherService";
import { EVoucherExtensionRequestRestService } from "../../../services/EVoucherExtensionRequestRestService";
import { toast } from "react-toastify";
import { EVoucherExporter } from "../common/EVoucherExporter";
import _ from "lodash";

export class EvoucherListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      searchBy: SearchEvoucherBy.Code,
      evoucherPrograms: [],
      evoucherStatuses: [],
      evoucherStatus: this.getQueryParamValue("evoucherStatus", ""),
      evoucherProgramId: 0,
      eVoucherOrderReference: "",
      eVoucherBatchReference: "",
      code: "",
      selectedEVouchers: [],
      isShowBlockDialog: false,
      isShowUnblockDialog: false,
      isShowExtendValidityDialog: false,
      isShowExportFileDialog: false,
      isSelectAll: false,
    };
    this.eVoucherService = new EVoucherService();
    this.eVoucherExtensionRequestRestService =
      new EVoucherExtensionRequestRestService();
  }

  componentDidMount() {
    this.loadData(0);
    this.loadEvoucherStatuses();
    this.loadEvoucherPrograms();
  }

  componentDidUpdate(prevProps, prevState) {
    const { data, selectedEVouchers } = this.state;
    if (
      data &&
      (!_.isEqual(prevState.data, data) ||
        !_.isEqual(prevState.selectedEVouchers, selectedEVouchers))
    ) {
      const isSelectAll = data.every((dataItem) =>
        selectedEVouchers.some(
          (selectedItem) => selectedItem.id === dataItem.id
        )
      );
      this.setState({
        isSelectAll: isSelectAll,
      });
    }
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVoucher",
      }),
    });
  };

  loadEvoucherStatuses = () => {
    RestClient.sendGetRequest(
      this.getApiPath() + "/statuses",
      (response) => {
        this.setState({ evoucherStatuses: response });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  loadEvoucherPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_programs",
      (response) => {
        this.setState({ evoucherPrograms: response.data });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  getApiPath = () => "/api/v1/evouchers";

  getDefaultSort = () => "-UpdatedOn";

  onProgramChange = (e) => {
    this.setState({ evoucherProgramId: e.target.value });
  };

  onStatusChange = (e) => {
    this.setState({ evoucherStatus: e.target.value });
  };

  onSearchByChange = (e) => {
    if (this.state.searchBy !== e.target.value) {
      this.setState({
        searchBy: e.target.value,
        eVoucherOrderReference: "",
        eVoucherBatchReference: "",
        code: "",
      });
    }
  };

  injectSearchTerm(queryParameters, searchBy) {
    const eVoucherBatchReference = this.state.eVoucherBatchReference;
    const eVoucherOrderReference = this.state.eVoucherOrderReference;
    const { code, evoucherStatus, evoucherProgramId } = this.state;

    return super.injectSearchTerm(queryParameters, {
      eVoucherBatchReference,
      eVoucherOrderReference,
      code,
      evoucherProgramId,
      status: evoucherStatus,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectEVoucher = (e, item) => {
    const isChecked = e.target.checked;
    const { selectedEVouchers } = this.state;

    this.setState({
      selectedEVouchers: isChecked
        ? [...selectedEVouchers, item]
        : selectedEVouchers.filter(
            (selectedItem) => selectedItem.id !== item.id
          ),
    });
    if (!isChecked) {
      this.setState({ isSelectAll: false });
    }
  };

  onSelectAll = (e) => {
    const { data, selectedEVouchers } = this.state;
    const isChecked = e.target.checked;

    const updatedSelectedEVouchers = isChecked
      ? selectedEVouchers.concat(
          data.filter(
            (item) =>
              !selectedEVouchers.some((selected) => selected.id === item.id)
          )
        )
      : [];

    this.setState({
      selectedEVouchers: updatedSelectedEVouchers,
    });
  };

  toggleExtendValidityDialog = (visible) => {
    this.setState({ isShowExtendValidityDialog: visible });
  };

  toggleConfirmBlockDialog = (visible) => {
    this.setState({ isShowBlockDialog: visible });
  };

  toggleConfirmUnblockDialog = (visible) => {
    this.setState({ isShowUnblockDialog: visible });
  };

  toggleExportFileDialog = (visible) => {
    if (!this.validateSelectedItems()) return;
    this.setState({
      isShowExportFileDialog: visible,
    });
  };

  openExtendDialog = () => {
    if (!this.validateSelectedItems()) return;
    this.toggleExtendValidityDialog(true);
  };

  openBlockDialog = () => {
    if (!this.validateSelectedItems()) return;
    const { selectedEVouchers } = this.state;

    const invalidEvouchers = selectedEVouchers.filter(
      (x) => x.status !== EVoucherStatus.Active
    );

    if (invalidEvouchers.length > 0) {
      toast.dismiss();
      toast.error(
        `${invalidEvouchers.map((x) => x.code).join(", ")} cannot be blocked" `
      );
      return;
    }

    this.toggleConfirmBlockDialog(true);
  };

  openUnblockDialog = () => {
    const { selectedEVouchers } = this.state;
    const invalidEvouchers = selectedEVouchers.filter(
      (x) => x.status !== EVoucherStatus.Blocked
    );

    if (!this.validateSelectedItems()) return;

    if (invalidEvouchers.length > 0) {
      toast.dismiss();
      toast.error(
        `${invalidEvouchers.map((x) => x.code).join(", ")} cannot be unblocked`
      );
      return;
    }

    this.toggleConfirmUnblockDialog(true);
  };

  handleMultipleBlocking = async (action, postfixToast) => {
    try {
      showLoading(true);
      toast.dismiss();
      const { data } = this.state;
      const res = await action();
      const updatedData = data.map((item) => {
        const updatedItem = res.find(
          (response) => response && response.id === item.id
        );
        return updatedItem ? updatedItem : item;
      });
      this.setState({ data: updatedData, selectedEVouchers: [] });
      toast.success(`${res.map((x) => x.code).join(", ")}: ${postfixToast}`);
    } finally {
      showLoading(false);
    }
  };

  blockMultiple = async (reason) => {
    const { selectedEVouchers } = this.state;
    await this.handleMultipleBlocking(
      () =>
        this.eVoucherService
          .block({
            ids: selectedEVouchers.map((x) => x.id),
            reason,
          })
          .then((res) => res?.data),
      "has been blocked"
    );
    this.toggleConfirmBlockDialog(false);
  };

  unblockMultiple = async () => {
    const { selectedEVouchers } = this.state;
    if (!this.validateSelectedItems()) return;
    await this.handleMultipleBlocking(
      () =>
        this.eVoucherService
          .unblock({
            ids: selectedEVouchers.map((x) => x.id),
          })
          .then((res) => res?.data),
      "has been unblocked"
    );
    this.toggleConfirmUnblockDialog(false);
  };

  extendMultiple = async ({ reason, newValidityEnd }) => {
    showLoading(true);
    toast.dismiss();
    const { selectedEVouchers } = this.state;
    if (!this.validateSelectedItems()) return;
    const response = await this.eVoucherExtensionRequestRestService.post({
      eVoucherIds: selectedEVouchers.map((x) => x.id),
      newValidityEnd,
      reason,
    });
    showLoading(false);
    if (response && response.status === 201) {
      toast.success(
        GenericMessages.CreationExtensionEvoucherSuccessfullMessage +
          ` ${selectedEVouchers.map((x) => x.code).join(", ")}`
      );
      this.toggleExtendValidityDialog(false);
      this.setState({ selectedEVouchers: [] });
    }
  };

  download = async (password) => {
    toast.dismiss();
    const { selectedEVouchers } = this.state;
    const payload = { ids: selectedEVouchers.map((x) => x.id) };
    showLoading(true);
    const response = await this.eVoucherService.download(payload);
    if (response.status === 200) {
      await EVoucherExporter.toProtectedZipReporting(response.data, password);
      this.toggleExportFileDialog(false);
    }
    showLoading(false);
  };

  validateSelectedItems = () => {
    const { selectedEVouchers } = this.state;
    if (selectedEVouchers.length === 0) {
      toast.dismiss();
      toast.error("Please select at least one eVoucher code");
      return false;
    }
    return true;
  };

  generateTableFilter() {
    const {
      searchBy,
      code,
      eVoucherBatchReference,
      eVoucherOrderReference,
      evoucherProgramId,
      evoucherPrograms,
      evoucherStatuses,
      evoucherStatus,
      selectedEVouchers,
      isShowBlockDialog,
      isShowUnblockDialog,
      isShowExtendValidityDialog,
      isShowExportFileDialog,
    } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-12 col-lg-8">
            <div className="row form-group mb-3 pb-3">
              <label className="form-control-label d-inline-flex text-nowrap col-md-2">
                Search By:
              </label>
              <div className="col-md-10">
                <RowFormField
                  label={
                    <>
                      <input
                        type="radio"
                        name="SearchBy"
                        value={SearchEvoucherBy.Code}
                        className="mr-1"
                        onChange={this.onSearchByChange}
                        checked={searchBy === SearchEvoucherBy.Code}
                      />
                      Code
                    </>
                  }
                  htmlFor="code"
                >
                  <input
                    className="col-8 form-control SingleSN2"
                    id="code"
                    type="text"
                    placeholder="Enter Code"
                    value={code}
                    name="code"
                    onChange={this.onInputChange}
                    disabled={searchBy !== SearchEvoucherBy.Code}
                  />
                </RowFormField>
                <RowFormField
                  label={
                    <>
                      <input
                        type="radio"
                        name="SearchBy"
                        value={SearchEvoucherBy.EVoucherBatchReference}
                        className="mr-1"
                        onChange={this.onSearchByChange}
                        checked={
                          searchBy === SearchEvoucherBy.EVoucherBatchReference
                        }
                      />
                      EVoucher Batch Reference
                    </>
                  }
                  htmlFor="eVoucherBatchReference"
                >
                  <input
                    className="col-8 form-control SingleSN2"
                    id="eVoucherBatchReference"
                    type="text"
                    placeholder="Enter EVoucher Batch Reference"
                    value={eVoucherBatchReference}
                    name="eVoucherBatchReference"
                    onChange={this.onInputChange}
                    disabled={
                      searchBy !== SearchEvoucherBy.EVoucherBatchReference
                    }
                  />
                </RowFormField>
                <RowFormField
                  label={
                    <>
                      <input
                        type="radio"
                        name="SearchBy"
                        value={SearchEvoucherBy.EVoucherOrderReference}
                        className="mr-1"
                        onChange={this.onSearchByChange}
                        checked={
                          searchBy === SearchEvoucherBy.EVoucherOrderReference
                        }
                      />
                      EVoucher Order Reference
                    </>
                  }
                  htmlFor="EVoucherOrderReference"
                >
                  <input
                    className="col-8 form-control SingleSN2"
                    id="EVoucherOrderReference"
                    type="text"
                    placeholder="Enter EVoucher Order Reference"
                    value={eVoucherOrderReference}
                    name="eVoucherOrderReference"
                    onChange={this.onInputChange}
                    disabled={
                      searchBy !== SearchEvoucherBy.EVoucherOrderReference
                    }
                  />
                </RowFormField>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 col-lg-7 col-xl-6 row mx-0 mb-3">
                <label
                  className="align-self-center mb-0 form-control-label col-md-7 col-xl-5 px-0"
                  htmlFor="BatchProgramId"
                >
                  eVoucher Program:
                </label>
                <select
                  className="form-control col-md-5 xol-xl-7"
                  name="eVoucherProgramId"
                  id="eVoucherProgramId"
                  required
                  onChange={this.onProgramChange}
                  value={evoucherProgramId}
                >
                  <option value={0}>All Programs</option>
                  {evoucherPrograms
                    ? evoucherPrograms.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.shortName}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>

              <div className="col-md-4 col-lg-5 col-xl-4 row mx-0 mb-3">
                <label
                  className="align-self-center mb-0 form-control-label col-md-4 px-0"
                  htmlFor="BatchStatus"
                >
                  Status:{" "}
                </label>
                <select
                  className="form-control col-md-8"
                  name="evoucherStatus"
                  id="evoucherStatus"
                  required
                  onChange={this.onStatusChange}
                  value={evoucherStatus}
                >
                  <option value="">All Status</option>
                  {evoucherStatuses
                    ? evoucherStatuses.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                    : null}
                </select>
              </div>

              <div className="col-md-2 col-lg-12 col-xl-2 input-group row mb-3 pr-0">
                <div className="input-group-btn ml-auto">
                  <button className="btn btn-primary" onClick={this.search}>
                    <i className="fa fa-search" /> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-12 col-lg-3 ml-2 mb-2"
            style={{ borderLeft: "solid 2px" }}
          >
            {selectedEVouchers.length > 0 && (
              <h4 className="text-center mb-2">
                {selectedEVouchers.length}&nbsp; Vouchers Selected:
              </h4>
            )}
            {selectedEVouchers.map((x) => x.code).join(", ")}
          </div>
        </div>

        <div
          className="d-flex align-items-center mb-2 p-3"
          style={{ background: "#d9d9d9", fontWeight: 600 }}
        >
          {selectedEVouchers.length > 0 && (
            <div className="mr-2">
              <i
                style={{ marginBottom: 4 }}
                className="fa fa-times-circle btn"
                aria-hidden="true"
                onClick={() => this.setState({ selectedEVouchers: [] })}
              ></i>
              {selectedEVouchers.length}&nbsp; Selected
            </div>
          )}
          {AuthenticationManager.isAuthorized(
            ResourceID.EVoucherListingPageMultipleBlockButton
          ) && (
            <>
              <div
                className="btn btn-danger mr-2"
                onClick={() => this.openBlockDialog()}
              >
                Block
              </div>
              <div
                className="btn btn-secondary mr-2"
                onClick={() => this.openUnblockDialog()}
              >
                Unblock
              </div>
            </>
          )}
          {AuthenticationManager.isAuthorized(
            ResourceID.EVoucherListingPageMultipleExtendButton
          ) && (
            <div
              className="btn btn-warning mr-2 text-white"
              onClick={() => this.openExtendDialog()}
            >
              Extend
            </div>
          )}
          {AuthenticationManager.isAuthorized(
            ResourceID.EVoucherListingPageDownloadButton
          ) && (
            <div
              className="btn btn-success"
              onClick={() => this.toggleExportFileDialog(true)}
            >
              <i className="fa fa-download" aria-hidden="true"></i> Download
              Report
            </div>
          )}
        </div>

        {isShowExtendValidityDialog && (
          <ExtendValidityDialog
            onCancel={() => this.toggleExtendValidityDialog(false)}
            onProceed={this.extendMultiple}
            reasonType={"Reason:"}
            placeholderText={"Enter reason for evoucher extension"}
          />
        )}
        {isShowBlockDialog && (
          <BaseConfirmReasonDialog
            onCancel={() => this.toggleConfirmBlockDialog(false)}
            onProceed={this.blockMultiple}
            message={`Are you sure you want to block ${selectedEVouchers.length} eVouchers?`}
            reasonType="Reason For Blocking:"
            placeholderText="Enter reason for blocking"
          />
        )}
        {isShowUnblockDialog && (
          <BaseConfirmReasonDialog
            onCancel={() => this.toggleConfirmUnblockDialog(false)}
            onProceed={this.unblockMultiple}
            message={`Are you sure you want to unblock ${selectedEVouchers.length} eVouchers?`}
          />
        )}
        {isShowExportFileDialog && (
          <FileDownloadModal
            onSubmit={(password) => this.download(password)}
            onCancel={() => this.toggleExportFileDialog(false)}
          />
        )}
      </>
    );
  }

  generateTableContent() {
    const { data, selectedEVouchers, isSelectAll } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>
              <input
                className="btn"
                type="checkbox"
                checked={isSelectAll}
                onChange={(e) => this.onSelectAll(e)}
              />
            </th>
            <th>Code</th>
            <th>E-Voucher Program</th>
            <th>
              Expiry Date
              <i
                id="ValidityEnd"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td onClick={(e) => e.stopPropagation()}>
                  <input
                    className="btn"
                    type="checkbox"
                    checked={selectedEVouchers.some(
                      (selectedItem) => selectedItem.id === item.id
                    )}
                    onChange={(e) => this.onSelectEVoucher(e, item)}
                  />
                </td>
                <td>{item.code}</td>
                <td>{item.eVoucherProgram?.shortName}</td>
                <td>{DateHelper.toDisplayDateFormat(item.validityEnd)}</td>
                <td className={EVoucherStatus.getColor(item.status)}>
                  {item.status}
                </td>
                <td>
                  {item.updatedOn &&
                    DateHelper.toDisplayDateTimeFormat(item.updatedOn)}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
