import React, { Component } from "react";
import $ from "jquery";
import RestClient from "../../../common/RestClient";
import { Redirect } from "react-router-dom";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { toast } from "react-toastify";

export class RefundCreation extends Component {
  constructor(props) {
    super(props);
    this.messages = {
      notAbleToWatch: "Patron not able to watch movie",
      watchOtherShowtime: "Patron decided to watch other showtime",
    };
    this.refundTypes = {
      full: 0,
      partial: 1,
    };
    this.state = {
      selectedReason: this.messages.notAbleToWatch,
      selectedRefundType: 0,
      partialAmount: 0,
      error: "",
      refundRequest: undefined,
    };
  }

  onSelectReason = (e) => {
    this.setState({
      selectedReason: e,
    });
  };

  onCreate = async () => {
    const { selectedReason, selectedRefundType, partialAmount } = this.state;
    const { transactionId, grossAmount } = this.props;

    if (!selectedReason || selectedReason.length < 10) {
      toast.error("Reason for cancellation must be more than 10 characters.");
      return;
    }

    let request = {
      transactionId,
      reason: selectedReason,
      amount:
        selectedRefundType === this.refundTypes.partial
          ? parseFloat(partialAmount)
          : parseFloat(grossAmount),
      author: AuthenticationManager.username(),
    };
    await RestClient.sendPostRequest(
      "/api/v1/refunds",
      request,
      (response) => {
        $(".modal-backdrop.fade.show").remove();
        this.setState({
          refundRequest: response,
        });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  render() {
    const { refundRequest, selectedReason, selectedRefundType, partialAmount } =
      this.state;
    if (refundRequest && refundRequest.id) {
      return <Redirect push to={`../refunds/${refundRequest.id}`} />;
    }
    return (
      <div
        className="modal fade show"
        id="RefundRequest"
        tabIndex="-1"
        role="dialog"
        style={{ paddingRight: "17px", display: "none" }}
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Refund Request
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <form>
                  <div className="row form-group">
                    <div className="col">
                      <label className="form-control-label" htmlFor="Reason">
                        Reason for cancellation:
                      </label>
                      <textarea
                        value={selectedReason}
                        className="form-control"
                        id="Reason"
                        placeholder="Please enter reason"
                        onChange={(e) =>
                          this.setState({ selectedReason: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="form-check">
                      </div>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col">
                      <label className="form-control-label">Refund Type:</label>
                      <div className="form-check">
                        <div className="radio">
                          <label htmlFor="Full" className="form-check-label ">
                            <input
                              type="radio"
                              name="refund"
                              value={this.refundTypes.full}
                              className="mr-1"
                              checked={
                                selectedRefundType === this.refundTypes.full
                              }
                              onChange={(e) =>
                                this.setState({
                                  selectedRefundType: this.refundTypes.full,
                                })
                              }
                            />
                            Full Refund
                          </label>
                        </div>
                        <div className="radio">
                          <label htmlFor="Partial" className="form-check-label">
                            <input
                              type="radio"
                              name="refund"
                              value={this.refundTypes.partial}
                              className="mr-1"
                              checked={
                                selectedRefundType === this.refundTypes.partial
                              }
                              onChange={(e) =>
                                this.setState({
                                  selectedRefundType: this.refundTypes.partial,
                                })
                              }
                            />
                            Partial Refund
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={
                      selectedRefundType === this.refundTypes.full
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <div className="col">
                      <label className="form-control-label" htmlFor="RefAmount">
                        Refund Amount: ${this.props.grossAmount}
                      </label>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={
                      selectedRefundType === this.refundTypes.partial
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    <div className="col">
                      <label className="form-control-label" htmlFor="RefAmount">
                        Refund Amount:
                      </label>
                      <input
                        className="form-control"
                        placeholder="Enter amount"
                        type="number"
                        value={partialAmount}
                        onChange={(e) =>
                          this.setState({ partialAmount: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={this.onCreate}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
