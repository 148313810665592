import { atom, selector } from "recoil";
import { CorporateBookingModel } from "./booking/models/CorporateBookingCreationRequest";

export const corporateBookingAtom = atom({
  key: "corporateBooking",
  default: new CorporateBookingModel({}),
});

export const corporateBookingDetailSelector = selector({
  key: "corporateBookingDetailSelector",
  get: ({ get }) => {
    return get(corporateBookingAtom).corporateBookingDetails;
  },
});

export const corporateBookingSeatCodesSelector = selector({
  key: "corporateBookingSeatCodesSelector",
  get: ({ get }) => {
    return get(corporateBookingAtom).corporateBookingDetails?.seatCodes ?? [];
  }
});
