import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import { EVoucherBatchExtensionRequestStatus } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class EvoucherBatchExtensionRequestListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      batchId: "",
    };
  }

  async componentDidMount() {
    await this.loadData(0);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "extensions",
        name: "eVoucher Batch Extension Request",
      }),
    });
  };

  getApiPath = () => "/api/v1/evoucher_batch_extension_requests";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters) {
    const batchId = StringHelper.isNullOrEmpty(this.state.batchId)
      ? ""
      : this.state.batchId;

    return super.injectSearchTerm(queryParameters, {
      batchId,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableFilter() {
    return (
      <>
        <div className="row mb-3">
          <div className="col-md-6 input-group ml-auto">
            <input
              type="text"
              id="input1-group2"
              name="batchId"
              placeholder="Enter Batch Id"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" /> Search
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  onRowClick = (id) => {
    this.props.history.push(`/evouchers/batch_extension_requests/${id}`);
  };
  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th width="10%">Request ID</th>
            <th width="25%">Batch ID</th>
            <th>eVoucher Program</th>
            <th>Request Status</th>
            <th>Company Name</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td>{item.id}</td>
                <td>{item.evoucherBatchId}</td>
                <td>{item.evoucherProgramShortName}</td>
                <td
                  className={EVoucherBatchExtensionRequestStatus.getColor(
                    item.status
                  )}
                >
                  {item.status}
                </td>
                <td>{item.evoucherOrderCompanyName}</td>
                <td>
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    item.updatedOn
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
