export class CompanyType {
  static Exhibitors = 1;
  static MiscellaneousDebtor = 2;
  static Distributors = 3;
  static SubDistributors = 4;
  static Licensor = 5;
  static Clients = 6;
  static CorporateClients = 7;

  static All = [
    {
      name: "Clients",
      value: this.Clients,
    },
    {
      name: "Corporate Clients",
      value: this.CorporateClients,
    },
    {
      name: "Sub Distributors",
      value: this.SubDistributors,
    },
    {
      name: "Distributors",
      value: this.Distributors,
    },
    {
      name: "Exhibitors",
      value: this.Exhibitors,
    },
    {
      name: "Licensor",
      value: this.Licensor,
    },
    {
      name: "Miscellaneous Debtor",
      value: this.MiscellaneousDebtor,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    return found?.name;
  }
}