import React from "react";
import _ from "lodash";

export const NumberInput = (props) => {
  const { name, value, onChange, placeholder, readOnly, min, max, disabled } =
    props;

  if (!_.isNil(readOnly) && readOnly) {
    return (
      <input className="form-control display-input" value={value} readOnly />
    );
  }
  return (
    <input
      type="number"
      valuetype="number"
      className="form-control"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      min={min}
      max={max}
      disabled={disabled}
    />
  );
};
