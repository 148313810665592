import React from "react";
import { PromotionCategory, StorageModule } from "../../../../common/Constants";
import {
  DateHelper,
  ImageHelper,
  StringHelper,
  generateFileName,
} from "../../../../common/Helpers";
import _ from "lodash";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { useUpload } from "../../../../hooks/useUpload";
import { CheckboxInput } from "../../../campaigns/CheckboxInput";
import { RowFormField } from "../../../layout/FormLayout";

export class ContestDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  openFile = (e) => {
    const { model } = this.state;
    const { onModelChange, defaultValue } = this.props;
    const sourceFile = e.target.files[0];
    const fileName = generateFileName(defaultValue?.id, StorageModule.Contest);
    const { uploadFile } = useUpload();
    uploadFile(sourceFile, fileName, StorageModule.Contest).then((response) => {
      model.poster = `${response.data.blobStoragePath}${response.data.fileName} `;
      onModelChange(model);
    });
  };

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""} `;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      category: PromotionCategory.Contest,
      code: value.code || "",
      contestEmbedUrl: value.contestEmbedUrl || "",
      contestEndTimeStr: value.contestEndTime || "",
      contestStartTimeStr: value.contestStartTime || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      name: value.name || "",
      planToPublishStr: value.planToPublish || "",
      poster: value.poster || "",
      termCondition: value.termCondition || "",
      weightDisplay: value.weightDisplay || 0,
      isVisibleToHomePage: value.isVisibleToHomePage || false,
    };
  }

  onModelChange() {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  }

  onTextBoxChange = (e) => {
    const valueType = e.target.getAttribute("valuetype");
    const fieldName =
      e.target.getAttribute("fieldname") ?? e.target.getAttribute("name");
    let value = e.target.value;
    if (_.isEqual("boolean", valueType)) {
      value = e.target.checked;
    }
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    return (
      <Card title="Contest Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const { cardMode, model } = this.state;
    const {
      code,
      contestEmbedUrl,
      contestEndTimeStr,
      contestStartTimeStr,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      termCondition,
      weightDisplay,
      isVisibleToHomePage,
    } = model;
    return (
      <React.Fragment>
        <div className="row mb-3 flex-md-row flex-column-reverse">
          <div className="col-12 col-md-6">
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="PromoCode">
                  Contest Code:
                </label>
                <input
                  className="form-control display-input"
                  id="PromoCode"
                  placeholder="Enter Contest Code"
                  readOnly={true}
                  value={code}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="Name">
                  Contest Name:
                </label>
                <input
                  className="form-control display-input"
                  id="Name"
                  placeholder="Enter Contest Name"
                  readOnly={true}
                  value={name}
                />
              </div>
            </div>
            <div className="row form-group">
              <label
                className="col form-control-label text-nowrap"
                htmlFor="Category"
              >
                Contest Category:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Category"
                  placeholder="Enter Contest Image Link"
                  readOnly={true}
                  value="Contest"
                />
              </div>
            </div>
            <div className="row form-group">
              <label
                className="col form-control-label"
                htmlFor="ContestStartDate"
              >
                Contest Start:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDat"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    contestStartTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row form-group">
              <label
                className="col form-control-label"
                htmlFor="ContestEndDate"
              >
                Contest End:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDat"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    contestEndTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="StartDate">
                Display Start:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDat"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayStartTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row">
              <label className="col form-control-label" htmlFor="EndDate">
                Display End:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="EndDat"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayEndTimeStr
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col">
                <img
                  src={ImageHelper.getPosterUrl(poster)}
                  onError={ImageHelper.onPosterError}
                  style={{ height: "320px" }}
                  alt=""
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col">
                <label className="form-control-label" htmlFor="ImgUrl">
                  Contest Image:
                </label>
                <textarea
                  className="form-control display-input resize-none"
                  rows="1"
                  readOnly={true}
                  value={poster}
                />
              </div>
            </div>
            <div className="row ">
              <label className="col form-control-label" htmlFor="Weight">
                Display Weight:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Weight"
                  type="number"
                  placeholder="Enter Display Weight"
                  min="1"
                  max="100"
                  readOnly={true}
                  value={weightDisplay}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label">
                Contest Q &amp; A link:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  type="text"
                  readOnly={true}
                  value={contestEmbedUrl}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-4 form-control-label" htmlFor="Weight">
                Is Visible To Home Page:
              </label>
              <div className="col-7">
                <CheckboxInput
                  name="isVisibleToHomePage"
                  value={isVisibleToHomePage}
                  checked={isVisibleToHomePage}
                />
              </div>
            </div>
            {CardMode.isScheduleMode(cardMode) && (
              <div className="row form-group">
                <label
                  className="col form-control-label"
                  htmlFor="PlanToPublish"
                >
                  Plan to Publish On:
                </label>
                <div className="col-11">
                  <input
                    className="form-control display-input"
                    id="PlanToPublish"
                    placeholder={DateHelper.DisplayDateTimePlaceholder}
                    readOnly={true}
                    value={StringHelper.asDateStringToDisplayDateTimeFormat(
                      planToPublishStr
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col">
            <label className="form-control-label" htmlFor="Desc">
              Contest Description:
            </label>
            <div className="text-black text-block min-height-200">
              {description}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label className="form-control-label" htmlFor="Desc">
              Contest Term &amp; Condition:
            </label>
            <div className="text-black text-block min-height-200">
              {termCondition}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { cardMode, model } = this.state;
    const {
      code,
      contestEmbedUrl,
      contestEndTimeStr,
      contestStartTimeStr,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      termCondition,
      weightDisplay,
      isVisibleToHomePage,
    } = model;
    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col">
            <RowFormField
              label={
                <>
                  Contest Code:<span className="color-red">*</span>
                </>
              }
              htmlFor="PromoCode"
            >
              {cardMode === CardMode.Create ? (
                <React.Fragment>
                  <input
                    id="PromoCode"
                    placeholder="Enter Contest Code"
                    className={this.getControlClassName("code")}
                    fieldname="code"
                    onChange={this.onTextBoxChange}
                    value={code}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="code"
                    isEnabled={isSubmit}
                    property="Contest Code"
                    value={code}
                  />
                  <Validators.MaxLengthValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    maxLength="12"
                    fieldName="code"
                    isEnabled={isSubmit}
                    property="Voucher Code"
                    value={code}
                  />
                </React.Fragment>
              ) : (
                <input
                  id="PromoCode"
                  className="form-control display-input"
                  placeholder="Enter Contest Code"
                  readOnly={true}
                  value={code}
                />
              )}
            </RowFormField>
            <RowFormField
              label={
                <>
                  Contest Name:<span className="color-red">*</span>
                </>
              }
              htmlFor="Name"
            >
              <input
                id="Name"
                placeholder="Enter Contest Name"
                className={this.getControlClassName("name")}
                fieldname="name"
                onChange={this.onTextBoxChange}
                value={name}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="name"
                isEnabled={isSubmit}
                property="Contest Name"
                value={name}
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  Contest Category:<span className="color-red">*</span>
                </>
              }
              htmlFor="Category"
            >
              <input
                className="form-control display-input"
                readOnly={true}
                value="Contest"
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  Display Weight:<span className="color-red">*</span>
                </>
              }
              htmlFor="Weight"
            >
              <input
                id="Weight"
                type="number"
                placeholder="Enter Display Weight"
                min="1"
                max="100"
                className={this.getControlClassName("weightDisplay")}
                fieldname="weightDisplay"
                onChange={this.onTextBoxChange}
                value={weightDisplay}
              />
              <Validators.RangeValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="weightDisplay"
                isEnabled={isSubmit}
                min="1"
                max="100"
                property="Display Weight"
                value={weightDisplay}
                allowDecimal={true}
              />
            </RowFormField>
            <RowFormField label="Contest Image:" htmlFor="ImgUrl">
              <input type="file" name="file" onChange={this.openFile} />
            </RowFormField>
            <RowFormField>
              <textarea
                id="ImgUrl"
                rows="1"
                placeholder="Enter Contest Image"
                className={this.getControlClassName("poster")}
                fieldname="poster"
                onChange={this.onTextBoxChange}
                value={poster}
                disabled={true}
              />
            </RowFormField>
          </div>
          <div className="col">
            <RowFormField
              label={
                <>
                  Display Start:<span className="color-red">*</span>
                </>
              }
              htmlFor="StartDate"
            >
              <input
                id="StartDate"
                type="datetime-local"
                className={this.getControlClassName("displayStartTimeStr")}
                fieldname="displayStartTimeStr"
                onChange={this.onTextBoxChange}
                value={displayStartTimeStr}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="displayStartTimeStr"
                isEnabled={isSubmit}
                property="Display Start"
                value={displayStartTimeStr}
              />
            </RowFormField>
            <RowFormField label="Display End:" htmlFor="EndDate">
              <input
                id="EndDate"
                type="datetime-local"
                className={this.getControlClassName("displayEndTimeStr")}
                fieldname="displayEndTimeStr"
                onChange={this.onTextBoxChange}
                value={displayEndTimeStr}
              />
              <Validators.DateTimeFromToValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="displayEndTimeStr"
                isEnabled={isSubmit}
                fromProperty="Display End"
                fromValue={displayStartTimeStr}
                toProperty="Display Start"
                toValue={displayEndTimeStr}
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  Contest Start:<span className="color-red">*</span>
                </>
              }
            >
              <input
                id="ContestStart"
                type="datetime-local"
                className={this.getControlClassName("contestStartTimeStr")}
                fieldname="contestStartTimeStr"
                onChange={this.onTextBoxChange}
                value={contestStartTimeStr}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="displayStartTimeStr"
                isEnabled={isSubmit}
                property="Contest Start"
                value={displayStartTimeStr}
              />
              <Validators.RangeDateValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="contestStartTimeStr"
                isEnabled={isSubmit}
                value={contestStartTimeStr}
                minDay={displayStartTimeStr}
                maxDay={displayEndTimeStr}
              />
            </RowFormField>
            <RowFormField label="Contest End:" htmlFor="ContestEnd">
              <input
                id="ContestEnd"
                type="datetime-local"
                className={this.getControlClassName("contestEndTimeStr")}
                fieldname="contestEndTimeStr"
                onChange={this.onTextBoxChange}
                value={contestEndTimeStr}
              />
              <Validators.DateTimeFromToValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="contestEndTimeStr"
                isEnabled={isSubmit}
                fromProperty="Contest End"
                fromValue={contestStartTimeStr}
                toProperty="Contest Start"
                toValue={contestEndTimeStr}
              />
              <Validators.DateTimeFromToValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="contestEndTimeStr"
                isEnabled={isSubmit}
                fromProperty="Contest End"
                fromValue={contestEndTimeStr}
                toProperty="Display End"
                customMessageFormat="The value in {0} must be a time earlier than the {1}"
                toValue={displayEndTimeStr}
              />
            </RowFormField>
            <RowFormField label="Contest Q &amp; A link:">
              <input
                type="text"
                placeholder="Enter Contest Q&A Link"
                className={this.getControlClassName("contestEmbedUrl")}
                fieldname="contestEmbedUrl"
                onChange={this.onTextBoxChange}
                value={contestEmbedUrl}
              />
            </RowFormField>
            <RowFormField label="Is Visible To Home Page:" htmlFor="Weight">
              <CheckboxInput
                name="isVisibleToHomePage"
                onChange={this.onTextBoxChange}
                value={isVisibleToHomePage}
                checked={isVisibleToHomePage}
              />
            </RowFormField>
            {CardMode.isScheduleMode(cardMode) && (
              <RowFormField
                label="Plan to Publish On:"
                htmlFor="PlanToPublish"
              >
                <input
                  id="PlanToPublish"
                  type="datetime-local"
                  className={this.getControlClassName("planToPublishStr")}
                  fieldname="planToPublishStr"
                  onChange={this.onTextBoxChange}
                  value={planToPublishStr}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="planToPublishStr"
                  isEnabled={isSubmit}
                  property="Plan to Publish On"
                  value={planToPublishStr}
                />
              </RowFormField>
            )}
          </div>
        </div>
        <div className="row col form-group p-0 mx-0">
          <label className="form-control-label" htmlFor="TnC">
            Contest Term &amp; Conditions:
          </label>
          <textarea
            id="TnC"
            placeholder="Terms & Conditions"
            maxLength="12000"
            rows="5"
            className={this.getControlClassName("termCondition", "resize-none")}
            fieldname="termCondition"
            onChange={this.onTextBoxChange}
            value={termCondition}
          />
          <label className="form-control-label ml-auto" htmlFor="TnC">
            <small>
              <span id="TnCCount">{termCondition.length}</span>/12000
            </small>
          </label>
        </div>
        <div className="row col form-group p-0 mx-0">
          <label className="form-control-label" htmlFor="Desc">
            Contest Description:
          </label>
          <textarea
            id="Desc"
            placeholder="Description"
            maxLength="50000"
            rows="5"
            className={this.getControlClassName("description", "resize-none")}
            fieldname="description"
            onChange={this.onTextBoxChange}
            value={description}
          />
          <label className="form-control-label ml-auto" htmlFor="Desc">
            <small>
              <span id="DescCount">{description.length}</span>/50000
            </small>
          </label>
        </div>
      </React.Fragment>
    );
  }
}
