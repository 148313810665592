import React, { useEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import _ from "lodash";
import { ServicesRestService } from "../../../services/ServicesRestService";
import { GenericMessages } from "../../../common/Constants";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { serviceAtom } from "./state";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import {
  Days,
  DaySelection,
  PerformanceTypes,
} from "../../../constants/PricingConstants";
import { LocationBrand } from "../../../constants/MovieConstants";
import { Card } from "../../common/Card";
import { RowFormField } from "../../layout/FormLayout";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { useServiceTimeBased } from "./modals/useServiceTimeBased";

export const ServiceCreation = () => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [service, setService] = useRecoilState(serviceAtom);

  const servicesRestService = useMemo(() => new ServicesRestService(), []);
  const history = useHistory();

  const resetService = useResetRecoilState(serviceAtom);
  useServiceTimeBased();

  useEffect(() => {
    _.noop();

    return () => {
      resetService();
    };
  }, [resetService]);

  useEffect(() => {
    servicesRestService
      .preview({
        ticketFormat: service.ticketFormat,
        performanceType: service.performanceType,
        ticketBrand: service.ticketBrand,
        daySelection: service.daySelection,
        performanceTimeStartOn: service.performanceTimeStartOn,
        performanceTimeEndOn: service.performanceTimeEndOn,
      })
      .then(({ data }) => {
        if (data) {
          setService((s) => ({ ...s, code: data.code }));
        }
      });
  }, [
    servicesRestService,
    service.ticketFormat,
    service.daySelection,
    service.performanceType,
    service.ticketBrand,
    service.performanceTimeStartOn,
    service.performanceTimeEndOn,
    setService,
  ]);

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const editable = _.cloneDeep(service);
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    editable[fieldName] = selectValue;
    setService(editable);
  };

  const isCustomDayOfWeek =
    !_.isNil(service) && service.daySelection === DaySelection.Custom;

  const onCloseAllModals = () => {
    setShowConfirmModal(false);
    setShowCancelModal(false);
  };

  const onShowConfirmDialog = () => {
    setShowConfirmModal(true);
  };

  const onPublish = () => {
    servicesRestService.post({ ...service }).then((response) => {
      if (response) {
        history.push(`/services/${response.data.id}`);
        toast.success("The service has been created successful.");
      }
    });
    onCloseAllModals();
  };

  const onCancel = () => {
    onCloseAllModals();
    history.push("/services");
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "service-creation",
        name: "Create Service",
      }),
    });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <Card title="Service Information" wrapperCss="info row">
                  <div className="col">
                    <RowFormField
                      label={
                        <span>
                          Service Name:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control display-input"
                        name="name"
                        type="text"
                        placeholder="Enter Service Name"
                        onChange={onInputChange}
                      />
                    </RowFormField>
                    <RowFormField
                      label={
                        <span>
                          Day of week:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        name="daySelection"
                        valuetype="number"
                        onChange={onInputChange}
                      >
                        <option value={0}>Select Day of Week</option>
                        {DaySelection.All.map((entry, index) => (
                          <option key={index} value={entry.value}>
                            {entry.name}
                          </option>
                        ))}
                      </select>
                    </RowFormField>
                    {isCustomDayOfWeek && (
                      <>
                        <RowFormField label="Start Day:">
                          <select
                            className="form-control"
                            name="customDayTime.startDay"
                            valuetype="number"
                            onChange={onInputChange}
                          >
                            {Days.All.map((entry, index) => (
                              <option key={index} value={entry.value}>
                                {entry.name}
                              </option>
                            ))}
                          </select>
                        </RowFormField>
                        <RowFormField label="Start Time:">
                          <input
                            className="form-control display-input"
                            type="time"
                            name="customDayTime.startTime"
                            value={service.customDayTime.startTime}
                            onChange={onInputChange}
                          />
                        </RowFormField>
                        <RowFormField label="End Day:">
                          <select
                            className="form-control"
                            name="customDayTime.endDay"
                            valuetype="number"
                            onChange={onInputChange}
                          >
                            {Days.All.map((entry, index) => (
                              <option key={index} value={entry.value}>
                                {entry.name}
                              </option>
                            ))}
                          </select>
                        </RowFormField>
                        <RowFormField label="End Time:">
                          <input
                            className="form-control display-input"
                            type="time"
                            name="customDayTime.endTime"
                            value={service.customDayTime.endTime}
                            onChange={onInputChange}
                          />
                        </RowFormField>
                      </>
                    )}
                  </div>
                  <div className="col">
                    <RowFormField
                      label={
                        <span>
                          Location Brand:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        name="ticketBrand"
                        valuetype="number"
                        onChange={onInputChange}
                      >
                        <option value="0">Select Location Brand</option>
                        {LocationBrand.All.filter(
                          (x) => x.value !== LocationBrand.None
                        ).map((entry, index) => (
                          <option key={index} value={entry.value}>
                            {entry.name}
                          </option>
                        ))}
                      </select>
                    </RowFormField>
                    <RowFormField
                      label={
                        <span>
                          Movie Format:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        name="ticketFormat"
                        valuetype="number"
                        onChange={onInputChange}
                      >
                        <option value="">Select Movie Format</option>
                        {LocationBrand.getMovieFormats(service.ticketBrand).map(
                          (entry, index) => (
                            <option key={index} value={entry.value}>
                              {entry.name}
                            </option>
                          )
                        )}
                      </select>
                    </RowFormField>
                    <RowFormField
                      label={
                        <span>
                          Showtime Type:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        name="performanceType"
                        valuetype="number"
                        onChange={onInputChange}
                      >
                        <option value="0">Select a Showtime Type</option>
                        {PerformanceTypes.AllExceptSpecial.map(
                          (entry, index) => (
                            <option key={index} value={entry.value}>
                              {entry.name}
                            </option>
                          )
                        )}
                      </select>
                    </RowFormField>
                    <RowFormField label="Performance Time Start On:">
                      <input
                        className="form-control display-input"
                        name="performanceTimeStartOn"
                        type="time"
                        value={service.performanceTimeStartOn}
                        onChange={onInputChange}
                      />
                    </RowFormField>
                    <RowFormField label="Performance Time End On:">
                      <input
                        className="form-control display-input"
                        name="performanceTimeEndOn"
                        type="time"
                        value={service.performanceTimeEndOn}
                        onChange={onInputChange}
                      />
                    </RowFormField>
                  </div>
                  <div className="col flex-grow-0">
                    <div className="row form-group">
                      <label className="col-12 form-control-label">
                        Service Code:
                      </label>
                      <div
                        className="col m-l-15 px-3 text-center mr-3"
                        style={{
                          backgroundColor: "grey",
                          paddingTop: "35px",
                          paddingBottom: "35px",
                          minWidth: "240px",
                        }}
                      >
                        <h3 className="text-white text-nowrp">
                          {service.code}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-success"
                onClick={onShowConfirmDialog}
              >
                Create
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                data-target="#cancelModal"
                data-toggle="modal"
                onClick={() => setShowCancelModal(true)}
              >
                Cancel
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        </div>
        <ConfirmDialog
          visible={showConfirmModal}
          onCancel={onCloseAllModals}
          onProceed={onPublish}
          message="Are you sure you want to publish?"
          title="Publish"
        />
        <ConfirmDialog
          visible={showCancelModal}
          title={GenericMessages.CancelChangesDialogTitle}
          onProceed={onCancel}
          onCancel={onCloseAllModals}
          message={GenericMessages.CancelChangesDialogMessage}
        />
      </div>
    </>
  );
};
