import React, { useState } from "react";
import { LocationBrand } from "../../../constants/MovieConstants";
import _ from "lodash";

export const FormatConditionDialog = (props) => {
  const [locationBrand, setLocationBrand] = useState(0);
  const [movieFormat, setMovieFormat] = useState([]);
  const { onConfirm, onCancel, visible } = props;
  const onAddClick = () => {
    onConfirm(locationBrand, movieFormat);
    setMovieFormat([]);
  };

  const checkedFormatBrand = (e) => {
    const value = parseInt(e.target.value);
    if (e.target.checked) {
      setMovieFormat((formats) => formats.concat([value]));
    } else {
      setMovieFormat((formats) => {
        return formats.filter((val) => val !== value);
      });
    }
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  return (
    <div
      className="modal fade show"
      id="BrandConditionModal"
      role="dialog"
      style={{ paddingRight: "17px", display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Valid for specific brand format
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-5">
            <div className="row">
              <div className="col-3">
                Location Brand:
                <span className="color-red font-weight-bold">*</span>
              </div>
              <div className="col-8">
                <select
                  className="form-control"
                  id="SelectBrandCode"
                  onChange={(e) => setLocationBrand(+e.target.value)}
                >
                  <option value="0">Select Location Brand</option>
                  {LocationBrand.All.map((entry, index) => {
                    return (
                      <option key={index} value={entry.value}>
                        {entry.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row FormatSelection mt-3">
              <div className="col-3">
                Movie Format:
                <span className="color-red font-weight-bold">*</span>
              </div>
              <div className="col" id="EventFormatSelection">
                {LocationBrand.getMovieFormats(locationBrand).map(
                  (entry, index) => (
                    <div className="" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="LocationCondition"
                        value={entry.value}
                        onChange={checkedFormatBrand}
                      />
                      <label className="form-check-label">{entry.name}</label>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn btn-danger"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn btn-success"
              data-dismiss="modal"
              onClick={onAddClick}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
