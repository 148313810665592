import React from "react";
import { toast } from "react-toastify";
import { BaseListing } from "../../common/BaseListing";
import { StringHelper } from "../../../common/Helpers";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ReportCategory } from "../common/Constants";
import { ReportRegisterDetailModal } from "../common/ReportRegisterDetailModal";
import {getModalInstant} from "../../../common/Common";

export class InternalReportsListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      search: "",
      showDetailDialog: false,
      selectedReportRegister: null,
    };
    this.modalInstant = null;
  }

  getApiPath = () => "/api/v1/report_registers";

  getDefaultSort = () => "-Code";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "report_manager",
        name: "Report Manager",
      }),
      activeModule: new NavigationItem({
        identifier: "internal_reports",
        name: "Internal Reports",
      }),
    });
  };

  handleError = (errorMsg) => {
    toast.error(errorMsg);
  };

  injectSearchTerm(queryParameters) {
    const search = StringHelper.isNullOrEmpty(this.state.search)
      ? ""
      : this.state.search;
    return super.injectSearchTerm(queryParameters, {
      search,
      categoryCode: ReportCategory.getCode(ReportCategory.Internal),
    });
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onRowClick = async (item) => {
    this.setState({
      selectedReportRegister: item,
    },() => {
      this.modalInstant = getModalInstant(item.id);
      this.modalInstant.show()
    });
  };

  onHideDetailDialog = () => {
    this.modalInstant.hide();
    this.setState({ selectedReportRegister: {} });
  };

  viewRequests = () => this.props.history.push(`/request_reports`);

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th onClick={this.onColumnClick}>
              Code
              <i
                className="fas fa-sort-alpha-down"
                id="Code"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Name
              <i
                className="fas fa-sort-alpha-down"
                id="Name"
                onClick={this.toggleSort}
              />
            </th>
            <th>Description</th>
          </tr>
        </thead>

        <tbody id="data">
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item)}>
                  <td>{item.reportCode}</td>
                  <td>{item.reportName}</td>
                  <td>{item.reportDescription}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateTableFilter() {
    const { search } = this.state;
    return (
      <div className="row mb-3">
        <div className="col-md-6 input-group ml-auto pr-md-0 mb-1">
          <input
            type="text"
            id="input1-group2"
            name="search"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onTextBoxChange}
            value={search}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
        <button
          className="btn btn-primary col-md-auto mx-3 mb-1"
          style={{ fontSize: "1rem" }}
          onClick={this.viewRequests}
        >
          View Request
        </button>
      </div>
    );
  }

  generateModals() {
    const { selectedReportRegister } = this.state;
    return (
      <ReportRegisterDetailModal
        onCancel={this.onHideDetailDialog}
        id={selectedReportRegister?.id}
        key={selectedReportRegister?.id}
      />
    );
  }
}
