import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { toast } from "react-toastify";
import { ObjectType, VoucherExtensionStatus } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { CardMode } from "../../common/CardMode";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { VoucherExtensionRequestDetailCard } from "./cards/VoucherExtensionRequestDetailCard";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class VoucherExtensionRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: null,
      isSubmit: false,
      cardMode: CardMode.View,
      showApproveDialog: false,
      showRejectDialog: false,
      showCancelDialog: false,
      showHistoryDialog: false,
      voucherExtensionRequest: null,
      voucherExtensionRequestValidations: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const voucherExtensionId = StringHelper.toNumber(id, 0);
    if (voucherExtensionId > 0) {
      this.setState({ voucherExtensionId }, this.refreshData);
    }
  }

  refreshData() {
    const { voucherExtensionId } = this.state;
    this.loadVoucherExtension(voucherExtensionId);
  }

  onAction = (action, successMessage, onCloseModal) => {
    const { voucherExtensionId } = this.state;
    RestClient.sendPostRequest(
      `api/v1/voucher_extensions/${voucherExtensionId}/${action}`,
      {},
      (response) => {
        toast.success(successMessage);
        this.setState({ model: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
    onCloseModal();
  };

  onCancel = () =>
    this.onAction(
      "cancel",
      "The voucher extension request have been cancelled successful",
      () => this.setState({ showCancelDialog: false })
    );

  onComplete = () =>
    this.onAction(
      "complete",
      "The voucher extension request have been completed successful",
      () => this.setState({ showApproveDialog: false })
    );

  onReject = () =>
    this.onAction(
      "reject",
      "The voucher extension request have been rejected successful",
      () => this.setState({ showRejectDialog: false })
    );

  loadVoucherExtension = (id) => {
    RestClient.sendGetRequest(
      `api/v1/voucher_extensions/${id}`,
      (response) => {
        this.setState({ model: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "extension_requests",
        name: "Extension Request Details",
      }),
    });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  render() {
    const {
      voucherExtensionId,
      model,
      cardMode,
      isSubmit,
      showApproveDialog,
      showRejectDialog,
      showHistoryDialog,
      showCancelDialog,
    } = this.state;

    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-outline-secondary"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </ButtonActionDetailGroup.Item>
                  </ButtonActionDetailGroup>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <VoucherExtensionRequestDetailCard
                  cardMode={cardMode}
                  defaultValue={model}
                  isSubmit={isSubmit}
                />
              </div>
            </div>
          </div>
          {model?.status === VoucherExtensionStatus.Pending &&
            AuthenticationManager.isAuthorized(
              ResourceID.VoucherExtensionRequestApproveRejectButton
            ) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success float-right"
                  onClick={() => this.setState({ showApproveDialog: true })}
                >
                  Approve
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={() => this.setState({ showRejectDialog: true })}
                >
                  Reject
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={() => this.setState({ showCancelDialog: true })}
                >
                  Cancel Request
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          <ConfirmDialog
            visible={showApproveDialog}
            onCancel={() => this.setState({ showApproveDialog: false })}
            onProceed={this.onComplete}
            message={`You are about to approve ${
              model?.voucherCount || 0
            } vouchers. Please confirm to proceed.`}
            title="Confirmation"
          />
          <ConfirmDialog
            visible={showRejectDialog}
            onCancel={() => this.setState({ showRejectDialog: false })}
            onProceed={this.onReject}
            message="Vouchers extension requests will be rejected. Please confirm to proceed."
            title="Confirmation"
          />
          <ConfirmDialog
            visible={showCancelDialog}
            onCancel={() => this.setState({ showCancelDialog: false })}
            onProceed={this.onCancel}
            message="Vouchers extension requests will be cancelled. Please confirm to proceed."
            title="Confirmation"
          />
          {showHistoryDialog && (
            <HistoryDialog
              objectId={voucherExtensionId}
              onClose={() => this.setState({ showHistoryDialog: false })}
              url="/api/v1/history_logs"
              objectType={ObjectType.VoucherExtensionRequestDetail}
            />
          )}
        </div>
      </div>
    );
  }
}
