import React from "react";
import { EVoucherService } from "../../../../services/EVoucherService";
import { DateHelper } from "../../../../common/Helpers";

export const EVouchersInformationTable = (props) => {
  const { data } = props;
  const eVoucherService = new EVoucherService();

  const [eVouchers, setEVouchers] = React.useState([]);

  React.useEffect(() => {
    const loadEVouchers = async () => {
      const eVoucherIds = data.map((x) => x.eVoucherId);
      const queryString = eVoucherIds.map((id) => `ids=${id}`).join("&");
      if (eVoucherIds.length === 0) return;
      const response = await eVoucherService.get(`?${queryString}`);

      if (response.status === 200) {
        setEVouchers(response.data.data);
      }
    };

    loadEVouchers();
  }, [data]);

  const generateTableContent = () => {
    return (
      <>
        <thead>
          <tr>
            <th className="text-center">Code</th>
            <th className="text-center">E-Voucher Program</th>
            <th className="text-center">Validity Start</th>
            <th className="text-center">Expiry Date:</th>
            <th className="text-center">Remaining Duration</th>
          </tr>
        </thead>
        <tbody>
          {eVouchers &&
            eVouchers.map((item) => {
              return (
                <tr key={item.id} style={{ cursor: "default" }}>
                  <td className="text-center">
                    <a href={`/evouchers/evouchers/${item.id}`}>{item.code}</a>
                  </td>
                  <td className="text-center">
                    {item.eVoucherProgram?.shortName}
                  </td>
                  <td className="text-center">
                    {DateHelper.toDisplayDateFormat(item.validityStart)}
                  </td>
                  <td className="text-center">
                    {DateHelper.toDisplayDateFormat(item.validityEnd)}
                  </td>
                  <td className="text-center">
                    {Math.ceil(item.remainingDuration)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="table-responsive m-b-10 table-px-15">
            <table className="table table-borderless table-striped table-earning">
              {generateTableContent()}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
