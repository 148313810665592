import _ from "lodash";

export class RefundStatus {
  static Pending = 0;
  static Approved = 1;
  static Successful = 2;
  static SubmittedToBank = 3;
  static Processed = 4;
  static Rejected = 5;
  static Issued = 6;
  static Voided = 7;

  static All = [
    {
      name: "Pending",
      value: this.Pending,
    },
    {
      name: "Approved",
      value: this.Approved,
    },
    {
      name: "Successful",
      value: this.Successful,
    },
    {
      name: "Submitted To Bank",
      value: this.SubmittedToBank,
    },
    {
      name: "Processed",
      value: this.Processed,
    },
    {
      name: "Rejected",
      value: this.Rejected,
    },
    {
      name: "Issued",
      value: this.Issued,
    },
    {
      name: "Voided",
      value: this.Voided,
    },
  ];

  static RefundedStatuses = [
    this.SubmittedToBank,
    this.Processed,
    this.Successful,
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}