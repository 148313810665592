import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingDistributionsAtom } from "./Report.state";

const QUERY_KEY_REPORTING_DISTRIBUTIONS = "QUERY_KEY_REPORTING_DISTRIBUTIONS";

export function useReportingDistributions() {
  const [distributions, setDistributions] = useRecoilState(
      reportingDistributionsAtom
  );
  useQuery(
    QUERY_KEY_REPORTING_DISTRIBUTIONS,
    () => {
      return new RestService("/api/v1/report_parameter_values/distributions").list();
    },
    {
      onSuccess: (response) => setDistributions(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return distributions;
}
