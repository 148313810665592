import { CorporateBookingRestService } from "../services/CorporateBookingRestService";
import { showLoading } from "./Common";
import { AuthenticationManager } from "./AuthenticationManager";

const DefaultPageWidth = "100%";
const DefaultPageHeight = "350px";
const ImageType = "image";

const seatRowColumnSplitValue = "";
const nodeOffsetValues = "";

const ELEMENT_MEDIA_MAPPING = [
  {
    elementCode: "EL_ASL",
    overlayText: "Aisle",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_AISLE",
  },
  {
    elementCode: "EL_CPS",
    overlayText: "Couple Seat",
    elementStatusCodeCurrent: "AV",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/couple-seat-avail.png",
  },
  {
    elementCode: "EL_CPS",
    overlayText: "Couple Seat",
    elementStatusCodeCurrent: "BL",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/couple-seat-blocked.png",
  },
  {
    elementCode: "EL_CPS",
    overlayText: "Couple Seat",
    elementStatusCodeCurrent: "CS",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/couple-seat-select.png",
  },
  {
    elementCode: "EL_CPS",
    overlayText: "Couple Seat",
    elementStatusCodeCurrent: "OH",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/couple-seat-hold.png",
  },
  {
    elementCode: "EL_CPS",
    overlayText: "Couple Seat",
    elementStatusCodeCurrent: "SO",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/couple-seat-unavail.png",
  },
  {
    elementCode: "EL_ENT",
    overlayText: "Entrance",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/entrance-no-text.png",
  },
  {
    elementCode: "EL_EXT",
    overlayText: "Exit",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/exit-no-text.png",
  },
  {
    elementCode: "EL_HDS",
    overlayText: "Handicap Seat",
    elementStatusCodeCurrent: "AV",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-wheelchair-avail.png",
  },
  {
    elementCode: "EL_HDS",
    overlayText: "Handicap Seat",
    elementStatusCodeCurrent: "BL",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-blocked.png",
  },
  {
    elementCode: "EL_HDS",
    overlayText: "Handicap Seat",
    elementStatusCodeCurrent: "CS",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-select.png",
  },
  {
    elementCode: "EL_HDS",
    overlayText: "Handicap Seat",
    elementStatusCodeCurrent: "OH",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-hold.png",
  },
  {
    elementCode: "EL_HDS",
    overlayText: "Handicap Seat",
    elementStatusCodeCurrent: "SO",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-unavail.png",
  },
  {
    elementCode: "EL_SCR",
    overlayText: "Digital Screen",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/screen-no-text.png",
  },
  {
    elementCode: "EL_SCRIM",
    overlayText: "IMAX Screen",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_SCREEN_IMAX",
  },
  {
    elementCode: "EL_STS",
    overlayText: "Standard Seat",
    elementStatusCodeCurrent: "AV",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-avail.png",
  },
  {
    elementCode: "EL_STS",
    overlayText: "Standard Seat",
    elementStatusCodeCurrent: "BL",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-blocked-new.png",
  },
  {
    elementCode: "EL_STS",
    overlayText: "Standard Seat",
    elementStatusCodeCurrent: "CS",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-select.png",
  },
  {
    elementCode: "EL_STS",
    overlayText: "Standard Seat",
    elementStatusCodeCurrent: "OH",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-hold.png",
  },
  {
    elementCode: "EL_STS",
    overlayText: "Standard Seat",
    elementStatusCodeCurrent: "SO",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-unavail.png",
  },
  {
    elementCode: "EL_TBL",
    overlayText: "Table",
    elementStatusCodeCurrent: "AV",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_TABLES_Round",
  },
  {
    elementCode: "EL_TBL",
    overlayText: "Table",
    elementStatusCodeCurrent: "AV",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_TABLES_Square",
  },
  {
    elementCode: "EL_TOL_F",
    overlayText: "Toilet Female",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_TOILET_Male",
  },
  {
    elementCode: "EL_TOL_H",
    overlayText: "Toilet Handicap",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "172.168.1.0/images/layoutElement/photos/elm_TOILET_Female",
  },
  {
    elementCode: "EL_TOL_M",
    overlayText: "Toilet Male",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/toilet-no-text.png",
  },
  {
    elementCode: "EL_WAL",
    overlayText: "Wall",
    elementStatusCodeCurrent: "OT",
    element_media_path_reference:
      "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/Wall.png",
  },
];

const getSeatsInBetween = (currentSeatIndex, closestIndex) => {
  let results = [];
  if (currentSeatIndex < closestIndex) {
    closestIndex = closestIndex - 1;
    while (currentSeatIndex < closestIndex) {
      results.push(closestIndex);
      closestIndex = closestIndex - 1;
    }
    return results;
  }

  closestIndex = closestIndex + 1;
  while (currentSeatIndex > closestIndex) {
    results.push(closestIndex);
    closestIndex = closestIndex + 1;
  }
  return results;
};

const findWithAttr = (array, attr, value) => {
  if (!array || array.length === 0) {
    return -1;
  }
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};

const union = function (rect1, rect2) {
  const x = Math.min(rect1.x, rect2.x);
  const y = Math.min(rect1.y, rect2.y);
  const width = Math.max(rect1.x + rect1.width, rect2.x + rect2.width);
  const height = Math.max(rect1.y + rect1.height, rect2.y + rect2.height);
  return { x: x, y: y, width: width - x, height: height - y };
};

const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

const getLayoutSeatCode = (layout) =>
  `${layout.rowReference}${layout.columnReference}`;

const dynamicSort = (property) => {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return (a, b) => {
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

const convertElements = (layoutData) => {
  const seatElements = layoutData.filter(
    (entry) => entry.elementCategoryCode === SeatCategory.Seat
  );
  return seatElements
    .map((entry) => {
      const element = {};
      element.rowReference = entry.rowReference;
      element.columnReference = entry.columnReference;
      element.layoutElementCode = entry.layoutElementCode;
      element.elementStatusCodeCurrent = entry.elementStatusCodeCurrent;
      element.anchorCoordinateX = entry.anchorCoordinateX;
      return element;
    })
    .sort(dynamicSort("anchorCoordinateX"));
};

const buildElLblNode = (visualizationDiagram, entry) => {
  if (entry.elementCode !== "EL_LBL") {
    return null;
  }
  const fillColor =
    entry.overlayText && entry.overlayText.replaceAll("\n", "") === "WALL"
      ? "#c0c0c0"
      : "transparent";
  const label = new Label(
    entry.overlayText,
    "White",
    "transparent",
    "none",
    visualizationDiagram
  );
  return new SampleTextNode(entry, fillColor, [label], visualizationDiagram);
};
const createSeatNodeLabels = (visualizationDiagram, entry) => {
  if (entry.elementCode !== SeatType.StandardSeat.code) return [];

  // Only create inner number for standard seats
  const overlayText =
    entry.elementStatusCodeCurrent === SeatStatus.SoldOut
      ? ""
      : entry.columnReference;
  const fontColor =
    entry.elementStatusCodeCurrent === SeatStatus.Blocked
      ? "#646464"
      : "#3c3737";
  const innerTextLabel = new Label(
    overlayText,
    fontColor,
    "transparent",
    "none",
    visualizationDiagram
  );
  return [innerTextLabel];
};

const buildWallNode = (visualizationDiagram, entry) => {
  const overlayText = entry.overlayText.trim().replaceAll("\n", "");
  const fillColor = overlayText === "WALL" ? "#c0c0c0" : "transparent";
  let label = new Label(
    entry.overlayText,
    "White",
    fillColor,
    "none",
    visualizationDiagram
  );
  return new SampleTextNode(entry, fillColor, [label], visualizationDiagram);
};

const buildTooltipSettings = () => ({
  templateId: "userSeatTooltip",
  alignment: {
    horizontal: "center",
    vertical: "bottom",
  },
});

const setViewToLeftNode = (diagram) => {
  let bounds;
  for (let counter = 0; counter < diagram.model.nodes.length; counter++) {
    const node = diagram.model.nodes[counter];
    if (!node.pivot) {
      continue;
    }
    const width = node.width ? node.width : node._width || 0;
    const height = node.height ? node.height : node._height || 0;
    //calculate the node positions
    const rect = {
      x: node.offsetX - width * node.pivot.x,
      y: node.offsetY - height * node.pivot.y,
      width: width,
      height: height,
    };
    if (!bounds) {
      bounds = {
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      };
      continue;
    }
    const rect1 = {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height,
    };
    bounds = union(bounds, rect1);
  }
  return bounds;
};

const getSeatOffsets = () => {
  const listOfSelectedSeats = seatRowColumnSplitValue
    .split(",")
    .filter((x) => x !== "");
  const listOfSelectedSeatsOffsets = nodeOffsetValues
    .split(",")
    .filter((x) => x !== "");

  // First loop check for checking if the seat selected is of same row:
  // Separate all the offsets based on the row.
  let listOfSeatEachRow = [];
  for (let index = 0; index < listOfSelectedSeats.length; index++) {
    const selectedSeat = listOfSelectedSeats[index];
    const seatOffset = parseFloat(listOfSelectedSeatsOffsets[index]);
    let rowName = selectedSeat.split(":")[0];

    if (rowName in listOfSeatEachRow) {
      listOfSeatEachRow[rowName].push(seatOffset);
      continue;
    }
    listOfSeatEachRow[rowName] = [seatOffset];
  }
  return listOfSeatEachRow;
};

const sortNodes = (x, y) => {
  if (
    x.addInfo.elementCode === SeatType.HandicapSeat.code &&
    y.addInfo.elementCode !== SeatType.HandicapSeat.code
  ) {
    return -1;
  }
  if (
    x.addInfo.elementCode !== SeatType.HandicapSeat.code &&
    y.addInfo.elementCode === SeatType.HandicapSeat.code
  ) {
    return 1;
  }
  return x.addInfo.rowReference < y.addInfo.rowReference ? 1 : -1;
};
const getStatus = (action) => {
  const status = SeatStatus[action];
  if (!status || status.length === 0) {
    throw new Error(`Invalid action '${action}'`);
  }
  return status;
};

class SampleImageNode {
  constructor(
    entry,
    source,
    elementCode,
    selectedImage,
    labels,
    visualizationDiagram,
    isOwner
  ) {
    this.name = entry.layoutElementCode || "imageNode";
    this.offsetX = entry.anchorCoordinateX || 100;
    this.offsetY = entry.anchorCoordinateY || 100;
    this.width = entry.elementWidthX || 20;
    this.height = entry.elementHeightY || 20;
    this.fillColor = "none";
    this.borderColor = "none";
    this.type = visualizationDiagram.Shapes.Image;
    this.shape = visualizationDiagram.Shapes.Image;
    this.source = source || "";
    this.addInfo = {
      rowReference: entry.rowReference || "",
      columnReference: entry.columnReference || "",
      seatCategory: entry.elementCategoryCode || "SEAT",
      currentStatus: entry.elementStatusCodeCurrent,
      sourceImage: source || "",
      elementCode: elementCode,
      selectedImage: selectedImage,
      isHouseSeat: entry.isHouseSeat,
      isOwner,
      updatedBy: entry.updatedBy,
      initialStatus: entry.initialStatus,
    };
    this.labels = labels;
  }
}

class SampleTextNode {
  constructor(entry, fillColor, labels, visualizationDiagram) {
    this.name = entry.layoutElementCode || "textNode";
    this.offsetX = entry.anchorCoordinateX || 100;
    this.offsetY = entry.anchorCoordinateY || 100;
    this.width = entry.elementWidthX || 20;
    this.height = entry.elementHeightY || 20;
    this.fillColor = fillColor;
    this.borderColor = "none";
    this.type = visualizationDiagram.Shapes.Basic;
    this.source = "";
    this.addInfo = {
      rowReference: "",
      columnReference: "",
      seatCategory: entry.elementCategoryCode || "LABEL",
      currentStatus: entry.elementStatusCodeCurrent || "OT",
      sourceImage: "",
      elementCode: entry.elementCode || "EL_LBL",
    };
    this.labels = labels;
  }
}

class Label {
  constructor(
    overlayText,
    fontColor,
    fillColor,
    borderColor,
    visualizationDiagram
  ) {
    this.text = overlayText;
    this.offset = {
      x: 0.5,
      y: 0.5,
    };
    this.fillColor = fillColor;
    this.borderColor = borderColor;
    this.fontSize = 10;
    this.fontColor = fontColor;
    this.readOnly = true;
    this.horizontalAlignment = visualizationDiagram.HorizontalAlignment.Center;
    this.verticalAlignment = visualizationDiagram.VerticalAlignment.Center;
    this.textAlign = visualizationDiagram.TextAlign.Center;
  }
}

class LayoutItem {
  constructor(node) {
    this.seatCode = node.addInfo.rowReference + node.addInfo.columnReference;
    this.layoutElementCode = node.name.replace(/:/g, ":,");
    this.elementCode = node.addInfo.elementCode;
    this.node = node;
  }
}

export const SeatType = {
  ToiletMale: { code: "EL_TOL_M", name: "Toilet Male" },
  ToiletFemale: { code: "EL_TOL_F", name: "Toilet Female" },
  ToiletHandicap: { code: "EL_TOL_H", name: "Toilet Handicap" },
  Exit: { code: "EL_EXT", name: "Exit" },
  Wall: { code: "EL_WAL", name: "Wall" },
  Aisle: { code: "EL_ASL", name: "Wall" },
  DigitalScreen: { code: "EL_SCR", name: "Digital Screen" },
  IMAXScreen: { code: "EL_SCRIM", name: "IMAX Screen" },
  Table: { code: "EL_TBL", name: "Table" },
  StandardSeat: { code: "EL_STS", name: "Standard Seat" },
  CoupleSeat: { code: "EL_CPS", name: "Couple Seat" },
  HandicapSeat: { code: "EL_HDS", name: "Handicap Seat" },
  Entrance: { code: "EL_ENT", name: "Entrance" },
  Label: { code: "EL_LBL", name: "Label" },
};

export const SeatStatus = {
  Available: "AV",
  OnHold: "OH",
  Blocked: "BL",
  Selected: "CS",
  SoldOut: "SO",
};

export const SeatAction = {
  Hold: "hold",
  Release: "release",
  Block: "block",
  Unblock: "unblock",
};

export const SeatCategory = {
  Seat: "SEAT",
  Label: "LABEL",
};

export const SeatSelectionMode = {
  SelectSeat: "selectSeat",
  BlockHouseSeat: "blockHouseSeat",
  ReleaseHouseSeat: "releaseHouseSeat",
  SelectBlockedSeat: "selectBlockedSeat",
};

export class SeatSelection {
  constructor(wrapperId, performanceId, layout) {
    this.layoutData = layout || [];
    this.diagramJqueryObj = window.$ ? window.$(`#${wrapperId}`) : {};
    this.performanceId = performanceId;
    this.dataVisualization = window.ej ? window.ej.datavisualization : {};
    this.visualizationDiagram = this.dataVisualization.Diagram;
    this.diagramConstraints = this.visualizationDiagram.DiagramConstraints;
    this.selectedItem = null;
    this.seatElementsServerPath = "";
    this.groupedElements = [];
    this.rowGroupedSeatElements = {};
    this.selectedItems = [];
    this.isPreventClick = false;
    this.selectionMode = SeatSelectionMode.SelectSeat;
    this.isLayoutInitiated = false;
    this.sessionId = crypto
      ? crypto.randomUUID()
      : `${Math.floor(Math.random() * 90000) + 10000}`;
    this.corporrateBookingRestService = new CorporateBookingRestService();
    this.isExistLayout = false;
  }

  onSeatChange = () => {};
  canHoldSeat = () => true;
  readOnly = () => false;
  isReleaseHouseSeatMode = () => {
    return this.selectionMode === SeatSelectionMode.ReleaseHouseSeat;
  };

  isBlockHouseSeatMode = () => {
    return this.selectionMode === SeatSelectionMode.BlockHouseSeat;
  };

  isSelectBlockedSeatMode = () => {
    return this.selectionMode === SeatSelectionMode.SelectBlockedSeat;
  };

  isSelectSeatMode = () => {
    return this.selectionMode === SeatSelectionMode.SelectSeat;
  };

  setSelectionMode = (mode) => {
    this.selectionMode = mode;
  };
  getAvailableAutoSelectSeats = () => {
    return this.getSeatsByStatus((x) => {
      if (x.isHouseSeat) {
        return [SeatStatus.Available, SeatStatus.Blocked].includes(
          x.currentStatus
        );
      }
      return (
        x.currentStatus === SeatStatus.Available ||
        this.isSeatBlocker({ addInfo: x })
      );
    });
  };

  getDiagramInstance = () => {
    if (
      !this.diagramJqueryObj ||
      !this.diagramJqueryObj.ejDiagram ||
      !this.isLayoutInitiated
    )
      return null;
    return this.diagramJqueryObj.ejDiagram("instance");
  };

  getImageForElementStatus = (source, elementCode, seatStatusRequired) => {
    let imageReference = "";
    if (!source.hasOwnProperty(elementCode)) {
      return imageReference;
    }

    const found = source[elementCode].find(
      (x) => x.elementStatusCodeCurrent === seatStatusRequired
    );
    if (found) {
      return `${this.seatElementsServerPath}${found.element_media_path_reference}`;
    }
    return imageReference;
  };

  onNotifySeatChanges = (event) => {
    const diagram = this.getDiagramInstance();
    const selectedLayoutElementCodes = this.selectedItems.map(
      (x) => x.layoutElementCode
    );
    const { actor, changedSeatData } = event;
    const sessionGuid = this.sessionId;

    // Ignore the seat status changed for the session that made the change
    if (actor === sessionGuid) return;

    Object.entries(changedSeatData).forEach(
      ([layoutElementCode, seatChangeData]) => {
        // Ignore the seat status synchronization with current selected seats
        // when opening the performance and select the seats
        const {
          updatedBy,
          status,
          sessionGuid: seatSessionGuid,
          initialStatus,
        } = seatChangeData;

        // Should not change the seat status when the seatSessionGuid match with current session guid
        // It means the sessionGuid is the owner of corresponding changes so it should be ignore
        // In order to keep the changes of current session
        if (
          selectedLayoutElementCodes.includes(layoutElementCode) &&
          sessionGuid === seatSessionGuid
        ) {
          return;
        }
        const node = diagram.findNode(layoutElementCode);
        const nextStatus = getStatus(status);
        node.addInfo.updatedBy = updatedBy;
        node.addInfo.initialStatus = getStatus(initialStatus);
        this.setSeatStatus(node, nextStatus);
      }
    );
  };

  getUpdateSeatStatusRequest(nodeSeats) {
    const getLayoutElementCodes = (nodeSeats) => {
      if (this.isExistLayout) {
        nodeSeats = nodeSeats.filter((x) => !x.addInfo.isOwner);
      }
      return nodeSeats.map((x) => new LayoutItem(x).layoutElementCode);
    };

    const layoutElementCodes = getLayoutElementCodes(nodeSeats);
    if (!layoutElementCodes.length) {
      return null;
    }

    return {
      layoutElementCodes,
      sessionGuid: this.sessionId,
      performanceId: this.performanceId,
    };
  }

  releaseAllSeatsInSession = () => {
    this.corporrateBookingRestService.releaseSeat({
      sessionGuid: this.sessionId,
    });
  };

  toggleHighLightBlockableHouseSeat = (isHighLight) => {
    const diagram = this.getDiagramInstance();
    if (!diagram) {
      return;
    }
    const houseSeatLayoutElementCodes = this.layoutData
      .filter((x) => x.isHouseSeat)
      .map((x) => x.layoutElementCode);
    const blockableHouseSeatNodes = diagram.model.nodes.filter((x) =>
      houseSeatLayoutElementCodes.includes(x.name)
    );
    for (const node of blockableHouseSeatNodes) {
      const nodeAttribute = {
        type: ImageType,
        source: this.getImageForElementStatus(
          this.groupedElements,
          node.addInfo.elementCode,
          node.addInfo.currentStatus
        ),
        addInfo: node.addInfo,
        borderColor: "",
        borderWidth: 0,
      };
      if (isHighLight && node.addInfo.currentStatus !== SeatStatus.Blocked) {
        nodeAttribute.borderColor = "red";
        nodeAttribute.borderWidth = 2;
      }
      diagram.updateNode(node.name, nodeAttribute);
    }
  };

  getElementAndMediaValues = () => {
    this.seatElementsServerPath = "";
    return groupBy(ELEMENT_MEDIA_MAPPING, "elementCode");
  };

  autoSelect = async (numOfSeats) => {
    if (!this.getDiagramInstance() || numOfSeats <= 0) {
      return;
    }
    const prevSelectedItems = [...this.selectedItems];
    const selectableSeats = this.getAvailableAutoSelectSeats()
      .map((x) => x.node)
      .sort((x, y) => sortNodes(x, y));

    let index = selectableSeats.length;
    const holdSeats = [];
    while (
      !this.readOnly() &&
      this.canHoldSeat() &&
      this.selectedItems.length < numOfSeats
    ) {
      const node = selectableSeats[--index];
      if (index < 0) {
        return;
      }
      this.setSeatStatus(node, SeatStatus.Selected);
      this.selectedItems.push(new LayoutItem(node));
      holdSeats.push(node);
    }
    showLoading(true);
    await this.executeHoldSeatRequest(holdSeats, SeatStatus.Available);
    showLoading(false);
    this.checkSeatChanged(this.selectedItems, prevSelectedItems);
  };

  checkSeatChanged = (nextSelectedSeat, prevSelectedSeat) => {
    if (nextSelectedSeat.length !== prevSelectedSeat.length) {
      this.onSeatChange({
        selectedItems: this.selectedItems,
      });
    }
    const isDifference = nextSelectedSeat.some(
      (x) => prevSelectedSeat.find((y) => y.seatCode === x.seatCode) == null
    );
    if (isDifference) {
      this.onSeatChange({
        selectedItems: this.selectedItems,
      });
    }
  };

  buildSeatNode = (entry, isOwner) => {
    if (entry.elementCategoryCode !== "SEAT") {
      return null;
    }
    const getImageRef = (elementCode) => {
      let imageReference = this.getImageForElementStatus(
        this.groupedElements,
        elementCode,
        SeatStatus.Selected
      );
      if (imageReference) {
        return imageReference;
      }

      if (elementCode === "EL_STS" || elementCode === "EL_HDS") {
        return "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/seat-curr-select.png";
      }

      if (elementCode === "EL_CPS") {
        return "https://nonprodngsstorageshawsg.blob.core.windows.net/uat/content/images/layout/default/en-sg/coup-curr-select.png";
      }

      return imageReference;
    };

    const elementCode = entry.elementCode;
    const source = this.getImageForElementStatus(
      this.groupedElements,
      elementCode,
      entry.elementStatusCodeCurrent
    );
    const imageNode = new SampleImageNode(
      entry,
      source,
      elementCode || "EL_STS",
      getImageRef(elementCode),
      createSeatNodeLabels(this.visualizationDiagram, entry),
      this.visualizationDiagram,
      isOwner
    );

    if (entry.elementStatusCodeCurrent === SeatStatus.Blocked) {
      imageNode.addInfo.BlockedBy = entry.updatedBy;
    }
    return imageNode;
  };
  buildElementMediaPathNode = (entry) => {
    const mediaRef = entry.element_media_path_reference;
    if (mediaRef !== null || mediaRef !== "") {
      const elementCode = entry.elementCode;
      const source = this.getImageForElementStatus(
        this.groupedElements,
        elementCode,
        entry.elementStatusCodeCurrent
      );
      let label = new Label(
        entry.overlayText || "",
        "#3c3737",
        "transparent",
        "none",
        this.visualizationDiagram
      );
      return new SampleImageNode(
        entry,
        source,
        elementCode || "EL_LBL",
        "",
        [label],
        this.visualizationDiagram
      );
    }
    return null;
  };

  convertLayoutNodes = (layoutData, selectedSeats = []) => {
    const results = [];
    layoutData.forEach((entry) => {
      const elLblNode = buildElLblNode(this.visualizationDiagram, entry);
      if (elLblNode) {
        results.push(elLblNode);
        return;
      }

      const seatNode = this.buildSeatNode(
        entry,
        selectedSeats.includes(getLayoutSeatCode(entry))
      );
      if (seatNode) {
        results.push(seatNode);
        return;
      }

      const mediaNode = this.buildElementMediaPathNode(entry);
      if (mediaNode != null) {
        results.push(mediaNode);
        return;
      }

      const wallNode = buildWallNode(this.visualizationDiagram, entry);
      if (wallNode != null) {
        results.push(wallNode);
      }
    });
    return results;
  };
  onNodeClick = (sender) => {
    if (this.isPreventClick) {
      this.isPreventClick = false;
      return;
    }
    let currentElementRow = "",
      currentElementColumn = "";
    if (!sender.element || sender.element._type !== "node") {
      this.isPreventClick = false;
      return;
    }
    let selectedNode = sender.element;
    if (selectedNode.addInfo.seatCategory !== SeatCategory.Seat) {
      this.isPreventClick = false;
      return;
    }

    currentElementRow = selectedNode.addInfo.rowReference;
    currentElementColumn = selectedNode.addInfo.columnReference;
    let checker = this.verifySelectedSeats(
      currentElementRow,
      currentElementColumn,
      selectedNode.name
    );
    if (this.selectedItems.length > 0 && checker !== 0) {
      this.isPreventClick = false;
      return;
    }

    this.onConfirmSelection(selectedNode);
  };
  isSeatBlocker = (node) => {
    return (
      node.addInfo.currentStatus === SeatStatus.Blocked &&
      node.addInfo.updatedBy === AuthenticationManager.username()
    );
  };
  onConfirmSelection = (selectedNode) => {
    if (this.readOnly()) return;

    const prevSelectedItems = [...this.selectedItems];
    const addInfo = selectedNode.addInfo;

    switch (addInfo.currentStatus) {
      case SeatStatus.Available:
        if (this.isSelectBlockedSeatMode()) return;
        if (this.isBlockHouseSeatMode()) {
          this.blockHouseSeat(selectedNode);
          break;
        }
        this.holdSeat(selectedNode);
        break;
      case SeatStatus.Selected:
        if (this.isBlockHouseSeatMode()) {
          this.blockHouseSeat(selectedNode);
          break;
        }
        this.releaseSeat(selectedNode);
        break;
      case SeatStatus.Blocked:
        if (
          this.isSelectBlockedSeatMode() &&
          this.isSeatBlocker(selectedNode)
        ) {
          this.holdSeat(selectedNode, SeatStatus.Blocked);
          break;
        }
        this.unblockSeat(selectedNode);
        break;
    }

    if (
      (this.isSelectSeatMode() || this.isSelectBlockedSeatMode()) &&
      this.onSeatChange
    ) {
      this.checkSeatChanged(this.selectedItems, prevSelectedItems);
    }
  };

  releaseSeat = (selectedNode) => {
    const addInfo = selectedNode.addInfo;
    if (this.readOnly() || addInfo.currentStatus !== SeatStatus.Selected) {
      return false;
    }
    const pevStatus = addInfo.currentStatus;

    this.setSeatStatus(selectedNode, addInfo.initialStatus);
    this.confirmSeatUpdate(selectedNode);
    this.executeReleasingSeatRequest([selectedNode], pevStatus);
    return true;
  };

  holdSeat = (selectedNode, allowHoldSeat = SeatStatus.Available) => {
    const addInfo = selectedNode.addInfo;
    if (
      this.readOnly() ||
      !this.canHoldSeat() ||
      addInfo.currentStatus !== allowHoldSeat
    ) {
      return false;
    }
    const prevStatus = addInfo.currentStatus;

    this.setSeatStatus(selectedNode, SeatStatus.Selected);
    this.confirmSeatUpdate(selectedNode);
    this.executeHoldSeatRequest([selectedNode], prevStatus);
    return true;
  };

  setSeatStatus = (selectedNode, status) => {
    const diagram = this.getDiagramInstance();
    if (!diagram) return;
    const addInfo = selectedNode.addInfo;
    addInfo.currentStatus = status;
    const elementCode = addInfo.elementCode
      ? addInfo.elementCode
      : SeatType.StandardSeat.code;
    const imageReference = this.getImageForElementStatus(
      this.groupedElements,
      elementCode,
      status
    );
    diagram.updateNode(selectedNode.name, {
      type: ImageType,
      source: imageReference,
      addInfo: addInfo,
    });
  };

  confirmSeatUpdate = (node) => {
    const item = new LayoutItem(node);
    const found = this.selectedItems.find((x) => x.seatCode === item.seatCode);

    const isSelected = node.addInfo.currentStatus === SeatStatus.Selected;

    if (!isSelected) {
      this.selectedItems = this.selectedItems.filter(
        (x) => x.seatCode !== item.seatCode
      );
    }

    if (isSelected && !found) {
      this.selectedItems.push(item);
    }
  };

  blockHouseSeat = (selectedNode) => {
    const layoutItem = new LayoutItem(selectedNode);
    const isHouseSeat = this.isHouseSeat(layoutItem.layoutElementCode);
    if (!isHouseSeat || this.readOnly() || !this.isBlockHouseSeatMode()) {
      return false;
    }

    const prevStatus = selectedNode.addInfo.currentStatus;

    this.setSeatStatus(selectedNode, SeatStatus.Blocked);
    this.confirmSeatUpdate(selectedNode);
    this.executeBlockSeatRequest([selectedNode], prevStatus);
    return true;
  };

  isHouseSeat = (layoutElementCode) => {
    const layout = this.layoutData.find(
      (x) => x.layoutElementCode === layoutElementCode
    );
    return layout && layout.isHouseSeat;
  };

  unblockSeat = (selectedNode) => {
    if (this.readOnly() || !this.isReleaseHouseSeatMode()) {
      return false;
    }
    const prevStatus = selectedNode.addInfo.currentStatus;
    this.setSeatStatus(selectedNode, SeatStatus.Available);
    this.confirmSeatUpdate(selectedNode);
    this.executeUnblockSeatRequest([selectedNode], prevStatus);
    return true;
  };
  verifySelectedSeats = (seatRow, seatColumn) => {
    const listOfSeatEachRow = getSeatOffsets();

    //Check if the list of row based offsets has the new row of seat that has been selected
    if (!(seatRow in listOfSeatEachRow)) {
      return 0;
    }
    let selectionValid = 0;
    //Loop through the seats' offsets separated based on the rows.
    for (let rowOffsets in listOfSeatEachRow) {
      //Check the conditions below only if the current selected row is the same as the loop row.
      if (
        seatRow !== rowOffsets ||
        !listOfSeatEachRow.hasOwnProperty(rowOffsets)
      ) {
        continue;
      }

      const indexes = listOfSeatEachRow[rowOffsets];
      //Second level of check is for verifying if the element is sequential ( verify the number order and it should be incremental or decremental)
      const currentSeatIndex = findWithAttr(
        this.rowGroupedSeatElements[seatRow],
        "columnReference",
        seatColumn
      ); // returns index of column
      indexes.sort((a, b) => a - b);

      const firstIndex = findWithAttr(
        this.rowGroupedSeatElements[seatRow],
        "anchorCoordinateX",
        indexes[0]
      );
      const lastIndex = findWithAttr(
        this.rowGroupedSeatElements[seatRow],
        "anchorCoordinateX",
        indexes[indexes.length - 1]
      );
      const closestIndex =
        currentSeatIndex >= firstIndex
          ? currentSeatIndex >= lastIndex
            ? lastIndex
            : firstIndex
          : firstIndex >= lastIndex
          ? lastIndex
          : firstIndex;

      if (
        firstIndex !== lastIndex &&
        firstIndex < currentSeatIndex &&
        currentSeatIndex < lastIndex
      ) {
        selectionValid = -20;
        continue;
      }

      if (currentSeatIndex < closestIndex) {
        if (currentSeatIndex + 1 === closestIndex) {
          if (
            (currentSeatIndex - 1 === firstIndex ||
              currentSeatIndex + 1 === lastIndex) &&
            !(firstIndex === lastIndex)
          ) {
            selectionValid = -20;
          }
        } else {
          selectionValid = this.seatAvailabilityValidation(
            seatRow,
            currentSeatIndex,
            closestIndex
          );
        }
        continue;
      }

      if (currentSeatIndex > closestIndex) {
        if (currentSeatIndex - 1 === closestIndex) {
          if (
            (currentSeatIndex + 1 === lastIndex ||
              currentSeatIndex - 1 === firstIndex) &&
            !(firstIndex === lastIndex)
          ) {
            selectionValid = -20;
          }
        } else {
          selectionValid = this.seatAvailabilityValidation(
            seatRow,
            currentSeatIndex,
            closestIndex
          );
        }
      }
    }
    return selectionValid;
  };

  seatAvailabilityValidation = (seatRow, currentSeatIndex, closestIndex) => {
    let seats = getSeatsInBetween(currentSeatIndex, closestIndex);
    for (let index = 0; index < seats.length; index++) {
      const seat = seats[index];
      if (
        this.rowGroupedSeatElements[seatRow][seat][
          "elementStatusCodeCurrent"
        ] === SeatStatus.Available
      ) {
        return -20;
      }
    }
    return 0;
  };

  nodeDragging = (args) => {
    let diagram = this.getDiagramInstance();
    diagram._clearSelection();
    args.cancel = true;
  };
  scrolling = (args) => {
    let diagramObj = this.getDiagramInstance();
    let oldZoomValues = args.oldValues;
    let newZoomValues = args.newValues;
    let offsetHorzDiff =
      oldZoomValues.horizontalOffset - newZoomValues.horizontalOffset;
    let offsetVertDiff =
      oldZoomValues.verticalOffset - newZoomValues.verticalOffset;
    let zoomDiff = oldZoomValues.zoom - newZoomValues.zoom;
    if (
      zoomDiff === 0 &&
      args.cause !== "zoom" &&
      Math.abs(offsetHorzDiff) <= 0.5 &&
      Math.abs(offsetVertDiff) <= 0.5
    ) {
      args.cancel = true;
      return;
    }
    const value =
      this.diagramConstraints.Resizable |
      this.diagramConstraints.Zoomable |
      this.diagramConstraints.Pannable;
    if (args.cause === "zoom" && diagramObj.model.constraints !== value) {
      diagramObj.model.constraints = value;
      diagramObj.update({
        tool:
          this.visualizationDiagram.Tool.ZoomPan |
          this.visualizationDiagram.Tool.SingleSelect,
      });
    }
  };
  buildPageSettings = () => {
    return {
      scrollLimit: this.visualizationDiagram.ScrollLimit.Diagram,
      multiplePage: true,
      autoScrollBorder: { left: 150, top: 15, right: 15, bottom: 15 },
      boundaryConstraints: "infinity",
      pageBackgroundColor: "black",
    };
  };
  buildCommandManagerConfiguration = () => {
    return {
      //command manager to zoomIn and zoomOut of diagram on pressing ctrl+ and ctrl-
      commands: {
        zoomIn: {
          canExecute: () => true,
          execute: () => {
            let zoom = this.visualizationDiagram.Zoom();
            zoom.zoomCommand = this.visualizationDiagram.ZoomCommand.ZoomIn;
            this.getDiagramInstance().zoomTo(zoom);
          },
          gesture: {
            key: 123,
            keyModifiers: this.visualizationDiagram.KeyModifiers.Control,
          },
        },
        zoomOut: {
          canExecute: () => true,
          execute: () => {
            let zoom = this.visualizationDiagram.Zoom();
            zoom.zoomCommand = this.visualizationDiagram.ZoomCommand.ZoomOut;
            this.getDiagramInstance().zoomTo(zoom);
          },
          gesture: {
            key: 122,
            keyModifiers: this.visualizationDiagram.KeyModifiers.Control,
          },
        },
      },
    };
  };

  fitToPageDiagram = () => {
    const marginObj = { right: 10, left: 10 };
    const instance = this.getDiagramInstance();
    if (!instance || !instance.fitToPage) {
      return;
    }
    instance.fitToPage(
      this.visualizationDiagram.FitMode.Page,
      this.visualizationDiagram.Region.content,
      marginObj,
      true
    );
    instance.model.constraints =
      this.diagramConstraints.Resizable | this.diagramConstraints.Zoomable;
    instance.update({
      tool:
        this.visualizationDiagram.Tool.ZoomPan |
        this.visualizationDiagram.Tool.SingleSelect,
    });
    const bounds = setViewToLeftNode(instance);
    if (!bounds) {
      return;
    }
    for (let i = 0; i < instance.model.nodes.length; i++) {
      const node = instance.model.nodes[i];
      instance.updateNode(node.name, {
        offsetY: node.offsetY - bounds.y ?? 0,
      });
    }
    instance.update({ scrollSettings: { verticalOffset: -bounds.y } });
  };

  getSeatsByStatus = (conditionFunc) => {
    const instance = this.getDiagramInstance();
    if (!instance || !instance.model) {
      return [];
    }

    return instance.model.nodes
      .filter((x) => conditionFunc(x.addInfo))
      .map((x) => new LayoutItem(x));
  };

  populateSelectedItems = (selectedSeatCodes) => {
    if (!selectedSeatCodes?.length) return [];
    for (const seatCode of selectedSeatCodes) {
      const found = this.layoutData.find(
        (x) => `${x.rowReference}${x.columnReference}` === seatCode
      );
      if (found) {
        found.elementStatusCodeCurrent = SeatStatus.Selected;
      }
    }
    return this.convertLayoutNodes(this.layoutData)
      .filter((x) => x.addInfo.currentStatus === SeatStatus.Selected)
      .map((x) => new LayoutItem(x));
  };

  build = (seatCodes, isExistLayout) => {
    this.isExistLayout = isExistLayout;
    this.groupedElements = this.getElementAndMediaValues();
    const selectedSeatCodes = seatCodes ? [...seatCodes] : [];
    this.selectedItems = this.populateSelectedItems(selectedSeatCodes);

    this.rowGroupedSeatElements = groupBy(
      convertElements(this.layoutData),
      "rowReference"
    );
    const layoutNodes = this.convertLayoutNodes(
      this.layoutData,
      seatCodes,
      true
    );
    if (!this.diagramJqueryObj) {
      return;
    }
    this.diagramJqueryObj.ejDiagram({
      width: DefaultPageWidth,
      height: DefaultPageHeight,
      click: this.onNodeClick,
      drag: this.nodeDragging,
      enableContextMenu: false,
      scrollChange: this.scrolling,
      scrollSettings: {
        maxZoom: 2,
        minZoom: 0.1,
      },
      enableAutoScroll: false,
      pageSettings: this.buildPageSettings(),
      constraints:
        this.diagramConstraints.Resizable | this.diagramConstraints.Zoomable,
      tool: this.visualizationDiagram.Tool.ZoomPan,
      selectedItems: {
        constraints: this.visualizationDiagram.NodeConstraints.PointerEvents,
      },
      snapSettings: { snapConstraints: null },
      autoScrollChange: (args) => (args.cancel = true),
      commandManager: this.buildCommandManagerConfiguration(),
      mouseEnter: () => {},
      tooltip: buildTooltipSettings(),
      nodes: layoutNodes,
    });
    if (layoutNodes.length > 0) {
      this.fitToPageDiagram();
    }
    this.diagramJqueryObj.taphold(() => {
      this.isPreventClick = true;
    });
    this.isLayoutInitiated = true;
    this.registerReleaseSeatsBeforeUnload();
  };

  registerReleaseSeatsBeforeUnload = () => {
    window.onbeforeunload = () => {
      console.log("Releasing all selected seats");
      this.releaseAllSeatsInSession();
    };
  };

  destroy = () => {
    const instance = this.getDiagramInstance();
    if (!instance) {
      return;
    }
    instance.destroy();
  };

  showSelectedSeats = () => {
    const diagram = this.getDiagramInstance();
    for (const item of this.selectedItems) {
      let node = diagram.findNode(item.layoutElementCode);
      if (
        node.type !== ImageType ||
        node.addInfo.seatCategory !== SeatCategory.Seat
      ) {
        continue;
      }
      node.addInfo.currentStatus = SeatStatus.Selected;
      diagram.updateNode(item.layoutElementCode, {
        type: ImageType,
        source: node.addInfo.selectedImage,
        borderColor: "none",
        borderWidth: 0,
        addInfo: node.addInfo,
      });
    }
  };

  clearAllSeats = async () => {
    const instance = this.getDiagramInstance();
    if (!instance) {
      return;
    }

    const prevSelectedItems = [...this.selectedItems];
    const releaseNormalSeats = [];

    for (const item of this.selectedItems) {
      const node = instance.findNode(item.layoutElementCode);
      releaseNormalSeats.push(node);
      this.setSeatStatus(node, node.addInfo.initialStatus);
    }
    this.selectedItems = [];
    showLoading(true);
    await this.executeReleasingSeatRequest(releaseNormalSeats);
    showLoading(false);
    this.checkSeatChanged(this.selectedItems, prevSelectedItems);
  };

  async executeBlockSeatRequest(nodeSeats, prevStatus) {
    await this.executeSeatUpdate(nodeSeats, prevStatus, (request) =>
      this.corporrateBookingRestService.blockSeat(request)
    );
  }

  async executeUnblockSeatRequest(nodeSeats, prevStatus = SeatStatus.Blocked) {
    await this.executeSeatUpdate(nodeSeats, prevStatus, (request) =>
      this.corporrateBookingRestService.unblockSeat(request)
    );
  }

  async executeHoldSeatRequest(nodeSeats, prevStatus = SeatStatus.Available) {
    await this.executeSeatUpdate(nodeSeats, prevStatus, (request) =>
      this.corporrateBookingRestService.holdSeat(request)
    );
  }

  async executeReleasingSeatRequest(nodeSeats, prevStatus) {
    await this.executeSeatUpdate(nodeSeats, prevStatus, (request) =>
      this.corporrateBookingRestService.releaseSeat(request)
    );
  }

  reverseSeatStatus(nodeSeats, status) {
    nodeSeats.forEach((node) => {
      this.setSeatStatus(node, status || node.addInfo.initialStatus);
      this.confirmSeatUpdate(node);
    });
  }

  async executeSeatUpdate(nodeSeats, prevStatus, action) {
    const request = this.getUpdateSeatStatusRequest(nodeSeats);
    if (!request) return;
    const res = await action(request);
    if (!res) {
      this.reverseSeatStatus(nodeSeats, prevStatus);
    }
  }
}
