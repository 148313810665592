import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class FilmFestivalListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
    this.onTextBoxChange = this.onTextBoxChange.bind(this);
  }

  getApiPath = () => "/api/v1/film_festivals";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "film_festivals",
        name: "Film Festivals",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : this.state.name;
    return super.injectSearchTerm(queryParameters, { name });
  }

  onRowClick = (id) => {
    this.props.history.push(`/film_festivals/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Film Festival Title</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(
                        item.displayStartTime
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(
                        item.displayEndTime
                      )}
                    </td>
                    <td>{item.displayWeight}</td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(item.updatedOn)}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-12 col-xl-6 input-group mb-1">
            <input
              type="text"
              id="input1-group2"
              name="name"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onTextBoxChange}
              value={this.state.name}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </div>
          </div>
          <div className="col-12 col-md-4 col-xl-3 mb-1 d-flex">
            {AuthenticationManager.isAuthorized(
              ResourceID.FilmFestivalCreateButtonPage
            ) && (
              <Link
                className="btn btn-primary flex-fill"
                to="/film_festivals/create"
                style={{ fontSize: "1rem" }}
              >
                <i className="fa fa-plus-circle"></i> Create New Film Festival
              </Link>
            )}
          </div>
          <div className="col-12 col-md-4 col-xl-3 mb-1 d-flex">
            <Link
              className="btn btn-primary flex-fill"
              to="/film_festivals/schedules"
              style={{ fontSize: "1rem" }}
            >
              Scheduled Publishing
            </Link>
          </div>
        </div>
      </div>
    );
  }

  onTextBoxChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
}
