import React, { Component } from "react";
import '../css/documentation.scss';

export class ViewKinoloungeHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To View Kinolounge Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 2 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Kinolounge" link. This will take you to the Kinolounge Listing Page.<br />
                                    <img src="/img/documentation-img/KinoImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Kinolounge Listing Page, there is a search bar for you to filter the kinolounge listings. Type the search string in the text box and click “Search”. <br />
                                    <img src="/img/documentation-img/KinoImg/list_search.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Kinolounge Listing Page, click on any kinolounge listing of your choice. This will take you to the View Kinolounge Details.<br />
                                    <img src="/img/documentation-img/KinoImg/list_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Kinolounge Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Movie ID
                                        </li>
                                        <li>
                                            Movie Title
                                        </li>
                                        <li>
                                            Display Start On
                                        </li>
                                        <li>
                                            Display End On
                                        </li>
                                        <li>
                                            Movie Runtime
                                        </li>
                                        <li>
                                            Movie Rating
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Is Collection
                                        </li>
                                        <li>
                                            Collection Description(Only shown when Is Collection is checked)
                                        </li>
                                        <li>
                                            Poster URL
                                        </li>
                                        <li>
                                            Movie URL
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start On should always be earlier than Display End On.<br />
                                            - If Is Collection is unchecked, there will be no Collection Description field.<br />
                                            - Collection Description should be in HTML format.
                                </div>
                            </li>



                            </ol>
                            <div className="text-right">
                                <a href="/documentation/kinolounge/CreateKinoloungeHelp" className="btn btn-primary">Create Kinolounge &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}