import React from "react";
import TransactionInformationMovies from "./TransactionInformationMovies";
import TransactionInformationProducts from "./TransactionInformationProducts";
import TransactionInformationVouchers from "./TransactionInformationVouchers";
import CardLayout from "../../../layout/CardLayout";

const TransactionInformation = (props) => {
  const { information } = props;
  return (
    <div className="row">
      <CardLayout
        id="transaction"
        bodyClassName="text-left"
        label="Transaction Information"
      >
        <TransactionInformationMovies movieInfos={information?.movieInfos} />
        <TransactionInformationProducts products={information?.products} />
        <TransactionInformationVouchers vouchers={information?.vouchers} />
      </CardLayout>
    </div>
  );
};

export default TransactionInformation;