import { PromotionCategory, VoucherTypeCategory } from "../../../common/Constants";

export function formatContestCategoryName(category) {
    const mapping = {
        [PromotionCategory.NotSpecified]: 'None',
        [PromotionCategory.Contest]: 'Contest',
    }
    return mapping[category || PromotionCategory.NotSpecified] || mapping[PromotionCategory.NotSpecified];
}

export function formatPromotionCategoryName(category) {
    const mapping = {
        [PromotionCategory.NotSpecified]: 'None',
        [PromotionCategory.Discount]: 'Discounts',
        [PromotionCategory.Event]: 'Events',
        [PromotionCategory.FoodAndBeverage]: 'F&B',
        [PromotionCategory.InTheatre]: 'InTheatre',
    }
    return mapping[category || PromotionCategory.NotSpecified] || mapping[PromotionCategory.NotSpecified];
}

export function formatMovieVoucherCategoryName(category) {
    const mapping = {
        [VoucherTypeCategory.NotSpecified]: 'None',
        [VoucherTypeCategory.Personal]: 'Gift/Personal Voucher',
        [VoucherTypeCategory.Corporate]: 'Corporate Voucher',
        [VoucherTypeCategory.Electronic]: 'E-Voucher',
    }
    return mapping[category || VoucherTypeCategory.NotSpecified] || mapping[VoucherTypeCategory.NotSpecified];
}
