import React from "react";
import $ from "jquery";

const onCollapse = (prefix) => {
  let cardBodySelector = `.card-body.${prefix}-info`;
  let downBtnSelector = `.fa-chevron-down.${prefix}-info`;
  let upBtnSelector = `.fa-chevron-up.${prefix}-info`;
  if ($(cardBodySelector).is(":visible")) {
    $(downBtnSelector).hide();
    $(upBtnSelector).show();
  } else {
    $(downBtnSelector).show();
    $(upBtnSelector).hide();
  }
  $(`.${prefix}-info`).toggle("normal");
  $(`#${prefix}-card`).toggleClass("flex-fill");
  $(`#${prefix}-card-col`).toggleClass("d-flex");
};

const CardLayout = (props) => {
  const { id, className, bodyClassName } = props;

  const cardOuterId = `${id}-card-col`;
  const cardHeaderId = `${id}-card`;

  const wrapperClass = `d-flex ${className || "col"}`;
  const cardBodyClass = `${id}-info`
  const bodyClass = `card-body card-block ${cardBodyClass} ${bodyClassName || ''}`

  return (
    <div className={wrapperClass} id={cardOuterId}>
      <div className="card flex-fill" id={cardHeaderId}>
        <div className="card-header" onClick={() => onCollapse(id)}>
          {props.label}
          <i
            className={`fas fa-chevron-down float-right zoom-1_5 ${cardBodyClass}`}
            style={{ display: "none" }}
          />
          <i className={`fas fa-chevron-up float-right zoom-1_5 ${cardBodyClass}`} />
        </div>
        <div className={bodyClass}>{props.children}</div>
      </div>
    </div>
  );
};

export default CardLayout;
