import React from "react";
import { toast } from "react-toastify";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import {
  ExtensionCriteriaType,
  SearchBy,
  VoucherExtensionRequestFieldName,
  VoucherExtensionStatus,
  VoucherStatus,
} from "../../../common/Constants";
import { DateHelper, StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import Validators from "../../common/Validators";
import { RowFormField } from "../../layout/FormLayout";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { FileHelper } from "../../../common/FileHelper";

export default class VoucherExtensionRequestCreation extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
      serialNumber: "",
      serialNumberStart: "",
      serialNumberEnd: "",
      validityEndFrom: "",
      validityEndTo: "",
      validityStartFrom: "",
      validityStartTo: "",
      programId: 0,
      voucherStatuses: [],
      voucherPrograms: [],
      searchBy: SearchBy.SingleSerialNumber,
      showTableAndForm: false,
      extensionCriteriaType: ExtensionCriteriaType.PreDefinedDate,
      validations: [],
      preDefinedDate: null,
      extensionCriteria: null,
      extensionCriterias: [],
      extensionDuration: null,
      reason: null,
      showConfirmDialog: false,
      isSubmit: false,
      durationPlaceHolderLabel: "Enter amount of days",
    };
  }

  async componentDidMount() {
    this.getVoucherPrograms();
  }

  getVoucherPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/vouchers/program_dropdowns",
      (response) => {
        this.setState({ voucherPrograms: response.data });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getExtensionCriterias = () => {
    RestClient.sendGetRequest(
      "/api/v1/voucher_extensions/duration_types",
      (response) => {
        this.setState({ extensionCriterias: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getApiPath = () => "/api/v1/vouchers";

  getDefaultSort = () => "SerialNumber";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher_extensions",
        name: "Voucher Extension Request Creation",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const {
      name,
      serialNumber,
      serialNumberStart,
      serialNumberEnd,
      validityEndFrom,
      validityEndTo,
      validityStartFrom,
      validityStartTo,
      programId,
    } = this.state;
    return super.injectSearchTerm(queryParameters, {
      name: name ?? "",
      serialNumber: serialNumber ?? "",
      serialNumberStart: serialNumberStart ?? "",
      serialNumberEnd: serialNumberEnd ?? "",
      validityEndFrom: validityEndFrom ?? "",
      validityEndTo: validityEndTo ?? "",
      validityStartFrom: validityStartFrom ?? "",
      validityStartTo: validityStartTo ?? "",
      programId,
    });
  }

  onSearchByChange = (e) => {
    if (this.state.searchBy !== e.target.value) {
      this.setState({ searchBy: e.target.value });
      this.setState({
        serialNumber: "",
        serialNumberStart: "",
        serialNumberEnd: "",
        validityEndFrom: "",
        validityEndTo: "",
        validityStartFrom: "",
        validityStartTo: "",
      });
    }
  };

  onInputChange = (e) => {
    const { extensionCriterias } = this.state;
    this.setState({ [e.target.name]: e.target.value });

    if (e.target.name === "extensionCriteria") {
      this.setState({
        durationPlaceHolderLabel: `Enter amount of ${extensionCriterias[
          e.target.value - 1
        ].name
          .toString()
          .toLowerCase()}s`,
      });
    }
  };

  onSearchClick = () => {
    this.setState({ showTableAndForm: true });
    this.search();
    this.getExtensionCriterias();
  };

  onExtensionCriteriaTypeChange = (e) => {
    const { extensionCriterias } = this.state;
    if (this.state.extensionCriteriaType !== e.target.value) {
      this.setState({
        extensionCriteriaType: e.target.value,
        preDefinedDate: null,
        extensionCriteria: extensionCriterias[0].id,
        extensionDuration: null,
      });
    }
  };

  onValidationStatusChange = (e) => {
    let newValidations = this.state.validations;
    let validation = newValidations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      newValidations.push(e);
    }
    this.setState({ validations: newValidations });
  };

  isValidated = () => {
    const { validations } = this.state;
    let result = true;
    if (
      this.state.extensionCriteriaType === ExtensionCriteriaType.PreDefinedDate
    ) {
      result =
        result &&
        validations.some(
          (val) =>
            val.fieldName === VoucherExtensionRequestFieldName.PreDefinedDate &&
            val.isValid
        );
    }
    if (
      this.state.extensionCriteriaType === ExtensionCriteriaType.AddingDuration
    ) {
      result =
        result &&
        validations.some(
          (val) =>
            val.fieldName ===
              VoucherExtensionRequestFieldName.ExtensionDuration && val.isValid
        );
    }

    result =
      result &&
      validations.some(
        (val) =>
          val.fieldName === VoucherExtensionRequestFieldName.Reason &&
          val.isValid
      );
    return result;
  };

  onSubmit = () => {
    let {
      data,
      serialNumber,
      serialNumberStart,
      serialNumberEnd,
      preDefinedDate,
      extensionCriteria,
      extensionDuration,
      reason,
    } = this.state;

    if (data?.length > 1) {
      serialNumber = null;
      serialNumberStart = data[0].serialNumber;
    } else if (data?.length === 1) {
      serialNumber = data[0].serialNumber;
      serialNumberStart = null;
      serialNumberEnd = null;
    }
    const model = {
      serialNumber: serialNumber,
      startSerialNumber: serialNumberStart,
      endSerialNumber: serialNumberEnd,
      preDefinedDate,
      extensionCriteria: parseInt(extensionCriteria),
      extensionDuration,
      reason,
      createdBy: AuthenticationManager.username(),
      status: VoucherExtensionStatus.Pending,
    };
    RestClient.sendPostRequest(
      `api/v1/voucher_extensions`,
      model,
      () => {
        toast.success(
          "Voucher extension request has been created successfully."
        );
        this.setState({ showConfirmDialog: false });
      },
      (error) => {
        this.handleError(error);
        this.setState({ showConfirmDialog: false });
      }
    );
  };

  onClear = () => {
    const { extensionCriterias } = this.state;
    this.setState({
      preDefinedDate: null,
      extensionCriteria: extensionCriterias[0].id,
      extensionDuration: null,
      reason: null,
      isSubmit: false,
    });
  };

  downloadResultSearch = () => {
    const maxResults = this.state.total;
    const { searchBy } = this.state;
    let queryParameters = {
      startAt: 0,
      maxResults: maxResults,
    };
    queryParameters = this.injectSortTerm(queryParameters);
    queryParameters = this.injectSearchTerm(queryParameters, searchBy);
    RestClient.sendGetRequestWithParameters(
      "/api/v1/vouchers",
      queryParameters,
      (response) => {
        const exportedData = response.data.map((item) => ({
          serialNumber: item.serialNumber,
          validityStart: DateHelper.toDisplayDateFormat(item.validityStart),
          validityEnd: DateHelper.toDisplayDateFormat(item.validityEnd),
          status: VoucherStatus.getVoucherStatus(item.status),
        }));
        FileHelper.toExcelFile(exportedData);
        toast.success(
          "The search result excel file is downloading. Please wait."
        );
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  onSubmitBtnClick = () => {
    this.setState({ isSubmit: true });
    if (!this.isValidated()) {
      return;
    }

    this.setState({ showConfirmDialog: true });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  generateExtendedComponents() {
    const { voucherPrograms } = this.state;
    return (
      <React.Fragment>
        <div className="row form-group">
          <label className="form-control-label d-inline-flex text-nowrap col-12 col-xl-1">
            Search By:
          </label>
          <div className="col-md-9 col-xl-8">
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="SingleSN"
                    name="SearchBy"
                    value={SearchBy.SingleSerialNumber}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    checked={
                      this.state.searchBy === SearchBy.SingleSerialNumber
                    }
                  />
                  Single Serial Number
                </>
              }
              htmlFor="SingleSN"
            >
              <input
                className="form-control SingleSN"
                id="SingleText"
                type="text"
                placeholder="Enter Single Serial Number"
                value={this.state.serialNumber}
                name="serialNumber"
                onChange={this.onInputChange}
                disabled={this.state.searchBy !== SearchBy.SingleSerialNumber}
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="SNRange"
                    name="SearchBy"
                    value={SearchBy.SerialNumberRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                  />
                  Serial Number Range
                </>
              }
              htmlFor="SNRange"
            >
              <div className="d-flex justify-content-between">
                <input
                  className="form-control SNRange"
                  type="text"
                  id="SNStart"
                  placeholder="Serial Start"
                  value={this.state.serialNumberStart}
                  name="serialNumberStart"
                  onChange={this.onInputChange}
                  disabled={this.state.searchBy !== SearchBy.SerialNumberRange}
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control SNRange"
                  type="text"
                  id="SNEnd"
                  placeholder="Serial End"
                  value={this.state.serialNumberEnd}
                  name="serialNumberEnd"
                  onChange={this.onInputChange}
                  disabled={this.state.searchBy !== SearchBy.SerialNumberRange}
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="DateRange"
                    name="SearchBy"
                    value={SearchBy.ValidityEndDateRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                  />
                  Validity End Date Range
                </>
              }
              htmlFor="DateRange"
            >
              <div className="row mx-0 justify-content-between">
                <input
                  className="form-control DateStart"
                  type="date"
                  id="DateStart"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityEndFrom}
                  name="validityEndFrom"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityEndDateRange
                  }
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control DateStart"
                  type="date"
                  id="DateEnd"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityEndTo}
                  name="validityEndTo"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityEndDateRange
                  }
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="DateRange2"
                    name="SearchBy"
                    value={SearchBy.ValidityStartDateRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                  />
                  Validity Start Date Range
                </>
              }
              htmlFor="DateRange2"
            >
              <div className="row mx-0 justify-content-between">
                <input
                  className="form-control"
                  type="date"
                  id="DateStart2"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityStartFrom}
                  name="validityStartFrom"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityStartDateRange
                  }
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control"
                  type="date"
                  id="DateEnd2"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityStartTo}
                  name="validityStartTo"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityStartDateRange
                  }
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
          </div>
          <div className="col-md-3 ml-auto">
            <div className="form-group">
              <label className="form-control-label">Voucher Program:</label>
              <select
                className="form-control"
                id="SelectVoucher"
                name="programId"
                onChange={this.onInputChange}
                required
              >
                <option value="0">All Voucher</option>
                {voucherPrograms &&
                  voucherPrograms.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <ButtonActionDetailGroup>
          {this.state.total > 0 && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={this.downloadResultSearch}
              id="btnDownload"
            >
              <i className="fa fa-download" />
              Download Results
            </ButtonActionDetailGroup.Item>
          )}
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            onClick={this.onSearchClick}
            id="btnSearch"
          >
            <i className="fa fa-search" />
            Search
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      </React.Fragment>
    );
  }

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>
              Serial Number
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="SerialNumber"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Validity Start
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityStart"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Validity End
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityEnd"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id} style={{ cursor: "default" }}>
                  <td>{item.serialNumber}</td>
                  <td>
                    {StringHelper.asDateStringToDefaultFormat(
                      item.validityStart
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDefaultFormat(item.validityEnd)}
                  </td>
                  <td className="color-orange">
                    {VoucherStatus.getVoucherStatus(item.status)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateMainGrid() {
    const { showTableAndForm } = this.state;
    return (
      showTableAndForm && (
        <div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">{this.generateTableFilter()}</div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="table-responsive m-b-10 table-px-15">
                    <table className="table table-borderless table-striped table-earning">
                      {this.generateTableContent()}
                    </table>
                  </div>
                </div>
              </div>
              {this.generatePagination()}
            </div>
          </div>
          {this.generateExtraComponents()}
        </div>
      )
    );
  }

  generateExtraComponents() {
    const {
      preDefinedDate,
      extensionCriteria,
      extensionCriterias,
      extensionDuration,
      reason,
      isSubmit,
      durationPlaceHolderLabel,
    } = this.state;
    return (
      <>
        <div className="row" id="extension-card">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2">
                    <label className="form-control-label">
                      Extension Criteria:
                    </label>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="radio">
                      <label htmlFor="predefDate" className="form-check-label ">
                        <input
                          type="radio"
                          id="predefDate"
                          name="Extension"
                          className="mr-1"
                          value={ExtensionCriteriaType.PreDefinedDate}
                          onChange={this.onExtensionCriteriaTypeChange}
                          checked={
                            this.state.extensionCriteriaType ===
                            ExtensionCriteriaType.PreDefinedDate
                          }
                        />
                        By pre-defined date
                      </label>
                      {this.state.extensionCriteriaType ===
                        ExtensionCriteriaType.PreDefinedDate && (
                        <>
                          <input
                            className="form-control Date ExtensionField"
                            type="date"
                            placeholder="Enter pre-defined date"
                            name={
                              VoucherExtensionRequestFieldName.PreDefinedDate
                            }
                            onChange={this.onInputChange}
                            value={preDefinedDate || ""}
                          />
                          <Validators.RequiredValidator
                            onValidationStatusChange={
                              this.onValidationStatusChange
                            }
                            fieldName={
                              VoucherExtensionRequestFieldName.PreDefinedDate
                            }
                            property="Pre-defined Date"
                            value={preDefinedDate}
                            isEnabled={isSubmit}
                          />
                        </>
                      )}
                    </div>
                    <div className="radio">
                      <label
                        htmlFor="DurationField"
                        className="form-check-label"
                      >
                        <input
                          type="radio"
                          id="DurationField"
                          className="mr-1"
                          name="Extension"
                          value={ExtensionCriteriaType.AddingDuration}
                          onChange={this.onExtensionCriteriaTypeChange}
                        />
                        By adding duration to existing validity end
                      </label>
                      {this.state.extensionCriteriaType ===
                        ExtensionCriteriaType.AddingDuration && (
                        <>
                          <br />
                          <label
                            className="form-control-label row mt-2 Duration ExtensionField"
                            style={{ padding: "0 15px" }}
                          >
                            Type:
                            <select
                              className="form-control ml-3 col Duration ExtensionField"
                              id="extensionCriteria"
                              name="extensionCriteria"
                              onChange={this.onInputChange}
                              value={extensionCriteria || ""}
                            >
                              {extensionCriterias &&
                                extensionCriterias.map((item) => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </label>
                          <label
                            className="form-control-label row mt-2 Duration ExtensionField"
                            style={{ padding: "0 15px" }}
                          >
                            Value:
                            <input
                              id="Value"
                              className="form-control ml-2 col Duration ExtensionField"
                              placeholder={durationPlaceHolderLabel}
                              type="number"
                              min={1}
                              max={100}
                              name={
                                VoucherExtensionRequestFieldName.ExtensionDuration
                              }
                              onChange={this.onInputChange}
                              value={extensionDuration || ""}
                            />
                          </label>
                          <Validators.RequiredValidator
                            onValidationStatusChange={
                              this.onValidationStatusChange
                            }
                            fieldName={
                              VoucherExtensionRequestFieldName.ExtensionDuration
                            }
                            property="Value"
                            isEnabled={isSubmit}
                            value={extensionDuration}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2">
                    <label className="form-control-label">
                      Reason for voucher extension:
                    </label>
                  </div>
                  <div className="col-md-5">
                    <textarea
                      className="form-control"
                      id="Reason"
                      placeholder="Enter reason for voucher extension"
                      style={{ height: 100 }}
                      name={VoucherExtensionRequestFieldName.Reason}
                      onChange={this.onInputChange}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName={VoucherExtensionRequestFieldName.Reason}
                      property="Reason"
                      value={reason}
                      isEnabled={isSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ButtonActionDetailGroup className="d-flex justify-content-end flex-row mb-2">
          <ButtonActionDetailGroup.Item
            className="btn btn-success mr-1"
            id="btnExtension"
            onClick={this.onSubmitBtnClick}
          >
            Submit Extension Request
          </ButtonActionDetailGroup.Item>
          <ButtonActionDetailGroup.Item
            className="btn btn-danger"
            id="btnClear"
            onClick={this.onClear}
          >
            Clear
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {this.state.showConfirmDialog && (
          <ConfirmDialog
            onCancel={this.onHideConfirmDialog}
            onProceed={this.onSubmit}
            message="You are requesting to extend these voucher/s by pre-defined date from existing validity end of each vouchers. Please confirm to continue."
            title="Confirmation"
          />
        )}
      </>
    );
  }
}
