import React, { useEffect, useState } from "react";
import Select from "react-select";

export const CustomSelect = (props) => {
  const {
    data,
    label,
    value,
    onChange,
    selectedValue,
    compareFunc,
    disabled,
    allowEmpty,
    isMulti,
    menuPlacement,
  } = props;

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!data) {
      return;
    }
    let selectOptions = data.map((item) => ({
      label: item[label],
      value: item[value],
    }));
    selectOptions = selectOptions.sort((x, y) => {
      if (compareFunc) {
        return compareFunc(x, y);
      }
      const xLabel = x.label?.toUpperCase();
      const yLabel = y.label?.toUpperCase();
      if (xLabel < yLabel) {
        return -1;
      }
      if (xLabel > yLabel) {
        return 1;
      }
      return 0;
    });
    if (allowEmpty) {
      selectOptions.unshift({ label: "Select an option", value: "" });
    }
    setOptions(selectOptions);
  }, [compareFunc, data, label, value]);
  let selected = options.filter(
    (x) => selectedValue && x.value === selectedValue
  );

  if (isMulti && selectedValue) {
    selected = options.filter((x) => selectedValue.includes(x.value));
  }

  let position = {
    menuPortalTarget: document.body,
    styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
  };

  if (menuPlacement) {
    position = { menuPlacement };
  }

  return (
    <Select
      {...props}
      value={selected}
      options={options}
      onChange={onChange}
      isDisabled={disabled}
      isSearchable
      isMulti={isMulti}
      {...position}
    />
  );
};
