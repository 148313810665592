import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { BaseListing } from "../../common/BaseListing";
import { StringHelper } from "../../../common/Helpers";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class LocationListing extends BaseListing {
  getApiPath = () => "/api/v1/locations";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "locations",
        name: "Locations",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/locations/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Location Name</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>Updated By</th>
            <th>Updated On</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndTime
                    )}
                  </td>
                  <td>{item.displayWeight}</td>
                  <td>{item.updatedBy}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.LocationScheduledListingButtonPage
      ) && (
        <div className="d-flex flex-row justify-content-end mb-3">
          <Link
            className="btn btn-primary"
            to="/locations/schedules"
            style={{ fontSize: "1rem" }}
          >
            Scheduled Publishing
          </Link>
        </div>)
    );
  }
}
