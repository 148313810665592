import React, { useState } from "react";
import { CacheClearingService } from "../../services/CacheClearingService";
import { FieldWrapper } from "../campaigns/FieldWrapper";
import { CustomSelect } from "../common/CustomSelect";
import { BreadCrumb } from "../common/BreadCrumb";
import { useCacheService } from "../../hooks/useCacheService";
import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { RedisSource } from "../../models/RedisSource";
import { isEmpty, startCase } from "lodash";

const normalizeRedisKey = (key) => {
  if (key === "GPay") return "GPay";
  if (key === "PWSMHomePage") return "Homepage, Banners, Carousels, Top Movies";
  return startCase(key);
};

export const CacheClearing = () => {
  const { internalGroupKeys, externalGroupKeys } = useCacheService();
  const [externalKey, setExternalKey] = useState("");
  const [internalKey, setInternalKey] = useState("");
  const [clearing, setClearing] = useState(false);

  const clear = (source, key) => {
    setClearing(true);
    new CacheClearingService()
      .clear(source, key)
      .then((response) => {
        toast.success(response.data.message);
        if (source === RedisSource.Internal) {
          setInternalKey("");
        } else {
          setExternalKey("");
        }
      })
      .finally(() => setClearing(false));
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      activeModule: new NavigationItem({
        identifier: "cache_clear",
        name: "Cache Clearing",
      }),
    });
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-header">Cache Clearing</div>
                  <div className="card-body info">
                    <div className="row form-group">
                      <div className="col-md-6">
                        <FieldWrapper
                          label="External (PWSM, GPAY)"
                          input={
                            <CustomSelect
                              data={externalGroupKeys.map((x) => ({
                                name: normalizeRedisKey(x),
                                value: x,
                              }))}
                              label="name"
                              value="value"
                              onChange={(e) => setExternalKey(e.value)}
                              selectedValue={externalKey}
                            />
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="input-group-btn">
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              clear(RedisSource.External, externalKey)
                            }
                            disabled={clearing || isEmpty(externalKey)}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-6">
                        <FieldWrapper
                          label="Internal (TKOS, TPOS, INCP)"
                          input={
                            <CustomSelect
                              data={internalGroupKeys.map((x) => ({
                                name: normalizeRedisKey(x),
                                value: x,
                              }))}
                              label="name"
                              value="value"
                              onChange={(e) => setInternalKey(e.value)}
                              selectedValue={internalKey}
                            />
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="input-group-btn">
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              clear(RedisSource.Internal, internalKey)
                            }
                            disabled={clearing || isEmpty(internalKey)}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
