import React from 'react';
import { Card } from '../../../common/Card';
import { DateHelper } from '../../../../common/Helpers';
import { BaseListing } from "../../../common/BaseListing";
import { EVoucherBatchExtensionRequestStatus } from "../../../../common/Constants";

export class EvoucherBatchExtensionRequestCard extends BaseListing {
    render() {     
        const { evoucherBatchExtensionRequest } = this.props;
        
        return evoucherBatchExtensionRequest && 
            <Card title="eVoucher Batch Extension Request Information">
                <div className="row">
                    <div className="col-md-8">
                        <div className="row form-group">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="Status">Status:</label>
                            </div>
                            <div className="col-md-9">
                                <input className={`form-control display-input ${EVoucherBatchExtensionRequestStatus.getColor(evoucherBatchExtensionRequest.status)} `} 
                                       type="text" id="Status" value={evoucherBatchExtensionRequest.status} readOnly />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="NewExpiryDate">New Expiry Date:</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control display-input" type="text"
                                    value={DateHelper.toDisplayDateTimeFormat(evoucherBatchExtensionRequest.newValidityEndDate)} readOnly />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="ExtensionReason">Reason:</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control display-input" type="text"
                                    value={evoucherBatchExtensionRequest.extensionReason} readOnly />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="CreatedBy">Created By:</label>
                            </div>
                            <div className="col-md-9">
                                <input className="form-control display-input" type="text"
                                    value={evoucherBatchExtensionRequest.createdBy} readOnly />
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="CreatedOn">Created On:</label>
                            </div>
                            <div className="col-md-3">
                                <input className="form-control display-input" type="text"
                                    value={DateHelper.toDisplayDateTimeFormat(evoucherBatchExtensionRequest?.createdOn)} readOnly />
                            </div>
                        </div>
                        {(evoucherBatchExtensionRequest.status===EVoucherBatchExtensionRequestStatus.Rejected || evoucherBatchExtensionRequest.status===EVoucherBatchExtensionRequestStatus.Cancelled) 
                        && <div className="row form-group" id="whenRejected">
                            <div className="col-md-3">
                                <label className="form-control-label" htmlFor="ApprovalReason">Reason For Rejection/Cancellation:</label>
                            </div>
                            <div className="col-md-6">
                                <input className="form-control display-input activated used" type="text"
                                    value={evoucherBatchExtensionRequest.approvalReason} readOnly />
                            </div>
                        </div>}
                    </div>
                </div>
            </Card>
    }  
}
