import React, { Component } from "react";
import '../css/documentation.scss';

export class ViewPromotionHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To View Promotion Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 2 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Promotions” link. This will take you to the Promotions Listing Page.  <br />
                                    <img src="/img/documentation-img/PromoImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Promotions Listing Page, there is a search bar for you to filter the promotion listings. Type the search string in the text box and click “Search”. <br />
                                    <img src="/img/documentation-img/PromoImg/list_search.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Promotions Listing Page, click on any promotion listing of your choice. This will take you to the View Promotions Details.<br />
                                    <img src="/img/documentation-img/PromoImg/list_details.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Promotion Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Promotion Code
                                        </li>
                                        <li>
                                            Promotion Name
                                        </li>
                                        <li>
                                            Promotion Category
                                        </li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Promotion Image
                                        </li>
                                        <li>
                                            Promotion Description
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Promotion Description should be in HTML format.
                                </div>
                            </li>



                            </ol>
                            <div className="text-right">
                                <a href="/documentation/promotions/CreatePromotionHelp" className="btn btn-primary">Create Promotion &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}