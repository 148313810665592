import React from "react";
import { Link } from "react-router-dom";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import { StringHelper } from "../../common/Helpers";
import { PageSizeSelector, Paginator } from "../../common/Paginator";
import RestClient from "../../common/RestClient";
import { BreadCrumb } from "../common/BreadCrumb";
import {
  formatStatus,
  formatStatusClassName,
} from "./common/UserMamagementConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";

export class UserListing extends React.Component {
  constructor() {
    super();
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      query: "",
      sort: "-updated_date",
      users: [],
      userCount: 0,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  getSortClassName(column) {
    const { sort } = this.state;
    let classes = ["fas"];
    if (this.isColumnMatchSortBy(column, sort)) {
      classes.push(
        sort.startsWith("-") ? "fa-sort-alpha-down-alt" : "fa-sort-alpha-down"
      );
      classes.push("color-light-blue");
    } else {
      classes.push("fa-sort-alpha-down");
    }
    return classes.join(" ");
  }

  isColumnMatchSortBy(column, sort) {
    const ascColumn = "+" + column;
    const descColumn = "-" + column;
    return column === sort || ascColumn === sort || descColumn === sort;
  }

  onPageIndexChange = (pageIndex) => {
    this.setState({ pageIndex }, this.refreshData);
  };

  onPageSizeChange = (pageSize) => {
    this.setState({ pageSize, pageIndex: 1 }, this.refreshData);
  };

  onRowClick = (id) => {
    this.props.history.push(`/users/${id}`);
  };

  onQueryChange = (e) => {
    const value = e.target.value;
    this.setState({ query: value });
  };

  onQueryKeyPress = (e) => {
    if (e.key === "Enter") {
      this.refreshData();
    }
  };

  onSearchClick = () => {
    this.refreshData();
  };

  onTableHeaderClick = (column) => {
    const { sort } = this.state;
    let newSort = `+${column}`;
    if (this.isColumnMatchSortBy(column, sort)) {
      newSort = sort.startsWith("-") ? "" : `-${column}`;
    }
    this.setState({ sort: newSort }, this.refreshData);
  };

  refreshData = () => {
    const { pageIndex, pageSize, query, sort } = this.state;
    let request = {
      maxResults: pageSize,
      query,
      sort,
      startAt: (pageIndex - 1) * pageSize,
    };
    if (!AuthenticationManager.isAuthorized(ResourceID.AccessAllDepartments)) {
      request.departmentId = AuthenticationManager.departmentId();
    }
    this.searchUser(request);
  };

  searchUser(request) {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/users",
      request,
      (response) => {
        this.setState({
          users: response.data,
          userCount: response.total,
        });
      },
      (errorResponse) => {
        console.error(errorResponse);
      }
    );
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "user-managements",
        name: "User Management",
      }),
      activeModule: new NavigationItem({
        identifier: "users",
        name: "Users",
      }),
    });
  };

  render() {
    const { pageIndex, pageSize, query, users, userCount } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col-md-6 col-xl-4 input-group ml-auto mb-1">
                <input
                  type="text"
                  id="input1-group2"
                  name="input1-group2"
                  placeholder="Search"
                  className="form-control"
                  onChange={this.onQueryChange}
                  onKeyPress={this.onQueryKeyPress}
                  value={query}
                />
                <div className="input-group-btn">
                  <button
                    className="btn btn-primary"
                    onClick={this.onSearchClick}
                  >
                    <i className="fa fa-search"></i> Search
                  </button>
                </div>
              </div>
              <div className="d-inline-flex mb-1 col-md-4 col-xl-3 width-fit">
                {AuthenticationManager.isAuthorized(
                  ResourceID.CreateNewUserButtonPage
                ) && (
                  <Link
                    className="btn btn-primary flex-fill"
                    to="/users/register"
                    style={{ fontSize: "1rem" }}
                  >
                    <i className="fa fa-plus-circle"></i> Create New User
                  </Link>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="table-responsive m-b-40 table-px-15">
                  <table className="table table-borderless table-striped table-earning">
                    <thead>
                      <tr>
                        <th
                          className="clickable"
                          onClick={() => this.onTableHeaderClick("name")}
                        >
                          Full Name{" "}
                          <i className={this.getSortClassName("name")}></i>
                        </th>
                        <th
                          className="clickable"
                          onClick={() => this.onTableHeaderClick("login")}
                        >
                          Login Id{" "}
                          <i className={this.getSortClassName("login")}></i>
                        </th>
                        <th
                          className="clickable"
                          onClick={() =>
                            this.onTableHeaderClick("updated_date")
                          }
                        >
                          Last Modified Date{" "}
                          <i
                            className={this.getSortClassName("updated_date")}
                          ></i>
                        </th>
                        <th
                          className="clickable"
                          onClick={() => this.onTableHeaderClick("status")}
                        >
                          Status{" "}
                          <i className={this.getSortClassName("status")}></i>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr
                          key={user.id}
                          onClick={() => this.onRowClick(user.id)}
                        >
                          <td>
                            {user.firstName} {user.middleName} {user.lastName}
                          </td>
                          <td>{user.username}</td>
                          <td>
                            {StringHelper.asDateStringToDisplayDateFormat(
                              user.updatedOn
                            )}
                          </td>
                          <td>
                            <span
                              className={formatStatusClassName(user.userStatus)}
                            >
                              {formatStatus(user.userStatus)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-5" aria-label="Page nav">
                <Paginator
                  onPageIndexChange={this.onPageIndexChange}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  total={userCount}
                />
              </div>
              <div className="ml-auto mr-0 col-7 col-md-3">
                <PageSizeSelector
                  onPageSizeChange={this.onPageSizeChange}
                  pageSize={pageSize}
                  selectors={[10, 25, 50, 100]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
