import React from "react";
import { PassStatus } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  formatPassProgramType,
  formatPassStatus,
  formatPassStatusClassName,
} from "../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import PassListFilterLayout from "../common/PassListFilterLayout";

export class PassProgramListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      programTypeOptions: [],
      passProgramId: 0,
      status: "",
    };
  }

  async componentDidMount() {
    await super.componentDidMount();
    await this.loadProgramTypeOptions();
  }

  async loadProgramTypeOptions() {
    RestClient.sendGetRequest("/api/v1/pass_programs/options", (response) => {
      this.setState({ programTypeOptions: response });
    });
  }

  getApiPath = () => "/api/v1/pass_programs";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "pass_programs",
        name: "Pass Programs",
      }),
    });
  };

  injectSearchTerm(queryParameters) {
    const { name, passProgramId, status } = this.state;
    let customParameters = {};
    if (!StringHelper.isNullOrEmpty(name)) {
      customParameters.name = `${name}`;
    }
    if (passProgramId > 0) {
      customParameters.passProgramId = passProgramId;
    }
    if (!StringHelper.isNullOrEmpty(status) && status !== "0") {
      customParameters.status = StringHelper.toNumber(status, 0);
    }
    return super.injectSearchTerm(queryParameters, customParameters);
  }

  onRowClick = (id) => {
    this.props.history.push(`/pass_programs/${id}`);
  };

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableFilter() {
    const { name, passProgramId, programTypeOptions, status } = this.state;
    return (
      <React.Fragment>
        <div className="row mb-3">
          <PassListFilterLayout
            label="Status:"
            htmlFor="Status"
            className="col-md-3"
          >
            <select
              className="form-control"
              name="status"
              id="Status"
              required
              onChange={this.onTextBoxChange}
              value={status}
            >
              <option value="">All Status</option>
              <option value={PassStatus.Active}>Active</option>
              <option value={PassStatus.Inactive}>Deactivated</option>
              <option value={PassStatus.Expired}>Expired</option>
            </select>
          </PassListFilterLayout>
          <PassListFilterLayout
            label="Program Type:"
            htmlFor="Type"
            className="col-md-5"
          >
            <select
              className="form-control"
              name="passProgramId"
              id="Type"
              required
              onChange={this.onTextBoxChange}
              value={passProgramId}
            >
              <option value="">All Type</option>
              {programTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </PassListFilterLayout>
          <PassListFilterLayout.Search>
            <input
              type="text"
              id="input1-group2"
              name="name"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onTextBoxChange}
              value={name}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </PassListFilterLayout.Search>
        </div>
      </React.Fragment>
    );
  }

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Program Code</th>
            <th>Program Name</th>
            <th>
              Program Type{" "}
              <i
                className={`sort-button fas fa-sort-alpha-down`}
                id="Type"
                onClick={this.toggleSort}
              />
            </th>
            <th>Start Date</th>
            <th>
              Expiry Date{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ExpiryDate"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item) => {
                return (
                  <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{formatPassProgramType(item.type)}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateFormat(
                        item.startDate
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateFormat(
                        item.expiryDate
                      )}
                    </td>
                    <td>
                      <span className={formatPassStatusClassName(item.status)}>
                        {formatPassStatus(item.status)}
                      </span>
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.updatedOn
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </React.Fragment>
    );
  }
}
