import React from "react";

const PassListFilterLayout = (props) => {
  return (
    <div className="col-12 col-md-6 col-xl-4 row mb-2 mx-0 align-items-center">
      <label className={`form-control-label col-6 px-0 ${props.className || ''}`} htmlFor={props.htmlFor}>
        {props.label}
      </label>
      <div className="col pr-0">{props.children}</div>
    </div>
  );
};

const Search = (props) => {
	return (
		<div className="col-12 col-md-6 col-xl-4 row mx-0 mb-2 input-group ml-auto">
			{props.children}
		</div>
	);
};

PassListFilterLayout.Search = Search;

export default PassListFilterLayout;