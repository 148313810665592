import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import { DateHelper } from "../../common/Helpers";
import { BaseListing } from "../common/BaseListing";
import { Link } from "react-router-dom";
import { CampaignStatus } from "../../constants/CampaignConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { CustomSelect } from "../common/CustomSelect";
import { ColumnFormField, RowFormField } from "../layout/FormLayout";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";

export class CampaignListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
      status: 0,
    };
  }

  injectSearchTerm(queryParameters) {
    const name = this.state.name;
    const status = this.state.status;
    return super.injectSearchTerm(queryParameters, {
      name,
      status,
    });
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "campaigns",
        name: "Campaigns",
      }),
      activeModule: new NavigationItem({
        identifier: "campaigns",
        name: "Campaigns",
      }),
    });
  };

  getParentModuleName = () => "Campaign";

  getModuleName = () => "Campaign";

  getApiPath = () => "/api/v1/campaigns";

  getDefaultSort = () => "-UpdatedOn";

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onRowClick = (id) => {
    this.props.history.push(`/campaigns/${id}`);
  };

  onSelectStatus = (e) => {
    this.setState({ status: e.value });
  };

  generateTableFilter() {
    const { status } = this.state;
    const statuses = CampaignStatus.All.filter(
      (x) =>
        x.value !== CampaignStatus.FullyRedeemed &&
        x.value !== CampaignStatus.NotAvailable
    );
    statuses.push({ name: "Select status", value: 0 });
    return (
      <div className="row mb-3">
        <div className="col-md-6">
          <RowFormField label="Status:" htmlFor="OrderStatus">
            <CustomSelect
              selectedValue={status}
              data={statuses}
              label="name"
              value="value"
              compareFunc={(x, y) => x.value - y.value}
              onChange={this.onSelectStatus}
            />
          </RowFormField>
        </div>
        <div className="col-md-6">
          <div className="input-group ml-auto">
            <input
              type="text"
              name="name"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Campaign Name</th>
            <th>Status</th>
            <th onClick={this.onColumnClick}>
              Created On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td className={CampaignStatus.getColor(item.status)}>
                      {CampaignStatus.getName(item.status)}
                    </td>
                    <td>
                      {item.createdOn &&
                        DateHelper.toDisplayDateTimeFormat(item.createdOn)}
                    </td>
                    <td>
                      {item.updatedOn &&
                        DateHelper.toDisplayDateTimeFormat(item.updatedOn)}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(ResourceID.CampaignsCreation) && (
        <>
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item className="btn btn-primary">
              <Link
                className="text-white d-block"
                to="/campaigns/create"
                style={{ fontSize: "1rem" }}
              >
                <i className="fas fa-plus-circle"></i> Create Campaign
              </Link>
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </>
      )
    );
  }
}
