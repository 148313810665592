export class DocketExportModel {
  constructor(data) {
    this.dailyDocketCompanyAccountCode =
      data?.dailyDocketCompanyAccountCode ?? "";
    this.locationVenueCode = data?.locationVenueCode ?? "";
    this.performanceType = data?.performanceType ?? "";
    this.movieReleaseId = data?.movieReleaseId?.toString() ?? "0";
    this.primaryTitle = data?.primaryTitle ?? "";
    this.grossAmount = data?.grossAmount?.toString() ?? "0";
    this.netAmount = data?.netAmount?.toString() ?? "0";
    this.taxAmount = data?.taxAmount?.toString() ?? "0";
    this.numOfSoldSeats = data?.numOfSoldSeats ?? "0";
  }
}