import { QuantityMechanicsType } from "../constants/CampaignConstants";

export class CampaignAvailability {
  constructor(data) {
    this.id = data.id ?? 0;
    this.quantityMechanicsType =
      data.quantityMechanicsType ?? QuantityMechanicsType.NoSpecificMechanics;
    this.locationIds = data.locationIds ?? [];
  }
}

export class CampaignSalesChannel {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignId = data.campaignId ?? 0;
    this.appId = data.appId ?? 0;
  }
}

export class CampaignRedemptionLocation {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignId = data.campaignId ?? 0;
    this.locationId = data.locationId ?? 0;
  }
}

export class CampaignQuantityAvailabilityItem {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignId = data.campaignId ?? 0;
    this.locationId = data.locationId ?? 0;
    this.appId = data.appId ?? 0;
    this.quantity = data.quantity ?? 0;
  }
}
