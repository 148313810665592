export class CardMode {
    static View = 0;
    static Register = 1;
    static Update = 2;

    static isEditMode(mode) {
        return mode === CardMode.Register || mode === CardMode.Update;
    }

    static isViewMode(mode) {
        return mode === CardMode.View;
    }
}
