import React, { Component } from "react";
import { Strings } from "../../../common/Common";
import QRCode from "qrcode.react";

export class TicketView extends Component {
  render() {
    let transaction = this.props.transaction;
    let info = this.props.information;
    if (!info || !info.movieInfos || !transaction) return null;
    return info.movieInfos.map((movieInfo) => {
      return (
        <div id={movieInfo.id} className="modal fade" key={movieInfo.id}>
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  View Ticket
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  data-bs-target={`#${movieInfo.id}`}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid overflow-x">
                  {movieInfo.tickets.map((item, index) => {
                    return (
                      <div
                        className="card w-100 mb-4 preview-slipbg coupon-preview"
                        style={{ marginTop: "20px" }}
                        key={index}
                      >
                        <div className="card-body pb-0">
                          <h5 className="card-title">
                            {item.movieName}
                            <br />
                            <small>({item.movieClassify})</small>
                          </h5>
                          <div className="row">
                            <p style={{ fontSize: "22px" }} className="pl-3">
                              {item.notice}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-6 pr-0">
                              <small className="float-left preview-rotatetext pb-2">
                                DATE
                              </small>
                              <h2 className="mb-0" style={{ fontSize: "26px" }}>
                                {item.showDate}
                              </h2>
                            </div>
                            <div className="col-4 pr-0 pl-0">
                              <small className="float-left preview-rotatetext pb-2">
                                TIME
                              </small>
                              <h2 className="mb-0" style={{ fontSize: "26px" }}>
                                {item.showTime}
                              </h2>
                            </div>

                            <div className="col-2 pr-0 pl-0">
                              <small className="mb-0 float-left preview-rotatetext pb-2">
                                SEAT
                              </small>
                              <h2 className="mb-0" style={{ fontSize: "26px" }}>
                                {item.seatList}
                              </h2>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-4 pl-md-0">
                              {Strings.isNullOrEmpty(
                                transaction.referenceNumber
                              ) ? null : (
                                <QRCode value={transaction.referenceNumber} />
                              )}
                            </div>
                            <div className="col-8">
                              <div className="row pt-1">
                                <div className="col-10">
                                  <h4>{item.hallName}</h4>
                                </div>
                              </div>
                              <div className="row">
                                {transaction.paymentType}
                              </div>
                              <div className="row">
                                <small id="prtLblDetail">
                                  {movieInfo.campaign
                                    ? movieInfo.campaign.promotionName
                                    : ""}
                                </small>
                              </div>
                              <div className="row">
                                <small>{item.ticketType}</small>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <small>SHAW THEATRES PTE LTD</small>
                            </div>
                            <div className="col-6">
                              <small className="float-right">
                                Co. Reg. No: 194700158G
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <small>{transaction.createdOn}</small>
                            </div>
                            <div className="col-6">
                              <small className="float-right">
                                {transaction.createdBy}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <small>{transaction.referenceNumber}</small>
                            </div>
                            <div className="col-6">
                              <small className="float-right">
                                {item.printCollectionNumber}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }
}