import { BlobStorageRestService } from "../services/BlobStorageRestService";
import { Base64String } from "../common/Common";
import { toast } from "react-toastify";

export const useUpload = () => {
  const uploadFile = (sourceFile, fileName, storageModule) => {
    return Base64String.getBase64String(sourceFile)
      .then((base64String) => {
        return new BlobStorageRestService().uploadFile(
          Base64String.getBase64StringFromDataURL(base64String),
          fileName,
          storageModule);
      })
      .catch((err) => {
        toast.error(`Failed to upload file ${sourceFile}. ${err}`);
      });
  };

  const deleteFile = (fileName, storageModule) => {
    return new BlobStorageRestService().deleteFile({
      fileName,
      storageModule,
    });
  };

  return { uploadFile, deleteFile };
};
