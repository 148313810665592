import {
  CouponType,
  PaymentOption,
} from "../../../../constants/CorporateBookingConstants";

export class CorporateBookingModel {
  constructor(data) {
    this.corporateBookingDetails = new CorporateBookingDetails(data);
    this.couponDetails = new CorporateBookingCouponDetails(data);
    this.ticketDetails = new CorporateBookingTicketDetails(data);
    this.performanceId = data?.performanceId ?? 0;
    this.corporateBookingCompanyId = data?.corporateBookingCompanyId ?? 0;
    this.status = data?.status ?? 0;
    this.bookingReference = data?.bookingReference ?? "";
    this.campaignId = data?.campaignId ?? 0;
    this.campaignBundleId = data?.campaignBundleId ?? 0;
    this.id = data?.id ?? 0;
  }
}

export class CorporateBookingDetails {
  constructor(data) {
    this.type = data?.type ?? 0;
    this.ticketDistribution = data?.ticketDistribution ?? 0;
    this.ticketStatus = data?.ticketStatus ?? 0;
    this.salesAmount = data?.salesAmount ?? 0;
    this.paymentOption = data?.paymentOption ?? PaymentOption.None;
    this.corporateBookingServiceCode = data?.corporateBookingServiceCode ?? "";
    this.seatCodes = data?.seatCodes ?? [];
    this.guestCount = data?.guestCount ?? 0;
    this.disclaimer = data?.disclaimer ?? "";
    this.requestedBy = data?.requestedBy ?? "";
  }
}

export class CorporateBookingCouponDetails {
  constructor(data) {
    this.couponName = data?.couponName ?? "";
    this.couponDetails = data?.couponDetails ?? "";
    this.hasCoupon = data?.hasCoupon ?? false;
    this.couponType = data?.couponType ?? CouponType.Custom;
  }
}

export class CorporateBookingTicketDetails {
  constructor(data) {
    this.moviePrimaryTitle = data?.moviePrimaryTitle ?? "";
    this.movieRating = data?.movieRating ?? "";
    this.ticketDetails = data?.ticketDetails ?? "";
    this.ticketType = data?.ticketType ?? "";
    this.glassesQuantity = data?.glassesQuantity ?? 0;
    this.has3dGlasses = data?.has3dGlasses ?? false;
    this.hasPrintPrice = data?.hasPrintPrice ?? false;
    this.hasPrintSeatNumber = data?.hasPrintSeatNumber ?? false;
  }
}