import { RestService } from "./RestService";

export class EVoucherBatchRestService extends RestService {
  constructor() {
    super("api/v1/evoucher_batches");
  }

  download(batchId, password) {
    return this.postImpl(`${batchId}/download`, { password });
  }
}
