import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateCancelOrder extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create/Cancel eVoucher Order</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    Click on eVouchers Orders link from the left navbar. <br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/eVoucherOrders.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In eVouchers Orders Listing Page, there is an action button ‘Create eVoucher Order’ found at the top right of the page. Click the button to start creating the eVoucher order.   <br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/eVoucherOrderList.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    You will be redirected to the Create eVoucher order page. After filling in the relevant details, you can see 2 action buttons: <br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/CreateeVoucherOrder.png" class="userImage" /><br /><br />
                                    <ul class="unorderedList">
                                        <li>
                                            Create – Performs the creation of the eVoucher order and you will see a prompt. To create a new eVoucher order, click this button and the following popup will appear for confirmation.<br />
                                            <img src="/img/documentation-img/evouchers/eVoucherOrder/createeVoucherPrompt.png" class="userImage" /><br /><br />
                                        </li>
                                        <li>
                                            Clear – Clears all data in selected fields.<br />
                                        </li>
                                    </ul>
                                </li>
                                <li class="createList">
                                    Upon successful creation of the eVoucher order, the newly created eVoucher will appear in the listing page together with the prompt at the lower right portion of the page.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/eVoucherOrderToast.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    To cancel the eVoucher orders, click on an ‘Active’ eVoucher order record. It will redirect you to the eVoucher Orders Details page.
                                </li>

                                <li class="createList">
                                    Click on ‘Cancel Order’ at the bottom right of the page to cancel the order.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/CancelEVoucher.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    After clicking the button, you will be greeted with this confirmation prompt. Fill in the reason and then click ‘Confirm’ button to cancel the order.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/CancelEVoucherPrompt.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    After cancelling the order, the following prompt will appear. The new status should show on the eVoucher listing page.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/savePrompt.png" class="userImage" /><br /><br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/afterCancel.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/createEvoucherBatch" className="btn btn-primary">Create EVoucher Batch &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
