import _ from "lodash"
import {StringHelper} from "../../../../common/Helpers";

export class EmailRecipient {
    constructor(data) {
        this.id = data?.id ?? 0;
        this.patronName = data?.patronName ?? '';
        this.emailAddress = data?.emailAddress ?? '';
        this.phoneNumber = data?.phoneNumber ?? '';
        this.seatCodes = data?.seatCodes ?? [];
        this.sentCount = data?.sentCount ?? 0;
        this.sentOn = data?.sentOn ?? '';
        this.isSending = false;
    }
    
    isValid() {
        return !_.isEmpty(this.patronName) && StringHelper.isEmail(this.emailAddress) && !_.isEmpty(this.seatCodes);
    }
}