import React, { Component } from "react";
import '../css/documentation.scss';

export class ExtendDownloadEvoucherBatch extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Extend Validity of eVoucher Batch/Download eVoucher Batch Codes</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 4 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    Navigate to the eVoucher Batch listing page by clicking the link in the left navbar.<br />
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/eVoucherBatch.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    To extend the validity of an existing eVoucher batch, click on an eVoucher batch record with the ‘Approved’ status.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/eVoucherBatchApproveListing.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    After selecting an appropriate record, user will be redirected to eVoucher Batch Details page where the action button ‘Extend Validity’ will be shown in the top right corner of the page.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/extendvalidbatch.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    Clicking on the ‘Extend Validity’ button will display the following confirmation prompt. To extend validity, enter the new expiry date and the reason before clicking ‘Confirm’.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/extensionprompt.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    After that, user has to click on the eVouchers extension request submodule for further actions. Click on the eVouchers Extensions link in the left navbar. This will direct you to the eVouchers Extensions listing page.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/evoucherExtensions.png" class="userImage" /><br /><br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/evouchersBatchExtendListing.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    Click on any ‘Pending’ batch extension request in the listing page and you will be redirected to eVoucher Batch Extension Request Details page with 3 action buttons displayed at the bottom right of the page.
                                    <ul class="unorderedList">
                                        <li><strong>Approve</strong> – Approves extension request</li>
                                        <li><strong>Reject</strong> – Rejects extension request</li>
                                        <li><strong>Cancel</strong> – Cancels extension request</li>
                                    </ul>
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/evoucherbatchextenddetails.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To approve the extension request, click the ‘Approve’ button, which will display the following confirmation prompt. Click ‘Confirm’ to approve the request.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/confirmapproval.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To reject the extension request, click the ‘Reject’ button, which will display the following confirmation prompt. Fill in the ‘Reason’ field and click ‘Confirm’ to reject the request.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/rejectprompt.png" class="userImage" /><br /><br />
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            If no reason is given, you will see the following message displayed.<br/>
                                                <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/reasonToaster.png" class="userImage"/><br/>
                                </div>
                            </li>
                                                <li class="createList">
                                                    To cancel the extension request, click the ‘Cancel’ button, which will display the following confirmation prompt. Fill in the Reason field and click ‘Confirm’ to cancel the request. Similarly, if no reason is given, the above message will be thrown.<br />
                                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/cancelprompt.png" class="userImage" />

                                                </li>
                                                <li class="createList">
                                                    Upon performing any of the above actions, the status will be changed accordingly.<br />
                                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/eVoucherBatchListing.png" class="userImage" /><br /><br />
                                                </li>
                                                <li class="createList">
                                                    To download eVoucher Batch Codes, click on an eVoucher batch record with the ‘Approved’ status after selecting eVouchersBatch link from the left navbar.<br />
                                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/eVoucherBatch.png" class="userImage" /><br /><br />
                                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/eVoucherBatchApproveListing.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    After selecting an appropriate record, user will be redirected to eVoucher Batch Details page where the action button ‘Download eVoucher Batch Codes will be shown in the top right.
                                                </li>
                                                <li class="createList">
                                                    This will download the eVoucher Batch Codes in an Excel Sheet.<br />
                                                    <img src="/img/documentation-img/evouchers/ExtendDownloadBatch/downloadbatchcodes.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    The file downloaded will be an excel file and a prompt indicating that a download has started will be displayed.<br />
                                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/approveCancelRejectBatch" className="btn btn-primary">&laquo; Approve/Cancel/Reject Batch</a>&nbsp;
                                <a href="/documentation/evouchers/extendEvoucherValidity" className="btn btn-primary">Extend EVoucher Validity &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
