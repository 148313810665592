import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BreadCrumb } from "../../common/BreadCrumb";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { Movie } from "../../../models/Movie";
import { MovieRelease } from "../../../models/MovieRelease";
import {
  Classify,
  Country,
  MovieCategory,
  MovieFormat,
  MovieOrigin,
  MovieSubtitle,
  Restriction,
} from "../../../constants/MovieConstants";
import { MovieService } from "../../../services/MovieService";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";
import { AddRestrictionModal } from "../modals/AddRestrictionModal";
import { MovieRestrictionService } from "../../../services/MovieRestrictionService";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ColumnFormField, RowFormField } from "../../layout/FormLayout";
import MovieReleaseLayoutAttribute from "./MovieReleaseLayoutAttribute";
import { Mode } from "../../../constants/Mode";

export class UpdateMovieReleaseRestrictions extends React.Component {
  constructor() {
    super();
    this.state = {
      movie: new Movie(),
      instance: new MovieRelease(),
      showAddRestriction: false,
      showCancelConfirmation: false,
    };
    this.movieReleaseService = new MovieReleaseService();
    this.movieService = new MovieService();
    this.movieRestrictionService = new MovieRestrictionService();
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.movieReleaseService
      .get(id, { includes: "MovieReleaseRestrictions" })
      .then((response) => {
        const instance = new MovieRelease(response.data);

        this.movieService.get(
          instance.movieId,
          { includes: "Company" },
          (movieResult) => {
            this.setState({ instance, movie: new Movie(movieResult) });
          },
          this.handleError
        );
      });
  }

  handleError = (error) => {
    toast.error(error.message);
  };

  onSaveChanges = () => {
    const { instance } = this.state;
    const errorMsg = instance.validate();
    if (errorMsg) {
      toast.error(errorMsg);
      return;
    }
    this.movieReleaseService.put(instance.id, instance).then((result) => {
      instance.handleResponse(result);
    });
  };

  onCancelChanges = () => {};

  renderConsumerAdvice = () => {
    const { instance } = this.state;
    const hasAdvice =
      !_.isEmpty(instance.adviceName) || !_.isEmpty(instance.adviceOnTicket);
    return (
      <MovieReleaseLayoutAttribute header={{ title: "Consumer Advice" }}>
        {hasAdvice && (
          <MovieReleaseLayoutAttribute.Body>
            <RowFormField label="Consumer Advice:" className="mb-0 py-2">
              <input
                className="form-control form-readonly"
                value={instance.adviceName}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Advice On Ticket:" className="mb-0 py-2">
              <input
                className="form-control form-readonly"
                value={instance.adviceOnTicket}
                readOnly
              />
            </RowFormField>
          </MovieReleaseLayoutAttribute.Body>
        )}
      </MovieReleaseLayoutAttribute>
    );
  };

  onAddRestriction = (restrictionData) => {
    const { instance } = this.state;
    const found = instance.movieReleaseRestrictions.find(
      (x) =>
        x.startOn === restrictionData.startOn &&
        x.endOn === restrictionData.endOn &&
        x.type === restrictionData.type
    );
    if (!_.isNil(found)) {
      return;
    }
    restrictionData.movieReleaseId = instance.id;
    this.movieRestrictionService.create(restrictionData, (result) => {
      restrictionData.id = result.id;
      instance.movieReleaseRestrictions.push(restrictionData);
      this.setState({ instance });
      toast.success("The movie restriction has been created successful.");
    });
  };

  onRemoveRestriction = (restrictionData) => {
    const { instance } = this.state;
    this.movieRestrictionService.delete(
      restrictionData,
      (_) => {
        instance.movieReleaseRestrictions =
          instance.movieReleaseRestrictions.filter(
            (x) =>
              x.startOn !== restrictionData.startOn &&
              x.endOn !== restrictionData.endOn &&
              x.type !== restrictionData.type
          );
        this.setState({ instance });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  renderMovieRestrictions = () => {
    const { instance } = this.state;
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.MovieReleaseUpdateRestrictionButtonPage
      ) && (
        <MovieReleaseLayoutAttribute
          header={{
            title: "Movie Restriction",
            onClick: () => this.setState({ showAddRestriction: true }),
          }}
          mode={Mode.Editable}
        >
          {instance.movieReleaseRestrictions.map((x, index) => {
            return (
              <MovieReleaseLayoutAttribute.Body
                key={index}
                mode={Mode.Editable}
                onDelete={() => this.onRemoveRestriction(x)}
              >
                <RowFormField label="Restriction:" className="mb-0 py-2">
                  <input
                    className="form-control form-readonly"
                    value={Restriction.getName(x.type)}
                    readOnly
                  />
                </RowFormField>
                <RowFormField label="Start On:" className="mb-0 py-2">
                  <input
                    className="form-control form-readonly"
                    value={DateHelper.toDisplayDateFormat(x.startOn)}
                    readOnly
                  />
                </RowFormField>
                <RowFormField label="End On:" className="mb-0 py-2">
                  <input
                    className="form-control form-readonly"
                    value={DateHelper.toDisplayDateFormat(x.endOn)}
                    readOnly
                  />
                </RowFormField>
              </MovieReleaseLayoutAttribute.Body>
            );
          })}
        </MovieReleaseLayoutAttribute>
      )
    );
  };

  renderMovieLanguage = () => {
    const { instance } = this.state;
    const languages = (instance.language ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Language",
        }}
      >
        {languages.map((x, index) => {
          return (
            <MovieReleaseLayoutAttribute.Body key={index}>
              <RowFormField label="Language:" className="mb-0 py-2">
                <input
                  className="form-control form-readonly"
                  value={x}
                  readOnly
                />
              </RowFormField>
            </MovieReleaseLayoutAttribute.Body>
          );
        })}
      </MovieReleaseLayoutAttribute>
    );
  };

  renderMovieSubtitle() {
    const { instance } = this.state;
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Subtitle",
        }}
      >
        {instance.subtitle > 0 && (
          <MovieReleaseLayoutAttribute.Body>
            <RowFormField label="Subtitle:" className="mb-0 py-2">
              <input
                className="form-control form-readonly"
                value={MovieSubtitle.getName(instance.subtitle)}
                readOnly
              />
            </RowFormField>
          </MovieReleaseLayoutAttribute.Body>
        )}
      </MovieReleaseLayoutAttribute>
    );
  }

  renderMovieGenre = () => {
    const { instance } = this.state;
    const genres = (instance.genre ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Genre",
        }}
      >
        {genres.map((x, index) => {
          return (
            <MovieReleaseLayoutAttribute.Body key={index}>
              <RowFormField label="Genre:" className="mb-0 py-2">
                <input
                  className="form-control form-readonly"
                  value={x}
                  readOnly
                />
              </RowFormField>
            </MovieReleaseLayoutAttribute.Body>
          );
        })}
      </MovieReleaseLayoutAttribute>
    );
  };

  renderMovieSection = () => {
    const { movie } = this.state;
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              Movie Information
              <i
                className="fas fa-chevron-down float-right zoom-1_5 info"
                style={{ display: "none" }}
                aria-hidden="true"
              />
              <i
                className="fas fa-chevron-up float-right zoom-1_5 info"
                aria-hidden="true"
              />
            </div>
            <div className="card-body info">
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Movie Id:</label>
                  <input
                    className="form-control display-input"
                    placeholder="Enter Movie Id"
                    value={movie.id}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">Primary Title:</label>
                  <input
                    className="form-control display-input"
                    placeholder="Enter Primary Title"
                    value={movie.primaryTitle}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Secondary Title:</label>
                  <input
                    className="form-control display-input"
                    placeholder="Enter Secondary Title"
                    value={movie.secondaryTitle}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">Movie Category:</label>
                  <input
                    className="form-control display-input"
                    value={MovieCategory.getName(movie.movieCategory)}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Duration:</label>
                  <input
                    className="form-control display-input"
                    type="number"
                    min="1"
                    max="300"
                    value={movie.duration}
                    placeholder="Enter Duration"
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">
                    Movie Origin Country:
                  </label>
                  <input
                    className="form-control display-input"
                    value={Country.getName(movie.movieOriginCountry)}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Movie Origin:</label>
                  <input
                    className="form-control display-input"
                    value={MovieOrigin.getName(movie.movieOrigin)}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">
                    Movie Distribution Company:
                  </label>
                  <input
                    className="form-control display-input"
                    value={movie.company.name}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Label Display:</label>
                  <input
                    className="form-control display-input"
                    value={movie.labelDisplay}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">Label Print:</label>
                  <input
                    className="form-control display-input"
                    value={movie.labelPrint}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Movie Language:</label>
                  <input
                    className="form-control display-input"
                    value={movie.language}
                    readOnly={true}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-control-label">Display Weight:</label>
                  <input
                    className="form-control display-input"
                    type="number"
                    min="1"
                    max="100"
                    value={movie.displayWeight}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-6">
                  <label className="form-control-label">Website:</label>
                  <input
                    className="form-control display-input"
                    value={movie.websiteUrl}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="form-control-label">Short synopsis:</label>
                  <textarea
                    className="form-control col display-input resize-none"
                    rows="4"
                    value={movie.shortSynopsis}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="form-control-label">Full synopsis:</label>
                  <textarea
                    className="form-control col display-input resize-none"
                    rows="6"
                    value={movie.fullSynopsis}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderMovieReleaseReadonlySection = () => {
    const { instance } = this.state;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              Movie Release
              <i
                className="fas fa-chevron-down float-right zoom-1_5 release"
                style={{ display: "none" }}
                aria-hidden="true"
              />
              <i
                className="fas fa-chevron-up float-right zoom-1_5 release"
                aria-hidden="true"
              />
            </div>
            <div className="card-body release">
              <div id="movieReleaseDv">
                <div className="card mb-0">
                  <div className="card-header">
                    {`${MovieFormat.getName(
                      instance.format
                    )} | ${Classify.getName(instance.classify)}`}
                    <i
                      className="fas fa-chevron-down float-right zoom-1_5 film01"
                      style={{ display: "none" }}
                      aria-hidden="true"
                    />
                    <i
                      className="fas fa-chevron-up float-right zoom-1_5 film01"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="card-body bg-light film01">
                    <div className="card card-body form-group">
                      <div className="row form-group">
                        <ColumnFormField label="Id:">
                          <input
                            className="form-control form-readonly"
                            value={instance.id}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Movie Code:">
                          <input
                            className="form-control form-readonly"
                            value={instance.releaseExternalCode}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField
                          label={
                            <span>
                              Film Code:<span className="color-red">*</span>
                            </span>
                          }
                        >
                          <input
                            className="form-control form-readonly"
                            value={instance.eventReleaseCode}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Movie Format:">
                          <input
                            className="form-control form-readonly"
                            value={MovieFormat.getName(instance.format)}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Display Weight:">
                          <input
                            className="form-control form-readonly"
                            value={instance.displayWeight}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Classification:">
                          <input
                            className="form-control form-readonly"
                            value={Classify.getName(instance.classify)}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Label Display:">
                          <input
                            className="form-control form-readonly"
                            value={instance.labelDisplay}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Release Date:">
                          <input
                            className="form-control form-readonly"
                            value={DateHelper.toDisplayDateFormat(
                              instance.releaseDate
                            )}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Label Print:">
                          <input
                            className="form-control form-readonly"
                            value={instance.labelPrint}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Release date confirmed?">
                          <input
                            className="form-control form-readonly"
                            value={
                              instance.isReleaseDateConfirmed ? "Yes" : "No"
                            }
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Shaw Exclusive?">
                          <input
                            className="form-control form-readonly"
                            value={instance.isShawExclusive ? "Yes" : "No"}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Movie Release Country:">
                          <input
                            className="form-control form-readonly"
                            value={Country.getName(
                              instance.movieReleaseCountry
                            )}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Visible to public?">
                          <input
                            className="form-control form-readonly"
                            value={instance.isPublic ? "Yes" : "No"}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                      <div className="row form-group">
                        <ColumnFormField label="Display Start On:">
                          <input
                            className="form-control form-readonly"
                            value={DateHelper.formatDateTimeString(
                              instance.displayStartOn
                            )}
                            readOnly
                          />
                        </ColumnFormField>
                        <ColumnFormField label="Display End On:-md-6">
                          <input
                            className="form-control form-readonly"
                            value={DateHelper.formatDateTimeString(
                              instance.displayEndOn
                            )}
                            readOnly
                          />
                        </ColumnFormField>
                      </div>
                    </div>
                  </div>
                  <div className="card-body bg-light">
                    <div className="row">
                      <div className="col-xl-6">
                        {this.renderConsumerAdvice()}
                        {this.renderMovieRestrictions()}
                        {this.renderMovieGenre()}
                      </div>
                      <div className="col-xl-6">
                        {this.renderMovieLanguage()}
                        {this.renderMovieSubtitle()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-release-details",
        name: "Edit Movie Release",
      }),
    });
  };

  render() {
    const { showAddRestriction, showCancelConfirmation } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.renderMovieSection()}
              {this.renderMovieReleaseReadonlySection()}
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-danger"
                  onClick={() =>
                    this.setState({ showCancelConfirmation: true })
                  }
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <AddRestrictionModal
          visible={showAddRestriction}
          onCancel={() => this.setState({ showAddRestriction: false })}
          onProceed={(restriction) => this.onAddRestriction(restriction)}
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          title="Cancel Update Restrictions"
          message="Are you sure you want to cancel?"
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          onProceed={() => {
            this.setState({ showCancelConfirmation: false });
            this.props.history.push(
              `/movies/movie_releases/${this.props.match.params.id}`
            );
          }}
        />
      </>
    );
  }
}
