import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import {
  AuthenticationManager,
  ResourceID,
} from "./common/AuthenticationManager";
import { Login } from "./components/auth/Login";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import { PassExtend } from "./components/pass.managements/extend/PassExtend";
import { PassCreate } from "./components/pass.managements/passes/PassCreate";
import { PassDetail } from "./components/pass.managements/passes/PassDetail";
import { PassListing } from "./components/pass.managements/passes/PassListing";
import { PassProgramDetail } from "./components/pass.managements/pass.program/PassProgramDetail";
import { PassProgramListing } from "./components/pass.managements/pass.program/PassProgramListing";
import { StaffPassCreate } from "./components/pass.managements/staff.passes/StaffPassCreate";
import { StaffPassDetail } from "./components/pass.managements/staff.passes/StaffPassDetail";
import { StaffPassListing } from "./components/pass.managements/staff.passes/StaffPassListing";
import { PassUsageDetail } from "./components/pass.managements/usage/PassUsageDetail";
import { PassUsageListing } from "./components/pass.managements/usage/PassUsageListing";
import { ProductMenuCreate } from "./components/products/product.menu/ProductMenuCreate";
import { ProductMenuListing } from "./components/products/product.menu/ProductMenusListing";
import { ProductCreate } from "./components/products/products/ProductCreate";
import { ProductDetail } from "./components/products/products/ProductDetail";
import { ProductListing } from "./components/products/products/ProductListing";
import { HallTransactionEnquiry } from "./components/transaction.managements/hall.transaction.enquiry/HallTransactionEnquiry";
import { RefundDetails } from "./components/transaction.managements/refunds/RefundDetails";
import { RefundListing } from "./components/transaction.managements/refunds/RefundListing";
import TransactionDetails from "./components/transaction.managements/transactions/TransactionDetails";
import { TransactionListing } from "./components/transaction.managements/transactions/TransactionListing";
import { UserDetail } from "./components/user.managements/UserDetail";
import { UserListing } from "./components/user.managements/UserListing";
import { UserRegister } from "./components/user.managements/UserRegister";
import { UserProfile } from "./components/user.managements/UserProfile";
import VoucherExtensionRequestCreation from "./components/vouchers/voucher.extensions/VoucherExtensionRequestCreation";
import { VoucherExtensionRequestDetail } from "./components/vouchers/voucher.extensions/VoucherExtensionRequestDetail";
import { VoucherExtensionRequestListing } from "./components/vouchers/voucher.extensions/VoucherExtensionRequestListing";
import { VoucherRequestListing } from "./components/vouchers/voucher.request/VoucherRequestListing";
import { EvoucherListing } from "./components/evouchers/evouchers/EvoucherListing";
import { EvoucherDetail } from "./components/evouchers/evouchers/EvoucherDetail";
import { EvoucherBatchListing } from "./components/evouchers/batches/EvoucherBatchListing";
import { EvoucherOrderListing } from "./components/evouchers/orders/EvoucherOrderListing";
import { EvoucherOrderCreate } from "./components/evouchers/orders/EvoucherOrderCreate";
import { EvoucherBatchCreate } from "./components/evouchers/batches/EvoucherBatchCreate";
import { EvoucherOrderDetail } from "./components/evouchers/orders/EvoucherOrderDetail";
import { EvoucherBatchDetail } from "./components/evouchers/batches/EvoucherBatchDetail";
import { EvoucherExtensionRequestListing } from "./components/evouchers/extension.request/EvoucherExtensionRequestListing";
import { EvoucherExtensionRequestDetail } from "./components/evouchers/extension.request/EvoucherExtensionRequestDetail";
import { EvoucherBatchExtensionRequestListing } from "./components/evouchers/batch.extension.requests/EvoucherBatchExtensionRequestListing";
import { EvoucherBatchExtensionRequestDetail } from "./components/evouchers/batch.extension.requests/EvoucherBatchExtensionRequestDetail";
import { EvoucherProgramListing } from "./components/evouchers/programs/EvoucherProgramListing";
import { EvoucherProgramDetail } from "./components/evouchers/programs/EvoucherProgramDetail";
import { VoucherProgramDetail } from "./components/vouchers/voucher.programs/VoucherProgramDetail";
import VoucherProgramListing from "./components/vouchers/voucher.programs/VoucherProgramListing";
import { ExternalVoucherDetails } from "./components/vouchers/vouchers/ExternalVoucherDetails";
import { VoucherActivation } from "./components/vouchers/vouchers/VoucherActivation";
import { VoucherDetail } from "./components/vouchers/vouchers/VoucherDetail";
import VoucherListing from "./components/vouchers/vouchers/VoucherListing";
import { VoucherRegistration } from "./components/vouchers/vouchers/VoucherRegistration";
import { ExternalVoucherUsageListing } from "./components/vouchers/voucher.usages/external/ExternalVoucherUsageListing";
import InternalVoucherUsageListing from "./components/vouchers/voucher.usages/internal/InternalVoucherUsageListing";
import { CareerCreate } from "./components/website.managements/careers/CareerCreate";
import { CareerDetail } from "./components/website.managements/careers/CareerDetail";
import { CareerListing } from "./components/website.managements/careers/CareerListing";
import { ContestCreate } from "./components/website.managements/contests/ContestCreate";
import { ContestDetail } from "./components/website.managements/contests/ContestDetail";
import { ContestListing } from "./components/website.managements/contests/ContestListing";
import { ScheduledContestDetail } from "./components/website.managements/contests/ScheduledContestDetail";
import { ScheduledContestListing } from "./components/website.managements/contests/ScheduledContestListing";
import { FilmFestivalCreate } from "./components/website.managements/film.festivals/FilmFestivalCreate";
import { FilmFestivalDetail } from "./components/website.managements/film.festivals/FilmFestivalDetail";
import { FilmFestivalListing } from "./components/website.managements/film.festivals/FilmFestivalListing";
import { ScheduledFilmFestivalDetail } from "./components/website.managements/film.festivals/ScheduledFilmFestivalDetail";
import { ScheduledFilmFestivalListing } from "./components/website.managements/film.festivals/ScheduledFilmFestivalListing";
import { KinoLoungeMovieCreate } from "./components/website.managements/kinolounge/KinoLoungeMovieCreate";
import { KinoLoungeMovieDetail } from "./components/website.managements/kinolounge/KinoLoungeMovieDetail";
import { KinoLoungeMovieListing } from "./components/website.managements/kinolounge/KinoLoungeMovieListing";
import { LocationDetail } from "./components/website.managements/locations/LocationDetail";
import { LocationListing } from "./components/website.managements/locations/LocationListing";
import { ScheduledLocationListing } from "./components/website.managements/locations/ScheduledLocationListing";
import { ScheduledLocationDetail } from "./components/website.managements/locations/ScheduledLocationDetail";
import { ScheduledKinoLoungeMovieListing } from "./components/website.managements/kinolounge/ScheduledKinoLoungeMovieListing";
import { ScheduledKinoLoungeMovieDetail } from "./components/website.managements/kinolounge/ScheduledKinoLoungeMovieDetail";
import { PromotionCreate } from "./components/website.managements/promotions/PromotionCreate";
import { PromotionDetail } from "./components/website.managements/promotions/PromotionDetail";
import { PromotionListing } from "./components/website.managements/promotions/PromotionListing";
import { ScheduledPromotionDetail } from "./components/website.managements/promotions/ScheduledPromotionDetail";
import { ScheduledPromotionListing } from "./components/website.managements/promotions/ScheduledPromotionListing";
import { ScheduledVoucherTypeDetail } from "./components/website.managements/vouchers/ScheduledVoucherTypeDetail";
import { ScheduledVoucherTypeListing } from "./components/website.managements/vouchers/ScheduledVoucherTypeListing";
import { VoucherTypeCreate } from "./components/website.managements/vouchers/VoucherTypeCreate";
import { VoucherTypeDetail } from "./components/website.managements/vouchers/VoucherTypeDetail";
import { VoucherTypeListing } from "./components/website.managements/vouchers/VoucherTypeListing";
import { CustomContentDetail } from "./components/website.managements/custom.contents/CustomContentDetail";
import { CustomContentListing } from "./components/website.managements/custom.contents/CustomContentListing";
import { MovieListing } from "./components/movie.managements/movies/MovieListing";
import { ChangePassword } from "./components/auth/ChangePassword";
import { MovieCreation } from "./components/movie.managements/movies/MovieCreation";
import { MovieDetails } from "./components/movie.managements/movies/MovieDetails";
import { ServicesListing } from "./components/pricing/services/ServicesListing";
import { ServiceCreation } from "./components/pricing/services/ServiceCreation";
import { ServiceDetails } from "./components/pricing/services/ServiceDetails";
import { SurchargesListing } from "./components/pricing/surcharges/SurchargesListing";
import { SurchargeDetails } from "./components/pricing/surcharges/SurchargeDetails";
import { SpecialPriceDetail } from "./components/pricing/special.price/SpecialPriceDetail";
import { SpecialPriceCreation } from "./components/pricing/special.price/SpecialPriceCreation";
import { PriceUpdateListing } from "./components/pricing/prices.update/PriceUpdateListing";
import { PerformanceListing } from "./components/movie.managements/performances/PerformanceListing";
import { PerformanceDetails } from "./components/movie.managements/performances/PerformanceDetails";
import { MovieReleaseMediaListing } from "./components/movie.managements/medias/MovieReleaseMediaListing";
import { MovieReleaseMediaDetails } from "./components/movie.managements/medias/MovieReleaseMediaDetails";
import { MovieReleaseDetails } from "./components/movie.managements/movies/MovieReleaseDetails";
import { MovieReleaseCreation } from "./components/movie.managements/movies/MovieReleaseCreation";
import { MoviePromotionValidityListing } from "./components/movie.managements/movie.promotion.validity/MoviePromotionValidityListing";
import { MoviePromotionValidityDetail } from "./components/movie.managements/movie.promotion.validity/MoviePromotionValidityDetail";
import { MoviePromotionValidityCreation } from "./components/movie.managements/movie.promotion.validity/MoviePromotionValidityCreation";
import { UpdateMovieReleaseRestrictions } from "./components/movie.managements/movies/UpdateMovieReleaseRestrictions";
import { InternalVoucherDetails } from "./components/vouchers/vouchers/InternalVoucherDetails";
import { BookingRequestListing } from "./components/corporate.booking/BookingRequestListing";
import { BookingRequestDetails } from "./components/corporate.booking/BookingRequestDetails";
import { CorporateClientListing } from "./components/corporate.booking/clients/CorporateClientListing";
import { CorporateClientDetail } from "./components/corporate.booking/clients/CorporateClientDetail";
import { CorporateClientCreate } from "./components/corporate.booking/clients/CorporateClientCreate";
import { CorporateBookingListing } from "./components/corporate.booking/booking/CorporateBookingListing";
import { CorporateBookingCreate } from "./components/corporate.booking/booking/CorporateBookingCreate";
import { CorporateBookingDetails } from "./components/corporate.booking/booking/CorporateBookingDetails";
import { ETicketDistribution } from "./components/corporate.booking/booking/eticket.distribution/ETicketDistribution";
import { CampaignCreation } from "./components/campaigns/CampaignCreation";
import { CampaignListing } from "./components/campaigns/CampaignListing";
import { CampaignDetails } from "./components/campaigns/CampaignDetails";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";
import { LayoutDocumentation } from "./documentation/LayoutDocumentation";
import { CreateUser } from "./documentation/users/CreateUser";
import { UpdateUser } from "./documentation/users/UpdateUser";
import { ActivateDeactivateUser } from "./documentation/users/ActivateDeactivateUser";
import { ResetUser } from "./documentation/users/ResetUser";
import { CreateCancelOrder } from "./documentation/evouchers/CreateCancelOrder";
import { CreateEvoucherBatch } from "./documentation/evouchers/CreateEvoucherBatch";
import { ApproveCancelRejectBatch } from "./documentation/evouchers/ApproveCancelRejectBatch";
import { ExtendDownloadEvoucherBatch } from "./documentation/evouchers/ExtendDownloadEvoucherBatch";
import { ExtendEvoucherValidity } from "./documentation/evouchers/ExtendEvoucherValidity";
import { BlockEvoucher } from "./documentation/evouchers/BlockEvoucher";
import { ViewLocationHelp } from "./documentation/locations/ViewLocationHelp";
import { EditLocationHelp } from "./documentation/locations/EditLocationHelp";
import { ViewLocationScheduledHelp } from "./documentation/locations/ViewLocationScheduledHelp";
import { EditLocationScheduledHelp } from "./documentation/locations/EditLocationScheduledHelp";
import { ViewCareerHelp } from "./documentation/careers/ViewCareerHelp";
import { CreateCareerHelp } from "./documentation/careers/CreateCareerHelp";
import { EditCareerHelp } from "./documentation/careers/EditCareerHelp";
import { ViewPromotionHelp } from "./documentation/promotions/ViewPromotionHelp";
import { CreatePromotionHelp } from "./documentation/promotions/CreatePromotionHelp";
import { EditPromotionHelp } from "./documentation/promotions/EditPromotionHelp";
import { ViewScheduledPromotionHelp } from "./documentation/promotions/ViewScheduledPromotionHelp";
import { EditScheduledPromotionHelp } from "./documentation/promotions/EditScheduledPromotionHelp";
import { ViewContestHelp } from "./documentation/contests/ViewContestHelp";
import { CreateContestHelp } from "./documentation/contests/CreateContestHelp";
import { EditContestHelp } from "./documentation/contests/EditContestHelp";
import { ViewScheduledContestHelp } from "./documentation/contests/ViewScheduledContestHelp";
import { EditScheduledContestHelp } from "./documentation/contests/EditScheduledContestHelp";
import { ViewMovieVoucherHelp } from "./documentation/movievouchers/ViewMovieVoucherHelp";
import { CreateMovieVoucherHelp } from "./documentation/movievouchers/CreateMovieVoucherHelp";
import { EditMovieVoucherHelp } from "./documentation/movievouchers/EditMovieVoucherHelp";
import { ViewScheduledMovieVoucherHelp } from "./documentation/movievouchers/ViewScheduledMovieVoucherHelp";
import { EditScheduledMovieVoucherHelp } from "./documentation/movievouchers/EditScheduledMovieVoucherHelp";
import { ViewFilmFestivalHelp } from "./documentation/filmfestivals/ViewFilmFestivalHelp";
import { CreateFilmFestivalHelp } from "./documentation/filmfestivals/CreateFilmFestivalHelp";
import { EditFilmFestivalHelp } from "./documentation/filmfestivals/EditFilmFestivalHelp";
import { ViewScheduledFilmFestivalHelp } from "./documentation/filmfestivals/ViewScheduledFilmFestivalHelp";
import { EditScheduledFilmFestivalHelp } from "./documentation/filmfestivals/EditScheduledFilmFestivalHelp";
import { ViewKinoloungeHelp } from "./documentation/kinolounge/ViewKinoloungeHelp";
import { CreateKinoloungeHelp } from "./documentation/kinolounge/CreateKinoloungeHelp";
import { EditKinoloungeHelp } from "./documentation/kinolounge/EditKinoloungeHelp";
import { ViewCustomContentHelp } from "./documentation/customcontent/ViewCustomContentHelp";
import { EditCustomContentHelp } from "./documentation/customcontent/EditCustomContentHelp";
import { InternalReportsListing } from "./components/report.manager/reports/InternalReportsListing";
import { TheatreReportsListing } from "./components/report.manager/reports/TheatreReportsListing";
import { RequestReportsListing } from "./components/report.manager/reports/RequestReportsListing";
import { DailyDocketExport } from "./components/report.manager/export/DailyDocketExport";
import { PrintTicket } from "./components/corporate.booking/booking/print.ticket/PrintTicket";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { VoucherRequestDetails } from "./components/vouchers/voucher.request/VoucherRequestDetails";
import MovieMarathonListing from "./components/website.managements/movie.marathon/MovieMarathonListing";
import MovieMarathonDetail from "./components/website.managements/movie.marathon/MovieMarathonDetail";
import { TransactionRefundListing } from "./components/transaction.managements/transactions/TransactionRefundListing";
import { SpecialPriceListing } from "./components/pricing/special.price/SpecialPriceListing";
import { CacheClearing } from "./components/cache.clearing/CacheClearing";
import PerformanceSelectorListing from "./components/movie.managements/performance.selectors/PerformanceSelectorListing";
import PerformanceSelectorDetail from "./components/movie.managements/performance.selectors/PerformanceSelectorDetail";
import { SettingsRestService } from "./services/SettingsRestService";
import { SystemSettings } from "./common/SystemSettings";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const App = () => {
  const location = useLocation();

  const [isDocumentation, setIsDocumentation] = useState(false);

  useEffect(() => {
    const isDoc = window.location.pathname.includes("/documentation");
    setIsDocumentation(isDoc);
  }, [location]);

  useEffect(() => {
    new SettingsRestService().get().then((response) => {
      SystemSettings.initial(response.data);
    });
  }, []);

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <Switch>
          <Route exact path="/changePassword" component={ChangePassword} />
          <Route exact path="/login" component={Login} />
          {isDocumentation ? (
            <AuthenticatedRoute
              exact
              path=""
              component={AuthenticatedAppDocumentation}
            />
          ) : (
            <AuthenticatedRoute exact path="" component={AuthenticatedApp} />
          )}
        </Switch>
        <ToastContainer
          position="bottom-right"
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          theme="colored"
          autoClose={60000}
        />
      </QueryClientProvider>
    </RecoilRoot>
  );
};

const AuthenticatedApp = () => (
  <Layout>
    <Switch>
      <AuthenticatedRoute
        exact
        path="/careers/create"
        component={CareerCreate}
        resourceId={ResourceID.CareerCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/careers/:id"
        component={CareerDetail}
        resourceId={ResourceID.CareerDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/careers"
        component={CareerListing}
        resourceId={ResourceID.CareerListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/contests/schedules/:id"
        component={ScheduledContestDetail}
        resourceId={ResourceID.ContestScheduledDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/contests/schedules"
        component={ScheduledContestListing}
        resourceId={ResourceID.ContestScheduledListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/contests/create"
        component={ContestCreate}
        resourceId={ResourceID.ContestCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/contests/:id"
        component={ContestDetail}
        resourceId={ResourceID.ContestDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/contests"
        component={ContestListing}
        resourceId={ResourceID.ContestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/film_festivals/schedules/:id"
        component={ScheduledFilmFestivalDetail}
        resourceId={ResourceID.FilmFestivalScheduledDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/film_festivals/schedules"
        component={ScheduledFilmFestivalListing}
        resourceId={ResourceID.FilmFestivalScheduledListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/film_festivals/create"
        component={FilmFestivalCreate}
        resourceId={ResourceID.FilmFestivalCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/film_festivals/:id"
        component={FilmFestivalDetail}
        resourceId={ResourceID.FilmFestivalDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/film_festivals"
        component={FilmFestivalListing}
        resourceId={ResourceID.FilmFestivalListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_marathons/:id"
        resourceId={ResourceID.MarathonDetailPage}
        component={MovieMarathonDetail}
      />
      <AuthenticatedRoute
        exact
        path="/movie_marathons"
        resourceId={ResourceID.MarathonListingPage}
        component={MovieMarathonListing}
      />
      <AuthenticatedRoute
        exact
        path="/scheduled_kinolounge_movies/schedules/:id"
        component={ScheduledKinoLoungeMovieDetail}
      />
      <AuthenticatedRoute
        exact
        path="/scheduled_kinolounge_movies/schedules"
        component={ScheduledKinoLoungeMovieListing}
      />
      <AuthenticatedRoute
        exact
        path="/kinolounge_movies/create"
        component={KinoLoungeMovieCreate}
        resourceId={ResourceID.KinoLoungeCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/kinolounge_movies/:id"
        component={KinoLoungeMovieDetail}
        resourceId={ResourceID.KinoLoungeDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/kinolounge_movies"
        component={KinoLoungeMovieListing}
        resourceId={ResourceID.KinoLoungeListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/locations/schedules/:id"
        component={ScheduledLocationDetail}
        resourceId={ResourceID.LocationScheduledDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/locations/schedules"
        component={ScheduledLocationListing}
        resourceId={ResourceID.LocationScheduledListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/locations/:id"
        component={LocationDetail}
        resourceId={ResourceID.LocationDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/locations"
        component={LocationListing}
        resourceId={ResourceID.LocationListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_vouchers/schedules/:id"
        component={ScheduledVoucherTypeDetail}
        resourceId={ResourceID.MovieVoucherScheduledDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_vouchers/schedules"
        component={ScheduledVoucherTypeListing}
        resourceId={ResourceID.MovieVoucherScheduledListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_vouchers/create"
        component={VoucherTypeCreate}
        resourceId={ResourceID.MovieVoucherCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_vouchers/:id"
        component={VoucherTypeDetail}
        resourceId={ResourceID.MovieVoucherDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_vouchers"
        component={VoucherTypeListing}
        resourceId={ResourceID.MovieVoucherListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/custom_contents/:id"
        component={CustomContentDetail}
        resourceId={ResourceID.CustomContentViewDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/custom_contents"
        component={CustomContentListing}
        resourceId={ResourceID.CustomContentListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes/create"
        component={PassCreate}
        resourceId={ResourceID.PassCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes/extend"
        component={PassExtend}
        resourceId={ResourceID.PassExtendButton}
      />
      <AuthenticatedRoute
        exact
        path="/passes/usage/:id"
        component={PassUsageDetail}
        resourceId={ResourceID.PassUsageDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes/usage"
        component={PassUsageListing}
        resourceId={ResourceID.PassUsageListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes/:pid/usage"
        component={PassUsageListing}
        resourceId={ResourceID.PassUsageListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes/:id"
        component={PassDetail}
        resourceId={ResourceID.PassDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/passes"
        component={PassListing}
        resourceId={ResourceID.PassListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/pass_programs/:id"
        component={PassProgramDetail}
        resourceId={ResourceID.PassProgramDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/pass_programs"
        component={PassProgramListing}
        resourceId={ResourceID.PassProgramListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/promotions/schedules/:id"
        component={ScheduledPromotionDetail}
        resourceId={ResourceID.PromotionScheduledDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/promotions/schedules"
        component={ScheduledPromotionListing}
        resourceId={ResourceID.PromotionScheduledListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/promotions/create"
        component={PromotionCreate}
        resourceId={ResourceID.PromotionCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/promotions/:id"
        component={PromotionDetail}
        resourceId={ResourceID.PromotionDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/promotions"
        component={PromotionListing}
        resourceId={ResourceID.PromotionListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/products"
        component={ProductListing}
        resourceId={ResourceID.ProductListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/products/create"
        component={ProductCreate}
        resourceId={ResourceID.ProductCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/products/:id"
        component={ProductDetail}
        resourceId={ResourceID.ProductDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/product_menus"
        component={ProductMenuListing}
        resourceId={ResourceID.ProductMenuListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/product_menus/:location/:app"
        component={ProductMenuCreate}
        resourceId={ResourceID.ProductMenuAddingButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/refunds/:id"
        component={RefundDetails}
        resourceId={ResourceID.RefundDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/refunds"
        component={RefundListing}
        resourceId={ResourceID.RefundListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/staff_passes/create"
        component={StaffPassCreate}
        resourceId={ResourceID.StaffPassCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/staff_passes/:pid/usage"
        component={PassUsageListing}
        resourceId={ResourceID.PassUsageListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/staff_passes/:id"
        component={StaffPassDetail}
        resourceId={ResourceID.StaffPassDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/staff_passes"
        component={StaffPassListing}
        resourceId={ResourceID.StaffPassListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/transaction_enquiry"
        component={HallTransactionEnquiry}
        resourceId={ResourceID.HallTransactionEnquiryPage}
      />
      <AuthenticatedRoute
        exact
        path="/transactions/:referenceNumber/refunds"
        component={TransactionRefundListing}
        resourceId={ResourceID.TransactionRefundListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/transactions/:referenceNumber/refunds/:id"
        component={RefundDetails}
        resourceId={ResourceID.RefundDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/transactions/:id"
        component={TransactionDetails}
        resourceId={ResourceID.TransactionDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/transactions"
        component={TransactionListing}
        resourceId={ResourceID.TransactionListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/users/register"
        component={UserRegister}
        resourceId={ResourceID.CreateNewUserButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/users/:id"
        component={UserDetail}
        resourceId={ResourceID.UserDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/users/profile/:id"
        component={UserProfile}
        resourceId={ResourceID.UserProfilePage}
      />
      <AuthenticatedRoute
        exact
        path="/users"
        component={UserListing}
        resourceId={ResourceID.UserListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/batch_extension_requests/:id"
        component={EvoucherBatchExtensionRequestDetail}
        resourceId={ResourceID.EVoucherBatchExtensionRequestViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/batch_extension_requests"
        component={EvoucherBatchExtensionRequestListing}
        resourceId={ResourceID.EVoucherBatchExtensionRequestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/batches"
        component={EvoucherBatchListing}
        resourceId={ResourceID.EVoucherBatchListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/batches/create"
        component={EvoucherBatchCreate}
        resourceId={ResourceID.EVoucherOrderBatchCreateButton}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/batches/:id"
        component={EvoucherBatchDetail}
        resourceId={ResourceID.EVoucherViewBatchDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/orders/create"
        component={EvoucherOrderCreate}
        resourceId={ResourceID.EVoucherOrderCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/orders/:id"
        component={EvoucherOrderDetail}
        resourceId={ResourceID.EVoucherOrderViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/orders"
        component={EvoucherOrderListing}
        resourceId={ResourceID.EVoucherOrderListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/evouchers/:id"
        component={EvoucherDetail}
        resourceId={ResourceID.EVoucherViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/evouchers"
        component={EvoucherListing}
        resourceId={ResourceID.EVoucherListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/extensions/:id"
        component={EvoucherExtensionRequestDetail}
        resourceId={ResourceID.EVoucherExtensionRequestViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/extensions"
        component={EvoucherExtensionRequestListing}
        resourceId={ResourceID.EVoucherExtensionRequestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/programs/:id"
        component={EvoucherProgramDetail}
        resourceId={ResourceID.EVoucherProgramViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/evouchers/programs"
        component={EvoucherProgramListing}
        resourceId={ResourceID.EVoucherProgramListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/internal_usages/:id"
        component={InternalVoucherDetails}
        resourceId={ResourceID.VoucherUsageInternalDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/internal_usages"
        component={InternalVoucherUsageListing}
        resourceId={ResourceID.VoucherUsageInternalListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/external_usages/:id"
        component={ExternalVoucherDetails}
        resourceId={ResourceID.VoucherUsageExternalDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/external_usages"
        component={ExternalVoucherUsageListing}
        resourceId={ResourceID.VoucherUsageExternalListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/programs/:id"
        component={VoucherProgramDetail}
        resourceId={ResourceID.VoucherProgramDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/programs"
        component={VoucherProgramListing}
        resourceId={ResourceID.VoucherProgramListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/extensions/create"
        component={VoucherExtensionRequestCreation}
        resourceId={ResourceID.VoucherExtensionRequestCreationPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/extensions/requests/:id"
        component={VoucherExtensionRequestDetail}
        resourceId={ResourceID.VoucherExtensionRequestDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/extensions/requests"
        component={VoucherExtensionRequestListing}
        resourceId={ResourceID.VoucherExtensionRequestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/register"
        component={VoucherRegistration}
        resourceId={ResourceID.VoucherRegistrationPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/activate"
        component={VoucherActivation}
        resourceId={ResourceID.VoucherActivationPage}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers/:id"
        component={VoucherDetail}
      />
      <AuthenticatedRoute
        exact
        path="/vouchers"
        component={VoucherListing}
        resourceId={ResourceID.VoucherListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/voucher_requests"
        component={VoucherRequestListing}
        resourceId={ResourceID.VoucherRequestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/voucher_requests/:id"
        component={VoucherRequestDetails}
        resourceId={ResourceID.VoucherRequestViewPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies"
        component={MovieListing}
        resourceId={ResourceID.MovieListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies/create"
        component={MovieCreation}
        resourceId={ResourceID.MovieCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies/:id"
        component={MovieDetails}
        resourceId={ResourceID.MovieDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/services"
        component={ServicesListing}
        resourceId={ResourceID.ServiceListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/services/create"
        component={ServiceCreation}
        resourceId={ResourceID.ServiceCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/services/:id"
        component={ServiceDetails}
      />
      <AuthenticatedRoute
        exact
        path="/surcharges"
        component={SurchargesListing}
        resourceId={ResourceID.ServiceSurchargeListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/surcharges/:id"
        component={SurchargeDetails}
        resourceId={ResourceID.ServiceSurchargeDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/price_update"
        component={PriceUpdateListing}
        resourceId={ResourceID.ServicePriceUpdateListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/special_prices"
        component={SpecialPriceListing}
        resourceId={ResourceID.SpecialPricingListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/special_prices/create"
        component={SpecialPriceCreation}
        resourceId={ResourceID.SpecialPricingCreateButtonAndPage}
      />
      <AuthenticatedRoute
        exact
        path="/special_prices/:id"
        component={SpecialPriceDetail}
        resourceId={ResourceID.SpecialPricingDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/performances"
        component={PerformanceListing}
        resourceId={ResourceID.PerformanceListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/performances/:id"
        component={PerformanceDetails}
        resourceId={ResourceID.PerformanceDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/performance-selectors"
        component={PerformanceSelectorListing}
        resourceId={ResourceID.PerformanceSelectorListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/performance-selectors/:id"
        component={PerformanceSelectorDetail}
        resourceId={ResourceID.PerformanceSelectorDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie-promotion-validity"
        component={MoviePromotionValidityListing}
        resourceId={ResourceID.MovieReleasePromotionValidityListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie-promotion-validity/create"
        component={MoviePromotionValidityCreation}
        resourceId={ResourceID.MovieReleasePromotionValidityCreationButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie-promotion-validity/:id"
        component={MoviePromotionValidityDetail}
        resourceId={ResourceID.MovieReleasePromotionValidityDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies/movie_releases/:id"
        component={MovieReleaseDetails}
        resourceId={ResourceID.MovieReleaseViewDetailsPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies/movie_releases/:id/update_restrictions"
        component={UpdateMovieReleaseRestrictions}
        resourceId={ResourceID.MovieReleaseUpdateRestrictionButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/movies/:id/movie_releases/create"
        component={MovieReleaseCreation}
        resourceId={ResourceID.MovieReleaseCreateButtonPage}
      />

      <AuthenticatedRoute
        exact
        path="/movie_release_medias"
        component={MovieReleaseMediaListing}
        resourceId={ResourceID.MovieMediaListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/movie_release_medias/:id"
        component={MovieReleaseMediaDetails}
        resourceId={ResourceID.MovieMediaDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/booking_requests"
        component={BookingRequestListing}
        resourceId={ResourceID.BookingRequestListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/booking_requests/:id"
        component={BookingRequestDetails}
        resourceId={ResourceID.BookingRequestDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_clients"
        component={CorporateClientListing}
        resourceId={ResourceID.CorporateClientListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_clients/create"
        component={CorporateClientCreate}
        resourceId={ResourceID.CorporateClientCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_clients/:id"
        component={CorporateClientDetail}
        resourceId={ResourceID.CorporateClientDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_bookings"
        component={CorporateBookingListing}
        resourceId={ResourceID.CorporateBookingListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_bookings/create"
        component={CorporateBookingCreate}
        resourceId={ResourceID.CorporateBookingCreateButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_bookings/:id"
        component={CorporateBookingDetails}
        resourceId={ResourceID.CorporateBookingDetailPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_bookings/:id/etickets"
        component={ETicketDistribution}
        resourceId={ResourceID.CorporateBookingDetailDistributeTicketButtonPage}
      />
      <AuthenticatedRoute
        exact
        path="/corporate_bookings/:id/print"
        component={PrintTicket}
        resourceId={ResourceID.CorporateBookingDetailPrintButton}
      />
      <AuthenticatedRoute
        exact
        path="/campaigns/create"
        component={CampaignCreation}
        resourceId={ResourceID.CampaignsCreation}
      />
      <AuthenticatedRoute
        exact
        path="/campaigns"
        component={CampaignListing}
        resourceId={ResourceID.CampaignsListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/campaigns/:id"
        component={CampaignDetails}
        resourceId={ResourceID.CampaignsViewPage}
      />
      <AuthenticatedRoute
        exact
        path="/internal_reports"
        component={InternalReportsListing}
        resourceId={ResourceID.InternalReportsListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/theatre_reports"
        component={TheatreReportsListing}
        resourceId={ResourceID.TheatreReportsListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/request_reports"
        component={RequestReportsListing}
        resourceId={ResourceID.RequestReportsListingPage}
      />
      <AuthenticatedRoute
        exact
        path="/daily_docket"
        component={DailyDocketExport}
        resourceId={ResourceID.DailyDocketExportPage}
      />
      <AuthenticatedRoute
        exact
        path="/cache_clear"
        component={CacheClearing}
        resourceId={ResourceID.CacheClearPage}
      />
      <AuthenticatedRoute exact path="/" component={Home} />
    </Switch>
  </Layout>
);

const AuthenticatedAppDocumentation = () => (
  <LayoutDocumentation>
    <Switch>
      <AuthenticatedRoute
        exact
        path="/documentation/users/createUser"
        component={CreateUser}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/users/updateUser"
        component={UpdateUser}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/users/activateDeactivateUser"
        component={ActivateDeactivateUser}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/users/resetUser"
        component={ResetUser}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/createCancelOrder"
        component={CreateCancelOrder}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/createEvoucherBatch"
        component={CreateEvoucherBatch}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/approveCancelRejectBatch"
        component={ApproveCancelRejectBatch}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/extendDownloadEvoucherBatch"
        component={ExtendDownloadEvoucherBatch}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/extendEvoucherValidity"
        component={ExtendEvoucherValidity}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/evouchers/blockEvoucher"
        component={BlockEvoucher}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/locations/viewLocationHelp"
        component={ViewLocationHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/locations/editLocationHelp"
        component={EditLocationHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/locations/viewLocationScheduledHelp"
        component={ViewLocationScheduledHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/locations/editLocationScheduledHelp"
        component={EditLocationScheduledHelp}
      />

      <AuthenticatedRoute
        exact
        path="/documentation/careers/viewCareerHelp"
        component={ViewCareerHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/careers/createCareerHelp"
        component={CreateCareerHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/careers/editCareerHelp"
        component={EditCareerHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/promotions/viewPromotionHelp"
        component={ViewPromotionHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/promotions/createPromotionHelp"
        component={CreatePromotionHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/promotions/editPromotionHelp"
        component={EditPromotionHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/promotions/viewScheduledPromotionHelp"
        component={ViewScheduledPromotionHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/promotions/editScheduledPromotionHelp"
        component={EditScheduledPromotionHelp}
      />

      <AuthenticatedRoute
        exact
        path="/documentation/contests/viewContestHelp"
        component={ViewContestHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/contests/createContestHelp"
        component={CreateContestHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/contests/editContestHelp"
        component={EditContestHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/contests/viewScheduledContestHelp"
        component={ViewScheduledContestHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/contests/editScheduledContestHelp"
        component={EditScheduledContestHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/movievouchers/viewMovieVoucherHelp"
        component={ViewMovieVoucherHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/movievouchers/createMovieVoucherHelp"
        component={CreateMovieVoucherHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/movievouchers/editMovieVoucherHelp"
        component={EditMovieVoucherHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/movievouchers/viewScheduledMovieVoucherHelp"
        component={ViewScheduledMovieVoucherHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/movievouchers/editScheduledMovieVoucherHelp"
        component={EditScheduledMovieVoucherHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/filmfestivals/viewFilmFestivalHelp"
        component={ViewFilmFestivalHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/filmfestivals/createFilmFestivalHelp"
        component={CreateFilmFestivalHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/filmfestivals/editFilmFestivalHelp"
        component={EditFilmFestivalHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/filmfestivals/viewScheduledFilmFestivalHelp"
        component={ViewScheduledFilmFestivalHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/filmfestivals/editScheduledFilmFestivalHelp"
        component={EditScheduledFilmFestivalHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/kinolounge/viewKinoloungeHelp"
        component={ViewKinoloungeHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/kinolounge/createKinoloungeHelp"
        component={CreateKinoloungeHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/kinolounge/editKinoloungeHelp"
        component={EditKinoloungeHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/customcontent/viewCustomContentHelp"
        component={ViewCustomContentHelp}
      />
      <AuthenticatedRoute
        exact
        path="/documentation/customcontent/editCustomContentHelp"
        component={EditCustomContentHelp}
      />
    </Switch>
  </LayoutDocumentation>
);

const AuthenticatedRoute = ({ component: Component, resourceId, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!AuthenticationManager.isAuthenticated()) {
        return <Redirect to="/login" />;
      }
      return AuthenticationManager.isAuthorized(resourceId) ? (
        <Component {...props} />
      ) : (
        <Forbidden />
      );
    }}
  />
);

const Forbidden = () => (
  <div className="main-content">
    <div className="section__content section__content--p30">
      <div
        className="container-fluid"
        style={{ paddingTop: "32px", textAlign: "center" }}
      >
        <h2 style={{ fontSize: "42px" }}>Forbidden</h2>
        You don't have permission to access this resource.
      </div>
    </div>
  </div>
);
export default App;
