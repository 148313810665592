import React from "react";
import {
  AuthenticationManager, ResourceID
} from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ScheduleConfirmationDialog } from "../../common/ScheduleConfirmationDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { VoucherTypeDetailCard } from "./cards/VoucherTypeDetailCard";
import { VoucherTypePreview } from "./preview/VoucherTypePreview";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { ScheduledVoucherTypeService } from "../../../services/ScheduledVoucherTypeService";
const ViewPageName = "View Movie Voucher";
const EditPageName = "Edit Movie Voucher";

export class VoucherTypeDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
      showScheduleDialog: false,
      voucherType: null,
      voucherTypeId: "",
      showHistoryDialog: false,
      scheduledVoucherTypes: []
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const voucherTypeId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || voucherTypeId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.loadScheduledVoucherTypes(id);
    this.setState({ voucherTypeId }, this.refreshData);
  }

  loadScheduledVoucherTypes = (id) => {
    new ScheduledVoucherTypeService().get("", { voucherTypeId: id })
      .then((response) => {
        this.setState({ scheduledVoucherTypes: response.data.data });
      });
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadVoucherType(id) {
    RestClient.sendGetRequest(
      `/api/v1/voucher_types/${id}`,
      (response) => {
        this.setState({ voucherType: response });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  onEditVoucherTypeClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const category = StringHelper.toNumber(model.category, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      category,
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onHideScheduleDialog = () => {
    this.setState({ showScheduleDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      voucherType: Object.assign({}, this.state.voucherType),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo, voucherTypeId } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/voucher_types/${voucherTypeId}`,
      request,
      (response) => {
        toast.success(GenericMessages.SaveChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          showConfirmDialog: false,
          voucherType: response,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onProceedScheduleDialog = (e) => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showScheduleDialog: false });
      return;
    }
    const { generalInfo, voucherTypeId } = this.state;
    const request = Object.assign(
      {
        liveVoucherTypeId: voucherTypeId,
      },
      e,
      generalInfo
    );
    RestClient.sendPostRequest(
      `/api/v1/scheduled_voucher_types/`,
      request,
      (response) => {
        toast.success(GenericMessages.ScheduleChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          showScheduleDialog: false,
        });
      },
      (errorResponse) => {
        toast.success(errorResponse.message);
        this.setState({
          showScheduleDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onShowScheduleDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showScheduleDialog: true });
  };

  refreshData() {
    const { voucherTypeId } = this.state;
    this.loadVoucherType(voucherTypeId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/movie_vouchers/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "movie_vouchers",
        name: "Movie Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-voucher-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showScheduleDialog,
      voucherType,
      showHistoryDialog,
      scheduledVoucherTypes,
    } = this.state;
    if (showPreview) {
      const previewModel = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <VoucherTypePreview voucherType={previewModel} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    {AuthenticationManager.isAuthorized(
                      ResourceID.MovieVoucherEditButtonPage
                    ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={voucherType ? undefined : "disabled"}
                          onClick={this.onEditVoucherTypeClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>)
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <VoucherTypeDetailCard
                  cardMode={cardMode}
                  defaultValue={voucherType}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                {AuthenticationManager.isAuthorized(
                  ResourceID.MovieVoucherScheduleButton
                ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={this.onShowScheduleDialog}
                    >
                      Schedule
                    </ButtonActionDetailGroup.Item>)}
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && voucherType}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && voucherType}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showScheduleDialog && voucherType && (
          <ScheduleConfirmationDialog
            onCancel={this.onHideScheduleDialog}
            onProceed={this.onProceedScheduleDialog}
            message={GenericMessages.ScheduleChangesDialogMessage("movie voucher")}
            warningMessage={GenericMessages.ScheduleWarningDialogMessage("movie voucher")}
            title={GenericMessages.ScheduleChangesDialogTitle}
            data={scheduledVoucherTypes}
          />
        )}
        {showHistoryDialog && (
          <HistoryDialog
            objectId={voucherType.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.VoucherType}
          />
        )}
      </div>
    );
  }
}
