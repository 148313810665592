import _ from "lodash"
export class ServiceTicket {
    constructor() {
        this.serviceId = 0;
        this.ticketType = 0;
        this.printLabel = "";
        this.serviceTicketPrices = []
    }
    
    isValid() {
        return this.ticketType > 0 &&
            !_.isEmpty(this.printLabel);
    }
}