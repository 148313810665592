import React, { useState } from "react";
import {
  Classify,
  MovieFormat,
  MovieSubtitle,
} from "../../../constants/MovieConstants";

export const MovieReleaseSelectionModal = (props) => {
  const [movieReleaseId, setMovieReleaseId] = useState(0);

  const onSelectMovieRelease = (e) => {
    let selectValue = e.target.value;
    let movieReleaseId = 0;
    if (!isNaN(+selectValue)) {
      movieReleaseId = +selectValue;
    }
    setMovieReleaseId(movieReleaseId);
  };

  const { onProceed, onCancel, movieReleases, title } = props;
  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="form-group row">
                  <label className="col-3">
                    Movie Release:<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    className="form-control col-8"
                    onChange={onSelectMovieRelease}
                  >
                    <option value={0}>
                      Select a Movie Release to duplicate from
                    </option>
                    {movieReleases.map((item) => (
                      <option key={item.id} value={item.id}>{`${
                        item.primaryTitle
                      } - ${MovieFormat.getCode(
                        item.format
                      )} - ${Classify.getCode(
                        item.classify
                      )} - ${MovieSubtitle.getCode(item.subtitle)}`}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  disabled={movieReleaseId === 0}
                  onClick={() => onProceed(movieReleaseId)}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
