import React from "react";
import { log } from "../../../common/Common";
import {
  EVoucherBatchStatus,
  SearchEvoucherBatchBy,
} from "../../../common/Constants";
import { StringHelper, DateHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { RowFormField } from "../../layout/FormLayout";

export class EvoucherBatchListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      searchBy: SearchEvoucherBatchBy.EVoucherBatchReference,
      evoucherBatchProgram: [],
      evoucherBatchStatus: [],
      batchStatus: 0,
      batchProgramId: 0,
      eVoucherOrderReference: "",
      reference: "",
      expiryDateStart: "",
      expiryDateEnd: "",
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "batches",
        name: "eVoucher Batches",
      }),
    });
  };

  async componentDidMount() {
    await this.loadData(0);
    this.loadEvoucherBatchStatuses();
    this.loadEvoucherBatchPrograms();
  }

  loadEvoucherBatchStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_batches/statuses",
      (response) => {
        this.setState({ evoucherBatchStatus: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  loadEvoucherBatchPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_orders/evoucher_programs",
      (response) => {
        this.setState({ evoucherBatchProgram: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  getApiPath = () => "/api/v1/evoucher_batches";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters, searchBy) {
    const reference = StringHelper.isNullOrEmpty(this.state.reference)
      ? ""
      : this.state.reference;
    const eVoucherOrderReference = StringHelper.isNullOrEmpty(
      this.state.eVoucherOrderReference
    )
      ? ""
      : this.state.eVoucherOrderReference;
    const batchStatus = this.state.batchStatus;
    const batchProgramId = this.state.batchProgramId;
    const expiryDateStart = this.state.expiryDateStart;
    const expiryDateEnd = this.state.expiryDateEnd;

    return super.injectSearchTerm(queryParameters, {
      batchProgramId,
      batchStatus,
      reference,
      eVoucherOrderReference,
      expiryDateStart,
      expiryDateEnd,
      includes: "EVoucherOrder.EVoucherProgram",
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onBatchProgramChange = (e) => {
    this.setState({ batchProgramId: e.target.value });
  };

  onBatchStatusChange = (e) => {
    this.setState({ batchStatus: e.target.value });
  };

  onSearchByChange = (e) => {
    if (this.state.searchBy !== e.target.value) {
      this.setState({ searchBy: e.target.value });
      this.setState({
        reference: "",
        eVoucherOrderReference: "",
        serialNumberEnd: "",
        validityEndFrom: "",
        validityEndTo: "",
        expiryDateStart: "",
        expiryDateEnd: "",
      });
    }
  };

  onRowClick = (id) => {
    this.props.history.push(`/evouchers/batches/${id}`);
  };

  generateTableFilter() {
    return (
      <>
        <div className="row form-group mb-3 pb-3">
          <label className="form-control-label d-inline-flex text-nowrap col-md-2">
            Search By:
          </label>
          <div className="col-md-10">
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="BatchID"
                    name="SearchBy"
                    value={SearchEvoucherBatchBy.EVoucherBatchReference}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    checked={
                      this.state.searchBy ===
                      SearchEvoucherBatchBy.EVoucherBatchReference
                    }
                  />
                  Batch ID
                </>
              }
              htmlFor="BatchID"
            >
              <input
                className="form-control SingleSN2"
                id="SingleText"
                type="text"
                placeholder="Enter Batch ID"
                value={this.state.reference}
                name="reference"
                onChange={this.onInputChange}
                disabled={
                  this.state.searchBy !==
                  SearchEvoucherBatchBy.EVoucherBatchReference
                }
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="OrderID"
                    name="SearchBy"
                    value={SearchEvoucherBatchBy.EVoucherOrderReference}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    checked={
                      this.state.searchBy ===
                      SearchEvoucherBatchBy.EVoucherOrderReference
                    }
                  />
                  Order ID
                </>
              }
              htmlFor="OrderID"
            >
              <input
                className="form-control SingleSN2"
                id="SingleText"
                type="text"
                placeholder="Enter Order ID"
                value={this.state.eVoucherOrderReference}
                name="eVoucherOrderReference"
                onChange={this.onInputChange}
                disabled={
                  this.state.searchBy !==
                  SearchEvoucherBatchBy.EVoucherOrderReference
                }
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="ExpiryDateRange"
                    name="SearchBy"
                    value={SearchEvoucherBatchBy.ExpiryDateRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                  />
                  Expiry Date Range
                </>
              }
              htmlFor="ExpiryDateRange"
            >
              <div className="row mx-0 justify-content-between">
                <input
                  className="form-control"
                  type="date"
                  id="DateStart2"
                  placeholder="Enter Expiry Date From"
                  value={this.state.expiryDateStart}
                  name="expiryDateStart"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !==
                    SearchEvoucherBatchBy.ExpiryDateRange
                  }
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control"
                  type="date"
                  id="DateEnd2"
                  placeholder="Enter Expiry Date To"
                  value={this.state.expiryDateEnd}
                  name="expiryDateEnd"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !==
                    SearchEvoucherBatchBy.ExpiryDateRange
                  }
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-7 col-xl-6 row mx-0 mb-3">
            <label
              className="align-self-center mb-0 form-control-label col-md-7 col-xl-5 px-0"
              htmlFor="BatchProgramId"
            >
              eVoucher Program:
            </label>
            <select
              className="form-control col-md-5 xol-xl-7"
              name="batchProgramId"
              id="BatchProgramId"
              required
              onChange={this.onBatchProgramChange}
              value={this.state.batchProgramId}
            >
              <option value={0}>All Programs</option>
              {this.state.evoucherBatchProgram
                ? this.state.evoucherBatchProgram.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-4 col-lg-5 col-xl-4 row mx-0 mb-3">
            <label
              className="align-self-center mb-0 form-control-label col-md-4 px-0"
              htmlFor="BatchStatus"
            >
              Status:{" "}
            </label>
            <select
              className="form-control col-md-8"
              name="batchStatus"
              id="BatchStatus"
              required
              onChange={this.onBatchStatusChange}
              value={this.state.batchStatus}
            >
              <option value={0}>All Status</option>
              {this.state.evoucherBatchStatus
                ? this.state.evoucherBatchStatus.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
          <div className="col-md-2 col-lg-12 col-xl-2 input-group row mb-3 pr-0">
            <div className="input-group-btn ml-auto">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" /> Search
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th width="25%">Batch ID</th>
            <th>EVoucher Program</th>
            <th>
              Expiry Date
              <i
                id="ValidityEnd"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th>Total Quantity</th>
            <th>Remaining Quantity</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td>{item.reference}</td>
                <td>{item.eVoucherOrder.eVoucherProgram.shortName}</td>
                <td>{DateHelper.toDisplayDateFormat(item.validityEnd)}</td>
                <td className={EVoucherBatchStatus.getColor(item.status)}>
                  {item.status}
                </td>
                <td>{item.totalQuantity}</td>
                <td>{item.totalQuantity - item.usedQuantity}</td>
                <td>
                  {item.updatedOn &&
                    DateHelper.toDisplayDateTimeFormat(item.updatedOn)}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
