import React, { useState } from "react";
import { DateHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ViewMode } from "../../../constants/PricingConstants";

export const SurchargePricingItem = (props) => {
  const { data, viewMode, onRemove } = props;
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onDelete = () => {
    RestClient.sendDeleteRequest(
      `/api/v1/surcharge_prices/${data.id}`,
      () => {
        toast.success("The surcharge price has been deleted successful.");
        onRemove();
      },
      (error) => {
        toast.error(error.message);
        
      }
    );
    setShowConfirmation(false);
  };

  if (!data) {
    return null;
  }

  return (
    <div className="card card-body position-relative">
      <div className="d-flex flex-row justify-content-end position-absolute top-8 right-8">
        <button
          className="btn btn-sm btn-danger"
          disabled={viewMode === ViewMode.Readonly}
          onClick={() => setShowConfirmation(true)}
        >
          <i className="far fa-trash-alt" />
        </button>
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="col-12">Price ID: {data.id}</label>
          <label className="col-12">
            Effective Start:
            <span className="EditDate mx-1" id="EditDate">
              {DateHelper.formatDateTimeString(data.effectiveStart)}
            </span>
          </label>
        </div>
        <div className="col-md-6">
          <label className="col-12">
            Sales Amount: {data.salesAmount.toFixed(6)}
          </label>
        </div>
      </div>
      <ConfirmDialog
        visible={showConfirmation}
        onProceed={onDelete}
        title="Delete Confirmation"
        message="Are you sure you want to delete this service ticket price?"
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};
