export class VoucherRequest {
    constructor(data) {
        this.id = data?.id ?? 0;
        this.type = data?.type ?? 0;
        this.quantity = data?.quantity ?? 0;
        this.emailAddress = data?.emailAddress ?? "";
        this.department = data?.department ?? "";
        this.postalCode = data?.postalCode ?? "";
        this.additionalComments = data?.additionalComments ?? "";
        this.salutation = data?.salutation ?? "";
        this.name = data?.name ?? "";
        this.contactNumber = data?.contactNumber ?? "";
        this.companyName = data?.companyName ?? "";
        this.companyAddress = data?.companyAddress ?? "";
        this.personInCharge = data?.personInCharge ?? "";
        this.status = data?.status ?? 0;
        this.reasonForRejection = data?.reasonForRejection ?? "";
        this.actions = data?.actions ?? [];
        this.voucherTypeName = data?.voucherTypeName ?? "";
    }
}