import React from "react";
import _ from "lodash";

export const TextAreaInput = (props) => {
  const { name, value, onChange, placeholder, readOnly, rows } = props;

  if (!_.isNil(readOnly) && readOnly) {
    return (
      <textarea
        className="form-control display-input"
        value={value}
        rows={rows ?? 2}
        readOnly
      />
    );
  }

  return (
    <textarea
      className="form-control"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder ?? `Enter ${_.capitalize(name)}`}
      rows={rows ?? 2}
    />
  );
};
