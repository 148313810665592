import { RestService } from "./RestService";

export class ServicesRestService extends RestService {
  constructor() {
    super("/api/v1/services");
  }

  async updatePrice(data) {
    return await this.postImpl("update_prices", data);
  }

  async preview(data) {
    return await this.postImpl("preview", data);
  }
}
