import React, { useState } from "react";
import { FieldWrapper } from "../../FieldWrapper";
import { TextInput } from "../../TextInput";
import RestClient from "../../../../common/RestClient";
import { campaignBundlesAtom } from "../../CampaignState";
import { useRecoilState } from "recoil";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import _ from "lodash";
import { toast } from "react-toastify";
import { CouponSlip } from "./CouponSlip";
import { TextAreaInput } from "../../TextAreaInput";
import { Card } from "../../../common/Card";
import { CouponTitleType } from "../../../../constants/CampaignConstants";

export const CampaignBundleCouponItem = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const { instance, idx, parentidx } = props;

  const handleError = (error) => console.error(error);

  const onDelete = () => {
    if (_.isNil(instance) || instance.id <= 0) {
      return;
    }
    RestClient.sendDeleteRequest(
      `/api/v1/campaign_bundle_coupons/${instance.id}`,
      () => {
        const editableCampaignBundles = _.cloneDeep(campaignBundles);
        const bundle = editableCampaignBundles.find(
          (x) => x.id === instance.campaignBundleId
        );
        bundle.campaignBundleCoupons = bundle.campaignBundleCoupons.filter(
          (x) => x.id !== instance.id
        );
        setCampaignBundles(editableCampaignBundles);
        toast.success(
          "The campaign bundle coupon has been deleted successful."
        );
      },
      handleError
    );
    setShowDeleteModal(false);
  };

  return (
    <Card title="Coupon Details" parentidx={parentidx} idx={idx}>
      <div className="card-body bundle0-product0-details">
        <div className="d-flex flex-row justify-content-end mb-3">
          <button
            className="btn btn-danger"
            onClick={() => setShowDeleteModal(true)}
          >
            <i className="fas fa-trash-alt" aria-hidden="true" />
          </button>
        </div>
        <div className="row form-group">
          <div className="col-xl-6">
            <FieldWrapper
              label="Title"
              additionalCss="form-group"
              input={<TextInput readOnly={true} value={instance.title} />}
            />
            {instance.couponTitleType !== CouponTitleType.UserDefined && (
              <FieldWrapper
                label="Example title display"
                additionalCss="form-group"
                input={
                  <TextInput
                    readOnly={true}
                    value={CouponTitleType.generateTitleExample(instance)}
                  />
                }
              />
            )}
            <FieldWrapper
              label="Label display"
              additionalCss="form-group"
              input={
                <TextInput readOnly={true} value={instance.labelDisplay} />
              }
            />
            <FieldWrapper
              label="Quantity"
              additionalCss="form-group"
              input={<TextInput readOnly={true} value={instance.quantity} />}
            />
            <FieldWrapper
              label="Description"
              additionalCss="form-group"
              input={<TextInput readOnly={true} value={instance.description} />}
            />
            <FieldWrapper
              label="Term Condition"
              additionalCss="form-group"
              input={
                <TextAreaInput
                  readOnly={true}
                  value={instance.termCondition}
                  rows={5}
                />
              }
            />
          </div>
          <div className="col-xl-6 mx-3 mx-xl-0 overflow-x">
            <CouponSlip instance={instance} />
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showDeleteModal}
        message="Are you sure you want to delete the campaign bundle coupon?"
        onProceed={onDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};
