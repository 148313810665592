export class EvoucherBatchMode {
    static View = 0;
    static Create = 1;

    static isEditMode(mode) {
        return mode === EvoucherBatchMode.Create;
    }

    static isViewMode(mode) {
        return mode === EvoucherBatchMode.View;
    }
}
