import React from "react";
import {
  DefaultSelection,
  roundAmount,
  YesNoOptions,
} from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../CardMode";
import { Availability, ProductCategory } from "../../Constants";
import { ProductCombos } from "../combos/ProductCombos";

export class GeneralInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
      isShowComboCard: false,
      selectedCategory: DefaultSelection.NotSelect,
      isPromotionalItem: false,
      isPublic: false,
      selectedAvailability: Availability.Available,
      availabilities: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });

      if (this.props.cardMode === CardMode.ProductMenuView) {
        this.getAvailability();
      }
    }
    if (this.props.model) {
      this.setState({ selectedCategory: this.props.model.category });
    }
  }

  getAvailability = () => {
    const availabilities = Availability.All;
    this.setState({ availabilities: availabilities });
  };

  getSelectedCategoryName = (id) => {
    return ProductCategory.getName(id);
  };

  getControlClassName(fieldName) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""}`;
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onComboContentChange = (contents) => {
    const newModel = Object.assign({}, this.state.model, {
      productCombos: contents,
    });
    this.setState({ model: newModel }, this.onModelChange);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value =
      e.target.type === "number" ? parseFloat(e.target.value) : e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onSalesAmountValueChange = (e) => {
    const salesAmount = roundAmount(parseFloat(e.target.value));
    const newModel = Object.assign({}, this.state.model, {
      salesAmount
    });
    this.setState({ model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  onChangeAvailability = (e) => {
    const value = parseInt(e.target.value);
    this.setState(
      { selectedAvailability: value },
      this.props.onChangeAvailability(value)
    );
  };

  onCategoryChange = (e) => {
    const value = parseInt(e.target.value) || 0;
    const newModel = Object.assign({}, this.state.model, { category: value });
    this.setState(
      { isDirty: true, model: newModel, selectedCategory: value },
      this.onModelChange
    );
    if (value === ProductCategory.Combos) {
      this.onChangeStateComboCard(true);
    } else {
      this.onChangeStateComboCard(false);
    }
  };

  onChangeStateComboCard = (value) => {
    this.setState({ isShowComboCard: value });
  };

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      code: value.code || "",
      name: value.name || "",
      description: value.description || "",
      category: value.category || 0,
      salesAmount: value.salesAmount || 0,
      displayAmount: value.displayAmount || 0,
      docketAmount: value.docketAmount || 0,
      categoryName: value.categoryName || "",
      image: value.image || "",
      displayWeight: value.displayWeight || 1,
      isPromotionalItem: value.isPromotionalItem || false,
      isPublic: value.isPublic || false,
      productCombos: value.productCombos || null,
    };
  }

  onPromotionItemCheckChange = (e) => {
    const newStatus = parseInt(e.target.value);
    const value = newStatus === YesNoOptions.Yes;
    const newModel = Object.assign({}, this.state.model, {
      isPromotionalItem: value,
    });
    this.setState(
      { isDirty: true, model: newModel, isPromotionalItem: e.target.value },
      this.onModelChange
    );
  };

  onPublicCheckChange = (e) => {
    const newStatus = parseInt(e.target.value);
    const value = newStatus === YesNoOptions.Yes;
    const newModel = Object.assign({}, this.state.model, { isPublic: value });
    this.setState(
      { isDirty: true, model: newModel, isPublic: e.target.value },
      this.onModelChange
    );
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const isPublicValue = nextProps.defaultValue.isPublic
        ? YesNoOptions.Yes
        : YesNoOptions.No;
      const isPromotionalItemValue = nextProps.defaultValue.isPromotionalItem
        ? YesNoOptions.Yes
        : YesNoOptions.No;
      const nextModel =this.mergeDefaultValue(nextProps.defaultValue); 
      this.setState(
        Object.assign({}, this.state, {
          model: nextModel,
          isPublic: isPublicValue,
          isPromotionalItem: isPromotionalItemValue,
          isShowComboCard: nextModel.category === ProductCategory.Combos
        })
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      this.setState(
        Object.assign({}, this.state, { cardMode: nextProps.cardMode })
      );
    }
    return true;
  }

  generatePreviewImageModal() {
    return (
      <div
        className="modal fade"
        id="previewAvailableModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ paddingRight: "17px", marginTop: "100px", display: "none" }}
      >
        <div className="modal-dialog modal-sx" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title ml-3" id="exampleModalLongTitle">
                Preview Image
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div id="PreviewContent" className="container-fluid">
                <img
                  id="Image"
                  className="img-thumbnail"
                  src={this.state.model.image}
                  alt="Preview Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { model, cardMode } = this.state;
    const { productCombos, id } = model;
    return (
      <div>
        <Card title="Product Information">
          {CardMode.isViewMode(this.state.cardMode)
            ? this.renderViewMode()
            : this.renderEditMode()}
        </Card>
        {this.state.isShowComboCard ||
          this.state.model.category === ProductCategory.Combos ? (
          <Card title="Combo Content">
            <ProductCombos
              cardMode={cardMode}
              model={productCombos}
              productCombos={productCombos}
              productId={id}
              onComboContentChange={this.onComboContentChange}
            />
          </Card>
        ) : null}
      </div>
    );
  }

  renderViewMode() {
    const {
      code,
      isPromotionalItem,
      isPublic,
      name,
      description,
      salesAmount,
      docketAmount,
      displayAmount,
      image,
      displayWeight,
      category,
    } = this.state.model;
    const { cardMode, availabilities, selectedAvailability } = this.state;
    return (
      <React.Fragment>
        <div className="card-body info row">
          <div className="col">
            {cardMode === CardMode.ProductMenuView && (
              <div className="row form-group">
                <label
                  htmlFor="availabilityDropdown"
                  className="col-4 form-control-label"
                >
                  Availability:
                </label>
                <div className="col-7 pl-0">
                  <select
                    id="availabilityDropdown"
                    className={this.getControlClassName("availability")}
                    fieldname="availability"
                    onChange={this.onChangeAvailability}
                    value={selectedAvailability}
                  >
                    {availabilities &&
                      availabilities.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <div className="row form-group">
              <label className="col-4 form-control-label">Product Code:
                <span className="color-red">*</span>
              </label>
              <div className="col-7 pl-0">
                <span>{code}</span>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-4 form-control-label">
                Product Category:
              </label>
              <div className="col-7 pl-0">
                <span>{this.getSelectedCategoryName(category)}</span>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-4 form-control-label">Name:
                <span className="color-red">*</span>
              </label>
              <div className="col-7 pl-0">
                <span>{name}</span>
              </div>
            </div>
            <div className="row form-group">
              <label className="col-4 form-control-label">Description:
                <span className="color-red">*</span>
              </label>
              <div className="col-7 pl-0">
                <textarea
                  className="form-control display-input resize-none"
                  rows={4}
                  value={description}
                  readOnly
                ></textarea>
              </div>
            </div>
            <div className="row form-group mt-10">
              <label className="col-4 form-control-label">
                Display Weight:
              </label>
              <div className="col-7 pl-0">
                <span>{displayWeight}</span>
              </div>
            </div>
            <div className="row form-group mt-10">
              <label className="col-4 form-control-label">
                Is Promotional Item:
              </label>
              <div className="col-7 pl-0">
                <span>{!isPromotionalItem ? "No" : "Yes"}</span>
              </div>
            </div>
            <div className="row form-group mt-10">
              <label className="col-4 form-control-label">Is Public:</label>
              <div className="col-7 pl-0">
                <span>{!isPublic ? "No" : "Yes"}</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row form-group">
              <div className="col">
                <label>Image:<span className="color-red">*</span></label>
                <img style={{ border: "#dfdfdf solid 4px" }} src={image} />
              </div>
            </div>

            <div className="row form-group ml-1 mr-5">
              <fieldset className="card card-body pt-1 mb-0 pb-1">
                <legend className="col-auto" style={{ fontSize: 16 + "px" }}>
                  Pricing
                </legend>
                <div className="row form-group">
                  <label className="col-4 form-control-label">
                    Display Amount:
                  </label>
                  <div className="col-7 pl-0">
                    <span>{displayAmount?.toFixed(6)}</span>
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-4 form-control-label">
                    Sales Amount:
                  </label>
                  <div className="col-7 pl-0">
                    <span>{salesAmount.toFixed(6)}</span>
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col-4 form-control-label">
                    Docket Amount:
                  </label>
                  <div className="col-7 pl-0">
                    <span>{docketAmount?.toFixed(6)}</span>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const {
      code,
      name,
      description,
      salesAmount,
      displayAmount,
      docketAmount,
      image,
      displayWeight,
      category,
      isPromotionalItem,
      isPublic
    } = this.state.model;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <div className="row form-group">
              <label className="col-4 form-control-label">Product Code:<span className="color-red">*</span></label>
              <div className="col-7 pl-0">
                <input
                  id="code"
                  type="text"
                  className={`form-control display-input ${this.getControlClassName("code")}`}
                  fieldname="code"
                  value={code}
                  onChange={this.onTextBoxChange}
                  placeholder="Enter Product Code"
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="code"
                  isEnabled={isSubmit}
                  property="Product Code"
                  value={code}
                />
              </div>
            </div>

            <div className="row form-group">
              <label
                htmlFor="CategoryDropdown"
                className="col-4 form-control-label"
              >
                Product Category:
              </label>
              <div className="col-7 pl-0">
                <select
                  id="CategoryDropdown"
                  className={this.getControlClassName("category")}
                  fieldname="category"
                  onChange={this.onCategoryChange}
                  value={category ?? ""}
                >
                  <option value="0">Select a product category</option>
                  {ProductCategory.All.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="category"
                  isEnabled={isSubmit}
                  property="Product Category"
                  value={category}
                />
              </div>
            </div>

            <div className="row form-group">
              <label className="col-4 form-control-label">Name:<span className="color-red">*</span></label>
              <div className="col-7 pl-0">
                <input
                  id="Name"
                  type="text"
                  placeholder="Enter Product Name"
                  className={`form-control display-input ${this.getControlClassName("name")}`}
                  fieldname="name"
                  value={name}
                  onChange={this.onTextBoxChange}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="name"
                  isEnabled={isSubmit}
                  property="Product Name"
                  value={name}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col-4 form-control-label">Description:<span className="color-red">*</span></label>
              <div className="col-7 pl-0">
                <textarea
                  rows="5"
                  placeholder="Enter Description"
                  value={description}
                  className={`form-control display-input resize-none ${this.getControlClassName("description")}`}
                  fieldname="description"
                  onChange={this.onTextBoxChange}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="description"
                  isEnabled={isSubmit}
                  property="Description"
                  value={description}
                />
              </div>
            </div>

            <div className="row form-group">
              <label className="col-4 form-control-label">
                Display Weight:
              </label>
              <div className="col-7 pl-0">
                <input
                  id="DisplayWeight"
                  type="number"
                  min="1"
                  max="100"
                  placeholder="Enter Display Weight"
                  value={displayWeight ?? 0}
                  className={`form-control display-input ${this.getControlClassName("displayWeight")}`}
                  fieldname="displayWeight"
                  onChange={this.onTextBoxChange}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="displayWeight"
                  value={displayWeight}
                  isEnabled={isSubmit}
                  property="Display Weight"
                />
              </div>
            </div>

            <div className="row form-group">
              <label className="col-4">Is Promotional Item:</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="PromotionalItem"
                  value={YesNoOptions.Yes}
                  checked={isPromotionalItem}
                  onChange={this.onPromotionItemCheckChange}
                />
                <label
                  className="form-check-label mr-2"
                  htmlFor="PromotionalItem"
                >
                  Yes
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="PromotionalItemN"
                  value={YesNoOptions.No}
                  checked={!isPromotionalItem}
                  onChange={this.onPromotionItemCheckChange}
                />
                <label className="form-check-label" htmlFor="PromotionalItemN">
                  No
                </label>
              </div>
            </div>

            <div className="row form-group">
              <label className="col-4">Is Publicly Visible:</label>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="PublicY"
                  value={YesNoOptions.Yes}
                  checked={isPublic}
                  onChange={this.onPublicCheckChange}
                />
                <label className="form-check-label mr-2" htmlFor="PublicY">
                  Yes
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="PublicN"
                  value={YesNoOptions.No}
                  checked={!isPublic}
                  onChange={this.onPublicCheckChange}
                />
                <label className="form-check-label" htmlFor="PublicN">
                  No
                </label>
              </div>
            </div>
          </div>
          <div className="col">
            <fieldset className="card card-body pt-1 mb-3 pb-1">
              <legend className="col-auto" style={{ fontSize: 16 + "px" }}>
                Images
              </legend>
              <div className="row form-group">
                <label className="col-4 form-control-label">Image:<span className="color-red">*</span></label>
                <div className="col-5 pl-0 pr-0">
                  <input
                    type="text"
                    placeholder="Enter available image link"
                    value={image}
                    className={this.getControlClassName("displayImage")}
                    fieldname="image"
                    onChange={this.onTextBoxChange}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="image"
                    value={image}
                    isEnabled={isSubmit}
                    property="Available Image"
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-backdrop="false"
                    data-target="#previewAvailableModal"
                  >
                    Preview
                  </button>
                </div>
              </div>
            </fieldset>

            <fieldset className="card card-body pt-1 mb-3 pb-1">
              <legend className="col-auto" style={{ fontSize: 16 + "px" }}>
                Price
              </legend>
              <div className="row form-group">
                <label className="col-4 form-control-label">
                  Display Amount:
                </label>
                <div className="col-7 pl-0">
                  <input
                    id="SaleAmt"
                    placeholder="Enter Display Amount"
                    type="number"
                    value={displayAmount}
                    className={`form-control display-input ${this.getControlClassName("displayAmount")}`}
                    fieldname="displayAmount"
                    onChange={this.onTextBoxChange}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldname="displayAmount"
                    value={displayAmount}
                    isEnabled={isSubmit}
                    property="Display Amount"
                  />
                </div>
              </div>

              <div className="row form-group">
                <label className="col-4 form-control-label">
                  Sales Amount:
                </label>
                <div className="col-7 pl-0">
                  <input
                    id="SaleAmt"
                    placeholder="Enter Sales Amount"
                    type="number"
                    value={salesAmount}
                    className={`form-control display-input ${this.getControlClassName("salesAmount")}`}
                    fieldname="salesAmount"
                    onChange={this.onSalesAmountValueChange}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldname="salesAmount"
                    value={salesAmount}
                    isEnabled={isSubmit}
                    property="Sales Amount"
                  />
                </div>
              </div>
              <div className="row form-group">
                <label className="col-4 form-control-label">
                  Docket Amount:
                </label>
                <div className="col-7 pl-0">
                  <span>{docketAmount}</span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        {this.generatePreviewImageModal()}
      </React.Fragment>
    );
  }
}
