import moment from "moment";
import _ from "lodash";
import { generatePricePrintCode } from "../common/Helpers";

export class Pricing {
  constructor() {
    this.salesAmount = 0;
    const now = new Date();
    now.setHours(7);
    now.setMinutes(0);
    this.effectiveStart = moment(now).format("yyyy-MM-DDTHH:mm");
    this.pricePrintCode = "";
  }

  isValid() {
    return (
      this.salesAmount >= 0 &&
      !_.isNil(this.effectiveStart) &&
      new Date(this.effectiveStart) >= new Date()
    );
  }

  onChange() {
    this.pricePrintCode = generatePricePrintCode(this.salesAmount);
  }
}
