import React, { useCallback, useState } from "react";
import { ProductCategory } from "../../../products/Constants";
import { FieldWrapper } from "../../FieldWrapper";
import { TextInput } from "../../TextInput";
import RestClient from "../../../../common/RestClient";
import { campaignBundlesAtom, productsAtom } from "../../CampaignState";
import { useRecoilState, useRecoilValue } from "recoil";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import _ from "lodash";
import { toast } from "react-toastify";
import { Product } from "../../../../models/Product";

export const CampaignBundleProductItem = (props) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const products = useRecoilValue(productsAtom);
  const { instance } = props;

  const handleError = useCallback((error) => {
    toast.error(error.message);
  }, []);

  const onDelete = () => {
    if (_.isNil(instance) || instance.id <= 0) {
      return;
    }
    RestClient.sendDeleteRequest(
      `/api/v1/campaign_bundle_products/${instance.id}`,
      () => {
        const changeableCampaignBundles = _.cloneDeep(campaignBundles);
        const bundle = changeableCampaignBundles.find(
          (x) => x.id === instance.campaignBundleId
        );
        bundle.campaignBundleProducts = bundle.campaignBundleProducts.filter(
          (x) => x.id !== instance.id
        );
        setCampaignBundles(changeableCampaignBundles);
        toast.success(
          "The campaign bundle product has been deleted successful."
        );
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const renderReadonly = () => {
    const product =
      products.find((x) => x.id === instance.productId) ?? new Product({});
    return (
      <div className="row">
        <div className="col-xl-6">
          <FieldWrapper
            label="Product Category"
            additionalCss="form-group"
            input={
              <TextInput
                readOnly={true}
                value={ProductCategory.getName(product.category)}
              />
            }
          />
          <FieldWrapper
            label="Product"
            additionalCss="form-group"
            input={<TextInput readOnly={true} value={product.name} />}
          />
          <FieldWrapper
            label="Quantity"
            additionalCss="form-group"
            input={<TextInput readOnly={true} value={instance.quantity} />}
          />
        </div>
        <div className="col-xl-6">
          <fieldset className=" card card-body">
            <legend className="col-auto" style={{ fontSize: "16px" }}>
              Note: All prices are per unit (SGD)
            </legend>
            <FieldWrapper
              label="Sales Amount"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={product.salesAmount.toFixed(6)}
                />
              }
            />
          </fieldset>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        Product Details
        <i
          className="fas fa-chevron-down float-right zoom-1_5"
          style={{ display: "none" }}
          aria-hidden="true"
        />
        <i
          className="fas fa-chevron-up float-right zoom-1_5"
          aria-hidden="true"
        />
      </div>
      <div className="card-body">
        <div className="d-flex flex-row justify-content-end">
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          >
            <i className="fas fa-trash-alt" aria-hidden="true" />
          </button>
        </div>
        {renderReadonly()}
      </div>
      <ConfirmDialog
        visible={showDeleteConfirmation}
        message="Are you sure you want to delete the campaign bundle product?"
        onProceed={onDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
    </div>
  );
};
