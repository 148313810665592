import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ScheduleConfirmationDialog } from "../../common/ScheduleConfirmationDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { ContestDetailCard } from "./cards/ContestDetailCard";
import { ContestPreview } from "./preview/ContestPreview";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { ScheduledContestService } from "../../../services/ScheduledContestService";

const EditPageName = "Edit Contest";
const ViewPageName = "View Contest";

export class ContestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      contest: null,
      contestId: "",
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
      showScheduleDialog: false,
      showHistoryDialog: false,
      scheduledContests: []
    };
  }

  loadScheduledContests = (id) => {
    new ScheduledContestService().get("", { contestId: id })
      .then((response) => {
        this.setState({ scheduledContests: response.data.data });
      });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const contestId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || contestId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.loadScheduledContests(id);
    this.setState({ contestId }, this.refreshData);
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadContest(id) {
    RestClient.sendGetRequest(
      `/api/v1/contests/${id}`,
      (response) => {
        this.setState({ contest: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onEditContestClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const contestStartTime = StringHelper.toDateTimeString(
      model.contestStartTimeStr
    );
    const contestEndTime = StringHelper.toDateTimeString(
      model.contestEndTimeStr
    );
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const category = StringHelper.toNumber(model.category, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      category,
      contestEndTime,
      contestStartTime,
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onHideScheduleDialog = () => {
    this.setState({ showScheduleDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      contest: Object.assign({}, this.state.contest),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { contestId, generalInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/contests/${contestId}`,
      request,
      (response) => {
        toast.success(GenericMessages.PublishChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          contest: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onProceedScheduleDialog = (e) => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showScheduleDialog: false });
      return;
    }
    const { contestId, generalInfo } = this.state;
    const request = Object.assign(
      {       
        livePromotionId: contestId,
      },
      e,
      generalInfo
    );
    RestClient.sendPostRequest(
      `/api/v1/scheduled_contests/`,
      request,
      (response) => {
        toast.success(GenericMessages.PublishChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          showScheduleDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showScheduleDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onShowScheduleDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showScheduleDialog: true });
  };

  refreshData() {
    const { contestId } = this.state;
    this.loadContest(contestId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/contests/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "contests",
        name: "Contests",
      }),
      activeModule: new NavigationItem({
        identifier: "contest-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      contest,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showScheduleDialog,
      showHistoryDialog,
      scheduledContests,
    } = this.state;
    if (showPreview) {
      const previewModel = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <ContestPreview contest={previewModel} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    {
                      AuthenticationManager.isAuthorized(
                        ResourceID.ContestEditButtonPage
                      ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={contest ? undefined : "disabled"}
                          onClick={this.onEditContestClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>)
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <ContestDetailCard
                  cardMode={cardMode}
                  defaultValue={contest}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                {
                  AuthenticationManager.isAuthorized(
                    ResourceID.ContestEditButtonPage
                  ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={this.onShowScheduleDialog}
                    >
                      Schedule
                    </ButtonActionDetailGroup.Item>)
                }
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && contest}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && contest}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showScheduleDialog && contest && (
          <ScheduleConfirmationDialog
            onCancel={this.onHideScheduleDialog}
            onProceed={this.onProceedScheduleDialog}
            message={GenericMessages.ScheduleChangesDialogMessage("contest")}
            warningMessage={GenericMessages.ScheduleWarningDialogMessage("contest")}
            title={GenericMessages.ScheduleChangesDialogTitle}
            data={scheduledContests}
          />
        )}
        {showHistoryDialog && contest && (
          <HistoryDialog
            objectId={contest.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.Promotion}
          />
        )}
      </div>
    );
  }
}
