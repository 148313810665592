import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CampaignBundleTicket } from "../../../../models/CampaignBundle";
import { NumberInput } from "../../NumberInput";
import { FieldWrapper } from "../../FieldWrapper";
import RestClient from "../../../../common/RestClient";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import { SingleSelector } from "../../SingleSelector";
import { campaignBundlesAtom } from "../../CampaignState";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import { TicketTypes } from "../../../../constants/PricingConstants";
import { DiscountMode } from "../../../../constants/CampaignConstants";

export const NewCampaignBundleTicketModal = (props) => {
  const [instance, setInstance] = useState(new CampaignBundleTicket({}));
  const [ticketTypes, setTicketTypes] = useState([]);
  const [serviceId, setServiceId] = useState(0);
  const { campaignBundleId, visible, onCancel, services } = props;

  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  useEffect(() => {
    if (serviceId <= 0) {
      setTicketTypes([]);
      return;
    }
    RestClient.sendGetRequestWithParameters(
      `/api/v1/service_tickets`,
      {
        serviceId,
      },
      (response) => {
        if (_.isEmpty(response.data)) {
          setTicketTypes([
            TicketTypes.All.find((x) => x.value === TicketTypes.Adult),
          ]);
        } else {
          const availableTicketTypes = response.data.map((x) => x.ticketType);
          setTicketTypes(
            TicketTypes.All.filter(
              (x) => availableTicketTypes.indexOf(x.value) >= 0
            )
          );
        }
      },
      handleError
    );
  }, [serviceId]);

  const onCreate = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundle_tickets`,
      {
        ...instance,
        campaignBundleId,
        currentUser: AuthenticationManager.username(),
      },
      (response) => {
        const changeableCampaignBundles = _.cloneDeep(campaignBundles);
        const foundBundle = changeableCampaignBundles.find(
          (x) => x.id === campaignBundleId
        );
        foundBundle.campaignBundleTickets.push(
          new CampaignBundleTicket(response)
        );
        setInstance(new CampaignBundleTicket({}));
        setCampaignBundles(changeableCampaignBundles);
        onCancel();
        toast.success(
          "The campaign bundle ticket has been created successful."
        );
      },
      handleError
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    instance[fieldName] = selectValue;
    setInstance(_.cloneDeep(instance));
  };

  const onChangeSalesAmount = (e) => {
    const salesAmount = +e.target.value;
    instance.calculate(salesAmount);
    setInstance(_.cloneDeep(instance));
  };

  if (!visible) {
    return null;
  }

  const serviceOptions = services.map((x) => ({ value: x.id, name: x.name }));

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ paddingRight: "17px", display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Ticket</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="container-fluid">
                <FieldWrapper
                  label="Service"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <SingleSelector
                      name="serviceId"
                      type="number"
                      options={serviceOptions}
                      value={instance.serviceId}
                      onChange={(e) => {
                        setServiceId(+e.target.value);
                        onInputChange(e);
                      }}
                      placeholder="Service"
                    />
                  }
                />
                <FieldWrapper
                  label="Ticket Type"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <SingleSelector
                      type="number"
                      name="ticketType"
                      options={ticketTypes}
                      value={instance.ticketType}
                      placeholder="Ticket Type"
                      onChange={onInputChange}
                    />
                  }
                />
                <FieldWrapper
                  label="Quantity"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <NumberInput
                      name="quantity"
                      type="number"
                      value={instance.quantity}
                      onChange={onInputChange}
                      placeholder="Enter Quantity"
                      min={0}
                    />
                  }
                />
                <FieldWrapper
                  label="Discount Mode"
                  require={true}
                  additionalCss="form-group"
                  input={DiscountMode.All.map((x) => {
                    return (
                      <div className="" key={x.value}>
                        <input
                          className="mr-1"
                          type="radio"
                          valuetype="number"
                          name="discountMode"
                          value={x.value}
                          checked={instance.discountMode === x.value}
                          onChange={onInputChange}
                        />
                        <label className="form-check-label">{x.name}</label>
                      </div>
                    );
                  })}
                />
                <div className="row">
                  <div className="col-md-12">
                    <fieldset className="card card-body mb-0">
                      <legend className="col-auto" style={{ fontSize: "16px" }}>
                        All prices are per unit (SGD)
                      </legend>
                      {instance.discountMode === DiscountMode.Fixed && (
                        <FieldWrapper
                          label="Sales Amount"
                          require={true}
                          additionalCss="form-group"
                          input={
                            <div className="row">
                              <div className="col-4">
                                <NumberInput
                                  name="salesAmount"
                                  value={instance.salesAmount}
                                  onChange={(e) => {
                                    onInputChange(e);
                                    onChangeSalesAmount(e);
                                  }}
                                  min={0}
                                />
                              </div>
                            </div>
                          }
                        />
                      )}

                      {instance.discountMode === DiscountMode.Discount && (
                        <FieldWrapper
                          label="Discount Amount"
                          require={true}
                          additionalCss="form-group"
                          input={
                            <NumberInput
                              name="discountAmount"
                              value={instance.discountAmount}
                              onChange={onInputChange}
                              min={0}
                            />
                          }
                        />
                      )}

                      <FieldWrapper
                        label="Reimbursement Amount"
                        require={true}
                        additionalCss="form-group"
                        input={
                          <NumberInput
                            name="reimbursementAmount"
                            value={instance.reimbursementAmount}
                            onChange={onInputChange}
                            min={0}
                          />
                        }
                      />
                      <FieldWrapper
                        label="Docket Amount"
                        require={true}
                        additionalCss="form-group"
                        input={
                          <NumberInput
                            name="docketAmount"
                            value={instance.docketAmount}
                            onChange={onInputChange}
                            min={0}
                          />
                        }
                      />
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-success"
                onClick={onCreate}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
