import React, { useState } from "react";
import RestClient from "../../../common/RestClient";
import { CampaignConditionPerformance } from "../../../models/CampaignCondtion";
import { toast } from "react-toastify";
import { PerformanceStatus } from "../../../constants/MovieConstants";
import { DateHelper } from "../../../common/Helpers";
import { useRecoilValue } from "recoil";
import { locationsAtom, moviesAtom } from "../CampaignState";
import { CustomSelect } from "../../common/CustomSelect";
import _ from "lodash";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const PerformanceConditionDialog = (props) => {
  const [performanceCondition, setPerformanceCondition] = useState(
    new CampaignConditionPerformance()
  );
  const [performances, setPerformances] = useState([]);
  const [location, setLocation] = useState(null);
  const [date, setDate] = useState("");
  const locations = useRecoilValue(locationsAtom);
  const movies = useRecoilValue(moviesAtom);
  const { onCancel, onConfirm, visible } = props;

  const onAddClick = () => {
    onConfirm(performanceCondition);
    setPerformanceCondition((data) => {
      return { ...data, performanceIds: [] };
    });
  };

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onSelectDate = (e) => {
    e.persist();
    setDate(e.target.value);
  };

  const onChangeLocation = (e) => {
    const found = locations.find((x) => x.id === e.value);
    setLocation(found);
  };

  const onSelectLocationVenue = (e) => {
    setPerformanceCondition((data) => {
      return {
        ...data,
        locationVenueId: e.value,
      };
    });
  };

  const onSelectMovie = (e) => {
    setPerformanceCondition((data) => {
      return {
        ...data,
        movieId: e.value,
      };
    });
  };

  const validate = (number, errorMessage) => {
    if (!number) {
      toast.error(errorMessage);
      return false;
    }
    return true;
  };

  const onGetPerformances = () => {
    const { movieId, locationVenueId } = performanceCondition;
    if (
      !validate(location?.id, "Location field is required.") ||
      !validate(locationVenueId, "Location Hall is required.") ||
      !validate(movieId, "Movie field is required.")
    ) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/performances",
      {
        includes: "Service",
        movieId: movieId,
        locationId: location.id,
        locationVenueIds: [locationVenueId],
        date,
        status: PerformanceStatus.Public,
      },
      (response) => {
        setPerformances(response.data);
        if (!response.data || response.data.length === 0) {
          toast.info("Not found any show time.");
        }
      },
      handleError
    );
  };

  const onSelectShowTime = (e) => {
    const performanceIds = [...performanceCondition.performanceIds];
    const performanceId = +e.target.value;
    if (performanceIds.includes(performanceId)) {
      return;
    }
    performanceIds.push(performanceId);
    setPerformanceCondition((data) => {
      return {
        ...data,
        performanceIds,
      };
    });
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  const isEnabledAddButton =
    performanceCondition.performanceIds &&
    performanceCondition.performanceIds.length > 0;
  return (
    <>
      <div
        className="modal fade show"
        id="PerformanceConditionModal"
        role="dialog"
        style={{ paddingRight: "17px", display: "block" }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Configure Valid Showtimes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="row form-group">
                <label
                  className="form-control-label col-md-4"
                  htmlFor="Location"
                >
                  Location:<span className="color-red font-weight-bold">*</span>
                </label>
                <div className="col-md-12">
                  <CustomSelect
                    data={locations}
                    label="name"
                    value="id"
                    onChange={onChangeLocation}
                    selectedValue={location?.id ?? 0}
                  />
                </div>
              </div>
              <div className="row form-group">
                <label
                  className="form-control-label col-md-4"
                  htmlFor="LocationHallz"
                >
                  Location Hall:
                  <span className="color-red font-weight-bold">*</span>
                </label>
                <div className="col-md-12">
                  <CustomSelect
                    data={location?.locationVenues ?? []}
                    label="name"
                    value="id"
                    onChange={onSelectLocationVenue}
                    selectedValue={performanceCondition.locationVenueId}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="form-control-label" htmlFor="MovieDate">
                    Movie Date:
                    <span className="color-red font-weight-bold">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="movieDate"
                    id="MovieDate"
                    type="date"
                    onChange={onSelectDate}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label
                    className="form-control-label mb-0"
                    htmlFor="FilmTitle"
                  >
                    Movie Title:
                    <span className="color-red font-weight-bold">*</span>
                  </label>
                  <CustomSelect
                    data={movies}
                    label="primaryTitle"
                    value="id"
                    onChange={onSelectMovie}
                    selectedValue={performanceCondition.movieId}
                  />
                </div>
              </div>
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-primary"
                  onClick={onGetPerformances}
                >
                  Retrieve Showtimes
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
              {performances && performances.length > 0 && (
                <div className="row form-group" id="RetPerf">
                  <div className="col-md-12">
                    <label
                      className="form-control-label mb-0"
                      htmlFor="MovieTime"
                    >
                      Movie Time:
                      <span className="color-red font-weight-bold">*</span>
                    </label>
                    <div className="col-8 row" id="MovieTime">
                      {performances.map((performance) => (
                        <div className="form-check mr-1" key={performance.id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={performance.id}
                            onChange={onSelectShowTime}
                          />
                          <label className="form-check-label">
                            {DateHelper.toDisplayTimeFormat(
                              performance.actualDateTime
                            )}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn btn-success"
                onClick={onAddClick}
                disabled={!isEnabledAddButton}
              >
                Add Condition
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-backdrop fade show"></div>
    </>
  );
};
