import React from "react";
import {
  CustomContentType,
  CustomContentStatus,
  StatusColor,
} from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { RowFormField } from "../../../layout/FormLayout";

export class CustomContentDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      id: value.id || 0,
      code: value.code || "",
      title: value.title || "",
      value: value.value || "",
      isPage: value.isPage || false,
      isActive: value.isActive || false,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  getStatusColor = (status) => {
    if (status === CustomContentStatus.Active) return StatusColor.Green;
    if (status === CustomContentStatus.Inactive) return StatusColor.Red;
  };

  render() {
    return (
      <Card title="CustomContent Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const { id, code, title, value, isPage, isActive } = this.state.model;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <RowFormField label="Id:" htmlFor="customContentId">
              <input
                className="form-control display-input"
                type="text"
                id="customContentId"
                placeholder="Enter Id"
                readOnly={true}
                value={id}
              />
            </RowFormField>
            <RowFormField htmlFor="status" label="Status:">
              <input
                className={`form-control display-input ${this.getStatusColor(
                  isActive
                    ? CustomContentStatus.Active
                    : CustomContentStatus.Inactive
                )}`}
                type="text"
                id="status"
                placeholder="Enter Status"
                readOnly={true}
                value={CustomContentStatus.getCustomContentStatus(
                  isActive
                    ? CustomContentStatus.Active
                    : CustomContentStatus.Inactive
                )}
              />
            </RowFormField>
          </div>
          <div className="col-md-6">
            <RowFormField htmlFor="pageCode" label="Page Code:">
              <input
                className="form-control display-input"
                id="pageCode"
                placeholder="Enter Code"
                readOnly={true}
                value={code}
              />
            </RowFormField>
            <RowFormField label="Page Type:" htmlFor="pageType">
              <input
                className="form-control display-input"
                id="pageType"
                placeholder="Enter Type"
                readOnly={true}
                value={CustomContentType.getCustomContentType(
                  isPage ? CustomContentType.Page : CustomContentType.Content
                )}
              />
            </RowFormField>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <RowFormField label="Title:" htmlFor="contentTitle">
              <input
                className="form-control display-input"
                type="text"
                id="contentTitle"
                placeholder="Enter Title"
                readOnly={true}
                value={title}
              />
            </RowFormField>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="content">
              Content:
            </label>
            <textarea
              className="form-control display-input resize-none border-light"
              id="content"
              rows="20"
              placeholder="Enter Content"
              maxLength="12000"
              readOnly={true}
              value={value}
            ></textarea>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { id, code, title, value, isPage, isActive } = this.state.model;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <RowFormField label="Id:" htmlFor="customContentId">
              <input
                className="form-control display-input"
                type="text"
                id="customContentId"
                placeholder="Enter Id"
                readOnly={true}
                value={id}
              />
            </RowFormField>
            <RowFormField label="Status:" htmlFor="status">
              <input
                className={`form-control display-input ${this.getStatusColor(
                  isActive
                    ? CustomContentStatus.Active
                    : CustomContentStatus.Inactive
                )}`}
                type="text"
                id="status"
                placeholder="Enter Status"
                readOnly={true}
                value={CustomContentStatus.getCustomContentStatus(
                  isActive
                    ? CustomContentStatus.Active
                    : CustomContentStatus.Inactive
                )}
              />
            </RowFormField>
          </div>
          <div className="col-md-6">
            <RowFormField label="Page Code:" htmlFor="pageCode">
              <input
                className="form-control display-input"
                id="pageCode"
                placeholder="Enter Code"
                readOnly={true}
                value={code}
              />
            </RowFormField>
            <RowFormField label="Page Type:" htmlFor="pageType">
              <input
                className="form-control display-input"
                id="pageType"
                placeholder="Enter Type"
                readOnly={true}
                value={CustomContentType.getCustomContentType(
                  isPage ? CustomContentType.Page : CustomContentType.Content
                )}
              />
            </RowFormField>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <RowFormField label="Title:" htmlFor="contentTitle">
              <input
                className="form-control display-input"
                type="text"
                id="contentTitle"
                onChange={this.onInputChange}
                placeholder="Enter Title"
                fieldname="title"
                value={title}
              />
            </RowFormField>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="content">
              Content:
            </label>
            <textarea
              rows="20"
              placeholder="Enter Content"
              className={this.getControlClassName("value", "resize-none")}
              fieldname="value"
              onChange={this.onTextBoxChange}
              value={value}
            ></textarea>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="value"
              isEnabled={isSubmit}
              property="Content"
              value={value}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
