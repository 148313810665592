import React from 'react';
import { Link } from 'react-router-dom';
import { AuthenticationManager, ResourceID } from '../common/AuthenticationManager';
import './css/MenuSidebar.scss';

export class MenuSidebarDocumentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: this.filterMenu(this.createMenu()),
    }
  }

  componentDidMount() {
    const selectedMenu = this.findMenu(this.state.menus, window.location.pathname);
    this.onMenuClick({}, selectedMenu.selectedPath, selectedMenu.selectedLabel);
  }

  createMenu() {
    return [
      {
        path: '#', label: 'Users', icon: 'fas fa-user-cog', children: [
          { path: '/documentation/users/createUser', label: 'Create a New User', icon: 'fas fa-bookmark', },
          { path: '/documentation/users/updateUser', label: 'Update a User', icon: 'fas fa-bookmark', },
          { path: '/documentation/users/activateDeactivateUser', label: 'Activate/Deactivate a User', icon: 'fas fa-bookmark', },
          { path: '/documentation/users/resetUser', label: 'Reset User Password', icon: 'fas fa-bookmark', },
        ]
      },
      {
        path: '#', label: 'eVouchers', icon: 'fas fa-money-check-alt', children: [
          { path: '/documentation/eVouchers/createCancelOrder', label: 'Create/Cancel eVouchers Orders', icon: 'fas fa-file-invoice-dollar', },
          { path: '/documentation/eVouchers/createEvoucherBatch', label: 'Create eVoucher Batch', icon: 'fas fa-file-invoice-dollar', },
          { path: '/documentation/eVouchers/approveCancelRejectBatch', label: 'Approve/Cancel/Reject Batch', icon: 'fas fa-file-invoice-dollar', },
          { path: '/documentation/eVouchers/extendDownloadeVoucherBatch', label: 'Extend Validity of eVoucher Batch/Download eVoucher Batch Codes', icon: 'fas fa-edit', },
          { path: '/documentation/eVouchers/extendEvoucherValidity', label: 'Extend Validty of eVouchers', icon: 'fas fa-list-alt', },
          { path: '/documentation/eVouchers/blockEvoucher', label: 'Block Evoucher', icon: 'fas fa-list-alt', }
        ]
      },
    ]
  }

  filterMenu(menu) {
    const newMenu = []
    for (let i = 0; i < menu.length; i++) {
      const menuItem = menu[i];
      const isAuthorized = AuthenticationManager.isAuthorized(menuItem.resourceId);
      if (menuItem.children) {
        const children = this.filterMenu(menuItem.children)
        if (isAuthorized && children.length > 0) {
          newMenu.push({
            path: menuItem.path,
            label: menuItem.label,
            icon: menuItem.icon,
            resourceId: menuItem.resourceId,
            children: children,
          })
        }
      }
      else if (isAuthorized) {
        newMenu.push({
          path: menuItem.path,
          label: menuItem.label,
          icon: menuItem.icon,
          resourceId: menuItem.resourceId,
        })
      }
    }
    return newMenu
  }

  findMenu(menuItems, path) {
    let result = {
      selectedPath: '',
      selectedLabel: ''
    };
    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];
      if (menuItem.children) {
        let subResult = this.findMenu(menuItem.children, path);
        if (subResult.selectedPath.length > result.selectedPath.length) {
          result.selectedPath = subResult.selectedPath;
          result.selectedLabel = subResult.selectedLabel;
        }
      }
      else if (path.toLowerCase().indexOf(menuItem.path) === 0) {
        if (menuItem.path.length > result.selectedPath.length) {
          result.selectedPath = menuItem.path;
          result.selectedLabel = menuItem.label;
        }
      }
    }
    return result;
  }

  onMenuClick = (e, selectedPath, selectedLabel) => {
    let menus = this.state.menus.map(menu => {
      this.updateMenu(menu, selectedPath, selectedLabel);
      return menu;
    });
    this.setState({ menus });
    if (selectedPath === '#') {
      e.preventDefault();
      return false;
    }
    return true;
  }

  updateMenu(menu, selectedPath, selectedLabel) {
    let found = menu.path === selectedPath && menu.label === selectedLabel;
    if (menu.children) {
      menu.isExpanded = found && !menu.isExpanded;
      let childrenFound = menu.children.reduce((result, child) => this.updateMenu(child, selectedPath, selectedLabel) || result, false);
      if (childrenFound) {
        found = childrenFound;
        menu.isExpanded = true;
      }
    } else {
      menu.isActive = found;
    }
    return found;
  }

  render() {
    return (
      <aside className="menu-sidebar d-none d-lg-block">
        <div className="logo">
          <Link to="/">
            <img src="img/shaw-icon-white.png" alt="Shaw Theatres" />
          </Link>
        </div>
        <div className="menu-sidebar__content js-scrollbar1">
          <nav className="navbar-sidebar">
            <ul className="list-unstyled navbar__list">
              {this.state.menus.map(menu => this.renderMenu(menu))}
            </ul>
          </nav>
        </div>
      </aside>
    )
  }

  renderMenu(menu) {
    return (
      <li key={menu.path + menu.label} className={`${menu.children ? 'has-sub' : ''} ${menu.isActive ? 'active' : ''}`}>
        <Link className={`js-arrow ${menu.children && menu.isExpanded ? 'open' : ''}`} to={menu.path} onClick={(e) => this.onMenuClick(e, menu.path, menu.label)}><i aria-hidden="true" className={menu.icon} />{menu.label}</Link>
        {menu.children &&
          <ul className={`list-unstyled navbar__sub-list js-sub-list ${menu.children && menu.isExpanded ? 'open' : ''}`}>
            {menu.children.map(sub => this.renderMenu(sub))}
          </ul>
        }
      </li>
    )
  }
}
