import React, { Component } from "react";
import { Navigation } from "./Navigation";
import Loader from "../components/common/Loader";
import { DesktopHeader } from "./layout/DesktopHeader";
import { NewVersionNotice } from "./settings/NewVersionNotice";

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSidebarCollapsed: false,
    };
  }

  componentDidMount() {
    window.addEventListener("storage", this.handleLoadingChange);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleLoadingChange);
  }

  handleLoadingChange = () => {
    const isLoadingValue = localStorage.getItem("isLoading") === "true";
    this.setState({
      isLoading: isLoadingValue,
    });
  };

  toggleSidebar = () => {
    this.setState(prevState => ({
      isSidebarCollapsed: !prevState.isSidebarCollapsed,
    }));
  };

  render() {
    const { isLoading } = this.state;
    return (
      <NewVersionNotice>
        <div className="app">
          {isLoading && <Loader />}
          <Navigation toggleSidebar={this.toggleSidebar} />
          <div className={`page-container ${this.state.isSidebarCollapsed ? 'collapsed' : ''}`}>
            <DesktopHeader />
            {this.props.children}
          </div>
        </div>
      </NewVersionNotice>
    );
  }
}
