import React from "react";
import { CouponTitleType } from "../../../../constants/CampaignConstants";

export const CouponSlip = (props) => {
  const { instance } = props;
  const { description, termCondition, labelDisplay } = instance;
  const title = CouponTitleType.generateTitleExample(instance);
  return (
    <div className="card preview-slipbg mb-2 coupon-preview">
      <div className="card-body pb-0">
        <h4 className="card-title">
          {labelDisplay}
          <br />
          <small id="couponName">{title}</small>
        </h4>

        <div className="row">
          <div className="col-4" style={{ paddingLeft: 0 }}>
            <img className="w-100" src="/img/testqrcode.png" />
          </div>
          <div className="col-8 coup-preview-detail">
            <small
              style={{
                fontSize: "70%",
                display: "block",
                lineHeight: "17px",
              }}
              className="coup-details"
            >
              {description}
            </small>
          </div>
          <small
            className="col-4 pl-0 text-center"
            style={{ marginTop: "-18px" }}
          >
            C213
          </small>
        </div>

        <div className="row mt-3" style={{ fontSize: "80%" }}>
          <div className="col-sm-6">
            Pay Type :<label className="payTypeLabel">cash</label>
          </div>
          <div className="col-sm-6 text-right">
            Valid Till :<label className="payTypeLabel">19/06/2019</label>
          </div>
        </div>
        <div className="row pb-2 ml-0" style={{ fontSize: "90%" }}>
          <small id="coup-terms1">{termCondition}</small>
        </div>
        <div className="row">
          <div className="col-sm-6">
            <small>SHAW THEATRES PTE LTD</small>
          </div>

          <div className="col-sm-6">
            <small className="float-right">Co. Reg. No: 194700158G</small>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <small>S2E89B0000E29</small>
          </div>
          <div className="col-sm-5">
            <small>19/06/2019 12:03 PM</small>
          </div>
          <div className="col-sm-3">
            <small style={{ float: "right" }}>testlido.cso</small>
          </div>
        </div>
      </div>
    </div>
  );
};
