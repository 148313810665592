import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ConfirmScheduleDialog } from "../../common/ConfirmScheduleDialog";
import { HistoryDialog } from "../../common/HistoryDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { FilmFestivalDetailCard } from "./cards/FilmFestivalDetailCard";
import { ProgrammeCard } from "./cards/ProgrammeCard";
import { FilmFestivalPreview } from "./preview/FilmFestivalPreview";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class FilmFestivalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      festival: null,
      festivalId: "",
      generalInfo: null,
      generalInfoValidations: [],
      programmeInfo: [],
      cachesReleases: [],
      programmeInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
      showScheduleDialog: false,
      isEdit: true,
      isUpdate: false,
      showHistoryDialog: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const festivalId = isNaN(id) ? 0 : Number(id);
    if (isNaN(id) || festivalId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ festivalId }, this.refreshData);
  }

  componentDidUpdate() {
    if (this.state.isUpdate) {
      this.setState({ isUpdate: false });
      this.refreshData();
    }
  }

  isValidated() {
    const { generalInfoValidations, programmeInfoValidations } = this.state;
    return (
      generalInfoValidations.every((val) => val.isValid) &&
      programmeInfoValidations.every((val) => val.isValid)
    );
  }

  loadFestival(id) {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/film_festivals/${id}`,
      { includes: "FilmFestivalContents" },
      (response) => {
        this.setState({ festival: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onEditFestivalClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onChangeEdit = (value) => {
    this.setState({ isEdit: value });
  };

  onChangeCacheReleases = (value) => {
    this.setState({ cachesReleases: value });
  };

  onProgrammeInfoModelChange = (model) => {
    this.setState({ programmeInfo: model });
  };

  onProgrammeInfoValidationsChange = (validations) => {
    this.setState({ programmeInfoValidations: validations });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = model.displayStartTimeStr;
    const displayEndTime = model.displayEndTimeStr;
    const displayWeight = isNaN(model.displayWeight)
      ? 0
      : Number(model.displayWeight);
    const newModel = Object.assign(model, {
      displayStartTime,
      displayEndTime,
      displayWeight,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onHideScheduleDialog = () => {
    this.setState({ showScheduleDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      isUpdate: true,
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    const { festivalId, generalInfo, programmeInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo,
      programmeInfo
    );

    RestClient.sendPutRequest(
      `/api/v1/film_festivals/${festivalId}`,
      request,
      (response) => {
        toast.success(GenericMessages.PublishChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          festival: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onProceedScheduleDialog = (e) => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showScheduleDialog: false });
      return;
    }
    const { festivalId, generalInfo, programmeInfo } = this.state;
    const request = Object.assign(
      {       
        liveFilmFestivalId: festivalId,
      },
      e,
      generalInfo,
      { programmes: programmeInfo.filmFestivalContents }
    );

    RestClient.sendPostRequest(
      `/api/v1/scheduled_film_festivals/`,
      request,
      (response) => {
        toast.success(
          GenericMessages.CreateScheduleSuccessfulMessage(response.name)
        );
        this.setState({
          cardMode: CardMode.View,
          festival: { ...this.state.festival, filmFestivalContents: response.programmes },
          isSubmit: false,
          showScheduleDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showScheduleDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    this.setState({ showConfirmDialog: true });
  };

  onShowScheduleDialog = () => {
    this.setState({ showScheduleDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  refreshData() {
    const { festivalId } = this.state;
    this.loadFestival(festivalId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/film_festivals/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "film_festivals",
        name: "Film Festivals",
      }),
      activeModule: new NavigationItem({
        identifier: "film-festival-details",
        name: CardMode.isViewMode(cardMode)
          ? "View Film Festival"
          : "Edit Film Festival",
      }),
    });
  };

  render() {
    const {
      cardMode,
      festival,
      isSubmit,
      isEdit,
      generalInfo,
      programmeInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showScheduleDialog,
      cachesReleases,
      showHistoryDialog,
    } = this.state;
    if (showPreview) {
      const model = Object.assign(
        {},
        generalInfo,
        programmeInfo
      );
      return (
        <>
          <CancelPreviewButton onClick={this.onHidePreview} />
          <FilmFestivalPreview
            festival={model}
            programmeInfo={cachesReleases}
          />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex justify-content-end">
                    {AuthenticationManager.isAuthorized(
                      ResourceID.FilmFestivalEditButtonPage
                    ) && (
                        <button
                          className="btn btn-primary ml-1"
                          value="Edit"
                          disabled={festival ? undefined : "disabled"}
                          onClick={this.onEditFestivalClick}
                        >
                          <i className="fas fa-edit" /> Edit
                        </button>)
                    }
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <FilmFestivalDetailCard
                  cardMode={cardMode}
                  defaultValue={festival}
                  isEdit={isEdit}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
                <ProgrammeCard
                  displayStart={generalInfo?.displayStartTime}
                  displayEnd={generalInfo?.displayEndTime}
                  cardMode={cardMode}
                  defaultValue={festival}
                  onEditableChange={this.onChangeEdit}
                  onChangeCacheReleases={this.onChangeCacheReleases}
                  onModelChange={this.onProgrammeInfoModelChange}
                  isSubmit={isSubmit}
                  model={programmeInfo}
                  onValidationsChange={this.onProgrammeInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                {AuthenticationManager.isAuthorized(
                  ResourceID.FilmFestivalScheduleButton
                ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={this.onShowScheduleDialog}
                    >
                      Schedule
                    </ButtonActionDetailGroup.Item>)
                }
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && festival}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && festival}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showScheduleDialog && festival && (
          <ConfirmScheduleDialog
            onCancel={this.onHideScheduleDialog}
            onProceed={this.onProceedScheduleDialog}
            message={GenericMessages.ScheduleChangesDialogMessage(
              "film festival"
            )}
            title={GenericMessages.ScheduleChangesDialogTitle}
          />
        )}
        {showHistoryDialog && (
          <HistoryDialog
            objectId={festival.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.FilmFestival}
          />
        )}
      </div>
    );
  }
}
