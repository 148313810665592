import React, { useState } from "react";
import { FieldWrapper } from "../../FieldWrapper";
import { TextInput } from "../../TextInput";
import RestClient from "../../../../common/RestClient";
import { campaignBundlesAtom } from "../../CampaignState";
import { useRecoilState } from "recoil";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import _ from "lodash";
import { toast } from "react-toastify";
import { TicketTypes } from "../../../../constants/PricingConstants";
import { DiscountMode } from "../../../../constants/CampaignConstants";
import { Card } from "../../../common/Card";

export const CampaignBundleTicketItem = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const { instance, idx, parentidx } = props;

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  const onDelete = () => {
    if (_.isNil(instance) || instance.id <= 0) {
      return;
    }
    RestClient.sendDeleteRequest(
      `/api/v1/campaign_bundle_tickets/${instance.id}`,
      () => {
        const changeableCampaignBundles = _.cloneDeep(campaignBundles);
        const bundle = changeableCampaignBundles.find(
          (x) => x.id === instance.campaignBundleId
        );
        bundle.campaignBundleTickets = bundle.campaignBundleTickets.filter(
          (x) => x.id !== instance.id
        );
        setCampaignBundles(changeableCampaignBundles);
        toast.success(
          "The campaign bundle ticket has been deleted successful."
        );
      },
      handleError
    );
    setShowDeleteModal(false);
  };

  return (
    <Card
      title="Ticket Details"
      idx={idx}
      parentidx={parentidx}
    >
      <div className="card-body bundle0-product0-details">
        <div className="d-flex flex-row justify-content-end">
          <button
            className="btn btn-danger"
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            <i className="fas fa-trash-alt" aria-hidden="true" />
          </button>
        </div>
        <div className="row form-group">
          <div className="col-xl-6">
            <FieldWrapper
              label="Service"
              additionalCss="form-group"
              input={<TextInput readOnly={true} value={instance.serviceCode} />}
            />
            <FieldWrapper
              label="Ticket Type"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={TicketTypes.getDisplay(instance.ticketType)}
                />
              }
            />
            <FieldWrapper
              label="Quantity"
              additionalCss="form-group"
              input={<TextInput readOnly={true} value={instance.quantity} />}
            />
            <FieldWrapper
              label="Discount Mode"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={DiscountMode.getName(instance.discountMode)}
                />
              }
            />
          </div>
          <fieldset className="col-xl-6 card card-body mx-3 mx-xl-0 pt-0 mb-0">
            <legend className="col-auto" style={{ fontSize: "16px" }}>
              Note: All prices are per unit (SGD)
            </legend>
            <FieldWrapper
              label="Sales Amount"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={instance.salesAmount.toFixed(6)}
                />
              }
            />
            <FieldWrapper
              label="Discount Amount"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={instance.discountAmount.toFixed(6)}
                />
              }
            />
            <FieldWrapper
              label="Reimbursement Amount"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={instance.reimbursementAmount.toFixed(6)}
                />
              }
            />
            <FieldWrapper
              label="Docket Amount"
              additionalCss="form-group"
              input={
                <TextInput
                  readOnly={true}
                  value={instance.docketAmount.toFixed(6)}
                />
              }
            />
          </fieldset>
        </div>
      </div>
      <ConfirmDialog
        visible={showDeleteModal}
        message="Are you sure you want to delete the campaign bundle ticket?"
        onProceed={onDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};
