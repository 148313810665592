import React, { useEffect, useState } from "react";
import { CustomSelect } from "../../common/CustomSelect";
import { TicketingApplication } from "../../../constants/PerformanceConstants";
import { PerformanceSelectorService } from "../../../services/PerformanceSelectorService";
import { toast } from "react-toastify";
import { RowFormField } from "../../layout/FormLayout";
import _ from "lodash";

const PerformanceSelectorsRestrictionModal = (props) => {
  const [selectedLocation, setSelectedLocation] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState([]);

  const getRequestBody = (form) => {
    const { stationLocationId } = Object.fromEntries(new FormData(form));
    return {
      stationLocationId,
      performanceSelectorIds: props.selectedSelectorIds,
      allowedTicketingApplications: selectedApplication.reduce(
        (acc, cur) => acc + cur,
        0
      ),
    };
  };

  const resetForm = () => {
    setSelectedLocation(0);
    setSelectedApplication([]);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const request = getRequestBody(form);
    new PerformanceSelectorService()
      .applyRestrictions(request)
      .then((response) => {
        toast.success(`${response.length} Performance selector updated!`);
        props.onSuccess(response);
        resetForm();
      });
  };

  useEffect(() => {
    if (props.data && !_.isEmpty(props.data)) {
      const { stationLocationId, allowedTicketingApplications } = props.data;
      setSelectedLocation(stationLocationId);
      setSelectedApplication(allowedTicketingApplications);
    }
  }, [props.data]);

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      id="performance-restriction-modal"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-md modal-dialog-centered"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Performance Selector Restrictions
            </h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              data-bs-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <div className="px-3">
                <RowFormField label="Station Location">
                  <select
                    className="form-control"
                    name="stationLocationId"
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(+e.target.value)}
                  >
                    <option value={0}>All</option>
                    {(props.locations || []).map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </RowFormField>
                <RowFormField label="Ticketing Applications:">
                  <CustomSelect
                    data={TicketingApplication.All.filter((x) => x.value)}
                    label="name"
                    value="value"
                    isMulti={true}
                    onChange={(e) =>
                      setSelectedApplication(e.map((x) => x.value))
                    }
                    selectedValue={selectedApplication}
                  />
                </RowFormField>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success"
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PerformanceSelectorsRestrictionModal;
