import React, { Component } from "react";
import { UpdatePriceCriteria } from "../../../constants/PricingConstants";
import _ from "lodash";
import { PriceUpdateModel } from "../../../models/PriceUpdateModel";
import { RowFormField } from "../../layout/FormLayout";

export class PriceUpdateModal extends Component {
  constructor() {
    super();
    this.state = {
      model: new PriceUpdateModel(),
    };
  }

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let value = e.target.value;
    const model = this.state.model;
    if (_.isEqual("number", valueType) && !isNaN(+value)) {
      value = +value;
    }

    model[fieldName] = value;
    this.setState({ model });
  };

  render() {
    const { model } = this.state;
    const { onUpdate, onCancel } = this.props;
    const enableUpdate = model.isValid();
    return (
      <div
        className="modal fade show"
        id="UpdateModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Update Pricing
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onCancel()}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Criteria:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control"
                    name="updateType"
                    valuetype="number"
                    onChange={this.onInputChange}
                  >
                    <option value="0">Select a criteria</option>
                    {UpdatePriceCriteria.All.map((x) => (
                      <option key={x.value} value={x.value}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      Value:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Enter value"
                    name="salesAmount"
                    valuetype="number"
                    onChange={this.onInputChange}
                  />
                </RowFormField>
                <RowFormField label={<span>Effective Start:<span className="color-red">*</span></span>}>
                  <input
                    className="form-control"
                    type="datetime-local"
                    value={model.effectiveStart}
                    name="effectiveStart"
                    onChange={this.onInputChange}
                  />
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  disabled={!enableUpdate}
                  onClick={() => onUpdate(model)}
                >
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() => onCancel()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
