import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledContestListing extends BaseListing {
  getApiPath = () => "/api/v1/scheduled_contests";

  getDefaultSort = () => "-PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "contests",
        name: "Contests",
      }),
      activeModule: new NavigationItem({
        identifier: "scheduled-contests",
        name: "Scheduled Contest",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/contests/schedules/${id}`);
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Name</th>
            <th>Contest Start</th>
            <th>Contest End</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>Display Weight</th>
            <th>Updated On</th>
            <th>
              Published On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PublishedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.contestStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.contestEndTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndTime
                    )}
                  </td>
                  <td>{item.weightDisplay}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.publishedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </React.Fragment>
    );
  }
}
