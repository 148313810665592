import React, { Component } from "react";
import '../css/documentation.scss';

export class ExtendEvoucherValidity extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Extend Validty of eVouchers</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 10 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    To extend the validity of the eVouchers, click a record with the <strong>Active</strong> status found in the eVoucher listing page. Navigate to this page with the left navbar.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/eVoucher.png" class="userImage" /><br /><br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/eVoucherListing.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    After selecting a record, you will be redirected to the View eVoucher details page. To extend the validity, click the ‘Extend Validity’ button found in the top right of the page.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/eVoucherExtendValidityDetails.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    Upon clicking the button, the following will appear. To extend the validity, user is requested to fill in the ‘New Expiry Date’ and ‘Reason’ field. After that, click ‘Confirm’.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/extendrequestprompt.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    If the action was successful, the following prompt is will be shown:<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/extendrequesttoast.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    After this, the page will have a new action button ‘View Pending Request’. Click on the button to be directed to the newly created eVoucher extension request detail page.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/pendingrequestBtn.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    In the extension request detail page, you can select either ‘Approve’ or ‘Reject’ action button to approve or reject the extension request.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/evoucherextenddetails.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Clicking ‘Approve’ will throw the following prompt. To approve the extension request, click ‘Confirm’.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/approveprompt.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    When the extension request is approved, the status is then changed to ‘Approved’.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/afterapproval.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Clicking ‘Reject’ will display the following prompt. To reject the extension request, click ‘Confirm’ after filling in the ‘Reason’ field.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/rejectprompt.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    When the extension request is rejected, the status is then changed to ‘Rejected’.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/afterreject.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/extendDownloadEvoucherBatch" className="btn btn-primary">&laquo; Extend/Download EVoucher Batch Codes</a>&nbsp;
                                <a href="/documentation/evouchers/blockEvoucher" className="btn btn-primary">Block EVoucher &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
