import React from "react";
import _ from "lodash";

export const TextInput = (props) => {
  const { name, value, onChange, placeholder, readOnly, disable, additionalCssClasses } = props;

  if (!_.isNil(readOnly) && readOnly) {
    return (
      <input
        type="text"
        className={`form-control display-input ${additionalCssClasses ?? ''}`}
        value={_.unescape(value)}
        readOnly
      />
    );
  }
  return (
    <input
      type="text"
      className={`form-control ${additionalCssClasses ?? ''}`}
      name={name}
      value={_.unescape(value)}
      onChange={onChange}
      placeholder={placeholder ?? `Enter ${_.capitalize(name)}`}
      disabled={disable}
    />
  );
};
