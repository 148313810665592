import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingLocationAreaCodesAtom } from "./Report.state";

const QUERY_KEY_REPORTING_LOCATION_AREA_CODES =
  "QUERY_KEY_REPORTING_LOCATION_AREA_CODES";

const CATEGORY_TYPES = ["TKTC", "HYBC", "KIOSK"];

export function useReportingLocationAreaCodes() {
  const [locationAreaCodes, setLocationAreaCodes] = useRecoilState(
      reportingLocationAreaCodesAtom
  );
  useQuery(
    [...CATEGORY_TYPES, QUERY_KEY_REPORTING_LOCATION_AREA_CODES],
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/location_areas"
      ).list({ categoryTypes: CATEGORY_TYPES.join(",") });
    },
    {
      onSuccess: (response) => setLocationAreaCodes(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return locationAreaCodes;
}
