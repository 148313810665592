import _ from "lodash";
import moment from "moment";
import { DateHelper } from "../common/Helpers";
import { CampaignBundle } from "./CampaignBundle";
import {
  CampaignQuantityAvailabilityItem,
  CampaignRedemptionLocation,
  CampaignSalesChannel,
} from "./CampaignAvailability";
import { QuantityLimitType } from "../constants/CampaignConstants";

export class Campaign {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.code = data?.code ?? "";
    this.name = data?.name ?? "";
    this.displayName = data?.displayName ?? "";
    this.description = data?.description ?? "";
    this.termCondition = data?.termCondition ?? "";
    this.startOn = data?.startOn ?? this.getDefaultStartOn();
    this.endOn = data?.endOn ?? this.getDefaultEndOn();
    this.ePromotionCode = data?.ePromotionCode ?? null;
    this.category = data?.category ?? 0;
    this.restriction = data?.restriction ?? 0;
    this.posterUrl = data?.posterUrl ?? "";
    this.displayWeight = data?.displayWeight ?? 1;
    this.bundleCount = data?.bundleCount ?? 0;
    this.cumulativeQuantityLimit = data?.cumulativeQuantityLimit ?? 1;
    this.status = data?.status ?? 0;
    this.isUnlimited = data?.isUnlimited ?? true;
    this.validForSpecificClassification =
      data?.validForSpecificClassification ?? false;
    this.validForSpecificLocationVenue =
      data?.validForSpecificLocationVenue ?? false;
    this.validForSpecificCreditCard = data?.validForSpecificCreditCard ?? false;
    this.validForSpecificTicketType = data?.validForSpecificTicketType ?? false;
    this.validForSpecificPerformance =
      data?.validForSpecificPerformance ?? false;
    this.validForSpecificDayTime = data?.validForSpecificDayTime ?? false;
    this.validForSpecificMovie = data?.validForSpecificMovie ?? false;
    this.validForSpecificBrandFormat =
      data?.validForSpecificBrandFormat ?? false;
    this.hasCustomTicket = data.hasCustomTicket ?? false;
    this.validForMidnightShows = data.validForMidnightShows ?? false;
    this.validForOpeningShows = data.validForOpeningShows ?? false;
    this.validOnPublicHoliday = data.validOnPublicHoliday ?? false;
    this.validOnPublicHolidayEve = data.validOnPublicHolidayEve ?? false;
    this.validOnBlackoutHoliday = data.validOnBlackoutHoliday ?? false;
    this.validOnSubstituteHoliday = data.validOnSubstituteHoliday ?? false;
    this.validForCrossSale = data.validForCrossSale ?? false;
    this.validForAdvanceSales = data.validForAdvanceSales ?? false;
    this.isStackCampaign = data.isStackCampaign ?? false;
    this.quantityMechanicsType = data.quantityMechanicsType ?? 0;
    this.quantityLimitType =
      data.quantityLimitType ?? QuantityLimitType.NoResetCycle;
    this.resetDay = data.resetDay ?? 0;
    this.loyaltyProgram = data.loyaltyProgram ?? "";
    this.loyaltyRedemptionQuantityPerCard =
      data.loyaltyRedemptionQuantityPerCard ?? 0;
    this.loyaltyRedemptionQuantityType =
      data.loyaltyRedemptionQuantityType ?? 0;
    this.maxRedemptionQuantityPerTransaction =
      data.maxRedemptionQuantityPerTransaction ?? 0;
    // Because reset day start from Sunday(0) to Saturday(6)
    this.loyaltyCardResetDay = data.loyaltyCardResetDay ?? -1;

    this.hasEPromotionCode = !_.isEmpty(this.ePromotionCode);
    this.campaignBundles = this.convertCampaignBundles(data);
    this.campaignSalesChannels = this.convertCampaignSalesChannel(data);
    this.campaignRedemptionLocations =
      this.convertCampaignRedemptionLocations(data);
    this.campaignQuantityAvailability =
      this.convertCampaignQuantityAvailability(data);
  }

  convertCampaignBundles(data) {
    return (data?.campaignBundles ?? []).map((x) => new CampaignBundle(x));
  }

  convertCampaignSalesChannel(data) {
    return (data?.campaignSalesChannels ?? []).map(
      (x) => new CampaignSalesChannel(x)
    );
  }

  convertCampaignQuantityAvailability(data) {
    return (data?.campaignQuantityAvailability ?? []).map(
      (x) => new CampaignQuantityAvailabilityItem(x)
    );
  }

  convertCampaignRedemptionLocations(data) {
    return (data?.campaignRedemptionLocations ?? []).map(
      (x) => new CampaignRedemptionLocation(x)
    );
  }

  getDefaultStartOn() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return moment(currentDate).format(DateHelper.IsoDateTimeFormat);
  }

  getDefaultEndOn() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    return moment(currentDate).format(DateHelper.IsoDateTimeFormat);
  }
}
