import React from "react";

const TransactionInformationVouchers = (props) => {
  const { vouchers } = props;

  if (!vouchers || vouchers.length === 0) return <></>;
  
  return (
    <>
      {vouchers.map((item, index) => (
        <div className="card" key={index}>
          <div className="card-body card-block">
            <div className="row m-0">
              <div className="col-md-4 mr-auto">
                <label className="form-control-label row" id="Title">
                  {item.voucherName}
                </label>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-row justify-content-end">
                  <label className="form-control-label mr-2" id="Seats">
                    {item.voucherNo}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TransactionInformationVouchers;