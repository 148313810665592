import _ from "lodash";

export class NavigationSettings {
  constructor(data) {
    this.rootModule = _.isNil(data.rootModule)
      ? null
      : new NavigationItem(data.rootModule);
    this.parentModule = _.isNil(data.parentModule)
      ? null
      : new NavigationItem(data.parentModule);
    this.activeModule = new NavigationItem(data.activeModule ?? {});
  }
}

export class NavigationItem {
  constructor(data) {
    this.identifier = data.identifier ?? "";
    this.name = data.name ?? "";
  }
}
