import _ from "lodash";
import { Days } from "../constants/PricingConstants";

export class Service {
  constructor(response) {
    this.id = response.id ?? 0;
    this.name = response.name ?? "";
    this.code = response.code ?? "------------";
    this.performanceType = response.performanceType ?? 0;
    this.ticketBrand = response.ticketBrand ?? 0;
    this.ticketFormat = response.ticketFormat ?? 0;
    this.daySelection = response.daySelection ?? 0;
    this.serviceTickets = response.serviceTickets ?? [];
    this.customDayTime = response.customDayTime ?? new CustomDayTime();
    this.performanceTimeStartOn = response.performanceTimeStartOn ?? "";
    this.performanceTimeEndOn = response.performanceTimeEndOn ?? "";
    this.isActive = response.isActive ?? false;
  }

  isValid() {
    return (
      !_.isEmpty(this.name) &&
      this.performanceType > 0 &&
      this.ticketBrand > 0 &&
      this.ticketFormat > 0 &&
      this.daySelection > 0
    );
  }
}

export class CustomDayTime {
  constructor() {
    this.startDay = Days.Monday;
    this.startTime = "00:00";
    this.endDay = Days.Monday;
    this.endTime = "23:59";
  }
}
