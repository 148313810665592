import React from "react";
import { DateHelper } from "../../../../common/Helpers";

export const EmailPreview = (props) => {
  const { visible, corporateBooking, performance, onClose } = props;

  if (!visible) {
    return null;
  }

  return (
    <div
      className="modal fade show"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      style={{ display: "block" }}
    >
      <div
        className="modal-dialog modal-md"
        role="document"
        style={{ maxWidth: "700px", height: "95%" }}
      >
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header">
            <h5 className="modal-title">Email Preview</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div
            className="modal-body"
            style={{ height: "100%", overflow: "auto" }}
          >
            <div className="container-fluid">
              <table
                className="v1MsoNormalTable"
                style={{ maxWidth: "6.25in" }}
                cellspacing="3"
                cellpadding="0"
                border="0"
              >
                <tbody>
                  <tr>
                    <td style={{ padding: "0in 0in 0in 0in" }}>
                      <table
                        className="v1MsoNormalTable"
                        style={{ width: "100%", background: "#616161" }}
                        width="100%"
                        cellspacing="3"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td style={{ padding: "11.25pt 22.5pt 0in 7.5pt" }}>
                              <div>
                                <p className="v1MsoNormal">
                                  <a
                                    href="https://shaw.sg/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <img
                                      style={{
                                        maxWidth: "3.1666in",
                                        maxHeight: ".8333in",
                                      }}
                                      id="v1Picture_x0020_1"
                                      src="/img/shaw-icon.png"
                                      width="304"
                                      height="80"
                                      border="0"
                                    />
                                  </a>
                                  <span className="v1MsoHyperlink" />
                                </p>
                              </div>
                              <p className="v1MsoNormal">&nbsp;</p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "11.25pt 22.5pt 0in 22.5pt",
                                minWidth: "300px",
                              }}
                            >
                              <p className="v1MsoNormal">
                                <span
                                  style={{
                                    fontSize: "16.5pt",
                                    color: "#E0E0E0",
                                  }}
                                >
                                  Shaw Theatres Movie Ticket Confirmation
                                </span>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                padding: "0in 22.5pt 15.0pt 22.5pt",
                                minWidth: "300px",
                              }}
                            >
                              <p className="v1MsoNormal">
                                <span
                                  style={{
                                    fontSize: "12.0pt",
                                    color: "#E0E0E0",
                                  }}
                                >
                                  Booking Reference No.{" "}
                                  {corporateBooking.bookingReference}
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "0in 0in 0in 0in" }}>
                      <table
                        className="v1MsoNormalTable"
                        style={{
                          width: "100%",
                          background: "#3D0203",
                          borderBottomLeftRadius: "4px",
                          borderBottomRightRadius: "4px",
                        }}
                        width="100%"
                        cellspacing="3"
                        cellpadding="0"
                        border="0"
                      >
                        <tbody>
                          <tr>
                            <td style={{ padding: "0in 22.5pt 0in 22.5pt" }}>
                              <table
                                className="v1MsoNormalTable"
                                style={{ minWidth: "300px" }}
                                cellspacing="3"
                                cellpadding="0"
                                border="0"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      style={{ padding: "0in 0in 0in 0in" }}
                                    />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{ padding: "0in 0in 15.0pt 0in" }}
                                    >
                                      <p className="v1MsoNormal">
                                        <span
                                          style={{
                                            fontSize: "14.5pt",
                                            color: "#B9B9B9",
                                          }}
                                        >
                                          Hi &lt;Patron's Name&gt;
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "none",
                                        borderBottom: "solid #E0E0E0 1.0pt",
                                        padding: "3.0pt 0in 3.0pt 0in",
                                      }}
                                    >
                                      <p className="v1MsoNormal">
                                        <span
                                          style={{
                                            fontSize: "11.5pt",
                                            color: "white",
                                          }}
                                        >
                                          <b>
                                            You can now proceed directly to the
                                            Usher point to enter the hall with
                                            the QR code below. No tickets need
                                            to be printed for this booking for
                                            entry.
                                          </b>
                                        </span>
                                        <span
                                          style={{
                                            fontSize: "11.5pt",
                                            color: "white",
                                          }}
                                        >
                                          <br />
                                          <br />
                                          <img
                                            className="mb-2"
                                            id="v1qrcode_S311F80049BAC"
                                            src="/img/qrcode.png"
                                            alt="Transaction Number: S311F80049BAC"
                                            width="580"
                                            height="580"
                                            border="0"
                                          />
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="pb-2"
                                      style={{ padding: "0in 0in 0in 0in" }}
                                    />
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        width: "100%",
                                        padding: "0in 0in 3.0pt 0in",
                                      }}
                                      width="100%"
                                    >
                                      <p
                                        className="v1MsoNormal"
                                        style={{ lineHeight: "15.0pt" }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "14.0pt",
                                            color: "white",
                                          }}
                                        >
                                          <b>{performance.primaryTitle}</b>
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{ padding: "0in 0in 3.0pt 0in" }}
                                    >
                                      <p className="v1MsoNormal">
                                        <span
                                          style={{
                                            fontsize: "14.0pt",
                                            color: "#F2F2F2",
                                          }}
                                        >
                                          {DateHelper.toDisplayDateTimeFormat(
                                            performance.actualDateTime
                                          )}
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{ padding: "0in 0in 15.0pt 0in" }}
                                    >
                                      <p className="v1MsoNormal">
                                        <span
                                          style={{
                                            fontSize: "14.0pt",
                                            color: "#F2F2F2",
                                          }}
                                        >
                                          {performance.locationVenue.name}
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{ padding: "0in 0in 3.0pt 0in" }}
                                    >
                                      <p className="v1MsoNormal">
                                        <span
                                          style={{
                                            fontSize: "14.0pt",
                                            color: "#F2F2F2",
                                          }}
                                        >
                                          Seat No(s): &lt;Seat Codes&gt;
                                        </span>
                                      </p>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        border: "none",
                                        borderBottom: "solid #E0E0E0 1.0pt",
                                        padding: "11.25pt 0in 3.0pt 0in",
                                      }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: "0in 0in 0in 0in" }}>
                                      <table
                                        className="v1MsoNormalTable"
                                        style={{ width: "100%" }}
                                        width="100%"
                                        cellspacing="3"
                                        cellpadding="0"
                                        border="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                padding:
                                                  "15.0pt .75pt .75pt .75pt",
                                              }}
                                            >
                                              <p className="v1MsoNormal">
                                                <span
                                                  style={{
                                                    fontSize: "12.0pt",
                                                    color: "#B9B9B9",
                                                  }}
                                                >
                                                  Thank you for choosing Shaw
                                                  Theatres! We look forward to
                                                  seeing you at our cinemas!
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding:
                                                  "15.0pt .75pt .75pt .75pt",
                                              }}
                                            >
                                              <p className="v1MsoNormal">
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      "&quot;Yu Gothic UI&quot;,sans-serif",
                                                    color: "white",
                                                  }}
                                                >
                                                  We wish to highlight the
                                                  following:
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding:
                                                  "15.0pt .75pt .75pt .75pt",
                                              }}
                                            >
                                              <p className="v1MsoNormal">
                                                <u>
                                                  <span
                                                    style={{ color: "white" }}
                                                  >
                                                    No refunds, cancellation or
                                                    exchanges
                                                  </span>
                                                </u>
                                                <span
                                                  style={{ color: "white" }}
                                                >
                                                  <br />
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: "9.0pt",
                                                    fontFamily:
                                                      "&quot;Yu Gothic UI&quot;,sans-serif",
                                                    color: "white",
                                                  }}
                                                >
                                                  Kindly note that no refunds,
                                                  cancellations or exchanges
                                                  will be entertained.
                                                </span>
                                                <span
                                                  style={{ color: "white" }}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding:
                                                  "22.5pt .75pt .75pt .75pt",
                                              }}
                                            >
                                              <p className="v1MsoNormal">
                                                <u>
                                                  <span
                                                    style={{
                                                      fontSize: "9.0pt",
                                                      color: "FFE699",
                                                    }}
                                                  >
                                                    Be early for your movie!
                                                  </span>
                                                </u>
                                                <span
                                                  style={{
                                                    fontSize: "9.0pt",
                                                    color: "#FFE699",
                                                  }}
                                                >
                                                  <br />
                                                </span>
                                                <b>
                                                  <span
                                                    style={{
                                                      fontSize: "9.0pt",
                                                      fontFamily:
                                                        "&quot;Yu Gothic UI&quot;,sans-serif",
                                                      color: "#FFE699",
                                                    }}
                                                  >
                                                    Due to SafeEntry to malls
                                                    and premise, we strongly
                                                    encourage patrons to be
                                                    early for the movie.
                                                    <br />
                                                    Thank you for the kind
                                                    understanding &amp; we hope
                                                    you have a great movie
                                                    experience at our theatres.
                                                  </span>
                                                </b>
                                                <span
                                                  style={{
                                                    fontSize: "9.0pt",
                                                    color: "#FFE699",
                                                  }}
                                                />
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                padding:
                                                  "7.5pt .75pt .75pt .75pt",
                                              }}
                                            >
                                              <p className="v1MsoNormal">
                                                <span
                                                  style={{
                                                    fontSize: "9.0pt",
                                                    color: "#6A6A6A",
                                                  }}
                                                >
                                                  Shaw Theatres GST Reg No.
                                                  194700158G
                                                </span>
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};