import React, { useState } from "react";
import _ from "lodash";
import { Languages } from "../../../constants/MovieConstants";
import {RowFormField} from "../../layout/FormLayout";

export const AddLanguageModal = (props) => {
  const [language, setLanguage] = useState("");

  if (!props.visible) {
    return null;
  }

  const disableAddBtn = _.isEmpty(language);

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Movie Language</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={props.onCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField label={<span>Movie Language:<span style={{ color: "red" }}>*</span></span>}>
                  <select
                    className="form-control"
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="">Select Language</option>
                    {Languages.All.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </RowFormField>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={disableAddBtn}
                onClick={() => {
                  props.onProceed(language);
                  props.onCancel();
                }}
              >
                <i className="fas fa-plus-sign" aria-hidden="true" />
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={props.onCancel}
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};