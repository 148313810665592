import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { CareerDetailCard } from "./cards/CareerDetailCard";
import { CareerPreview } from "./preview/CareerPreview";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const EditPageName = "Edit Career";
const ViewPageName = "View Career";

export class CareerDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      career: null,
      careerId: "",
      generalInfo: null,
      generalInfoValidations: [],
      showPreview: false,
      showHistoryDialog: false,
      showCancelDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const careerId = StringHelper.toNumber(id);
    if (isNaN(id) || careerId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ careerId }, this.refreshData);
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadCareer(id) {
    RestClient.sendGetRequest(
      `/api/v1/careers/${id}`,
      (response) => {
        this.setState({ career: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onEditCareerClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      career: Object.assign({}, this.state.career),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { careerId, generalInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/careers/${careerId}`,
      request,
      (response) => {
        toast.success(GenericMessages.PublishChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          career: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  refreshData() {
    const { careerId } = this.state;
    this.loadCareer(careerId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/careers/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "careers",
        name: "Careers",
      }),
      activeModule: new NavigationItem({
        identifier: "career-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      career,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showHistoryDialog,
    } = this.state;
    if (showPreview) {
      const model = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <CareerPreview career={model} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    {
                      AuthenticationManager.isAuthorized(
                        ResourceID.CareerEditButtonPage
                      ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={career ? undefined : "disabled"}
                          onClick={this.onEditCareerClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>)
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <CareerDetailCard
                  cardMode={cardMode}
                  defaultValue={career}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && career}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && career}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId={career.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.Career}
          />
        )}
      </div>
    );
  }
}
