import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { RefundStatus } from "../../../constants/RefundConstants";
import { BaseListing } from "../../common/BaseListing";
import _ from "lodash";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ApplicationID } from "../../../common/Constants";

export class TransactionRefundListing extends BaseListing {
  constructor() {
    super();
    this.selectedStatuses = [];
    this.searchText = "";
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "transaction-history",
        name: "Transaction History",
      }),
      activeModule: new NavigationItem({
        identifier: "refunds",
        name: "Transaction Refund",
      }),
    });
  };

  getDefaultSort = () => "-CreatedOn";

  getApiPath = () => "/api/v1/refunds";

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Transaction Mode</th>
            <th>Amount</th>
            <th>Requested By</th>
            <th>Status</th>
            <th onClick={this.onColumnClick}>
              Last Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th onClick={this.onColumnClick}>
              Created On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{ApplicationID.getName(item.appId)}</td>
                  <td>{item.amount ? item.amount.toFixed(6) : ""}</td>
                  <td>{item.createdBy}</td>
                  <td>
                    <span className={this.getStatusStyle(item.status)}>
                      {RefundStatus.getName(item.status)}
                    </span>
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.createdOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  async componentDidMount() {
    await super.componentDidMount();
  }

  injectSearchTerm(queryParameters, searchBy) {
    return super.injectSearchTerm(
      queryParameters,
      this.createSearchParameters()
    );
  }

  generateExtendedComponents() {
    return <>{this.generateSearchComponent()}</>;
  }

  generateSearchComponent() {
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body card-block text-left pending-approved">
              <div className="row mb-2">
                <div className="col text-nowrap" style={{ flexGrow: "0" }}>
                  <label className="form-control-label" htmlFor="TransStatus">
                    Refund Status:
                  </label>
                </div>
                <div className="col-md-3 mr-auto">
                  <div
                    className="rs-select2--dark rs-select2--md m-r-10 rs-select2--border"
                    style={{ width: "100%" }}
                  >
                    <select
                      className="form-control"
                      name="search"
                      onChange={(e) => this.onChangeStatus(e)}
                      required
                    >
                      <option value="">All Status</option>
                      {RefundStatus.All.map((x) => (
                        <option value={x.value} key={x.value}>
                          {x.name}
                        </option>
                      ))}
                    </select>
                    <div className="dropDownSelect2" />
                  </div>
                </div>
                <div className="col-md-4 pr-0">
                  <input
                    className="form-control search"
                    type="Text"
                    placeholder="Enter transaction number"
                    onChange={(e) => (this.searchText = e.target.value)}
                    onKeyDown={this.onKeyDownSearchInput}
                  />
                </div>
                <button
                  className="btn btn-primary search"
                  style={{ marginRight: "15px" }}
                  onClick={this.search}
                >
                  <i className="fas fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getStatusStyle(status) {
    const map = {
      pending: "color-orange",
      rejected: "color-red",
    };
    const statusName = RefundStatus.getName(status);
    if (!statusName) {
      return "color-gray";
    }
    let normalizedStatus = statusName.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  }

  onChangeStatus = (e) => {
    let value = e.target.value;
    if (!value) {
      this.selectedStatuses = [
        RefundStatus.Pending,
        RefundStatus.Approved,
        RefundStatus.SubmittedToBank,
        RefundStatus.Successful,
        RefundStatus.Processed,
        RefundStatus.Rejected,
        RefundStatus.Issued,
        RefundStatus.Voided,
      ];
    } else {
      this.selectedStatuses = [+value];
    }
  };

  createStatusFiltering() {
    if (this.selectedStatuses.length === 0) return null;
    return this.selectedStatuses.join(",");
  }

  createSearchParameters() {
    const referenceNo = this.props.match.params.referenceNumber;
    const result = {
      referenceNumber: referenceNo,
    };
    let statuses = this.createStatusFiltering();
    if (!_.isEmpty(statuses)) {
      result.status = statuses;
    }
    return result;
  }
}
