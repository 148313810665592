import React from "react";
import { PromotionCategory } from "../../../../common/Constants";
import { ImageHelper } from "../../../../common/Helpers";

export const ContestPreview = (props) => {
  const contest = props.contest || {};
  return (
    <div className="main-content promotions-detail-content">
      <div className="container">
        <div className="row">
          <div className="handlebar-promotion-head">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="content-wrap p-d row">
                  <div className="col-md-4 col-xs-12">
                    <div className="block-tablet hide-for-desktop display_none_ie7">
                      <h2 title={contest.name}>{contest.name}</h2>
                    </div>
                    <div className="block-img">
                      <a
                        className="image-overlay-box cboxElement"
                        href={ImageHelper.getPosterUrl(contest.poster)}
                      >
                        <img
                          src={ImageHelper.getPosterUrl(contest.poster)}
                          alt={contest.name}
                          onError={ImageHelper.onPosterError}
                          className="img-responsive gm-loaded gm-observing gm-observing-cb"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="col-md-8 col-xs-12">
                    <div className="block-tablet-desktop show-for-desktop-only">
                      <h2 title={contest.name}>{contest.name}</h2>
                    </div>
                    <div className="col-right p-l-20">
                      <div className="block-info">
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={{
                            __html: contest.description,
                          }}
                        ></div>
                        {contest.category === PromotionCategory.Contest &&
                          contest.contestEmbedUrl
                          && (
                            <iframe src={contest.contestEmbedUrl}
                              height="440"
                              width="100%"
                            />
                          )}
                        <div
                          className="term"
                          dangerouslySetInnerHTML={{
                            __html: contest.termCondition,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
