import React, { useEffect, useState } from "react";
import RestClient from "../../../common/RestClient";
import { DateHelper } from "../../../common/Helpers";
import { DayOfWeek } from "../../../constants/CampaignConstants";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignGlobalRefreshAtom,
  isSelectedPerformanceConditionsAtom,
  validForSpecificDayTimeSelector,
} from "../CampaignState";
import { DayTimeConditionDialog } from "../dialogs/DayTimeConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const DayTimeConditionCard = (props) => {
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const isSelectedPerformance = useRecoilValue(
    isSelectedPerformanceConditionsAtom
  );

  const globalRefresh = useRecoilValue(campaignGlobalRefreshAtom);
  const validForSpecificDayTime = useSetRecoilState(
    validForSpecificDayTimeSelector
  );
  const { isEdit } = props;
  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificDayTime);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/day_times",
      { campaignId: campaign.id },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh, globalRefresh]);

  const onCreate = (dateTimeCondition, isValidForFullBusinessDay) => {
    const params = {
      ...dateTimeCondition,
      isValidForFullBusinessDay: isValidForFullBusinessDay,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/day_times",
      params,
      (_) => {
        setRefresh((r) => r + 1);
        validForSpecificDayTime(true);
        toast.success(
          "The campaign day time condition has been created successful."
        );
        setShowCreateDialog(false);
      },
      handleError
    );
  };

  const onDeleteElement = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/day_times/${selectedId}`,
      () => {
        setRefresh((r) => r + 1);
        toast.success("The day time condition has been deleted successful.");
        validForSpecificDayTime(data.length > 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_day_times_conditions`,
      () => {
        toast.success("All day time conditions have been deleted successful.");
        setRefresh((ref) => ref + 1);
        validForSpecificDayTime(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label htmlFor="input1" className="col-sm-2 col-form-label">
          Days & Time Condition
        </label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="DayAndTimeCondition"
              id="DayAndTimeConditionRbNo"
              value="No"
              checked={!showTable}
              onClick={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="DayAndTimeConditionRbNo"
            >
              No day and time restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="DayAndTimeCondition"
              id="DayAndTimeConditionRbYes"
              value="Yes"
              checked={showTable}
              onClick={() => setShowTable(true)}
            />
            <label
              className="form-check-label"
              htmlFor="DayAndTimeConditionRbYes"
            >
              Valid for specific day and time
            </label>
            <button
              type="button"
              id="DayAndTimeConditionbtn1"
              name="DayAndTimeCondition"
              data-toggle="modal"
              data-target="#DayAndTimeConditionModal"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th>Day of Week</th>
              <th>Start Time</th>
              <th>End of Time</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{DayOfWeek.getName(item.dayOfWeek)}</td>
                    <td>
                      {item.timeStart &&
                        DateHelper.toDisplayShortTimeFormat(item.timeStart)}
                    </td>
                    <td>
                      {item.timeEnd &&
                        DateHelper.toDisplayShortTimeFormat(item.timeEnd)}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setShowDeleteConfirmation(true);
                          setSelectedId(item.id);
                        }}
                        disabled={!isEdit}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onDeleteElement}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
      <DayTimeConditionDialog
        visible={showCreateDialog}
        onConfirm={onCreate}
        onCancel={() => setShowCreateDialog(false)}
      />
    </>
  );
};
