import React from "react";
import { DateHelper } from "../../../../common/Helpers";
import { EVoucherExtensionStatus } from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import { RowFormField } from "../../../layout/FormLayout";

export class ExtensionRequestInformationCard extends React.Component {
  renderView(evoucherExtension) {
    return (
      <div className="row">
        <div className="col-md-8">
          <RowFormField label="Status:" htmlFor="status">
            <input
              className={
                "form-control display-input " +
                EVoucherExtensionStatus.getColor(evoucherExtension.status)
              }
              type="text"
              id="status"
              value={evoucherExtension.status}
              readOnly
            />
          </RowFormField>
          <RowFormField label="New Expiry Date:" htmlFor="new-expiry">
            <input
              className="form-control display-input"
              type="text"
              id="new-expiry"
              value={DateHelper.toDisplayDateTimeFormat(
                evoucherExtension.newValidityEnd
              )}
              readOnly
            />
          </RowFormField>
          <RowFormField
            className="Reason For Extension:"
            htmlFor="reason-extension"
          >
            <input
              className="form-control display-input"
              type="text"
              id="reason-extension"
              value={evoucherExtension.extensionReason}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Created By:" htmlFor="created-by">
            <input
              className="form-control display-input"
              type="text"
              id="created-by"
              value={evoucherExtension.createdBy}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Created On:" htmlFor="created-on">
            <input
              className="form-control display-input"
              type="text"
              in="created-on"
              value={DateHelper.toDisplayDateTimeFormat(
                evoucherExtension.createdOn
              )}
              readOnly
            />
          </RowFormField>
          {evoucherExtension.status === EVoucherExtensionStatus.Rejected && (
            <RowFormField
              label="Reason For Rejection:"
              htmlFor="reason-rejection"
            >
              <input
                className="form-control display-input"
                type="text"
                id="reason-rejection"
                value={evoucherExtension.approvalReason}
                readOnly
              />
            </RowFormField>
          )}
          {evoucherExtension.status === EVoucherExtensionStatus.Cancelled && (
            <RowFormField
              label="Reason For Cancellation:"
              htmlFor="reason-cancellation"
            >
              <input
                className="form-control display-input"
                type="text"
                id="reason-cancellation"
                value={evoucherExtension.approvalReason}
                readOnly
              />
            </RowFormField>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { evoucherExtension } = this.props;
    return (
      evoucherExtension && (
        <Card title="Extension Request Information">
          {this.renderView(evoucherExtension)}
        </Card>
      )
    );
  }
}
