import { RestService } from "./RestService";

export class CacheClearingService extends RestService {
  constructor() {
    super("/api/v1/cached_data");
  }

  async clear(source, key) {
    return await this.postImpl("clear", { key, source });
  }

  async getInternalGroupKeys() {
    return await this.getImpl("group_keys/internal");
  }

  async getExternalGroupKeys() {
    return await this.getImpl("group_keys/external");
  }
}
