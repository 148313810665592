import React, { useState } from 'react';
import _ from 'lodash'
import { TextInput } from "../campaigns/TextInput";
import { FieldWrapper } from "../campaigns/FieldWrapper";
import { toast } from "react-toastify";
import { RestService } from '../../services/RestService';
import { YesNoOptions } from '../../common/Constants';


export const ConfirmResendEmailDialog = (props) => {
  const { message, title, visible, onCancel, transaction } = props;
  const [isSameEmail, setIsSameEmail] = useState(true);
  const [newEmail, setNewEmail] = useState("");
  if (!_.isNil(visible) && !visible) {
    return null;
  }

  const send = async () => {
    new RestService(`api/v1/transactions/${transaction.id}/send_email`).post(
      { emailAddress: newEmail }
    ).then((response) => {
      if (response) {
        toast.success("The email address is resent successfully.");
        onCancel();
      }
    });
  }

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {title || "Resend Email"}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {message || "Use the same email?"}
              <div className="row form-group">
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="isSameEmail"
                      value={YesNoOptions.Yes}
                      checked={isSameEmail}
                      onChange={() => setIsSameEmail(true)}
                    />
                    <label className="form-check-label">Yes</label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="isSameEmail"
                      value={YesNoOptions.No}
                      checked={!isSameEmail}
                      onChange={() => setIsSameEmail(false)}
                    />
                    <label className="form-check-label">No</label>
                  </div>
                </div>
              </div>
              <FieldWrapper
                label="New Email"
                visible={!isSameEmail}
                input={
                  <TextInput
                    name="name"
                    value={newEmail}
                    placeholder="Enter New Email"
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                }
              />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={send}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
}
