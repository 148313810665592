import { RestService } from "./RestService";

export class EVoucherExtensionRequestRestService extends RestService {
  constructor() {
    super("api/v1/evoucher_extensions");
  }

  getDetails(id) {
    return this.getImpl(`${id}/details`);
  }

  approve(id, payload) {
    return this.postImpl(`${id}/approve`, payload);
  }

  reject(id, payload) {
    return this.postImpl(`${id}/reject`, payload);
  }

  cancel(id, payload) {
    return this.postImpl(`${id}/cancel`, payload);
  }
}
