import React from 'react';
import Validators from '../../../common/Validators';
import { StringHelper } from "../../../../common/Helpers";
import { AuthenticationManager } from '../../../../common/AuthenticationManager';

export class ExtendBatchValidityDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newExpireDate:'',
            reason:'',
            validations:[],
            isSubmitted : props.isSubmitted,
            eVoucherBatchId: props.eVoucherBatchId,
            currentUser: props.currentUser
        };
    }

    onTexboxChange = (e) => {
        const fieldName = e.target.getAttribute('fieldname');
        this.setState({ [fieldName]: e.target.value });
        this.setState({ isSubmitted: true });
    }

    isValidated() {
        const { validations } = this.state;
        return validations.every(val => val.isValid);
    }

    onSubmitClick = () => {   
        if (!this.isValidated()) {
            this.setState({ isSubmitted: true});
            return;
        }
        else{
            if (this.props.onProceed) {
                const { currentExpiredDate } = this.props;
                const { newExpireDate, eVoucherBatchId, reason } = this.state;
                const request = Object.assign({ newValidityEndDate: newExpireDate, evoucherBatchId: eVoucherBatchId, reason: reason, currentValidityEndDate: currentExpiredDate });
                this.props.onProceed(request);
            }
        }
    }

    onValidationsChange = () => {
        const { onValidationsChange } = this.props;
        if (onValidationsChange) {
            onValidationsChange([...this.state.validations]);
        }
    }

    onValidationStatusChange = (e) => {
        const { validations } = this.state;
        let validation = validations.find(val => val.fieldName === e.fieldName && val.type === e.type);
        if (validation) {
            validation.isValid = e.isValid;
            validation.message = e.message;
        } else {
            validations.push(e);
        }
        this.setState({ validations }, this.onValidationsChange);
    }

    render() {
        const { newExpireDate, reason, isSubmitted } = this.state;
        const {currentExpiredDate} = this.props;
        return (
            <>
                <div className="modal fade show" id="extendBatchValidity" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Create Extension Request</h5>
                                <button type="button" className="close" aria-label="Close" onClick={this.props.onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row form-group">                                    
                                    <label className="col-md-5 form-control-label" htmlFor="currentExpiryValue">Current Expiry Date:</label>
                                    <div className="col-md-7">                            
                                    <input className="display-input Date" id="currentExpiryValue" value={StringHelper.asDateStringToDisplayDateTimeFormat(currentExpiredDate)} readOnly />
                                    </div>
                                </div>   
                                <div className="row form-group">                                    
                                    <label className="col-md-5 form-control-label" htmlFor="newExpireDate">New Expiry Date:</label>
                                    <div className="col-md-7">                            
                                        <input className="form-control display-input" type="date" id="newExpireDate" value={newExpireDate} fieldname="newExpireDate" 
                                            onChange={this.onTexboxChange}/>

                                        <Validators.RequiredValidator onValidationStatusChange={this.onValidationStatusChange} fieldname="newExpireDate" 
                                            isEnabled={isSubmitted} property="Validity Start" value={newExpireDate}/>

                                        <Validators.DateTimeFromToValidator onValidationStatusChange={this.onValidationStatusChange} fieldname="newExpireDate" 
                                            isEnabled={isSubmitted} fromProperty="New Expiry Date" fromValue={currentExpiredDate} toProperty="Current Expiry Date" 
                                            toValue={newExpireDate} />
                                    </div>
                                </div>
                                <div className="row form-group">                                    
                                    <label className="col-md-5 form-control-label" htmlFor="reason">Reason:<span className="color-red">*</span></label>  
                                    <div className="col-md-7">                            
                                    <textarea id="Reason" rows="4" placeholder="Enter reason for evoucher extension" id="reason" fieldname="reason" className="form-control" onChange={this.onTexboxChange} value={reason} />                               
                                    </div>
                                </div>                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={this.onSubmitClick}>Confirm</button>
                                <button type="button" className="btn btn-danger" onClick={this.props.onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        )
    }
}
