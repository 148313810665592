import React, { Component } from "react";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="main-content">
        <div className="breadcrumb">
          <div>Dashboard</div>
          <ol className="right">
            <li>
              <a>Dashboard</a>
              <span className="divider">/</span>
            </li>
            <li>
              <a>Main</a>
            </li>
          </ol>
        </div>
        <div className="section__content section__content--p30">
          <div className="container-fluid" style={{ textAlign: "center" }}>
            <h2 style={{ color: "orange", fontSize: "42px" }}>Welcome!</h2>
            Click on any page to start
          </div>
        </div>
      </div>
    );
  }
}
