import React, { useEffect, useState } from "react";
import RestClient from "../../../../common/RestClient";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../common/CustomSelect";
import { CompanyType } from "../../../../constants/CompanyType";

export const ClientSelectionModal = (props) => {
  const [client, setClient] = useState(null);
  const [data, setData] = useState([]);
  const { onSelect, onCancel, visible } = props;

  useEffect(() => {
    RestClient.sendGetRequest(
      "api/v1/corporate_booking_companies",
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, []);

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onConfirm = () => {
    if (onSelect) {
      onSelect(client);
    }
  };

  const onChange = (e) => {
    const found = data.find((x) => x.id === e.value);
    setClient(found);
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        id="chooseCustomer"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Choose Existing Client</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row form-group">
                  <div className="col-md-12">
                    <CustomSelect
                      data={data}
                      label="name"
                      value="id"
                      selectedValue={client?.id ?? 0}
                      onChange={onChange}
                    />
                  </div>
                </div>
                {client && (
                  <>
                    <div className="row form-group">
                      <div className="col-md-9">
                        <label className="form-control-label">
                          Company Code:
                        </label>
                        <input
                          className="form-control display-input"
                          type="text"
                          value={client.code}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-9">
                        <label className="form-control-label">
                          Company Name:
                        </label>
                        <input
                          className="form-control display-input"
                          value={client.name}
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-9">
                        <label className="form-control-label">
                          Client Name:
                        </label>
                        <input
                          className="form-control display-input"
                          value={client.clientName}
                          type="text"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-9">
                        <label className="form-control-label">
                          Company Type:
                        </label>
                        <input
                          className="form-control display-input"
                          type="text"
                          value={CompanyType.getName(client.companyType)}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-9 ">
                        <label className="form-control-label">
                          Contact Number:
                        </label>
                        <input
                          className="form-control display-input"
                          type="tel"
                          value={client.contactNumber}
                          inputMode="tel"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-9">
                        <label className="form-control-label">
                          Email Address:
                        </label>
                        <input
                          className="form-control display-input"
                          type="email"
                          inputMode="email"
                          value={client.emailAddress}
                          readOnly
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                onClick={onConfirm}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
