import React, { Component } from "react";
import '../css/documentation.scss';

export class EditContestHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Contest Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 7 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Contests” link. This will take you to the Contest Listing Page. <br />
                                    <img src="/img/documentation-img/ContestImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Contest Listing Page, click on any contest listing of your choice. This will take you to the View Contest Details. <br />
                                    <img src="/img/documentation-img/ContestImg/listing_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Contest Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Contest Code
                                        </li>
                                        <li>
                                            Contest Name
                                        </li>
                                        <li>
                                            Contest Category
                                        </li>
                                        <li>
                                            Contest Start
                                        </li>
                                        <li>
                                            Contest End
                                        </li>
                                        <li>
                                            Display Start
                                        </li>
                                        <li>
                                            Display End
                                        </li>
                                        <li>
                                            Contest Description
                                        </li>
                                        <li>
                                            Contest Terms and Conditions
                                        </li>
                                        <li>
                                            Contest Image
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Contest Q&A Link
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start Date should always be earlier than Display End Date.<br />
                                            - The value in Display Start must precede value in Contest Start.<br />
                                            - The value in Contest End must precede value in Display End.<br />
                                            - Contest Description and Contest Terms and Conditions should be in HTML format.<br />
                                </div>
                            </li>
                                        <li class="createList">
                                            To edit the contest listing, click on the “Edit” button in the top right corner of the page. <br />
                                            <img src="/img/documentation-img/ContestImg/details_edit.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            In Edit Contest Page, you will be able to see the following fields:
                                            <ul class="unorderedList">
                                                <li>
                                                    Contest Code
                                                </li>
                                                <li>
                                                    Contest Name
                                                </li>
                                                <li>
                                                    Contest Category
                                                </li>
                                                <li>
                                                    Contest Start
                                                </li>
                                                <li>
                                                    Contest End
                                                </li>
                                                <li>
                                                    Display Start
                                                </li>
                                                <li>
                                                    Display End
                                                </li>
                                                <li>
                                                    Contest Description
                                                </li>
                                                <li>
                                                    Contest Terms and Conditions
                                                </li>
                                                <li>
                                                    Contest Image
                                                </li>
                                                <li>
                                                    Display Weight
                                                </li>
                                                <li>
                                                    Contest Q&A Link
                                                </li>

                                            </ul>
                                            <div class="alert alert-primary" role="alert">
                                                <i class="fas fa-info-circle" aria-hidden="true"></i>
                                                <strong class="noteHeader">Information:</strong><br/><br/>
                                                    - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                                    - Display Start Date should always be earlier than Display End Date.<br />
                                                    - The value in Display Start must precede value in Contest Start.<br />
                                                    - The value in Contest End must precede value in Display End.<br />
                                                    - Contest Description and Contest Terms and Conditions should be in HTML format.<br />
                                                    - Contest Description and Contest Terms and Conditions have a maximum of 12000 characters.
                                </div>
                            </li>
                                                <li class="createList">
                                                    To preview the contest listing, click “Preview” at the bottom right corner of the page. <br />
                                                    <img src="/img/documentation-img/ContestImg/edit_preview.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To cancel the preview, click on “Cancel Preview” at the top right corner of the page. <br />
                                                    <img src="/img/documentation-img/ContestImg/preview_cancelpreview.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To save your changes, click on “Save Changes” at the bottom right corner of the page. <br />
                                                    <img src="/img/documentation-img/ContestImg/edit_savechanges.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                                                    <img src="/img/documentation-img/ContestImg/edit_save_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Upon successful save, a confirmation message will appear at the bottom right of the screen. <br />
                                                    <img src="/img/documentation-img/ContestImg/edit_success_toaster.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To cancel all changes, click on the “Cancel” button at the bottom right corner. <br />
                                                    <img src="/img/documentation-img/ContestImg/edit_cancel.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To confirm that you want to cancel all changes, click on "Confirm".<br />
                                                    <img src="/img/documentation-img/ContestImg/edit_cancel_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To schedule a contest, click on the “Schedule” button at the bottom right corner.<br />
                                                    <img src="/img/documentation-img/ContestImg/edit_schedule.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Select your date and time for publishing and click on “Confirm” to schedule the contest for publishing.<br />
                                                    <img src="/img/documentation-img/ContestImg/edit_schedule_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Upon successful scheduling, a confirmation message will appear at the bottom right of the screen.<br />
                                                    <img src="/img/documentation-img/ContestImg/edit_success_toaster.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To view the scheduled listing, follow the steps listed below.
                                                    <ol class="alphaList">
                                                        <li>
                                                            Using the menu on the left, click on "Website Management", then click on the “Contests” link. This will take you to the Contest Listing Page.<br />
                                                            <img src="/img/documentation-img/ContestImg/navbar.png" class="userImage" />
                                                        </li>
                                                        <li>
                                                            To view a scheduled contest, click on “Scheduled Publishing” in the top right corner of the Contest Listing Page.<br />
                                                            <img src="/img/documentation-img/ContestImg/listing_scheduledpublishing.png" class="userImage" />
                                                        </li>
                                                        <li>
                                                            When you return to the scheduled contests listing page, you will see the changes made.<br />
                                                            <img src="/img/documentation-img/ContestImg/scheduled_success_endpoint.png" class="userImage" />
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li class="createList">
                                                    To view history changes, click on the "History” button, at the top right corner of the page.<br />
                                                    <img src="/img/documentation-img/ContestImg/details_history.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    A popup will appear, stating the changes made. Click on “View Details” to see more.<br />
                                                    <img src="/img/documentation-img/ContestImg/history_modal.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    A pop-up will appear, stating the old and new vaues. <br />
                                                    <img src="/img/documentation-img/ContestImg/history_details.png" class="userImage" />
                                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/contests/CreateContestHelp" className="btn btn-primary">&laquo; Create Contests</a>&nbsp;
                                <a href="/documentation/contests/ViewScheduledContestHelp" className="btn btn-primary">View Scheduled Contests &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}