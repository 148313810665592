import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import { formatMovieVoucherCategoryName } from "../common/WebsiteManagementConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledVoucherTypeListing extends BaseListing {
  getApiPath = () => "/api/v1/scheduled_voucher_types";

  getDefaultSort = () => "-PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "movie_vouchers",
        name: "Movie Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "movie_vouchers",
        name: "Scheduled Movie Voucher",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/movie_vouchers/schedules/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>Display Weight</th>
            <th>Updated On</th>
            <th onClick={this.toggleSort}>
              Published On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PlanToPublish"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>{formatMovieVoucherCategoryName(item.category)}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.displayStartTime
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.displayEndTime
                      )}
                    </td>
                    <td>{item.weightDisplay}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.updatedOn
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.planToPublish
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }
}
