import React from "react";

export const RowFormField = (props) => {
  return (
    <div className={`row form-group ${props.className || ''}`}>
      <label
        className="form-control-label col-12 col-md-6 col-xl-4"
        htmlFor={props.htmlFor}
      >
        {props.label}
      </label>
      <div className="col-12 col-md-6 col-xl-8">{props.children}</div>
    </div>
  );
};

export const ColumnFormField = (props) => {
  return (
    <div className={`col-12 col-md-6 mb-3 ${props.className || ''}`}>
      {props.label && (
        <label className="form-control-label" htmlFor={props.htmlFor}>
          {props.label}&nbsp;
        </label>
      )}
      {props.children}
    </div>
  );
};
