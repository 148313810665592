import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BaseListing } from "../../common/BaseListing";
import { Strings } from "../../../common/Common";
import { DateHelper } from "../../../common/Helpers";
import { Link } from "react-router-dom";
import _ from "lodash";
import { TicketTypes } from "../../../constants/PricingConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ServicesListing extends BaseListing {
  constructor() {
    super();
    this.state = { search: "" };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "services",
        name: "Services",
      }),
    });
  };

  getApiPath = () => "/api/v1/services";

  getDefaultSort = () => "-UpdatedOn";

  getTicketTypeNames = (service) => {
    if (_.isNil(service) || _.isNil(service.serviceTickets)) {
      return "";
    }
    return service.serviceTickets
      .map((x) => TicketTypes.getDisplay(x.ticketType))
      .join(", ");
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Ticket Types</th>
            <th>
              Updated On
              <i
                className="fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{this.getTicketTypeNames(item)}</td>
                  <td>{DateHelper.formatDateTimeString(item.updatedOn)}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  injectSearchTerm(queryParameters, searchBy) {
    return super.injectSearchTerm(
      queryParameters,
      this.createSearchParameters()
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.ServiceCreateButtonPage
      ) && (
        <div className="d-flex flex-row justify-content-end mb-3">
          <Link
            className="btn btn-primary"
            to="/services/create"
            style={{ fontSize: "1rem" }}
          >
            <i className="fas fa-plus-circle" /> Create New Service
          </Link>
        </div>
      )
    );
  }

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col row" />
        <div className="col-md-6 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="name"
            placeholder="Enter key word"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={(e) => this.setState({ search: e.target.value })}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  createSearchParameters() {
    const { search } = this.state;
    const data = {
      includes: "ServiceTickets",
    };
    if (!Strings.isNullOrEmpty(search)) {
      data.search = search;
    }
    return data;
  }
}
