import React, { useEffect, useState } from "react";
import { VoucherProgramType, VoucherStatus } from "../../../common/Constants";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
import { toast } from "react-toastify";
import { StringHelper } from "../../../common/Helpers";
import { ColumnFormField } from "../../layout/FormLayout";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const VoucherDetail = ({ match: { params } }) => {
  const { id } = params;
  const [model, setModel] = useState({});
  const [performance, setPerformance] = useState({});
  const [transaction, setTransaction] = useState({});
  const [redemptionLocation, setRedemptionLocation] = useState({});
  const [location, setLocation] = useState({});
  const [locationVenue, setLocationVenue] = useState({});
  const [showHistoryDialog, setShowHistoryDialogModel] = useState(false);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `api/v1/vouchers/${id}`,
      { includes: "VoucherProgram" },
      (response) => {
        setModel(response);
      },
      (error) => toast.error(error.message)
    );
  }, [id]);

  useEffect(() => {
    if (!model.performanceId || model.performanceId === 0) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/performances/${model.performanceId}`,
      (response) => {
        setPerformance(response);
      },
      (error) => toast.error(error.message)
    );
  }, [model]);

  useEffect(() => {
    if (!performance || !performance.locationId) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/cached_data/locations/${performance.locationId}`,
      (response) => {
        setRedemptionLocation(response);
      },
      (error) => toast.error(error.message)
    );
  }, [performance]);

  useEffect(() => {
    if (!transaction || !transaction.locationId) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/cached_data/locations/${transaction.locationId}`,
      (response) => {
        setLocation(response);
      },
      (error) => toast.error(error.message)
    );
  }, [transaction]);

  useEffect(() => {
    if (!performance || !performance.locationVenueId) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/cached_data/location_venues/${performance.locationVenueId}`,
      (response) => {
        setLocationVenue(response);
      },
      (error) => toast.error(error.message)
    );
  }, [performance]);

  useEffect(() => {
    if (!model.transactionId || model.transactionId === 0) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/transactions/${model.transactionId}`,
      (response) => {
        setTransaction(response);
      },
      (error) => toast.error(error.message)
    );
  }, [model]);

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-details",
        name: "Voucher Details",
      }),
    });
  };

  const onHistoryDialogClose = () => {
    setShowHistoryDialogModel(false);
  };

  const {
    serialNumber,
    voucherProgram,
    validityStart,
    validityEnd,
    status,
    createdOn,
    activatedOn,
    usedOn,
    createdBy,
    activatedBy,
  } = model;

  const { primaryTitle, actualDateTime } = performance;
  const { referenceNumber } = transaction;

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={() => setShowHistoryDialogModel(true)}
                >
                  History
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            </div>
          </div>
          <Card title="Voucher Details" wrapperCss="pt-3">
            <div className="row">
              <ColumnFormField label="Serial Number:" htmlFor="voucherref">
                {serialNumber}
              </ColumnFormField>
              <ColumnFormField label="Status:" htmlFor="status">
                {VoucherStatus.getVoucherStatus(status)}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Voucher Program:" htmlFor="program">
                {voucherProgram?.name}
              </ColumnFormField>
              <ColumnFormField label="Activated On:" htmlFor="activatedon">
                {StringHelper.asDateStringToDisplayDateTimeFormat(activatedOn)}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Validity Start:" htmlFor="startdate">
                {StringHelper.asDateStringToDisplayDateTimeFormat(
                  validityStart
                )}
              </ColumnFormField>
              <ColumnFormField label="Validity End:" htmlFor="enddate">
                {StringHelper.asDateStringToDisplayDateTimeFormat(validityEnd)}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Registered By:" htmlFor="enddate">
                {createdBy}
              </ColumnFormField>
              <ColumnFormField label="Activated By:" htmlFor="usedon">
                {activatedBy}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Used On:" htmlFor="usedon">
                {StringHelper.asDateStringToDisplayDateTimeFormat(usedOn)}
              </ColumnFormField>
            </div>
          </Card>
          {status === VoucherStatus.Used && (
            <Card title="Usage Details" wrapperCss="pt-3">
              <div className="row form-group">
                <ColumnFormField label="Movie Title:">
                  {primaryTitle}
                </ColumnFormField>
                <ColumnFormField label="Redemption Mode:">
                  {VoucherProgramType.getName(voucherProgram?.type)}
                </ColumnFormField>
              </div>
              <div className="row form-group">
                <ColumnFormField label="Location:">
                  {location.name}
                </ColumnFormField>
                <ColumnFormField label="Redemption Location:">
                  {redemptionLocation.name}
                </ColumnFormField>
              </div>
              <div className="row form-group">
                <ColumnFormField label="Location Hall:">
                  {locationVenue.name}
                </ColumnFormField>
                <ColumnFormField label="Redemption Counter:">
                  {transaction.salesChannel}
                </ColumnFormField>
              </div>
              <div className="row form-group">
                <ColumnFormField label="Movie Datetime:">
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    actualDateTime
                  )}
                </ColumnFormField>
                <ColumnFormField label="Transaction Reference:">
                  {referenceNumber}
                </ColumnFormField>
              </div>
            </Card>
          )}
        </div>
      </div>
      {showHistoryDialog && (
        <HistoryDialog
          objectId={id}
          onClose={onHistoryDialogClose}
          url="/api/v1/history_logs"
          objectType={ObjectType.Voucher}
        />
      )}
    </div>
  );
};
