import React from "react";
import { Link } from "react-router-dom";
import { AuthenticationManager } from "../../common/AuthenticationManager";
import RestClient from "../../common/RestClient";
import { Card } from "../common/Card";
import Validators from "../common/Validators";
import { toast } from "react-toastify";
import Loader from "../common/Loader";

export class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      username: "",
      validations: [],
      isLoading: false
    };
  }

  changePassword = (username, oldPassword, newPassword) => {
    this.setState({ isLoading: true });
    RestClient.sendPostRequest(
      "/api/v1/auth/change_password",
      { username, oldPassword, newPassword },
      () => {
        if (!AuthenticationManager.isAuthenticated()) {
          this.props.history.push(`/login`);
        }
        toast.success("Password is changed successfully.");
        this.setState({ isLoading: false });
      },
      (error) => {
        toast.error(error.message)
        this.setState({ isLoading: false });
      }
    );
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return this.state.isSubmit && isError
      ? "form-control is-invalid"
      : "form-control";
  }

  onKeyDownInput = async (e) => {
    if (e.keyCode === 13) {
      await this.onSubmitClick();
    }
  };

  onSubmitClick = async () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, isLoading: false });
      return;
    }
    const { oldPassword, newPassword, username } = this.state;
    this.changePassword(username, oldPassword, newPassword);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    this.setState({ [fieldName]: value });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  render() {
    const {
      isSubmit,
      oldPassword,
      newPassword,
      newPasswordConfirm,
      username,
      isLoading
    } = this.state;

    return (
      <div className="page-wrapper" id="loginpagelayout">
        <div className="container" id="flex">
          <div className="row" id="adjusttomiddle">
            <div className="col-md-12">
              <div className="login-logo">
                <span
                  className="sprite-logo-shaw-black"
                  id="adjusttomiddle2"
                ></span>
              </div>
            </div>
            <div className="col-md-12">
              <div className="login-form">
                <Card title="Change password">
                  <div className="form-group">
                    <label className="form-control-label" htmlFor="username">
                      Username
                    </label>
                    <input
                      className={this.getControlClassName("username")}
                      id="username"
                      name="username"
                      fieldname="username"
                      onChange={this.onTextBoxChange}
                      onKeyDown={this.onKeyDownInput}
                      type="text"
                      value={username}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="username"
                      isEnabled={isSubmit}
                      property="Username"
                      value={username}
                    ></Validators.RequiredValidator>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="old_password"
                    >
                      Old Password
                    </label>
                    <input
                      className={this.getControlClassName("oldPassword")}
                      id="old_password"
                      name="old_password"
                      fieldname="oldPassword"
                      onChange={this.onTextBoxChange}
                      onKeyDown={this.onKeyDownInput}
                      type="password"
                      value={oldPassword}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="oldPassword"
                      isEnabled={isSubmit}
                      property="Old Password"
                      value={oldPassword}
                    ></Validators.RequiredValidator>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="new_password"
                    >
                      New Password
                    </label>
                    <input
                      className={this.getControlClassName("newPassword")}
                      id="new_password"
                      name="new_password"
                      fieldname="newPassword"
                      onChange={this.onTextBoxChange}
                      onKeyDown={this.onKeyDownInput}
                      type="password"
                      value={newPassword}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="newPassword"
                      isEnabled={isSubmit}
                      property="New Password"
                      value={newPassword}
                    ></Validators.RequiredValidator>
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="new_password_confirm"
                    >
                      Confirm New Password
                    </label>
                    <input
                      className={this.getControlClassName("newPasswordConfirm")}
                      id="new_password_confirm"
                      name="new_password_confirm"
                      fieldname="newPasswordConfirm"
                      onChange={this.onTextBoxChange}
                      onKeyDown={this.onKeyDownInput}
                      type="password"
                      value={newPasswordConfirm}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="newPasswordConfirm"
                      isEnabled={isSubmit}
                      property="New Password Confirm"
                      value={newPasswordConfirm}
                    ></Validators.RequiredValidator>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary float-right"
                      name="submit"
                      onClick={this.onSubmitClick}
                      type="button"
                    >
                      Submit
                    </button>
                  </div>
                </Card>
              </div>
            </div>
            <div className="col-md-12 aligningtextcenter">
              <Link to="/login">Login</Link>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
        <footer className="aligningtextcenter">
          <p>
            @ Copyright 2023 Shaw Organisation.
            <br />
            All rights reserved.
            <br />
            Co. Reg. No.: 194700158G
          </p>
        </footer>
      </div>
    );
  }
}
