import React from "react";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { PassUsageDetailCard } from "./cards/PassUsageDetailCard";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class PassUsageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passUsage: null,
      passUsageId: "",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const passUsageId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || passUsageId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ passUsageId }, this.refreshData);
  }

  loadPassUsage(id) {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/passes/usage/${id}`,
      { includes: "Pass" },
      (response) => {
        this.setState({ passUsage: response });
      }, (error) => toast.error(error.message));
  }

  refreshData() {
    const { passUsageId } = this.state;
    this.loadPassUsage(passUsageId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/passes/usage/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "pass-usage-details",
        name: "View Pass Usage",
      }),
    });
  };

  render() {
    const { passUsage } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <PassUsageDetailCard defaultValue={passUsage} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
