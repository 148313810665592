import React, { useEffect, useMemo, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { Mode } from "../../../constants/Mode";
import _ from "lodash";
import { VoucherRequestService } from "../../../services/VoucherRequestService";
import { VoucherRequest } from "../../../models/VoucherRequest";
import {
  VoucherRequestStatus,
  GroupCode,
} from "../../../constants/VoucherRequestConstants";
import { Strings } from "../../../common/Common";
import RestClient from "../../../common/RestClient";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  GenericMessages,
  ObjectType,
  PersonInCharge,
} from "../../../common/Constants";
import { VoucherRequestRejectModal } from "./VoucherRequestRejectModal";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { VoucherType } from "../../../common/Constants";
import { toast } from "react-toastify";
import { HistoryDialog } from "../../common/HistoryDialog";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { Card } from "../../common/Card";

export const VoucherRequestDetails = (props) => {
  const [instance, setInstance] = useState(new VoucherRequest({}));
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] =
    useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showCompleteConfirmation, setShowCompleteConfirmation] =
    useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [mode, setMode] = useState(Mode.Readonly);
  const [users, setUsers] = useState([]);
  const voucherRequestService = useMemo(() => new VoucherRequestService(), []);

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher_requests",
        name:
          mode === Mode.Editable
            ? "Edit Voucher Request"
            : "Voucher Request Details",
      }),
    });
  };

  const loadData = () => {
    const id = props.match.params.id;
    voucherRequestService.get(
      id,
      (response) => {
        setInstance(new VoucherRequest(response.data));
        loadUsers();
      },
      handleError
    );
  };

  const loadUsers = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/user_groups`,
      { code: GroupCode.CB_BRA },
      (response) => {
        const users = response.data.map((x) => x.name);
        setUsers(users);
      },
      handleError
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  const onModeChange = () => {
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    setMode(newMode);
  };

  const onSubmit = () => {
    voucherRequestService.update(
      instance,
      (response) => {
        setInstance(new VoucherRequest(response.data));
        setMode(Mode.Readonly);
        closeAllModals();
        toast.success(GenericMessages.SaveChangesSuccessfulMessage);
      },
      handleError
    );
  };

  const onCancel = () => {
    loadData();
    setMode(Mode.Readonly);
    closeAllModals();
  };

  const onComplete = () => {
    RestClient.sendPostRequest(
      `/api/v1/voucher_requests/${instance.id}/complete`,
      {},
      (response) => {
        setInstance(new VoucherRequest(response));
        toast.success("The voucher request has been completed successful.");
      },
      handleError
    );
    closeAllModals();
  };

  const onReject = (reason) => {
    if (_.isEmpty(reason)) {
      return;
    }
    RestClient.sendPostRequest(
      `/api/v1/voucher_requests/${instance.id}/reject`,
      {
        reason,
      },
      (response) => {
        setInstance(new VoucherRequest(response));
        toast.success("The voucher request has been rejected successful.");
      },
      handleError
    );
    closeAllModals();
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    setInstance((instance) => {
      return {
        ...instance,
        [fieldName]: selectValue,
      };
    });
  };

  const renderTopButtons = () => {
    return (
      <ButtonActionDetailGroup>
        {instance.status === VoucherRequestStatus.Pending && (
          <ButtonActionDetailGroup.Item
            className="btn btn-success"
            onClick={onModeChange}
          >
            <i className="fas fa-edit" aria-hidden="true" />
            Edit
          </ButtonActionDetailGroup.Item>
        )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          onClick={() => setShowHistoryDialog(true)}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const renderBottomButtons = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => setShowSaveChangesConfirmation(true)}
          disabled={_.isEmpty(instance.personInCharge)}
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const closeAllModals = () => {
    setShowSaveChangesConfirmation(false);
    setShowCancelConfirmation(false);
    setShowCompleteConfirmation(false);
    setShowRejectConfirmation(false);
  };

  const getCssClassMapping = (actionName) => {
    const map = {
      complete: "btn btn-success float-right mr-1",
      reject: "btn btn-danger",
    };
    const defaultCss = "btn btn-primary";
    if (Strings.isNullOrEmpty(actionName)) {
      return defaultCss;
    }
    let value = map[actionName];
    return value ? value : defaultCss;
  };

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  const checkIsPersonInCharge = (currentUser) => {
    return (
      instance.personInCharge !== currentUser ||
      instance.personInCharge !== PersonInCharge.Unassigned
    );
  };

  const generateActions = () => {
    let buttons = [];
    let actions = instance.actions ?? [];
    const isPersonInCharge = checkIsPersonInCharge(
      AuthenticationManager.username()
    );
    actions.forEach((action, index) => {
      let lastIndexOfSlash = action.lastIndexOf("/");
      let actionName = action.substring(lastIndexOfSlash + 1, action.length);
      actionName = actionName.replaceAll("_", " ");
      if (
        AuthenticationManager.isAuthorized(
          ResourceID.BookingRequestCompleteRejectButton
        )
      ) {
        buttons.push(
          <ButtonActionDetailGroup.Item
            className={getCssClassMapping(actionName)}
            style={{ textTransform: "capitalize" }}
            key={index}
            onClick={() => {
              if (actionName.toLowerCase() === "complete") {
                setShowCompleteConfirmation(true);
              } else {
                setShowRejectConfirmation(true);
              }
            }}
            disabled={!isPersonInCharge}
            title={isPersonInCharge ? "" : "You are not person in charge"}
          >
            {actionName}
          </ButtonActionDetailGroup.Item>
        );
      }
    });
    return buttons;
  };

  const getStatusStyle = (status) => {
    status = status ?? "";
    const map = {
      pending: "color-orange",
      rejected: "color-red",
    };
    if (!status) return "color-gray";
    let normalizedStatus = status.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  };

  const renderForm = () => {
    //const { instance, mode, users } = state;
    const status = VoucherRequestStatus.getName(instance.status);
    return (
      <div className="row">
        <div className="col">
          <Card title="Voucher Request Details" wrapperCss="px-3">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label
                  className="form-control-label"
                  htmlFor="preferredTheatre"
                >
                  Type:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={instance.voucherTypeName}
                  id="voucherType"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Salutation">
                  Salutation:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={instance.salutation}
                  id="Salutation"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="FirstHall">
                  Quantity:
                </label>
                <input
                  className="form-control display-input"
                  id="quantity"
                  value={instance.quantity}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="name">
                  Name:
                </label>
                <input
                  className="form-control display-input"
                  id="name"
                  value={instance.name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="emailAddress">
                  Email:
                </label>
                <input
                  className="form-control display-input"
                  id="emailAddress"
                  value={instance.emailAddress}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="ContactNo">
                  Contact Number:
                </label>
                <input
                  className="form-control display-input"
                  id="ContactNo"
                  type="tel"
                  placeholder="Contact Number"
                  value={instance.contactNumber}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="department">
                  Department/Designation:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={instance.department}
                  id="department"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="companyName">
                  Company Name:
                </label>
                <input
                  className="form-control display-input"
                  id="companyName"
                  value={instance.companyName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="postalCode">
                  Postal Code:
                </label>
                <input
                  className="form-control display-input"
                  id="postalCode"
                  type="text"
                  placeholder="PostalCode"
                  value={instance.postalCode}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="companyAddress">
                  Address:
                </label>
                <input
                  className="form-control display-input"
                  id="companyAddress"
                  type="text"
                  placeholder="Company Address"
                  value={instance.companyAddress}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="status">
                  Status:
                </label>
                <input
                  className={`form-control display-input ${getStatusStyle(
                    status
                  )}`}
                  id="status"
                  type="text"
                  value={status}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="InCharge">
                  Person In Charge:
                </label>
                {mode === Mode.Editable && (
                  <select
                    className="form-control"
                    id="InCharge"
                    name="personInCharge"
                    value={instance.personInCharge}
                    onChange={onInputChange}
                  >
                    <option value="">Select Person In Charge</option>
                    {users.map((user, index) => {
                      return (
                        <option value={user} key={index}>
                          {user}
                        </option>
                      );
                    })}
                  </select>
                )}
                {mode === Mode.Readonly && (
                  <input
                    className="form-control display-input"
                    id="InCharge"
                    type="text"
                    value={instance.personInCharge}
                    readOnly={true}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label
                  className="form-control-label"
                  htmlFor="reasonForRejection"
                >
                  Reason For Reject:
                </label>
                <input
                  className="form-control display-input"
                  id="reasonForRejection"
                  type="text"
                  value={instance.reasonForRejection}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  className="form-control-label"
                  htmlFor="AdditionalComments"
                >
                  Additional Comments:
                </label>
                <input
                  className="form-control display-input"
                  id="AdditionalComments"
                  type="text"
                  value={instance.additionalComments}
                  readOnly={true}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {mode === Mode.Readonly && renderTopButtons()}
            {renderForm()}
            {mode === Mode.Readonly && (
              <ButtonActionDetailGroup>
                {generateActions()}
              </ButtonActionDetailGroup>
            )}
            {mode === Mode.Editable && renderBottomButtons()}
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showSaveChangesConfirmation}
        title={GenericMessages.SaveChangesDialogTitle}
        onProceed={onSubmit}
        onCancel={() => setShowSaveChangesConfirmation(false)}
        message="Are you sure you want to save changes?"
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        title={GenericMessages.CancelChangesDialogTitle}
        onProceed={onCancel}
        onCancel={() => setShowCancelConfirmation(false)}
        message={GenericMessages.CancelChangesDialogMessage}
      />
      <ConfirmDialog
        visible={showCompleteConfirmation}
        title="Complete Voucher Request"
        onProceed={onComplete}
        onCancel={() => setShowCompleteConfirmation(false)}
        message="Are you sure you want to complete the request?"
      />
      <VoucherRequestRejectModal
        visible={showRejectConfirmation}
        onProceed={onReject}
        onCancel={() => setShowRejectConfirmation(false)}
        message="Are you sure you want to reject the request?"
      />
      {showHistoryDialog && (
        <HistoryDialog
          objectId={props.match.params.id}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.VoucherRequest}
        />
      )}
    </>
  );
};
