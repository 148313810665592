import React, { Component } from "react";
import "../css/documentation.scss";

export class UpdateUser extends Component {
  render() {
    return (
      <div className="main-content">
        <div className="section__content section__content--p30 createContent">
          <div className="container-fluid">
            <h1 style={{ fontSize: "36px", marginTop: "30px" }}>
              How To Update User Details
            </h1>
            <p
              className="dateFormat"
              style={{ fontSize: "14px", color: "grey" }}
            >
              18/11/2021 ▪ 3 minutes to read
            </p>
            <br />
            <br />
            <div className="createUser container-fluid">
              <ol>
                <li className="createList">
                  Click on <strong>'User'</strong> link from main left side
                  menu. This will bring you to User Listing Page. <br />
                  <img
                    src="/img/documentation-img/users/Users_Menu.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  From the user listing page, you can select a ‘record’ from the
                  User listing table.
                  <br />
                  <img
                    src="/img/documentation-img/users/Select.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  Upon clicking a record from the table, you will be redirected
                  to the ‘User Details page’.
                  <br />
                  <img
                    src="/img/documentation-img/users/userDetails.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  To edit, click the ‘Edit User’ button and modify the fields
                  needed.
                  <br />
                  <img
                    src="/img/documentation-img/users/editBtn.png"
                    className="userImage"
                    alt=""
                  />
                  <br />
                  <br />
                </li>
                <li className="createList">
                  If you wish to edit the General info field, edit this portion
                  <br />
                  <img
                    src="/img/documentation-img/users/editGeneral.png"
                    className="userImage"
                    alt=""
                  />
                </li>
                <li className="createList">
                  If you wish to edit ‘Staff Authentication’ details and/role,
                  edit this section. <br />
                  <img
                    src="/img/documentation-img/users/editStaff.png"
                    className="userImage"
                    alt=""
                  />
                </li>
                <li className="createList">
                  If you wish to edit 'Application Access', edit this section.
                  <br />
                  <img
                    src="/img/documentation-img/users/editApplication.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  After filling in the relevant details, clicking the ‘Save
                  Changes’ button found in the bottom of the page will display
                  the confirmation prompt.
                  <br />
                  <img
                    src="/img/documentation-img/users/saveChanges.png"
                    className="userImage"
                  />
                  <br />
                  There is a ‘Clear’ button in this section, and it will clear
                  the data on all the fields upon confirmation.
                </li>
                <li className="createList">
                  Click ‘Confirm’ when the confirmation prompt appears to save
                  the edits made.
                  <br />
                  <img
                    src="/img/documentation-img/users/savePrompt.png"
                    className="userImage"
                  />
                  <br />
                </li>
                <li className="createList">
                  A message will be shown once edits are saved successfully.
                  <br />
                  <img
                    src="/img/documentation-img/users/saveMessage.png"
                    className="userImage"
                  />
                  <br />
                </li>
                        </ol>
            <div className="text-right">
                <a href="/documentation/users/createUser" className="btn btn-primary">&laquo; Create User</a>&nbsp;
                <a href="/documentation/users/activateDeactivateUser" className="btn btn-primary">Activate/Deactivate User &raquo;</a>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
