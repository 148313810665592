import React from "react";
import {
  EnumHelper,
  StringHelper,
  generateFileName,
} from "../../../../common/Helpers";
import {
  LocationBrands,
  LocationBrandsEnum,
  StorageModule,
} from "../../../../common/Constants";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { PreviewImageDialog } from "../../../common/PreviewImageDialog";
import { CardMode } from "../../common/CardMode";
import { BlobStorageRestService } from "../../../../services/BlobStorageRestService";
import { useUpload } from "../../../../hooks/useUpload";
import { ColumnFormField } from "../../../layout/FormLayout";

export class LocationDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      isMediaSubmitted: false,
      mediaDisplayWeight: 0,
      mediaUrl: "",
      mediaValidations: [],
      model: this.mergeDefaultValue(props.model),
      newMediaId: -1,
      selectedMediaId: 0,
      showDeleteMediaDialog: false,
      showPreviewMediaDialog: false,
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  buildLocationBrandFromModel(model) {
    const { isDigital, isDreamers, isImax, isLumiere, isPremiere } = model;
    return (
      (isDigital ? LocationBrandsEnum.Digital : 0) +
      (isDreamers ? LocationBrandsEnum.Dreamers : 0) +
      (isImax ? LocationBrandsEnum.Imax : 0) +
      (isLumiere ? LocationBrandsEnum.Lumiere : 0) +
      (isPremiere ? LocationBrandsEnum.Premiere : 0)
    );
  }

  buildLocationBrandNameFromModel(model) {
    const { isDreamers, isImax, isLumiere, isPremiere } = model;
    const brandNames = [
      isImax ? LocationBrands.Imax : null,
      isLumiere ? LocationBrands.Lumiere : null,
      isPremiere ? LocationBrands.Premiere : null,
      isDreamers ? LocationBrands.Dreamers : null,
    ];
    return brandNames.filter((brand) => brand !== null).join(", ");
  }

  isMediaValidated() {
    const { mediaValidations } = this.state;
    return mediaValidations.every((val) => val.isValid);
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  getMediaUrl = () => {
    const { model, selectedMediaId } = this.state;
    const media = model.medias.find((media) => media.id === selectedMediaId);
    return media === null ? "" : media?.mediaUrl;
  };

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    const brands = value.brands || 0;
    return {
      address: value.address || "",
      brands: value.brands || 0,
      code: value.code || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      displayWeight: value.displayWeight || 0,
      isDigital:
        value.isDigital ||
        EnumHelper.hasFlag(brands, LocationBrandsEnum.Digital),
      isDreamers:
        value.isDigital ||
        EnumHelper.hasFlag(brands, LocationBrandsEnum.Dreamers),
      isImax:
        value.isDigital || EnumHelper.hasFlag(brands, LocationBrandsEnum.Imax),
      isLumiere:
        value.isDigital ||
        EnumHelper.hasFlag(brands, LocationBrandsEnum.Lumiere),
      isPremiere:
        value.isDigital ||
        EnumHelper.hasFlag(brands, LocationBrandsEnum.Premiere),
      mapLink: value.mapLink || "",
      medias: value.medias || [],
      name: value.name || "",
      planToPublishStr: value.planToPublish || "",
      poster: value.poster || "",
      wheelchairAccessRoute: value.wheelchairAccessRoute || "",
    };
  }

  onAddMediaClick = () => {
    if (!this.isMediaValidated()) {
      this.setState({ isMediaSubmitted: true });
      return;
    }
    const { mediaUrl, mediaDisplayWeight, model, newMediaId } = this.state;
    const newMedia = {
      id: newMediaId,
      mediaUrl,
      displayWeight: mediaDisplayWeight,
    };
    const medias = [...model.medias, newMedia];
    const newModel = Object.assign({}, model, { medias });
    this.setState(
      {
        isDirty: true,
        model: newModel,
        mediaUrl: "",
        mediaDisplayWeight: 0,
        isMediaSubmitted: false,
        newMediaId: newMediaId - 1,
        removeMediaUrl: "",
      },
      this.onModelChange
    );
  };

  onCheckBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const checked = e.target.checked;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: checked,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onDeleteMediaClick = (e) => {
    const id = e.target.getAttribute("data-id");
    const url = e.target.getAttribute("data-url");
    this.setState({
      selectedMediaId: Number(id),
      showDeleteMediaDialog: true,
      removeMediaUrl: url,
    });
  };

  onHideDeleteMediaDialog = () => {
    this.setState({ selectedMediaId: 0, showDeleteMediaDialog: false });
  };

  onHidePreviewMediaDialog = () => {
    this.setState({ selectedMediaId: 0, showPreviewMediaDialog: false });
  };

  onMediaTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    this.setState({ [fieldName]: value });
  };

  onMediaValidationStatusChange = (e) => {
    let { mediaValidations } = this.state;
    let validation = mediaValidations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      mediaValidations.push(e);
    }
    this.setState({ mediaValidations });
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const brands = this.buildLocationBrandFromModel(this.state.model);
      const model = Object.assign(this.state.model, { brands });
      onModelChange(model);
    }
  };

  onPreviewMediaClick = (e) => {
    const id = e.target.getAttribute("data-id");
    this.setState({
      selectedMediaId: Number(id),
      showPreviewMediaDialog: true,
    });
  };

  onProceedDeleteMediaDialog = () => {
    const { selectedMediaId, model, removeMediaUrl } = this.state;
    const medias = model.medias.filter((media) => media.id !== selectedMediaId);
    new BlobStorageRestService()
      .deleteFile(removeMediaUrl, StorageModule.Location)
      .then(() => {
        const newModel = Object.assign({}, model, { medias });
        this.setState(
          {
            isDirty: true,
            model: newModel,
            selectedMediaId: 0,
            showDeleteMediaDialog: false,
          },
          this.onModelChange
        );
      });
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  openFile = (e) => {
    const { model } = this.state;
    const { onModelChange, defaultValue } = this.props;
    const sourceFile = e.target.files[0];
    const fileName = generateFileName(defaultValue?.id, StorageModule.Location);
    const { uploadFile } = useUpload();
    uploadFile(sourceFile, fileName, StorageModule.Location).then(
      (response) => {
        const newMediaUrl = `${response.data.blobStoragePath}${response.data.fileName}`;
        this.setState({ mediaUrl: newMediaUrl });
        onModelChange(model);
      }
    );
  };

  render() {
    return CardMode.isViewMode(this.state.cardMode)
      ? this.renderViewMode()
      : this.renderEditMode();
  }

  renderViewMode() {
    const { model, showPreviewMediaDialog } = this.state;
    const {
      address,
      code,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      displayWeight,
      medias,
      mapLink,
      name,
      planToPublishStr,
      wheelchairAccessRoute,
    } = model;
    return (
      <>
        <div className="card">
          <div className="card-body">
            <div className="row form-group">
              <ColumnFormField label="Location Code:">
                <input
                  className="form-control form-readonly"
                  value={code}
                  readOnly
                />
              </ColumnFormField>
              <ColumnFormField label="Location Address:">
                <input
                  className="form-control form-readonly"
                  value={address}
                  readOnly
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Location Name:">
                <input
                  className="form-control form-readonly"
                  value={name}
                  readOnly
                />
              </ColumnFormField>
              <ColumnFormField label="Display Weight:">
                <input
                  className="form-control form-readonly"
                  value={displayWeight}
                  readOnly
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Display Start DateTime:">
                <input
                  className="form-control form-readonly"
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayStartTimeStr
                  )}
                  readOnly
                />
              </ColumnFormField>
              <ColumnFormField label="Display End DateTime:">
                <input
                  className="form-control form-readonly"
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayEndTimeStr
                  )}
                  readOnly
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Event Format Types:">
                <div className="text-black">
                  {this.buildLocationBrandNameFromModel(model)}
                </div>
              </ColumnFormField>
              <ColumnFormField label="Theatre Location Map:">
                <input
                  className="form-control form-readonly"
                  value={mapLink}
                  readOnly
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Plan To Publish:">
                <input
                  className="form-control form-readonly"
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    planToPublishStr
                  )}
                  readOnly
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <div className="col-12">
                <label className="form-control-label" htmlFor="details">
                  Details:
                </label>
                <div
                  className="form-control border-0 pl-0"
                  style={{ color: "black" }}
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12">
                <label className="form-control-label" htmlFor="routedetails">
                  Access Route Details:
                </label>
                <div
                  className="form-control border-0 pl-4"
                  style={{ color: "black" }}
                  dangerouslySetInnerHTML={{ __html: wheelchairAccessRoute }}
                ></div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12">
                <label className="form-control-label" htmlFor="format">
                  Photos:
                </label>
                {medias.map((media) => (
                  <div className="row form-group" key={media.id}>
                    <div className="col-md-11">
                      <input
                        className="form-control display-input mt-1"
                        type="text"
                        id="Format"
                        readOnly={true}
                        value={media.mediaUrl}
                      />
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                      <button
                        className="btn btn-primary"
                        data-id={media.id}
                        onClick={this.onPreviewMediaClick}
                      >
                        Preview
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <PreviewImageDialog
          visible={showPreviewMediaDialog}
          onClose={this.onHidePreviewMediaDialog}
          url={this.getMediaUrl()}
        />
      </>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const {
      cardMode,
      isMediaSubmitted,
      mediaUrl,
      mediaDisplayWeight,
      model,
      showDeleteMediaDialog,
      showPreviewMediaDialog,
    } = this.state;
    const {
      address,
      code,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      displayWeight,
      isDreamers,
      isImax,
      isLumiere,
      isPremiere,
      medias,
      mapLink,
      name,
      planToPublishStr,
      wheelchairAccessRoute,
    } = model;
    return (
      <>
        <div className="row">
          <div className="col">
            <Card title="Location Details">
              <>
                <div className="row form-group">
                  <ColumnFormField
                    label="Location Code:"
                    htmlFor="locationCode"
                  >
                    <input
                      className="form-control display-input"
                      type="text"
                      id="locationCode"
                      readOnly={true}
                      value={code}
                    />
                  </ColumnFormField>
                  <ColumnFormField label="Location Address:" htmlFor="address">
                    <input
                      type="text"
                      id="address"
                      className={this.getControlClassName("address")}
                      fieldname="address"
                      onChange={this.onTextBoxChange}
                      value={address}
                    />
                  </ColumnFormField>
                </div>
                <div className="row">
                  <ColumnFormField
                    label="Location Name:"
                    htmlFor="locationName"
                  >
                    <input
                      type="text"
                      id="locationName"
                      className={this.getControlClassName("name", "form-group")}
                      fieldname="name"
                      onChange={this.onTextBoxChange}
                      value={name}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="name"
                      isEnabled={isSubmit}
                      property="Location Name"
                      value={name}
                    />
                  </ColumnFormField>
                  <ColumnFormField
                    label="Theatre Location Map:"
                    htmlFor="location"
                  >
                    <textarea
                      rows="6"
                      maxLength="4000"
                      id="location"
                      className={this.getControlClassName(
                        "mapLink",
                        "resize-none"
                      )}
                      fieldname="mapLink"
                      onChange={this.onTextBoxChange}
                      value={mapLink}
                    />
                    <label
                      className="form-control-label float-right"
                      htmlFor="location"
                    >
                      <small>
                        <span id="LocationCount">{mapLink.length}</span>/4000
                      </small>
                    </label>
                    <Validators.MaxLengthValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="mapLink"
                      isEnabled={isSubmit}
                      maxLength="4000"
                      property="Theatre Location Map"
                      value={mapLink}
                    />
                  </ColumnFormField>
                </div>
                <div className="row form-group">
                  <ColumnFormField label="Display Start DateTime:" htmlFor="startDate">
                    <input
                      id="startDate"
                      type="datetime-local"
                      className={this.getControlClassName(
                        "displayStartTimeStr",
                        "display-input"
                      )}
                      fieldname="displayStartTimeStr"
                      onChange={this.onTextBoxChange}
                      value={displayStartTimeStr}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="displayStartTimeStr"
                      isEnabled={isSubmit}
                      property="Display Start DateTime"
                      value={displayStartTimeStr}
                    />
                  </ColumnFormField>
                  <ColumnFormField label="Display Weight:" htmlFor="displayweight">
                    <input
                      id="displayWeight"
                      type="number"
                      min="1"
                      max="100"
                      placeholder="Enter Display Weight"
                      value={displayWeight}
                      className={this.getControlClassName("displayWeight")}
                      fieldname="displayWeight"
                      onChange={this.onTextBoxChange}
                    />
                  </ColumnFormField>
                </div>
                <div className="row form-group">
                  <ColumnFormField label="Display End DateTime:" htmlFor="endDate">
                    <input
                      id="endDate"
                      type="datetime-local"
                      className={this.getControlClassName(
                        "displayEndTimeStr",
                        "display-input"
                      )}
                      fieldname="displayEndTimeStr"
                      onChange={this.onTextBoxChange}
                      value={displayEndTimeStr}
                    />
                    <Validators.DateTimeFromToValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="displayEndTimeStr"
                      isEnabled={isSubmit}
                      fromProperty="Display Start DateTime"
                      fromValue={displayStartTimeStr}
                      toProperty="Display End DateTime"
                      toValue={displayEndTimeStr}
                    />
                  </ColumnFormField>
                  {CardMode.isScheduleMode(cardMode) && (
                    <ColumnFormField
                      label="Plan to Publish On:"
                      htmlFor="PlanToPublish"
                    >
                      <input
                        id="PlanToPublish"
                        type="datetime-local"
                        className={this.getControlClassName(
                          "planToPublishStr",
                          "display-input"
                        )}
                        fieldname="planToPublishStr"
                        onChange={this.onTextBoxChange}
                        value={planToPublishStr}
                      />
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldName="planToPublishStr"
                        isEnabled={isSubmit}
                        property="Plan to Publish On"
                        value={planToPublishStr}
                      />
                    </ColumnFormField>
                  )}
                </div>
                <div className="row form-group">
                  <div className="col-12">
                    <label className="form-control-label" htmlFor="details">
                      Details:
                    </label>
                    <textarea
                      id="details"
                      rows="15"
                      maxLength="8000"
                      className={this.getControlClassName(
                        "displayStartTimeStr",
                        "resize-none"
                      )}
                      fieldname="description"
                      onChange={this.onTextBoxChange}
                      value={description}
                    />
                    <label
                      className="form-control-label float-right"
                      htmlFor="details"
                    >
                      <small>
                        <span id="DetailsCount">{description.length}</span>/8000
                      </small>
                    </label>
                    <Validators.MaxLengthValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="description"
                      isEnabled={isSubmit}
                      maxLength="8000"
                      property="Details"
                      value={description}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-12">
                    <label
                      className="form-control-label"
                      htmlFor="routedetails"
                    >
                      Access Route Details:
                    </label>
                    <textarea
                      id="routedetails"
                      rows="15"
                      maxLength="4000"
                      className={this.getControlClassName(
                        "wheelchairAccessRoute",
                        "resize-none"
                      )}
                      fieldname="wheelchairAccessRoute"
                      onChange={this.onTextBoxChange}
                      value={wheelchairAccessRoute}
                    />
                    <label
                      className="form-control-label float-right"
                      htmlFor="Desc"
                    >
                      <small>
                        <span id="RouteDetailsCount">
                          {wheelchairAccessRoute.length}
                        </span>
                        /4000
                      </small>
                    </label>
                    <Validators.MaxLengthValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="wheelchairAccessRoute"
                      isEnabled={isSubmit}
                      maxLength="4000"
                      property="Access Route Details"
                      value={wheelchairAccessRoute}
                    />
                  </div>
                </div>
              </>
            </Card>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card title="Photos">
              <div className="card-block text-left email-info">
                <div className="container-fluid">
                  <div className="row mb-2 pl-0">
                    <div className="col-8">
                      <h5>Image URL</h5>
                    </div>
                    <div className="col-4 col-md-2">
                      <h5>Display Weight</h5>
                    </div>
                  </div>
                  <div id="stillsList">
                    {medias.map((media) => (
                      <div className="row form-group" key={media.id}>
                        <div className="col-8 text-eclipse">{media.mediaUrl}</div>
                        <div className="col-2">{media.displayWeight}</div>
                        <div className="col-md-2">
                          <button
                            className="btn btn-secondary"
                            data-id={media.id}
                            onClick={this.onPreviewMediaClick}
                          >
                            Preview
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-danger"
                            data-id={media.id}
                            data-url={media.mediaUrl}
                            onClick={this.onDeleteMediaClick}
                          >
                            <i
                              className="far fa-trash-alt"
                              aria-hidden="true"
                              data-id={media.id}
                              data-url={media.mediaUrl}
                            ></i>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-12 col-lg-8">
                    <div className="row mx-0 form-group">
                      <input
                        className="form-control col-12 col-md-8 mb-3 mb-md-0"
                        type="text"
                        name="addStillsDetails"
                        id="image"
                        placeholder="Enter Image URL"
                        fieldname="mediaUrl"
                        onChange={this.onMediaTextBoxChange}
                        value={mediaUrl}
                      />
                      <Validators.RequiredValidator
                        onValidationStatusChange={
                          this.onMediaValidationStatusChange
                        }
                        fieldName="mediaUrl"
                        isEnabled={isMediaSubmitted}
                        property="Image URL"
                        value={mediaUrl}
                      />
                      <div className="col-12 col-md-4 px-0 px-md-3">
                        <input
                          type="file"
                          name="file"
                          onChange={this.openFile}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 col-md-2">
                    <input
                      className="form-control"
                      type="number"
                      name="addStillsDetails"
                      id="weight"
                      min="1"
                      max="255"
                      placeholder="Enter Weight"
                      fieldname="mediaDisplayWeight"
                      onChange={this.onMediaTextBoxChange}
                      value={mediaDisplayWeight}
                    />
                    <Validators.RangeValidator
                      onValidationStatusChange={
                        this.onMediaValidationStatusChange
                      }
                      fieldName="mediaDisplayWeight"
                      isEnabled={isMediaSubmitted}
                      min="1"
                      max="100"
                      property="Display Weight"
                      value={mediaDisplayWeight}
                      allowDecimal={true}
                    />
                  </div>
                  <div className="col-3 col-md-2">
                    <button
                      className="btn btn-success"
                      onClick={this.onAddMediaClick}
                    >
                      <i className="fas fa-plus-circle" aria-hidden="true"></i>{" "}
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <ConfirmDialog
          visible={showDeleteMediaDialog}
          onCancel={this.onHideDeleteMediaDialog}
          onProceed={this.onProceedDeleteMediaDialog}
          message="Are you sure to delete this media?"
          title="Confirmation"
        />
        <PreviewImageDialog
          visible={showPreviewMediaDialog}
          onClose={this.onHidePreviewMediaDialog}
          url={this.getMediaUrl()}
        />
      </>
    );
  }
}
