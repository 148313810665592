import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateFilmFestivalHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create Film Festival</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 5 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Film Festivals" link. This will take you to the Film Festivals Listing Page.<br />
                                    <img src="/img/documentation-img/FilmImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create a film festival, click on “Create New Film Festival” in the top right corner of the Film Festival Listing Page. <br />
                                    <img src="/img/documentation-img/FilmImg/list_create.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    In the Create Film Festivals Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Film Festival Title
                                        </li>
                                        <li>Film Festival Code</li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Film Festival Description
                                        </li>
                                        <li>
                                            Festival Image
                                        </li>
                                        <li>
                                            Film Festival Programmes
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Film Festival Description should be in HTML format.<br />
                                            - Film Festival Description should have a maximum of 8000 characters.
                                </div>
                            </li>
                                        <li class="createList">
                                            To add a film festival content, click on “Add Movie”.<br />
                                            <img src="/img/documentation-img/FilmImg/edit_addmovie.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Select the Film Title, Sales Start Date, Sales End Date, and Film Availability. If Kinolounge is checked as a Film Availability, you will need to enter the film’s KinoLounge URL link.<br />
                                    <img src="/img/documentation-img/FilmImg/addmovie_kinolounge.png" class="userImage" /><br /><br />
                                            <div class="alert alert-primary" role="alert">
                                                <i class="fas fa-info-circle" aria-hidden="true"></i>
                                                <strong class="noteHeader">Information:</strong><br /><br />
                                                - Sales Start Date should always be earlier than Sales End Date.
                                            </div>
                                        </li>
                                        <li class="createList">
                                            To add the movie, click on 'Add Movie'.<br />
                                            <img src="/img/documentation-img/FilmImg/addmovie_addmovie.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To delete a film festival content, click on the trashcan icon.<br />
                                            <img src="/img/documentation-img/FilmImg/filmfestivalprogramme_delete.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To preview the film festival listing, click “Preview” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/FilmImg/create_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/FilmImg/preview_cancelpreview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/FilmImg/create_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm".<br />
                                            <img src="/img/documentation-img/FilmImg/create_cancel_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To create the film festival, click on “Create” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/FilmImg/create_create.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to create the film festival, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/FilmImg/create_create_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful creation, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/FilmImg/create_create_successfultoaster.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            When you return to the film festivals listing page, you will see the changes made.<br />
                                            <img src="/img/documentation-img/FilmImg/create_success_endpoint.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/filmfestivals/ViewFilmFestivalHelp" className="btn btn-primary">&laquo; View Film Festival</a>&nbsp;
                                <a href="/documentation/filmfestivals/EditFilmFestivalHelp" className="btn btn-primary">Edit Film Festival &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}