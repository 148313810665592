import React from "react";
import {
  SystemSettingKeys,
  ProgrammeAvailabilitiesEnum,
} from "../../../../common/Constants";
import {
  DateHelper,
  EnumHelper,
  NumberHelper,
} from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { CardMode } from "../../common/CardMode";
import { ProgrammeCardMovieDialog } from "./ProgrammeCardMovieDialog";
import { SystemSettings } from "../../../../common/SystemSettings";
import _ from "lodash";
import { toast } from "react-toastify";
import { CheckboxInput } from "../../../campaigns/CheckboxInput";
import { FieldWrapper } from "../../../campaigns/FieldWrapper";

export class ProgrammeCard extends React.Component {
  constructor(props) {
    super(props);
    this.refreshCache = false;
    this.state = {
      cachedReleases: [],
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      releaseOptions: [],
      selectedMovieReleaseId: 0,
      showDeleteDialog: false,
      showMovieDialog: false,
      validations: [],
      isEdit: true,
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    this.refreshCache = true;
    this.loadMovieReleaseOptions();
  }

  componentDidUpdate() {
    if (this.refreshCache) {
      this.refreshCache = false;
      const movieReleaseIds = this.state.model.filmFestivalContents.map(
        (prog) => prog.movieReleaseId
      );
      if (movieReleaseIds.length > 0) {
        this.loadMovieReleases(movieReleaseIds);
      }
    }
  }

  getControlClassName(fieldName) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""}`;
  }

  getMovieRelease(movieReleaseId) {
    const release = this.state.cachedReleases.find(
      (ev) => ev.movieReleaseId === movieReleaseId
    );
    return (
      release || {
        classifyCode: "G",
        name: "Loading...",
        plotSummary: "",
        movieId: "",
        poster: "",
        movieReleaseId: "",
        duration: 0,
      }
    );
  }

  isCachedRelease(code) {
    return (
      this.state.cachedReleases.findIndex(
        (evt) => evt.movieReleaseId === code
      ) > -1
    );
  }

  loadMovieReleases(ids) {
    const movieReleaseIds = (ids || []).join(",");
    const { onEditableChange, onChangeCacheReleases } = this.props;
    RestClient.sendGetRequestWithParameters(
      `/api/v1/movie_releases/summary`,
      {
        movieReleaseIds,
      },
      (response) => {
        let { cachedReleases } = this.state;
        response.data.forEach((item) => {
          if (
            cachedReleases.findIndex(
              (evt) => evt.movieReleaseId === item.movieReleaseId
            ) === -1
          ) {
            cachedReleases.push(item);
          }
        });
        onEditableChange(cachedReleases.length <= 0);
        onChangeCacheReleases(this.state.model.programmes);
        this.setState({ cachedReleases });
      }
    );
  }

  loadMovieReleaseOptions() {
    RestClient.sendGetRequest("/api/v1/movie_releases/options", (response) => {
      this.setState({ releaseOptions: response.data });
    });
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      filmFestivalContents:
        value.filmFestivalContents || value.programmes || [],
    };
  }

  onHideDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  onHideMovieDialog = () => {
    this.setState({ showMovieDialog: false });
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onProceedDeleteDialog = () => {
    const { model, selectedMovieReleaseId } = this.state;
    const { onEditableChange, onChangeCacheReleases } = this.props;
    model.filmFestivalContents = model.filmFestivalContents.filter(
      (x) => x.movieReleaseId !== selectedMovieReleaseId
    );

    this.onModelChange(model);
    onEditableChange(model.filmFestivalContents.length <= 0);
    onChangeCacheReleases(model.filmFestivalContents);
    this.setState({
      model,
      selectedMovieReleaseId: 0,
      showDeleteDialog: false,
    });
  };

  onProceedMovieDialog = (model) => {
    if (!this.isCachedRelease(model.movieReleaseId)) {
      this.loadMovieReleases([model.movieReleaseId]);
    }
    const parentModel = this.state.model;
    const { onEditableChange, onChangeCacheReleases } = this.props;
    const isMovieReleaseAttached = parentModel.filmFestivalContents.some(
      (x) => x.movieReleaseId === model.movieReleaseId
    );
    if (isMovieReleaseAttached) {
      const movieRelease = this.getMovieRelease(model.movieReleaseId);
      toast.error(
        `The movie release ${movieRelease.name} was already in the film festival.`
      );
      return;
    }
    parentModel.filmFestivalContents.push(model);
    parentModel.filmFestivalContents.length > 0
      ? onEditableChange(false)
      : onEditableChange(true);
    onChangeCacheReleases(parentModel.filmFestivalContents);
    this.setState(
      { model: parentModel, showMovieDialog: false },
      this.onModelChange
    );
  };

  onShowDeleteDialog = (movieReleaseId) => {
    this.setState({
      selectedMovieReleaseId: movieReleaseId,
      showDeleteDialog: true,
    });
  };

  onShowDeleteDialog = (movieReleaseId) => {
    this.setState({
      selectedMovieReleaseId: movieReleaseId,
      showDeleteDialog: true,
    });
  };

  onShowMovieDialog = () => {
    this.setState({ showMovieDialog: true });
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  onIsActiveChange = (item) => {
    const filmFestivalContent = this.state.model.filmFestivalContents.find(
      (content) => content.id === item.id,
    );

    filmFestivalContent.isActive = !item.isActive;

    const newModel = Object.assign({}, this.state.model);
    this.setState({ model: newModel }, this.onModelChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      this.refreshCache = true;
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    const {
      cardMode,
      model,
      releaseOptions,
      showDeleteDialog,
      showMovieDialog,
    } = this.state;
    const { displayStart, displayEnd } = this.props;

    const filmFestivalContents = model.filmFestivalContents.map((x) => {
      const movieRelease = this.getMovieRelease(x.movieReleaseId);
      return {
        ...x,
        ...movieRelease,
      };
    });

    return (
      <>
        <Card title="Film Festival Contents">
          {CardMode.isEditMode(cardMode) && (
            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-primary"
                onClick={this.onShowMovieDialog}
              >
                <i className="fas fa-plus-circle" />
                Add Movie
              </button>
            </div>
          )}
          <div className="row">
            {
              cardMode === CardMode.View && filmFestivalContents.length === 0 ? (
                <p className="w-100 text-center">No Film Festival Contents Added</p>
              ) : (
                <div
                  className={`${filmFestivalContents.length > 0 && "mt-3"} col`}
                  id="filmMovies"
                >
                  {_.sortBy(filmFestivalContents, ["displayWeight", "name"]).map(
                    (item, index) => this.renderProgramme(item, index),
                  )}
                </div>
              )
            }
          </div>
        </Card>
        {showDeleteDialog && (
          <ConfirmDialog
            message="Any future performances will no longer be tagged to this Film Festival. Are you sure to delete this film festival content?"
            title="Confirmation"
            onCancel={this.onHideDeleteDialog}
            onProceed={this.onProceedDeleteDialog}
          />
        )}
        {showMovieDialog && (
          <ProgrammeCardMovieDialog
            displayStart={displayStart}
            displayEnd={displayEnd}
            releaseOptions={releaseOptions}
            onCancel={this.onHideMovieDialog}
            onProceed={this.onProceedMovieDialog}
          />
        )}
      </>
    );
  }

  renderProgramme(item, index) {      
    const { cardMode } = this.state;
    const enableInCinema = EnumHelper.hasFlag(
      item.contentAvailability,
      ProgrammeAvailabilitiesEnum.Cinema
    );
    const enableKinoLounge = EnumHelper.hasFlag(
      item.contentAvailability,
      ProgrammeAvailabilitiesEnum.KinoLounge
    );
    return (
      <div className="card" key={index}>
        <div className="card-body row">
          <div className="row col">
            <div className="col-6 col-md-2 pb-2 pb-md-0">
              <img style={{ height: "140px" }} src={item.poster} />
            </div>
            <div className="col-md-10 col-xl-6" id="#filmMovies">
              <strong>{item.name}</strong>
              <p>
                <span>
                  <span className="sprite-legends-rating-pg mr-1 float-left" />
                </span>
                <span className="movie-duration">
                  | {NumberHelper.formatRuntime(item.duration)}{" "}
                </span>
              </p>
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: item.plotSummary }}
              />
              <a
                href={`${
                  SystemSettings.get(SystemSettingKeys.PublicWebsiteUrl) || ""
                }/movie-details/${item.movieReleaseId}`}
                target="_blank"
              >
                (Details)
              </a>
            </div>
            <div className="col-xl-4">
              <label className="form-control-label pl-0 col-5">
                Sales Start:
              </label>
              <label className="form-check-label pl-0 col-12 col-md-6">
                {DateHelper.formatDateTimeString(item.salesStartTime)}
              </label>
              <label className="form-control-label pl-0 col-5">
                Sales End:
              </label>
              <label className="form-check-label pl-0 col-12 col-md-6">
                {DateHelper.formatDateTimeString(item.salesEndTime)}
              </label>
              <label className="form-control-label pl-0 col-5">
                Display Weight:
              </label>
              <label className="form-check-label pl-0 col-12 col-md-6">
                {item.displayWeight}
              </label>
              <div className="row">
                <label className="form-control-label col-md-5">
                  Film Availability:
                </label>
                <ul className="col-12 col-md-5" style={{ paddingLeft: "30px" }}>
                  {enableInCinema && <li>Cinema</li>}
                  {enableKinoLounge && <li>KinoLounge</li>}
                </ul>
              </div>
              <FieldWrapper
                label="Is Active"
                input={
                  <>
                    {CardMode.isEditMode(cardMode) ? (
                      <CheckboxInput
                        name="isActive"
                        value={item.isActive}
                        onChange={()=>this.onIsActiveChange(item)}
                        checked={item.isActive}
                      />
                    ) : (
                      <label className="form-check-label pl-0 col-12 col-md-6">
                        {item.isActive ? "Yes" : "No"}
                      </label>
                    )}
                  </>
                }
              />

              {enableKinoLounge && (
                <div className="row">
                  <label className="form-control-label col-md-5">
                    KinoLounge Link:
                  </label>
                  <a
                    className="col-12 col-md-6"
                    href={item.kinoLoungeUrl}
                    target="blank"
                  >
                    {item.kinoLoungeUrl}
                  </a>
                </div>
              )}
            </div>
          </div>
          {CardMode.isEditMode(cardMode) && (
            <div className="p-r-15">
              <button
                className="btn btn-danger"
                onClick={() => this.onShowDeleteDialog(item.movieReleaseId)}
              >
                <i className="far fa-trash-alt" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
