import _ from "lodash";
import { StatusColor } from "../common/Constants";

export class TransactionStatus {
  static Created = 1;
  static Successful = 2;
  static Failed = 3;

  static ColorMap = {
    [this.Created]: StatusColor.Gray,
    [this.Successful]: StatusColor.Green,
    [this.Failed]: StatusColor.Red,
  };

  static BackgroundColorMap = {
    [this.Created]: "#fff",
    [this.Successful]: "#fff",
    [this.Failed]: "#ffefe9",
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };

  static getBackgroundColor = (status) => {
    const defaultColor = this.BackgroundColorMap[this.Created];
    const map = this.BackgroundColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };

  static All = [
    {
      name: "Created",
      value: this.Created,
    },
    {
      name: "Successful",
      value: this.Successful,
    },
    {
      name: "Failed",
      value: this.Failed,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class PaymentType {
  static Cash = 1;
  static Credit = 2;
  static Voucher = 3;
  static Pass = 4;
  static CashAndPass = 7;
  static EVoucher = 14;
  static All = [
    {
      name: "Cash",
      value: this.Cash,
    },
    {
      name: "Credit",
      value: this.Credit,
    },
    {
      name: "Voucher",
      value: this.Voucher,
    },
    {
      name: "Pass",
      value: this.Pass,
    },
    {
      name: "Cash + Pass",
      value: this.CashAndPass,
    },
    {
      name: "EVoucher",
      value: this.EVoucher,
    }
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CreditCardType {
  static AmericanExpress = 1;
  static InstantRewards = 2;
  static JapanCreditBureau = 3;
  static MasterCard = 4;
  static ChinaUnionPay = 5;
  static PayNow = 6;
  static Visa = 7;

  static All = [
    {
      code: "AE",
      name: "Amex",
      value: this.AmericanExpress,
    },
    {
      code: "IN",
      name: "Instant Rewards",
      value: this.InstantRewards,
    },
    {
      code: "JC",
      name: "Japan Credit Bureau",
      value: this.JapanCreditBureau,
    },
    {
      code: "MC",
      name: "MasterCard",
      value: this.MasterCard,
    },
    {
      code: "UP",
      name: "China Union Pay",
      value: this.ChinaUnionPay,
    },
    {
      code: "PN",
      name: "PayNow",
      value: this.PayNow,
    },
    {
      code: "VS",
      name: "Visa",
      value: this.Visa,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CreditCardProgram {
  static AMEX = 1;
  static JCB = 2;
  static ChinaUnionPay = 3;
  static ENets = 4;
  static MasterCard = 5;
  static Visa = 7;
  static PayNowHsbc = 8;

  static All = [
    {
      code: "AE",
      name: "Amex",
      value: this.AMEX,
    },
    {
      code: "IN",
      name: "Instant Rewards",
      value: this.JCB,
    },
    {
      code: "MC",
      name: "MasterCard",
      value: this.MasterCard,
    },
    {
      code: "UP",
      name: "China Union Pay",
      value: this.ChinaUnionPay,
    },
    {
      code: "PN",
      name: "PayNow",
      value: this.PayNowHsbc,
    },
    {
      code: "VS",
      name: "Visa",
      value: this.Visa,
    },
    {
      code: "EN",
      name: "ENets",
      value: this.ENets,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class HallTransactionEnquiryKeys {
  static LocationId = "LocationId";
  static LocationVenueIds = "LocationVenueIds";
  static TimeFrame = "TimeFrame";
  static IncludeHistorical = "IncludeHistorical";
  static All = [
    {
      name: "Location",
      value: this.LocationId,
    },
    {
      name: "LocationVenues",
      value: this.LocationVenueIds,
    },
    {
      name: "TimeFrame",
      value: this.TimeFrame,
    },
    {
      name: "Include Historical",
      value: this.IncludeHistorical,
    },
  ];
  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class PaymentStatus {
  static None = 0;
  static PaymentInitiated = 1;
  static Authorized = 2;
  static AuthorizationFailed = 3;
  static Captured = 4;
  static CapturedFailed = 5;
  static RefundInitiated = 6;
  static Refunded = 7;
  static RefundFailed = 8;
  static Voided = 9;

  static ColorMap = {
    [this.None]: StatusColor.Red,
    [this.PaymentInitiated]: StatusColor.Orange,
    [this.Authorized]: StatusColor.Orange,
    [this.Captured]: StatusColor.Green,
    [this.CapturedFailed]: StatusColor.Red,
    [this.RefundInitiated]: StatusColor.Orange,
    [this.Refunded]: StatusColor.Green,
    [this.RefundFailed]: StatusColor.Red,
    [this.Voided]: StatusColor.Gray,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };

  static All = [
    {
      value: this.PaymentInitiated,
      name: "Payment Initiated",
    },
    {
      value: this.Authorized,
      name: "Authorized",
    },
    {
      value: this.AuthorizationFailed,
      name: "Authorization Failed",
    },
    {
      value: this.Captured,
      name: "Captured",
    },
    {
      value: this.CapturedFailed,
      name: "Captured Failed",
    },
    {
      value: this.RefundInitiated,
      name: "Refund Initiated",
    },
    {
      value: this.Refunded,
      name: "Refunded",
    },
    {
      value: this.RefundFailed,
      name: "Refund Failed",
    },
    {
      value: this.Voided,
      name: "Voided",
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}
