import {useQuery} from "react-query";
import {RestService} from "../../services/RestService";
import {useRecoilState} from "recoil";
import {reportingFilmFestivalsAtom} from "./Report.state";

const QUERY_KEY_REPORTING_CAMPAIGNS = "GET_REPORTING_FILM_FESTIVALS";

export function useReportingFilmFestivals() {
  const [data, setData] = useRecoilState(reportingFilmFestivalsAtom);
  useQuery(
    QUERY_KEY_REPORTING_CAMPAIGNS,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/film_festivals"
      ).list();
    },
    {
      onSuccess: (response) => setData(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return data;
}
