import React, { useState, useEffect } from "react";
import _ from "lodash";
import { CampaignBundleProduct } from "../../../../models/CampaignBundle";
import { Product } from "../../../../models/Product";
import { NumberInput } from "../../NumberInput";
import { FieldWrapper } from "../../FieldWrapper";
import RestClient from "../../../../common/RestClient";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import { SingleSelector } from "../../SingleSelector";
import { ProductCategory } from "../../../products/Constants";
import { campaignBundlesAtom, productsAtom } from "../../CampaignState";
import { useRecoilState, useRecoilValue } from "recoil";
import { toast } from "react-toastify";
import { TextInput } from "../../TextInput";

export const NewCampaignBundleProductModal = (props) => {
  const [instance, setInstance] = useState(new CampaignBundleProduct({}));
  const [products, setProducts] = useState([]);
  const [productCategory, setProductCategory] = useState(0);
  const [product, setProduct] = useState(new Product({}));
  const { campaignBundleId, visible, onCancel } = props;

  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const sourceProducts = useRecoilValue(productsAtom);

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    // Reset the product selection
    setProduct(new Product({}));
    if (productCategory <= 0) {
      setProducts([]);
      return;
    }
    setProducts(sourceProducts.filter(x => x.category === productCategory));
  }, [productCategory, sourceProducts]);

  const onAdd = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundle_products`,
      {
        ...instance,
        campaignBundleId,
        currentUser: AuthenticationManager.username(),
      },
      (response) => {
        const changableCampaignBundles = _.cloneDeep(campaignBundles);
        const foundBundle = changableCampaignBundles.find(
          (x) => x.id === campaignBundleId
        );
        foundBundle.campaignBundleProducts.push(
          new CampaignBundleProduct(response)
        );
        setInstance(new CampaignBundleProduct({}));
        setCampaignBundles(changableCampaignBundles);
        onCancel();
        toast.success(
          "The campaign bundle product has been created successful."
        );
      },
      handleError
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }
    instance[fieldName] = selectValue;
    setInstance(_.cloneDeep(instance));
  };

  const onSelectProduct = (e) => {
    const id = +e.target.value;
    const found = products.find((x) => x.id === id);
    setProduct(found ?? new Product({}));
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ paddingRight: "17px", display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Product</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <FieldWrapper
                label="Product Category"
                require={true}
                additionalCss="form-group"
                input={
                  <SingleSelector
                    name="productCategory"
                    placeholder="Product Category"
                    options={ProductCategory.All}
                    onChange={(e) => setProductCategory(+e.target.value)}
                  />
                }
              />
              <FieldWrapper
                label="Products"
                require={true}
                additionalCss="form-group"
                input={
                  <SingleSelector
                    name="productId"
                    type="number"
                    options={products.filter(x => x.category === productCategory).map((x) => {
                      return { name: x.name, value: x.id };
                    })}
                    value={instance.productId}
                    onChange={(e) => {
                      onInputChange(e);
                      onSelectProduct(e);
                    }}
                    placeholder="Product"
                  />
                }
              />
              <FieldWrapper
                label="Quantity"
                require={true}
                additionalCss="form-group"
                input={
                  <NumberInput
                    name="quantity"
                    type="number"
                    value={instance.quantity}
                    onChange={onInputChange}
                    placeholder="Enter Quantity"
                    min={0}
                  />
                }
              />
              <div className="row">
                <div className="col-md-12">
                  <fieldset className="card card-body mb-0">
                    <legend className="col-auto" style={{ fontSize: "16px" }}>
                      All prices are per unit
                    </legend>
                    <FieldWrapper
                      label="Sales Amount"
                      require={true}
                      additionalCss="form-group"
                      input={
                        <TextInput
                          value={product.salesAmount.toFixed(6)}
                          readOnly={true}
                        />
                      }
                    />
                  </fieldset>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-success"
                onClick={onAdd}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
