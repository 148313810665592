import React from "react";
import RestClient from "../../../common/RestClient";

export class EvoucherProgramPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = { evoucherProgram: null };
  }

  componentDidMount() {
    this.loadEvoucherProgramPreview();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.evoucherProgramId &&
      prevProps.evoucherProgramId !== this.props.evoucherProgramId
    ) {
      this.loadEvoucherProgramPreview();
    }
  }

  loadEvoucherProgramPreview() {
    const { evoucherProgramId } = this.props;
    if (evoucherProgramId) {
      RestClient.sendGetRequest(
        `/api/v1/evoucher_programs/${evoucherProgramId}`,
        (response) => {
          if (response) {
            this.setState({ evoucherProgram: response });
          }
        }
      );
    }
  }

  render() {
    const { evoucherProgram } = this.state;
    if (!evoucherProgram) return <></>;
    return (
      <div className="row form-group">
        <div className="col-md-3">
          <label className="form-control-label" htmlFor="program">
            eVoucher Program:
          </label>
        </div>
        <div className="col-md-9">
          <input
            className="form-control display-input"
            type="text"
            value={evoucherProgram.name}
            readOnly
          />
          <div className="card mt-3">
            <div className="card-body">
              <div className="row form-group">
                <label
                  htmlFor="Program"
                  className="form-control-label col-md-5"
                >
                  Short Name:
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control display-input"
                    id="Program"
                    type="text"
                    value={evoucherProgram.shortName}
                    readOnly
                  />
                </div>
              </div>
              <div className="row form-group">
                <label
                  htmlFor="Price"
                  className="form-control-label col-md-5"
                >
                  Price:
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control display-input"
                    id="Price"
                    type="number"
                    value={evoucherProgram.salesAmount}
                    readOnly
                  />
                </div>
              </div>
              <div className="row form-group">
                <label
                  htmlFor="Duration"
                  className="form-control-label col-md-5"
                >
                  Validity Duration:
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control display-input"
                    id="Duration"
                    type="text"
                    value={`${evoucherProgram.validityDuration} days`}
                    readOnly
                  />
                </div>
              </div>
              <div className="row form-group">
                <label
                  htmlFor="Prefix"
                  className="form-control-label col-md-5"
                >
                  Prefix:
                </label>
                <div className="col-md-6">
                  <input
                    className="form-control display-input"
                    id="Prefix"
                    type="text"
                    value={evoucherProgram.prefix}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
