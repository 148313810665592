import React from "react";
import { PassMembershipCardType } from "../../../../common/Constants";
import { DateHelper, StringHelper } from "../../../../common/Helpers";
import { Card } from "../../../common/Card";
import { DisneyCardPreview } from "../../common/card-preview/DisneyCardPreview";
import { GenericCardPreview } from "../../common/card-preview/GenericCardPreview";
import { PremiereCardPreview } from "../../common/card-preview/PremiereCardPreview";
import { StaffCardPreview } from "../../common/card-preview/StaffCardPreview";
import { VipCardPreview } from "../../common/card-preview/VipCardPreview";
import { formatPassProgramType } from "../../common/Constants";
import { RowFormField } from "../../../layout/FormLayout";

export class PassProgramDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      model: this.mergeDefaultValue(props.defaultValue),
      pass: this.createPreviewPass(),
    };
  }

  createPreviewPass() {
    return {
      code: "0017",
      expiryDateStr: DateHelper.toIsoDateFormat(new Date()),
      name: "First Name",
    };
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      code: value.code || "",
      description: value.description || "",
      endDate: value.endDate || null,
      labelPrint: value.labelPrint || "",
      membershipCardType: value.membershipCardType || 0,
      name: value.name || "",
      partnerCompanyCode: value.partnerCompanyCode || "",
      prefix: value.prefix || "",
      startDate: value.startDate || null,
      status: value.status || 0,
      termCondition: value.termCondition || "",
      partialTermCondition: value.partialTermCondition || "",
      type: value.type || 0,
      usageType: value.usageType || "",
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (newState) {
      this.setState(newState);
    }
    return newState === null;
  }

  render() {
    const { model, pass } = this.state;
    const {
      code,
      description,
      expiryDate,
      name,
      partnerCompanyCode,
      prefix,
      startDate,
      termCondition,
      type,
      usageType,
      labelPrint,
      partialTermCondition,
    } = model;

    return (
      <Card title="Pass Program Details">
        <div className="details row">
          <div className="col">
            <RowFormField label="Program Code:" htmlFor="ProgramCode">
              <input
                className="form-control display-input"
                id="ProgramCode"
                type="text"
                placeholder="Enter Pass Code"
                readOnly={true}
                value={code}
              />
            </RowFormField>
            <RowFormField label="Program Type:" htmlFor="Type">
              <input
                className="form-control display-input"
                id="Type"
                type="text"
                placeholder="Enter ID"
                readOnly={true}
                value={formatPassProgramType(type)}
              />
            </RowFormField>
            <RowFormField label="Name:" htmlFor="Name">
              <input
                className="form-control display-input"
                id="Name"
                type="text"
                placeholder="Enter Name"
                readOnly={true}
                value={name}
              />
            </RowFormField>
            <RowFormField label="Program Description:" htmlFor="Desc">
              <textarea
                className="form-control display-input resize-none"
                rows="1"
                id="Desc"
                readOnly={true}
                value={description}
              />
            </RowFormField>
            <RowFormField label="Terms &amp; Conditions:" htmlFor="TC">
              <textarea
                className="form-control display-input resize-none"
                rows="4"
                id="TC"
                readOnly={true}
                value={termCondition}
              />
            </RowFormField>
            <RowFormField
              label="Partial Discount Terms &amp; Conditions:"
              htmlFor="PTC"
            >
              <textarea
                className="form-control display-input resize-none"
                rows="4"
                id="PTC"
                readOnly={true}
                value={partialTermCondition}
              />
            </RowFormField>
            <RowFormField label="Start Date:" htmlFor="StartDate">
              <input
                className="form-control display-input"
                id="StartDate"
                type="date"
                readOnly={true}
                value={StringHelper.asDateStringToIsoDateFormat(startDate)}
              />
            </RowFormField>
            <RowFormField label="Expiry Date:" htmlFor="ExpiryDate">
              <input
                className="form-control display-input"
                id="ExpiryDate"
                type="date"
                readOnly={true}
                value={StringHelper.asDateStringToIsoDateFormat(expiryDate)}
              />
            </RowFormField>
            <RowFormField label="Partner Company Code:" htmlFor="CompCode">
              <input
                className="form-control display-input"
                id="CompCode"
                type="text"
                readOnly={true}
                value={partnerCompanyCode}
              />
            </RowFormField>
            <RowFormField label="Label Print:" htmlFor="Name">
              <input
                className="form-control display-input"
                id="Name"
                type="text"
                placeholder=""
                value={labelPrint}
                readOnly={true}
              />
            </RowFormField>
            <RowFormField label="Prefix:" htmlFor="Comp">
              <input
                className="form-control display-input"
                id="Comp"
                type="text"
                placeholder=""
                readOnly={true}
                value={prefix}
              />
            </RowFormField>
            <RowFormField label="Usage Type:" htmlFor="Comp">
              <input
                className="form-control display-input"
                id="Comp"
                type="text"
                placeholder=""
                readOnly={true}
                value={usageType}
              />
            </RowFormField>
          </div>
          <div className="col-xl-4">
            {model && this.renderMembershipCardPreview(pass, model)}
          </div>
        </div>
      </Card>
    );
  }

  renderMembershipCardPreview(pass, program) {
    pass.code = `${program.prefix}0017`;
    return (
      <>
        {program.membershipCardType === PassMembershipCardType.Disney && (
          <DisneyCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Generic && (
          <GenericCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Premiere && (
          <PremiereCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Staff && (
          <StaffCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Vip && (
          <VipCardPreview program={program} pass={pass} />
        )}
      </>
    );
  }
}
