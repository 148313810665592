export class ProductCategory {
  static Snacks = 1;
  static Drinks = 2;
  static Combos = 3;
  static Merchandise = 4;
  static ComboContent = 5;
  static PremiereFnb = 6;
  static All = [
    { value: this.Snacks, name: "Snacks" },
    { value: this.Drinks, name: "Drinks" },
    { value: this.Combos, name: "Combos" },
    { value: this.Merchandise, name: "Merchandise" },
    { value: this.ComboContent, name: "Combo Content" },
    { value: this.PremiereFnb, name: "Premiere FnB" },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    return found?.name ?? "";
  }
}

export class ProductComboCategories {
  static ComboRange = [ProductCategory.Snacks, ProductCategory.Drinks];
}

export class Availability {
  static Available = 1;
  static SoldOut = 2;
  static NotAvailable = 3;
  static All = [
    { id: this.Available, name: "Available", backgroundColor: "#F4FFE9" },
    { id: this.SoldOut, name: "Sold Out", backgroundColor: "#ffefe9" },
    {
      id: this.NotAvailable,
      name: "Not Available",
      backgroundColor: "#efedec",
    },
  ];
}
