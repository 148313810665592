import React from "react";
import {
  DayOfWeek,
  QuantityLimitType,
} from "../../../../constants/CampaignConstants";
import { campaignExtensionPropertiesSelector } from "../../CampaignState";
import { useRecoilState } from "recoil";

export const QuantityLimitTypeCard = (props) => {
  const [campaignExtensionProperty, setCampaignExtensionProperty] =
    useRecoilState(campaignExtensionPropertiesSelector);

  const { disabled } = props;
  return (
    <>
      <div className="row form-group">
        <div className="col-md-4">
          <label>
            Quantity Limit Type:
            <span className="color-red font-weight-bold">*</span>
          </label>
        </div>
        <div className="col-md-8">
          <select
            className="form-control"
            onChange={(e) => {
              setCampaignExtensionProperty({
                ...campaignExtensionProperty,
                quantityLimitType: +e.target.value,
              });
            }}
            value={campaignExtensionProperty.quantityLimitType}
            disabled={disabled}
          >
            <option value={0}>Select a quantity limit type</option>
            {QuantityLimitType.All.map((x, index) => {
              return (
                <option value={x.value} key={index}>
                  {x.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {campaignExtensionProperty.quantityLimitType ===
        QuantityLimitType.Weekly && (
        <div className="row form-group">
          <div className="col-md-4">
            <label>Reset Day:</label>
          </div>
          <div className="col-md-8">
            <select
              className="form-control"
              onChange={(e) => {
                setCampaignExtensionProperty({
                  ...campaignExtensionProperty,
                  resetDay: +e.target.value,
                });
              }}
              value={campaignExtensionProperty.resetDay}
              disabled={disabled}
            >
              <option value="">Select a Reset Day</option>
              {DayOfWeek.All.map((x, index) => {
                return (
                  <option value={x.value} key={index}>
                    {x.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      )}
    </>
  );
};
