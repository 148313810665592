import React, { useEffect, useState } from "react";
import { Advice } from "../../../models/Advice";
import { Constants } from "../../common/Constant";
import _ from "lodash";
import { toast } from "react-toastify";
import { RowFormField } from "../../layout/FormLayout";
import AdviceSelect from "../../common/AdviceSelect";

export const AddConsumerAdviceModal = (props) => {
  const [instance, setInstance] = useState(new Advice());
  const { onCancel, onProceed } = props;

  useEffect(() => {
    setInstance((data) => {
      const adviceName =
        instance.adviceName.length > Constants.AdviceOnTicketLength
          ? instance.adviceName.slice(0, Constants.AdviceOnTicketLength)
          : instance.adviceName;
      return { ...data, adviceOnTicket: adviceName };
    });
  }, [instance.adviceName]);

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    let selectValue = e.target.value;
    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    setInstance(_.cloneDeep(instance));
  };

  const onSelect = (event) => {
    setInstance((data) => ({
      ...data,
      id: event.value ?? 0,
      adviceName: event.label ?? "",
    }));
  };

  const onAdd = () => {
    if (instance.adviceOnTicket.length > 30) {
      toast.error("Advice on Ticket field allows maximum 30 characters");
      return;
    }
    onProceed(instance);
    onCancel();
  };

  const visible = props.visible;
  if (!visible) {
    return null;
  }

  const disableAddBtn =
    _.isEmpty(instance.adviceName) || _.isEmpty(instance.adviceOnTicket);
  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Consumer Advice</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={props.onCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflow: "unset" }}>
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Consumer Advice:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <AdviceSelect
                    onChange={onSelect}
                    selectedValue={{
                      value: instance.id,
                      label: instance.adviceName,
                    }}
                  />
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      Advice On Ticket:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <input
                    className="form-control"
                    name="adviceOnTicket"
                    maxLength={30}
                    onChange={onInputChange}
                    value={instance.adviceOnTicket}
                    placeholder="Enter Advice On Ticket"
                    type="text"
                  />
                </RowFormField>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-dismiss="modal"
                disabled={disableAddBtn}
                onClick={onAdd}
              >
                <i className="fas fa-plus-sign" aria-hidden="true" />
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
