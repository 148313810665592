import {
  CreditCardProgram,
  PaymentStatus,
  PaymentType,
} from "../../../../constants/TransactionConstants";
import { VoucherProgramType } from "../../../../constants/VoucherContants";
import React from "react";
import { ApplicationID } from "../../../../common/Constants";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";

const TPOS = ApplicationID.getName(ApplicationID.TPOS);

const PaymentRowLayout = (props) => {
  return (
    <div className="row justify-content-between mb-1">
      <div className="col-8 row">
        <div className="col-md-6">
          <label className="form-control-label">{props.first}</label>
        </div>
        <div className="col-md-6">
          <label className="form-control-label">{props.second}</label>
        </div>
      </div>
      <div className="col-4 d-flex align-items-center justify-content-end">
        <label className="form-control-label">{props.third}</label>
      </div>
    </div>
  );
};

const TransactionDetailPaymentInformation = (props) => {
  const {
    transaction,
    paymentCredit,
    paymentVouchers,
    paymentPasses,
    eVoucherProgram,
    paymentEVouchers,
    onViewChargedStatus,
  } = props;
  const processAmount = (transaction.processAmount ?? 0).toFixed(6);
  const finalAmount =
    transaction.salesChannel === ApplicationID.TPOS &&
    transaction.paymentType === PaymentType.Cash
      ? processAmount
      : transaction.amountFinal.toFixed(6);
  const isFullyPayPass = transaction.paymentType === PaymentType.Pass;
  const paymentMethod =
    CreditCardProgram.getName(paymentCredit.creditCardProgram) ||
    (isFullyPayPass
      ? paymentPasses?.map((x) => x.passProgramName).join(", ")
      : "");
  const passPaymentCodes = paymentPasses?.map((x) => x.passCode).join(", ");

  const paymentMethodName =
    transaction.paymentType === PaymentType.Credit ||
    transaction.paymentType === PaymentType.Pass
      ? `[${paymentMethod}]`
      : "";
  const paymentTypeName = PaymentType.getName(transaction.paymentType);
  const creditMaskedNumberOrPassCode =
    paymentCredit.creditCardMaskedNumber ||
    (isFullyPayPass ? passPaymentCodes : "");
  const paymentFinalAmount =
    transaction.paymentStatus === PaymentStatus.Captured
      ? `$${finalAmount}`
      : "-";

  const isShowViewChargeStatusButton = !(
    transaction.salesChannel === TPOS &&
    transaction.paymentType === PaymentType.Cash
  );
  return (
    <>
      <PaymentRowLayout first="Payment method:" third="Payment Amount" />
      <PaymentRowLayout
        first={`${paymentTypeName}${paymentMethodName}`}
        second={creditMaskedNumberOrPassCode}
        third={paymentFinalAmount}
      />
      {paymentVouchers.length > 0 &&
        paymentVouchers.map((paymentVoucher) => (
          <PaymentRowLayout
            first={`${
              paymentVoucher.voucherProgramName
            }${VoucherProgramType.getName(paymentVoucher.voucherProgramType)}`}
            second={paymentVoucher.voucherProgramCode}
            third={paymentVoucher.amountCollect.toFixed(6)}
          />
        ))}
      {paymentPasses.length > 0 && !isFullyPayPass && (
        <PaymentRowLayout
          first={paymentMethod}
          second={passPaymentCodes}
          third={`$ 0`}
        />
      )}
      {eVoucherProgram &&
        paymentEVouchers.length > 0 &&
        paymentEVouchers.map((voucher) => (
          <PaymentRowLayout
            key={voucher.id}
            first={`Evoucher<${eVoucherProgram.description}>`}
            second={voucher.eVoucherCode}
            third={(voucher?.amountCollect ?? 0).toFixed(6)}
          />
        ))}
      {isShowViewChargeStatusButton && (
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            onClick={onViewChargedStatus}
          >
            View Charged Status
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      )}
    </>
  );
};

export default TransactionDetailPaymentInformation;
