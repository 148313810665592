import React from "react";
import { Card } from "../../common/Card";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CorporateClient } from "../../../models/CorporateClient";
import _ from "lodash";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { RowFormField } from "../../layout/FormLayout";

export class CorporateClientCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: new CorporateClient(),
      redirect: false,
      showCancelDialog: false,
      showConfirmDialog: false,
      isClear: false,
      types: [],
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "create_corporate_clients",
        name: "Create Corporate Client",
      }),
    });
  };

  async componentDidMount() {
    this.getCompanyType();
  }

  getCompanyType = () => {
    RestClient.sendGetRequest(
      "/api/v1/corporate_booking_companies/types",
      (response) => {
        this.setState({ types: response });
      },
      (errorResponse) => toast.error(errorResponse.message)
    );
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onShowConfirmDialog = () => {
    this.setState({ showConfirmDialog: true });
  };

  onClearClick = () => {
    this.setState({
      instance: new CorporateClient(),
      isClear: !this.state.isClear,
    });
  };

  onProceedConfirmDialog = () => {
    const { instance } = this.state;
    const request = Object.assign({}, instance);
    RestClient.sendPostRequest(
      "/api/v1/corporate_booking_companies/",
      request,
      (response) => {
        this.setState({
          showConfirmDialog: false,
        });
        toast.success("Corporate Client have been created successfully.");
        this.props.history.push(`/corporate_clients/${response.id}`);
      },
      (errorResponse) => {
        this.setState({
          showConfirmDialog: false,
        });
        toast.error(errorResponse.message);
      }
    );
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  render() {
    const { showConfirmDialog, instance, types } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <Card title="Client Details">
              <div className="col-md-8">
                <RowFormField label="Company Code:" htmlFor="CompanyCode">
                  <input
                    className="form-control"
                    id="CompanyCode"
                    placeholder="Enter Company Code"
                    value={instance.code}
                    onChange={this.onInputChange}
                    name="code"
                  />
                </RowFormField>
                <RowFormField htmlFor="CompanyName" label="Company Name:">
                  <input
                    className="form-control"
                    id="CompanyName"
                    placeholder="Enter Company Name"
                    value={instance.name}
                    onChange={this.onInputChange}
                    name="name"
                  />
                </RowFormField>
                <RowFormField label="Company Type:" htmlFor="CompanyType">
                  <select
                    className="form-control"
                    id="CompanyType"
                    value={instance.companyType}
                    onChange={this.onInputChange}
                    valuetype="number"
                    name="companyType"
                  >
                    <option value="">Select Company Type</option>
                    {types.map((object) => {
                      return (
                        <option value={object.id} key={object.id}>
                          {object.name}
                        </option>
                      );
                    })}
                  </select>
                </RowFormField>
                <RowFormField label="Client Name:" htmlFor="ContactPerson">
                  <input
                    className="form-control"
                    id="ContactPerson"
                    placeholder="Enter Client Name "
                    value={instance.clientName}
                    onChange={this.onInputChange}
                    name="clientName"
                  />
                </RowFormField>
                <RowFormField labe="Contact Number:" htmlFor="ContactNumber">
                  <input
                    className="form-control"
                    type="tel"
                    inputMode="tel"
                    id="ContactNumber"
                    placeholder="Enter Contact number"
                    value={instance.contactNumber}
                    onChange={this.onInputChange}
                    name="contactNumber"
                  />
                </RowFormField>
                <RowFormField label="Email:" htmlFor="Email">
                  <input
                    className="form-control"
                    id="Email"
                    type="email"
                    inputMode="email"
                    placeholder="Enter Email Address"
                    value={instance.emailAddress}
                    onChange={this.onInputChange}
                    name="emailAddress"
                  />
                </RowFormField>
              </div>
            </Card>
            <div className="d-flex flex-row justify-content-end pb-3">
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger mr-1"
                onClick={this.onClearClick}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to create Corporate Client?"
          title="Create Corporate Client"
        />
      </div>
    );
  }
}
