import { RestService } from "./RestService";

export class CorporateBookingRestService extends RestService {
  constructor() {
    super("api/v1/corporate_bookings");
  }

  async autoSelectSeats(payload) {
    return await this.postImpl("auto_select_seats", payload);
  }

  async holdSeat(payload) {
    return await this.postImpl("hold_seats", payload);
  }

  async releaseSeat(payload) {
    return await this.postImpl("release_seats", payload);
  }

  async blockSeat(payload) {
    return await this.postImpl("block_seats", payload);
  }

  async unblockSeat(payload) {
    return await this.postImpl("unblock_seats", payload);
  }
}
