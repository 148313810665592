import { isNil } from "lodash";
import { AllowedTiming } from "../constants/PricingConstants";
import { LocationBrandsEnum } from "../common/Constants";
import { Service } from "./Service";
import { StringHelper } from "../common/Helpers";
import { getDateTimeLocal } from "../common/Common";

const getDefaultStartOn = () => {
  const date = new Date();
  date.setHours(7, 0, 0, 0);
  return getDateTimeLocal(date);
}

const getDefaultEndOn = () => {
  const date = new Date();
  date.setHours(6, 59, 0, 0);
  return getDateTimeLocal(date, 0);
}
export class SpecialPrice {
  constructor(data) {
    this.id = data.id ?? 0;
    this.movieReleaseId = data.movieReleaseId ?? 0;
    this.locationVenueId = data.locationVenueId ?? null;
    this.serviceId = data.serviceId ?? 0;
    this.startOn = isNil(data.startOn)
      ? getDefaultStartOn()
      : StringHelper.toDateTimeString(data.startOn);

    this.endOn = isNil(data.endOn)
      ? getDefaultEndOn()
      : StringHelper.toDateTimeString(data.endOn);
    this.createdOn = data.createdOn ?? "";
    this.isActive = data.isActive ?? true;
    this.allowedTiming = data.allowedTiming ?? AllowedTiming.None;
    this.allowedLocationBrands = data.allowedLocationBrands ?? LocationBrandsEnum.None;
    this.service = new Service(data.service ?? {});
    this.movieRelease = data.movieRelease ?? {};
  }
}