import React, { Component } from "react";
import '../css/documentation.scss';

export class EditMovieVoucherHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Movie Voucher Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 6 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Movie Vouchers” link. This will take you to the Movie Vouchers Listing Page.<br />
                                    <img src="/img/documentation-img/MovieVchImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Movie Vouchers Listing Page, click on any movie voucher listing of your choice. This will take you to the View Movie Vouchers Details.<br />
                                    <img src="/img/documentation-img/MovieVchImg/listing_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To edit the movie voucher listing, click on the “Edit” button in the top right corner of the page.<br />
                                    <img src="/img/documentation-img/MovieVchImg/view_edit.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    In Edit Movie Vouchers Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Voucher Code
                                        </li>
                                        <li>
                                            Voucher Program Name
                                        </li>
                                        <li>
                                            Pricing Information
                                        </li>
                                        <li>
                                            Voucher Category
                                        </li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Voucher Description
                                        </li>
                                        <li>
                                            Voucher Image
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Voucher Description should be in HTML format.<br />
                                            - Voucher Description has a maximum of 8000 characters.
                                </div>
                            </li>
                                        <li class="createList">
                                            To preview the movie voucher listing, click on “Preview” in the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/MovieVchImg/preview_cancelpreview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To save your changes, click on “Save Changes” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_save.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_save_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful save, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_success_toaster.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm".<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_cancel_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To schedule a movie voucher, click on the “Schedule” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_schedule.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Select your date and time for publishing and click on “Confirm” to schedule the movie voucher for publishing.<br />
                                            <img src="/img/documentation-img/MovieVchImg/edit_schedule_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful scheduling, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/MovieVchImg/schedule_success_toaster.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To view the scheduled listing, follow the steps listed below.
                                            <ol class='alphaList'>
                                                <li>
                                                    Using the menu on the left, click on "Website Management", then click on the “Movie Vouchers” link. This will take you to the Movie Vouchers Listing Page.<br />
                                                    <img src="/img/documentation-img/MovieVchImg/navbar.png" class="userImage" />
                                                </li>
                                                <li>
                                                    To view a scheduled movie voucher, click on “Scheduled Publishing” in the top right corner of the Movie Vouchers Listing Page.<br />
                                                    <img src="/img/documentation-img/MovieVchImg/listing_scheduled.png" class="userImage" />
                                                </li>
                                                <li>
                                                    You will then be able to view your scheduled movie voucher.<br />
                                                    <img src="/img/documentation-img/MovieVchImg/scheduled_success_endpoint.png" class="userImage" />
                                                </li>

                                            </ol>
                                        </li>
                                        <li class="createList">
                                            To view your history changes, click on the 'History' button, at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/MovieVchImg/view_history.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A popup will appear, stating the changes made. Click on the listing to see more.<br />
                                            <img src="/img/documentation-img/MovieVchImg/history_modal.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A pop-up will appear, stating the old and new values.<br />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/movievouchers/CreateMovieVoucherHelp" className="btn btn-primary">&laquo; Create Movie Voucher</a>&nbsp;
                                <a href="/documentation/movievouchers/ViewScheduledMovieVoucherHelp" className="btn btn-primary">View Scheduled Movie Voucher &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}