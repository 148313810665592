import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingFnbCampaignsAtom } from "./Report.state";

const QUERY_KEY_REPORTING_FNB_CAMPAIGNS = "QUERY_KEY_REPORTING_FNB_CAMPAIGNS";

export function useReportingFnbCampaigns() {
  const [fnbCampaigns, setFnbCampaigns] = useRecoilState(
    reportingFnbCampaignsAtom
  );
  useQuery(
    QUERY_KEY_REPORTING_FNB_CAMPAIGNS,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/fnb_campaigns"
      ).list();
    },
    {
      onSuccess: (response) => setFnbCampaigns(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return fnbCampaigns;
}
