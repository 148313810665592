import React from 'react';
import { ImageHelper } from '../../../../common/Helpers';
import './PromotionPreview.scss';

export const PromotionPreview = (props) => {
    const promotion = props.promotion || {};
    return (
        <div className="main-content promotions-detail-content">
            <div className="container">
                <div className="row">
                    <div className="handlebar-promotion-head">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="content-wrap p-d row">
                                    <div className="col-md-4 col-xs-12">
                                        <div className="block-tablet hide-for-desktop display_none_ie7">
                                            <h2 title={promotion.name}>{promotion.name}</h2>
                                        </div>
                                        <div className="block-img">
                                            <a className="image-overlay-box cboxElement" href={ImageHelper.getPosterUrl(promotion.poster)}>
                                                <img src={ImageHelper.getPosterUrl(promotion.poster)} alt={promotion.name} onError={ImageHelper.noImageUrl} className="img-responsive gm-loaded gm-observing gm-observing-cb" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-md-8 col-xs-12">
                                        <div className="block-tablet-desktop show-for-desktop-only">
                                            <h2 title={promotion.name}>{promotion.name}</h2>
                                        </div>
                                        <div className="col-right">
                                            <div className="block-info">
                                                <div className="desc" dangerouslySetInnerHTML={{ __html: promotion.description }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
