import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingSafraCampaignsAtom } from "./Report.state";

const QUERY_KEY_REPORTING_SAFRA_CAMPAIGNS = "QUERY_KEY_REPORTING_SAFRA_CAMPAIGNS";

export function useReportingSafraCampaigns() {
  const [safraCampaigns, setSafraCampaigns] = useRecoilState(reportingSafraCampaignsAtom);
  useQuery(
      QUERY_KEY_REPORTING_SAFRA_CAMPAIGNS,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/safra_campaigns"
      ).list();
    },
    {
      onSuccess: (response) => setSafraCampaigns(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return safraCampaigns;
}
