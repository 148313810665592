import React from 'react';
import { AuthenticationManager, ResourceID } from '../../../common/AuthenticationManager';
import { log } from '../../../common/Common';
import { StringHelper } from '../../../common/Helpers';
import RestClient from '../../../common/RestClient';
import { Card } from '../../common/Card';
import Validators from '../../common/Validators';
import { CardMode } from './CardMode';

export class GeneralInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowAllDepartment: AuthenticationManager.isAuthorized(ResourceID.AccessAllDepartments),
      cardMode: CardMode.View,
      departmentOptions: [],
      designationOptions: [],
      isDirty: false,
      model: this.mergeDefaultValue(),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    if (this.state.allowAllDepartment) {
      this.getDepartmentOptions('');
    }
    else {
      this.getDepartmentOptions(AuthenticationManager.departmentId())
    }
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(val => val.fieldName === fieldName && !val.isValid);
    const isEnabled = CardMode.isEditMode(this.state.cardMode) && this.props.isSubmit;
    return isEnabled && isError ? 'form-control is-invalid' : 'form-control';
  }

  getDepartmentOptions(departmentId) {
    RestClient.sendGetRequestWithParameters('/api/v1/departments/options', { departmentId }, response => {
      this.setState({ departmentOptions: response.data });
    }, error => {
      log(error);
    })
  }

  getDesignationOptions(departmentId) {
    RestClient.sendGetRequestWithParameters('/api/v1/designations/options', { departmentId }, response => {
      this.setState({ designationOptions: response.data });
    }, error => {
      log(error);
    })
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      departmentId: value.departmentId || '',
      designationId: value.designationId || '',
      effectiveEndDateStr: value.effectiveEndDate || '',
      effectiveStartDateStr: value.effectiveStartDate || '',
      email: value.email || '',
      firstName: value.firstName || '',
      lastName: value.lastName || '',
      middleName: value.middleName || '',
      personalEmail: value.personalEmail || '',
      personalPhoneNumber: value.personalPhoneNumber || '',
      phoneNumber: value.phoneNumber || '',
      remark: value.remark || '',
      salutation: value.salutation || '',
    };
  }

  onDepartmentChange = (e) => {
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, { departmentId: value, designationId: '' });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
    this.getDesignationOptions(value);
  }

  onDesignationChange = (e) => {
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, { designationId: value });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  }

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute('fieldname');
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, { [fieldName]: value });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations]
      onValidationsChange(validations);
    }
  }

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(val => val.fieldName === e.fieldName && val.type === e.type);
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  }

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      })
      if (!StringHelper.isNullOrEmpty(model.departmentId)) {
        this.getDesignationOptions(model.departmentId);
      }
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      })
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    const { isSubmit } = this.props;
    const { cardMode, departmentOptions, designationOptions, model } = this.state;
    return (
      <Card title="General Info">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="StaffSalutationDropDown" className="form-control-label">Staff Salutation<span className="color-red font-weight-bold">*</span>:</label>
            <select
              id="StaffSalutationDropDown"
              disabled={CardMode.isViewMode(cardMode) ? 'disabled' : undefined}
              className={this.getControlClassName('salutation')}
              fieldname="salutation"
              onChange={this.onTextBoxChange} value={model.salutation}>
              <option value="">Please select</option>
              <option value="Mr">Mr</option>
              <option value="Ms">Ms</option>
              <option value="Mrs">Mrs</option>
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="salutation" isEnabled={isSubmit}
              property="Salutation"
              value={model.salutation}>
            </Validators.RequiredValidator>
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="FirstName" className="form-control-label">First Name<span className="color-red font-weight-bold">*</span>:</label>
            <input
              type="text"
              id="FirstName"
              className={this.getControlClassName('firstName')}
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="firstName"
              onChange={this.onTextBoxChange}
              value={model.firstName} />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="firstName" isEnabled={isSubmit}
              property="First Name"
              value={model.firstName}>
            </Validators.RequiredValidator>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="MiddleName" className="form-control-label">Middle Name:</label>
            <input
              type="text"
              id="MiddleName"
              className={this.getControlClassName('middleName')}
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="middleName"
              onChange={this.onTextBoxChange}
              value={model.middleName} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="LastName" className="form-control-label">Last Name<span className="color-red font-weight-bold">*</span>:</label>
            <input
              type="text"
              id="LastName"
              className={this.getControlClassName('lastName')}
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="lastName"
              onChange={this.onTextBoxChange}
              value={model.lastName} />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="lastName"
              isEnabled={isSubmit}
              property="Last Name"
              value={model.lastName}>
            </Validators.RequiredValidator>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="StartDate" className="form-control-label">Effective Start Date<span className="color-red font-weight-bold">*</span>:</label>
            <input
              type={CardMode.isViewMode(cardMode) ? 'text' : 'date'}
              id="StartDate" className={this.getControlClassName('effectiveStartDateStr')}
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="effectiveStartDateStr"
              onChange={this.onTextBoxChange}
              value={StringHelper.asDateStringToIsoDateFormat(model.effectiveStartDateStr)} />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="effectiveStartDateStr"
              isEnabled={isSubmit}
              property="Effective Start Date"
              value={model.effectiveStartDateStr}>
            </Validators.RequiredValidator>
            <Validators.CompareDateValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="effectiveStartDateStrRange"
              isEnabled={isSubmit}
              startDate={model.effectiveStartDateStr}
              endDate={model.effectiveEndDateStr}
              property="Effective Start Date"
              value={model.effectiveStartDateStr}
              message={`Effective Start Date should be a date before Effective End Date.`} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="EndDate" className="form-control-label">Effective End Date:</label>
            <input
              type={CardMode.isViewMode(cardMode) ? 'text' : 'date'}
              id="EndDate" className="form-control"
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="effectiveEndDateStr"
              onChange={this.onTextBoxChange}
              value={StringHelper.asDateStringToIsoDateFormat(model.effectiveEndDateStr)} />
            <Validators.CompareDateValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="effectiveEndDateStrRange"
              isEnabled={model.effectiveStartDateStr.length > 0 && model.effectiveEndDateStr.length > 0}
              startDate={model.effectiveStartDateStr}
              endDate={model.effectiveEndDateStr}
              property="Effective End Date"
              value={model.effectiveEndDateStr}
              message={`Effective End Date should be a date after Effective Start Date.`} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="PersonalEmail1" className="form-control-label">Personal Email:</label>
            <input
              type="email"
              id="PersonalEmail1"
              className="form-control"
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="personalEmail"
              onChange={this.onTextBoxChange}
              value={model.personalEmail} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="WorkEmail1" className="form-control-label">Work Email:</label>
            <input
              type="email"
              id="WorkEmail1"
              className={this.getControlClassName('email')}
              fieldname="email"
              readOnly={CardMode.isViewMode(cardMode)}
              onChange={this.onTextBoxChange}
              value={model.email} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="PersonalNumber1" className="form-control-label">Personal Contact Number:</label>
            <input
              type="tel"
              id="PersonalNumber1"
              className="form-control"
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="personalPhoneNumber"
              onChange={this.onTextBoxChange}
              value={model.personalPhoneNumber} />
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="WorkNumber1" className="form-control-label">Work Contact Number:</label>
            <input
              type="tel"
              id="WorkNumber1"
              className="form-control"
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="phoneNumber"
              onChange={this.onTextBoxChange}
              value={model.phoneNumber} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label htmlFor="DepartmentDropDown" className="form-control-label">Department<span className="color-red font-weight-bold">*</span>:</label>
            <select
              id="DepartmentDropDown"
              disabled={CardMode.isViewMode(cardMode) ? 'disabled' : undefined}
              className={this.getControlClassName('departmentId')}
              fieldname="departmentId"
              onChange={this.onDepartmentChange}
              value={model.departmentId}>
              <option value=''>Select Department</option>
              {departmentOptions.map(option =>
                <option key={option.id} value={option.id}>{option.name}</option>
              )}
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="departmentId"
              isEnabled={isSubmit}
              property="Department"
              value={model.departmentId}>
            </Validators.RequiredValidator>
          </div>
          <div className="col-md-6 mb-3">
            <label htmlFor="DesignationDropDown" className="form-control-label">Designation<span className="color-red font-weight-bold">*</span>:</label>
            <select
              id="DesignationDropDown"
              disabled={CardMode.isViewMode(cardMode) ? 'disabled' : undefined}
              className={this.getControlClassName('designationId')}
              fieldname="designationId"
              onChange={this.onDesignationChange}
              value={model.designationId}>
              <option value=''>Select Designation</option>
              {designationOptions.map(option =>
                <option key={option.id} value={option.id}>{option.name}</option>
              )}
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="designationId"
              isEnabled={isSubmit}
              property="Designation"
              value={model.designationId}>
            </Validators.RequiredValidator>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <label htmlFor="Remarks" className="form-control-label">Remarks:</label>
            <textarea
              id="Remarks"
              className="form-control"
              readOnly={CardMode.isViewMode(cardMode)}
              fieldname="remark"
              onChange={this.onTextBoxChange}
              value={model.remark}>
            </textarea>
          </div>
        </div>
      </Card>
    )
  }
}
