import React from 'react';
import { Card } from '../../../common/Card';
import { StatusColor, VoucherExtensionStatus } from "../../../../common/Constants";
import { CardMode } from '../../../common/CardMode';

export class VoucherExtensionRequestDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError = this.state.validations.some(val => val.fieldName === fieldName && !val.isValid) && this.props.isSubmit && CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? 'is-invalid' : ''} ${defaultClass || ''}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      serialNumber: value.serialNumber || '',
      status: value.status || '',
      reason: value.reason || '',
      extensionCriteria: value.extensionCriteria || '',
      voucherCount: value.voucherCount || '',
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  }

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute('fieldname');
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, { [fieldName]: value });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  }

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  getStatusColor = (status) => {
    if(!status) return "";
    if (status === VoucherExtensionStatus.Pending)
      return StatusColor.Orange;
    if (status === VoucherExtensionStatus.Approved)
      return StatusColor.Green;
    if (status === VoucherExtensionStatus.Rejected || status === VoucherExtensionStatus.Cancelled)
      return StatusColor.Red;
    return status;
  };

  render() {
    return (
      <Card title="Voucher Request Details">
        {this.renderViewMode()}
      </Card>
    )
  }

  renderViewMode() {
    const { model } = this.state;
    return (
      <>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="extensionid">
              Serial Number (Range):
            </label>
            <input
              className="form-control display-input"
              type="text"
              id="SerialNo"
              value={model?.serialNumber ?? ""}
              readOnly
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="Status">
              Status:
            </label>
            <input
              className={`form-control display-input ` + this.getStatusColor(model?.status ?? "")}
              type="text"
              id="Status"
              value={VoucherExtensionStatus.getVoucherExtensionStatus(model?.status ?? "")}
              readOnly
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="reason">
              Reason For Voucher Extension:
            </label>
            <input
              className="form-control display-input"
              type="text"
              id="reason"
              value={model?.reason ?? ""}
              readOnly
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="Type">
              Extension Criteria:
            </label>
            <input
              className="form-control display-input"
              type="text"
              id="Type"
              value={model?.extensionCriteria}
              readOnly
            />
          </div>
        </div>
        <div id="VCount" className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="criteria">
              Voucher Count:
            </label>
            <input
              className="form-control display-input"
              type="number"
              id="count"
              value={model?.voucherCount}
              readOnly
            />
          </div>
        </div>
      </>
    )
  }
}
