import React, { useEffect, useState } from "react";
import { Classify } from "../../../constants/MovieConstants";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignGlobalRefreshAtom,
  isSelectedPerformanceConditionsAtom,
  validForSpecificClassificationSelector,
} from "../CampaignState";
import { ClassificationConditionDialog } from "../dialogs/ClassificationConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const ClassificationConditionCard = (props) => {
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const isSelectedPerformance = useRecoilValue(
    isSelectedPerformanceConditionsAtom
  );
  const globalRefresh = useRecoilValue(campaignGlobalRefreshAtom);
  const validForSpecificClassification = useSetRecoilState(
    validForSpecificClassificationSelector
  );
  const { isEdit } = props;

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificClassification);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/classification",
      { campaignId: campaign.id },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh, globalRefresh]);

  const onDeleteElement = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/classification/${selectedId}`,
      () => {
        toast.success(
          "The classification condition has been deleted successful."
        );
        setRefresh((r) => r + 1);
        validForSpecificClassification(data.length > 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onCreate = (classifies) => {
    const params = {
      classifies: classifies,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/classification",
      params,
      (_) => {
        toast.success(
          "The campaign classification condition has been created successful."
        );
        validForSpecificClassification(true);
        setRefresh((r) => r + 1);
      },
      handleError
    );
    setShowCreateDialog(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_classification_conditions`,
      () => {
        toast.success(
          "All classification conditions have been deleted successful."
        );
        setRefresh((ref) => ref + 1);
        validForSpecificClassification(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">
          Classification Condition
        </label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="classificationCondition"
              id="noClassificationCondition"
              value="No"
              checked={!showTable}
              onChange={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="noClassificationCondition"
            >
              No Classification restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="classificationCondition"
              id="yesClassificationCondition"
              value="Yes"
              checked={showTable}
              onChange={() => setShowTable(true)}
            />
            <label
              className="form-check-label"
              htmlFor="yesClassificationCondition"
            >
              Valid for specific Classification
            </label>
            <button
              type="button"
              id="classNameificationConditionBtn"
              name="classNameificationCondition"
              data-toggle="modal"
              data-target="#classNameifyConditionModal"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th>Classification</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data?.map((classify, index) => (
              <tr key={index}>
                <td>{Classify.getName(classify.classify)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setSelectedId(classify.id);
                      setShowDeleteConfirmation(true);
                    }}
                    disabled={!isEdit}
                  >
                    <i className="far fa-trash-alt" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onDeleteElement}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <ClassificationConditionDialog
        visible={showCreateDialog}
        onConfirm={onCreate}
        onCancel={() => setShowCreateDialog(false)}
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
    </>
  );
};
