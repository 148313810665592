import React, { useState } from "react";
import { CampaignBundleItem } from "./CampaignBundleItem";
import { NewCampaignBundle } from "./NewCampaignBundle";
import { useRecoilValue } from "recoil";
import { campaignBundlesAtom, locationsAtom } from "../CampaignState";
import { Card, generateCardId } from "../../common/Card";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { useServices } from "../../../hooks/useServices";
import { Collapse } from "bootstrap";

export const CampaignBundleCard = () => {
  const campaignBundles = useRecoilValue(campaignBundlesAtom);
  const [showAddModal, setShowAddModal] = useState(false);
  const locations = useRecoilValue(locationsAtom);
  const { services } = useServices();
  
  const onDuplicateSuccess = () => {
    const cardId = generateCardId(campaignBundles.length);
    setTimeout(() => {
      const el = document.getElementById(`body-${cardId}`);
      if (el) {
        const col = new Collapse(el);
        col.show();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <Card title="Bundles">
      <div className="card-body bundle">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            onClick={() => setShowAddModal(true)}
          >
            Add Bundle
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {showAddModal && (
          <NewCampaignBundle onCancel={() => setShowAddModal(false)} />
        )}
        {campaignBundles.map((campaignBundle, index) => (
          <CampaignBundleItem
            campaignBundle={campaignBundle}
            idx={index}
            key={index}
            locations={locations}
            services={services}
            onDuplicateSuccess={onDuplicateSuccess}
          />
        ))}
      </div>
    </Card>
  );
};
