import React from "react";
import { BundleSalesChannels } from "../../bundles/CampaignBundleItem";
import { ApplicationID } from "../../../../common/Constants";
import { QuantityMechanicsType } from "../../../../constants/CampaignConstants";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignExtensionPropertiesSelector,
  campaignSalesChannelsSelector,
  quantityAppSpecificSelector,
} from "../../CampaignState";
import _ from "lodash";
import { NumberInput } from "../../NumberInput";
import { QuantityLimitTypeCard } from "./QuantityLimitTypeCard";

export const ApplicationSpecificCard = (props) => {
  const [data, setData] = useRecoilState(quantityAppSpecificSelector);
  const campaignSalesChannels = useRecoilValue(campaignSalesChannelsSelector);
  const setCampaign = useSetRecoilState(campaignAtom);
  const campaignExtensionProperties = useRecoilValue(
    campaignExtensionPropertiesSelector
  );
  const { disabled } = props;

  const onInputChange = (e) => {
    let clonedData = _.clone(data);
    const key = `${e.target.value}`;
    if (e.target.checked) {
      clonedData[key] = 0;
    } else {
      delete clonedData[key];
    }
    setData(clonedData);
  };

  const onInputValueChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    setData((instance) => {
      return {
        ...instance,
        [fieldName]: +e.target.value,
      };
    });
  };

  return (
    <>
      <div>
        <input
          className="mr-1"
          type="radio"
          valuetype="number"
          name="quantityMechanicsType"
          value={QuantityMechanicsType.ApplicationSpecificQuantity}
          onChange={(e) => {
            setCampaign((instance) => {
              return {
                ...instance,
                quantityMechanicsType: +e.target.value,
                campaignQuantityAvailability: [],
              };
            });
          }}
          checked={
            QuantityMechanicsType.ApplicationSpecificQuantity ===
            campaignExtensionProperties.quantityMechanicsType
          }
          disabled={disabled}
        />
        <label className="form-check-label">
          Application Specific Quantity
        </label>
      </div>
      {campaignExtensionProperties.quantityMechanicsType ===
        QuantityMechanicsType.ApplicationSpecificQuantity && (
        <>
          <div className="row mt-3">
            <div className="col-md-6">
              <QuantityLimitTypeCard disabled={disabled} />
            </div>
          </div>
          <div className="row">
            {ApplicationID.All.filter(
              (x) => BundleSalesChannels.indexOf(x.value) >= 0
            ).map((x, index) => {
              const appIdKey = `${x.value}`;
              return (
                <div className="col-md-6" key={index}>
                  <div className="form-group row">
                    <div className="col-md-4">
                      <input
                        className="mr-1"
                        type="checkbox"
                        value={x.value}
                        onChange={onInputChange}
                        disabled={
                          disabled || !campaignSalesChannels.includes(x.value)
                        }
                        checked={appIdKey in data}
                      />
                      <label className="form-check-label">{x.name}</label>
                    </div>
                    <div className="col-md-8">
                      <NumberInput
                        name={x.value}
                        value={data[appIdKey] ?? 0}
                        onChange={onInputValueChange}
                        placeholder="Value"
                        disabled={disabled || !(appIdKey in data)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
