import React from "react";
import { DateHelper, StringHelper } from "../../../../common/Helpers";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { ColumnFormField } from "../../../layout/FormLayout";

export class CareerDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      benefit: value.benefit || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      name: value.name || "",
      requirement: value.requirement || "",
      responsibility: value.responsibility || "",
      summary: value.summary || "",
      weightDisplay: value.weightDisplay || 0,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    return (
      <Card title="Career Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const {
      benefit,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      requirement,
      responsibility,
      summary,
      weightDisplay,
    } = this.state.model;
    return (
      <React.Fragment>
        <div className="row">
          <ColumnFormField>
            <div className="row form-group">
              <ColumnFormField label="Career Title:">
                <input
                  className="form-control display-input"
                  type="text"
                  id="CareerTitle"
                  placeholder="Enter Career Title"
                  readOnly={true}
                  value={name}
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Display Weight:">
                <input
                  className="form-control display-input"
                  type="number"
                  id="DisplayWeight"
                  placeholder="Enter Display Weight"
                  min="1"
                  max="100"
                  readOnly={true}
                  value={weightDisplay}
                />
              </ColumnFormField>
            </div>
          </ColumnFormField>
          <ColumnFormField>
            <div className="row form-group">
              <ColumnFormField label="Display Start:">
                <input
                  className="form-control display-input"
                  id="StartDate"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayStartTimeStr
                  )}
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Display End:">
                <input
                  className="form-control display-input"
                  id="EndDate"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayEndTimeStr
                  )}
                />
              </ColumnFormField>
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="description">
              Short Description:
            </label>
            <textarea
              className="form-control display-input resize-none"
              rows="5"
              placeholder="Description"
              maxLength="4000"
              readOnly={true}
              value={summary}
            ></textarea>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="responsibilities">
              Responsibilities:
            </label>
            <textarea
              className="form-control display-input resize-none"
              rows="5"
              placeholder="Responsibilities"
              maxLength="4000"
              readOnly={true}
              value={responsibility}
            ></textarea>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="requirements">
              Requirements:
            </label>
            <textarea
              className="form-control display-input resize-none"
              rows="5"
              placeholder="Requirements"
              maxLength="4000"
              readOnly={true}
              value={requirement}
            ></textarea>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="routedetails">
              Benefits:
            </label>
            <textarea
              className="form-control display-input resize-none"
              rows="5"
              placeholder="Benefits"
              maxLength="4000"
              readOnly={true}
              value={benefit}
            ></textarea>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="longDescription">
              Long Description:
            </label>
            <textarea
              className="form-control display-input resize-none"
              rows="10"
              placeholder="Long Description"
              maxLength="12000"
              readOnly={true}
              value={description}
            ></textarea>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const {
      benefit,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      requirement,
      responsibility,
      summary,
      weightDisplay,
    } = this.state.model;
    return (
      <React.Fragment>
        <div className="row">
          <ColumnFormField>
            <div className="row form-group">
              <ColumnFormField
                label={
                  <>
                    Career Title:<span className="color-red">*</span>
                  </>
                }
                htmlFor="careerTitle"
              >
                <input
                  type="text"
                  id="CareerTitle"
                  placeholder="Enter Career Title"
                  className={this.getControlClassName("name")}
                  fieldname="name"
                  onChange={this.onTextBoxChange}
                  value={name}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="name"
                  isEnabled={isSubmit}
                  property="Career Title"
                  value={name}
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField
                label={
                  <>
                    Display Weight:<span className="color-red">*</span>
                  </>
                }
                htmlFor="displayweight"
              >
                <input
                  type="number"
                  id="DisplayWeight"
                  placeholder="Enter Display Weight"
                  min="1"
                  max="100"
                  className={this.getControlClassName("weightDisplay")}
                  fieldname="weightDisplay"
                  onChange={this.onTextBoxChange}
                  value={weightDisplay}
                />
                <Validators.RangeValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="weightDisplay"
                  isEnabled={isSubmit}
                  min="1"
                  max="100"
                  property="Display Weight"
                  value={weightDisplay}
                  allowDecimal={true}
                />
              </ColumnFormField>
            </div>
          </ColumnFormField>
          <ColumnFormField>
            <div className="row form-group">
              <ColumnFormField
                htmlFor="startDate"
                label={
                  <>
                    Display Start:<span className="color-red">*</span>
                  </>
                }
              >
                <input
                  type="datetime-local"
                  id="StartDate"
                  className={this.getControlClassName("displayStartTimeStr")}
                  fieldname="displayStartTimeStr"
                  onChange={this.onTextBoxChange}
                  value={displayStartTimeStr}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="displayStartTimeStr"
                  isEnabled={isSubmit}
                  property="Display Start DateTime"
                  value={displayStartTimeStr}
                />
              </ColumnFormField>
            </div>
            <div className="row form-group">
              <ColumnFormField label="Display End:" htmlFor="endDate">
                <input
                  type="datetime-local"
                  id="EndDate"
                  className={this.getControlClassName("displayEndTimeStr")}
                  fieldname="displayEndTimeStr"
                  onChange={this.onTextBoxChange}
                  value={displayEndTimeStr}
                />
                <Validators.DateTimeFromToValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="displayEndTimeStr"
                  isEnabled={isSubmit}
                  fromProperty="Display Start DateTime"
                  fromValue={displayStartTimeStr}
                  toProperty="Display End DateTime"
                  toValue={displayEndTimeStr}
                />
              </ColumnFormField>
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="description">
              Short Description:
            </label>
            <textarea
              rows="5"
              placeholder="Description"
              maxLength="4000"
              className={this.getControlClassName("summary", "resize-none")}
              fieldname="summary"
              onChange={this.onTextBoxChange}
              value={summary}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="summary"
              isEnabled={isSubmit}
              maxLength="4000"
              property="Short Description"
              value={summary}
            />
            <label className="form-control-label float-right" htmlFor="Desc">
              <small>
                <span className="counter">{summary.length}</span>/4000
              </small>
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="responsibilities">
              Responsibilities:
            </label>
            <textarea
              rows="5"
              placeholder="Responsibilities"
              maxLength="4000"
              className={this.getControlClassName(
                "responsibility",
                "resize-none"
              )}
              fieldname="responsibility"
              onChange={this.onTextBoxChange}
              value={responsibility}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="responsibility"
              isEnabled={isSubmit}
              maxLength="4000"
              property="Responsibilities"
              value={responsibility}
            />
            <label className="form-control-label float-right" htmlFor="Desc">
              <small>
                <span className="counter">{responsibility.length}</span>/4000
              </small>
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="requirements">
              Requirements:
            </label>
            <textarea
              rows="5"
              placeholder="Requirements"
              maxLength="4000"
              className={this.getControlClassName("requirement", "resize-none")}
              fieldname="requirement"
              onChange={this.onTextBoxChange}
              value={requirement}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="requirement"
              isEnabled={isSubmit}
              maxLength="4000"
              property="Requirements"
              value={requirement}
            />
            <label className="form-control-label float-right" htmlFor="Desc">
              <small>
                <span className="counter">{requirement.length}</span>/4000
              </small>
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="routedetails">
              Benefits:
            </label>
            <textarea
              rows="5"
              placeholder="Benefits"
              maxLength="4000"
              className={this.getControlClassName("benefit", "resize-none")}
              fieldname="benefit"
              onChange={this.onTextBoxChange}
              value={benefit}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="benefit"
              isEnabled={isSubmit}
              maxLength="4000"
              property="Benefits"
              value={benefit}
            />
            <label className="form-control-label float-right" htmlFor="Desc">
              <small>
                <span className="counter">{benefit.length}</span>/4000
              </small>
            </label>
          </div>
        </div>
        <div className="row form-group">
          <div className="col">
            <label className="form-control-label" htmlFor="longDescription">
              Long Description:
            </label>
            <textarea
              rows="10"
              placeholder="Long Description"
              maxLength="12000"
              className={this.getControlClassName("description", "resize-none")}
              fieldname="description"
              onChange={this.onTextBoxChange}
              value={description}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="description"
              isEnabled={isSubmit}
              maxLength="12000"
              property="Long Description"
              value={description}
            />
            <label className="form-control-label float-right" htmlFor="Desc">
              <small>
                <span className="counter">{description.length}</span>/12000
              </small>
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
