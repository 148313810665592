import React, { Component } from "react";
import "../css/documentation.scss";

export class CreateUser extends Component {
  render() {
    return (
      <div className="main-content">
        <div className="section__content section__content--p30 createContent">
          <div className="container-fluid">
            <h1 style={{ fontSize: "36px", marginTop: "30px" }}>
              How To Create A New User
            </h1>
            <p
              className="dateFormat"
              style={{ fontSize: "14px", color: "grey" }}
            >
              18/11/2021 ▪ 3 minutes to read
            </p>
            <br />
            <br />
            <div className="createUser container-fluid">
              <ol>
                <li className="createList">
                  Click on <strong>'User'</strong> link from main left side
                  menu. This will bring you to User Listing Page. <br />
                  <img
                    src="/img/documentation-img/users/Users_Menu.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  In User listing page, click <strong>‘Create New User’</strong>{" "}
                  button located in the top right of the page. This will bring
                  you to <strong>Create User Page</strong>
                  <br />
                  <img
                    src="/img/documentation-img/users/CreateUserButton.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  Fill in required fields for{" "}
                  <strong>General Information</strong> section. <br />
                  <img
                    src="/img/documentation-img/users/GeneralCardCreate.png"
                    className="userImage"
                  />
                  <br />
                  <br />
                  <div className="alert alert-primary" role="alert">
                    <i className="fas fa-info-circle" />
                    <strong className="noteHeader">Information:</strong>
                    <br />
                    <br />
                    Personal Email field allows emails ending with @gmail.com,
                    however Work Email is restricted to accepting emails that
                    end with @shaw.com.sg or @sbmedia.sg
                  </div>
                </li>
                <li className="createList">
                  Fill in required fields for{" "}
                  <strong>Staff Authentication section</strong>
                  <br />
                  <img
                    src="/img/documentation-img/users/StaffAuthCreate.png"
                    className="userImage"
                  />
                  <br />
                  <br />
                  <div className="alert alert-primary" role="alert">
                    <i className="fas fa-info-circle" />
                    <strong className="noteHeader">Information:</strong>
                    <br />
                    <br />
                    <b>User Login Id: </b>This will be used as username/login Id
                    to log into connect portal and box office POS applications.
                    <br />
                    <b>Password:</b> You can key in your own password or
                    generate a strong random password from ‘Generate Password’
                    button. Remember to take note of the password if you choose
                    to generate password.
                    <br />
                    <b>Staff Role:</b> One user can have many staff roles
                    depending on the actions they need to perform. These staff
                    roles are tied with the user’s ‘Department’ selected in
                    ‘General Info’ section.
                  </div>
                </li>
                <li className="createList">
                  Fill in required fields for{" "}
                  <strong>Application Access</strong> section.
                  <br />
                  <img
                    src="/img/documentation-img/users/AppAccCreate.png"
                    className="userImage"
                  />
                </li>
                <li className="createList">
                  Upon filling in all required fields down to the bottom. The
                  user may click among the following buttons: &nbsp;&nbsp;
                  <ul className="createList">
                    <li className="createList">
                      <strong>Create</strong> – This will open a ‘Confirmation’
                      modal before proceeding to creating the user. Upon
                      confirmation, the ‘user’ will be created and the page will
                      redirect back to the Listing page. From the listing page,
                      the created user will be shown at the topmost record from
                      the listing table.
                      <br />
                      <img
                        src="/img/documentation-img/users/CreateConfirm.png"
                        className="userImage"
                      />
                      <br />
                      <br />
                      <br />
                      <img
                        src="/img/documentation-img/users/AfterCreate.png"
                        className="userImage"
                      />
                    </li>
                    <li className="createList">
                      <strong>Clear</strong> – This button will clear the fields
                      and will need the user’s confirmation in the form of the
                      below confirmation modal. Click ‘Confirm’ to clear all
                      fields of available data
                      <br />
                      <img
                        src="/img/documentation-img/users/ConfirmClearCreate.png"
                        className="userImage"
                      />
                    </li>
                  </ul>
                </li>
                        </ol>
            <div className="text-right">
            <a href="/documentation/users/updateUser" className="btn btn-primary">Update User &raquo;</a>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
