import React from "react";

function generateRandomId() {
  return "id-" + Math.random().toString(36).substr(2, 9) + "-" + Date.now();
}
export const generateCardId = (idx, parentIdx = "") => `card-${idx ?? generateRandomId()}${parentIdx}`;

export class Card extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        collapsible: props.collapsible || false,
      };
      this.defaultWrapperCss = "card-block text-left";
  }

  render() {
    const { children, title, wrapperCss, isActive, parentidx, idx } = this.props;
    const { collapsible } = this.state;
    const targetString = generateCardId(idx, parentidx);
    const bodyWrapperCss = wrapperCss ?? this.defaultWrapperCss;
    return (
      <div
        className="card"
        id={`header-${targetString}`}
        style={isActive ? { borderColor: "orange" } : {}}
      >
        <div className="card-header accordion-header">
          <button
            className={`accordion-button ${collapsible ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#body-${targetString}`}
            aria-expanded="true"
            aria-controls={`body-${targetString}`}
          >
            {title}
          </button>
        </div>
        <div
          className={`card-body ${bodyWrapperCss} accordion-collapse collapse ${
            collapsible ? "" : "show"
          }`}
          id={`body-${targetString}`}
          aria-labelledby={`header-${targetString}`}
          data-bs-parent={`#header-${targetString}`}
        >
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    );
  }
}

export class ErrorCard extends React.Component {
  render() {
    return (
      <div className="alert alert-danger alert-dismissible show" role="alert">
        <div><strong>Error</strong></div>
        <div>{this.props.error}</div>
        <button aria-label="Close" className="close" type="button" onClick={this.props.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }
}

export class WarningCard extends React.Component {
  render() {
    return (
      <div className="alert alert-warning alert-dismissible show" role="alert">
        <div>{this.props.warning}</div>
        <button aria-label="Close" className="close" type="button" onClick={this.props.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }
}

export class SuccessCard extends React.Component {
  render() {
    return (
      <div className="alert alert-success alert-dismissible show" role="alert">
        <div>{this.props.message}</div>
        <button aria-label="Close" className="close" type="button" onClick={this.props.onClose}>
          <span aria-hidden="true">×</span>
        </button>
      </div>
    )
  }
}
