import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { ContestDetailCard } from "./cards/ContestDetailCard";
import { ContestPreview } from "./preview/ContestPreview";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const EditPageName = "Edit Scheduled Contest";
const ViewPageName = "View Scheduled Contest";

export class ScheduledContestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.ScheduleView,
      generalInfo: null,
      generalInfoValidations: [],
      scheduledContest: null,
      scheduledContestId: "",
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
      showDeleteModal: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const scheduledContestId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || scheduledContestId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ scheduledContestId }, this.refreshData);
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadScheduledContest(id) {
    RestClient.sendGetRequest(
      `/api/v1/scheduled_contests/${id}`,
      (response) => {
        this.setState({ scheduledContest: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onEditContestClick = () => {
    this.setState({ cardMode: CardMode.ScheduleUpdate });
  };

  onDelete = () => {
    this.setState({ showDeleteModal: true });
  };

  onCancel = () => {
    this.setState({ showDeleteModal: false });
  };

  onProceedDelete = () => {
    const { scheduledContestId } = this.state;
    RestClient.sendDeleteRequest(
      `/api/v1/scheduled_contests/${scheduledContestId}`,
      () => {
        toast.success("The scheduled contest has been deleted successful.");
        this.redirectToListingPage();
      }, (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
    this.setState({ showDeleteModal: false });
  }

  onGeneralInfoModelChange = (model) => {
    const contestStartTime = StringHelper.toDateTimeString(
      model.contestStartTimeStr
    );
    const contestEndTime = StringHelper.toDateTimeString(
      model.contestEndTimeStr
    );
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const planToPublish = StringHelper.toDateTimeString(model.planToPublishStr);
    const category = StringHelper.toNumber(model.category, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      category,
      contestStartTime,
      contestEndTime,
      displayStartTime,
      displayEndTime,
      planToPublish,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.ScheduleView,
      scheduledContest: Object.assign({}, this.state.scheduledContest),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo, scheduledContestId } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/scheduled_contests/${scheduledContestId}`,
      request,
      (response) => {
        toast.success(GenericMessages.SaveChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.ScheduleView,
          isSubmit: false,
          scheduledContest: response,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/contests/schedules/${additionalParameter ?? ""}`);
  }

  refreshData() {
    const { scheduledContestId } = this.state;
    this.loadScheduledContest(scheduledContestId);
  }

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "contests",
        name: "Contests",
      }),
      activeModule: new NavigationItem({
        identifier: "scheduled-contest-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      scheduledContest,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showDeleteModal,
    } = this.state;
    if (showPreview) {
      const previewModel = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <ContestPreview contest={previewModel} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    {
                      AuthenticationManager.isAuthorized(
                        ResourceID.ContestScheduledEditButtonPage
                      ) && (
                        <div>
                          <button
                            className="btn btn-primary ml-2 mb-2"
                            value="Edit"
                            disabled={scheduledContest ? undefined : "disabled"}
                            onClick={this.onEditContestClick}
                          >
                            <i className="fas fa-edit"></i> Edit
                          </button>
                          <button
                            className="btn btn-danger ml-2 mb-2"
                            value="Delete Schedule"
                            disabled={scheduledContest ? undefined : "disabled"}
                            onClick={this.onDelete}
                          > Delete Schedule
                          </button></div>
                      )
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <ContestDetailCard
                  cardMode={cardMode}
                  defaultValue={scheduledContest}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger mb-1"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && scheduledContest}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && scheduledContest}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showDeleteModal}
          message={"Are you sure you want to delete this schedule contest?"}
          onProceed={this.onProceedDelete}
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}