import { useEffect, useState } from "react";
import RestClient from "../../common/RestClient";
import { toast } from "react-toastify";

export function usePerformance(performanceId) {
  const [performance, setPerformance] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationVenue, setLocationVenue] = useState(null);
  useEffect(() => {
    if (!performanceId || performanceId <= 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      `api/v1/performances/${performanceId}`,
      {
        includes: "Location,Service",
      },
      (performance) => {
        setPerformance(performance);
        setLocationVenue(performance.locationVenue);
        setLocation(performance.locationVenue.location);
      },
      (error) => toast.error(error.message)
    );
  }, [performanceId]);

  return {
    performance,
    location,
    locationVenue,
  };
}
