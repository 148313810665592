import React, { useState } from "react";
import { ReportParameterValueType } from "./Constants";
import { CustomSelect } from "../../common/CustomSelect";
import { InputFormItem } from "./InputFormItem";
import moment from "moment";
import { DateHelper, isMobileOrTablet } from "../../../common/Helpers";

export const determineInputType = (type) => {
  switch (type) {
    case ReportParameterValueType.Date:
      return "date";
    case ReportParameterValueType.Decimal:
      return "number";
    case type === ReportParameterValueType.Datetime:
      return "datetime-local";
    case type === ReportParameterValueType.Numeric:
      return "number";
    case type === ReportParameterValueType.Time:
      return "time";
    default:
      return "text";
  }
};

export const InputForm = (props) => {
  const [data, setData] = useState({});
  const { schemas, onChange } = props;

  const onSelect = (item, fieldName) => {
    const newData = {
      ...data,
      [fieldName]: item.value,
    };
    setData(newData);
    onChange(newData);
  };

  const onInputChange = (e) => {
    e.persist();
    let value = e.target.value;
    if (e.target.type === "date") {
      value = moment(value).format(DateHelper.DisplayDateFormat);
    }
    const newData = {
      ...data,
      [e.target.name]: value,
    };
    setData(newData);
    onChange(newData);
  };

  const isSelection = (type, options) =>
    type === ReportParameterValueType.String && options && options.length > 0;

  const displayConfig = isMobileOrTablet()
    ? {
        menuPlacement: "auto",
        maxMenuHeight: "150",
      }
    : {};

  return (
    <>
      {schemas.map((x, index) => {
        if (x.isSelection || isSelection(x.type, x.options)) {
          return (
            <InputFormItem
              key={index}
              label={x.name}
              required={x.required}
              component={
                <CustomSelect
                  data={x.options}
                  label={x.displayOptionField}
                  value={x.valueOptionField}
                  onChange={(e) => onSelect(e, x.field)}
                  selectedValue={data[x.field] ?? x.defaultSelection}
                  compareFunc={x.compareFunc}
                  {...displayConfig}
                />
              }
            />
          );
        }
        return (
          <InputFormItem
            key={index}
            label={x.name}
            required={x.required}
            component={
              <input
                className="form-control display-input"
                type={determineInputType(x.type)}
                onChange={onInputChange}
                name={x.field}
                value={data[x.name]}
              />
            }
          />
        );
      })}
    </>
  );
};
