import React from "react";
import { Card } from "../../../common/Card";

const MovieMarathonDetailCard = (props) => {
  const {
    shortSynopsis,
    displayEndOn,
    displayStartOn,
    primaryTitle,
    labelDisplay,
    posterUrl,
    displayWeight,
  } = props;
  return (
    <Card title="Movie Marathon Details">
      <div className="row">
        <div className="col" style={{ paddingLeft: "30px" }}>
          <div className="row form-group">
            <div className="col-11">
              <label className="form-control-label" htmlFor="FestivalTitle">
                Movie Marathon Title:
              </label>
              <input
                className="form-control display-input"
                id="FestivalTitle"
                value={primaryTitle ?? ""}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row form-group">
            <label className="col form-control-label" htmlFor="StartDate">
              Display Start DateTime:
            </label>
            <div className="col-11">
              <input
                className="form-control display-input"
                id="StartDate"
                type="datetime-local"
                value={displayStartOn ?? ""}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row form-group">
            <label className="col form-control-label" htmlFor="EndDate">
              Display End DateTime:
            </label>
            <div className="col-11">
              <input
                className="form-control display-input"
                id="EndDate"
                type="datetime-local"
                value={displayEndOn ?? ""}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row ">
            <label className="col form-control-label" htmlFor="Weight">
              Display Weight:
            </label>
            <div className="col-11">
              <input
                className="form-control display-input"
                id="Weight"
                type="number"
                placeholder="Enter Display Weight"
                min="1"
                max="100"
                value={displayWeight ?? 0}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <label className="col form-control-label" htmlFor="Desc">
              Movie Marathon Description:
            </label>

            <div className="col-11">
              <textarea
                className="form-control m-r-15 resize-none display-input"
                maxLength="12000"
                rows="2"
                id="Desc"
                placeholder="Description"
                readOnly={true}
                value={shortSynopsis ?? ""}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row form-group">
            <div className="col">
              <label className="form-control-label" htmlFor="ImgUrl">
                Marathon Image:
              </label>
              <img
                src={posterUrl ?? ""}
                style={{ maxHeight: "230px" }}
                id="img"
                className="float-right m-l-15"
              />
            </div>
          </div>
          <div className="row form-group">
            <div className="col">
              <label className="form-control-label" htmlFor="Code">
                Label Display:
              </label>
              <input
                className="form-control display-input"
                id="Code"
                type="text"
                placeholder="Enter Code"
                min="1"
                max="100"
                value={labelDisplay ?? ""}
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MovieMarathonDetailCard