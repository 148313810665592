export class ReportParameterValue {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.reportParameterID = data?.reportParameterID ?? "";
    this.reportParameterValueCode = data?.reportParameterValueCode ?? "";
    this.reportParameterValueName = data?.reportParameterValueName ?? "";
    this.displayWeight = data?.displayWeight ?? "";
    this.isDefault = data?.isDefault ?? false;
  }
}

export class ReportParameterRegister {
  constructor(data) {
    this.id = data?.id ?? 0;
    this.reportID = data?.reportID ?? 0;
    this.reportParameterCode = data?.reportParameterCode ?? "";
    this.reportParameterName = data?.reportParameterName ?? "";
    this.reportParameterValueType = data?.reportParameterValueType ?? "";
    this.isMandatory = data?.isMandatory ?? false;
    this.displayWeight = data?.displayWeight ?? 1;
    this.reportParameterValues =
      data?.reportParameterValues?.map((x) => new ReportParameterValue(x)) ??
      [];
  }
}

export class ReportRegister {
  constructor(data) {
    this.reportRecordID = data?.reportRecordID ?? 0;
    this.reportCode = data?.reportCode ?? "";
    this.reportName = data?.reportName ?? "";
    this.reportDescription = data?.reportDescription ?? "";
    this.reportParameterRegisters =
      data?.reportParameterRegisters?.map(
        (x) => new ReportParameterRegister(x)
      ) ?? [];
  }
}
