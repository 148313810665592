import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

export const BreadCrumb = (props) => {
  const { navigationSettings } = props;

  const { rootModule, parentModule, activeModule } = navigationSettings;

  return (
    <div className="breadcrumb">
      <div>
        <strong>{_.startCase(activeModule.name)}</strong>
      </div>
      <ol className="right">
        {rootModule && (
          <li>
            <a>{_.startCase(rootModule.name)}</a>
            <span className="divider">/</span>
          </li>
        )}
        {parentModule && (
          <li>
            {!_.isNil(rootModule) ? (
              <Link
                to={{ pathname: `/${parentModule.identifier}` }}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {_.startCase(parentModule.name)}
              </Link>
            ) : (
              <a>{_.startCase(parentModule.name)}</a>
            )}
            <span className="divider">/</span>
          </li>
        )}
        <li>
          <a>{_.startCase(activeModule.name)}</a>
        </li>
      </ol>
    </div>
  );
};
