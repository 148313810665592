import React, { Component } from "react";
import '../css/documentation.scss';

export class EditLocationScheduledHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Scheduled Publishing</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 10 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Locations" link. This will take you to the Locations Listing Page.  <br />
                                    <img src="/img/documentation-img/location/location_navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To view Locations Scheduled Publishing, click on "Scheduled Publishing".<br />
                                    <img src="/img/documentation-img/location/scheduled_publishing.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    To view Locations Scheduled Publishing Details, click on any scheduled publishing listing.<br />
                                    <img src="/img/documentation-img/location/select_scheduled_locations_details.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    Click on “Edit”, on the top right corner of View Scheduled Location Details Page. This will take you to the Edit Location Page.<br />
                                    <img src="/img/documentation-img/location/edit_scheduled_location.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Edit Location Page, you will be able to see the following editable fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Location Code
                                        </li>
                                        <li>
                                            Location Address
                                        </li>
                                        <li>
                                            Location Name
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Event Format Types
                                        </li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Theatre Location Map
                                        </li>
                                        <li>
                                            Details
                                        </li>
                                        <li>
                                            Access Route Details
                                        </li>
                                        <li>
                                            Photos
                                        </li>
                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Theatre Location Map should have a maximum of 4000 characters.<br />
                                            - Details and Access Routes Details should be in HTML format.<br />
                                            - Details should have a maximum of 8000 characters.<br />
                                            - Access Route Details should have a maximum of 4000 characters.
                                </div>
                            </li>

                                        <li class="createList">
                                            To view the preview image of the locations, click on the “Preview” button, under the “Photos” section.<br />
                                            <img src="/img/documentation-img/location/edit_preview_button.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To delete the image, click on the trash button.<br />
                                            <img src="/img/documentation-img/location/delete_image.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm deletion of the image, click on the “Confirm” button. <br />
                                            <img src="/img/documentation-img/location/Confirm_delete_picture.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To add an image, type in the Image URL and Display Weight. Then click “Add”.<br />
                                            <img src="/img/documentation-img/location/Add_image.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To preview the location listing, click on “Preview”.<br />
                                            <img src="/img/documentation-img/location/preview_schedule_location_edit.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” in the top right corner.<br />
                                            <img src="/img/documentation-img/location/cancel_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To save your edit changes, click on “Save Changes” in the bottom left corner.<br />
                                            <img src="/img/documentation-img/location/save_changes.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/location/confirm_save_changes.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful save, a message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/location/success_toaster_savechanges_editlocatioon.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/location/schedule_edit_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm". <br />
                                            <img src="/img/documentation-img/location/schedule_edit_cancel_confirm.png" class="userImage" />
                                        </li>

                            </ol>
                            <div className="text-right">
                                <a href="/documentation/locations/viewLocationScheduledHelp" className="btn btn-primary">&laquo; View Scheduled Location</a>&nbsp;
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
        );
    }
}