import React from "react";
import { AuthenticationManager } from "../../common/AuthenticationManager";
import { ApiResultCodes, StorageKeys } from "../../common/Constants";
import RestClient from "../../common/RestClient";
import { Card } from "../common/Card";
import Validators from "../common/Validators";
import { toast } from "react-toastify";
import Loader from "../common/Loader";

export class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      password: "",
      showResetPasswordDialog: false,
      username: "",
      validations: [],
      isLoading: false
    };
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return this.state.isSubmit && isError
      ? "form-control is-invalid"
      : "form-control";
  }

  login(username, password) {
    this.setState({ isLoading: true });
    RestClient.sendPostRequest(
      "/api/v1/auth",
      { username, password },
      (response) => {
        if (response.isSuccess) {
          AuthenticationManager.saveAuthInfo(response);
          const returnPath = localStorage.getItem(StorageKeys.ReturnPath);
          if (returnPath) {
            this.props.history.replace(returnPath);
          } else {
            this.props.history.replace("/");
          }
        }
      },
      (errorResponse) => {
        this.setState({ isLoading: false });
        if (errorResponse.code === ApiResultCodes.PasswordChange) {
          this.setState({ showResetPasswordDialog: true});
          return;
        }
        toast.error(errorResponse.message);
      }
    );
  }

  onHideResetPasswordDialog = (e) => {
    this.setState({
      showResetPasswordDialog: false,
      password: e.clearPassword ? "" : this.state.password,
    });
  };

  onKeyDownInput = async (e) => {
    if (e.keyCode === 13) {
      await this.onSubmitClick();
    }
  };

  onPasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  onSubmitClick = async () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, isLoading: false });
      return;
    }
    const { password, username } = this.state;
    this.login(username, password);
  };

  onUsernameChange = (e) => {
    this.setState({ username: e.target.value });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  redirectToChangePasswordPage = () => {
    this.props.history.push(`/changePassword/`);
  }

  render() {
    const { isSubmit, password, showResetPasswordDialog, username, isLoading } =
      this.state;

    return (
      <div className="page-wrapper" id="loginpagelayout">
        <div className="container" id="flex">
          <div className="row" id="adjusttomiddle">
            <div className="col-md-12">
              <div className="login-logo">
                <span className="sprite-logo-shaw-black" id="adjusttomiddle2" />
              </div>
            </div>
            <div className="col-md-12">
              <div className="login-form">
                <Card title="Welcome to Shaw Connect Portal!">
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="login_username"
                    >
                      Username
                    </label>
                    <input
                      className={this.getControlClassName("username")}
                      id="login_username"
                      name="username"
                      onChange={this.onUsernameChange}
                      onKeyDown={this.onKeyDownInput}
                      type="text"
                      value={username}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="username"
                      isEnabled={isSubmit}
                      property="Username"
                      value={username}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="form-control-label"
                      htmlFor="login_password"
                    >
                      Password
                    </label>
                    <input
                      className={this.getControlClassName("password")}
                      id="login_password"
                      name="password"
                      onChange={this.onPasswordChange}
                      onKeyDown={this.onKeyDownInput}
                      type="password"
                      value={password}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="password"
                      isEnabled={isSubmit}
                      property="Password"
                      value={password}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-primary float-right"
                      name="submit"
                      onClick={this.onSubmitClick}
                      type="button"
                    >
                      Login
                    </button>
                  </div>
                </Card>
              </div>
            </div>
            {/*<div className="col-md-12 aligningtextcenter">*/}
            {/*  <button*/}
            {/*    className="btn btn-link"*/}
            {/*    onClick={this.redirectToChangePasswordPage}*/}
            {/*  >*/}
            {/*    Forgot password?*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
          {showResetPasswordDialog && (
            <ResetPasswordDialog
              password={password}
              username={username}
              onClose={this.onHideResetPasswordDialog}
            />
          )}
        </div>
        {isLoading && <Loader />}
        <footer className="aligningtextcenter">
          <p>
            @ Copyright 2023 Shaw Organisation.
            <br />
            All rights reserved.
            <br />
            Co. Reg. No.: 194700158G
          </p>
        </footer>
      </div>
    );
  }
}

class ResetPasswordDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccess: false,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      username: "",
      validations: [],
    };
  }

  componentDidMount() {
    toast.warning("You need to change password before continuing");
  }

  changePassword(username, oldPassword, newPassword) {
    RestClient.sendPostRequest(
      "/api/v1/auth/change_password",
      { username, oldPassword, newPassword },
      () => {
        toast.success("Password is changed successfully.");
        this.setState({ isSuccess: true });
      },
      (error) => toast.error(error.message)
    );
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return this.state.isSubmit && isError
      ? "form-control is-invalid"
      : "form-control";
  }

  onCloseClick = () => {
    const { isSuccess } = this.state;
    if (this.props.onClose) {
      this.props.onClose({
        clearPassword: isSuccess,
      });
    }
  };

  onKeyDownInput = async (e) => {
    if (e.keyCode === 13) {
      await this.onSubmitClick();
    }
  };

  onSubmitClick = async () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    const { password, username } = this.props;
    const { newPassword } = this.state;
    this.changePassword(username, password, newPassword);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    this.setState({ [fieldName]: value });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  render() {
    const { password } = this.props;
    const { isSubmit, isSuccess, newPassword, newPasswordConfirm } = this.state;
    return (
      <React.Fragment>
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Reset Password
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.onCloseClick}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="form-control-label" htmlFor="old_password">
                    Old Password
                  </label>
                  <input
                    className="form-control"
                    id="old_password"
                    name="old_password"
                    fieldname="oldPassword"
                    type="password"
                    readOnly={true}
                    value={password}
                  />
                </div>
                <div className="form-group">
                  <label className="form-control-label" htmlFor="new_password">
                    New Password
                  </label>
                  <input
                    className={this.getControlClassName("newPassword")}
                    id="new_password"
                    name="new_password"
                    fieldname="newPassword"
                    onChange={this.onTextBoxChange}
                    onKeyDown={this.onKeyDownInput}
                    type="password"
                    readOnly={isSuccess}
                    value={newPassword}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="newPassword"
                    isEnabled={isSubmit}
                    property="New Password"
                    value={newPassword}
                  />
                </div>
                <div className="form-group">
                  <label
                    className="form-control-label"
                    htmlFor="new_password_confirm"
                  >
                    Confirm New Password
                  </label>
                  <input
                    className={this.getControlClassName("newPasswordConfirm")}
                    id="new_password_confirm"
                    name="new_password_confirm"
                    fieldname="newPasswordConfirm"
                    onChange={this.onTextBoxChange}
                    onKeyDown={this.onKeyDownInput}
                    type="password"
                    readOnly={isSuccess}
                    value={newPasswordConfirm}
                  />
                  <Validators.RequiredValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="newPasswordConfirm"
                    isEnabled={isSubmit}
                    property="New Password Confirm"
                    value={newPasswordConfirm}
                  />
                  <Validators.CompareValidator
                    onValidationStatusChange={this.onValidationStatusChange}
                    fieldName="confirmPassword"
                    isEnabled={isSubmit}
                    property="Confirm Password"
                    value={newPassword}
                    valueToCompare={newPasswordConfirm}
                  />
                </div>
              </div>
              <div className="modal-footer">
                {isSuccess ? (
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={this.onCloseClick}
                  >
                    Close
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={this.onSubmitClick}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    );
  }
}
