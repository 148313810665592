import RestClient from "../common/RestClient";
import { RestService } from "./RestService";

export class MovieReleaseService extends RestService {
  constructor() {
    super("/api/v1/movie_releases");
  }

  updateMedias(movieRelease, onSuccess, onError) {
    const data = {};
    data.movieReleaseMedias = movieRelease.movieReleaseMedias;
    return RestClient.sendPostRequest(
      `${this.baseUrl}/${movieRelease.id}/medias`,
      data,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }

  duplicateMedias(
    sourceMovieReleaseId,
    targetMovieReleaseId,
    onSuccess,
    onError
  ) {
    const data = {};
    data.targetMovieReleaseId = targetMovieReleaseId;
    return RestClient.sendPostRequest(
      `${this.baseUrl}/${sourceMovieReleaseId}/medias/duplicate`,
      data,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }

  duplicate(sourceMovieId, onSuccess, onError) {
    return RestClient.sendGetRequest(
      `${this.baseApi}/${sourceMovieId}`,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }
}
