import _ from "lodash";
import { DateHelper } from "./Helpers";

export class SystemSettingKeys {
  static APP_SETTINGS = "APP_SETTINGS";
  static PublicWebsiteUrl = "publicWebsiteUrl";
  static Version = "version";
  static PoolingVersionIntervalInSecond = "poolingVersionIntervalInSecond";
  static OnlineRefundTimeoutInMinutes = "onlineRefundTimeoutInMinutes";
  static SignalRUrl = "signalRUrl";
}

export class ApiResultCodes {
  static General = 100000;
  static NotFound = 100001;
  static InvalidProperty = 100002;
  static Duplicated = 100003;

  static RefundFailed = 100004;
  static PaymentFailed = 100005;
  static CheckoutFailed = 100006;

  static InvalidAction = 100009;
  static ExpiredRequest = 100010;
  static PaymentPending = 100011;
  static InvalidGrant = 100012;
  static PasswordChange = 100013;
  static Unknown = 999999;
}

export class ApplicationID {
  static TPOS = 1;
  static TKOS = 2;
  static PWSM = 3;
  static GPAY = 4;
  static INCP = 5;
  static TDSP = 6;
  static INTERNAL = 7;
  static EXTERNAL = 10;

  static All = [
    {
      name: "TPOS",
      value: this.TPOS,
    },
    {
      name: "TKOS",
      value: this.TKOS,
    },
    {
      name: "PWSM",
      value: this.PWSM,
    },
    {
      name: "GPAY",
      value: this.GPAY,
    },
    {
      name: "INCP",
      value: this.INCP,
    },
    {
      name: "TDSP",
      value: this.TDSP,
    },
    {
      name: "INTERNAL",
      value: this.INTERNAL,
    },
    {
      name: "EXTERNAL",
      value: this.EXTERNAL,
    },
  ];

  static SaleChannels = [
    {
      name: "TPOS",
      value: this.TPOS,
    },
    {
      name: "TKOS",
      value: this.TKOS,
    },
    {
      name: "PWSM",
      value: this.PWSM,
    },
    {
      name: "GPAY",
      value: this.GPAY,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class GenericMessages {
  static CancelChangesDialogMessage =
    "Are you sure you want to cancel the changes?";
  static CancelChangesDialogTitle = "Cancel changes";
  static CancelCreationDialogMessage = (itemInLowerCase) =>
    `Are you sure you want to cancel the creation of ${itemInLowerCase}?`;
  static CancelCreationDialogTitle = (itemInLowerCase) =>
    `Cancel ${itemInLowerCase} creation`;
  static CreationSuccessfulMessage = (itemInSentenceCase) =>
    `${itemInSentenceCase} have been created successfully.`;
  static ConfirmCreationDialogMessage = (itemInLowerCase) =>
    `Are you sure you want to create ${itemInLowerCase}?`;
  static ConfirmCreationDialogTitle = (itemInLowerCase) =>
    `Create ${itemInLowerCase}`;
  static PublishChangesSuccessfulMessage =
    "Changes have been published successfully.";
  static SaveChangesDialogMessage = "Are you sure you want to save changes?";
  static SaveChangesDialogTitle = "Save changes";
  static SaveChangesSuccessfulMessage = "Changes have been saved successfully.";
  static ScheduleChangesDialogMessage = (itemInLowerCase) =>
    `You are going to make changes to the ${itemInLowerCase} details. Please confirm to proceed.`;
  static ScheduleWarningDialogMessage = (itemInLowerCase) =>
    `Warning: This ${itemInLowerCase} has existing scheduled ${itemInLowerCase}(s).`;
  static ScheduleChangesDialogTitle = "Confirmation";
  static ScheduleChangesSuccessfulMessage =
    "Changes have been scheduled to publish successfully.";
  static CreateScheduleSuccessfulMessage = (filmFestival) =>
    `A Schedule for ${filmFestival} has been created successfully.`;
  static PublishPricingDialogTitle = "Confirm Pricing Update";
  static PublishPricingUpdateMessage =
    "Are you sure you want to publish the price updates? The number of Service Ticket Types to be updated is";
  static CancelPricingDialogTitle = "Cancel Pricing Update";
  static CreationExtensionSuccessfullMessage = `An extension request has been created.`;
  static CreationExtensionEvoucherSuccessfullMessage = `An extension request has been created for the eVoucher Code`;
  static DownloadBatchSuccessfullMessage =
    "Evoucher Batch has been downloaded successfully";
  static ApproveBatchSuccessfullMessage =
    "Evoucher Batch has been approved successfully";
  static RejectBatchSuccessfullMessage =
    "Evoucher Batch has been rejected successfully";
  static CancelBatchSuccessfullMessage =
    "Evoucher Batch has been cancelled successfully";
  static DownloadPassExtensionSuccessfullMessage =
    "Pass Extension has been downloaded successfully";
  static DeleteMovieMarathonMappingTitle = "Delete movie marathon";
  static DeleteMovieMarathonMappingMessage =
    "Are you sure want to delete movie marathon?";
  static DeleteMovieMediaPosterUrlTitle = "Delete movie media poster url";
  static DeleteMovieMediaPosterUrlMessage =
    "This action will remove all existing images on Website, POS, Kiosk. Are you sure you want to proceed?";
  static DuplicateMovieMediaTitle = "Duplicate movie media";
  static DuplicateMovieMediaMessage =
    "There are existing uploaded images. Once you confirm to duplicate, the existing media from this movie release will be deleted and replaced by the media from the duplicating source.";
}

export class PasswordPolicies {
  static HasDigit = true;
  static HasLowercase = true;
  static HasUppercase = true;
  static MinLength = 8;
  static MaxLength = 20;
}

export class LocationBrands {
  static Digital = "Digital";
  static Dreamers = "Dreamers";
  static Imax = "IMAX";
  static KinoLounge = "KinoLounge";
  static Lumiere = "Lumiere";
  static Premiere = "premiere";
}

export class LocationBrandsEnum {
  static None = 0;
  static Digital = 1;
  static Imax = 2;
  static Premiere = 4;
  static Lumiere = 8;
  static Dreamers = 16;
  static KinoLounge = 32;

  static LocationBrandMap = {
    [this.Imax]: LocationBrands.Imax,
    [this.Digital]: LocationBrands.Digital,
    [this.Premiere]: LocationBrands.Premiere,
    [this.Lumiere]: LocationBrands.Lumiere,
    [this.Dreamers]: LocationBrands.Dreamers,
    [this.KinoLounge]: LocationBrands.KinoLounge,
  };
  static getLocationBrand = (brand) => {
    if (!brand) return LocationBrands.Digital;
    return this.LocationBrandMap[brand];
  };
}

export class ProgrammeAvailabilitiesEnum {
  static None = 0;
  static Cinema = 1;
  static KinoLounge = 2;
}

export class PassProgramType {
  static None = 0;
  static External = 1;
  static Internal = 2;
}

export class PassProgramStatus {
  static NotSpecified = 0;
  static Active = 1;
  static Inactive = 2;
  static Expired = 3;
}

export class PassRedemptionMode {
  static NotSpecified = 0;
  static Counter = 1;
  static Online = 2;
}

export class PassStatus {
  static NotSpecified = 0;
  static Active = 1;
  static Inactive = 2;
  static Expired = 3;
}

export class PassMembershipCardType {
  static NotSpecified = 0;
  static Generic = 1;
  static Staff = 2;
  static Vip = 3;
  static Premiere = 4;
  static Disney = 5;
}

export class PassDiscountType {
  static PassComplimentary = 1;
  static PassPartial = 2;
}

export class PassDiscountProgram {
  static None = 0;
  static StaffPass = 1;
}

export class PromotionCategory {
  static NotSpecified = 0;
  static Discount = 1;
  static Event = 2;
  static FoodAndBeverage = 3;
  static InTheatre = 4;
  static Contest = 5;
}

export class VoucherTypeCategory {
  static NotSpecified = 0;
  static Personal = 1;
  static Corporate = 2;
  static Electronic = 3;
}

export class VoucherType {
  static None = 0;
  static Blue = 1;
  static PopcornCombo = 2;
  static ImaxCorporate = 3;

  static VoucherTypeMap = {
    [this.None]: "None",
    [this.Blue]: "Blue",
    [this.PopcornCombo]: "PopcornCombo",
    [this.ImaxCorporate]: "ImaxCorporate",
  };

  static getVoucherType = (type) => {
    if (!type) return "";
    return this.VoucherTypeMap[type];
  };
}

export class VoucherStatus {
  static None = 0;
  static Registered = 1;
  static Activated = 2;
  static Used = 3;
  static Expired = 4;

  static VoucherMap = {
    [this.None]: "None",
    [this.Registered]: "Registered",
    [this.Activated]: "Activated",
    [this.Used]: "Used",
    [this.Expired]: "Expired",
  };

  static getVoucherStatus(status) {
    if (!status) return "";
    return this.VoucherMap[status];
  }
}

export class SearchBy {
  static SingleSerialNumber = "SingleSerialNumber";
  static SerialNumberRange = "SerialNumberRange";
  static ValidityEndDateRange = "ValidityEndDateRange";
  static ValidityStartDateRange = "ValidityStartDateRange";
}

export class SearchEvoucherBy {
  static EVoucherBatchReference = "EVoucherBatchReference";
  static EVoucherOrderReference = "EVoucherOrderReference";
  static Code = "code";
}

export class SearchEvoucherBatchBy {
  static EVoucherBatchReference = "EVoucherBatchReference";
  static EVoucherOrderReference = "EVoucherOrderReference";
  static ExpiryDateRange = "ExpiryDateRange";
}

export class RegistrationType {
  static Individual = "Individual";
  static Multiple = "Multiple";
}

export class VoucherExtensionStatus {
  static All = 0;
  static Pending = 1;
  static Approved = 2;
  static Rejected = 3;
  static Cancelled = 4;

  static VoucherExtensionMap = {
    [this.All]: "All",
    [this.Pending]: "Pending",
    [this.Approved]: "Approved",
    [this.Rejected]: "Rejected",
    [this.Cancelled]: "Cancelled",
  };

  static getVoucherExtensionStatus(status) {
    if (!status) return "";
    return this.VoucherExtensionMap[status];
  }
}

export class StatusColor {
  static Orange = "color-orange";
  static Green = "color-green";
  static Red = "color-red";
  static Gray = "color-gray";
  static Purple = "color-purple";
}

export class EVoucherBatchStatus {
  static Pending = "Pending";
  static Approved = "Approved";
  static Rejected = "Rejected";
  static Expired = "Expired";
  static Cancelled = "Cancelled";
  static FullyRedeemed = "FullyRedeemed";

  static ColorMap = {
    [this.Pending]: StatusColor.Orange,
    [this.Approved]: StatusColor.Green,
    [this.Rejected]: StatusColor.Red,
    [this.Expired]: StatusColor.Red,
    [this.Cancelled]: StatusColor.Gray,
    [this.FullyRedeemed]: StatusColor.Green,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };
}

export class EVoucherStatus {
  static Active = "Active";
  static Used = "Used";
  static Expired = "Expired";
  static Blocked = "Blocked";
  static Refunded = "Refunded";
  static InUse = "InUse";

  static ColorMap = {
    [this.Active]: StatusColor.Green,
    [this.Used]: StatusColor.Orange,
    [this.Expired]: StatusColor.Orange,
    [this.Blocked]: StatusColor.Gray,
    [this.Refunded]: StatusColor.Gray,
    [this.InUse]: StatusColor.Orange,
  };

  static ValueMap = {
    [this.Active]: 1,
    [this.Used]: 2,
    [this.Expired]: 3,
    [this.Blocked]: 4,
    [this.Refunded]: 5,
    [this.InUse]: 6,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };
}

export class EVoucherExtensionStatus {
  static Pending = "Pending";
  static Approved = "Approved";
  static Rejected = "Rejected";
  static Cancelled = "Cancelled";

  static ColorMap = {
    [this.Pending]: StatusColor.Orange,
    [this.Approved]: StatusColor.Green,
    [this.Rejected]: StatusColor.Red,
    [this.Cancelled]: StatusColor.Gray,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }

    const value = map[status];
    if (!value) {
      return defaultColor;
    }

    return value;
  };
}

export class EVoucherBatchExtensionRequestStatus {
  static Pending = "Pending";
  static Approved = "Approved";
  static Rejected = "Rejected";
  static Cancelled = "Cancelled";

  static ColorMap = {
    [this.Pending]: StatusColor.Orange,
    [this.Approved]: StatusColor.Green,
    [this.Rejected]: StatusColor.Red,
    [this.Cancelled]: StatusColor.Gray,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };
}

export class TypeProceedRequest {
  static Approve = "approve";
  static Reject = "reject";
  static Cancel = "cancel";
}

export class ExtensionCriteriaType {
  static PreDefinedDate = "PreDefinedDate";
  static AddingDuration = "AddingDuration";
}


export class VoucherProgramType {
  static Internal = 1;
  static External = 2;

  static All = [
    {
      name: "Shaw Internal Vouchers",
      code: "I",
      value: this.Internal,
    },
    {
      name: "External Vouchers",
      code: "E",
      value: this.External,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class VoucherProgramDurationType {
  static Day = 1;
  static Month = 2;
  static Year = 3;
  
  static All = [
    {
      name: "Day",
      value: this.Day,
    },
    {
      name: "Month",
      value: this.Month,
    },
    {
      name: "Year",
      value: this.Year,
    },
  ];
  
  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class VoucherProgramMedium {
  static Physical = 1;
  static Digital = 2;

  static All = [
    {
      name: "Physical Vouchers",
      code: "P",
      value: this.Physical,
    },
    {
      name: "Digital Vouchers",
      code: "D",
      value: this.Digital,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class VoucherExtensionRequestFieldName {
  static PreDefinedDate = "preDefinedDate";
  static ExtensionDuration = "extensionDuration";
  static Reason = "reason";
}

export class YesNoOptions {
  static Yes = 1;
  static No = 0;

  static YesNoMap = {
    [this.Yes]: "Yes",
    [this.No]: "No",
  };
  static getYesNoOptions = (option) => {
    const map = this.YesNoMap;
    if (!option) {
      return map[this.No];
    }
    const value = map[option];
    if (!value) {
      return map[this.No];
    }
    return value;
  };
}

export class DefaultSelection {
  static NotSelect = 0;
}

export class ObjectType {
  static UserManagement = 1;
  static EvoucherBatch = 2;
  static EvoucherOrder = 3;
  static Evoucher = 4;
  static EvoucherExtensionRequest = 5;
  static EvoucherBatchExtensionRequest = 6;
  static Location = 7;
  static Career = 8;
  static Promotion = 9;
  static VoucherType = 11;
  static FilmFestival = 12;
  static Kinolounge = 13;
  static CorporateBooking = 14;
  static CorporateClient = 15;
  static VoucherExtensionRequestDetail = 16;
  static CorporateBookingRequest = 17;
  static Service = 18;
  static ServiceTicketPrice = 19;
  static Surcharge = 20;
  static RefundRequest = 21;
  static Movie = 22;
  static MovieRelease = 23;
  static MovieReleaseMedia = 24;
  static Performance = 25;
  static SurchargePrice = 26;
  static ServiceTicket = 27;
  static Campaign = 28;
  static MovieReleaseRestriction = 29;
  static Voucher = 32;
  static ScheduledFilmFestival = 33;
  static CustomContent = 34;
  static Pass = 35;
  static Transaction = 36;
  static FilmFestivalContent = 37;
  static VoucherRequest = 38;
  static Product = 39;
  static ProductMenu = 40;
  static SpecialPrice = 42;
}

export class HistoryMessageTypes {
  static EvoucherBatchDownloaded = 10301;
  static DistributeTicket = 11417;
  static HallTransactionDownloaded = 12534;
}

export class DefaultDate {
  static ExpiryDateYearDefault = 2999;

  static getExpiryDateDefault(seed) {
    const date = seed ? new Date(seed) : new Date();
    const month = date.getMonth();
    const day = date.getDate();
    return DateHelper.toIsoDateFormat(
      new Date(DefaultDate.ExpiryDateYearDefault, month, day)
    );
  }
}

export class CustomContentType {
  static Content = 0;
  static Page = 1;

  static CustomContentTypeMap = {
    [this.Content]: "Content",
    [this.Page]: "Page",
  };

  static getCustomContentType(status) {
    return this.CustomContentTypeMap[status];
  }
}

export class CustomContentStatus {
  static Inactive = 0;
  static Active = 1;

  static CustomContentStatusMap = {
    [this.Inactive]: "Inactive",
    [this.Active]: "Active",
  };

  static getCustomContentStatus(status) {
    return this.CustomContentStatusMap[status];
  }
}

export class PersonInCharge {
  static Unassigned = "Unassigned";
}

export class UserAction {
  static Logout = "Logout";
}

export class PerformanceSelectorType {
  static Date = 0;
  static Movie = 1;
  static Location = 2;
}

export class EvoucherOrderStatus {
  static Pending = 0;
  static Approved = 1;
  static Cancelled = 2;
  static Rejected = 3;

  static All = [
    {
      name: "Pending",
      value: this.Pending,
    },
    {
      name: "Approved",
      value: this.Approved,
    },
    {
      name: "Cancelled",
      value: this.Cancelled,
    },
    {
      name: "Rejected",
      value: this.Rejected,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }

  static ColorMap = {
    ["Pending"]: StatusColor.Orange,
    ["Approved"]: StatusColor.Green,
    ["Rejected"]: StatusColor.Red,
    ["Cancelled"]: StatusColor.Gray,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };
}

export const roundAmount = (amount) => {
  const numWith6Digit = amount.toFixed(7);
  const dotIndex = numWith6Digit.indexOf(".");
  const numOfDigital = numWith6Digit.length - 1 - dotIndex;

  let numberWithoutDigitPoint =
    parseFloat(numWith6Digit) * Math.pow(10, numOfDigital);

  const numberWithoutDigitStr = `${numberWithoutDigitPoint}`;
  const lastNumber = parseInt(
    numberWithoutDigitStr.substring(
      numberWithoutDigitStr.length - 1,
      numberWithoutDigitStr.length
    )
  );

  if (lastNumber >= 6) {
    numberWithoutDigitPoint = numberWithoutDigitPoint - lastNumber + 10;
  }

  return numberWithoutDigitPoint / Math.pow(10, numOfDigital);
};

export class StorageModule {
  static Movie = 1;
  static Promotion = 2;
  static Voucher = 3;
  static Location = 4;
  static Contest = 5;
  static All = [
    {
      name: "Movie",
      value: this.Movie,
    },
    {
      name: "Promotion",
      value: this.Promotion,
    },
    {
      name: "Voucher",
      value: this.Voucher,
    },
    {
      name: "Location",
      value: this.Location,
    },
    {
      name: "Contest",
      value: this.Contest,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class StorageKeys {
  static ReturnPath = "ReturnPath";
}

export const LongDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
