import React, { useState } from "react";
import { MovieBrand, MovieFormat } from "../../../constants/MovieConstants";
import { moviesAtom } from "../CampaignState";
import { useRecoilValue } from "recoil";
import { CustomSelect } from "../../common/CustomSelect";
import _ from "lodash";

export const MovieConditionDialog = (props) => {
  const [movieReleaseIds, setMovieReleaseIds] = useState([]);

  const movies = useRecoilValue(moviesAtom);

  const [activeMovie, setActiveMovie] = useState(null);

  const { onConfirm, onCancel, visible } = props;

  const onSelect = (event) => {
    const found = movies.find((x) => x.id === event.value);
    setActiveMovie(found);
  };

  const onAddClick = () => {
    onConfirm(movieReleaseIds);
    setMovieReleaseIds([]);
  };

  const onSelectMovieRelease = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setMovieReleaseIds((x) => [...x, value]);
    } else {
      setMovieReleaseIds((x) => x.filter((x) => x !== value));
    }
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  return (
    <div
      className="modal fade show"
      id="EventConditionModal"
      role="dialog"
      style={{ paddingRight: "17px", display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Add Movie
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-5">
            <div className="row">
              <div className="col-3">
                Movie Title:
                <span className="color-red font-weight-bold">*</span>
              </div>
              <div className="col">
                <CustomSelect
                  data={movies}
                  label="primaryTitle"
                  value="id"
                  onChange={onSelect}
                  selectedValue={activeMovie?.id ?? 0}
                />
              </div>
            </div>
            <div className="row EventDetails mt-3">
              <label className="col-3">
                Movie Releases:
                <span className="color-red font-weight-bold">*</span>
              </label>
              <div className="col">
                {activeMovie?.movieReleases?.map((x) => (
                  <div className="" key={x.id}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="movieRelease"
                      value={x.id}
                      onChange={onSelectMovieRelease}
                    />
                    <label className="form-check-label">
                      {x.primaryTitle}&nbsp;-&nbsp;
                      {MovieBrand.getName(x.brand)}&nbsp;-&nbsp;
                      {MovieFormat.getName(x.format)}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn btn-danger"
              data-dismiss="modal"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn btn-success"
              data-dismiss="modal"
              onClick={onAddClick}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
