import {
  Country,
  Languages,
  MovieCategory,
  MovieOrigin,
} from "../../../constants/MovieConstants";
import React from "react";
import { Card } from "../../common/Card";
import { ColumnFormField } from "../../layout/FormLayout";

export const MovieDetailCard = (props) => {
  const { movie } = props;
  let movieLanguage = movie.language;

  if (!isNaN(+movieLanguage)) {
    movieLanguage =
      Languages.All.find((x) => x.value === +movieLanguage)?.name ||
      movieLanguage;
  }

  return (
    <Card title="Movie Information">
      <div className="row">
        <ColumnFormField label="ID:">
          <input
            className="form-control form-readonly"
            value={movie.id}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Primary Title:">
          <input
            className="form-control form-readonly"
            value={movie.primaryTitle}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Secondary Title:">
          <input
            className="form-control form-readonly"
            value={movie.secondaryTitle}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Movie Category:">
          <input
            className="form-control form-readonly"
            value={MovieCategory.getName(movie.movieCategory)}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Duration:">
          <input
            className="form-control form-readonly"
            value={movie.duration}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Movie Origin Country:">
          <input
            className="form-control form-readonly"
            value={Country.getName(movie.movieOriginCountry)}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Movie Origin:">
          <input
            className="form-control form-readonly"
            value={MovieOrigin.getName(movie.movieOrigin)}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Movie Distribution Company:">
          <input
            className="form-control form-readonly"
            value={movie.company.name}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Movie Language:">
          <input
            className="form-control form-readonly"
            value={movieLanguage}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Label Display:">
          <input
            className="form-control form-readonly"
            value={movie.labelDisplay}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Label Print:">
          <input
            className="form-control form-readonly"
            value={movie.labelPrint}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Display Weight:">
          <input
            className="form-control form-readonly"
            value={movie.displayWeight}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <ColumnFormField label="Website Url:">
          <input
            className="form-control form-readonly"
            value={movie.websiteUrl}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-check-label">Notice:</label>
          <textarea
            className="form-control form-readonly border-0"
            rows="5"
            value={movie.notice}
            readOnly
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-check-label">Short synopsis:</label>
          <textarea
            className="form-control form-readonly border-0"
            rows="5"
            value={movie.shortSynopsis}
            readOnly
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <label className="form-check-label">Full synopsis:</label>
          <textarea
            className="form-control form-readonly"
            rows="5"
            value={movie.fullSynopsis}
            readOnly
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">{props.children}</div>
      </div>
    </Card>
  );
};

export default MovieDetailCard;
