import React from "react";
import { BundleSalesChannels } from "../../bundles/CampaignBundleItem";
import { ApplicationID } from "../../../../common/Constants";
import { QuantityMechanicsType } from "../../../../constants/CampaignConstants";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignExtensionPropertiesSelector,
  campaignRedemptionLocationsSelector,
  campaignSalesChannelsSelector,
  locationsAtom,
  quantityApplicationLocationSpecificSelector,
} from "../../CampaignState";
import { NumberInput } from "../../NumberInput";
import _ from "lodash";
import { QuantityLimitTypeCard } from "./QuantityLimitTypeCard";

export const ApplicationLocationSpecificCard = (props) => {
  const [data, setData] = useRecoilState(
    quantityApplicationLocationSpecificSelector
  );
  const campaignExtension = useRecoilValue(campaignExtensionPropertiesSelector);
  const campaignRedemptionLocations = useRecoilValue(
    campaignRedemptionLocationsSelector
  );
  const campaignSalesChannels = useRecoilValue(campaignSalesChannelsSelector);
  const setCampaign = useSetRecoilState(campaignAtom);
  const campaignExtensionProperties = useRecoilValue(
    campaignExtensionPropertiesSelector
  );
  const locations = useRecoilValue(locationsAtom);
  const { disabled } = props;

  const onAppSelect = (e) => {
    let clonedData = _.cloneDeep(data);
    const appId = `${e.target.value}`;
    if (e.target.checked) {
      clonedData[appId] = {};
    } else {
      delete clonedData[appId];
    }
    setData(clonedData);
  };

  const onLocationSelect = (e) => {
    let clonedData = _.cloneDeep(data);
    const appId = e.target.getAttribute("name");
    const locationId = `${e.target.value}`;
    if (e.target.checked) {
      clonedData[appId][locationId] = 0;
    } else {
      delete clonedData[appId][locationId];
    }
    setData(clonedData);
  };

  const onQuantityChange = (e) => {
    let clonedData = _.cloneDeep(data);
    const fieldName = e.target.getAttribute("name");
    const objectPaths = fieldName.split(".");
    const appId = objectPaths[0];
    const locationId = objectPaths[1];
    clonedData[appId][locationId] = +e.target.value;
    setData(clonedData);
  };

  return (
    <>
      <div>
        <input
          className="mr-1"
          type="radio"
          valuetype="number"
          name="quantityMechanicsType"
          value={QuantityMechanicsType.LocationApplicationSpecificQuantity}
          onChange={(e) => {
            setCampaign((instance) => {
              return {
                ...instance,
                quantityMechanicsType: +e.target.value,
                campaignQuantityAvailability: [],
              };
            });
          }}
          checked={
            QuantityMechanicsType.LocationApplicationSpecificQuantity ===
            campaignExtensionProperties.quantityMechanicsType
          }
          disabled={disabled}
        />
        <label className="form-check-label">
          Location & Application Specific Quantity
        </label>
      </div>
      {campaignExtension.quantityMechanicsType ===
        QuantityMechanicsType.LocationApplicationSpecificQuantity && (
        <>
          <div className="row mt-3">
            <div className="col-md-6">
              <QuantityLimitTypeCard disabled={disabled} />
            </div>
          </div>
          <div className="row">
            {ApplicationID.All.filter(
              (x) => BundleSalesChannels.indexOf(x.value) >= 0
            ).map((app, appIndex) => {
              const appIdKey = `${app.value}`;
              return (
                <div className="col-md-12" key={appIndex}>
                  <div className="form-group row">
                    <div className="col">
                      <input
                        className="mr-1"
                        type="checkbox"
                        name={app.value}
                        value={app.value}
                        onChange={onAppSelect}
                        checked={appIdKey in data}
                        disabled={
                          disabled || !campaignSalesChannels.includes(app.value)
                        }
                      />
                      <label className="form-check-label">{app.name}</label>
                    </div>
                  </div>
                  {appIdKey in data && (
                    <div className="row pl-4 pr-4">
                      {locations.map((location, locationIndex) => {
                        const locationId = `${location.id}`;
                        return (
                          <div className="col-md-6 mb-2" key={locationIndex}>
                            <div className="row">
                              <div className="col-md-8">
                                <input
                                  className="mr-1"
                                  type="checkbox"
                                  name={appIdKey}
                                  value={location.id}
                                  checked={locationId in data[appIdKey]}
                                  onChange={onLocationSelect}
                                  disabled={
                                    disabled ||
                                    !campaignRedemptionLocations.includes(
                                      location.id
                                    )
                                  }
                                />
                                <label className="form-check-label">
                                  {location.name}
                                </label>
                              </div>
                              <div className="col-md-4">
                                <NumberInput
                                  name={`${appIdKey}.${locationId}`}
                                  value={data[appIdKey][locationId] ?? 0}
                                  placeholder="Value"
                                  onChange={onQuantityChange}
                                  disabled={
                                    disabled || !(locationId in data[appIdKey])
                                  }
                                  min={0}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
