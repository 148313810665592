import React from "react";
import { PromotionCategory, StorageModule } from "../../../../common/Constants";
import {
  DateHelper,
  generateFileName,
  ImageHelper,
  StringHelper,
} from "../../../../common/Helpers";
import _ from "lodash";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { useUpload } from "../../../../hooks/useUpload";
import { CheckboxInput } from "../../../campaigns/CheckboxInput";
import { RowFormField } from "../../../layout/FormLayout";

export class PromotionDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      categoryOptions: this.createCategoryOptions(),
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      category: value.category || "",
      categoryName: this.getCategoryName(value.category),
      code: value.code || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      name: value.name || "",
      planToPublishStr: value.planToPublish || "",
      poster: value.poster || "",
      weightDisplay: value.weightDisplay || 0,
      homePageDisplayWeight: value.homePageDisplayWeight || 0,
      isVisibleToHomePage: value.isVisibleToHomePage || false,
      isValidForSpecificMovie: value.isValidForSpecificMovie || false,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onTextBoxChange = (e) => {
    const valueType = e.target.getAttribute("valuetype");
    const fieldName =
      e.target.getAttribute("fieldname") ?? e.target.getAttribute("name");
    let value = e.target.value;
    if (_.isEqual("boolean", valueType)) {
      value = e.target.checked;
    }
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  onKeyPressDisplayWeight(event) {
    if (event.key === "." || event.key === "e") {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  openFile = (e) => {
    const { model } = this.state;
    const { onModelChange, defaultValue } = this.props;
    const sourceFile = e.target.files[0];
    const fileName = generateFileName(
      defaultValue?.id,
      StorageModule.Promotion
    );
    const { uploadFile } = useUpload();
    uploadFile(sourceFile, fileName, StorageModule.Promotion).then(
      (response) => {
        model.poster = `${response.data.blobStoragePath}${response.data.fileName}`;
        onModelChange(model);
      }
    );
  };

  render() {
    return (
      <Card title="Promotion Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const { cardMode, model } = this.state;
    const {
      categoryName,
      code,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      weightDisplay,
      isVisibleToHomePage,
      homePageDisplayWeight,
      isValidForSpecificMovie,
    } = model;
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-md-6" style={{ paddingLeft: "30px" }}>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="PromoCode">
                  Promotion Code:
                </label>
                <input
                  className="form-control display-input"
                  id="PromoCode"
                  placeholder="Enter Promotion Code"
                  readOnly={true}
                  value={code}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="Name">
                  Promotion Name:
                </label>
                <input
                  className="form-control display-input"
                  id="Name"
                  placeholder="Enter Promotion Name"
                  readOnly={true}
                  value={name}
                />
              </div>
            </div>
            <div className="row form-group">
              <label
                className="col form-control-label text-nowrap"
                htmlFor="Category"
              >
                Promotion Category:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Category"
                  placeholder="Enter Promotion Image Link"
                  readOnly={true}
                  value={categoryName}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="StartDate">
                Display Start DateTime:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDate"
                  readOnly={true}
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayStartTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="EndDate">
                Display End DateTime:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="EndDate"
                  readOnly={true}
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayEndTimeStr
                  )}
                />
              </div>
            </div>
            <RowFormField label="Display Weight:" htmlFor="Weight">
              <input
                className="form-control display-input"
                id="Weight"
                type="number"
                readOnly={true}
                placeholder="Enter Display Weight"
                min="1"
                max="100"
                value={weightDisplay}
              />
            </RowFormField>
            <RowFormField
              label="Home Page Display Weight:"
              htmlFor="HomePageDisplayWeight"
            >
              <input
                className="form-control display-input"
                id="HomePageDisplayWeight"
                type="number"
                readOnly={true}
                placeholder="Enter Home Page Display Weight"
                min="1"
                max="100"
                value={homePageDisplayWeight}
              />
            </RowFormField>
            <RowFormField
              label="Is Visible To Home Page:"
              htmlFor="isVisibleToHomePage"
            >
              {isVisibleToHomePage ? "Yes" : "No"}
            </RowFormField>
            <RowFormField
              label="Is Valid For Specific Movie:"
              htmlFor="isValidForSpecificMovie"
            >
              {isValidForSpecificMovie ? "Yes" : "No"}
            </RowFormField>
          </div>

          <div
            className="col-md-6 mt-3 mt-md-0"
            style={{ paddingLeft: "30px" }}
          >
            {CardMode.isScheduleMode(cardMode) && (
              <RowFormField label="Plan to Publish On:" htmlFor="PlanToPublish">
                <input
                  className="form-control display-input"
                  id="PlanToPublish"
                  readOnly={true}
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    planToPublishStr
                  )}
                />
              </RowFormField>
            )}
            <div className="row form-group">
              <div className="col-12">
                <img
                  src={ImageHelper.getPosterUrl(poster)}
                  style={{ height: "320px" }}
                  alt="poster"
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-12">
                <label className="form-control-label" htmlFor="ImgUrl">
                  Promotion Image:
                </label>
                <textarea
                  className="form-control display-input resize-none"
                  rows="3"
                  readOnly={true}
                  placeholder="No image set"
                  value={poster}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12" style={{ paddingLeft: "30px" }}>
            <label className="form-control-label" htmlFor="Desc">
              Promotion Description:
            </label>
            <textarea
              className="form-control m-r-15 resize-none display-input"
              maxLength="50000"
              rows="30"
              id="Desc"
              placeholder="No description"
              readOnly={true}
              value={description}
            ></textarea>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { cardMode, categoryOptions, model } = this.state;
    const {
      category,
      code,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      weightDisplay,
      isVisibleToHomePage,
      homePageDisplayWeight,
      isValidForSpecificMovie,
    } = model;
    return (
      <div className="details row">
        <div className="col-md-6">
          <RowFormField
            label={
              <>
                Promotion Code:<span className="color-red">*</span>
              </>
            }
            htmlFor="PromoCode"
          >
            {cardMode === CardMode.Create ? (
              <React.Fragment>
                <input
                  type="text"
                  id="PromoCode"
                  placeholder="Enter Promotion Code"
                  className={this.getControlClassName("code")}
                  fieldname="code"
                  onChange={this.onTextBoxChange}
                  value={code}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="code"
                  isEnabled={isSubmit}
                  property="Promotion Code"
                  value={code}
                />
                <Validators.MaxLengthValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  maxLength="12"
                  fieldName="code"
                  isEnabled={isSubmit}
                  property="Voucher Code"
                  value={code}
                ></Validators.MaxLengthValidator>
              </React.Fragment>
            ) : (
              <input
                type="text"
                id="PromoCode"
                className="form-control display-input"
                placeholder="Enter Promotion Code"
                readOnly={true}
                value={code}
              />
            )}
          </RowFormField>
          <RowFormField
            label={
              <>
                Promotion Name:<span className="color-red">*</span>
              </>
            }
            htmlFor="Name"
          >
            <input
              type="text"
              id="Name"
              placeholder="Enter Promotion Name"
              className={this.getControlClassName("name")}
              fieldname="name"
              onChange={this.onTextBoxChange}
              value={name}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="name"
              isEnabled={isSubmit}
              property="Promotion Name"
              value={name}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Promotion Category:<span className="color-red">*</span>
              </>
            }
            htmlFor="Category"
          >
            <select
              id="Category"
              className={this.getControlClassName("category")}
              fieldname="category"
              onChange={this.onTextBoxChange}
              value={category}
            >
              {categoryOptions.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="category"
              isEnabled={isSubmit}
              property="Category"
              value={category}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Start DateTime:<span className="color-red">*</span>
              </>
            }
            htmlFor="StartDate"
          >
            <input
              id="StartDate"
              type="datetime-local"
              className={this.getControlClassName("displayStartTimeStr")}
              fieldname="displayStartTimeStr"
              onChange={this.onTextBoxChange}
              value={displayStartTimeStr}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayStartTimeStr"
              isEnabled={isSubmit}
              property="Display Start DateTime"
              value={displayStartTimeStr}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display End DateTime:<span className="color-red">*</span>
              </>
            }
            htmlFor="EndDate"
          >
            <input
              id="EndDate"
              type="datetime-local"
              className={this.getControlClassName("displayEndTimeStr")}
              fieldname="displayEndTimeStr"
              onChange={this.onTextBoxChange}
              value={displayEndTimeStr}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayEndTimeStr"
              isEnabled={isSubmit}
              fromProperty="Display Start"
              fromValue={displayStartTimeStr}
              toProperty="Display End"
              toValue={displayEndTimeStr}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Weight:<span className="color-red">*</span>
              </>
            }
            htmlFor="Weight"
          >
            <input
              id="Weight"
              type="number"
              placeholder="Enter Display Weight"
              min="1"
              step="1"
              max="100"
              className={this.getControlClassName("weightDisplay")}
              fieldname="weightDisplay"
              onChange={this.onTextBoxChange}
              onKeyDown={this.onKeyPressDisplayWeight}
              value={weightDisplay}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="weightDisplay"
              isEnabled={isSubmit}
              min="1"
              max="100"
              property="Display Weight"
              value={weightDisplay}
              allowDecimal={true}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Home Page Display Weight:<span className="color-red">*</span>
              </>
            }
            htmlFor="HomePageDisplayWeight"
          >
            <input
              id="HomePageDisplayWeight"
              type="number"
              placeholder="Enter Display Weight"
              min="1"
              step="1"
              max="100"
              className={this.getControlClassName("homePageDisplayWeight")}
              fieldname="homePageDisplayWeight"
              onChange={this.onTextBoxChange}
              onKeyDown={this.onKeyPressDisplayWeight}
              value={homePageDisplayWeight}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Is Visible To Home Page:<span className="color-red">*</span>
              </>
            }
            htmlFor="Weight"
          >
            <CheckboxInput
              name="isVisibleToHomePage"
              wrapperClass="pl-0"
              onChange={this.onTextBoxChange}
              value={isVisibleToHomePage}
              checked={isVisibleToHomePage}
            />
          </RowFormField>
          <RowFormField
            label={<>Is Valid For Specific Movie:</>}
          >
            <CheckboxInput
              name="isValidForSpecificMovie"
              wrapperClass="pl-0"
              onChange={this.onTextBoxChange}
              value={isValidForSpecificMovie}
              checked={isValidForSpecificMovie}
            />
          </RowFormField>
          <RowFormField
            label={
              <span>
                Promotion Image:<span className="color-red">*</span>
              </span>
            }
            htmlFor="ImgUrl"
          >
            <input type="file" name="file" onChange={this.openFile} />
          </RowFormField>
          <div className="row form-group">
            <div className="col-11">
              <textarea
                id="ImgUrl"
                placeholder="Enter Promotion Image Link"
                className={this.getControlClassName("poster", "resize-none")}
                fieldname="poster"
                onChange={this.onTextBoxChange}
                value={poster}
                disabled={true}
              ></textarea>
              <Validators.ValidUrlValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="poster"
                property="Promotion Image"
                isEnabled={isSubmit}
                value={poster}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {CardMode.isScheduleMode(cardMode) && (
            <RowFormField label="Plan to Publish On:" htmlFor="PlanToPublish">
              <input
                id="PlanToPublish"
                type="datetime-local"
                className={this.getControlClassName("planToPublishStr")}
                fieldname="planToPublishStr"
                onChange={this.onTextBoxChange}
                value={planToPublishStr}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="planToPublishStr"
                isEnabled={isSubmit}
                property="Plan to Publish On"
                value={planToPublishStr}
              />
            </RowFormField>
          )}
          <div className="d-flex flex-wrap">
            <label className="form-control-label" htmlFor="Desc">
              Promotion Description:
            </label>
            <textarea
              rows="16"
              id="Desc"
              placeholder="Description"
              className={this.getControlClassName(
                "description",
                "m-r-15 resize-none"
              )}
              fieldname="description"
              onChange={this.onTextBoxChange}
              value={description}
            ></textarea>
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="description"
              isEnabled={isSubmit}
              maxLength="50000"
              property="Description"
              value={description}
            />
            <label className="form-control-label ml-auto m-r-15" htmlFor="Desc">
              <small>
                <span id="DescCount">{description.length}</span>/50000
              </small>
            </label>
          </div>
        </div>
      </div>
    );
  }

  createCategoryOptions() {
    return [
      { code: "", name: "Please Select" },
      { code: PromotionCategory.Discount, name: "Discounts" },
      { code: PromotionCategory.Event, name: "Events" },
      { code: PromotionCategory.FoodAndBeverage, name: "F&B" },
      { code: PromotionCategory.InTheatre, name: "In Theatre" },
    ];
  }

  getCategoryName(category) {
    const mapping = {
      [PromotionCategory.NotSpecified]: "None",
      [PromotionCategory.Discount]: "Discounts",
      [PromotionCategory.Event]: "Events",
      [PromotionCategory.FoodAndBeverage]: "F&B",
      [PromotionCategory.InTheatre]: "InTheatre",
    };
    return mapping[category || 0];
  }
}
