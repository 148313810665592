export class CardMode {
    static View = 0;
    static Create = 1;
    static Update = 2;
    static ProductMenuView = 3;

    static isEditMode(mode) {
        return mode === CardMode.Create || mode === CardMode.Update || mode === CardMode.ScheduleUpdate;
    }

    static isScheduleMode(mode) {
        return mode === CardMode.ScheduleView || mode === CardMode.ScheduleUpdate;
    }

    static isViewMode(mode) {
        return mode === CardMode.View || mode === CardMode.ScheduleView || mode === CardMode.ProductMenuView;
    }
}