import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BreadCrumb } from "../../common/BreadCrumb";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { Movie } from "../../../models/Movie";
import { MovieRelease } from "../../../models/MovieRelease";
import {
  Classify,
  Country,
  CrewRole,
  Languages,
  MovieFormat,
  MovieGenre,
  MovieSubtitle,
  Restriction,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { MovieService } from "../../../services/MovieService";
import { DateHelper, StringHelper } from "../../../common/Helpers";
import _, { isEmpty } from "lodash";
import { AddConsumerAdviceModal } from "../modals/AddConsumerAdviceModal";
import { AddLanguageModal } from "../modals/AddLanguageModal";
import { AddGenreModal } from "../modals/AddGenreModal";
import { AddRestrictionModal } from "../modals/AddRestrictionModal";
import { MovieRestrictionService } from "../../../services/MovieRestrictionService";
import "../../css/components/accordion.css";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ColumnFormField, RowFormField } from "../../layout/FormLayout";
import MovieReleaseLayoutAttribute from "./MovieReleaseLayoutAttribute";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import MovieDetailCard from "./MovieDetailCard";
import { Card } from "../../common/Card";
import { showLoading } from "../../../../src/common/Common";

export class MovieReleaseDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      movie: new Movie(),
      instance: new MovieRelease(),
      showAddAdviceModal: false,
      showAddLanguage: false,
      showAddSubtitle: false,
      showAddGenre: false,
      showAddRestriction: false,
      showHistoryDialog: false,
      showSaveChangesModal: false,
      showCancelModel: false,
      mode: Mode.Readonly,
    };
    this.movieReleaseService = new MovieReleaseService();
    this.movieService = new MovieService();
    this.movieRestrictionService = new MovieRestrictionService();
  }

  getMovieRelease() {
    const id = this.props.match.params.id;
    this.movieReleaseService
      .get(id, { includes: "MovieReleaseRestrictions" })
      .then((response) => {
        const movieReleaseResult = response.data;
        this.movieService.get(
          movieReleaseResult.movieId,
          { includes: "Company" },
          (movieResult) => {
            this.setState({
              instance: new MovieRelease(movieReleaseResult),
              movie: new Movie(movieResult),
            });
          },
          (movieError) => {
            toast.error(movieError.message);
          }
        );
      });
  }

  componentDidMount() {
    this.getMovieRelease();
  }

  onSaveChanges = () => {
    showLoading(true);
    const { instance } = this.state;
    const errorMsg = instance.validate();
    if (errorMsg) {
      toast.error(errorMsg);
    } else {
      this.movieReleaseService
        .put(instance.id, instance)
        .then((response) => {
          if (response) {
            this.setState({
              instance: new MovieRelease(response.data),
              mode: Mode.Readonly,
            });
            this.getMovieRelease();
            toast.success("The movie release has been updated successful.");
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
    this.setState({ showSaveChangesModal: false });
  };

  onCancelChanges = () => {
    this.setState({ mode: Mode.Readonly, showCancelModel: false });
  };

  onAddAdvice = (advice) => {
    const { instance } = this.state;
    instance.adviceName = advice.adviceName;
    instance.adviceOnTicket = advice.adviceOnTicket;
    this.setState({ instance });
  };

  onRemoveAdvice = () => {
    const { instance } = this.state;
    instance.adviceName = "";
    instance.adviceOnTicket = "";
    this.setState({ instance });
  };

  renderConsumerAdvice = () => {
    const { instance, mode } = this.state;
    const hasAdvice =
      !_.isEmpty(instance.adviceName) || !_.isEmpty(instance.adviceOnTicket);
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Consumer Advice",
          onClick: () => this.setState({ showAddAdviceModal: true }),
        }}
        mode={mode}
      >
        {hasAdvice && (
          <MovieReleaseLayoutAttribute.Body
            mode={mode}
            onDelete={this.onRemoveAdvice}
          >
            <RowFormField className="mb-0 py-2" label="Consumer Advice:">
              <input
                className="form-control form-readonly"
                value={instance.adviceName}
                readOnly
              />
            </RowFormField>
            <RowFormField className="mb-0 py-2" label="Advice On Ticket:">
              <input
                className="form-control form-readonly"
                value={instance.adviceOnTicket}
                readOnly
              />
            </RowFormField>
          </MovieReleaseLayoutAttribute.Body>
        )}
      </MovieReleaseLayoutAttribute>
    );
  };

  onAddRestriction = (restrictionData) => {
    const { instance } = this.state;
    const found = instance.movieReleaseRestrictions.find(
      (x) =>
        x.startOn === restrictionData.startOn &&
        x.endOn === restrictionData.endOn &&
        x.type === restrictionData.type
    );
    if (!_.isNil(found)) {
      return;
    }
    restrictionData.movieReleaseId = instance.id;
    this.movieRestrictionService.create(restrictionData, (_) => {
      instance.movieReleaseRestrictions.push(restrictionData);
      this.setState({ instance });
      toast.success("The restriction has been added successful.");
    });
  };

  onRemoveRestriction = (restrictionData) => {
    const { instance } = this.state;
    this.movieRestrictionService.delete(
      restrictionData,
      (_) => {
        instance.movieReleaseRestrictions =
          instance.movieReleaseRestrictions.filter(
            (x) =>
              x.startOn !== restrictionData.startOn &&
              x.endOn !== restrictionData.endOn &&
              x.type !== restrictionData.type
          );
        this.setState({ instance });
        toast.success("The restriction has been deleted successful.");
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  renderMovieRestrictions = () => {
    const { instance, mode } = this.state;
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Restriction",
          onClick: () => this.setState({ showAddRestriction: true }),
        }}
        mode={mode}
      >
        {instance.movieReleaseRestrictions.map((x, index) => {
          return (
            <MovieReleaseLayoutAttribute.Body
              key={index}
              mode={mode}
              onDelete={() => this.onRemoveRestriction(x)}
            >
              <RowFormField className="mb-0 py-2" label="Restriction:">
                <input
                  className="form-control form-readonly"
                  value={Restriction.getName(x.type)}
                  readOnly
                />
              </RowFormField>
              <RowFormField className="mb-0 py-2" label="Start On:">
                <input
                  className="form-control form-readonly"
                  value={DateHelper.toDisplayDateFormat(x.startOn)}
                  readOnly
                />
              </RowFormField>
              <RowFormField className="mb-0 py-2" label="End On:">
                <input
                  className="form-control form-readonly"
                  value={DateHelper.toDisplayDateFormat(x.endOn)}
                  readOnly
                />
              </RowFormField>
            </MovieReleaseLayoutAttribute.Body>
          );
        })}
      </MovieReleaseLayoutAttribute>
    );
  };

  onAddLanguage = (language) => {
    const { instance } = this.state;
    const languageArray = (instance.language ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x);
    const found = languageArray.find((x) => x === language);
    if (!_.isNil(found)) {
      return;
    }
    languageArray.push(language);
    instance.language = languageArray.join(",");
    this.setState({ instance });
  };

  onRemoveLanguage = (language) => {
    const { instance } = this.state;
    instance.language = instance.language
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x && x !== language)
      .join(",");
    this.setState({ instance });
  };

  renderMovieLanguage = () => {
    const { instance, mode } = this.state;
    const languages = (instance.language ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Language",
          onClick: () => this.setState({ showAddLanguage: true }),
        }}
        mode={mode}
      >
        {languages.map((x, index) => {
          return (
            <MovieReleaseLayoutAttribute.Body
              key={index}
              mode={mode}
              onDelete={() => this.onRemoveLanguage(x.trim())}
            >
              <RowFormField className="mb-0 py-2" label="Language:">
                <input
                  className="form-control form-readonly"
                  value={Languages.getName(+x)}
                  readOnly
                />
              </RowFormField>
            </MovieReleaseLayoutAttribute.Body>
          );
        })}
      </MovieReleaseLayoutAttribute>
    );
  };

  onAddGenre = (genre) => {
    const { instance } = this.state;
    const genreArray = (instance.genre ?? "").split(",").map((x) => x.trim());
    const found = genreArray.find((x) => x === genre);
    if (!_.isNil(found)) {
      return;
    }
    genreArray.push(genre);
    instance.genre = genreArray.filter((x) => !isEmpty(x)).join(", ");
    this.setState({ instance });
  };

  onRemoveGenre = (genre) => {
    const { instance } = this.state;
    instance.genre = instance.genre
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x !== genre)
      .join(", ");
    this.setState({ instance });
  };

  renderMovieGenre = () => {
    const { instance, mode } = this.state;
    const genres = (instance.genre ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <MovieReleaseLayoutAttribute
        header={{
          title: "Movie Genre",
          onClick: () => this.setState({ showAddGenre: true }),
        }}
        mode={mode}
      >
        {genres.map((x, index) => {
          return (
            <MovieReleaseLayoutAttribute.Body
              key={index}
              mode={mode}
              onDelete={() => this.onRemoveGenre(x.trim())}
            >
              <RowFormField className="mb-0 py-2" label="Genre:">
                <input
                  className="form-control form-readonly"
                  value={MovieGenre.getName(+x)}
                  readOnly
                />
              </RowFormField>
            </MovieReleaseLayoutAttribute.Body>
          );
        })}
      </MovieReleaseLayoutAttribute>
    );
  };

  renderMovieSection = () => {
    const { movie } = this.state;
    return (
      <div className="row">
        <div className="col">
          <MovieDetailCard movie={movie}>
            {this.renderMovieCrew()}
          </MovieDetailCard>
        </div>
      </div>
    );
  };

  renderMovieCrew = () => {
    const { movie } = this.state;
    return (
      <div className="card mb-3">
        <div className="card-body Movie Crew-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Movie Crew</span>
          </div>
          {!_.isNil(movie.crews) &&
            movie.crews.map((x, index) => {
              return (
                <div key={index} className="card card-body bg-light mb-1 py-1">
                  <div className="row pb-0 mb-0">
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">{x.name}</label>
                    </div>
                  </div>
                  <div className="row pb-0 mb-0">
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew Role:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">
                        {CrewRole.getName(x.role)}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  renderMovieReleaseReadonlySection = () => {
    const { instance } = this.state;
    return (
      <div className="row">
        <div className="col">
          <Card title="Movie Release">
            <Card
              title={`${MovieFormat.getName(
                instance.format
              )} | ${Classify.getName(instance.classify)}`}
            >
              <div className="card-body bg-light film01">
                <div className="card card-body form-group">
                  <div className="row">
                    <ColumnFormField label="Id:">
                      <input
                        className="form-control form-readonly"
                        value={instance.id}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Movie Code:">
                      <input
                        className="form-control form-readonly"
                        value={instance.releaseExternalCode}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Film Code:">
                      <input
                        className="form-control form-readonly"
                        value={instance.eventReleaseCode}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Movie Format:">
                      <input
                        className="form-control form-readonly"
                        value={MovieFormat.getName(instance.format)}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Movie Subtitle:">
                      <input
                        className="form-control form-readonly"
                        value={MovieSubtitle.getName(instance.subtitle)}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Classification:">
                      <input
                        className="form-control form-readonly"
                        value={Classify.getName(instance.classify)}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Movie Release Country:">
                      <input
                        className="form-control form-readonly"
                        value={Country.getName(instance.movieReleaseCountry)}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Label Display:">
                      <input
                        className="form-control form-readonly"
                        value={instance.labelDisplay}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Display Weight:">
                      <input
                        className="form-control form-readonly"
                        value={instance.displayWeight}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="HomePage Display Weight:">
                      <input
                        className="form-control form-readonly"
                        value={instance.homePageDisplayWeight}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Label Print:">
                      <input
                        className="form-control form-readonly"
                        value={instance.labelPrint}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Release Date:">
                      <input
                        className="form-control form-readonly"
                        value={DateHelper.toDisplayDateFormat(
                          instance.releaseDate
                        )}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Display Start On:">
                      <input
                        className="form-control form-readonly"
                        value={StringHelper.asDateStringToDisplayDateTimeFormat(
                          instance.displayStartOn
                        )}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Display End On:">
                      <input
                        className="form-control form-readonly"
                        value={StringHelper.asDateStringToDisplayDateTimeFormat(
                          instance.displayEndOn
                        )}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Release date confirmed?">
                      <input
                        className="form-control form-readonly"
                        value={instance.isReleaseDateConfirmed ? "Yes" : "No"}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Is Marathon:">
                      <input
                        className="form-control form-readonly"
                        value={instance.isMarathon ? "Yes" : "No"}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Shaw Exclusive?">
                      <input
                        className="form-control form-readonly"
                        value={instance.isShawExclusive ? "Yes" : "No"}
                        readOnly
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Visible to public?">
                      <input
                        className="form-control form-readonly"
                        value={instance.isPublic ? "Yes" : "No"}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Is DTS-X?">
                      <input
                        className="form-control form-readonly"
                        value={instance.isDtsx ? "Yes" : "No"}
                        readOnly
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Rating Notice">
                      <textarea
                        className="form-control form-readonly"
                        value={instance.ratingNotice}
                        readOnly
                      />
                     </ColumnFormField>                    
                  </div>
                </div>
              </div>
              <div className="card-body bg-light">
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderConsumerAdvice()}
                    {this.renderMovieRestrictions()}
                  </div>
                  <div className="col-xl-6">
                    {this.renderMovieLanguage()}
                    {this.renderMovieGenre()}
                  </div>
                </div>
              </div>
            </Card>
          </Card>
        </div>
      </div>
    );
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = selectValue.toString() === "true";
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  renderMovieReleaseEditableSection = () => {
    const { instance } = this.state;
    return (
      <div className="row">
        <div className="col">
          <Card title="Movie Release">
            <Card
              title={`${MovieFormat.getName(
                instance.format
              )} | ${Classify.getName(instance.classify)}`}
            >
              <div className="card-body bg-light film01">
                <div className="card card-body">
                  <div className="row">
                    <ColumnFormField label="Id:">
                      <input
                        className="form-control display-input"
                        value={instance.id}
                        readOnly={true}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Movie Code:">
                      <input
                        className="form-control"
                        placeholder="Enter Movie Code"
                        name="releaseExternalCode"
                        onChange={this.onInputChange}
                        value={instance.releaseExternalCode}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Film Code:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <label className="form-control-label"></label>
                      <input
                        className="form-control"
                        placeholder="Enter Film Code"
                        name="eventReleaseCode"
                        onChange={this.onInputChange}
                        value={instance.eventReleaseCode}
                      />
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          Movie Format:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        defaultValue={instance.format}
                        valuetype="number"
                        name="format"
                        onChange={this.onInputChange}
                      >
                        {MovieFormat.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Movie Subtitle:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        defaultValue={instance.subtitle}
                        valuetype="number"
                        name="subtitle"
                        onChange={this.onInputChange}
                      >
                        {MovieSubtitle.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                    <ColumnFormField label="Classification:">
                      <select
                        className="form-control"
                        valuetype="number"
                        name="classify"
                        onChange={this.onInputChange}
                        defaultValue={instance.classify}
                      >
                        {Classify.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Movie Release Country:">
                      <select
                        className="form-control"
                        valuetype="number"
                        name="movieReleaseCountry"
                        onChange={this.onInputChange}
                      >
                        {Country.All.map((x) => (
                          <option
                            key={x.value}
                            value={x.value}
                            defaultValue={instance.movieReleaseCountry}
                          >
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                    <ColumnFormField label="Label Display:">
                      <input
                        className="form-control"
                        placeholder="Enter Label Display"
                        name="labelDisplay"
                        onChange={this.onInputChange}
                        value={instance.labelDisplay}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Display Weight:">
                      <input
                        className="form-control"
                        placeholder="Enter Display Weight"
                        name="displayWeight"
                        onChange={this.onInputChange}
                        value={instance.displayWeight}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="HomePage Display Weight:">
                      <input
                        className="form-control"
                        placeholder="Enter HomePage Display Weight"
                        name="homePageDisplayWeight"
                        onChange={this.onInputChange}
                        value={instance.homePageDisplayWeight}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Label Print:">
                      <input
                        className="form-control"
                        placeholder="Enter Label Print"
                        name="labelPrint"
                        onChange={this.onInputChange}
                        value={instance.labelPrint}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Release Date:">
                      <input
                        className="form-control"
                        type="date"
                        name="releaseDate"
                        onChange={this.onInputChange}
                        value={instance.releaseDate}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Display Start On:">
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="displayStartOn"
                        onChange={this.onInputChange}
                        value={instance.displayStartOn}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Display End On:">
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="displayEndOn"
                        onChange={this.onInputChange}
                        value={instance.displayEndOn}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Shaw Exclusive?">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isShawExclusive"
                          valuetype="boolean"
                          value={true}
                          checked={instance.isShawExclusive}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label mr-2">Yes</label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isShawExclusive"
                          valuetype="boolean"
                          value={false}
                          checked={!instance.isShawExclusive}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </ColumnFormField>
                    <ColumnFormField label="Visible to public?">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isPublic"
                          valuetype="boolean"
                          value={true}
                          checked={instance.isPublic}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label mr-2">Yes</label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isPublic"
                          valuetype="boolean"
                          value={false}
                          checked={!instance.isPublic}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Release date confirmed?">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isReleaseDateConfirmed"
                          valuetype="boolean"
                          value={true}
                          checked={instance.isReleaseDateConfirmed}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label mr-2">Yes</label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isReleaseDateConfirmed"
                          valuetype="boolean"
                          value={false}
                          checked={!instance.isReleaseDateConfirmed}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </ColumnFormField>
                    <ColumnFormField label="Is DTS-X?">
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isDtsx"
                          valuetype="boolean"
                          value={true}
                          checked={instance.isDtsx}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label mr-2">Yes</label>
                      </div>
                      <div className="form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="isDtsx"
                          valuetype="boolean"
                          value={false}
                          checked={!instance.isDtsx}
                          onChange={this.onInputChange}
                        />
                        <label className="form-check-label">No</label>
                      </div>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Rating Notice:">
                      <textarea
                        className="form-control"
                        placeholder="Enter Rating Notice"
                        name="ratingNotice"
                        onChange={this.onInputChange}
                        value={instance.ratingNotice}
                      />    
                    </ColumnFormField>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light">
                <div className="row">
                  <div className="col-xl-6">
                    {this.renderConsumerAdvice()}
                    {this.renderMovieRestrictions()}
                  </div>
                  <div className="col-xl-6">
                    {this.renderMovieLanguage()}
                    {this.renderMovieGenre()}
                  </div>
                </div>
              </div>
            </Card>
          </Card>
        </div>
      </div>
    );
  };

  renderTopButton = () => {
    const { mode } = this.state;
    if (mode === Mode.Editable) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-primary"
          onClick={() => {
            const id = this.props.match.params.id;
            this.props.history.push(
              `/movies/movie_releases/${id}/update_restrictions`
            );
          }}
        >
          Update Movie Restriction{" "}
        </ButtonActionDetailGroup.Item>
        {AuthenticationManager.isAuthorized(
          ResourceID.MovieReleaseEditButtonPage
        ) && (
          <ButtonActionDetailGroup.Item
            className="btn btn-success"
            onClick={() => this.setState({ mode: Mode.Editable })}
          >
            <i className="fas fa-edit" aria-hidden="true" /> Edit Movie Release
          </ButtonActionDetailGroup.Item>
        )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          data-target="#HistoryPopup"
          data-toggle="modal"
          onClick={() => this.setState({ showHistoryDialog: true })}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  renderBottomButtons = () => {
    const { mode } = this.state;
    if (mode === Mode.Readonly) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          onClick={() => this.setState({ showSaveChangesModal: true })}
          data-target="#confirmModal"
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          onClick={() => this.setState({ showCancelModel: true })}
          data-target="#cancelModal"
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  getNavigationSettings = () => {
    const { mode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-release-creation",
        name:
          mode === Mode.Readonly ? "View Movie Release" : "Edit Movie Release",
      }),
    });
  };

  render() {
    const {
      mode,
      showAddAdviceModal,
      showAddLanguage,
      showAddGenre,
      showAddRestriction,
      showHistoryDialog,
      instance,
      showSaveChangesModal,
      showCancelModel,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.renderTopButton()}
              {this.renderMovieSection()}
              {mode === Mode.Readonly
                ? this.renderMovieReleaseReadonlySection()
                : this.renderMovieReleaseEditableSection()}
              {this.renderBottomButtons()}
            </div>
          </div>
        </div>
        <AddConsumerAdviceModal
          visible={showAddAdviceModal}
          onCancel={() => this.setState({ showAddAdviceModal: false })}
          onProceed={(advice) => this.onAddAdvice(advice)}
        />

        <AddLanguageModal
          visible={showAddLanguage}
          onCancel={() => this.setState({ showAddLanguage: false })}
          onProceed={(language) => this.onAddLanguage(language)}
        />
        <AddGenreModal
          visible={showAddGenre}
          onCancel={() => this.setState({ showAddGenre: false })}
          onProceed={(genre) => this.onAddGenre(genre)}
        />
        {AuthenticationManager.isAuthorized(
          ResourceID.MovieReleaseUpdateRestrictionButtonPage
        ) && (
          <AddRestrictionModal
            visible={showAddRestriction}
            onCancel={() => this.setState({ showAddRestriction: false })}
            onProceed={(restriction) => this.onAddRestriction(restriction)}
          />
        )}
        {showHistoryDialog && (
          <HistoryDialog
            objectId={instance.id}
            onClose={() => this.setState({ showHistoryDialog: false })}
            url="/api/v1/history_logs"
            objectType={ObjectType.MovieRelease}
          />
        )}
        <ConfirmDialog
          visible={showSaveChangesModal}
          title="Save Changes"
          onProceed={this.onSaveChanges}
          onCancel={() => this.setState({ showSaveChangesModal: false })}
          message="Are you sure you want to save the changes?"
        />
        <ConfirmDialog
          visible={showCancelModel}
          title="Cancel confirmation"
          onProceed={this.onCancelChanges}
          onCancel={() => this.setState({ showCancelModel: false })}
          message="Are you sure you want to cancel?"
        />
      </>
    );
  }
}
