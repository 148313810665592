import React from "react";

export const IntegerInput = (props) => {
  const onKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <input
      {...props}
      className="form-control display-input"
      type="number"
      onKeyPress={onKeyPress}
    />
  );
};
