import { useEffect, useState } from "react";
import RestClient from "../../common/RestClient";
import { toast } from "react-toastify";

export function useLocations() {
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/locations`,
      { includes: "LocationVenues" },
      (response) => {
        setLocations(response.data);
      },
      (error) => toast.error(error.message)
    );
  }, []);
  return locations;
}
