import React, { useEffect, useState } from "react";
import RestClient from "../../../common/RestClient";
import { MovieFormat, MovieBrand } from "../../../constants/MovieConstants";
import { toast } from "react-toastify";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignGlobalRefreshAtom,
  validForSpecificMovieSelector,
  isSelectedPerformanceConditionsAtom,
  moviesAtom,
} from "../CampaignState";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { MovieConditionDialog } from "../dialogs/MovieConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const MovieConditionCard = (props) => {
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const isSelectedPerformance = useRecoilValue(
    isSelectedPerformanceConditionsAtom
  );
  const movies = useRecoilValue(moviesAtom);
  const globalRefresh = useRecoilValue(campaignGlobalRefreshAtom);
  const validForSpecificMovie = useSetRecoilState(
    validForSpecificMovieSelector
  );
  const { isEdit } = props;

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificMovie);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/movies",
      { campaignId: campaign.id },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh, globalRefresh]);

  const onDeleteElement = () => {
    RestClient.sendDeleteRequest(
      `/api/v1/campaign_validity_conditions/movies/${selectedId}`,
      () => {
        toast.success("The movie condition has been deleted successful.");
        setRefresh((r) => r + 1);
        validForSpecificMovie(data.length > 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onCreate = (movieReleaseIds) => {
    const params = {
      movieReleaseIds,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/movies",
      params,
      (_) => {
        setRefresh((r) => r + 1);
        validForSpecificMovie(true);
        toast.success(
          "The campaign movie condition has been created successful."
        );
      },
      handleError
    );
    setShowCreateDialog(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_movie_conditions`,
      () => {
        toast.success("All movie conditions have been deleted successful.");
        setRefresh((ref) => ref + 1);
        validForSpecificMovie(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label htmlFor="input1" className="col-sm-2 col-form-label">
          Movie Condition
        </label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="EventCondition"
              id="EventConditionRbNo"
              value="No"
              checked={!showTable}
              onClick={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label className="form-check-label" htmlFor="EventConditionRbNo">
              No movie restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="EventCondition"
              id="EventConditionRbYes"
              value="Yes"
              checked={showTable}
              onClick={() => setShowTable(true)}
            />
            <label className="form-check-label" htmlFor="EventConditionRbYes">
              Valid for specific movies
            </label>
            <button
              type="button"
              id="EventConditionbtn1"
              name="EventCondition"
              data-toggle="modal"
              data-target="#EventConditionModal"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th>Movie Title</th>
              <th>Movie Releases</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                const movie = movies.find((x) => x.id === item.movieId);
                const movieRelease = (movie?.movieReleases ?? []).find(
                  (x) => x.id === item.movieReleaseId
                );
                return (
                  <tr key={index}>
                    <td>{movie?.primaryTitle}</td>
                    <td>
                      {movieRelease?.id}(
                      {MovieBrand.getName(movieRelease?.brand)} -{" "}
                      {MovieFormat.getName(movieRelease?.format)})
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedId(item.id);
                          setShowDeleteConfirmation(true);
                        }}
                        disabled={!isEdit}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onDeleteElement}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
      <MovieConditionDialog
        visible={showCreateDialog}
        onConfirm={onCreate}
        onCancel={() => setShowCreateDialog(false)}
      />
    </>
  );
};
