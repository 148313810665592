export class CorporateBookingRequest {
    constructor() {
        this.id = 0;
        this.eventDate = '';
        this.eventStartTime = 0;
        this.preferredTheatre = '';
        this.firstTheatre = '';
        this.secondTheatre = '';
        this.moviePrimaryTitle = '';
        this.numberOfGuests = 0;
        this.comment = '';
        this.name = '';
        this.emailAddress = '';
        this.contactNumber = '';
        this.companyName = '';
        this.companyAddress = '';
        this.postalCode = '';
        this.reasonForRejection = '';
        this.personInCharge = '';
        this.salutation = '';
        this.status = 0;
        this.actions = [];
    }
    
    handleResponse(data) {
        this.id = data.id;
        this.preferredTheatre = data.preferredTheatre;
        this.eventDate = data.eventDate;
        this.eventStartTime = data.eventStartTime;
        this.firstTheatre = data.firstTheatre;
        this.secondTheatre = data.secondTheatre ?? '';
        this.moviePrimaryTitle = data.moviePrimaryTitle ?? '';
        this.numberOfGuests = data.numberOfGuests;
        this.comment = data.comment;
        this.name = data.name;
        this.emailAddress = data.emailAddress;
        this.contactNumber = data.contactNumber;
        this.companyName = data.companyName;
        this.companyAddress = data.companyAddress;
        this.postalCode = data.postalCode ?? '';
        this.reasonForRejection = data.reasonForRejection ?? '';
        this.personInCharge = data.personInCharge ?? '';
        this.status = data.status;
        this.actions = data.actions;
        this.salutation = data.salutation;
    }
}