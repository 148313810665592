import React, { useEffect, useRef, useState } from "react";
import { Card } from "../../../common/Card";
import { useRecoilValue } from "recoil";
import { corporateBookingDetailSelector } from "../../CorporateBookingState";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { WarningDialog } from "../../../common/WarningDialog";

const COUNT_DOWN = 60 * 10;
const SHOW_WARNING = 60 * 3;
const SHOW_DANGER = 60;
const EXTEND_TIME = 60 * 3;

const getTimeString = (second) => {
  const minutes = Math.floor(second / 60);
  const seconds = second % 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds} ${
    minutes > 0 ? "minutes" : "seconds"
  } left until reset`;
};

export const SeatPlanCountDown = (props) => {
  const corporateBookingDetail = useRecoilValue(corporateBookingDetailSelector);
  const [countDown, setCountDown] = useState(COUNT_DOWN);
  const [showWarning, setShowWarning] = useState(false);
  const [showDanger, setShowDanger] = useState(false);
  const firstChangedRef = useRef(false);
  const isExtendedRef = useRef(false);
  const intervalRef = useRef(null);
  const selectedSeats = corporateBookingDetail.seatCodes;

  const { readOnly } = props;

  const setCountDownInterval = () =>
    setInterval(() => {
      setCountDown((prev) => {
        if (prev === 0) {
          clearInterval(intervalRef.current);
          window.location.reload();
          return 0;
        }

        const next = prev - 1;
        if (next === SHOW_WARNING && !isExtendedRef.current) {
          setShowWarning(true);
          isExtendedRef.current = true;
        } else if (next === SHOW_DANGER) {
          setShowWarning(false);
          setShowDanger(true);
        }

        return next;
      });
    }, 1000);

  const resetCountDown = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    isExtendedRef.current = false;
    setCountDown(COUNT_DOWN);
    setShowWarning(false);
    setShowDanger(false);
  };

  useEffect(() => {
    if (readOnly) {
      resetCountDown();
      return;
    }
    if (!firstChangedRef.current) {
      firstChangedRef.current = true;
      return;
    }
    if (!selectedSeats.length) {
      resetCountDown();
    }

    if (
      firstChangedRef.current &&
      selectedSeats.length &&
      !intervalRef.current
    ) {
      intervalRef.current = setCountDownInterval();
    }
  }, [selectedSeats, readOnly]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const title = `Seat Selection - ${getTimeString(countDown)}`;

  const extendSession = () => {
    setCountDown((prev) => prev + EXTEND_TIME);
    setShowWarning(false);
  };

  return (
    <Card title={title}>
      <WarningDialog
        visible={showDanger}
        message="On hold seat will timeout soon"
        onCancel={() => setShowDanger(false)}
      />
      <ConfirmDialog
        visible={showWarning}
        title="On Hold Seat Expiring soon"
        message="Do you want to extend the session?"
        onProceed={extendSession}
        onCancel={() => setShowWarning(false)}
      />
      {props.children}
    </Card>
  );
};
