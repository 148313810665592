import {Days} from "../constants/PricingConstants";

export class SearchServiceCriteria {
    constructor() {
        this.ticketBrand = 0;
        this.ticketFormat = 0;
        this.performanceType = 0;
        this.daySelection = 0;
        this.ticketType = 0;
        this.startDay = Days.Sunday;
        this.endDay = Days.Saturday;
        this.startTime = "00:00";
        this.endTime = "00:00";
    }

    reset() {
        this.ticketBrand = 0;
        this.ticketFormat = 0;
        this.performanceType = 0;
        this.daySelection = 0;
        this.ticketType = 0;
    }
    
}