import { QuantityMechanicsType } from "../../../../constants/CampaignConstants";

export class CampaignExtensionProperties {
  constructor(data) {
    this.validForMidnightShows = data.validForMidnightShows ?? false;
    this.validForOpeningShows = data.validForOpeningShows ?? false;
    this.validOnPublicHoliday = data.validOnPublicHoliday ?? false;
    this.validOnPublicHolidayEve = data.validOnPublicHolidayEve ?? false;
    this.validOnBlackoutHoliday = data.validOnBlackoutHoliday ?? false;
    this.validOnSubstituteHoliday = data.validOnSubstituteHoliday ?? false;
    this.validForCrossSale = data.validForCrossSale ?? false;
    this.validForAdvanceSales = data.validForAdvanceSales ?? false;
    this.isUnlimited = data?.isUnlimited ?? false;
    this.cumulativeQuantityLimit = data.cumulativeQuantityLimit ?? 0;
    this.quantityMechanicsType =
      data.quantityMechanicsType ?? QuantityMechanicsType.NoSpecificMechanics;
    this.quantityLimitType = data.quantityLimitType ?? 0;
    this.resetDay = data.resetDay ?? null;
  }
}
