import React from "react";
import _ from "lodash";

export const FieldWrapper = (props) => {
  const { label, input, action, visible, require, additionalCss } = props;

  const renderLabel = () => {
    if (!label) {
      return null;
    }
    if (typeof label === "string") {
      return (
        <label className="form-control-label">
          {label}:
          {!_.isNil(require) && require && (
            <span className="color-red font-weight-bold">*</span>
          )}
        </label>
      );
    }
    return label;
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  if (!action) {
    return (
      <div className={`row ${additionalCss ?? ""}`}>
        <div className="col-md-4">{renderLabel()}</div>
        <div className="col-md-8">{input}</div>
      </div>
    );
  }
  return (
    <div className={`row ${additionalCss ?? ""}`}>
      <div className="col-md-4">{renderLabel()}</div>
      <div className="col-md-6">{input}</div>
      <div className="col-md-2">{action}</div>
    </div>
  );
};
