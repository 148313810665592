import React from 'react'
import { Link } from "react-router-dom";

const MenuItem = (props) => {
	const {path, label, isActive, children, isExpanded, icon, onMenuClick} = props;
	return (
		<li
			className={`${children ? "has-sub" : ""} ${isActive ? "active" : ""
			}`}
		>
			<Link
				className={`js-arrow ${children && isExpanded ? "open" : ""
				}`}
				to={path}
				onClick={(e) => onMenuClick(e, path, label)}
			>
				<i aria-hidden="true" className={icon}/>
				{label}
			</Link>
			{children && (
				<ul
					className={`list-unstyled navbar__sub-list js-sub-list ${children && isExpanded ? "open" : ""
					}`}
				>
					{children.map((sub) => <MenuItem key={sub.path + sub.label} {...sub} onMenuClick={onMenuClick} />)}
				</ul>
			)}
		</li>
	)
}

export default MenuItem