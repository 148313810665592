import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BreadCrumb } from "../common/BreadCrumb";
import { Campaign } from "../../models/Campaign";
import { Card } from "../common/Card";

import {
  CampaignCategory,
  CampaignRestriction,
  CampaignStatus,
  DayOfWeek,
  RedemptionQuantityType,
} from "../../constants/CampaignConstants";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import _ from "lodash";
import RestClient from "../../common/RestClient";
import { TextInput } from "./TextInput";
import { TextAreaInput } from "./TextAreaInput";
import { FieldWrapper } from "./FieldWrapper";
import { DateTimeInput } from "./DateTimeInput";
import { NumberInput } from "./NumberInput";
import { SingleSelector } from "./SingleSelector";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { PreviewImageDialog } from "../common/PreviewImageDialog";
import { CampaignBundleCard } from "./bundles/CampaignBundleCard";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CustomizeTicketCard } from "./customTicket/CustomizeTicketCard";
import { toast } from "react-toastify";
import { CampaignAvailabilityCard } from "./availability/CampaignAvailabilityCard";
import { CampaignValidityConditionsCard } from "./cards/CampaignValidityConditionsCard";
import {
  CampaignActivePanel,
  campaignActivePanelAtom,
  campaignAtom,
  campaignBundlesAtom,
  locationsAtom,
  moviesAtom,
  productsAtom,
  useResetStatesOnDestroy,
} from "./CampaignState";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { HistoryDialog } from "../common/HistoryDialog";
import { ObjectType } from "../../common/Constants";
import { CheckboxInput } from "./CheckboxInput";
import { Product } from "../../models/Product";
import { Movie } from "../../models/Movie";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";
import { useStackCampaigns } from "./useStackCampaigns";
import useVisibleModal from "../../hooks/useVisibleModal";

const modalAction = {
  save: "save",
  activate: "activate",
  delete: "delete",
  block: "block",
  unBlock: "unBlock",
  preview: "preview",
  historyDialog: "historyDialog",
};

export const CampaignDetails = () => {
  const [campaign, setCampaign] = useRecoilState(campaignAtom);
  const setMovies = useSetRecoilState(moviesAtom);
  const setLocations = useSetRecoilState(locationsAtom);
  const setCampaignBundles = useSetRecoilState(campaignBundlesAtom);
  const { visibleState, changeVisibleState } = useVisibleModal({
    action: modalAction,
  });
  const [activePanel, setActivePanel] = useRecoilState(campaignActivePanelAtom);
  const setProducts = useSetRecoilState(productsAtom);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const { loadStackCampaign } = useStackCampaigns();

  const { id } = useParams();
  const history = useHistory();

  useResetStatesOnDestroy();

  const handleError = useCallback(
    (error) => {
      if (error.status === 404) {
        toast.error(`The campaign with id ${id} cannot be found.`);
        history.push(`/campaigns`);
        return;
      }
      toast.error(error.message);
    },
    [history, id]
  );

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/movies",
      { includes: "MovieReleases", sortColumn: "PrimaryTitle" },
      (response) => {
        setMovies(response.data.map((x) => new Movie(x)));
      },
      (error) => toast.error(error.message)
    );
  }, [setMovies]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/products`,
      {},
      (response) => {
        setProducts(response.data.map((x) => new Product(x)));
      },
      handleError
    );
  }, [handleError, setProducts]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/locations`,
      { includes: "LocationVenues" },
      (response) => {
        setLocations(response.data);
      },
      handleError
    );
  }, [handleError, setLocations]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/campaigns/${id}`,
      {
        includes: [
          "CampaignSalesChannelsValidity",
          "CampaignRedemptionLocationValidity",
          "CampaignQuantityAvailability",
          "CampaignBundles",
          "CampaignBundles.CampaignBundleProducts",
          "CampaignBundles.CampaignBundleTickets",
          "CampaignBundles.CampaignBundleCoupons",
          "CampaignBundles.CampaignBundleSalesChannelsValidity",
          "CampaignBundles.CampaignBundleRedemptionLocationValidity",
        ].join(","),
      },
      (response) => {
        const instance = new Campaign(response);
        setCampaign(instance);
        setCampaignBundles(instance.campaignBundles);
      },
      handleError
    );
  }, [handleError, id, setCampaign, setCampaignBundles, campaign.status]);

  useEffect(() => {
    loadStackCampaign();
  }, []);

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setCampaign((instance) => {
      return { ...instance, [fieldName]: selectValue };
    });
  };

  const confirmCancel = () => {
    setActivePanel("");
    setShowCancelConfirmation(false);
  };

  const onSave = () => {
    const body = {
      ...campaign,
    };
    RestClient.sendPutRequest(
      `/api/v1/campaigns/${+id}`,
      body,
      (response) => {
        setCampaign(new Campaign(response));
        toast.success("The campaign has been updated successful.");
        setActivePanel("");
      },
      handleError
    );
    changeVisibleState(modalAction.save, false);
  };

  const changeStatus = (action, successMessage) => {
    const body = {};
    RestClient.sendPostRequest(
      `/api/v1/campaigns/${campaign.id}/${action}`,
      body,
      (response) => {
        setCampaign(new Campaign(response));
        toast.success(successMessage);
        setActivePanel("");
      },
      handleError
    );
  };

  const onDelete = () => {
    RestClient.sendDeleteRequest(
      `/api/v1/campaigns/${campaign.id}`,
      () => {
        history.push("/campaigns");
        toast.success("The campaign has been deleted successful.");
      },
      handleError
    );
    changeVisibleState(modalAction.delete, false);
  };

  const onActivate = () => {
    changeStatus("activate", "The campaign has been activated.");
    changeVisibleState(modalAction.activate, false);
  };

  const onBlock = () => {
    changeStatus("block", "The campaign has been blocked.");
    changeVisibleState(modalAction.block, false);
  };

  const onUnblock = () => {
    changeStatus("unblock", "The campaign has been unblocked.");
    changeVisibleState(modalAction.unBlock, false);
  };

  const onEPromotionCodeChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const selectValue = e.target.checked;
    const ePromotionCode = selectValue ? campaign.ePromotionCode : "";
    setCampaign((instance) => {
      return {
        ...instance,
        [fieldName]: selectValue,
        ePromotionCode: ePromotionCode,
      };
    });
  };

  const renderCampaignDetails = () => {
    return (
      <Card
        title="Campaign Details"
        isActive={activePanel === CampaignActivePanel.Detail}
      >
        <div className="card-body">
          {activePanel !== CampaignActivePanel.Detail && (
            <ButtonActionDetailGroup>
              {AuthenticationManager.isAuthorized(
                ResourceID.CampaignsViewPageEditFeatures
              ) && (
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  onClick={() => {
                    setActivePanel(CampaignActivePanel.Detail);
                  }}
                  disabled={!_.isEmpty(activePanel)}
                  title={
                    !_.isEmpty(activePanel) &&
                    activePanel !== CampaignActivePanel.Detail
                      ? `The panel ${activePanel} is being edit. Please complete the current editing panel first.`
                      : ""
                  }
                >
                  <i className="fas fa-edit" aria-hidden="true" /> Edit
                </ButtonActionDetailGroup.Item>
              )}
            </ButtonActionDetailGroup>
          )}
          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Name"
                require={true}
                input={
                  <TextInput
                    name="name"
                    value={campaign.name}
                    onChange={onInputChange}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Start On"
                require={true}
                input={
                  <DateTimeInput
                    name="startOn"
                    value={campaign.startOn}
                    onChange={onInputChange}
                    placeholder="Enter Start On"
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Code"
                require={true}
                input={
                  <TextInput
                    name="code"
                    value={campaign.code}
                    onChange={onInputChange}
                    readOnly={
                      activePanel !== CampaignActivePanel.Detail ||
                      campaign.status === CampaignStatus.Active
                    }
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="End On"
                require={true}
                input={
                  <DateTimeInput
                    name="endOn"
                    value={campaign.endOn}
                    onChange={onInputChange}
                    placeholder="Enter End On"
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Terms &amp; Conditions"
                require={true}
                input={
                  <TextAreaInput
                    name="termCondition"
                    value={campaign.termCondition}
                    onChange={onInputChange}
                    placeholder="Enter Terms & Condition"
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Description"
                require={true}
                input={
                  <TextAreaInput
                    name="description"
                    value={campaign.description}
                    onChange={onInputChange}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Display Name"
                require={true}
                input={
                  <TextInput
                    name="displayName"
                    value={campaign.displayName}
                    onChange={onInputChange}
                    placeholder="Enter Display Name"
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="ePromo Code"
                visible={activePanel === CampaignActivePanel.Detail}
                input={
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="hasEPromotionCode"
                      valuetype="boolean"
                      value={campaign.hasEPromotionCode}
                      onChange={onEPromotionCodeChange}
                      type="checkbox"
                      checked={campaign.hasEPromotionCode}
                      readOnly={activePanel !== CampaignActivePanel.Detail}
                    />
                  </div>
                }
              />
              <FieldWrapper
                label={
                  activePanel !== CampaignActivePanel.Detail
                    ? "ePromo Code"
                    : ""
                }
                input={
                  <TextInput
                    name="ePromotionCode"
                    value={campaign.ePromotionCode}
                    onChange={onInputChange}
                    placeholder="Enter Promotion Code"
                    disable={!campaign.hasEPromotionCode}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Status"
                input={
                  <TextInput
                    name="status"
                    value={CampaignStatus.getName(campaign.status)}
                    onChange={onInputChange}
                    additionalCssClasses={CampaignStatus.getColor(
                      campaign.status
                    )}
                    readOnly={true}
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Display Weight"
                require={true}
                input={
                  <NumberInput
                    name="displayWeight"
                    value={campaign.displayWeight}
                    onChange={onInputChange}
                    placeholder="Enter Display Weight"
                    min={1}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>
          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Campaign Category"
                require={true}
                input={
                  <SingleSelector
                    type="number"
                    name="category"
                    onChange={onInputChange}
                    value={campaign.category}
                    options={CampaignCategory.All}
                    readOnly={
                      activePanel !== CampaignActivePanel.Detail ||
                      campaign.status === CampaignStatus.Active
                    }
                  />
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Is Stack Campaign"
                input={
                  <CheckboxInput
                    name="isStackCampaign"
                    wrapperClass="pl-0"
                    value={campaign.isStackCampaign}
                    onChange={onInputChange}
                    checked={campaign.isStackCampaign}
                    disabled={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Poster URL"
                input={
                  <TextInput
                    name="posterUrl"
                    value={campaign.posterUrl}
                    onChange={onInputChange}
                    placeholder="Enter Poster URL"
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
                action={
                  <button
                    className="btn btn-primary float-right mt-2 mt-md-0"
                    onClick={() => changeVisibleState(modalAction.preview, true)}
                    disabled={_.isEmpty(campaign.posterUrl)}
                  >
                    Preview
                  </button>
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Max Redemption Quantity Per Transaction"
                require={true}
                input={
                  <NumberInput
                    name="maxRedemptionQuantityPerTransaction"
                    onChange={onInputChange}
                    value={campaign.maxRedemptionQuantityPerTransaction}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            {campaign.category === CampaignCategory.ExternalLoyaltyCard && (
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Redemption Quantity Per Card"
                  require={true}
                  input={
                    <NumberInput
                      name="loyaltyRedemptionQuantityPerCard"
                      onChange={onInputChange}
                      value={campaign.loyaltyRedemptionQuantityPerCard}
                      readOnly={activePanel !== CampaignActivePanel.Detail}
                    />
                  }
                />
              </div>
            )}
            <div className="col-md-6 mb-3">
              <FieldWrapper
                label="Restriction"
                input={
                  <SingleSelector
                    type="number"
                    name="restriction"
                    enableDefaultEntry={false}
                    onChange={onInputChange}
                    value={campaign.restriction}
                    options={CampaignRestriction.All}
                    readOnly={activePanel !== CampaignActivePanel.Detail}
                  />
                }
              />
            </div>
          </div>

          <div className=" row">
            {campaign.loyaltyRedemptionQuantityType ===
              RedemptionQuantityType.Weekly && (
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Reset Day"
                  require={true}
                  input={
                    <SingleSelector
                      type="number"
                      name="loyaltyCardResetDay"
                      onChange={onInputChange}
                      value={campaign.loyaltyCardResetDay}
                      options={DayOfWeek.All}
                      placeholder="Reset Day"
                      readOnly={activePanel !== CampaignActivePanel.Detail}
                    />
                  }
                />
              </div>
            )}
            {campaign.category === CampaignCategory.ExternalLoyaltyCard && (
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Loyalty Program"
                  require={true}
                  input={
                    <TextInput
                      name="loyaltyProgram"
                      onChange={onInputChange}
                      value={campaign.loyaltyProgram}
                      placeholder="Enter Loyalty Program"
                      readOnly={activePanel !== CampaignActivePanel.Detail}
                    />
                  }
                />
              </div>
            )}
          </div>
          <div className=" row">
            {campaign.category === CampaignCategory.ExternalLoyaltyCard && (
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Redemption Quantity Type"
                  require={true}
                  input={
                    <SingleSelector
                      type="number"
                      name="loyaltyRedemptionQuantityType"
                      onChange={onInputChange}
                      value={campaign.loyaltyRedemptionQuantityType}
                      options={RedemptionQuantityType.All}
                      placeholder="Redemption Quantity Type"
                      readOnly={activePanel !== CampaignActivePanel.Detail}
                    />
                  }
                />
              </div>
            )}
            <div className="col-md-6 mb-3"></div>
          </div>

          {activePanel === CampaignActivePanel.Detail && (
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                onClick={() => changeVisibleState(modalAction.save, true)}
              >
                Save changes
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={() => {
                  setActivePanel("");
                }}
              >
                Cancel
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          )}
        </div>
      </Card>
    );
  };

  const renderTopButtons = () => {
    if (activePanel === CampaignActivePanel.Detail) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        {campaign.status === CampaignStatus.Draft && (
          <>
            <ButtonActionDetailGroup.Item
              className="btn btn-secondary"
              onClick={() => changeVisibleState(modalAction.activate, true)}
            >
              Activate
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-danger"
              onClick={() => changeVisibleState(modalAction.delete, true)}
            >
              Delete
            </ButtonActionDetailGroup.Item>
          </>
        )}

        {campaign.status === CampaignStatus.Active &&
          AuthenticationManager.isAuthorized(
            ResourceID.CampaignsViewPageBlock
          ) && (
            <ButtonActionDetailGroup.Item
              className="btn btn-secondary"
              onClick={() => changeVisibleState(modalAction.block, true)}
            >
              Block
            </ButtonActionDetailGroup.Item>
          )}
        {campaign.status === CampaignStatus.Blocked &&
          AuthenticationManager.isAuthorized(
            ResourceID.CampaignsViewPageBlock
          ) && (
            <ButtonActionDetailGroup.Item
              onClick={() => changeVisibleState(modalAction.unBlock, true)}
              className="btn btn-secondary"
            >
              Unblock
            </ButtonActionDetailGroup.Item>
          )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          onClick={() => changeVisibleState(modalAction.historyDialog, true)}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "campaigns",
        name: "Campaigns",
      }),
      activeModule: new NavigationItem({
        identifier: "campaigns",
        name:
          activePanel === CampaignActivePanel.Detail
            ? "Edit Campaign"
            : "View Campaign",
      }),
    });
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          {renderTopButtons()}
          {renderCampaignDetails()}
          <CampaignAvailabilityCard />
          <CampaignValidityConditionsCard />
          <CampaignBundleCard />
          <CustomizeTicketCard />
        </div>
      </div>
      <ConfirmDialog
        visible={visibleState.activate}
        message="Are you sure you want to activate the campaign?"
        onProceed={onActivate}
        onCancel={() => changeVisibleState(modalAction.activate, false)}
      />
      <ConfirmDialog
        visible={visibleState.save}
        message="Are you sure you want to save as draft?"
        onProceed={onSave}
        onCancel={() => changeVisibleState(modalAction.save, false)}
      />
      <ConfirmDialog
        visible={visibleState.block}
        message="Are you sure you want to block the campaign?"
        onProceed={onBlock}
        onCancel={() => changeVisibleState(modalAction.block, false)}
      />
      <ConfirmDialog
        visible={visibleState.unBlock}
        message="Are you sure you want to unblock the campaign?"
        onProceed={onUnblock}
        onCancel={() => changeVisibleState(modalAction.unBlock, false)}
      />
      <ConfirmDialog
        visible={visibleState.delete}
        message="Are you sure you want to delete the campaign?"
        onProceed={onDelete}
        onCancel={() => changeVisibleState(modalAction.delete, false)}
      />
      <PreviewImageDialog
        url={campaign.posterUrl}
        visible={visibleState.preview}
        onClose={() => changeVisibleState(modalAction.preview, false)}
      />
      {visibleState.historyDialog && (
        <HistoryDialog
          objectId={campaign.id}
          onClose={() => changeVisibleState(modalAction.historyDialog, false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.Campaign}
        />
      )}
      <ConfirmDialog
        visible={showCancelConfirmation}
        message="Are you sure you want to cancel?"
        onProceed={confirmCancel}
        onCancel={() => setShowCancelConfirmation(false)}
      />
    </div>
  );
};
