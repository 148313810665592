import React from "react";
import { log } from "../../../common/Common";
import { ApplicationID } from "../../../common/Constants";
import { ArrayHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import { CardMode } from "./CardMode";

export class ApplicationAccessCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      locationCodeOptions: [],
      model: this.mergeDefaultValue(),
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    this.getLocationOptions();
  }

  getLocationOptions() {
    RestClient.sendGetRequest(
      "/api/v1/locations/options",
      (response) => {
        this.setState({ locationCodeOptions: response.data });
      },
      (error) => {
        log(error);
      }
    );
  }

  isAppIdChecked(appId) {
    const { appIds } = this.state.model;
    return appIds.indexOf(appId) > -1;
  }

  isLocationChecked(locCode) {
    const { locationCodes } = this.state.model;
    return locationCodes.indexOf(locCode) > -1;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      appIds: value.appIds || [],
      locationCodes: value.locationCodes || [],
    };
  }

  onCheckBoxChange = (e, useNumberValue) => {
    const fieldName = e.target.getAttribute("field-name");
    const { model } = this.state;
    const { checked, value } = e.target;
    const options = model[fieldName];
    const convertedValue = useNumberValue ? value - 0 : value;
    const newOptions = checked
      ? [...options, convertedValue]
      : options.filter((opt) => opt !== convertedValue);
    const newModel = Object.assign({}, model, { [fieldName]: newOptions });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onAppIdCheck = (e) => {
    this.onCheckBoxChange(e, true);
  };

  onLocationCodeCheck = (e) => {
    this.onCheckBoxChange(e, false);
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onSelectAllLocationClick = (e) => {
    const { locationCodeOptions, model } = this.state;
    const locationCodes = locationCodeOptions.map((x) => x.code);
    const newModel = Object.assign({}, model, { locationCodes });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
    e.preventDefault();
    return false;
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      this.setState(
        {
          isDirty: false,
          model,
        },
        this.onModelChange
      );
      return false;
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      this.setState({ cardMode: nextProps.cardMode });
      return false;
    }
    return true;
  }

  render() {
    const { cardMode, locationCodeOptions } = this.state;
    const locationCodeGroups = ArrayHelper.divide(locationCodeOptions, 3);
    return (
      <Card title="Application Access">
        <div className="row form-group">
          <div className="col-md-3">
            <label className="form-control-label">Allowed system apps:</label>
          </div>
          <div className="col-md-9">
            <div className="form-check">
              <div className="checkbox">
                <label htmlFor="POS-CB" className="form-check-label">
                  <input
                    type="checkbox"
                    id="POS-CB"
                    name="checkbox1"
                    className="mr-1"
                    disabled={
                      CardMode.isViewMode(cardMode) ? "disabled" : undefined
                    }
                    checked={this.isAppIdChecked(ApplicationID.TPOS)}
                    field-name="appIds"
                    onChange={this.onAppIdCheck}
                    value={ApplicationID.TPOS}
                  />
                  Point Of Sales
                </label>
                {this.isAppIdChecked(ApplicationID.TPOS) &&
                  CardMode.isEditMode(cardMode) && (
                    <>
                      &nbsp;(
                      <a href="#" onClick={this.onSelectAllLocationClick}>
                        Select all locations
                      </a>
                      )
                    </>
                  )}
                {this.isAppIdChecked(ApplicationID.TPOS) && (
                  <div className="form-check">
                    {locationCodeGroups.map((group, index) => (
                      <div
                        className="checkbox col-md-6 float-left POS-Check"
                        key={index}
                      >
                        {group.map((option) => (
                          <div className="checkbox" key={option.code}>
                            <label
                              htmlFor={`location_code_${option.code}`}
                              className="form-check-label "
                            >
                              <input
                                type="checkbox"
                                id={`location_code_${option.code}`}
                                name="checkbox3"
                                className="mr-1"
                                disabled={
                                  CardMode.isViewMode(cardMode)
                                    ? "disabled"
                                    : undefined
                                }
                                checked={this.isLocationChecked(option.code)}
                                field-name="locationCodes"
                                onChange={this.onLocationCodeCheck}
                                value={option.code}
                              />
                              {option.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="checkbox" style={{ clear: "both" }}>
                <label htmlFor="ConnectCB" className="form-check-label">
                  <input
                    type="checkbox"
                    id="ConnectCB"
                    name="checkbox3"
                    className="mr-1"
                    disabled={
                      CardMode.isViewMode(cardMode) ? "disabled" : undefined
                    }
                    checked={this.isAppIdChecked(ApplicationID.INCP)}
                    field-name="appIds"
                    onChange={this.onAppIdCheck}
                    value={ApplicationID.INCP}
                  />
                  Connect Portal
                </label>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}
