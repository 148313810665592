import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ShowTimeSelectionModal } from "./modals/ShowTimeSelectionModal";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";

import { SeatPlanComponent } from "./seat.selection/SeatPlanComponent";
import { BalestierDreamerSeatPlan } from "./seat.selection/BalestierDreamerSeatPlan";
import {
  CorporateBookingCouponDetails,
  CorporateBookingModel,
} from "./models/CorporateBookingCreationRequest";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ClientSelectionModal } from "./modals/ClientSelectionModal";
import { NewClientModal } from "./modals/NewClientModal";
import {
  CorporateBookingType,
  CouponType,
  PaymentOption,
  TicketDistributionType,
} from "../../../constants/CorporateBookingConstants";
import {
  Classify,
  LocationBrand,
  MovieFormat,
} from "../../../constants/MovieConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { CustomSelect } from "../../common/CustomSelect";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { useCampaigns } from "../useCampaigns";
import { useServices } from "../../../hooks/useServices";
import { Card } from "../../common/Card";
import { PerformanceTypes } from "../../../constants/PricingConstants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { CompanyType } from "../../../constants/CompanyType";
import { useLayoutLiveLives } from "../useLayoutLiveLives";
import { useRecoilState } from "recoil";
import { corporateBookingAtom } from "../CorporateBookingState";
import { CorporateBookingRestService } from "../../../services/CorporateBookingRestService";

export const CorporateBookingCreate = () => {
  const [instance, setInstance] = useRecoilState(corporateBookingAtom);
  const [performance, setPerformance] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationVenue, setLocationVenue] = useState(null);
  const [client, setClient] = useState(null);
  const [showCoupon, setShowCoupon] = useState(false);
  const [showShowTimeSelectionModal, setShowShowTimeSelectionModal] =
    useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSearchClient, setShowSearchClient] = useState(false);
  const [showNewClient, setShowNewClient] = useState(false);

  const { campaigns, campaignOptions } = useCampaigns();
  const { services } = useServices(PerformanceTypes.SpecialScreening);

  const { remainingCapacity } = useLayoutLiveLives(performance?.id ?? 0);

  const [campaignBundles, setCampaignBundles] = useState([]);

  const history = useHistory();

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "create_corporate_bookings",
        name: "Create Corporate Bookings",
      }),
    });
  };

  useEffect(() => {
    setInstance(new CorporateBookingModel());
  }, []);

  const onClear = () => {
    setInstance(new CorporateBookingModel());
    setPerformance(null);
    setLocation(null);
    setLocationVenue(null);
    setClient(null);
  };

  const onSelectPerformance = (data) => {
    const { performance, location, locationVenue } = data;
    setInstance((prev) => ({ ...prev, performanceId: performance.id }));
    setPerformance(performance);
    setLocation(location);
    setLocationVenue(locationVenue);
    setShowShowTimeSelectionModal(false);
    onCloseAllModals();
  };

  const onSelectClient = (client) => {
    setClient(client);
    setShowSearchClient(false);
  };

  const onCloseAllModals = () => {
    setShowShowTimeSelectionModal(false);
    setShowCreateConfirmation(false);
    setShowSearchClient(false);
    setShowNewClient(false);
    setShowCancelConfirmation(false);
  };
  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const inputType = e.target.getAttribute("type");
    const valueType = e.target.getAttribute("valuetype");
    const cloned = _.cloneDeep(instance);
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    if (_.isEqual("radio", inputType)) {
      selectValue = selectValue === "true";
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      cloned[object][field] = selectValue;
    } else {
      cloned[fieldName] = selectValue;
    }
    setInstance(cloned);
  };

  const onCreate = () => {
    if (!performance) {
      toast.error("The performance is required to create a corporate booking.");
      onCloseAllModals();
      return;
    }

    if (!client) {
      toast.error("The client is required to create a corporate booking.");
      onCloseAllModals();
      return;
    }

    const body = {
      ...instance,
      performanceId: performance.id,
      corporateBookingCompanyId: client.id,
    };
    new CorporateBookingRestService().post(body).then((response) => {
      if(!response) return;
      history.push(`/corporate_bookings/${response.data.id}`);
      toast.success("The corporate booking has been created successful.");
    });
    onCloseAllModals();
  };

  const onSelectServiceCode = (e) => {
    setInstance((data) => {
      const cloned = _.cloneDeep(data);
      cloned.corporateBookingDetails.corporateBookingServiceCode = e.value;
      return { ...cloned };
    });
  };

  const onSelectCampaign = (e) => {
    setInstance((data) => {
      return { ...data, campaignId: e.value };
    });
    const campaign = campaigns.find((x) => x.id === e.value);
    const results = (campaign?.campaignBundles ?? [])
      .filter((x) => x.isActive)
      .map((x) => ({
        id: x.id,
        displayName: `${x.id} - ${x.displayName}`,
      }));
    setCampaignBundles(results);
    if (results.length === 0) {
      setInstance((data) => {
        return { ...data, campaignBundleId: 0 };
      });
    }
  };

  const onSelectCampaignBundle = (e) => {
    setInstance((data) => {
      return { ...data, campaignBundleId: e.value };
    });
  };

  const renderMovieSessionDetails = () => {
    const { ticketDetails, corporateBookingDetails } = instance;

    return (
      <Card title="Movie Session Details">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary float-right"
            onClick={() => setShowShowTimeSelectionModal(true)}
          >
            Select Showtime
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {!_.isNil(performance) &&
          !_.isNil(location) &&
          !_.isNil(locationVenue) && (
            <>
              <div className="card my-0 showtimeCard">
                <div className="card-body my-0">
                  <div className="row form-group my-0">
                    <div className="col-md-3">
                      <img
                        src={performance.posterUrl}
                        alt={performance.primaryTitle}
                      />
                    </div>
                    <div className="col-md-9">
                      <div className="row form-group">
                        <label
                          htmlFor="UserId"
                          className="form-control-label col-md-4"
                        >
                          Title:
                        </label>
                        <div className="col-md-6">
                          <input
                            className="form-control display-input"
                            id="Program"
                            type="text"
                            value={performance.primaryTitle}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="UserId"
                          className="form-control-label col-md-4"
                        >
                          Hall:
                        </label>
                        <div className="col-md-6">
                          <input
                            className="form-control display-input"
                            id="Program"
                            type="text"
                            value={`Shaw Theatres ${locationVenue.name}`}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="UserId"
                          className="form-control-label col-md-4"
                          style={{ width: "100%" }}
                        >
                          Date:
                        </label>
                        <div className="col-md-6">
                          <input
                            className="form-control display-input"
                            id="Program"
                            type="text"
                            value={DateHelper.toDisplayDateFormat(
                              performance.actualDateTime
                            )}
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <label
                          htmlFor="UserId"
                          className="form-control-label col-md-4"
                        >
                          Time:
                        </label>
                        <div className="col-md-6">
                          <input
                            className="form-control display-input"
                            id="Program"
                            type="text"
                            value={DateHelper.toDisplayTimeFormat(
                              performance.actualDateTime
                            )}
                            readOnly={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3 showtimeCard">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label">
                        <i>Performance Service Code:</i>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        className="form-control display-input"
                        type="text"
                        value={performance.service.code}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label">
                        <i>Description:</i>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <input
                        className="form-control display-input"
                        type="text"
                        value={performance.service.name}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row form-group my-0">
                    <div className="col-md-4">
                      <label className="form-control-label">
                        <i>
                          Corporate Booking Service Code
                          <span className="color-red font-weight-bold">*</span>:
                        </i>
                      </label>
                    </div>
                    <div className="col-md-8">
                      <CustomSelect
                        data={services}
                        label="code"
                        value="code"
                        onChange={onSelectServiceCode}
                        selectedValue={
                          corporateBookingDetails.corporateBookingServiceCode
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">
              Booking Type
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-8">
            <select
              className="form-control"
              name="corporateBookingDetails.type"
              valuetype="number"
              value={corporateBookingDetails.type}
              onChange={onInputChange}
              disabled={_.isNil(performance)}
            >
              <option value="">Select One</option>
              {CorporateBookingType.All.map((type, index) => (
                <option value={type.value} key={index}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">
              Number of Guest
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-6 mb-1 mb-md-0">
            <input
              className="form-control"
              placeholder="Enter Number of Guest"
              type="number"
              min="1"
              max={remainingCapacity}
              valuetype="number"
              name="corporateBookingDetails.guestCount"
              value={corporateBookingDetails.guestCount}
              onChange={onInputChange}
              disabled={_.isNil(performance)}
            />
          </div>

          <div className="col-md-2">
            <input
              className="form-control"
              value={remainingCapacity}
              readOnly={true}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">
              Mode of Ticket Distribution
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-8">
            <select
              className="form-control"
              disabled={_.isNil(performance)}
              value={corporateBookingDetails.ticketDistribution}
              valuetype="number"
              name="corporateBookingDetails.ticketDistribution"
              onChange={onInputChange}
            >
              <option value="">Select mode of ticket distribution</option>
              {TicketDistributionType.All.map((type, index) => (
                <option value={type.value} key={index}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-check-label"> Glasses:</label>
          </div>
          <div className="col-md-1">
            <input
              disabled={
                _.isNil(performance) ||
                performance.movieFormat === MovieFormat.DigitalStandard
              }
              className="mr-1"
              type="checkbox"
              checked={ticketDetails.has3dGlasses}
              name="ticketDetails.has3dGlasses"
              valuetype="boolean"
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-7">
            <input
              disabled={_.isNil(performance)}
              className="form-control"
              placeholder="Enter Quantity For 3D Glasses"
              type="number"
              name="ticketDetails.glassesQuantity"
              value={ticketDetails.glassesQuantity}
              valuetype="number"
              onChange={onInputChange}
              style={
                ticketDetails.has3dGlasses
                  ? { display: "flex" }
                  : { display: "none" }
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label text-nowrap">
              Payment Options:
              <span style={{ color: "red" }} className="red font-weight-bold">
                *
              </span>
              :
            </label>
          </div>
          <div className="col-md-8">
            <select
              className="form-control"
              name="corporateBookingDetails.paymentOption"
              valuetype="number"
              value={corporateBookingDetails.paymentOption}
              onChange={onInputChange}
            >
              <option value="">Select Payment Option</option>
              {PaymentOption.All.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {corporateBookingDetails.paymentOption !==
          PaymentOption.PrepaidTickets && (
          <div className="row form-group">
            <div className="col-md-4">
              <label className="form-control-label">
                Payment Amount:
                <span style={{ color: "red" }} className="red font-weight-bold">
                  *
                </span>
              </label>
            </div>
            <div className="col-md-8">
              <input
                className="form-control display-input"
                type="number"
                min="1"
                valuetype="number"
                name="corporateBookingDetails.salesAmount"
                value={corporateBookingDetails.salesAmount}
                onChange={onInputChange}
              />
            </div>
          </div>
        )}
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Requested By:</label>
          </div>
          <div className="col-md-8">
            <input
              className="form-control display-input"
              type="text"
              name="corporateBookingDetails.requestedBy"
              value={corporateBookingDetails.requestedBy}
              onChange={onInputChange}
            />
          </div>
        </div>
        <FieldWrapper
          label="Campaign"
          additionalCss="form-group"
          input={
            <CustomSelect
              data={campaignOptions}
              label="name"
              value="id"
              selectedValue={instance.campaignId}
              onChange={onSelectCampaign}
            />
          }
        />
        <FieldWrapper
          label="Campaign Bundle"
          additionalCss="form-group"
          input={
            <CustomSelect
              data={campaignBundles}
              label="displayName"
              value="id"
              selectedValue={instance.campaignBundleId}
              onChange={onSelectCampaignBundle}
            />
          }
        />
      </Card>
    );
  };

  const renderClient = () => {
    return (
      <Card title="Client Details">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary search"
            onClick={() => setShowSearchClient(true)}
          >
            Choose Existing Client
          </ButtonActionDetailGroup.Item>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            onClick={() => setShowNewClient(true)}
          >
            Add New Client
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {!_.isNil(client) && (
          <>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Company Code:</label>
              </div>

              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  value={client.code}
                  type="text"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Company Name:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Client Name:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.clientName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="CompanyType">
                  Company Type:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  value={CompanyType.getName(client.companyType)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="ContactNo">
                  Contact Number:
                </label>
              </div>
              <div className="col-md-8 ">
                <input
                  className="form-control display-input"
                  type="tel"
                  value={client.contactNumber}
                  inputMode="tel"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="Email">
                  Email Address:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="email"
                  value={client.emailAddress}
                  inputMode="email"
                  readOnly={true}
                />
              </div>
            </div>
          </>
        )}
        {client && client.ticketTypeId && (
          <>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Ticket Type:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.ticketTypeId}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Prepaid Quantity:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.prepaidTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">
                  Used Ticket Quantity:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.usedTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">
                  Remaining Ticket Quantity:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.remainingTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    );
  };

  const renderTicketAndCoupon = () => {
    const { corporateBookingDetails, ticketDetails } = instance;
    return (
      <div className="row">
        <div className="col">
          <Card title="Ticket &amp; Coupon">
            <div className="row">
              <div className="col-xl-6">
                <div className="row form-group">
                  <div className="col-md-4">
                    <label
                      className="form-control-label"
                      htmlFor="DisclaimerText"
                    >
                      Disclaimer:
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control col"
                      placeholder="Enter Disclaimer"
                      value={corporateBookingDetails.disclaimer}
                      name="corporateBookingDetails.disclaimer"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    <label className="form-control-label" htmlFor="MovieTitle">
                      Movie Title:
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control col"
                      name="ticketDetails.moviePrimaryTitle"
                      value={ticketDetails.moviePrimaryTitle}
                      placeholder="Enter Movie Title"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    <label
                      className="form-control-label mt-2"
                      htmlFor="ClassifyCode"
                    >
                      Movie Rating:
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control col"
                      name="ticketDetails.movieRating"
                      value={ticketDetails.movieRating}
                      placeholder="Enter Movie Rating"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    <label
                      className="form-control-label mt-2"
                      htmlFor="ticketdetails"
                    >
                      Ticket Details :
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control col"
                      name="ticketDetails.ticketDetails"
                      value={ticketDetails.ticketDetails}
                      placeholder="Enter Ticket Details"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    <label
                      className="form-control-label mt-2"
                      htmlFor="TicketType"
                    >
                      Ticket Type :
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control col"
                      name="ticketDetails.ticketType"
                      value={ticketDetails.ticketType}
                      placeholder="Enter Ticket Type"
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    Has Ticket Details printed on ticket?
                  </div>
                  <div className="col-md-2">
                    <div className="form-check">
                      <input
                        className="mr-1"
                        type="radio"
                        name="ticketDetails.hasPrintPrice"
                        value={true}
                        checked={ticketDetails.hasPrintPrice}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-check">
                      <input
                        className="mr-1"
                        type="radio"
                        name="ticketDetails.hasPrintPrice"
                        value={false}
                        checked={!ticketDetails.hasPrintPrice}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-4">
                    Has print seat number on ticket?
                  </div>
                  <div className="col-md-2">
                    <div className="form-check">
                      <input
                        className="mr-1"
                        type="radio"
                        name="ticketDetails.hasPrintSeatNumber"
                        value={true}
                        checked={ticketDetails.hasPrintSeatNumber}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-check">
                      <input
                        className="mr-1"
                        type="radio"
                        name="ticketDetails.hasPrintSeatNumber"
                        value={false}
                        checked={!ticketDetails.hasPrintSeatNumber}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 overflow-x mx-3 px-0">
                <div className="card preview-slipbg coupon-preview mb-2">
                  <div className="card-body pb-0">
                    <h4 className="card-title">
                      <span>
                        {_.isEmpty(ticketDetails.moviePrimaryTitle)
                          ? performance?.primaryTitle
                          : ticketDetails.moviePrimaryTitle}
                      </span>
                      <span>
                        (
                        <span id="classifycode">
                          {_.isEmpty(ticketDetails.movieRating)
                            ? Classify.getName(performance?.classify)
                            : ticketDetails.movieRating}
                        </span>
                        )
                      </span>
                    </h4>
                    <div className="row">
                      <p
                        style={{ fontSize: "14px" }}
                        id="Disclaimer"
                        className="pl-3"
                      >
                        {_.isEmpty(corporateBookingDetails.disclaimer)
                          ? "Violence And Brief Coarse Language"
                          : corporateBookingDetails.disclaimer}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-6 pr-0">
                        <small className="float-left preview-rotatetext mt-2">
                          DATE
                        </small>
                        <h2 className="mb-0" style={{ fontSize: "26px" }}>
                          {" "}
                          19-06-2019
                        </h2>
                      </div>
                      <div className="col-4 pr-0 pl-0">
                        <small className="float-left preview-rotatetext mt-2">
                          TIME
                        </small>
                        <h2 className="mb-0" style={{ fontSize: "25px" }}>
                          4:00PM
                        </h2>
                      </div>
                      <div className="col-2 pr-0 pl-0">
                        <small className="mb-0 float-left preview-rotatetext mt-2">
                          SEAT
                        </small>
                        <h2 className="mb-0" style={{ fontSize: "26px" }}>
                          07
                        </h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 pl-0">
                        <img className="w-100" src="../img/testqrcode.png" />
                      </div>
                      <div className="col-8">
                        <div className="row pt-3">
                          <div className="col-10 pl-0">
                            <h4>
                              <span id="ticketlocation">LIDO&nbsp;HALL</span>
                            </h4>
                          </div>
                          <div className="col-1">
                            <h1>3</h1>
                          </div>
                        </div>
                        <div className="row mt-3">cash</div>
                        <div className="row">
                          <small>
                            {_.isEmpty(ticketDetails.ticketDetails)
                              ? performance?.service.name
                              : ticketDetails.ticketDetails}
                          </small>
                        </div>
                        <div className="row">
                          <small>
                            {_.isEmpty(ticketDetails.ticketType)
                              ? "Adult"
                              : ticketDetails.ticketType}{" "}
                            1 Only
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <small>SHAW THEATRES PTE LTD</small>
                      </div>
                      <div className="col-6">
                        <small className="float-right">
                          Co. Reg. No: 194700158G
                        </small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <small>
                          <span>19/06/2019 12:03 PM</span>
                        </small>
                      </div>
                      <div className="col-6">
                        <small className="float-right">testlido.cso</small>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <small>S2E89B0000E29</small>
                      </div>
                      <div className="col-6">
                        <small className="float-right">
                          E001-0100-85-003595
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {renderCouponComponent()}
          </Card>
        </div>
      </div>
    );
  };

  const onRemoveCoupon = () => {
    const cloned = _.cloneDeep(instance);
    cloned.couponDetails = new CorporateBookingCouponDetails();
    setInstance(cloned);
    setShowCoupon(false);
  };

  const onAddCoupon = () => {
    const cloned = _.cloneDeep(instance);
    cloned.couponDetails.hasCoupon = true;
    setInstance(cloned);
    setShowCoupon(true);
  };

  const onCouponTypeChange = (e) => {
    const newType = +e.target.value;
    const cloned = _.cloneDeep(instance);
    cloned.couponDetails.couponType = newType;
    cloned.couponDetails.hasCoupon = true;
    if (newType === CouponType.Custom) {
      cloned.couponDetails.couponName = "";
      cloned.couponDetails.couponDetails = "";
    } else {
      cloned.couponDetails.couponName = "Safra Discount";
      cloned.couponDetails.couponDetails =
        "Present this coupon at MovieBites to enjoy $1 Off Popcorn (Sweet/Salted) Combo Set (Regular/Large/Family), Hotdog or Nachos Combo Set";
    }
    setInstance(cloned);
  };

  const renderCouponComponent = () => {
    const { couponDetails } = instance;
    return (
      <>
        <ButtonActionDetailGroup>
          {!showCoupon && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={onAddCoupon}
            >
              Add Coupon
            </ButtonActionDetailGroup.Item>
          )}
          {showCoupon && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={onRemoveCoupon}
            >
              Remove Coupon
            </ButtonActionDetailGroup.Item>
          )}
        </ButtonActionDetailGroup>
        {showCoupon && (
          <div className="row form-group">
            <div className="col-xl-6">
              <div className="row form-group">
                <div className="col-md-12">Coupon Type:</div>
              </div>
              <div className="row form-group">
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      value={CouponType.Custom}
                      checked={couponDetails.couponType === CouponType.Custom}
                      onChange={onCouponTypeChange}
                    />
                    <label className="form-check-label">Custom</label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      value={CouponType.Premiere}
                      checked={couponDetails.couponType === CouponType.Premiere}
                      onChange={onCouponTypeChange}
                    />
                    <label className="form-check-label">Premiere</label>
                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3">
                  <label className="form-control-label" htmlFor="CoupName">
                    Coupon Name:
                  </label>
                </div>
                <div className="col-md-9">
                  <input
                    className="form-control"
                    name="couponDetails.couponName"
                    value={couponDetails.couponName}
                    placeholder="Enter Coupon Name"
                    onChange={onInputChange}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-3">
                  <label className="form-control-label" htmlFor="CoupDetails">
                    Coupon Details:
                  </label>
                </div>
                <div className="col-md-9">
                  <textarea
                    className="form-control"
                    name="couponDetails.couponDetails"
                    style={{ fontSize: "80%", resize: "none" }}
                    placeholder="Enter Coupon Details"
                    rows="7"
                    value={couponDetails.couponDetails}
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 overflow-x mx-3 px-0">
              <div className="card preview-slipbg coupon-preview mb-2">
                <div className="card-body pb-0">
                  <h4 className="card-title">
                    COUPON SLIP
                    <br />
                    <small id="couponName">
                      {_.isEmpty(couponDetails.couponName)
                        ? "Product name"
                        : couponDetails.couponName}
                    </small>
                  </h4>

                  <div className="row">
                    <div className="col-sm-4" style={{ paddingLeft: "0" }}>
                      <img className="w-100" src="../img/testqrcode.png" />
                    </div>
                    <div className="col-sm-8 coup-preview-detail">
                      <small
                        style={{
                          fontSize: "70%",
                          display: "block",
                          lineHeight: "17px",
                        }}
                        className="coup-details"
                      >
                        {_.isEmpty(couponDetails.couponDetails)
                          ? "Product details"
                          : couponDetails.couponDetails}
                      </small>
                    </div>
                    <small
                      className="col-sm-4 pl-0 text-center"
                      style={{ marginTop: "-18px" }}
                    >
                      C213
                    </small>
                  </div>

                  <div className="row mt-3" style={{ fontSize: "80%" }}>
                    <div className="col-sm-6">
                      Pay Type :<label className="payTypeLabel">cash</label>
                    </div>
                    <div className="col-sm-6 text-right">
                      Valid Till :
                      <label className="payTypeLabel">19/06/2019</label>
                    </div>
                  </div>
                  <div className="row pb-2 ml-0" style={{ fontSize: "90%" }}>
                    <small id="coup-terms1">
                      *THIS COUPON IS VALID ON THE SAME DAY OF MOVIE
                    </small>
                    <small id="coup-terms2">
                      *THIS COUPON IS NOT EXCHANGEABLE FOR CASH OR OTHER ITEMS.
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <small>SHAW THEATRES PTE LTD</small>
                    </div>

                    <div className="col-sm-6">
                      <small className="float-right">
                        Co. Reg. No: 194700158G
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <small>S2E89B0000E29</small>
                    </div>
                    <div className="col-sm-5">
                      <small>19/06/2019 12:03 PM</small>
                    </div>
                    <div className="col-sm-3">
                      <small style={{ float: "right" }}>testlido.cso</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderBottomActions = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#submitModal"
          onClick={() => setShowCreateConfirmation(true)}
        >
          Create
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          onClick={onClear}
        >
          Clear
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#cancelModal"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const canSelectSeat = () => {
    const { seatCodes, guestCount } = instance.corporateBookingDetails;
    if (!seatCodes) {
      return true;
    }
    return seatCodes.length < guestCount;
  };

  const isDreamersBalestier =
    performance &&
    performance.locationVenueBrand === LocationBrand.DreamersBalestier;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="d-flex flex-row justify-content-end mb-3" />
            <div className="row">
              <div className="col-xl-7">{renderMovieSessionDetails()}</div>
              <div className="col-xl-5">{renderClient()}</div>
            </div>
            {!isDreamersBalestier && performance && (
              <SeatPlanComponent
                key={performance.id}
                canSelectSeat={canSelectSeat}
                remainingCapacity={remainingCapacity}
              />
            )}
            {isDreamersBalestier && (
              <BalestierDreamerSeatPlan
                performanceId={performance.id}
                guestCount={instance.corporateBookingDetails.guestCount}
              />
            )}
            {renderTicketAndCoupon()}
            {renderBottomActions()}
          </div>
        </div>
      </div>
      <ShowTimeSelectionModal
        visible={showShowTimeSelectionModal}
        onSelect={onSelectPerformance}
        onCancel={() => setShowShowTimeSelectionModal(false)}
      />
      <ClientSelectionModal
        visible={showSearchClient}
        onSelect={onSelectClient}
        onCancel={() => setShowSearchClient(false)}
      />
      <NewClientModal
        visible={showNewClient}
        onSelect={onSelectClient}
        onCancel={() => setShowNewClient(false)}
      />
      <ConfirmDialog
        visible={showCreateConfirmation}
        title="Create corporate booking"
        message="Are you sure you want to create corporate booking?"
        onProceed={onCreate}
        onCancel={() => setShowCreateConfirmation(false)}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        title="Cancel corporate booking"
        message="Are you sure you want to cancel?"
        onProceed={() => history.push("/corporate_bookings")}
        onCancel={() => setShowCancelConfirmation(false)}
      />
    </>
  );
};
