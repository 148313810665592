import React from "react";
import { StringHelper } from "../../../../common/Helpers";

export const DisneyCardPreview = (props) => {
  const { pass } = props;
  return (
    <div
      className="card hide-card"
      id="DisneyCard"
      style={{ borderRadius: "20px" }}
    >
      <div
        className="card-body pb-1"
        style={{
          background: "url('/img/membership-cards/disney-pass.png')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "20px",
        }}
      >
        <div className="row" style={{ marginTop: "120px" }}>
          <div className="mt-auto col-8">
            <label className="form-control-label row m-0 Name">
              {pass.name}
            </label>
            <label className="form-control-label row m-0 PassCode">
              {pass.code}
            </label>
            <label
              className="form-control-label my-0 float-right ExpiryDate"
              style={{ fontSize: "12px" }}
            >
              {StringHelper.asDateStringToMembershipCardDateFormat(
                pass.expiryDateStr
              )}
            </label>
            <label
              className="form-control-label my-0 float-right mr-1"
              style={{ fontSize: "12px" }}
            >
              EXP
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
