import { BaseListing } from "../../common/BaseListing";
import React from "react";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";
import { Link } from "react-router-dom";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class MovieListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      search: "",
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
    });
  };

  getApiPath = () => "/api/v1/movies";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters, searchBy) {
    const { search } = this.state;
    let request = {
      includes: "Company",
    };

    if (!_.isEmpty(search)) {
      request.search = search;
    }

    return super.injectSearchTerm(queryParameters, request);
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Primary Title</th>
            <th>Secondary Title</th>
            <th>Distribution Company</th>
            <th>
              Updated On{" "}
              <i
                className="fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Created On
              <i
                className="fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody id="data">
          {!_.isNil(this.state.data)
            ? this.state.data.map((item) => {
                return (
                  <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.primaryTitle}</td>
                    <td>{item.secondaryTitle}</td>
                    <td>{item.company?.name}</td>
                    <td className="datetime">
                      {DateHelper.formatDateTimeString(item.updatedOn)}
                    </td>
                    <td className="datetime">
                      {DateHelper.formatDateTimeString(item.createdOn)}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }

  generateTableFilter() {
    const { search } = this.state;
    return (
      <div className="row mb-3">
        <div className="col-md-6 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="search"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onTextBoxChange}
            value={search}
          />
          <span>&nbsp;</span>
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(ResourceID.MovieCreateButtonPage) && (
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item className="btn btn-primary">
            <Link to="/movies/create" className="text-white">
              <i className="fas fa-plus-circle" /> Create New Movie
            </Link>
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      )
    );
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}
