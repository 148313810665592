import React, { Component } from "react";

export class PriceUpdateSummary extends Component {
  constructor() {
    super();
  }

  render() {
    const { data } = this.props;
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              Summary
              <i
                className="fas fa-chevron-down float-right zoom-1_5 summary"
                style={{ display: "none" }}
                aria-hidden="true"
              />
              <i
                className="fas fa-chevron-up float-right zoom-1_5 summary"
                aria-hidden="true"
              />
            </div>
            <div className="card-body summary">
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                Please review pricing changes carefully.
              </div>
              <div className="table-responsive table-px-12">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <tr>
                      <th>Service Code</th>
                      <th>Service Name</th>
                      <th style={{ width: "10%" }}>Ticket Type</th>
                      <th style={{ width: "12%" }}>Current Pricing</th>
                      <th style={{ width: "10%" }}>New Pricing</th>
                      <th>Latest Effective Start</th>
                      <th>New Effective Start</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td title={item.serviceCode}>{item.serviceCode}</td>
                            <td title={item.serviceName}>{item.serviceName}</td>
                            <td title={item.ticketType}>{item.ticketType}</td>
                            <td title={item.latestPricing}>
                              {item.latestPricing}
                            </td>
                            <td
                              style={{ color: "green" }}
                              title={item.newPricing}
                            >
                              {item.newPricing}
                            </td>
                            <td
                              className="time"
                              title={item.latestEffectiveStart}
                            >
                              {item.latestEffectiveStart}
                            </td>
                            <td
                              className="time"
                              style={{ color: "green" }}
                              title={item.newEffectiveStart}
                            >
                              {item.newEffectiveStart}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
