import React, { useEffect, useState } from "react";
import RestClient from "../../../../common/RestClient";
import { DateHelper } from "../../../../common/Helpers";
import { toast } from "react-toastify";
import { CustomSelect } from "../../../common/CustomSelect";
import { PerformanceRestService } from "../../../../services/PerformanceRestService";
import { PerformanceStatus } from "../../../../constants/MovieConstants";

const ExcludedLocationCodes = ["0000"];

export const ShowTimeSelectionModal = (props) => {
  const [locations, setLocations] = useState([]);
  const [locationVenues, setLocationVenues] = useState([]);
  const [movies, setMovies] = useState([]);
  const [actualDateTimes, setActualDateTimes] = useState({});
  const [performances, setPerformances] = useState([]);

  const [performanceId, setPerformanceId] = useState(0);
  const [locationId, setLocationId] = useState(0);
  const [locationVenueId, setLocationVenueId] = useState(0);
  const [movieId, setMovieId] = useState(0);
  const [showDate, setShowDate] = useState("");

  const { onSelect, onCancel, visible } = props;

  useEffect(() => {
    RestClient.sendGetRequest(
      "api/v1/locations",
      (response) =>
        setLocations(
          response.data.filter(
            (item) => !ExcludedLocationCodes.includes(item.code)
          )
        ),
      handleError
    );
  }, [setLocations]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      "api/v1/locations/venues",
      {
        locationId: locationId,
      },
      (response) => setLocationVenues(response.data),
      handleError
    );
  }, [setLocationVenues, locationId]);

  useEffect(() => {
    RestClient.sendGetRequest(
      "api/v1/movies",
      (response) => setMovies(response.data),
      handleError
    );
  }, []);

  useEffect(() => {
    if (locationId <= 0 || movieId <= 0 || locationVenueId <= 0) {
      return;
    }

    new PerformanceRestService()
      .get('', {
        movieId,
        locationId,
        locationVenueIds: locationVenueId,
        includes: "Service",
        isCorporateBooking: true,
        status: PerformanceStatus.Public
      })
      .then((response) => {
        const results = response.data.data;
        setPerformances(results);
        loadActualDateTimes(results);
      });
  }, [locationId, locationVenueId, movieId]);

  const loadActualDateTimes = (performances) => {
    const actualDateTimes = {};
    for (const performance of performances) {
      const key = DateHelper.toDisplayDateFormat(performance.actualDateTime);
      if (!actualDateTimes.hasOwnProperty(key)) {
        actualDateTimes[key] = [];
      }
      const value = DateHelper.toDisplayTimeFormat(performance.actualDateTime);
      actualDateTimes[key].push({
        performanceId: performance.id,
        showTime: value,
      });
    }
    setActualDateTimes(actualDateTimes);
  };

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onSelectShowTime = (e) => {
    setPerformanceId(+e.target.value);
  };

  const onSelectMovie = (e) => {
    setMovieId(e.value);
  };

  const onConfirm = () => {
    if (onSelect) {
      const performance = performances.find((x) => x.id === performanceId);
      const location = locations.find((x) => x.id === locationId);
      const locationVenue = locationVenues.find(
        (x) => x.id === locationVenueId
      );
      onSelect({ performance, location, locationVenue });
    }
  };

  if (!visible) {
    return null;
  }

  const disableConfirmation =
    locationId <= 0 ||
    locationVenueId <= 0 ||
    movieId <= 0 ||
    performanceId <= 0;
  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select Showtime</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row form-group">
                <label className="form-control-label col-md-4">Location:</label>
                <div className="col-md-12">
                  <select
                    className="form-control"
                    onChange={(e) => setLocationId(+e.target.value)}
                  >
                    <option value={0}>Select One</option>
                    {locations.map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <label
                  className="form-control-label col-md-4"
                  htmlFor="LocationHall"
                >
                  Location Hall:
                </label>
                <div className="col-md-12">
                  <select
                    className="form-control"
                    id="Location"
                    onChange={(e) => setLocationVenueId(+e.target.value)}
                  >
                    <option value={0}>Select One</option>
                    {locationVenues.map((x) => (
                      <option value={x.id} key={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label
                    className="form-control-label mb-0"
                    htmlFor="FilmTitle"
                  >
                    Film Title:
                  </label>
                  <CustomSelect
                    data={movies}
                    label="primaryTitle"
                    value="id"
                    onChange={onSelectMovie}
                    selectedValue={movieId}
                  />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-12">
                  <label className="form-control-label" htmlFor="EventDT">
                    Movie Date:
                  </label>
                  <select
                    className="form-control mb-0"
                    onChange={(e) => setShowDate(e.target.value)}
                  >
                    <option value={0}>Select One</option>
                    {Object.keys(actualDateTimes).map((x, index) => {
                      return (
                        <option value={x} key={index}>
                          {x}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-12">
                  <label className="form-control-label" htmlFor="EventDT">
                    Movie Time:
                  </label>
                  <select
                    className="form-control mb-0"
                    onChange={onSelectShowTime}
                  >
                    <option value={0}>Select One</option>
                    {actualDateTimes[showDate] &&
                      actualDateTimes[showDate].map((x, index) => {
                        return (
                          <option value={x.performanceId} key={index}>
                            {x.showTime}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={onConfirm}
                disabled={disableConfirmation}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
