import React from "react";

export const InputFormItem = (props) => {
  const { label, component, required } = props;
  return (
    <div className="row form-group">
      <label className="col form-control-label text-nowrap">
        {label}:
        {required && <span className="color-red font-weight-bold">*</span>}
      </label>
      <div className="col-12">{component}</div>
    </div>
  );
};
