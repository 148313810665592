import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../common/Constants";
import { StringHelper } from "../../common/Helpers";
import RestClient from "../../common/RestClient";
import { BreadCrumb } from "../common/BreadCrumb";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { HistoryDialog } from "../common/HistoryDialog";
import { ApplicationAccessCard } from "./cards/ApplicationAccessCard";
import { CardMode } from "./cards/CardMode";
import { GeneralInformationCard } from "./cards/GeneralInformationCard";
import { StaffAuthenticationCard } from "./cards/StaffAuthenticationCard";
import { UserStatus } from "./common/UserMamagementConstants";
import { ResetPasswordDialog } from "./dialogs/ResetPasswordDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import _ from "lodash";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";

const EditPageName = "Edit User";
const ViewPageName = "View User";

export class UserDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appAccessInfo: null,
      authInfo: null,
      authInfoValidations: [],
      cardMode: CardMode.View,
      generalInfo: null,
      generalInfoValidations: [],
      showDeactiveUserDialog: false,
      showHistoryDialog: false,
      showResetPasswordDialog: false,
      showClearConfirmation: false,
      showSaveConfirmation: false,
      user: null,
      userId: "",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ userId: id }, this.refreshData);
  }

  isValidated() {
    const { authInfoValidations, generalInfoValidations } = this.state;
    return (
      authInfoValidations.every((val) => val.isValid) &&
      generalInfoValidations.every((val) => val.isValid)
    );
  }

  loadUser(id) {
    RestClient.sendGetRequest(
      `/api/v1/users/${id}`,
      (response) => {
        this.setState({ user: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  onApplicationAccessChange = (model) => {
    this.setState({ appAccessInfo: model });
  };

  onClearClick = () => {
    this.setState({
      user: Object.assign({}, this.state.user),
      showClearConfirmation: false,
    });
  };

  onHideConfirmDialog = () => {
    this.setState({ showSaveConfirmation: false });
  };

  onshowSaveConfirmation = () => {
    this.setState({ showSaveConfirmation: true });
  };

  onDeactivateUserClick = () => {
    this.setState({ showDeactiveUserDialog: true });
  };

  onEditUserClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const effectiveStartDate = StringHelper.isNullOrEmpty(
      model.effectiveStartDateStr
    )
      ? null
      : model.effectiveStartDateStr;
    const effectiveEndDate = StringHelper.isNullOrEmpty(
      model.effectiveEndDateStr
    )
      ? null
      : model.effectiveEndDateStr;
    const newModel = Object.assign(model, {
      effectiveStartDate,
      effectiveEndDate,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideDeactiveUserDialog = () => {
    this.setState({ showDeactiveUserDialog: false });
  };

  onHideHistoryDialog = () => {
    this.setState({ showHistoryDialog: false });
  };

  onHideResetPasswordDialog = () => {
    this.setState({ showResetPasswordDialog: false });
  };

  onProceedDeactiveUserDialog = () => {
    const { id, userStatus } = this.state.user;
    const request = {
      userId: id,
      status:
        userStatus === UserStatus.Active
          ? UserStatus.Inactive
          : UserStatus.Active,
    };
    RestClient.sendPostRequest(
      "/api/v1/users/update_status",
      request,
      (response) => {
        const user = Object.assign({}, this.state.user, {
          userStatus: response.userStatus,
        });
        const action =
          response.userStatus === UserStatus.Active
            ? "activated"
            : "deactivated";
        this.setState({
          showDeactiveUserDialog: false,
          user,
        });
        this.handleSuccess(`User have been ${action} successfully.`);
      },
      (error) => {
        this.setState({ showDeactiveUserDialog: false });
        this.handleError(error);
      }
    );
  };

  onResetPasswordDialogSuccess = () => {
    this.setState({ showResetPasswordDialog: false });
    this.handleSuccess("The password is reset successfully");
  };

  onResetPasswordClick = () => {
    this.setState({ showResetPasswordDialog: true });
  };

  onStaffAuthenticationModelChange = (model) => {
    const roleIds = model.roles.map((role) => role.id);
    const mappedModel = Object.assign(model, { roleIds });
    this.setState({ authInfo: mappedModel });
  };

  onStaffAuthenticationValidationsChange = (validations) => {
    this.setState({ authInfoValidations: validations });
  };

  getDummyEmail(firstName, middleName, lastName) {
    middleName = !_.isEmpty(middleName) ? middleName : "";
    return (
      StringHelper.handleize(
        firstName + " " + middleName + " " + lastName,
        "_"
      ) + "@shaw.com.sg"
    );
  }

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({
        isSubmit: true,
        showSaveConfirmation: false,
      });
      return;
    }
    const { appAccessInfo, authInfo, generalInfo, userId } = this.state;
    if (_.isEmpty(generalInfo?.email)) {
      generalInfo.email = this.getDummyEmail(
        generalInfo.firstName,
        generalInfo.middleName,
        generalInfo.lastName
      );
    }
    const request = Object.assign(
      { },
      generalInfo,
      authInfo,
      appAccessInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/users/${userId}`,
      request,
      () => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          showSaveConfirmation: false,
        });
        this.handleSuccess(GenericMessages.SaveChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({
          showSaveConfirmation: false,
        });
        this.handleError(error);
      }
    );
  };

  onViewHistoryClick = () => {
    this.setState({ showHistoryDialog: true });
  };

  refreshData() {
    const { userId } = this.state;
    this.loadUser(userId);
  }

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "user-managements",
        name: "User Management",
      }),
      activeModule: new NavigationItem({
        identifier: "user-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  handleSuccess = (message) => {
    toast.success(message);
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  render() {
    const {
      cardMode,
      isSubmit,
      showDeactiveUserDialog,
      showHistoryDialog,
      showResetPasswordDialog,
      user,
      showClearConfirmation,
      showSaveConfirmation,
    } = this.state;
    const generalInfo = Object.assign(
      { departmentId: "", designationId: "" },
      this.state.generalInfo
    );
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <ButtonActionDetailGroup className="d-flex flex-row-reverse">
                    <ButtonActionDetailGroup.Item
                      className="btn btn-outline-secondary"
                      onClick={this.onViewHistoryClick}
                    >
                      History
                    </ButtonActionDetailGroup.Item>
                    {AuthenticationManager.isAuthorized(
                      ResourceID.UserDeactivateActivateButton
                    ) && (
                        <React.Fragment>
                          {user && user.userStatus === UserStatus.Active ? (
                            <ButtonActionDetailGroup.Item
                              className="btn btn-danger"
                              disabled={user ? undefined : "disabled"}
                              onClick={this.onDeactivateUserClick}
                            >
                              <i className="fas fa-user-alt-slash" /> Deactivate
                            </ButtonActionDetailGroup.Item>
                          ) : (
                            <ButtonActionDetailGroup.Item
                              className="btn btn-success"
                              disabled={user ? undefined : "disabled"}
                              onClick={this.onDeactivateUserClick}
                            >
                              <i className="fas fa-user-alt" /> Activate
                            </ButtonActionDetailGroup.Item>
                          )}
                        </React.Fragment>
                      )}
                    {AuthenticationManager.isAuthorized(
                      ResourceID.ResetPasswordButton
                    ) && (
                        <ButtonActionDetailGroup.Item
                          className="btn btn-primary"
                          disabled={user ? undefined : "disabled"}
                          onClick={this.onResetPasswordClick}
                        >
                          Reset Password
                        </ButtonActionDetailGroup.Item>
                      )}
                    {AuthenticationManager.isAuthorized(
                      ResourceID.EditUserButtonPage
                    ) && (
                        <ButtonActionDetailGroup.Item
                          className="btn btn-primary"
                          value="Edit"
                          disabled={user ? undefined : "disabled"}
                          onClick={this.onEditUserClick}
                        >
                          <i className="fas fa-user-edit" /> Edit User
                        </ButtonActionDetailGroup.Item>
                      )}
                  </ButtonActionDetailGroup>
                </div>
              </div>)}
            <div className="row">
              <div className="col-md-12">
                <GeneralInformationCard
                  cardMode={cardMode}
                  defaultValue={user}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <StaffAuthenticationCard
                  cardMode={cardMode}
                  defaultValue={user}
                  departmentId={generalInfo.departmentId}
                  designationId={generalInfo.designationId}
                  isSubmit={isSubmit}
                  onModelChange={this.onStaffAuthenticationModelChange}
                  onValidationsChange={
                    this.onStaffAuthenticationValidationsChange
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ApplicationAccessCard
                  cardMode={cardMode}
                  defaultValue={user}
                  onModelChange={this.onApplicationAccessChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <div id="EditButtons" className="row pb-3">
                <div className="d-inline-flex ml-auto">
                  <button
                    className="btn btn-success mr-2"
                    onClick={this.onshowSaveConfirmation}
                  >
                    Save Changes
                  </button>
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() =>
                      this.setState({ showClearConfirmation: true })
                    }
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {showDeactiveUserDialog && user && (
          <>
            <ConfirmDialog
              visible={user.userStatus === UserStatus.Active}
              onCancel={this.onHideDeactiveUserDialog}
              onProceed={this.onProceedDeactiveUserDialog}
              message="Are you sure you want to deactivate this user?"
              title="Deactivate User"
            />
            <ConfirmDialog
              visible={user.userStatus !== UserStatus.Active}
              onCancel={this.onHideDeactiveUserDialog}
              onProceed={this.onProceedDeactiveUserDialog}
              message="Are you sure you want to activate this user?"
              title="Activate User"
            />
          </>
        )}
        {showResetPasswordDialog && user && (
          <ResetPasswordDialog
            onClose={this.onHideResetPasswordDialog}
            onSuccess={this.onResetPasswordDialogSuccess}
            username={user.username}
          />
        )}
        {showHistoryDialog && user && (
          <HistoryDialog
            objectId={user.id}
            objectType={ObjectType.UserManagement}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/users/histories"
          />
        )}
        <ConfirmDialog
          visible={showClearConfirmation}
          onCancel={() => this.setState({ showClearConfirmation: false })}
          onProceed={this.onClearClick}
          message="Are you sure to clear?"
          title="Confirm"
        />
        <ConfirmDialog
          visible={showSaveConfirmation}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to save changes?"
          title="Save changes"
        />
      </div>
    );
  }
}
