import React, { Component } from "react";
import '../css/documentation.scss';

export class ResetUser extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Reset A User's Password</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div class="createUser container-fluid">
                            <ol>
                                <li class="createList">
                                    Click on <strong>'User'</strong> link from main left side menu. This will bring you to User Listing Page. <br />
                                    <img src="/img/documentation-img/users/Users_Menu.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    From the user listing page, you can select a ‘record’ from the User listing table.<br />
                                    <img src="/img/documentation-img/users/Select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Upon clicking a record from the table, you will be redirected to the ‘User Details page’.<br />
                                    <img src="/img/documentation-img/users/userDetails.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To reset a user password, click on the ‘Reset password’ button found in the top right of the User Details page.
                                    <img src="/img/documentation-img/users/resetBtn.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Next, a prompt will appear. You may manually enter the desired password or use the ‘Generate Password’ button to automatically generate random characters as a password.<br/>
                                    <img src="/img/documentation-img/users/generatePassword.png" class="userImage" /><br />
                                </li>
                                <li class="createList">
                                    You must confirm the password generated and both passwords entered must match.<br /><br />
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle"></i>
                                        <strong class="noteHeader">Information:</strong><br /><br />
                                        Password: You can key in your own password or generate a strong random password from ‘Generate Password’ button. Remember to take note of the password if you choose to generate password.
                                    </div>
                                </li>
                                <li class="createList">
                                    Click 'Reset Password' to reset the password of the user.<br />
                                    <img src="/img/documentation-img/users/resetPassPrompt.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    A message prompt will show once the changes are made successfully.<br />
                                    <img src="/img/documentation-img/users/resetMsg.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/users/activateDeactivateUser" className="btn btn-primary">&laquo; Activate/Deactivate User</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}