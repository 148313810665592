import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { FilmFestivalDetailCard } from "./cards/FilmFestivalDetailCard";
import { ProgrammeCard } from "./cards/ProgrammeCard";
import { FilmFestivalPreview } from "./preview/FilmFestivalPreview";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const EditPageName = "Edit Scheduled Film Festival";
const ViewPageName = "View Scheduled Film Festival";

export class ScheduledFilmFestivalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.ScheduleView,
      generalInfo: null,
      generalInfoValidations: [],
      programmeInfo: [],
      programmeInfoValidations: [],
      scheduledFestival: null,
      scheduledFestivalId: "",
      showCancelDialog: false,
      showConfirmDialog: false,
      cachesReleases: [],
      isEdit: true,
      showPreview: false,
      isUpdate: true,
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const scheduledFestivalId = isNaN(id) ? 0 : Number(id);
    if (isNaN(id) || scheduledFestivalId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ scheduledFestivalId }, this.refreshData);
  }

  componentDidUpdate() {
    if (this.state.isUpdate) {
      this.setState({ isUpdate: false });
      this.refreshData();
    }
  }

  isValidated() {
    const { generalInfoValidations, programmeInfoValidations } = this.state;
    return (
      generalInfoValidations.every((val) => val.isValid) &&
      programmeInfoValidations.every((val) => val.isValid)
    );
  }

  loadScheduledFestival(id) {
    RestClient.sendGetRequest(
      `/api/v1/scheduled_film_festivals/${id}`,
      (response) => {
        this.setState({ scheduledFestival: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onEditFestivalClick = () => {
    this.setState({ cardMode: CardMode.ScheduleUpdate });
  };

  onProgrammeInfoModelChange = (model) => {
    this.setState({ programmeInfo: model });
  };

  onProgrammeInfoValidationsChange = (validations) => {
    this.setState({ programmeInfoValidations: validations });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.isNullOrEmpty(
      model.displayStartTimeStr
    )
      ? null
      : model.displayStartTimeStr;
    const displayEndTime = StringHelper.isNullOrEmpty(model.displayEndTimeStr)
      ? null
      : model.displayEndTimeStr;
    const planToPublish = StringHelper.isNullOrEmpty(model.planToPublishStr)
      ? null
      : model.planToPublishStr;
    const displayWeight = isNaN(model.displayWeight)
      ? 0
      : Number(model.displayWeight);
    const newModel = Object.assign(model, {
      displayStartTime,
      displayEndTime,
      planToPublish,
      displayWeight,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.ScheduleView,
      festival: Object.assign({}, this.state.festival),
      showCancelDialog: false,
      isUpdate: true,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { scheduledFestivalId, generalInfo, programmeInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo,
      programmeInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/scheduled_film_festivals/${scheduledFestivalId}`,
      request,
      (response) => {
        toast.success(GenericMessages.SaveChangesSuccessfulMessag);
        this.setState({
          cardMode: CardMode.ScheduleView,
          festival: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onChangeEdit = (value) => {
    this.setState({ isEdit: value });
  };

  onChangeCacheReleases = (value) => {
    this.setState({ cachesReleases: value });
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  refreshData() {
    const { scheduledFestivalId } = this.state;
    this.loadScheduledFestival(scheduledFestivalId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(
      `/film_festivals/schedules/${additionalParameter ?? ""}`
    );
  }

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "film_festivals",
        name: "Film Festivals",
      }),
      activeModule: new NavigationItem({
        identifier: "create-film-festival",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };


  onProceedDelete = () => {
    const { scheduledFestivalId } = this.state;
    RestClient.sendDeleteRequest(
      `/api/v1/scheduled_film_festivals/${scheduledFestivalId}`,
      () => {
        toast.success("The scheduled film festival has been deleted successful.");
        this.redirectToListingPage();
      }, (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
    this.setState({ showDeleteModal: false });
  }

  onDelete = () => {
    this.setState({ showDeleteModal: true });
  };

  onCancel = () => {
    this.setState({ showDeleteModal: false });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      programmeInfo,
      showCancelDialog,
      scheduledFestival,
      showConfirmDialog,
      showPreview,
      isEdit,
      cachesReleases,
      showDeleteModal,
    } = this.state;
    if (showPreview) {
      const previewModel = Object.assign(
        {},
        generalInfo,
        programmeInfo
      );
      return (
        <>
          <FilmFestivalPreview
            festival={previewModel}
            programmeInfo={cachesReleases}
          />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    {AuthenticationManager.isAuthorized(
                      ResourceID.FilmFestivalScheduledEditButtonPage
                    ) && (
                        <>
                          <button
                            className="btn btn-primary ml-2 mb-2"
                            value="Edit"
                            disabled={scheduledFestival ? undefined : "disabled"}
                            onClick={this.onEditFestivalClick}
                          >
                            <i className="fas fa-edit"></i> Edit
                          </button>
                          <button
                            className="btn btn-danger ml-2 mb-2"
                            value="Delete Schedule"
                            disabled={scheduledFestival ? undefined : "disabled"}
                            onClick={this.onDelete}
                          > Delete Schedule
                          </button>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <FilmFestivalDetailCard
                  cardMode={cardMode}
                  defaultValue={scheduledFestival}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                  isEdit={isEdit}
                />
                <ProgrammeCard
                  displayStart={generalInfo?.displayStartTime}
                  displayEnd={generalInfo?.displayEndTime}
                  cardMode={cardMode}
                  generalInfoValidations={this.state.generalInfoValidations}
                  defaultValue={scheduledFestival}
                  isSubmit={isSubmit}
                  model={programmeInfo}
                  onModelChange={this.onProgrammeInfoModelChange}
                  onValidationsChange={this.onProgrammeInfoValidationsChange}
                  onEditableChange={this.onChangeEdit}
                  onChangeCacheReleases={this.onChangeCacheReleases}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && scheduledFestival}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && scheduledFestival}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showDeleteModal}
          message={"Are you sure you want to delete this scheduled film festival?"}
          onProceed={this.onProceedDelete}
          onCancel={this.onCancel}
        />
      </div>
    );
  }
}
