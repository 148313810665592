import React, { useCallback, useEffect, useState } from "react";
import { Strings } from "../../../common/Common";
import RestClient from "../../../common/RestClient";
import { HistoryDialog } from "../../common/HistoryDialog";
import { TicketView } from "../common/TicketView";
import TransactionInformation from "../common/TransactionInformation";
import { BreadCrumb } from "../../common/BreadCrumb";
import { StringHelper } from "../../../common/Helpers";
import { RefundRequest } from "../../../models/RefundRequest";
import { AppealRefundModal } from "./AppealRefundModal";

import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { RefundStatus } from "../../../constants/RefundConstants";
import { ApplicationID, ObjectType } from "../../../common/Constants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { RowFormField } from "../../layout/FormLayout";
import { Transaction } from "../../../models/Transaction";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";
import { useTransactionChargedStatus } from "../../../hooks/useTransactionChargedStatus";

const Appeal = "appeal";
const Refund = "refund";
const PWSM = ApplicationID.getName(ApplicationID.PWSM);

export const RefundDetails = () => {
  const [instance, setInstance] = useState(new RefundRequest({}));
  const [information, setInformation] = useState({});
  const [transaction, setTransaction] = useState(new Transaction({}));
  const [isShowAppealModal, setIsShowAppealModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const { id } = useParams();

  const { viewChargedStatus } = useTransactionChargedStatus();

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
    setIsLoading(false);
  };

  const loadOrderDetail = useCallback((transactionId) => {
    RestClient.sendGetRequest(
      `/api/v1/transactions/${transactionId}/information`,
      (response) => {
        response.movieInfos.forEach((x) => {
          x.id = Strings.generateRandomString();
        });
        setInformation(response);
      },
      handleError
    );
  }, []);

  const loadTransaction = useCallback((id) => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/transactions/${id}`,
      {
        includes: "PaymentCredits",
      },
      (data) => {
        setTransaction(new Transaction(data));
      },
      handleError
    );
  }, []);

  useEffect(() => {
    RestClient.sendGetRequest(
      `/api/v1/refunds/${id}`,
      (response) => {
        setInstance(new RefundRequest(response));
        loadOrderDetail(response.transactionId);
        loadTransaction(response.transactionId);
      },
      handleError
    );
  }, [id, loadOrderDetail, loadTransaction]);

  const getStatusStyle = (status) => {
    const map = {
      pending: "color-orange",
      rejected: "color-red",
      issued: "color-orange",
      voided: "color-orange",
    };
    const statusName = RefundStatus.getName(status);
    if (!statusName) {
      return "color-gray";
    }
    const normalizedStatus = statusName.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "transactions",
        name: "Transaction Management",
      }),
      activeModule: new NavigationItem({
        identifier: "refund-details",
        name: "Refund Details",
      }),
    });
  };

  const onHistoryClick = () => setShowHistoryDialog(true);

  const onShowAppealModalClose = () => setIsShowAppealModal(false);

  const onHistoryDialogClose = () => setShowHistoryDialog(false);

  const onAppealRefund = (reason) => {
    const appealActions = instance.actions.filter((x) => x.includes(Appeal));
    if (appealActions.length === 0) {
      return;
    }
    postRequest(appealActions[0], Appeal, reason);
  };

  const doRefund = async (action, actionName) => {
    await viewChargedStatus(instance.transactionId).then(() =>
      postRequest(action, actionName)
    );
  };

  const generateActions = () => {
    const results = [];
    const actions = instance.actions ?? [];
    actions.forEach((action, index) => {
      const lastIndexOfSlash = action.lastIndexOf("/");
      const actionName = action
        .substring(lastIndexOfSlash + 1, action.length)
        .replaceAll("_", " ");

      const isRefundAction = isEqual(actionName, Refund);
      if (isRefundAction) {
        if (!AuthenticationManager.isAuthorized(ResourceID.RefundButton))
          return;
      } else {
        if (
          !AuthenticationManager.isAuthorized(
            ResourceID.RefundApproveRejectButton
          )
        )
          return;
      }

      results.push({
        actionName,
        component: (
          <ButtonActionDetailGroup.Item
            className={getCssClassMapping(actionName)}
            style={{ textTransform: "capitalize" }}
            key={index}
            onClick={() => {
              if (isRefundAction) {
                doRefund(action, actionName);
              } else {
                postRequest(action, actionName);
              }
            }}
            disabled={isLoading}
          >
            {actionName}
          </ButtonActionDetailGroup.Item>
        ),
      });
    });
    return results;
  };

  const getCssClassMapping = (actionName) => {
    const map = {
      approve: "btn btn-success float-right",
      reject: "btn btn-danger",
    };
    const defaultCss = "btn btn-primary";
    if (Strings.isNullOrEmpty(actionName)) {
      return defaultCss;
    }
    const value = map[actionName];
    return value ? value : defaultCss;
  };

  const getMessageMapping = (actionName) => {
    const map = {
      approve: "The refund request has been approved successfully.",
      reject: "The refund request has been rejected successfully.",
      refund: "The refund request has been refunded successfully.",
      appeal: "The refund request has been appealed successfully.",
      acknowledge: "The refund request has been acknowledged successfully.",
    };
    const defaultMessage = "Success";
    if (Strings.isNullOrEmpty(actionName)) {
      return defaultMessage;
    }
    const value = map[actionName];
    return value ? value : defaultMessage;
  };

  const postRequest = async (path, actionName, reason = null) => {
    const request = {
      processedBy: AuthenticationManager.username(),
    };
    if (actionName === "appeal") {
      request.appealReason = reason;
    }
    setIsLoading(true);
    await RestClient.sendPostRequest(
      `/${path}`,
      request,
      (response) => {
        toast.success(getMessageMapping(actionName));
        setIsShowAppealModal(false);
        setInstance(new RefundRequest(response));
        setIsLoading(false);
      },
      handleError
    );
  };

  const getCreditCardNumber = (transaction) => {
    if (
      !transaction ||
      !transaction.paymentCredits ||
      transaction.paymentCredits.length === 0
    )
      return "";
    const paymentCredit = transaction.paymentCredits[0];
    return (paymentCredit.creditCardMaskedNumber ?? "").replaceAll("X", "*");
  };

  const allActions = generateActions();
  const appealButton = allActions.find((x) => x.actionName === Appeal);
  const exceptAppealButtons = allActions
    .filter((x) => x.actionName !== Appeal)
    .map((x) => x.component);

  const labelReason =
    instance.status !== RefundStatus.Pending
      ? " Reason for cancellation:"
      : " Remarks:";

  const isPWSMSaleChannel = transaction?.salesChannel === PWSM;
  const showApproveDatetime = instance.status === RefundStatus.Approved;
  const showRefundedDatetime =
    RefundStatus.RefundedStatuses.indexOf(instance.status) >= 0;

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <ButtonActionDetailGroup>
            {appealButton && appealButton.component}
            <ButtonActionDetailGroup.Item
              className="btn btn-outline-secondary"
              id="History"
              onClick={onHistoryClick}
            >
              History
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
          <div className="card mb-3">
            <div className="card-body">
              <RowFormField label="Transaction Number:" htmlFor="TransactionNo">
                <input
                  className="form-control display-input"
                  id="TransactionNo"
                  value={instance?.transactionReferenceNumber ?? ""}
                  readOnly
                />
              </RowFormField>
              <RowFormField label={labelReason} htmlFor="Reason">
                <input
                  className="form-control display-input"
                  id="Reason"
                  value={instance.reason ?? ""}
                  readOnly
                />
              </RowFormField>
              <RowFormField label=" Transaction Mode:" htmlFor="Mode">
                <input
                  type="text"
                  className="form-control display-input"
                  id="Mode"
                  value={ApplicationID.getName(instance?.appId) ?? ""}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Transaction Amount:" htmlFor="TransAmount">
                <input
                  type="text"
                  className="form-control display-input"
                  id="TransAmount"
                  value={"$" + (transaction?.processAmount ?? 0).toFixed(2)}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Refund Amount:" htmlFor="RefAmount">
                <input
                  type="text"
                  className="form-control display-input"
                  id="RefAmount"
                  value={"$" + (instance.amount ?? 0).toFixed(2)}
                  readOnly
                />
              </RowFormField>
              {isPWSMSaleChannel && (
                <RowFormField label="Patron Name:" htmlFor="PatronName">
                  <input
                    className="form-control display-input"
                    id="PatronName"
                    value={transaction?.customerName ?? ""}
                    readOnly
                  />
                </RowFormField>
              )}
              <RowFormField label="Credit Card Number:" htmlFor="CCNumber">
                <input
                  className="form-control display-input"
                  id="CCNumber"
                  value={getCreditCardNumber(transaction)}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Status:" htmlFor="Status">
                <input
                  className={
                    "form-control display-input " +
                    getStatusStyle(instance.status)
                  }
                  id="Status"
                  value={RefundStatus.getName(instance.status)}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Transaction DateTime:" htmlFor="TransDT">
                <input
                  className="form-control display-input"
                  type="datetime"
                  id="TransDT"
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    transaction?.createdOn ?? ""
                  )}
                  readOnly
                />
              </RowFormField>
              <RowFormField
                label="Refund Requested DateTime:"
                htmlFor="RejectDT"
              >
                <input
                  className="form-control display-input"
                  type="datetime"
                  id="RefundDT"
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    instance.createdOn
                  )}
                  readOnly
                />
              </RowFormField>
              {showApproveDatetime && (
                <RowFormField label="Approved DateTime:" htmlFor="ApprovedDT">
                  <input
                    className="form-control display-input"
                    type="datetime"
                    id="ApprovedDT"
                    value={StringHelper.asDateStringToDisplayDateTimeFormat(
                      instance.approvedOn
                    )}
                    readOnly
                  />
                </RowFormField>
              )}
              {showRefundedDatetime && (
                <RowFormField
                  label="Refunded/Refund DateTime:"
                  htmlFor="RefundDT"
                >
                  <input
                    className="form-control display-input"
                    type="datetime"
                    id="RefundDT"
                    value={StringHelper.asDateStringToDisplayDateTimeFormat(
                      instance.refundedOn
                    )}
                    readOnly
                  />
                </RowFormField>
              )}
              {isPWSMSaleChannel && (
                <RowFormField label="Mobile number:" htmlFor="Reason">
                  <input
                    className="form-control display-input"
                    id="Reason"
                    value={transaction?.contactNumber ?? ""}
                    readOnly
                  />
                </RowFormField>
              )}
              <RowFormField label="Request By:" htmlFor="Reason">
                <input
                  className="form-control display-input"
                  id="Reason"
                  value={instance.createdBy ?? ""}
                  readOnly
                />
              </RowFormField>
            </div>
          </div>
          <TransactionInformation information={information} />
          <div className="d-flex flex-row justify-content-end pb-3">
            {exceptAppealButtons}
          </div>
        </div>
      </div>
      <TicketView transaction={transaction} information={information} />
      {showHistoryDialog && (
        <HistoryDialog
          objectId={instance.id}
          onClose={onHistoryDialogClose}
          url="/api/v1/history_logs"
          objectType={ObjectType.RefundRequest}
        />
      )}
      {isShowAppealModal && (
        <AppealRefundModal
          onProceed={onAppealRefund}
          onClose={onShowAppealModalClose}
        />
      )}
    </div>
  );
};
