import React, { useEffect, useState } from "react";
import { FieldWrapper } from "../FieldWrapper";
import { TextInput } from "../TextInput";
import { TicketPrint } from "../../../models/TicketPrint";
import { Card } from "../../common/Card";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import {
  CampaignActivePanel,
  campaignActivePanelAtom,
  campaignAtom,
} from "../CampaignState";
import { useRecoilState, useRecoilValue } from "recoil";
import _ from "lodash";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const CustomizeTicketCard = () => {
  const campaign = useRecoilValue(campaignAtom);
  const [ticket, setTicket] = useState(new TicketPrint({}));
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isCustomizeTicket, setIsCustomizeTicket] = useState(
    campaign.hasCustomTicket || false
  );
  const [activePanel, setActivePanel] = useRecoilState(campaignActivePanelAtom);

  const onInputTicketChange = (e) => {
    const editableInstance = _.cloneDeep(ticket);
    const fieldName = e.target.getAttribute("name");
    editableInstance[fieldName] = e.target.value;
    setTicket(editableInstance);
  };

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  useEffect(() => {
    setIsCustomizeTicket(campaign.hasCustomTicket);
    if (!campaign.hasCustomTicket) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "/api/v1/campaign_customize_ticket",
      { campaignId: campaign.id },
      (response) => {
        if (response.data.length > 0) {
          setTicket(new TicketPrint(response.data[0]));
        }
      },
      (error) => toast.error(error.message)
    );
  }, [campaign]);

  const onCreateCustomizeTicket = () => {
    const body = {
      ...ticket,
      campaignId: campaign.id,
      hasCustomTicket: isCustomizeTicket
    };
    RestClient.sendPostRequest(
      "/api/v1/campaign_customize_ticket",
      body,
      (response) => {
        toast.success("The customized ticket has been created successful.");
        setActivePanel("");
        setTicket(new TicketPrint(response));
      },
      handleError
    );
  };

  const onUpdateCustomizeTicket = (customTicketId) => {
    const body = {
      ...ticket,
      hasCustomTicket: isCustomizeTicket
    };
    RestClient.sendPutRequest(
      `/api/v1/campaign_customize_ticket/${customTicketId}`,
      body,
      () => {
        toast.success("The customized ticket has been updated successful.");
        setActivePanel("");
      },
      handleError
    );
  };

  const onSave = () => {
    if (ticket.id > 0) {
      onUpdateCustomizeTicket(ticket.id);
    } else {
      onCreateCustomizeTicket();
    }
  };

  const onCancel = () => {
    setActivePanel("");
  };

  const onDelete = () => {
    RestClient.sendDeleteRequest(
      `/api/v1/campaign_customize_ticket/${ticket.id}`,
      () => {
        toast.success("The customized ticket has been deleted successful.");
        setActivePanel("");
        setTicket(new TicketPrint({}));
        setShowDeleteConfirmation(false);
      },
      handleError
    );
  };

  const onCheckNo = () => {
    setIsCustomizeTicket(false);
    if (!ticket || ticket.id === 0) {
      return;
    }
    setShowDeleteConfirmation(true);
  };

  const isEdit = activePanel === CampaignActivePanel.CustomizeTicket;

  return (
    <Card
      title="Customize Ticket"
      isActive={activePanel === CampaignActivePanel.CustomizeTicket}
    >
      {activePanel !== CampaignActivePanel.CustomizeTicket &&
        AuthenticationManager.isAuthorized(
          ResourceID.CampaignsViewPageEditFeatures
        ) && (
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() =>
                setActivePanel(CampaignActivePanel.CustomizeTicket)
              }
              disabled={!_.isEmpty(activePanel)}
              title={
                !_.isEmpty(activePanel) &&
                activePanel !== CampaignActivePanel.CustomizeTicket
                  ? `The panel ${activePanel} is being edit. Please complete the current editing panel first.`
                  : ""
              }
            >
              <i className="fas fa-edit mr-1" aria-hidden="true" />
              Edit
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        )}
      <div className="row form-group">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-4">
              <label className="form-check-label">Customize Ticket</label>
            </div>
            <div className="col-8">
              <div className="row form-group">
                <div className="col-md-2">
                  <div className="">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      id="custom"
                      disabled={!isEdit}
                      checked={isCustomizeTicket}
                      onChange={() => setIsCustomizeTicket(true)}
                    />
                    <label className="form-check-label" htmlFor="custom">
                      Yes
                    </label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      id="default"
                      disabled={!isEdit}
                      checked={!isCustomizeTicket}
                      onChange={onCheckNo}
                    />
                    <label className="form-check-label" htmlFor="default">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-2">
            <FieldWrapper
              label="Disclaimer"
              input={
                <TextInput
                  name="disclaimer"
                  value={ticket.disclaimer}
                  disable={!isCustomizeTicket || !isEdit}
                  onChange={onInputTicketChange}
                />
              }
            />
          </div>
          <div className="my-2">
            <FieldWrapper
              label="Movie Title"
              input={
                <TextInput
                  name="movieTitle"
                  placeholder="Enter Movie Title"
                  value={ticket.movieTitle}
                  disable={!isCustomizeTicket || !isEdit}
                  onChange={onInputTicketChange}
                />
              }
            />
          </div>
          <div className="my-2">
            <FieldWrapper
              label="Movie Rating"
              input={
                <TextInput
                  name="movieRating"
                  placeholder="Enter Movie Rating"
                  value={ticket.movieRating}
                  disable={!isCustomizeTicket || !isEdit}
                  onChange={onInputTicketChange}
                />
              }
            />
          </div>
          <div className="my-2">
            <FieldWrapper
              label="Ticket Details"
              input={
                <TextInput
                  name="ticketDetails"
                  placeholder="Enter Ticket Details"
                  value={ticket.ticketDetails}
                  disable={!isCustomizeTicket || !isEdit}
                  onChange={onInputTicketChange}
                />
              }
            />
          </div>
          <div className="my-2">
            <FieldWrapper
              label="Ticket Type"
              input={
                <TextInput
                  name="ticketType"
                  placeholder="Enter Ticket Types"
                  value={ticket.ticketType}
                  disable={!isCustomizeTicket || !isEdit}
                  onChange={onInputTicketChange}
                />
              }
            />
          </div>
        </div>
        <div className="col-xl-6 overflow-x mx-1 mx-xl-0">
          <div className="card preview-slipbg coupon-preview mb-2">
            <div className="card-body pb-0">
              <h4 className="card-title">
                <span id="movietitle">{ticket.movieTitle}</span>
                <span id="ratingText">
                  (<span id="movieRating">{ticket.movieRating}</span>)
                </span>
              </h4>
              <div className="row">
                <p id="Disclaimer" className="pl-3">
                  {ticket.disclaimer}
                </p>
              </div>
              <div className="row">
                <div className="col-6 pr-0">
                  <small className="float-left preview-rotatetext mt-2">
                    DATE
                  </small>
                  <h2 className="mb-0" id="ticketdatebig">
                    {" "}
                    19-06-2019
                  </h2>
                </div>
                <div className="col-4 pr-0 pl-0">
                  <small className="float-left preview-rotatetext mt-2">
                    TIME
                  </small>
                  <h2 className="mb-0" id="tickettimebig">
                    4:00PM
                  </h2>
                </div>

                <div className="col-2 pr-0 pl-0">
                  <small className="mb-0 float-left preview-rotatetext mt-2">
                    SEAT
                  </small>
                  <h2 className="mb-0">07</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-4 pl-0">
                  <img className="w-100" src="../img/testqrcode.png" alt="" />
                </div>
                <div className="col-8">
                  <div className="row pt-3">
                    <div className="col-10 pl-0">
                      <h4>
                        <span id="ticketlocation">JEWEL</span> <span>HALL</span>
                      </h4>
                    </div>
                    <div className="col-1">
                      <h1 id="tickethall">3</h1>
                    </div>
                  </div>
                  <div className="row mt-3">cash</div>
                  <div className="row">
                    <small id="ticketdetails">{ticket.ticketDetails}</small>
                  </div>

                  <div className="row">
                    <small id="ticketType">{ticket.ticketType}</small>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <small>SHAW THEATRES PTE LTD</small>
                </div>
                <div className="col-6">
                  <small className="float-right">Co. Reg. No: 194700158G</small>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <small>
                    <span id="ticketdate">19/06/2019</span>{" "}
                    <span id="tickettime">12:03 PM</span>
                  </small>
                </div>
                <div className="col-6">
                  <small className="float-right">testlido.cso</small>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <small>S2E89B0000E29</small>
                </div>
                <div className="col-6">
                  <small className="float-right">E001-0100-85-003595</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEdit && (
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item className="btn btn-success" onClick={onSave}>
            Save Changes
          </ButtonActionDetailGroup.Item>
          <ButtonActionDetailGroup.Item className="btn btn-danger" onClick={onCancel}>
            Cancel
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        message="Are you sure to delete the customize ticket?"
        onProceed={onDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
      />
    </Card>
  );
};
