import React from "react";
import Validators from "../common/Validators";
import _ from "lodash";
import { RowFormField } from "../layout/FormLayout";

export class ConfirmScheduleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      planToPublish: "",
      validations: [],
    };
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  onProceedClick = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    const { planToPublish } = this.state;
    const e = Object.assign(
      {},
      { planToPublish: planToPublish === "" ? null : planToPublish }
    );
    if (this.props.onProceed) {
      this.props.onProceed(e);
    }
  };

  onPublishTimeChange = (e) => {
    this.setState({ planToPublish: e.target.value });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  render() {
    let { message, title } = this.props;
    message = message || "Are you sure to perform this action?";
    title = title || "Confirmation";
    const { isSubmit, planToPublish } = this.state;
    const disableConfirmBtn =
      _.isEmpty(planToPublish) || new Date(planToPublish) <= new Date();
    return (
      <React.Fragment>
        <div
          className="modal fade show"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <RowFormField label="Publish On:">
                    <input
                      className="form-control"
                      type="datetime-local"
                      onChange={this.onPublishTimeChange}
                      value={planToPublish}
                    />
                    <div className="offset-md-2 col-md-10">
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldName="planToPublish"
                        isEnabled={isSubmit}
                        property="Publish On"
                        value={planToPublish}
                      />
                    </div>
                  </RowFormField>
                  {message && <div>{message}</div>}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.onProceedClick}
                  disabled={disableConfirmBtn}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </React.Fragment>
    );
  }
}
