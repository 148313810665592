import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { BreadCrumb } from "../../common/BreadCrumb";
import { AllowedTiming, ViewMode } from "../../../constants/PricingConstants";
import { SpecialPriceService } from "../../../services/SpecialPriceService";
import { SpecialPrice } from "../../../models/SpecialPrice";
import { ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import { LocationBrand } from "../../../constants/MovieConstants";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { DateTimeInput } from "../../campaigns/DateTimeInput";
import { useServices } from "../../../hooks/useServices";
import { CustomSelect } from "../../common/CustomSelect";
import { MovieFormat } from "../../../constants/MovieConstants";
import { CheckboxInput } from "../../campaigns/CheckboxInput";
import { useLocationVenues } from "../../../hooks/useLocationVenues";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const SpecialPriceDetail = ({ match: { params } }) => {
  const { id } = params;
  const [viewMode, setViewMode] = useState(ViewMode.Readonly);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [specialPrice, setSpecialPrice] = useState(new SpecialPrice({}));
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const specialPriceService = useMemo(() => new SpecialPriceService(), []);
  const history = useHistory();
  const { services } = useServices();
  const { locationVenues } = useLocationVenues();

  useEffect(() => {
    getSpecialPrice();
  }, [id, specialPriceService, viewMode]);

  const getSpecialPrice = () => {
    specialPriceService.get(id).then((response) => {
      setSpecialPrice(new SpecialPrice(response.data));
    });
  };

  const onDelete = () => {
    specialPriceService
      .delete(id)
      .then(() => {
        history.push("/special_prices");
        toast.success("The special price has been deleted successful.");
      })
      .catch((error) => {
        toast.error(error.message);
      });
    setShowDeleteModal(false);
  };

  const onProcessUpdateSpecialPrice = () => {
    setShowUpdateConfirmation(false);
    specialPriceService
      .put(specialPrice.id, specialPrice)
      .then(() => {
        getSpecialPrice();
        toast.success("Update special price information success");
        setViewMode(ViewMode.Readonly);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const onSelectChange = (e, name) => {
    const value = e.value;
    setSpecialPrice((data) => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  const onCancelUpdateSpecialPrice = () => {
    setShowUpdateConfirmation(false);
    getSpecialPrice();
  };

  const onProcessCancelConfirmation = () => {
    setViewMode(ViewMode.Readonly);
    setShowCancelConfirmation(false);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setSpecialPrice((specialPrice) => {
      return {
        ...specialPrice,
        [fieldName]: selectValue,
      };
    });
  };

  const onEdit = () => {
    setViewMode(ViewMode.Editable);
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "special-price-detail",
        name:
          viewMode === ViewMode.Readonly
            ? "View Special Price"
            : "Edit Special Price",
      }),
    });
  };

  if (!specialPrice) return <></>;
  const movieReleaseIdentifier = `${specialPrice?.movieRelease?.primaryTitle}-${
    specialPrice.movieRelease?.id
  }-${MovieFormat.getName(specialPrice.movieRelease?.format)}`;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="container-fluid">
              {viewMode === ViewMode.Readonly && (
                <ButtonActionDetailGroup>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.SpecialPricingEditButtonAndPage
                  ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success"
                      onClick={onEdit}
                    >
                      <i className="fas fa-edit" aria-hidden="true" /> Edit
                    </ButtonActionDetailGroup.Item>
                  )}
                  {AuthenticationManager.isAuthorized(
                    ResourceID.SpecialPricingDeleteButton
                  ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      onClick={() => setShowDeleteModal(true)}
                    >
                      Delete
                    </ButtonActionDetailGroup.Item>
                  )}
                  <ButtonActionDetailGroup.Item
                    className="btn btn-outline-secondary"
                    data-target="#HistoryPopup"
                    data-toggle="modal"
                    onClick={() => setShowHistoryDialog(true)}
                  >
                    History
                  </ButtonActionDetailGroup.Item>
                </ButtonActionDetailGroup>
              )}
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Special Price Details
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row form-group">
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Movie Release"
                            require={true}
                            input={
                              <input
                                className="form-control display-input"
                                value={movieReleaseIdentifier}
                                id="moviereleaseId"
                                name="moviereleaseId"
                                disabled={true}
                                onChange={onInputChange}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group my-0">
                            <div className="col-md-4">
                              <label className="form-control-label">
                                <i>
                                  Services
                                  <span className="color-red font-weight-bold">
                                    *
                                  </span>
                                  :
                                </i>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CustomSelect
                                data={services}
                                label="name"
                                value="id"
                                onChange={(e) => onSelectChange(e, "serviceId")}
                                disabled={viewMode === ViewMode.Readonly}
                                selectedValue={specialPrice.serviceId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6 mb-3 mb-md-0">
                          <FieldWrapper
                            label="Is Active"
                            input={
                              <CheckboxInput
                                name="isActive"
                                value={specialPrice.isActive}
                                checked={specialPrice.isActive}
                                onChange={onInputChange}
                                disabled={viewMode === ViewMode.Readonly}
                                wrapperClass="px-0"
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Location Venue"
                            input={
                              <CustomSelect
                                data={locationVenues}
                                label="name"
                                value="id"
                                onChange={(e) =>
                                  onSelectChange(e, "locationVenueId")
                                }
                                selectedValue={specialPrice.locationVenueId}
                                disabled={viewMode === ViewMode.Readonly}
                                allowEmpty={true}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Start On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="startOn"
                                value={specialPrice.startOn}
                                onChange={onInputChange}
                                placeholder="Enter Start On"
                                readOnly={viewMode === ViewMode.Readonly}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="End On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="endOn"
                                value={specialPrice.endOn}
                                onChange={onInputChange}
                                placeholder="Enter End On"
                                readOnly={viewMode === ViewMode.Readonly}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Allowed Location Brands"
                            input={
                              <CustomSelect
                                data={LocationBrand.All}
                                label="name"
                                value="value"
                                onChange={(e) =>
                                  onSelectChange(e, "allowedLocationBrands")
                                }
                                disabled={viewMode === ViewMode.Readonly}
                                selectedValue={
                                  specialPrice.allowedLocationBrands
                                }
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Allowed Timing"
                            input={
                              <CustomSelect
                                data={AllowedTiming.All}
                                label="name"
                                value="value"
                                name="allowedTiming"
                                onChange={(e) =>
                                  onSelectChange(e, "allowedTiming")
                                }
                                disabled={viewMode === ViewMode.Readonly}
                                selectedValue={specialPrice.allowedTiming}
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewMode === ViewMode.Editable && (
                <ButtonActionDetailGroup>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    onClick={() => setShowUpdateConfirmation(true)}
                  >
                    Update
                  </ButtonActionDetailGroup.Item>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    onClick={() => setShowCancelConfirmation(true)}
                  >
                    Cancel
                  </ButtonActionDetailGroup.Item>
                </ButtonActionDetailGroup>
              )}
            </div>
          </div>
        </div>
      </div>
      {showHistoryDialog && (
        <HistoryDialog
          objectId={specialPrice.id}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.SpecialPrice}
        />
      )}
      <ConfirmDialog
        visible={showDeleteModal}
        message="Are you sure you want to delete the special price?"
        onProceed={onDelete}
        onCancel={() => setShowDeleteModal(false)}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        onCancel={() => setShowCancelConfirmation(false)}
        onProceed={() => onProcessCancelConfirmation()}
        title="Cancel confirmation"
      />
      <ConfirmDialog
        visible={showUpdateConfirmation}
        onCancel={() => onCancelUpdateSpecialPrice()}
        onProceed={() => onProcessUpdateSpecialPrice()}
        title="Update confirmation"
        message="Are you sure want to update special price information?"
      />
    </>
  );
};
