import { BreadCrumb } from "../../common/BreadCrumb";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PerformanceSelectorService } from "../../../services/PerformanceSelectorService";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ColumnFormField } from "../../layout/FormLayout";
import { PerformanceSelectorsType } from "../../../constants/PerformanceSelectorsConstants";
import { DateHelper } from "../../../common/Helpers";
import { LocationService } from "../../../services/LocationService";
import { TicketingApplication } from "../../../constants/PerformanceConstants";
import { Card } from "../../common/Card";
import PerformanceSelectorsRestrictionModal from "./PerformanceSelectorsRestrictionModal";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";

const getNavigationSettings = () => {
  return new NavigationSettings({
    parentModule: new NavigationItem({
      identifier: "movies",
      name: "Movies",
    }),
    activeModule: new NavigationItem({
      identifier: "performance-selectors",
      name: "Performance Selectors",
    }),
  });
};

const PerformanceSelectorRestriction = (props) => {
  const { locations, restriction } = props;

  const location = locations.find((x) => x.id === restriction.locationId);
  const applicationName = TicketingApplication.getPossibleValue(
    restriction.allowedTicketingApplications
  )
    .map((x) => x.name)
    .join(", ");
  return (
    <div className="row mb-2">
      <div className="col-10 col-md-11 row">
        <ColumnFormField label="Location: ">
          <input
            className="form-control form-readonly"
            value={location?.name ?? "All"}
            readOnly
          />
        </ColumnFormField>
        <ColumnFormField label="Application:">
          <input
            className="form-control form-readonly"
            value={applicationName}
            readOnly
          />
        </ColumnFormField>
      </div>
      <div className="col-2 col-md-1 d-flex justify-content-end">
        <button
          className="btn btn-primary mr-1"
          onClick={() =>
            props.onUpdate({
              allowedTicketingApplications:
                TicketingApplication.getPossibleValue(
                  restriction.allowedTicketingApplications
                ).map((x) => x.value),
              stationLocationId: restriction.locationId,
            })
          }
        >
          <i className="fas fa-edit" />
        </button>
        <button
          className="btn btn-danger"
          onClick={() => props.onDelete(restriction)}
        >
          <i className="far fa-trash-alt"></i>
        </button>
      </div>
    </div>
  );
};

const defaultUpdateRestriction = {
  data: {},
  show: false,
};

const defaultClearRestriction = {
  data: {},
  show: false,
};

const sortRestrictions = (restrictions) =>
  restrictions?.sort((a, b) => a.id - b.id);

const PerformanceSelectorDetail = () => {
  const params = useParams();
  const [detail, setDetail] = useState({});
  const [locations, setLocations] = useState([]);
  const [updateRestriction, setUpdateRestriction] = useState(
    defaultUpdateRestriction
  );
  const [clearRestriction, setClearRestriction] = useState(
    defaultClearRestriction
  );

  const id = +params["id"];

  useEffect(() => {
    new LocationService().list().then((x) => setLocations(x.data.data));
  }, []);

  useEffect(() => {
    if (id && id > 0) {
      new PerformanceSelectorService()
        .get(id, {
          Includes: "PerformanceSelectorRestrictions",
        })
        .then((response) => {
          sortRestrictions(response.data.performanceSelectorRestrictions);
          setDetail(response.data);
        });
    }
  }, [id]);

  const onUpdateRestrictionSuccess = (response) => {
    setDetail((prev) => {
      const { performanceSelectorRestrictions: prevRestrictions } = prev;
      const oldRestriction = prevRestrictions.filter(
        (x) => !response.some((newRestriction) => newRestriction.id === x.id)
      );

      const performanceSelectorRestrictions = [...oldRestriction, ...response];

      sortRestrictions(performanceSelectorRestrictions);

      return {
        ...prev,
        performanceSelectorRestrictions,
      };
    });
    setUpdateRestriction(defaultUpdateRestriction);
  };

  const onDelete = () => {
    new PerformanceSelectorService()
      .clearRestrictions([clearRestriction.data.id])
      .then((res) => {
        toast.success(res.message);
        setDetail(({ performanceSelectorRestrictions, ...prev }) => ({
          ...prev,
          performanceSelectorRestrictions:
            performanceSelectorRestrictions.filter(
              (x) => x.id !== clearRestriction.data.id
            ),
        }));
        setClearRestriction(defaultClearRestriction);
      });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <Card title="General Information">
              <div className="row">
                <ColumnFormField label="Name:">
                  <span className="pl-2">{detail.name}</span>
                </ColumnFormField>
                <ColumnFormField label="Type:">
                  <span className="pl-2">
                    {PerformanceSelectorsType.getName(detail.type)}
                  </span>
                </ColumnFormField>
              </div>
              <div className="row">
                <ColumnFormField label="Start Date:">
                  <span className="pl-2">
                    {DateHelper.formatDateTimeString(detail.startDate)}
                  </span>
                </ColumnFormField>
                <ColumnFormField label="Display weight: ">
                  <span className="pl-2">{detail.displayWeight}</span>
                </ColumnFormField>
              </div>
              <div className="row">
                <ColumnFormField label="End Date:">
                  <span className="pl-2">
                    {DateHelper.formatDateTimeString(detail.endDate)}
                  </span>
                </ColumnFormField>
                <ColumnFormField label="Is Active:">
                  <span className="pl-2">{detail.isActive ? "Yes" : "No"}</span>
                </ColumnFormField>
              </div>
            </Card>

            {detail.performanceSelectorRestrictions && (
              <Card title="Restrictions">
                {detail.performanceSelectorRestrictions.map((x) => (
                  <PerformanceSelectorRestriction
                    locations={locations}
                    restriction={x}
                    key={x.id}
                    onUpdate={(data) => {
                      setUpdateRestriction({ data, show: true });
                    }}
                    onDelete={(data) => {
                      setClearRestriction({ data, show: true });
                    }}
                  />
                ))}
              </Card>
            )}
          </div>
        </div>
      </div>
      {updateRestriction.show && (
        <PerformanceSelectorsRestrictionModal
          locations={locations}
          selectedSelectorIds={[detail.id]}
          data={updateRestriction.data}
          onSuccess={onUpdateRestrictionSuccess}
        />
      )}
      <ConfirmDialog
        visible={clearRestriction.show}
        message="Are you sure you want to delete the restriction?"
        onProceed={onDelete}
      />
    </>
  );
};

export default PerformanceSelectorDetail;
