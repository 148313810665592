import React, { useEffect, useMemo, useState } from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  Classify,
  MediaType,
  MovieDisplayWeight,
  MovieFormat,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import _ from "lodash";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  GenericMessages,
  StorageModule,
  ObjectType,
} from "../../../common/Constants";
import { MovieRelease } from "../../../models/MovieRelease";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { BlobStorageRestService } from "../../../services/BlobStorageRestService";
import { DateHelper } from "../../../common/Helpers";
import { AddMediaModal } from "./AddMediaModal";
import { MovieReleaseSelectionModal } from "./MovieReleaseSelectionModal";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { toast } from "react-toastify";
import { isValidHttpUrl } from "../../../common/Common";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { Card } from "../../common/Card";
import { RowFormField } from "../../layout/FormLayout";
import { useParams } from "react-router-dom";

export const MovieReleaseMediaDetails = (props) => {
  const [instance, setInstance] = useState(new MovieRelease());
  const [mode, setMode] = useState(Mode.Readonly);
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] =
    useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showAddMediaModal, setShowAddMediaModal] = useState(false);
  const [showDuplicationModal, setShowDuplicationModal] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [showRemovePosterConfirmation, setShowRemovePosterConfirmation] =
    useState(false);
  const [movieReleases, setMovieReleases] = useState([]);
  const [activeMediaType, setActiveMediaType] = useState(MediaType.Poster);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const movieReleaseService = useMemo(() => new MovieReleaseService(), []);
  const params = useParams();
  const [sourceMovieReleaseId, setSourceMovieReleaseId] = useState(0);
  const [showDuplicateConfirmation, setShowDuplicateConfirmation] = useState(false);

  const id = params.id;
  const loadData = () => {
    movieReleaseService
      .get(id, {
        includes: "MovieReleaseMedias",
      })
      .then((response) => {
        setInstance(new MovieRelease(response.data));
      });
  };

  useEffect(() => {
    loadData();
  }, [id]);

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onModeChange = () => {
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    if (newMode === Mode.Editable) {
      movieReleaseService
        .list({ movieId: instance.movieId, includes: 'MovieReleaseMedias' })
        .then((response) => {
          setMovieReleases(
            response.data.data.filter((x) => x.id !== instance.id)
          );
        });
    }
    setMode(newMode);
  };

  const onAddMedia = (media) => {
    const existingMedia = instance.movieReleaseMedias.find(
      (x) => x.type === media.type && x.url === media.url
    );

    if (existingMedia) {
      toast.error(`The media url is duplicated. Please choose other.`);
      return;
    }

    if (!isValidHttpUrl(media.url)) {
      toast.error(
        "The media url is not a valid fully-qualified http, https, or ftp URL."
      );
      return;
    }
    if (
      media.type === MediaType.Photo &&
      media.displayWeight < MovieDisplayWeight.Length
    ) {
      toast.error(
        `The media weight must be greater than or equal ${MovieDisplayWeight.Length}`
      );
      return;
    }
    setInstance((prev) => {
      prev.movieReleaseMedias.push(media);
      return { ...prev };
    });
    closeAllModals();
  };
  
  const duplicate = (sourceMovieReleaseId) => {
    setSourceMovieReleaseId(sourceMovieReleaseId);
    if (instance.movieReleaseMedias.length) {
      setShowDuplicationModal(false);
      setShowDuplicateConfirmation(true);
      return;
    }
    onDuplicate();
  };
  const onDuplicate = () => {
    if (!sourceMovieReleaseId) return;
    movieReleaseService.duplicateMedias(
      sourceMovieReleaseId,
      instance.id,
      (_) => {
        loadData();
      },
      handleError
    );
    closeAllModals();
  };

  const onSubmit = () => {
    movieReleaseService.updateMedias(
      instance,
      (response) => {
        toast.success(`The movie release media has been updated successful.`);
        setInstance(new MovieRelease(response));
        setMode(Mode.Readonly);
      },
      handleError
    );
    closeAllModals();
  };

  const onCancel = () => {
    loadData();
    setMode(Mode.Readonly);
    closeAllModals();
  };

  const removeMediaAsset = async (media) => {
    await new BlobStorageRestService()
      .deleteFile(media.url, StorageModule.Movie)
      .then(() => {
        setInstance((prev) => {
          prev.movieReleaseMedias = prev.movieReleaseMedias.filter(
            (x) => x.id !== media.id
          );
          return { ...prev };
        });
      });
  };

  const onRemovePosterUrl = async () => {
    await removeMediaAsset(selectedMedia);
    setShowRemovePosterConfirmation(false);
  };

  const onRemoveMedia = (media) => {
    if (_.isNil(media)) {
      return;
    }
    setSelectedMedia(media);
    if (media.type === MediaType.Poster) {
      setShowRemovePosterConfirmation(true);
      return;
    }
    removeMediaAsset(media);
  };

  const renderRemoveButton = (media) => {
    if (mode === Mode.Editable) {
      return (
        <div className="col-md-12">
          <button
            className="btn btn-danger float-right"
            onClick={() => onRemoveMedia(media)}
          >
            <i className="fas fa-trash-alt" aria-hidden="true" />
          </button>
        </div>
      );
    }
    return null;
  };

  const renderAddButton = (mediaType) => {
    if (mode === Mode.Editable) {
      return (
        <div className="card-body">
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-success"
              data-toggle="modal"
              onClick={() => {
                setActiveMediaType(mediaType);
                setShowAddMediaModal(true);
              }}
              data-target="#PosterModal"
            >
              Add
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </div>
      );
    }
    return null;
  };

  const renderTopButtons = () => {
    if (mode === Mode.Editable) {
      return (
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            data-toggle="modal"
            onClick={() => setShowDuplicationModal(true)}
            data-target="#DuplicateModal"
          >
            Duplicate Media
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
      );
    }
    return (
      <ButtonActionDetailGroup>
        {AuthenticationManager.isAuthorized(
          ResourceID.MovieReleaseEditButtonPage
        ) && (
          <ButtonActionDetailGroup.Item
            className="btn btn-success"
            onClick={onModeChange}
          >
            <i className="fas fa-edit" aria-hidden="true" />
            Edit
          </ButtonActionDetailGroup.Item>
        )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          data-target="#HistoryPopup"
          data-toggle="modal"
          onClick={() => setShowHistoryDialog(true)}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const renderBottomButtons = () => {
    if (mode !== Mode.Editable) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#confirmModal"
          disabled={_.isNil(instance)}
          onClick={() => setShowSaveChangesConfirmation(true)}
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const closeAllModals = () => {
    setShowDuplicationModal(false);
    setShowAddMediaModal(false);
    setShowCancelConfirmation(false);
    setShowSaveChangesConfirmation(false);
    setShowDuplicateConfirmation(false);
  };

  const renderView = () => {
    const trailers = instance.movieReleaseMedias.filter(
      (x) => x.type === MediaType.Trailer
    );
    const posters = instance.movieReleaseMedias.filter(
      (x) => x.type === MediaType.Poster
    );
    const photos = instance.movieReleaseMedias.filter(
      (x) => x.type === MediaType.Photo
    );
    return (
      <>
        <div className="row">
          <div className="col">
            <Card title="Movie Media Information">
              <div className="row">
                <div className="col">
                  <div className="row form-group">
                    <div className="col-md-6">
                      <label className="form-control-label">
                        Primary Title:
                      </label>
                      <input
                        className="form-control display-input"
                        value={instance.primaryTitle}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="form-control-label">
                        Movie Format:
                      </label>
                      <input
                        className="form-control display-input"
                        value={MovieFormat.getName(instance.format)}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-6">
                      <label className="form-control-label">
                        Secondary Title:
                      </label>
                      <input
                        className="form-control display-input"
                        value={instance.secondaryTitle ?? ""}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-control-label">
                        Movie Rating:
                      </label>
                      <input
                        className="form-control display-input"
                        value={Classify.getName(instance.classify)}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <div className="card">
              <div className="card-header d-flex">
                <h4>Trailer/Teaser</h4>
              </div>
              {renderAddButton(MediaType.Trailer)}
              <div className="card-body">
                <div className="row" id="PhotoDiv">
                  {trailers.map((x) => {
                    return (
                      <div key={x.id} className="col-md-6 pb-3">
                        <div className="card mb-0">
                          <div className="card-body" key={x.id}>
                            <div className="card-img-top text-center mb-3">
                              <iframe
                                height="400"
                                style={{ width: "90%" }}
                                src={x.url}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""
                              />
                            </div>
                            <RowFormField label="Url:">
                              <label style={{ wordBreak: "break-all" }}>
                                {x.url}
                              </label>
                            </RowFormField>
                            <RowFormField label="Display Start:">
                              <label>
                                {DateHelper.formatDateTimeString(
                                  x.displayStart
                                )}
                              </label>
                            </RowFormField>
                            {renderRemoveButton(x)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header d-flex">
                <h4>Poster</h4>
              </div>
              {renderAddButton(MediaType.Poster)}
              <div className="card-body">
                <div className="row" id="PhotoDiv">
                  {posters.map((x) => {
                    return (
                      <div key={x.id} className="col-md-6 pb-3">
                        <div className="card mb-0">
                          <div className="card-body">
                            <div
                              className="card-img-top text-center mb-3"
                              style={{ paddingBottom: "12px" }}
                            >
                              <img src={x.url} />
                            </div>
                            <RowFormField label="Url:">
                              <label style={{ wordBreak: "break-all" }}>
                                {x.url}
                              </label>
                            </RowFormField>
                            <RowFormField label="Display Start:">
                              <label>
                                {DateHelper.formatDateTimeString(
                                  x.displayStart
                                )}
                              </label>
                            </RowFormField>
                            {renderRemoveButton(x)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header d-flex">
                <h4>Photo</h4>
              </div>
              {renderAddButton(MediaType.Photo)}
              <div className="card-body">
                <div className="row" id="PhotoDiv">
                  {photos.map((x, index) => {
                    return (
                      <div key={index} className="col-md-6 pb-3">
                        <div className="card mb-0">
                          <div className="card-body">
                            <div className="card-img-top mb-3">
                              <img src={x.url} />
                            </div>
                            <RowFormField label="Url:">
                              <label style={{ wordBreak: "break-all" }}>
                                {x.url}
                              </label>
                            </RowFormField>
                            <RowFormField label="Display Start:">
                              <label>
                                {DateHelper.formatDateTimeString(
                                  x.displayStart
                                )}
                              </label>
                            </RowFormField>
                            <RowFormField label="Display Weight:">
                              <label>{x.displayWeight}</label>
                            </RowFormField>
                            {renderRemoveButton(x)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-release-details",
        name:
          mode === Mode.Readonly
            ? "View Movie Release Media Details"
            : "Edit Movie Release Media Details",
      }),
    });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {renderTopButtons()}
            {renderView()}
            {renderBottomButtons()}
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showSaveChangesConfirmation}
        title={GenericMessages.SaveChangesDialogTitle}
        onProceed={onSubmit}
        onCancel={() => setShowSaveChangesConfirmation(false)}
        message={GenericMessages.SaveChangesDialogMessage}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        title={GenericMessages.CancelChangesDialogTitle}
        onProceed={onCancel}
        onCancel={() => setShowCancelConfirmation(false)}
        message={GenericMessages.CancelChangesDialogMessage}
      />

      <ConfirmDialog
        visible={showRemovePosterConfirmation}
        title={GenericMessages.DeleteMovieMediaPosterUrlTitle}
        onProceed={onRemovePosterUrl}
        onCancel={() => setShowRemovePosterConfirmation(false)}
        message={GenericMessages.DeleteMovieMediaPosterUrlMessage}
      />
      
      <ConfirmDialog
        visible={showDuplicateConfirmation}
        title={GenericMessages.DuplicateMovieMediaTitle}
        onProceed={onDuplicate}
        onCancel={() => setShowDuplicateConfirmation(false)}
        message={GenericMessages.DuplicateMovieMediaMessage}
      />

      {showAddMediaModal && (
        <AddMediaModal
          type={activeMediaType}
          onProceed={onAddMedia}
          onCancel={() => setShowAddMediaModal(false)}
          movieRelease={instance}
        />
      )}
      {showDuplicationModal && (
        <MovieReleaseSelectionModal
          title="Duplicate Media"
          onProceed={duplicate}
          movieReleases={movieReleases}
          onCancel={() => setShowDuplicationModal(false)}
        />
      )}
      {showHistoryDialog && (
        <HistoryDialog
          parentObjectId={instance.id}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          parentObjectType={ObjectType.MovieRelease}
        />
      )}
    </>
  );
};
