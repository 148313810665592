import React, { useEffect, useState } from "react";
import { log } from "../../../common/Common";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import {
  LocationBrandsEnum,
  VoucherProgramDurationType,
  VoucherProgramMedium,
  VoucherProgramType,
  VoucherType,
} from "../../../common/Constants";
import { MovieFormat } from "../../../constants/MovieConstants";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { VoucherProgramCategory } from "../../../constants/VoucherContants";

export const VoucherProgramDetail = ({ match: { params } }) => {
  const { id } = params;
  const [model, setModel] = useState({});

  useEffect(() => {
    RestClient.sendGetRequest(
      `api/v1/vouchers/programs/${id}`,
      (response) => {
        setModel(response);
      },
      (error) => {
        log(error);
      }
    );
  }, [id]);

  const boolToString = (boolValue) => (boolValue ? "Yes" : "No");

  const validityDuration = `${
    model?.validityDuration ?? 0
  } ${VoucherProgramDurationType.getName(model?.validityDurationType ?? 0)}`;

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-programs",
        name: "Voucher Program Details",
      }),
    });
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Card title="Program Information">
                <div className="row flex-column-reverse flex-md-row">
                  <div className="col-md-9">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="code" className="form-control-label">
                          Program Code:
                        </label>
                        <input
                          type="text"
                          id="code"
                          value={model?.code ?? ""}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="type" className="form-control-label">
                          Program Type:
                        </label>
                        <input
                          type="text"
                          id="type"
                          value={VoucherProgramType.getName(model.type)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="name" className="form-control-label">
                          Program Name:
                        </label>
                        <input
                          type="text"
                          id="name"
                          value={model?.name ?? ""}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="Category"
                          className="form-control-label"
                        >
                          Category:
                        </label>
                        <input
                          type="text"
                          id="Category"
                          value={VoucherProgramCategory.getName(model.category)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="Description"
                          className="form-control-label"
                        >
                          Program Description:
                        </label>
                        <label
                          id="Description"
                          className="form-control display-input"
                          readOnly
                        >
                          {model.description}
                        </label>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="medium" className="form-control-label">
                          Program Medium:
                        </label>
                        <input
                          type="text"
                          id="medium"
                          value={VoucherProgramMedium.getName(model.medium)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="TermCondition"
                          className="form-control-label"
                        >
                          Term Condition:
                        </label>
                        <label
                          id="TermCondition"
                          className="form-control display-input"
                          readOnly
                        >
                          {model.termCondition}
                        </label>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="CompanyCode"
                          className="form-control-label"
                        >
                          Program Partner Company Code:
                        </label>
                        <input
                          type="text"
                          id="CompanyCode"
                          value={model.partnerCompanyCode}
                          className="form-control display-input"
                          readOnly
                        />
                        <br />
                        <label
                          htmlFor="CompanyName"
                          className="form-control-label"
                        >
                          Program Partner Company Name:
                        </label>
                        <input
                          type="text"
                          id="CompanyName"
                          value={model.partnerCompanyName}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <img
                      id="image"
                      className="img-thumbnail"
                      src={model.voucherImage}
                      alt="Voucher"
                    />
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Card title="Program Mechanics">
                <div className="card">
                  <div className="card-body px-3">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="StartDate"
                          className="form-control-label"
                        >
                          Effective Start:
                        </label>
                        <input
                          type="datetime-local"
                          id="StartDate"
                          value={model?.effectiveStart ?? ""}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="EndDate" className="form-control-label">
                          Effective End:
                        </label>
                        <input
                          type="datetime-local"
                          id="EndDate"
                          value={model?.effectiveEnd ?? ""}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="SalesAmt"
                          className="form-control-label"
                        >
                          Sales Amount:
                        </label>
                        <input
                          type="text"
                          id="SalesAmt"
                          value={(model?.salesAmount ?? 0).toFixed(6)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="Quantity"
                          className="form-control-label"
                        >
                          Batch Quantity:
                        </label>
                        <input
                          type="text"
                          id="Quantity"
                          value={model?.batchQuantity ?? 0}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="RedemptionF&B"
                          className="form-control-label"
                        >
                          Redemption Amount (F&B):
                        </label>
                        <input
                          type="text"
                          id="RedemptionF&B"
                          value={model?.amountRedeemFoodBeverage ?? 0}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="SalesType"
                          className="form-control-label"
                        >
                          Sales Type:
                        </label>
                        <input
                          type="text"
                          id="SalesType"
                          value={model?.salesType ?? ""}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="redemptionmerchandise"
                          className="form-control-label"
                        >
                          Redemption Amount (Merchandise):
                        </label>
                        <input
                          type="text"
                          id="RedemptionMerchandise"
                          value={model?.amountRedeemMerchandise ?? 0}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="Validity"
                          className="form-control-label"
                        >
                          Validity Duration:
                        </label>
                        <div className="d-flex">
                          <input
                            type="text"
                            id="Validity"
                            value={validityDuration}
                            className="form-control display-input"
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="RedemptionTicket"
                          className="form-control-label"
                        >
                          Redemption Amount (Ticket):
                        </label>
                        <input
                          type="text"
                          id="RedemptionTicket"
                          value={model?.amountRedeemTicket ?? 0}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="MaxQty" className="form-control-label">
                          Voucher Usage Max Quantity Per Transaction:
                        </label>
                        <input
                          type="text"
                          id="MaxQty"
                          value={
                            model?.voucherUsageMaxQuantityPerTransaction ?? 0
                          }
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="CompanyName"
                          className="form-control-label"
                        >
                          Format:
                        </label>
                        <input
                          type="text"
                          id="CompanyName"
                          value={MovieFormat.getName(model?.movieFormat ?? "")}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="MaxQty" className="form-control-label">
                          Voucher Type:
                        </label>
                        <input
                          type="text"
                          id="MaxQty"
                          value={VoucherType.getVoucherType(
                            model?.voucherType ?? ""
                          )}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="CompanyCode"
                          className="form-control-label"
                        >
                          Brand:
                        </label>
                        <input
                          type="text"
                          id="CompanyCode"
                          value={LocationBrandsEnum.getLocationBrand(
                            model.locationBrand
                          )}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="TermCondition"
                          className="form-control-label"
                        >
                          Is Topup allowed:
                        </label>
                        <label
                          id="TermCondition"
                          className="form-control display-input"
                          readOnly
                        >
                          {boolToString(model.isTopUpAllowed)}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidMerchandise"
                          className="form-control-label"
                        >
                          Is valid for merchandise?:
                        </label>
                        <input
                          type="text"
                          id="ValidMerchandise"
                          value={boolToString(model.isValidForMerchandise)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body validity px-3">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidTicket"
                          className="form-control-label"
                        >
                          Is valid for ticket?:
                        </label>
                        <input
                          type="text"
                          id="ValidTicket"
                          value={boolToString(model.isValidForTicket)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidMidnight"
                          className="form-control-label"
                        >
                          Is valid for Midnight show?:
                        </label>
                        <input
                          type="text"
                          id="ValidMidnight"
                          value={boolToString(model.isValidForMidnightShow)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidOpening"
                          className="form-control-label"
                        >
                          Is valid for Opening show?:
                        </label>
                        <input
                          type="text"
                          id="ValidOpening"
                          value={boolToString(model.isValidForOpeningShow)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidHoliday"
                          className="form-control-label"
                        >
                          Is valid for Public Holiday?:
                        </label>
                        <input
                          type="text"
                          id="ValidHoliday"
                          value={boolToString(model.isValidForPublicHoliday)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidHolidayEve"
                          className="form-control-label"
                        >
                          Is valid for Public Holiday Eve?:
                        </label>
                        <input
                          type="text"
                          id="ValidHolidayEve"
                          value={boolToString(model.isValidForPublicHolidayEve)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidSubstitute"
                          className="form-control-label"
                        >
                          Is valid for Public Holiday Substitute?:
                        </label>
                        <input
                          type="text"
                          id="ValidSubstitute"
                          value={boolToString(
                            model.isValidForPublicHolidaySubstitute
                          )}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidBlackout"
                          className="form-control-label"
                        >
                          Is valid for blackout day?:
                        </label>
                        <input
                          type="text"
                          id="ValidBlackout"
                          value={boolToString(model.isValidForBlackoutDay)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="RefundVoucher"
                          className="form-control-label"
                        >
                          Is a refund voucher?:
                        </label>
                        <input
                          type="text"
                          id="RefundVoucher"
                          value={boolToString(model.IsValidForFoodBeverage)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="CashVoucherAmt"
                          className="form-control-label"
                        >
                          Cash Voucher Amount:
                        </label>
                        <input
                          type="text"
                          id="CashVoucherAmt"
                          value={model.cashVoucherAmount}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="CashVoucher"
                          className="form-control-label"
                        >
                          Is a cash voucher?:
                        </label>
                        <input
                          type="text"
                          id="CashVoucher"
                          value={boolToString(model.isCashVoucher)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="ValidF&B"
                          className="form-control-label"
                        >
                          Is valid for F&B?:
                        </label>
                        <input
                          type="text"
                          id="ValidF&B"
                          value={boolToString(model.isValidForFoodBeverage)}
                          className="form-control display-input"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body bg-light px-3">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <fieldset className="card card-body pt-0 mb-0">
                          <legend
                            className="col-auto"
                            style={{ fontSize: "16px" }}
                          >
                            Valid for a specific location or app?
                          </legend>
                          <ul
                            id="LocationOrApp"
                            style={{ marginLeft: "20px", color: "black" }}
                          >
                            {model.locations &&
                              model.locations.map((item) => (
                                <li key={item.id}>{item.name}</li>
                              ))}
                          </ul>
                        </fieldset>
                      </div>
                      <div className="col-md-6 mb-3">
                        <fieldset className="card card-body pt-0 mb-0">
                          <legend
                            className="col-auto"
                            style={{ fontSize: "16px" }}
                          >
                            Valid for a specific service code or ticket type
                            code?
                          </legend>
                          <ul
                            id="ServiceOrTicket"
                            style={{ marginLeft: "20px", color: "black" }}
                          >
                            {model.services &&
                              model.services.map((item) => (
                                <li key={item.id}>{item.name}</li>
                              ))}
                          </ul>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <fieldset className="card card-body pt-0 mb-0">
                          <legend
                            className="col-auto"
                            style={{ fontSize: "16px" }}
                          >
                            Valid for a specific Movie?
                          </legend>
                          <ul
                            id="ServiceOrTicket"
                            style={{ marginLeft: "20px", color: "black" }}
                          >
                            {model.movieReleases &&
                              model.movieReleases.map((item) => (
                                <li key={item.id}>{item.primaryTitle}</li>
                              ))}
                          </ul>
                        </fieldset>
                      </div>
                      <div className="col-md-6 mb-3">
                        <fieldset className="card card-body pt-0 mb-0">
                          <legend
                            className="col-auto"
                            style={{ fontSize: "16px" }}
                          >
                            Valid for a specific distributor?
                          </legend>
                          <ul
                            id="ServiceOrTicket"
                            style={{ marginLeft: "20px", color: "black" }}
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <fieldset className="card card-body pt-0 mb-0">
                          <legend
                            className="col-auto"
                            style={{ fontSize: "16px" }}
                          >
                            Valid for a specific brand and format?
                          </legend>
                          <ul
                            id="BrandAndFormat"
                            style={{ marginLeft: "20px", color: "black" }}
                          >
                            {model.brandAndFormats &&
                              model.brandAndFormats.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                          </ul>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
