export class TheatreCompanyType {
  static ALL = 0;
  static ShawTheatres = 2;
  static ShawApproach = 3;

  static All = [
    {
      code: "All",
      name: "All",
      value: this.ALL,
    },
    {
      code: "ST",
      name: "Shaw Theatres",
      value: this.ShawTheatres,
    },
    {
      code: "SA",
      name: "Shaw Approach",
      value: this.ShawApproach,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (!found) {
      return "";
    }
    return found.name;
  }
}
