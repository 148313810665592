import React, { Component } from "react";
import '../css/documentation.scss';

export class EditPromotionHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Promotion Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 7 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Promotions” link. This will take you to the Promotions Listing Page.  <br />
                                    <img src="/img/documentation-img/PromoImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Promotions Listing Page, click on any promotion listing of your choice. This will take you to the View Promotions Details.<br />
                                    <img src="/img/documentation-img/PromoImg/list_details.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To edit the promotion listing, click on the “Edit” button in the top right corner of the page.<br />
                                    <img src="/img/documentation-img/PromoImg/view_edit.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Edit Promotion Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Promotion Code
                                        </li>
                                        <li>
                                            Promotion Name
                                        </li>
                                        <li>
                                            Promotion Category
                                        </li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Promotion Image
                                        </li>
                                        <li>
                                            Promotion Description
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Promotion Description should be in HTML format.<br />
                                            - Promotion Description has a maximum of 12000 characters.
                                </div>
                            </li>
                                        <li class="createList">
                                            To preview the promotion, click on “Preview” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/edit_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/preview_cancelpreview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To save your changes, click on “Save Changes” at the bottom right corner of the page. <br />
                                            <img src="/img/documentation-img/PromoImg/edit_savechanges.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/PromoImg/edit_savechanges_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful save, a confirmation message will appear at the bottom right of the screen. <br />
                                            <img src="/img/documentation-img/PromoImg/save_success.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner. <br />
                                            <img src="/img/documentation-img/PromoImg/edit_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm". <br />
                                            <img src="/img/documentation-img/PromoImg/edit_cancel_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To schedule a promotion, click on the “Schedule” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/PromoImg/edit_schedule.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Select your date and time for publishing and click on “Confirm” to schedule the promotion for publishing.<br />
                                            <img src="/img/documentation-img/PromoImg/edit_schedule_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful scheduling, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/PromoImg/scheduled_success_toaster.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To view the scheduled listing, follow the steps listed below.
                                            <ol class="alphaList">
                                                <li>
                                                    Using the menu on the left, click on “Website Management”, then click on the “Promotions” link. This will take you to the Promotions Listing Page.<br />
                                                    <img src="/img/documentation-img/PromoImg/navbar.png" class="userImage" />
                                                </li>
                                                <li>
                                                    To view a scheduled promotion, click on “Scheduled Publishing” in the top right corner of the Promotions Listing Page.<br />
                                                    <img src="/img/documentation-img/PromoImg/list_scheduledpublishing.png" class="userImage" />
                                                </li>
                                                <li>
                                                    You will then be able to view your scheduled promotion.<br />
                                                    <img src="/img/documentation-img/PromoImg/scheduled_success_endpoint.png" class="userImage" />
                                                </li>
                                            </ol>
                                        </li>
                                        <li class="createList">
                                            To view history changes, click on the 'History' button, at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/view_history.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A popup will appear, stating the changes made. Click on “View Details” to see more.<br />
                                            <img src="/img/documentation-img/PromoImg/historymodal.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A pop-up will appear, stating the old and new values.<br />
                                            <img src="/img/documentation-img/PromoImg/history_details.png" class="userImage" />
                                        </li>
                            </ol>

                            <div className="text-right">
                                <a href="/documentation/promotions/CreatePromotionHelp" className="btn btn-primary">&laquo; Create Promotion</a>&nbsp;
                                <a href="/documentation/promotions/ViewScheduledPromotionHelp" className="btn btn-primary">View Scheduled Promotion &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}