import React from "react";

export const BaseTableCondition = (props) => {
  return (
    <div className="row form-group overflow-x mx-1">
      <div className="offset-2 col">
        <div className="card width-fit">
          <div
            className="card-body width-fit"
            style={{ backgroundColor: "#d6d8db" }}
          >
            <table className="table table-sm table-secondary table-borderless table-text-nowrap">
              {props.children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
