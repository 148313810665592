import {
  AuthenticationManager,
  ResourceID,
} from "../common/AuthenticationManager";

export class SidebarService {
  static ALL_MENUS = [
    { path: "/", label: "Dashboard", icon: "fas fa-tachometer-alt" },
    {
      path: "#",
      label: "Transactions",
      icon: "far fa-handshake",
      children: [
        {
          path: "/transactions",
          label: "Transactions",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.TransactionListingPage,
        },
        {
          path: "/transaction_enquiry",
          label: "Hall Transaction Enquiry",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.HallTransactionEnquiryPage,
        },
        {
          path: "/refunds",
          label: "Refunds",
          icon: "fas fa-comment-dollar",
          resourceId: ResourceID.RefundListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Corporate Bookings",
      icon: "fas fa-address-book",
      children: [
        {
          path: "/booking_requests",
          label: "Booking Requests",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.BookingRequestListingPage,
        },
        {
          path: "/corporate_bookings",
          label: "Corporate Bookings",
          icon: "fas fa-address-book",
          resourceId: ResourceID.CorporateBookingListingPage,
        },
        {
          path: "/corporate_clients",
          label: "Corporate Clients",
          icon: "fas fa-users",
          resourceId: ResourceID.CorporateClientListingPage,
        },
      ],
    },
    {
      path: "/users",
      label: "Users",
      icon: "fas fa-user-cog",
      resourceId: ResourceID.UserListingPage,
    },
    {
      path: "#",
      label: "Vouchers",
      icon: "fas fa-money-check-alt",
      children: [
        {
          path: "/vouchers",
          label: "Vouchers",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.VoucherListingPage,
        },
        {
          path: "/voucher_requests",
          label: "Voucher Requests",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.VoucherRequestListingPage,
        },
        {
          path: "/vouchers/register",
          label: "Voucher Registration",
          icon: "fas fa-edit",
          resourceId: ResourceID.VoucherRegistrationPage,
        },
        {
          path: "/vouchers/activate",
          label: "Voucher Activation",
          icon: "fas fa-edit",
          resourceId: ResourceID.VoucherActivationPage,
        },
        {
          path: "/vouchers/extensions/create",
          label: "Voucher Extension",
          icon: "fas fa-edit",
          resourceId: ResourceID.VoucherExtensionRequestCreationPage,
        },
        {
          path: "/vouchers/extensions/requests",
          label: "Extension Request",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.VoucherExtensionRequestListingPage,
        },
        {
          path: "/vouchers/programs",
          label: "Voucher Programs",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.VoucherProgramListingPage,
        },
        {
          path: "#",
          label: "Voucher Usage",
          icon: "fas fa-chart-area",
          children: [
            {
              path: "/vouchers/internal_usages",
              label: "Internal Vouchers",
              icon: "fas fa-money-check-alt",
              resourceId: ResourceID.VoucherUsageInternalListingPage,
            },
            {
              path: "/vouchers/external_usages",
              label: "External Vouchers",
              icon: "fas fa-money-check-alt",
              resourceId: ResourceID.VoucherUsageExternalListingPage,
            },
          ],
        },
      ],
    },
    {
      path: "#",
      label: "eVouchers",
      icon: "fas fa-money-check-alt",
      children: [
        {
          path: "/evouchers/orders",
          label: "eVouchers Orders",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.EVoucherOrderListingPage,
        },
        {
          path: "/evouchers/batches",
          label: "eVouchers Batches",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.EVoucherBatchListingPage,
        },
        {
          path: "/evouchers/batch_extension_requests",
          label: "eVouchers Batch Extension Request",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.EVoucherBatchExtensionRequestListingPage,
        },
        {
          path: "/evouchers/evouchers",
          label: "eVouchers",
          icon: "fas fa-file-invoice-dollar",
          resourceId: ResourceID.EVoucherListingPage,
        },
        {
          path: "/evouchers/extensions",
          label: "eVouchers Extensions",
          icon: "fas fa-edit",
          resourceId: ResourceID.EVoucherExtensionRequestListingPage,
        },
        {
          path: "/evouchers/programs",
          label: "eVouchers Programs",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.EVoucherProgramListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Website Management",
      icon: "fas fa-cogs",
      children: [
        {
          path: "/locations",
          label: "Locations",
          icon: "fas fa-location-arrow",
          resourceId: ResourceID.LocationListingPage,
        },
        {
          path: "/careers",
          label: "Careers",
          icon: "fas fa-user-graduate",
          resourceId: ResourceID.CareerListingPage,
        },
        {
          path: "/promotions",
          label: "Promotions",
          icon: "fas fa-comment-dollar",
          resourceId: ResourceID.PromotionListingPage,
        },
        {
          path: "/contests",
          label: "Contests",
          icon: "fas fa-comment-dollar",
          resourceId: ResourceID.ContestListingPage,
        },
        {
          path: "/movie_vouchers",
          label: "Movie Vouchers",
          icon: "fas fa-money-check-alt",
          resourceId: ResourceID.MovieVoucherListingPage,
        },
        {
          path: "/film_festivals",
          label: "Film Festivals",
          icon: "fas fa-film",
          resourceId: ResourceID.FilmFestivalListingPage,
        },
        {
          path: "/movie_marathons",
          label: "Movie Marathons",
          icon: "fas fa-film",
          resourceId: ResourceID.MarathonListingPage,
        },
        {
          path: "/kinolounge_movies",
          label: "Kinolounge",
          icon: "fas fa-film",
          resourceId: ResourceID.KinoLoungeListingPage,
        },
        {
          path: "/custom_contents",
          label: "Custom Contents",
          icon: "fas fa-desktop",
          resourceId: ResourceID.CustomContentListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Passes",
      icon: "fas fa-ticket-alt",
      children: [
        {
          path: "/passes",
          label: "Passes",
          icon: "fas fa-ticket-alt",
          resourceId: ResourceID.PassListingPage,
        },
        {
          path: "/staff_passes",
          label: "Staff Passes",
          icon: "fas fa-ticket-alt",
          resourceId: ResourceID.StaffPassListingPage,
        },
        {
          path: "/passes/extend",
          label: "Pass Extension",
          icon: "fas fa-edit",
          resourceId: ResourceID.PassExtensionPage,
        },
        {
          path: "/pass_programs",
          label: "Pass Program",
          icon: "fas fa-list-alt",
          resourceId: ResourceID.PassProgramListingPage,
        },
        {
          path: "/passes/usage",
          label: "Pass Usage",
          icon: "fas fa-chart-area",
          resourceId: ResourceID.PassUsageListingPage,
        },
      ],
    },
    {
      path: "/campaigns",
      label: "Campaigns",
      icon: "fas fa-bullhorn",
      resourceId: ResourceID.CampaignsListingPage,
    },
    {
      path: "#",
      label: "Products",
      icon: "fas fa-shopping-cart",
      children: [
        {
          path: "/products",
          label: "Products",
          icon: "fas fa-shopping-cart",
          resourceId: ResourceID.ProductListingPage,
        },
        {
          path: "/product_menus",
          label: "Product Menu",
          icon: "fas fa-align-justify",
          resourceId: ResourceID.ProductMenuListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Movies",
      icon: "fas fa-film",
      children: [
        {
          path: "/movies",
          label: "Movies",
          icon: "fas fa-film",
          resourceId: ResourceID.MovieListingPage,
        },
        {
          path: "/movie_release_medias",
          label: "Movie Media",
          icon: "fas fa-photo-video",
          resourceId: ResourceID.MovieMediaListingPage,
        },
        {
          path: "/performances",
          label: "Showtimes",
          icon: "fas fa-theater-masks",
          resourceId: ResourceID.PerformanceListingPage,
        },
        {
          path: "/performance-selectors",
          label: "Performance Selectors",
          icon: "fas fa-theater-masks",
          resourceId: ResourceID.PerformanceSelectorListingPage,
        },
        {
          path: "/movie-promotion-validity",
          label: "Movie Promotion Validity",
          icon: "fas fa-film",
          resourceId: ResourceID.MovieReleasePromotionValidityListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Pricing",
      icon: "fas fa-dollar-sign",
      children: [
        {
          path: "/services",
          label: "Service",
          icon: "fas fa-hand-holding-usd",
          resourceId: ResourceID.ServiceListingPage,
        },
        {
          path: "/surcharges",
          label: "Surcharge",
          icon: "fas fa-dollar-sign",
          resourceId: ResourceID.ServiceSurchargeListingPage,
        },
        {
          path: "/price_update",
          label: "Price Update",
          icon: "fas fa-sync-alt",
          resourceId: ResourceID.PriceUpdateCreateButton,
        },
        {
          path: "/special_prices",
          label: "Special Prices",
          icon: "fas fa-sync-alt",
          resourceId: ResourceID.SpecialPricingListingPage,
        },
      ],
    },
    {
      path: "#",
      label: "Report Manager",
      icon: "fas fa-clipboard",
      children: [
        {
          path: "/internal_reports",
          label: "Internal Reports",
          icon: "fas fa-file-pdf",
          resourceId: ResourceID.InternalReportsListingPage,
        },
        {
          path: "/theatre_reports",
          label: "Theatre Reports",
          icon: "fas fa-file-pdf",
          resourceId: ResourceID.TheatreReportsListingPage,
        },
        {
          path: "/request_reports",
          label: "Requested Reports",
          icon: "fas fa-file-pdf",
          resourceId: ResourceID.RequestReportsListingPage,
        },
        {
          path: "/daily_docket",
          label: "Daily Docket Export",
          icon: "fas fa-file-export",
          resourceId: ResourceID.DailyDocketExportPage,
        },
      ],
    },
    {
      path: "/cache_clear",
      label: "Cache Clearing",
      icon: "fas fa-sync-alt",
      resourceId: ResourceID.CacheClearPage,
    },
  ];

  static filterMenu = (menu) => {
    const newMenu = [];
    for (let i = 0; i < menu.length; i++) {
      const menuItem = menu[i];
      const isAuthorized = AuthenticationManager.isAuthorized(
        menuItem.resourceId
      );
      if (menuItem.children) {
        const children = this.filterMenu(menuItem.children);
        if (isAuthorized && children.length > 0) {
          newMenu.push({
            path: menuItem.path,
            label: menuItem.label,
            icon: menuItem.icon,
            resourceId: menuItem.resourceId,
            children: children,
          });
        }
      } else if (isAuthorized) {
        newMenu.push({
          path: menuItem.path,
          label: menuItem.label,
          icon: menuItem.icon,
          resourceId: menuItem.resourceId,
        });
      }
    }
    return newMenu;
  };

  static findMenu = (menuItems, path) => {
    const result = {
      selectedPath: "",
      selectedLabel: "",
    };
    for (let i = 0; i < menuItems.length; i++) {
      const menuItem = menuItems[i];
      if (menuItem.children) {
        let subResult = this.findMenu(menuItem.children, path);
        if (subResult.selectedPath.length > result.selectedPath.length) {
          result.selectedPath = subResult.selectedPath;
          result.selectedLabel = subResult.selectedLabel;
        }
      } else if (path.toLowerCase().indexOf(menuItem.path) === 0) {
        if (menuItem.path.length > result.selectedPath.length) {
          result.selectedPath = menuItem.path;
          result.selectedLabel = menuItem.label;
        }
      }
    }
    return result;
  };
}