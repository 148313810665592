import { DateHelper } from "../../../common/Helpers";
import { FileHelper } from "../../../common/FileHelper";

export class EVoucherExporter {
  static toProtectedZipFile = async (data, password, onCustomize) => {
    const exportedData = data.map((eVoucher) => {
      const row = {
        Code: eVoucher.code,
        "Valid On": DateHelper.toDisplayDateTimeFormat(eVoucher.validityStart),
        "Valid Till": DateHelper.toDisplayDateTimeFormat(eVoucher.validityEnd),
        Status: eVoucher.status,
      };

      if (onCustomize) {
        onCustomize(eVoucher, row);
      }
      return row;
    });

    await FileHelper.toZipFile(exportedData, password);
  };

  static toProtectedZipReporting = async (data, password) =>
    await this.toProtectedZipFile(data, password, (eVoucher, row) => {
      row["Internal Running Number"] = eVoucher.internalId;
      row.OriginEVoucherId = eVoucher.originalEVoucherId;
      row.id = eVoucher.id;
      row["eVoucher Program Name"] = eVoucher.eVoucherProgramId;
    });
}
