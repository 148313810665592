import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class KinoLoungeMovieListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "kinolounge_movies",
        name: "Kinolounge Movies",
      }),
    });
  };

  getDefaultSort = () => "+DisplayWeight";

  getApiPath = () => "/api/v1/kinolounge_movies";

  injectSearchTerm(queryParameters, searchBy) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : this.state.name;
    return super.injectSearchTerm(queryParameters, { name });
  }

  onRowClick = (id) => {
    this.props.history.push(`/kinolounge_movies/${id}`);
  };

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableFilter() {
    const { name } = this.props;
    return (
      <div className="d-flex flex-row justify-content-end mb-2">
        <div className="col-md-3 pr-0">
          <input
            className="form-control search"
            type="Text"
            name="name"
            placeholder="Search"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onTextBoxChange}
            value={name}
          />
        </div>
        <button className="btn btn-primary search" onClick={this.search}>
          <i className="fas fa-search" aria-hidden="true"></i> Search
        </button>
      </div>
    );
  }

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Film Id</th>
            <th>Movie Title</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>Display Start On</th>
            <th>Display End On</th>
            <th>
              Created On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.movieId}</td>
                  <td>{item.movieTitle}</td>
                  <td>{item.displayWeight}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.createdOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </React.Fragment>
    );
  }

  generateExtendedComponents() {
    return (
      <div className="container-fluid">
        <div className="d-flex flex-row justify-content-end mb-2">
          {AuthenticationManager.isAuthorized(
            ResourceID.KinoLoungeCreateButtonPage
          ) && (
              <Link
                className="btn btn-primary"
                to="/kinolounge_movies/create"
                style={{ fontSize: "0.875rem" }}
              >
                <i className="fa fa-plus-circle"></i> Create New Kinolounge Movie
              </Link>)
          }
          <Link
            className="btn btn-primary ml-2"
            to="/scheduled_kinolounge_movies/schedules"
            style={{ fontSize: "1rem" }}
          >
            Scheduled Publishing
          </Link>
        </div>
      </div>
    );
  }
}
