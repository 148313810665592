import MenuItem from "./MenuItem";
import React from "react";
import { useEffect, useState } from "react";
import { SidebarService } from "../../services/SidebarService";

const MenuList = (props) => {
  const [menus, setMenus] = useState(
    SidebarService.filterMenu(SidebarService.ALL_MENUS)
  );

  const { pathname } = window.location;

  const updateMenu = (menu, selectedPath, selectedLabel) => {
    let found = menu.path === selectedPath && menu.label === selectedLabel;
    if (menu.children) {
      menu.isExpanded = found && !menu.isExpanded;
      let childrenFound = menu.children.reduce(
        (result, child) =>
          updateMenu(child, selectedPath, selectedLabel) || result,
        false
      );
      if (childrenFound) {
        found = childrenFound;
        menu.isExpanded = true;
      }
    } else {
      menu.isActive = found;
    }
    return found;
  };

  const onMenuClick = (
    e,
    selectedPath,
    selectedLabel,
    autoClickMobile = true
  ) => {
    setMenus((prev) =>
      prev.map((menu) => {
        updateMenu(menu, selectedPath, selectedLabel);
        return menu;
      })
    );
    if (selectedPath === "#") {
      e.preventDefault();
      return false;
    }
    if (props.onMenuClick && autoClickMobile) {
      props.onMenuClick();
    }
    return true;
  };

  useEffect(() => {
    const selectedMenu = SidebarService.findMenu(menus, pathname);
    onMenuClick(
      {},
      selectedMenu.selectedPath,
      selectedMenu.selectedLabel,
      false
    );
  }, [pathname]);
  return (
    <div className="menu-sidebar__content js-scrollbar1">
      <nav className="navbar-sidebar">
        <ul className="list-unstyled navbar__list">
          {menus.map((menu) => (
            <MenuItem
              key={menu.path + menu.label}
              {...menu}
              onMenuClick={onMenuClick}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default MenuList;