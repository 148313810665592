import React, { useState } from "react";
import { RowFormField } from "../layout/FormLayout";
import { useUpload } from "../../hooks/useUpload";
import { StorageModule } from "../../common/Constants";
import { toast } from "react-toastify";

export const AddPosterModal = (props) => {
  const { onCancel, onProceed, campaign } = props;
  const [sourceFile, setSourceFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const { uploadFile } = useUpload();

  const openFile = (e) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/webp"];
    const openedFile = e.target.files[0];
    const newFileName = campaign.code + ".jpg";

    if (!openedFile) {
      return;
    }

    if (!allowedFileTypes.includes(openedFile.type)) {
      toast.error("Invalid file extension. Please select another file.");
      e.target.value = null;
      return;
    }
    setSourceFile(openedFile);
    setFileName(newFileName);
  };

  const onClickAdd = () => {
    if (!sourceFile) {
      toast.error("File is required.");
      return;
    }

    uploadFile(sourceFile, fileName, StorageModule.Promotion).then(
      (response) => {
        const updatedImageUrl = `${response.data.blobStoragePath}${response.data.fileName}`;
        onProceed(updatedImageUrl);
        toast.success("Uploaded Poster Successfully.");
      }
    );
  };

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Poster</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="container-fluid">
                <div>
                  <RowFormField
                    label={
                      <span>
                        Upload File:<span className="color-red">*</span>
                      </span>
                    }
                  >
                    <div>
                      <input type="file" name="file" onChange={openFile} />
                    </div>
                  </RowFormField>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={onClickAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
