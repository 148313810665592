import React, { useState } from "react";
import Validators from "./Validators";
import _ from "lodash";
import { RowFormField } from "../layout/FormLayout";

export const ScheduleConfirmationDialog = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [planToPublish, setPlanToPublish] = useState("");
  const [validations, setValidations] = useState([]);

  const { onCancel, onProceed, message, title, data, warningMessage } = props;

  const isValidated = () => {
    return validations.every((val) => val.isValid);
  };

  const onProceedClick = () => {
    if (!isValidated()) {
      setIsSubmit(true);
      return;
    }
    const e = {
      planToPublish: planToPublish === "" ? null : planToPublish,
    };
    if (onProceed) {
      onProceed(e);
    }
  };

  const onPublishTimeChange = (e) => {
    setPlanToPublish(e.target.value);
  };

  const onValidationStatusChange = (e) => {
    const updatedValidations = validations.map((val) => {
      if (val.fieldName === e.fieldName && val.type === e.type) {
        return {
          ...val,
          isValid: e.isValid,
          message: e.message,
        };
      }
      return val;
    });
    setValidations(updatedValidations);
  };

  const actionMessage =
    message || "Are you sure you want to perform this action?";
  const headerTitle = title || "Confirmation";
  const isInvalidPlanToPublish =
    _.isEmpty(planToPublish) || new Date(planToPublish) <= new Date();

  return (
    <div>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {headerTitle}
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField label="Publish On:">
                  <input
                    className="form-control"
                    type="datetime-local"
                    onChange={onPublishTimeChange}
                    value={planToPublish}
                  />
                  <div className="offset-md-2 col-md-10">
                    <Validators.RequiredValidator
                      onValidationStatusChange={onValidationStatusChange}
                      fieldName="planToPublish"
                      isEnabled={isSubmit}
                      property="Publish On"
                      value={planToPublish}
                    />
                  </div>
                </RowFormField>
                {data.length > 0 && (
                  <div className="text-warning">
                    {warningMessage}
                  </div>
                )}
                {actionMessage && <div>{actionMessage}</div>}
                {isInvalidPlanToPublish  && <div className="text-danger">Publish On must be a future date.</div>}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onProceedClick}
                disabled={isInvalidPlanToPublish}
              >
                Confirm
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  );
};
