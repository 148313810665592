import React, { Component } from "react";
import { NavigationDocumentation } from "./NavigationDocumentation";
import { HeaderDesktopDocumentation } from "./HeaderDesktopDocumentation";


export class LayoutDocumentation extends Component {
    static displayName = LayoutDocumentation.name;

    render() {
        return (
            <div>
                <NavigationDocumentation />
                <div className="page-container">
                    <HeaderDesktopDocumentation />
                    {this.props.children}
                </div>
            </div>
        );
    }
}
