import React, { Component } from "react";
import '../css/documentation.scss';

export class EditCareerHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Career Details</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 5 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Careers” link. This will take you to the Careers Listing Page.   <br />
                                    <img src="/img/documentation-img/CareersImg/nav_careers.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Careers Listing Page, click on any career listing of your choice. This will take you to the View Career Details. <br />
                                    <img src="/img/documentation-img/CareersImg/career_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To edit the career listing, click on the “Edit” button in the top right corner of the page.<br />
                                    <img src="/img/documentation-img/CareersImg/edit_career.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Edit Career Page, you will be able to see the following editable fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Career Title
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Display Start
                                        </li>
                                        <li>
                                            Display End
                                        </li>
                                        <li>
                                            Short Description
                                        </li>
                                        <li>
                                            Responsibilities
                                        </li>
                                        <li>
                                            Requirements
                                        </li>
                                        <li>
                                            Long Description
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Short Description, Responsbilities, Requirements, and Long Description should be in HTML format.<br />
                                            - Short Description, Responsibilities, Requirements, and Benefits have a maximum of 4000 characters.<br />
                                            - Long Description has a maximum of 12000 characters.
                                </div>
                            </li>
                            <li class="createList">
                                To preview the career listing, click “Preview” at the bottom right corner of the page. <br />
                        <img src="/img/documentation-img/CareersImg/edit_career_preview.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To cancel the preview, click on “Cancel Preview” at the top right corner of the page. <br />
                        <img src="/img/documentation-img/CareersImg/cancel_preview.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To cancel all changes, click on the “Cancel” button at the bottom right corner. <br />
                        <img src="/img/documentation-img/CareersImg/edit_career_cancel.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To confirm that you want to cancel all changes, click on "Confirm". <br />
                        <img src="/img/documentation-img/CareersImg/edit_cancel_confirm.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To save your changes, click on “Save Changes” at the bottom right corner of the page. <br />
                        <img src="/img/documentation-img/CareersImg/edit_career_savechanges.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                        <img src="/img/documentation-img/CareersImg/save_changes_confirm.png" class="userImage" />
                            </li>
                            <li class="createList">
                                Upon successful save, a message will appear at the bottom right of the screen.<br />
                        <img src="/img/documentation-img/CareersImg/successful_toaster.png" class="userImage" />
                            </li>
                            <li class="createList">
                                To view history changes, click on the "History” button, at the top right corner of the page.<br />
                        <img src="/img/documentation-img/CareersImg/history_career.png" class="userImage" />
                            </li>
                            <li class="createList">
                                A popup will appear, stating the changes made. Click on “View Details” to see more.<br />
                        <img src="/img/documentation-img/CareersImg/history_view.png" class="userImage" />
                            </li>
                            <li class="createList">
                                A pop-up will appear, stating the old and new values.<br />
                        <img src="/img/documentation-img/CareersImg/history_details.png" class="userImage" />
                            </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/careers/CreateCareerHelp" className="btn btn-primary">&laquo; Create Career</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}