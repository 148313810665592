import React, { Component } from "react";
import '../css/documentation.scss';

export class ApproveCancelRejectBatch extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Approve/Cancel/Reject Batch</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    Navigate to the eVoucher Batch listing page by clicking the link in the left navbar.<br />
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/eVoucherBatch.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    Upon creating an eVoucher batch, it is displayed in the eVoucher Batches listing page. To perform further actions on the eVoucher batch, select any record from the listing page with the 'Pending' status.<br />
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/eVoucherBatchListing.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    Upon selecting any record, you will be redirected to the view eVoucher Batch Details page. If the status of the eVoucher is ‘Pending’, the following action buttons ‘Approve’, ‘Reject’, ‘Cancel Batch’ will be shown
                                    <ul class="unorderedList">
                                        <li>
                                            Approve – Approves the batch
                                        </li>
                                        <li>
                                            Reject – Rejects the batch
                                        </li>
                                        <li>
                                            Cancel – Cancels the batch
                                        </li>
                                    </ul>
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/eVoucherBatchDetails.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    To Approve the batch, click the ‘Approve’ button. Click ‘Confirm’ to approve the batch.<br />
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/approveprompt.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    To Reject the batch, click the ‘Reject’ button. You will see the following confirmation prompt. Before the rejection process is allowed, you must fill in the ‘Reason’ field, before clicking ‘Confirm’ to reject the batch.<br />
                                    <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/rejectprompt.png" class="userImage" /><br /><br />
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            If no reason is given, you will see the following message.<br />
                                            <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/reasonToaster.png" class="userImage" /><br />
                                </div>
                            </li>
                                        <li class="createList">
                                            To cancel the batch, click the ‘Cancel Batch’ button. This will throw a confirmation prompt. Users are required to fill in the ‘Reason’ field before cancellation of the batch is allowed. After filling in the reason, click ‘Confirm’ to cancel the batch. If no reason is given the same message shown above will be displayed once more.<br />
                                            <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/cancelprompt.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            After performing any of the above actions ‘Approve/Reject/Cancel Batch’, the status of the eVoucher Batch will be shown accordingly in the listing page.<br />
                                            <img src="/img/documentation-img/evouchers/approve-cancel-reject-batch/afterstatus.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/createEvoucherBatch" className="btn btn-primary">&laquo; Create EVoucher Batch</a>&nbsp;
                                <a href="/documentation/evouchers/extendDownloadEvoucherBatch" className="btn btn-primary">Extend/Download EVoucher Batch Codes &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
