import React from "react";
import _ from "lodash";
import { MovieReleaseMedia } from "../../../models/MovieReleaseMedia";
import { MediaType } from "../../../constants/MovieConstants";
import { MediaLinkPlaceHolder } from "../../../constants/MovieConstants";
import { MovieDisplayWeight } from "../../../constants/MovieConstants";
import { StorageModule } from "../../../common/Constants";
import { useUpload } from "../../../hooks/useUpload";
import { toast } from "react-toastify";
import Validators from "../../common/Validators";
import { RowFormField } from "../../layout/FormLayout";

export class AddMediaModal extends React.Component {
  constructor(props) {
    super();
    const instance = new MovieReleaseMedia();

    const now = new Date();
    instance.id = now.getTime();
    instance.type = props.type;

    this.state = {
      instance: instance,
      imageURL: "",
      sourceFile: null,
      fileName: "",
      validations: [],
      isSubmit: false,
    };
  }

  openFile = (e) => {
    const { instance } = this.state;
    const { movieRelease } = this.props;
    const suffixNumber = new Date().valueOf();
    const sourceFile = e.target.files[0];
    const fileName = `${movieRelease.id}-${MediaType.getName(
      instance.type
    )}-${suffixNumber}`;
    this.setState({
      sourceFile,
      fileName: fileName,
    });
  };

  onClickAdd = () => {
    const { instance, fileName, sourceFile } = this.state;
    const { onProceed } = this.props;
    const { uploadFile } = useUpload();
    if (instance.type === MediaType.Trailer) {
      onProceed(instance);
      return;
    }
    if (sourceFile) {
      uploadFile(sourceFile, fileName, StorageModule.Movie).then((response) => {
        const updatedImageUrl = `${response.data.blobStoragePath}${response.data.fileName}`;
        instance.url = updatedImageUrl;
        this.setState({
          imageURL: updatedImageUrl,
          instance,
          isSubmit: false,
        });
        onProceed(instance);
      });
    } else {
      toast.error("File is required.");
    }
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    instance[fieldName] = selectValue;
    this.setState({ instance });
  };

  renderDisplayWeight = (type) => {
    const { instance } = this.state;
    if (type !== MediaType.Photo) {
      return null;
    }
    return (
      <RowFormField
        label={
          <span>
            Display Weight:<span className="color-red">*</span>
          </span>
        }
      >
        <input
          className="form-control"
          type="number"
          onChange={this.onInputChange}
          name="displayWeight"
          valuetype="number"
          value={instance.displayWeight}
          placeholder="Enter display Weight"
          min={MovieDisplayWeight.Length}
          max="255"
        />
      </RowFormField>
    );
  };

  onFileNameChange = (e) => {
    this.setState({ fileName: e.target.value });
  };

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  render() {
    const { onCancel, type } = this.props;
    const { instance, fileName, isSubmit } = this.state;
    return (
      <>
        <div
          className="modal fade show"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: "block", paddingRight: "17px" }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add {MediaType.getName(type)}</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onCancel}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  {instance.type === MediaType.Poster ||
                  instance.type === MediaType.Photo ? (
                    <div>
                      <RowFormField
                        label={
                          <span>
                            Upload File:<span className="color-red">*</span>
                          </span>
                        }
                      >
                        <input
                          type="file"
                          name="file"
                          onChange={this.openFile}
                        />
                      </RowFormField>
                      <RowFormField label={<span>File Name:</span>}>
                        <input
                          className="form-control"
                          type="text"
                          value={fileName}
                          onChange={this.onFileNameChange}
                          name="fileName"
                        />
                      </RowFormField>
                    </div>
                  ) : (
                    <RowFormField
                      label={
                        <span>
                          Url:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        type="text"
                        placeholder={
                          instance.type === MediaType.Trailer
                            ? MediaLinkPlaceHolder.Video
                            : ""
                        }
                        onChange={this.onInputChange}
                        name="url"
                      />
                    </RowFormField>
                  )}

                  <RowFormField
                    label={
                      <span>
                        Display Start:<span className="color-red">*</span>
                      </span>
                    }
                  >
                    <input
                      className="form-control"
                      type="datetime-local"
                      value={instance.displayStart}
                      onChange={this.onInputChange}
                      name="displayStart"
                    />
                    <div className="col-8">
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldName="displayStart"
                        isEnabled={isSubmit}
                        property="Display Start"
                        value={instance.displayStart}
                        className="col-8"
                      />
                    </div>
                  </RowFormField>
                  {this.renderDisplayWeight(type)}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    data-dismiss="modal"
                    onClick={this.onClickAdd}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    data-dismiss="modal"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </>
    );
  }
}
