import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import { ProductCategory } from "../Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ProductListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
      selectedCategory: 0,
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
      activeModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
    });
  };

  getApiPath = () => "/api/v1/products";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters, searchBy) {
    const { name, selectedCategory } = this.state;
    const category = selectedCategory ? selectedCategory : 0;
    return super.injectSearchTerm(queryParameters, { name, category });
  }

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Product Code</th>
            <th>Prouduct Name</th>
            <th>Category</th>
            <th>
              Display Weight
              <i
                className="fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On
              <i
                className="fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody id="data">
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.code}</td>
                  <td>{item.name}</td>
                  <td>{ProductCategory.getName(item.category)}</td>
                  <td>{item.displayWeight}</td>
                  <td className="datetime">
                    {StringHelper.asDateStringToDefaultFormat(item.updatedOn)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateTableFilter() {
    const { selectedCategory } = this.state;
    return (
      <div className="row mb-3">
        <div className="col row">
          <label
            className="form-control-label mt-1 m-l-15 mr-2"
            htmlFor="Status"
          >
            Category:
          </label>
          <select
            className="form-control col-5"
            name="status"
            id="Status"
            required
            onChange={this.onProductCategoryChange}
            value={selectedCategory}
          >
            <option value="0">All Category</option>
            {ProductCategory.All.map((item, index) => {
              if(item.value === ProductCategory.ComboContent) {
                return <></>
              }
              return (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-md-6 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="name"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onTextBoxChange}
            value={this.state.name}
          />
          <span>&nbsp;</span>
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.ProductCreateButtonPage
      ) && (
        <div className="d-flex flex-row justify-content-end mb-3">
          <Link
            className="btn btn-primary"
            to="/products/create"
            style={{ fontSize: "1rem" }}
          >
            <i className="fas fa-plus-circle" /> Create New Product
          </Link>
        </div>)
    );
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onProductCategoryChange = (e) => {
    this.setState({ selectedCategory: e.target.value });
  };
}
