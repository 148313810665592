import React from "react";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import { StringHelper } from "../../../../common/Helpers";
import { EVoucherBatchStatus } from "../../../../common/Constants";
import { BaseListing } from "../../../common/BaseListing";
import { log } from "../../../../common/Common";
import { Link } from 'react-router-dom';
import {ColumnFormField} from "../../../layout/FormLayout";

export class EvoucherBatchListingCard extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      batchStatus: 0,
      evoucherBatchStatus: [],
      reference: "",
    };
  }

  async componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    await this.loadData(0);
    this.getEvoucherBatchStatuses();
  }

  componentDidUpdate() {
    if (
      !this.refreshCache ||
      !this.state.model.evoucherProgram ||
      StringHelper.isNullOrEmpty(this.state.model.eVoucherProgram.id)
    ) {
      return;
    }
    this.refreshCache = false;
    this.loadEvoucherProgramDetails(this.state.model.eVoucherProgram.id);
  }

  getEvoucherBatchStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_batches/statuses",
      (response) => {
        this.setState({ evoucherBatchStatus: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  getApiPath = () => "/api/v1/evoucher_batches";

  getDefaultSort = () => "UpdatedOn";

  injectSearchTerm(queryParameters, searchBy) {
    const reference = StringHelper.isNullOrEmpty(this.state.reference)
      ? ""
      : this.state.reference;
    const batchStatus = this.state.batchStatus;
    const evoucherOrderId = this.props.orderId;
    return super.injectSearchTerm(queryParameters, {
      reference,
      batchStatus,
      evoucherOrderId,
    });
  }

  render() {
    return (
      <Card title="Batch Information">
        <div className="row">
          <div className="col">{this.generateTableFilter()}</div>
        </div>
        <div className="row">
          <div className="col">
            <div className="table-responsive m-b-10 table-px-15">
              <table className="table table-borderless table-striped table-earning">
                {this.generateTableContent()}
              </table>
            </div>
          </div>
        </div>
        {this.generatePagination()}
      </Card>
    );
  }

  onEvoucherBatchStatusChange = (e) => {
    this.setState({ batchStatus: e.target.value });
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <ColumnFormField label="Status:" htmlFor="OrderStatus">
          <select
            className="form-control"
            name="orderStatus"
            id="OrderStatus"
            required
            onChange={this.onEvoucherBatchStatusChange}
            value={this.state.batchStatus}
          >
            <option value={0}>All Status</option>
            {this.state.evoucherBatchStatus &&
              this.state.evoucherBatchStatus.map((item) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
          </select>
        </ColumnFormField>
        <ColumnFormField label="&nbsp;">
          <div className="d-flex">
            <input
              type="text"
              id="input1-group2"
              name="reference"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" /> Search
              </button>
            </div>
          </div>
        </ColumnFormField>
      </div>
    );
  }

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Batch ID</th>
            <th>
              Expiry Date
              <i
                id="ValidityEnd"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th>Total Quantity</th>
            <th>Remaining Quantity</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr key={item.id} className="pending">
                <td><Link to={`/evouchers/batches/${item.id}`}>{item.reference}</Link></td>
                <td>
                  {StringHelper.asDateStringToDisplayDateFormat(
                    item.validityEnd
                  )}
                </td>
                <td className={EVoucherBatchStatus.getColor(item.status)}>
                  {item.status}
                </td>
                <td>{item.totalQuantity}</td>
                <td>{item.totalQuantity - item.usedQuantity}</td>
                <td>
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    item.updatedOn
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
