import { MovieFormat } from "../../constants/MovieConstants";
import {
  ApplicationID,
  VoucherProgramMedium,
  VoucherProgramType,
} from "../../common/Constants";
import { PerformanceTypes } from "../../constants/PricingConstants";
import { CreditCardType } from "../../constants/TransactionConstants";
import { TheatreCompanyType } from "../../constants/TheatreCompanyType";

export function useMovieFormats() {
  return MovieFormat.All.filter(
    (x) => x.value !== MovieFormat.AnalogStandard
  ).map((x) => ({
    ...x,
    id: x.value,
    isDefault: false,
  }));
}

export function useAdmissionTypes() {
  return [
    {
      id: 0,
      code: "All",
      name: "All",
      isDefault: true,
    },
    {
      id: 1,
      code: "ADM",
      name: "Exclude No Admission",
      isDefault: false,
    },
    {
      id: 2,
      code: "NOADM",
      name: "No Admission Only",
      isDefault: false,
    },
  ];
}

export function useSystemComponents() {
  return [
    {
      id: ApplicationID.TPOS,
      code: "TPOS",
      name: "Counter (Selling Station)",
      isDefault: false,
    },
    {
      id: ApplicationID.PWSM,
      code: "PWSM",
      name: "Internet (Public Website)",
      isDefault: false,
    },
    { id: ApplicationID.TKOS, code: "TKOS", name: "Kiosk", isDefault: false },
    { id: ApplicationID.INCP, code: "INCP", name: "Portal", isDefault: false },
  ];
}

export function usePerformanceTypes() {
  return PerformanceTypes.All.filter(
    (x) => x.value !== PerformanceTypes.SpecialScreening
  ).map((x) => ({
    ...x,
    id: x.value,
    isDefault: false,
  }));
}

export function useCreditCardTypes() {
  return CreditCardType.All.map((x) => ({
    ...x,
    id: x.value,
    isDefault: false,
  }));
}

export function useTheatreCompanyTypes() {
  return TheatreCompanyType.All.map((x) => ({
    ...x,
    id: x.value,
    isDefault: false,
  }));
}

export function useVoucherProgramMediums() {
  return VoucherProgramMedium.All.map((x) => ({
    ...x,
    id: x.value,
    isDefault: x.value === VoucherProgramMedium.Physical,
  }));
}

export function useVoucherProgramTypes() {
  return VoucherProgramType.All.map((x) => ({
    ...x,
    id: x.value,
    isDefault: false
  }));
}

export function useYearSelections() {
  const minYear = 2018;

  const currentYear = new Date().getFullYear();
  let counter = minYear;
  const years = [];
  while (counter <= currentYear) {
    years.push(counter++);
  }

  return years.map((x) => {
    const text = `${x}`; 
    return {
      id: x,
      code: text,
      name: text,
      isDefault: x === currentYear,
    };
  });
}

export function useMonthSelections() {
  const start = new Date(2018, 0, 1);
  const results = [];
  let counter = 1;
  const currentShortMonth = new Date().toLocaleString("en-us", {month: "short"});
  while(counter <= 12) {
    const shortMonth = start.toLocaleString("en-us", {month: "short"});
    results.push({
      id: counter,
      code: shortMonth,
      name: shortMonth,
      isDefault: shortMonth === currentShortMonth,
    })
    start.setMonth(counter);
    counter++;
  }
  return results;
}
