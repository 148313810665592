import React, { useEffect, useMemo, useState } from "react";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import { BreadCrumb } from "../../common/BreadCrumb";
import { StringHelper } from "../../../common/Helpers";
import { PerformanceRestService } from "../../../services/PerformanceRestService";
import { useLocations } from "../../transaction.managements/TransactionState";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ColumnFormField } from "../../layout/FormLayout";

export const ExternalVoucherDetails = ({ match: { params } }) => {
  const { id } = params;
  const [model, setModel] = useState({});
  const [performance, setPerformance] = useState({});
  const [transaction, setTransaction] = useState({});
  const performanceService = useMemo(() => new PerformanceRestService(), []);
  const locations = useLocations();
  const { serialNumber, voucherProgram, usedOn, performanceId, transactionId } =
    model;

  useEffect(() => {
    RestClient.sendGetRequest(
      `api/v1/voucher_usages/externals/${id}?includes=VoucherProgram`,
      (response) => {
        setModel(response);
      },
      (error) => toast.error(error.message)
    );
  }, [id]);

  useEffect(() => {
    if (!performanceId || performanceId === 0) return;
    performanceService.get(
      performanceId,
      (response) => {
        setPerformance(response);
      },
      (error) => toast.error(error.message)
    );
  }, [performanceId, performanceService]);

  useEffect(() => {
    if (!transactionId || transactionId === 0) return;
    RestClient.sendGetRequest(
      `api/v1/transactions/${transactionId}`,
      (response) => {
        setTransaction(response);
      },
      (error) => toast.error(error.message)
    );
  }, [transactionId]);

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-details",
        name: "Voucher Details",
      }),
    });
  };
  const { primaryTitle, actualDateTime, locationVenue } = performance;
  const { referenceNumber, clientCode, salesChannel } = transaction;
  const redemptionLocation =
    locations.find((x) => x.id === transaction.locationId)?.name ?? "";
  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <Card title="Voucher Details">
            <div className="row">
              <ColumnFormField label="Serial Number:" htmlFor="voucherref">
                {serialNumber}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Voucher Program:" htmlFor="program">
                {voucherProgram?.name}
              </ColumnFormField>
            </div>
            <div className="row">
              <ColumnFormField label="Used On:" htmlFor="usedon">
                {StringHelper.asDateStringToDisplayDateTimeFormat(usedOn)}
              </ColumnFormField>
            </div>
          </Card>
          {!!transaction && !!performanceId && (
            <Card title="Usage Details">
              <div className="row">
                <ColumnFormField label="Movie Title:">
                  {primaryTitle}
                </ColumnFormField>
                <ColumnFormField label="Redemption Mode:">
                  {salesChannel}
                </ColumnFormField>
              </div>
              <div className="row">
                <ColumnFormField label="Location:">
                  {locationVenue?.location?.name}
                </ColumnFormField>
                <ColumnFormField label="Redemption Location:">
                  {redemptionLocation}
                </ColumnFormField>
              </div>
              <div className="row">
                <ColumnFormField label="Location Hall:">
                  {locationVenue?.name}
                </ColumnFormField>
                <ColumnFormField label="Redemption Counter:">
                  {clientCode}
                </ColumnFormField>
              </div>
              <div className="row">
                <ColumnFormField label="Movie Datetime:">
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    actualDateTime
                  )}
                </ColumnFormField>
                <ColumnFormField label="Transaction Reference:">
                  {referenceNumber}
                </ColumnFormField>
              </div>
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};
