import React, { useState } from "react";
import { DateHelper } from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { modeAtom, serviceTicketsSelector } from "../state";
import _ from "lodash";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { ViewMode } from "../../../../constants/PricingConstants";

export const PricingItem = (props) => {
  const { data } = props;
  const [serviceTickets, setServiceTickets] = useRecoilState(
    serviceTicketsSelector
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const mode = useRecoilValue(modeAtom);

  const onDelete = () => {
    RestClient.sendDeleteRequest(
      `/api/v1/service_ticket_prices/${data.id}`,
      () => {
        const editableServiceTickets = _.cloneDeep(serviceTickets);
        const serviceTicket = editableServiceTickets.find(
          (x) => x.id === data.serviceTicketId
        );
        serviceTicket.serviceTicketPrices =
          serviceTicket.serviceTicketPrices.filter((x) => x.id !== data.id);
        setServiceTickets(editableServiceTickets);
        toast.success("The service ticket price has been deleted successful.");
      },
      (error) => {
        toast.error(error.message);
      }
    );
    setShowConfirmation(false);
  };

  if (!data) {
    return null;
  }

  return (
    <div className="card card-body position-relative">
      <div className="d-flex flex-row justify-content-end position-absolute top-8 right-8">
        {props.isAllowDelete && (
          <button
            className="btn btn-sm btn-danger"
            onClick={() => setShowConfirmation(true)}
            disabled={mode === ViewMode.Readonly}
          >
            <i className="far fa-trash-alt" />
          </button>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <label className="col-12">Price ID: {data.id}</label>
          <label className="col-12">
            Effective Start:
            <span className="EditDate mx-1" id="EditDate">
              {DateHelper.formatDateTimeString(data.effectiveStart)}
            </span>
          </label>
        </div>
        <div className="col-md-6">
          <label className="col-12">
            Sales Amount: {data.salesAmount.toFixed(6)}
          </label>
          <label className="col-12">
            Price Print Code: {data.pricePrintCode}
          </label>
        </div>
      </div>
      <ConfirmDialog
        visible={showConfirmation}
        onProceed={onDelete}
        title="Delete Confirmation"
        message="Are you sure you want to delete this service ticket price?"
        onCancel={() => setShowConfirmation(false)}
      />
    </div>
  );
};
