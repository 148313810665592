import React, { useState } from "react";
import { MovieGenre } from "../../../constants/MovieConstants";
import _ from "lodash";
import { RowFormField } from "../../layout/FormLayout";

export const AddGenreModal = (props) => {
  const [genre, setGenre] = useState("");

  if (!props.visible) {
    return null;
  }

  const disableAddBtn = _.isEmpty(genre);

  return (
    <>
      <div
        className="modal fade show"
        id="CrewModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Movie Genre
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={props.onCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Movie Genre:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control"
                    name="role"
                    onChange={(e) => setGenre(e.target.value)}
                  >
                    <option value={0}>Select Movie Genre</option>
                    {MovieGenre.All.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  disabled={disableAddBtn}
                  onClick={() => {
                    props.onProceed(genre);
                    props.onCancel();
                    setGenre(0);
                  }}
                  data-dismiss="modal"
                >
                  <i className="fas fa-plus-sign" aria-hidden="true" />
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={props.onCancel}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};