import React, { useState } from "react";
import _ from "lodash";
import { PricingItem } from "./PricingItem";
import { TicketTypes, ViewMode } from "../../../../constants/PricingConstants";
import { activeServiceTicketIdAtom, modeAtom } from "../state";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AddPricingModal } from "../modals/AddPricingModal";
import {RowFormField} from "../../../layout/FormLayout";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";

export const ServiceTicketItem = (props) => {
  const { serviceTicket } = props;
  const setServiceTicketId = useSetRecoilState(activeServiceTicketIdAtom);
  const [showAddPricingModal, setShowAddPricingModal] = useState(false);
  const ticketType = TicketTypes.get(serviceTicket.ticketType);
  const mode = useRecoilValue(modeAtom);

  return (
    <>
      <div className="card card-body bg-light">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary float-right"
            data-toggle="modal"
            onClick={() => {
              setServiceTicketId(serviceTicket.id);
              setShowAddPricingModal(true);
            }}
            disabled={mode === ViewMode.Readonly}
          >
            Add Pricing
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>

        <div className="card card-body">
          <RowFormField label="Ticket type Code:">
            <input
              className="form-control form-readonly"
              value={ticketType.code}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Ticket type Name:">
            <input
              className="form-control form-readonly"
              value={ticketType.name}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Print Label:">
            <input
              className="form-control form-readonly"
              value={serviceTicket.printLabel}
              readOnly
            />
          </RowFormField>
        </div>
        <div>
          {!_.isEmpty(serviceTicket.serviceTicketPrices) &&
            serviceTicket.serviceTicketPrices.map((entry, index) => (
              <PricingItem key={index} data={entry} isAllowDelete={serviceTicket.serviceTicketPrices.length > 1} />
            ))}
        </div>
      </div>
      <AddPricingModal
        visible={showAddPricingModal}
        onCancel={() => setShowAddPricingModal(false)}
      />
    </>
  );
};
