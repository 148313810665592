import React, { useCallback, useEffect, useState } from "react";
import { Strings } from "../../../../common/Common";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../../common/AuthenticationManager";
import RestClient from "../../../../common/RestClient";
import { TicketView } from "../../common/TicketView";
import TransactionInformation from "../../common/TransactionInformation";
import { RefundCreation } from "../../refunds/RefundCreation";
import "./../../../css/transaction-details.css";
import { BreadCrumb } from "../../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../../common/NavigationSettings";
import { toast } from "react-toastify";
import { TransactionStatus } from "../../../../constants/TransactionConstants";
import { Transaction } from "../../../../models/Transaction";
import { useHistory } from "react-router";
import { Customer } from "../../../../models/Customer";
import { PaymentCredit } from "../../../../models/PaymentCredit";
import { RefundRequest } from "../../../../models/RefundRequest";
import { HistoryDialog } from "../../../common/HistoryDialog";
import { ConfirmResendEmailDialog } from "../../../common/ConfirmResendEmailDialog";
import {
  ApplicationID,
  ObjectType,
  SystemSettingKeys,
} from "../../../../common/Constants";
import { PaymentVoucher } from "../../../../models/PaymentVoucher";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import TransactionDetailPaymentInformation from "./TransactionDetailPaymentInformation";
import TransactionDetailCustomerInformation from "./TransactionDetailCustomerInformation";
import TransactionDetailGeneralInformation from "./TransactionDetailGeneralInformation";
import CardLayout from "../../../layout/CardLayout";
import { RefundStatus } from "../../../../constants/RefundConstants";
import { useTransactionChargedStatus } from "../../../../hooks/useTransactionChargedStatus";
import { SystemSettings } from "../../../../common/SystemSettings";

const TPOS = ApplicationID.getName(ApplicationID.TPOS);
const PWSM = ApplicationID.getName(ApplicationID.PWSM);
const GPAY = ApplicationID.getName(ApplicationID.GPAY);
const TKOS = ApplicationID.getName(ApplicationID.TKOS);

const getCreateRefundButtonTooltip = (
  transaction,
  refundRequests,
  orderDetail
) => {
  if (transaction.isRefund)
    return "Refund request cannot be created for refund transactions.";
  if (transaction.isCharged === undefined)
    return "Please view charged status before creating refund request";
  if (
    transaction.status !== TransactionStatus.Successful &&
    !transaction.isCharged
  )
    return "Refund request cannot be created as the transaction was not charged.";
  const hasVoucher = Object.keys(orderDetail.vouchers || {}).length > 0;
  if (hasVoucher)
    return "Refund request cannot be created for transactions with voucher(s) applied.";

  const hasPendingRequest =
    refundRequests.filter((x) => x.status === RefundStatus.Pending).length > 0;
  if (hasPendingRequest)
    return "A refund request created for this transaction is currently in pending status.";

  const refundedAmount = refundRequests
    .filter((x) => x.status !== RefundStatus.Rejected)
    .reduce(
      (refundedAmount, refundRequest) => refundedAmount + refundRequest.amount,
      0
    );
  if (refundedAmount >= transaction.amountFinal)
    return "The transaction has already been fully refunded.";

  return "";
};

const isRefundPeriodExpired = (transaction, refundRequests) => {
  if (
    transaction.salesChannel !== PWSM ||
    !refundRequests ||
    refundRequests.length === 0
  ) {
    return false;
  }

  const processedStatuses = [RefundStatus.Approved, RefundStatus.Successful];
  const isNotRefundable = refundRequests.some((x) =>
    processedStatuses.includes(x.status)
  );
  if (isNotRefundable) {
    return true;
  }

  const pendingRequest = refundRequests.find(
    (x) => x.status === RefundStatus.Pending
  );
  if (!pendingRequest) {
    return false;
  }

  const onlineRefundTimeoutInMinutes = SystemSettings.get(
    SystemSettingKeys.OnlineRefundTimeoutInMinutes
  );
  const expiredDateTime = new Date(pendingRequest.createdOn);
  expiredDateTime.setMinutes(
    expiredDateTime.getMinutes() + onlineRefundTimeoutInMinutes
  );
  return expiredDateTime <= new Date();
};

const TransactionDetails = ({ match: { params } }) => {
  const { id } = params;
  const [transaction, setTransaction] = useState(new Transaction({}));
  const [customer, setCustomer] = useState(new Customer({}));
  const [information, setInformation] = useState({});
  const [refundRequests, setRefundRequests] = useState([]);
  const [paymentCredit, setPaymentCredit] = useState(new PaymentCredit({}));
  const [paymentEVouchers, setPaymentEVouchers] = useState([]);
  const [paymentVouchers, setPaymentVouchers] = useState([]);
  const [paymentPasses, setPaymentPasses] = useState([]);
  const [eVoucherProgram, setEVoucherProgram] = useState(null);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [showConfirmResendEmail, setShowConfirmResendEmail] = useState(false);

  const { viewChargedStatus } = useTransactionChargedStatus();

  const history = useHistory();

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "transactions",
        name: "Transaction Management",
      }),
      activeModule: new NavigationItem({
        identifier: "transaction-history",
        name: "Transaction History",
      }),
    });
  };

  const handleError = useCallback((error) => {
    toast.error(error.message);
  }, []);

  const loadTransactionInformation = useCallback(() => {
    RestClient.sendGetRequest(
      `/api/v1/transactions/${id}/information`,
      (response) => {
        response.movieInfos.forEach((x) => {
          x.id = Strings.generateRandomString();
        });

        setInformation(response);
      },
      handleError
    );
  }, [handleError, id]);

  useEffect(() => {
    if (!transaction || !transaction.emailAddress) {
      return;
    }
    RestClient.sendGetRequest(
      `/api/v1/customers/${transaction.emailAddress}`,
      (response) => {
        setCustomer(new Customer(response));
      },
      handleError
    );
  }, [handleError, transaction]);

  useEffect(() => {
    if (
      !eVoucherProgram &&
      paymentEVouchers.length > 0 &&
      paymentEVouchers[0].eVoucherProgramId
    ) {
      RestClient.sendGetRequest(
        `api/v1/evoucher_programs/${paymentEVouchers[0].eVoucherProgramId}`,
        (data) => {
          setEVoucherProgram(data);
        }
      );
    }
  }, [eVoucherProgram, paymentEVouchers]);

  useEffect(() => {
    RestClient.sendGetRequest(
      `api/v1/transactions/${id}/payment_passes`,
      (response) => {
        setPaymentPasses(response);
      },
      handleError
    );
  }, [handleError, id]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/transactions/${id}`,
      {
        includes:
          "PaymentCredits,Prints,PaymentCashes,PaymentEVouchers,PaymentPasses,Prints.PrintItems",
      },
      (data) => {
        const instance = new Transaction(data);
        setTransaction(instance);
        if (instance.refundRequests && instance.refundRequests.length > 0) {
          setRefundRequests(
            instance.refundRequests.map((x) => new RefundRequest(x))
          );
        }
        if (instance.paymentCredits && instance.paymentCredits.length > 0) {
          setPaymentCredit(new PaymentCredit(instance.paymentCredits[0]));
        }
        if (instance.paymentEVouchers && instance.paymentEVouchers.length > 0) {
          setPaymentEVouchers(instance.paymentEVouchers);
        }
        loadTransactionInformation();
      },
      handleError
    );
  }, [handleError, id, loadTransactionInformation]);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      "api/v1/refunds",
      { transactionId: id },
      (response) => {
        if (response.data.length) {
          setRefundRequests(
            response.data.map((item) => new RefundRequest(item))
          );
        }
      },
      handleError
    );
  }, [handleError, id]);

  useEffect(() => {
    RestClient.sendGetRequest(
      `api/v1/transactions/${id}/payment_vouchers`,
      (response) => {
        setPaymentVouchers(response.map((item) => new PaymentVoucher(item)));
      },
      handleError
    );
  }, [handleError, id]);

  const onViewRefund = () => {
    history.push(`/transactions/${transaction.referenceNumber}/refunds`);
  };

  const onHideConfirmResendEmail = () => {
    setShowConfirmResendEmail(false);
  };

  const onViewChargedStatus = async () => {
    const isCharged = await viewChargedStatus(id);
    if (isCharged) {
      setTransaction((prev) => ({
        ...prev,
        isCharged: isCharged,
      }));
    }
  };

  const grossAmount = (transaction.grossAmount ?? 0).toFixed(6);
  const disableViewRefund = refundRequests.length === 0 || transaction.isRefund;

  const isShowResendEmailButton =
    (transaction.salesChannel === PWSM || transaction.salesChannel === GPAY) &&
    transaction.status === TransactionStatus.Successful &&
    AuthenticationManager.isAuthorized(ResourceID.ResendConfirmationEmail);
  const createRefundButtonTooltip = getCreateRefundButtonTooltip(
    transaction,
    refundRequests,
    information
  );

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <ButtonActionDetailGroup>
            {isShowResendEmailButton && (
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                onClick={() => setShowConfirmResendEmail(true)}
              >
                Resend Email
              </ButtonActionDetailGroup.Item>
            )}
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              id="btnRefund"
              onClick={onViewRefund}
              disabled={disableViewRefund}
            >
              View Refund
            </ButtonActionDetailGroup.Item>
            {AuthenticationManager.isAuthorized(
              ResourceID.CreateRefundRequestButton
            ) && (
              <ButtonActionDetailGroup.Item
                className="btn btn-primary float-right"
                data-toggle="modal"
                title={createRefundButtonTooltip}
                data-target="#RefundRequest"
                disabled={createRefundButtonTooltip}
              >
                <i className="fas fa-edit" /> Create Refund Request
              </ButtonActionDetailGroup.Item>
            )}
            <ButtonActionDetailGroup.Item
              className="btn btn-outline-secondary"
              onClick={() => setShowHistoryDialog(true)}
            >
              History
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
          {isRefundPeriodExpired(transaction, refundRequests) && (
            <div
              className="alert alert-warning alert-dismissible fade show"
              role="alert"
            >
              This online request's period has expired.
            </div>
          )}
          <div className="row">
            <CardLayout
              id="general"
              className="col-md-6"
              bodyClassName="text-left"
              label="General Information"
            >
              <TransactionDetailGeneralInformation transaction={transaction} />
            </CardLayout>
            {transaction.salesChannel !== TPOS &&
              transaction.salesChannel !== TKOS && (
                <CardLayout
                  id="cust"
                  className="col-md-6"
                  bodyClassName="text-left"
                  label="Customer Information"
                >
                  <TransactionDetailCustomerInformation
                    customer={customer}
                    transaction={transaction}
                  />
                </CardLayout>
              )}
          </div>
          <div className="row">
            <CardLayout
              id="payment"
              bodyClassName="text-left"
              label="Payment Information"
            >
              <TransactionDetailPaymentInformation
                transaction={transaction}
                paymentCredit={paymentCredit}
                paymentVouchers={paymentVouchers}
                paymentPasses={paymentPasses}
                eVoucherProgram={eVoucherProgram}
                paymentEVouchers={paymentEVouchers}
                onViewChargedStatus={onViewChargedStatus}
              />
            </CardLayout>
          </div>
          <TransactionInformation information={information} />
        </div>
      </div>
      <TicketView transaction={transaction} information={information} />
      <RefundCreation
        transactionId={transaction.id}
        grossAmount={grossAmount}
      />
      {showConfirmResendEmail && (
        <ConfirmResendEmailDialog
          onCancel={onHideConfirmResendEmail}
          transaction={transaction}
          showConfirmResendEmail={showConfirmResendEmail}
        />
      )}
      {showHistoryDialog && (
        <HistoryDialog
          objectId={transaction.id}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.Transaction}
        />
      )}
    </div>
  );
};

export default TransactionDetails;
