import React, { useState } from "react";
import { locationsAtom } from "../CampaignState";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { CustomSelect } from "../../common/CustomSelect";

const EXCLUDED_LOCATION_CODES = ["0000"]

export const LocationHallConditionDialog = (props) => {
  const [location, setLocation] = useState({});
  const [locationVenues, setLocationVenues] = useState([]);
  const locations = useRecoilValue(locationsAtom);
  const { onConfirm, onCancel, visible } = props;

  const onInputCheckChange = (e) => {
    const locationVenueId = +e.target.value;
    if (e.target.checked) {
      setLocationVenues((venues) => {
        venues.push(locationVenueId);
        return venues;
      });
    } else {
      setLocationVenues((venues) =>
        venues.filter((x) => x !== locationVenueId)
      );
    }
  };

  const onAddClick = () => {
    onConfirm(location, locationVenues);
    setLocationVenues([]);
  };

  const onChangeLocation = (e) => {
    setLocation(locations.find((x) => x.id === e.value));
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  return (
    <>
      <div className="modal fade show" tabIndex="-1" role="dia log">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Valid for specific Location and/or Location Hall
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="row">
                <div className="col-3">
                  Location:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-8">
                  <CustomSelect
                    data={locations.filter(item => !EXCLUDED_LOCATION_CODES.includes(item.code))}
                    label="name"
                    value="id"
                    onChange={onChangeLocation}
                    selectedValue={location?.id}
                  />
                </div>
              </div>
              <div className="row  mt-2">
                <div className="col-3">
                  Location Hall:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-8">
                  {location &&
                    location.locationVenues &&
                    location.locationVenues.map((x) => (
                      <div className="" key={x.id}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="LocationCondition"
                          value={x.id}
                          onChange={onInputCheckChange}
                        />
                        <label className="form-check-label">{x.name}</label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn btn-success"
                data-dismiss="modal"
                onClick={onAddClick}
              >
                Add Condition
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
