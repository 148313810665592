import React, { useEffect, useMemo, useState } from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { ServiceTicketPanel } from "./cards/ServiceTicketPanel";
import { ServicesRestService } from "../../../services/ServicesRestService";
import { Service } from "../../../models/Service";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { useRecoilState } from "recoil";
import { ServicePanelCard } from "./cards/ServicePanelCard";
import { modeAtom, serviceAtom, useResetStatesOnDestroy } from "./state";
import { ViewMode } from "../../../constants/PricingConstants";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { useServiceTimeBased } from "./modals/useServiceTimeBased";

export const ServiceDetails = ({ match: { params } }) => {
  const { id } = params;
  const [mode, setMode] = useRecoilState(modeAtom);
  const [service, setService] = useRecoilState(serviceAtom);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const servicesRestService = useMemo(() => new ServicesRestService(), []);

  useResetStatesOnDestroy();

  useServiceTimeBased();

  useEffect(() => {
    if (!id) {
      return;
    }

    (async function () {
      const result = await servicesRestService.get(id, {
        includes: "ServiceTickets,ServiceTickets.ServiceTicketPrices",
      });
      setService(result.data);
    })();
  }, [servicesRestService, setService, id]);

  const update = async () => {
    setShowUpdateConfirmation(false);
    setMode(ViewMode.Readonly);
    const { id, ...params } = service;
    const response = await servicesRestService.put(id, params);
    setService(new Service(response.data));
    toast.success("Update service information success.");
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "service-creation",
        name: mode === ViewMode.Readonly ? "View Service" : "Edit Service",
      }),
    });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              {mode === ViewMode.Readonly &&
                AuthenticationManager.isAuthorized(
                  ResourceID.ServiceViewEditButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    onClick={() => setMode(ViewMode.Editable)}
                  >
                    <i className="fas fa-edit" aria-hidden="true" />
                    Edit
                  </ButtonActionDetailGroup.Item>
                )}
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                data-target="#HistoryPopup"
                data-toggle="modal"
                onClick={() => setShowHistoryDialog(true)}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col">
                <ServicePanelCard />
                <ServiceTicketPanel />
                {mode === ViewMode.Editable && (
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success "
                      onClick={() => setShowUpdateConfirmation(true)}
                    >
                      Update
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      onClick={() => setShowCancelConfirmation(true)}
                    >
                      Cancel
                    </ButtonActionDetailGroup.Item>
                  </ButtonActionDetailGroup>
                )}
              </div>
            </div>
          </div>
        </div>
        {showHistoryDialog && (
          <HistoryDialog
            objectId={service.id}
            onClose={() => setShowHistoryDialog(false)}
            url="/api/v1/history_logs"
            objectType={ObjectType.Service}
          />
        )}
        <ConfirmDialog
          visible={showCancelConfirmation}
          onCancel={() => setShowCancelConfirmation(false)}
          onProceed={() => {
            setMode(ViewMode.Readonly);
            setShowCancelConfirmation(false);
          }}
          title="Cancel confirmation"
        />
        <ConfirmDialog
          visible={showUpdateConfirmation}
          onCancel={() => setShowUpdateConfirmation(false)}
          onProceed={() => update()}
          title="Update confirmation"
          message="Are you sure want to update Service information?"
        />
      </div>
    </>
  );
};
