import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { PassStatus } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  formatPassStatus,
  formatPassStatusClassName,
} from "../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import PassListFilterLayout from "../common/PassListFilterLayout";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class StaffPassListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      programTypeOptions: [],
      passProgramId: 0,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();
    await this.loadProgramTypeOptions();
  }

  async loadProgramTypeOptions() {
    RestClient.sendGetRequest(
      "/api/v1/pass_programs/options?staffOnly=true",
      (response) => {
        this.setState({ programTypeOptions: response });
      }
    );
  }

  getApiPath = () => "/api/v1/passes";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "staff_passes",
        name: "Staff Passes",
      }),
    });
  };

  injectSearchTerm(queryParameters) {
    const { searchTerm, passProgramId, status } = this.state;
    let customParameters = { staffOnly: true };
    if (!StringHelper.isNullOrEmpty(searchTerm)) {
      customParameters.name = searchTerm;
      customParameters.passCodes = searchTerm;
    }
    if (passProgramId > 0) {
      customParameters.passProgramId = passProgramId;
    }
    if (!StringHelper.isNullOrEmpty(status) && status !== "0") {
      customParameters.status = StringHelper.toNumber(status, 0);
    }
    return super.injectSearchTerm(queryParameters, customParameters);
  }

  onRowClick = (id) => {
    this.props.history.push(`/staff_passes/${id}`);
  };

  generateTableFilter() {
    const { programTypeOptions, passProgramId } = this.state;
    return (
      <>
        <div className="row mb-3">
          <PassListFilterLayout
            label="Status:"
            htmlFor="Status"
            className="col-md-3"
          >
            <select
              className="form-control"
              name="status"
              id="Status"
              required
              onChange={this.onTextBoxChange}
              value={this.state.status}
            >
              <option value={PassStatus.NotSpecified}>All Status</option>
              <option value={PassStatus.Active}>Active</option>
              <option value={PassStatus.Inactive}>Deactivated</option>
              <option value={PassStatus.Expired}>Expired</option>
            </select>
          </PassListFilterLayout>
          <PassListFilterLayout
            label="Program Type:"
            htmlFor="Type"
            className="col-md-5"
          >
            <select
              className="form-control"
              name="passProgramId"
              id="Type"
              required
              onChange={this.onTextBoxChange}
              value={passProgramId}
            >
              <option value="">All Type</option>
              {programTypeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </PassListFilterLayout>
          <PassListFilterLayout.Search>
            <input
              type="text"
              id="input1-group2"
              name="searchTerm"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onTextBoxChange}
              value={this.state.searchTerm}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true"></i> Search
              </button>
            </div>
          </PassListFilterLayout.Search>
        </div>
      </>
    );
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Pass Code</th>
            <th>Name</th>
            <th>
              Program Type{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PassProgramId"
                onClick={this.toggleSort}
              />
            </th>
            <th>Start Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{item.passProgramName}</td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateFormat(
                        item.startDate
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateFormat(
                        item.expiryDate
                      )}
                    </td>
                    <td>
                      <span className={formatPassStatusClassName(item.status)}>
                        {formatPassStatus(item.status)}
                      </span>
                    </td>
                    <td>
                      {StringHelper.asDateStringToDisplayDateTimeFormat(
                        item.updatedOn
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.StaffPassCreationButtonPage
      ) && (
        <>
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item className="btn btn-primary">
              <Link
                to="/staff_passes/create"
                style={{ fontSize: "1rem", color: "white" }}
              >
                <i className="fas fa-plus-circle"></i> Create New Pass
              </Link>
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </>
      )
    );
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}
