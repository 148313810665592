import React from "react";
import Validators from "../../common/Validators";
export class BaseConfirmReasonDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmit: false,
      reason: "",
      validations: [],
      isSubmitted: false,
      placeholderText: props.placeholderText,
      reasonType: props.reasonType,
    };
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const { isSubmit, validations } = this.state;
    const isError = validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return isSubmit && isError ? "form-control is-invalid" : "form-control";
  }

  onSubmitClick = () => {
    if (!this.isValidated() && this.props.placeholderText) {
      this.setState({ isSubmitted: true });
      return;
    }
    if (this.props.onProceed) {
      this.props.onProceed(this.state.reason);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    this.setState({ [fieldName]: e.target.value });
    this.setState({ isSubmitted: true });
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      onValidationsChange([...this.state.validations]);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  render() {
    const { reason, isSubmitted, placeholderText, reasonType } = this.state;
    let { message, title } = this.props;
    message = message || "Are you sure to perform this action?";
    title = title || "Confirmation";
    return (
      <>
        <div
          className="modal fade show"
          id="cancelEvoucherOrder"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label className="form-control-label">{message}</label>
                <div className="row form-group">
                  <label
                    className="col-md-3 form-control-label"
                    htmlFor="Reason"
                  >
                    {reasonType}
                  </label>
                  {placeholderText && (
                    <div className="col-md-9">
                      <textarea
                        id="Reason"
                        rows="4"
                        placeholder={placeholderText}
                        className={this.getControlClassName("reason")}
                        fieldname="reason"
                        onChange={this.onTextBoxChange}
                        value={reason}
                      />
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldname="newExpireDate"
                        isEnabled={isSubmitted}
                        property={reasonType}
                        value={reason}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onSubmitClick}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
