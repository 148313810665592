import React from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { Movie } from "../../../models/Movie";
import { MovieService } from "../../../services/MovieService";
import _ from "lodash";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  Country,
  CrewRole,
  MovieCategory,
  MovieDisplayWeight,
  MovieOrigin,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { AddCrewModal } from "../modals/AddCrewModal";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { CustomSelect } from "../../common/CustomSelect";
import { CompanyType } from "../../../constants/CompanyType";
import { CompanyRestService } from "../../../services/CompanyRestService";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class MovieCreation extends React.Component {
  constructor() {
    super();
    this.state = {
      instance: new Movie({}),
      companies: [],
      languages: [],
      showCreatingConfirmation: false,
      showAddCrewModal: false,
      showClearConfirmation: false,
      showCancelConfirmation: false,
    };
    this.movieService = new MovieService();
    this.companyService = new CompanyRestService();
  }

  componentDidMount() {
    this.companyService
      .list({ type: CompanyType.Distributors })
      .then((response) => {
        this.setState({ companies: response.data.data });
      });

    this.movieService.getLanguages((response) => {
      this.setState({ languages: response });
    });
  }

  onSubmit = () => {
    const { instance } = this.state;
    this.movieService.create(
      { ...instance, companyId: instance.company?.id },
      (_) => {
        toast.success(`The movie has been created successful.`);
        this.props.history.push("/movies");
      }
    );
    this.setState({
      showCreatingConfirmation: false,
    });
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  onMovieCompanyChange = (e) => {
    const instance = this.state.instance;
    this.setState({
      instance: {
        ...instance,
        company: {
          ...instance.company,
          id: e.value,
        },
      },
    });
  };

  onAddCrew = (crew) => {
    const { instance } = this.state;
    const found = instance.crews.find(
      (x) => x.name === crew.name && x.role === crew.role
    );
    if (!_.isNil(found)) {
      return;
    }
    instance.crews.push(crew);
    this.setState({ instance });
  };

  onRemoveCrew = (crew) => {
    const { instance } = this.state;
    instance.crews = instance.crews.filter(
      (x) => x.name !== crew.name || x.role !== crew.role
    );
    this.setState({ instance });
  };

  renderMovieCrew = () => {
    const { instance, mode } = this.state;
    return (
      <div className="card mb-3">
        <div className="card-body Movie Crew-0">
          <div className="row mx-0 mb-3">
            <span style={{ fonSize: "20px" }}>Movie Crew</span>
            <button
              className="ml-auto btn btn-sm btn-success"
              onClick={() => this.setState({ showAddCrewModal: true })}
              data-toggle="modal"
            >
              Add
            </button>
          </div>
          {!_.isNil(instance.crews) &&
            instance.crews.map((x, index) => {
              return (
                <div key={index} className="card card-body bg-light mb-1 py-1">
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">{x.name}</label>
                    </div>
                  </div>
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew Role:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">
                        {CrewRole.getName(x.role)}
                      </label>
                    </div>
                    <div className="col-md-2 py-1">
                      {mode === Mode.Editable && (
                        <button
                          className="float-right btn btn-sm btn-danger"
                          onClick={() => this.onRemoveCrew(x)}
                        >
                          <i className="fas fa-trash-alt" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-creation",
        name: "Create Movie",
      }),
    });
  };

  render() {
    const {
      instance,
      companies,
      languages,
      showCreatingConfirmation,
      showAddCrewModal,
      showClearConfirmation,
      showCancelConfirmation,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Movie Information
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Primary Title:
                            <span className="color-red">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter Primary Title"
                            name="primaryTitle"
                            onChange={this.onInputChange}
                            value={instance.primaryTitle}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Secondary Title:
                            <span className="color-red">*</span>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter Secondary Title"
                            name="secondaryTitle"
                            onChange={this.onInputChange}
                            value={instance.secondaryTitle}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Movie Category:
                            <span className="color-red">*</span>
                          </label>
                          <select
                            className="form-control"
                            valuetype="number"
                            name="movieCategory"
                            onChange={this.onInputChange}
                            value={instance.movieCategory}
                          >
                            <option value="0">Select Movie Category</option>
                            {MovieCategory.All.map((x) => {
                              return (
                                <option key={x.value} value={x.value}>
                                  {x.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          className="col-md-6"
                          title="You may key in # 1 if the Duration is not yet final"
                        >
                          <label className="form-control-label d-flex justify-content-between">
                            <span>
                              Duration: <span className="color-red">*</span>
                            </span>
                            <i
                              class="fa fa-exclamation-circle"
                              aria-hidden="true"
                            ></i>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            min="1"
                            max="200"
                            valuetype="number"
                            name="duration"
                            onChange={this.onInputChange}
                            value={instance.duration}
                            placeholder="Enter Duration"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Movie Origin Country:
                            <span className="color-red">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="movieOriginCountry"
                            valuetype="number"
                            onChange={this.onInputChange}
                            value={instance.movieOriginCountry}
                          >
                            <option value="0">
                              Select Movie Origin Country
                            </option>
                            {Country.All.map((x) => {
                              return (
                                <option key={x.value} value={x.value}>
                                  {x.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Movie Origin:<span className="color-red">*</span>
                          </label>
                          <select
                            className="form-control"
                            value={instance.movieOrigin}
                            name="movieOrigin"
                            valuetype="number"
                            onChange={this.onInputChange}
                          >
                            <option value="0">Select Movie Origin</option>
                            {MovieOrigin.All.map((x) => {
                              return (
                                <option key={x.value} value={x.value}>
                                  {x.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Movie Distribution Company:
                            <span className="color-red">*</span>
                          </label>
                          <CustomSelect
                            label="name"
                            value="id"
                            data={companies}
                            onChange={this.onMovieCompanyChange}
                            selectedValue={instance.company.id}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Movie Language:
                            <span className="color-red">*</span>
                          </label>
                          <select
                            className="form-control"
                            name="language"
                            onChange={this.onInputChange}
                            value={
                              _.isEmpty(instance.language)
                                ? 0
                                : instance.language
                            }
                          >
                            <option value="0">Select Movie Language</option>
                            {languages.map((x) => (
                              <option key={x.id} value={x.id}>
                                {x.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Label Display:
                          </label>
                          <input
                            className="form-control"
                            name="labelDisplay"
                            onChange={this.onInputChange}
                            value={instance.labelDisplay}
                            placeholder="Enter Label Display"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Label Print:
                          </label>
                          <input
                            className="form-control"
                            name="labelPrint"
                            onChange={this.onInputChange}
                            value={instance.labelPrint}
                            placeholder="Enter print label"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Display Weight:
                            <span className="color-red">*</span>
                          </label>
                          <input
                            className="form-control"
                            type="number"
                            min={MovieDisplayWeight.Length}
                            valuetype="number"
                            name="displayWeight"
                            onChange={this.onInputChange}
                            value={instance.displayWeight}
                            max="150"
                            placeholder="Enter Display Weight"
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-control-label">
                            Website Url:
                          </label>
                          <input
                            className="form-control"
                            name="websiteUrl"
                            onChange={this.onInputChange}
                            value={instance.websiteUrl}
                            placeholder="Enter website"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-12">
                          <label className="form-check-label">Notice:</label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="notice"
                            onChange={this.onInputChange}
                            value={instance.notice}
                            placeholder="Enter notice"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-12">
                          <label className="form-check-label">
                            Short synopsis:
                          </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="shortSynopsis"
                            onChange={this.onInputChange}
                            value={instance.shortSynopsis}
                            placeholder="Enter short synopsis"
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-12">
                          <label className="form-check-label">
                            Full synopsis:
                          </label>
                          <textarea
                            className="form-control"
                            rows="5"
                            name="fullSynopsis"
                            onChange={this.onInputChange}
                            value={instance.fullSynopsis}
                            placeholder="Enter full synopsis"
                          />
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-md-6">{this.renderMovieCrew()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row justify-content-end pb-3">
                <ButtonActionDetailGroup>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    data-toggle="modal"
                    onClick={() =>
                      this.setState({ showCreatingConfirmation: true })
                    }
                    data-target="#confirmModal"
                  >
                    Create
                  </ButtonActionDetailGroup.Item>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    onClick={() =>
                      this.setState({ showClearConfirmation: true })
                    }
                  >
                    Clear
                  </ButtonActionDetailGroup.Item>
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    onClick={() =>
                      this.setState({ showCancelConfirmation: true })
                    }
                  >
                    Cancel
                  </ButtonActionDetailGroup.Item>
                </ButtonActionDetailGroup>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showCreatingConfirmation}
          title="Create Movie"
          message="Are you sure you want to create movie?"
          onCancel={() => this.setState({ showCreatingConfirmation: false })}
          onProceed={this.onSubmit}
        />
        <ConfirmDialog
          visible={showClearConfirmation}
          title="Clear Form"
          message="Are you sure you want to clear?"
          onCancel={() => this.setState({ showClearConfirmation: false })}
          onProceed={() =>
            this.setState({
              instance: new Movie(),
              showClearConfirmation: false,
            })
          }
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          title="Clear Form"
          message="Are you sure you want to cancel?"
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          onProceed={() => {
            this.setState({ showCancelConfirmation: false });
            this.props.history.push("/movies");
          }}
        />
        <AddCrewModal
          visible={showAddCrewModal}
          onCancel={() => this.setState({ showAddCrewModal: false })}
          onProceed={(crew) => this.onAddCrew(crew)}
        />
      </>
    );
  }
}
