import React from "react";
import _ from "lodash";

export const SingleSelector = (props) => {
  const {
    name,
    value,
    type,
    onChange,
    readOnly,
    disable,
    options,
    placeholder,
    enableDefaultEntry,
  } = props;

  if (!_.isNil(readOnly) && readOnly) {
    const selectedOption = options.find((x) => x.value === value);
    return (
      <input
        type="text"
        className="form-control display-input"
        value={selectedOption?.name ?? ""}
        readOnly
      />
    );
  }
  return (
    <select
      className="form-control"
      valuetype={type}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disable}
    >
      {(_.isNil(enableDefaultEntry) || enableDefaultEntry) && (
        <option value={type === "number" ? -1 : ""}>
          Select{" "}
          {!_.isNil(placeholder) && !_.isEmpty(placeholder)
            ? placeholder
            : _.capitalize(name)}
        </option>
      )}

      {options.map((x, index) => {
        return (
          <option value={x.value} key={index}>
            {x.name}
          </option>
        );
      })}
    </select>
  );
};
