import { ApplicationID } from "../common/Constants";

export class Surcharge {
  constructor(data) {
    this.id = data?.id || 0;
    this.name = data?.name ?? "";
    this.salesChannel = this.getSalesChannel(data?.appId ?? 0);
    this.surchargePrices = data?.surchargePrices || [];
    this.appId = data?.appId || '';
  }

  getSalesChannel(appId) {
    const foundAppId = ApplicationID.All.find((x) => x.value === appId);
    if (foundAppId) {
      return foundAppId.name;
    }
    return "";
  }
}