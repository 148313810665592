import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { GenericMessages, PromotionCategory } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { PromotionDetailCard } from "./cards/PromotionDetailCard";
import { PromotionPreview } from "./preview/PromotionPreview";
import { PromotionCardMovie } from "./cards/PromotionCardMovie";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class PromotionCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      promotionDetail: null,
      promotionDetailValidations: [],
      promotionMovie: null,
      promotionMovieValidations: [],
      cachesReleases: [],
      redirect: false,
      isEdit: true,
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
    };
  }

  isValidated() {
    const { promotionDetailValidations } = this.state;
    return promotionDetailValidations.every((val) => val.isValid);
  }

  onPromotionDetailModelChange = (model) => {
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const category = StringHelper.toNumber(model.category, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      category,
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ promotionDetail: newModel });
  };

  onPromotionDetailValidationsChange = (validations) => {
    this.setState({ promotionDetailValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onChangeCacheReleases = (value) => {
    this.setState({ cachesReleases: value });
  };

  onPromotionMovieEditChange = (value) => {
    this.setState({ isEdit: value });
  };

  onPromotionMovieModelChange = (model) => {
    this.setState({ promotionMovie: model });
  };

  onPromotionMovieValidationsChange = (validations) => {
    this.setState({ promotionMovieValidations: validations });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { promotionDetail, promotionMovie } = this.state;
    let movieReleaseIds = [];
    if (promotionMovie) {
      promotionMovie.promotionMovies.forEach((item) => {
        movieReleaseIds = movieReleaseIds.concat(item.selectedMovieReleases.map(x => x.id));
      });
    }
    const request = Object.assign(
      {},
      promotionDetail, { movieReleaseIds: movieReleaseIds }
    );
    RestClient.sendPostRequest(
      "/api/v1/promotions/",
      request,
      () => {
        const successMessage =
          GenericMessages.CreationSuccessfulMessage("Promotion");
        toast.success(successMessage);
        this.setState({
          redirect: true,
          showConfirmDialog: false,
        });
        this.redirectToListingPage();
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/promotions/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "promotions",
        name: "Promotions",
      }),
      activeModule: new NavigationItem({
        identifier: "promotion-creation",
        name: "Create Promotion",
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      promotionDetail,
      promotionMovie,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      promotionDetailValidations
    } = this.state;

    if (showPreview) {
      const previewModel = Object.assign(
        {},
        promotionDetail,
      );
      return (
        <>
          <PromotionPreview promotion={previewModel} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <PromotionDetailCard
                  cardMode={cardMode}
                  model={promotionDetail}
                  isSubmit={isSubmit}
                  onModelChange={this.onPromotionDetailModelChange}
                  onValidationsChange={this.onPromotionDetailValidationsChange}
                />
                {
                  promotionDetail?.category === PromotionCategory.Event &&
                  <PromotionCardMovie
                    displayStart={promotionDetail?.displayStartTime}
                    displayEnd={promotionDetail?.displayEndTime}
                    cardMode={cardMode}
                    model={promotionMovie}
                    onEditableChange={this.onPromotionMovieEditChange}
                    onChangeCacheReleases={this.onChangeCacheReleases}
                    isSubmit={isSubmit}
                    promotionDetailValidations={promotionDetailValidations}
                    onModelChange={this.onPromotionMovieModelChange}
                    onValidationsChange={this.onPromotionMovieValidationsChange}
                  />
                }

              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-outline-secondary mr-1"
                onClick={this.onShowPreview}
              >
                Preview
              </button>
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelCreationDialogMessage("promotion")}
          title={GenericMessages.CancelCreationDialogTitle("promotion")}
        />
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.ConfirmCreationDialogMessage("promotion")}
          title={GenericMessages.ConfirmCreationDialogTitle("promotion")}
        />
      </div>
    );
  }
}
