import React from "react";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { PassProgramMechanismCard } from "./cards/PassProgramMechanismCard";
import { PassProgramDetailCard } from "./cards/PassProgramDetailCard";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class PassProgramDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passProgram: null,
      passProgramId: "",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const passProgramId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || passProgramId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ passProgramId }, this.refreshData);
  }

  loadPassProgram(id) {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/pass_programs/${id}`,
      {
        includes: [
          "PassDayTimeValidities",
          "PassCompanyValidities",
          "PassLocationVenueValidities",
          "PassProgramMovieBrandAndFormatValidity",
          "PassRestrictionValidities",
          "PassSpecialDayTimeValidities",
        ].join(","),
      },
      (response) => {
        this.setState({ passProgram: response });
      },
      (error) => toast.error(error.message)
    );
  }

  refreshData() {
    const { passProgramId } = this.state;
    this.loadPassProgram(passProgramId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/pass_programs/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      parentModule: new NavigationItem({
        identifier: "staff_passes",
        name: "Staff Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "pass-program-details",
        name: "View Pass Program",
      }),
    });
  };

  render() {
    const { passProgram } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <PassProgramDetailCard defaultValue={passProgram} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <PassProgramMechanismCard defaultValue={passProgram} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
