import React from "react";
import { PageSizeSelector, Paginator } from "../../../../common/Paginator";
import { StringHelper } from "../../../../common/Helpers";
import {
  formatPassStatus,
  formatPassStatusClassName,
} from "../../common/Constants";
import { toast } from "react-toastify";
import { GenericMessages } from "../../../../common/Constants";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import { FileHelper } from "../../../../common/FileHelper";

export class PassTableSection extends React.Component {
  mergePropsWithDefault() {
    const { data, pageIndex, pageSize, total } = this.props;
    return {
      data: data || [],
      pageIndex: pageIndex || 1,
      pageSize: pageSize || 10,
      total: total || 0,
    };
  }

  onModelChange = (field, value) => {
    if (this.props.onModelChange) {
      const props = this.mergePropsWithDefault();
      const { pageIndex, pageSize } = props;
      let model = {
        pageIndex,
        pageSize,
      };
      model[field] = value;
      this.props.onModelChange(model);
    }
  };

  onPageIndexChange = (pageIndex) => {
    this.onModelChange("pageIndex", pageIndex);
  };

  onPageSizeChange = (pageSize) => {
    this.onModelChange("pageSize", pageSize);
  };

  downloadResultSearch = async () => {
    const { data } = this.props;
    const exportedData = data.map((item) => ({
      code: item.code,
      passProgramName: item.passProgramName,
      startDate: StringHelper.asDateStringToDisplayDateFormat(item.startDate),
      expiryDate: StringHelper.asDateStringToDisplayDateFormat(item.expiryDate),
      status: formatPassStatus(item.status),
    }));
    toast.success(GenericMessages.DownloadPassExtensionSuccessfullMessage);
    await FileHelper.toExcelFile(exportedData);
  };

  render() {
    const { data, pageIndex, pageSize, total } = this.mergePropsWithDefault();
    return (
      <React.Fragment>
        <div className="row mt-2">
          <div className="col">
            <div className="table-responsive table-px-15">
              <table className="table table-borderless table-striped table-earning">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }}>Pass Code</th>
                    <th>Pass Program</th>
                    <th>Pass Start Date</th>
                    <th>Pass Expiry Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.code}>
                      <td>{item.code}</td>
                      <td>{item.passProgramName}</td>
                      <td>
                        {StringHelper.asDateStringToDisplayDateFormat(
                          item.startDate
                        )}
                      </td>
                      <td>
                        {StringHelper.asDateStringToDisplayDateFormat(
                          item.expiryDate
                        )}
                      </td>
                      <td className={formatPassStatusClassName(item.status)}>
                        {formatPassStatus(item.status)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {data.length === 0 && (
          <p className="d-flex justify-content-center">No results</p>
        )}

        {data.length > 0 && (
          <div className="row justify-content-between mx-0" id="pagination">
            <div className="col-4 col-md-5 px-0" aria-label="Page nav">
              <Paginator
                onPageIndexChange={this.onPageIndexChange}
                pageIndex={pageIndex}
                pageSize={pageSize}
                total={total}
              />
            </div>
            <div className="d-inline-flex">
              <PageSizeSelector
                onPageSizeChange={this.onPageSizeChange}
                pageSize={pageSize}
                selectors={[10, 25, 50, 100]}
              />
            </div>
          </div>
        )}

        {data.length > 0 && (
          <div className="mt-3">
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                id="btnDownload"
                onClick={this.downloadResultSearch}
              >
                <i className="fa fa-download" aria-hidden="true"></i>
                &nbsp;Download Search Result
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        )}
      </React.Fragment>
    );
  }
}
