import React from "react";
import { PasswordPolicies } from "../../common/Constants";
import { StringHelper } from "../../common/Helpers";
import _ from "lodash";
import { formatDateTime, Strings } from "../../common/Common";

class ValidatorBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldName: props.fieldName || StringHelper.randomString(16),
      isEnabled: true,
      isValid: true,
    };
  }

  componentDidMount() {
    this.setState(
      { isValid: this.props.isValid },
      this.onValidationStatusChange
    );
  }

  onValidationStatusChange = () => {
    const { fieldName, isValid } = this.state;
    const { message, type } = this.props;
    if (this.props.onValidationStatusChange) {
      const e = {
        fieldName,
        isValid,
        message,
        type,
      };
      this.props.onValidationStatusChange(e);
    }
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.isValid !== nextProps.isValid) {
      this.setState(
        { isValid: nextProps.isValid },
        this.onValidationStatusChange
      );
    }
    return true;
  }

  render() {
    const { isEnabled, isValid, message } = this.props;
    const displayMode = isEnabled && !isValid ? "block" : "none";
    const className = isEnabled && !isValid ? "status--denied" : "";
    return (
      <label className={className} style={{ display: displayMode }}>
        {message}
      </label>
    );
  }
}

export class CompareValidator extends React.Component {
  getMessage(defaultMessage) {
    let message = defaultMessage || "This field value does not match.";
    if (this.props.property) {
      message = `${this.props.property} does not match.`;
    }
    return message;
  }

  isValid() {
    const { value, valueToCompare } = this.props;
    if (
      value === undefined ||
      value === null ||
      valueToCompare === undefined ||
      valueToCompare === null
    ) {
      return value === valueToCompare;
    }
    if (Array.isArray(value) && Array.isArray(valueToCompare)) {
      return (
        value.length === valueToCompare.length &&
        value.every((val) => valueToCompare.indexOf(val) > -1)
      );
    }
    return value === valueToCompare;
  }

  render() {
    let { message, ...props } = { ...this.props };
    message = this.getMessage(message);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="compare"
      />
    );
  }
}

export class DateTimeFromToValidator extends React.Component {
  getMessage(defaultMessage) {
    let message = defaultMessage || `This selected date range is invalid.`;
    const { fromProperty, toProperty, customMessageFormat } = this.props;
    if (fromProperty && toProperty) {
      message = customMessageFormat
        ? Strings.format(customMessageFormat, fromProperty, toProperty)
        : `The value in ${fromProperty} must be a time later than the ${toProperty}`;
    }
    return message;
  }

  isValid() {
    const { fromValue, toValue } = this.props;
    if (
      StringHelper.isNullOrEmpty(fromValue) ||
      StringHelper.isNullOrEmpty(toValue)
    ) {
      return true;
    }
    const fromDate = new Date(fromValue);
    const toDate = new Date(toValue);
    return fromDate.getTime() <= toDate.getTime();
  }

  render() {
    let { message, ...props } = { ...this.props };
    message = this.getMessage(message);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="datefromto"
      />
    );
  }
}

export class MaxLengthValidator extends React.Component {
  getMessage(defaultMessage, maxLength) {
    let message =
      defaultMessage || `This field must have maximum length of ${maxLength}.`;
    if (this.props.property) {
      message = `${this.props.property} must have maximum length of ${maxLength}.`;
    }
    return message;
  }

  isValid() {
    const { maxLength, value } = this.props;
    if (value === undefined || value === null) {
      return true;
    }
    const maxLengthInt = StringHelper.toNumber(maxLength, 0);
    return typeof value === "string" && value.length <= maxLengthInt;
  }

  render() {
    let { message, maxLength, ...props } = { ...this.props };
    message = this.getMessage(message, maxLength);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class MinLengthValidator extends React.Component {
  getMessage(defaultMessage, minLength) {
    let message =
      defaultMessage || `This field must have minimum length of ${minLength}.`;
    if (this.props.property) {
      message = `${this.props.property} must have minimum length of ${minLength}.`;
    }
    return message;
  }

  isValid() {
    const { minLength, value } = this.props;
    if (value === undefined || value === null) {
      return true;
    }
    const minLengthInt = StringHelper.toNumber(minLength, 0);
    return typeof value === "string" && value.length >= minLengthInt;
  }

  render() {
    let { message, minLength, ...props } = { ...this.props };
    message = this.getMessage(message, minLength);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class PasswordValidator extends React.Component {
  getMessage(defaultMessage) {
    let message =
      defaultMessage ||
      `Password must has a length of ${PasswordPolicies.MinLength}-${PasswordPolicies.MaxLength} and contain alphanumeric characters with upper and lower case.`;
    if (this.props.property) {
      message = `${this.props.property} must has a length of ${PasswordPolicies.MinLength}-${PasswordPolicies.MaxLength} and contain alphanumeric characters with upper and lower case.`;
    }
    return message;
  }

  isValid() {
    const { value } = this.props;
    if (value === undefined || value === null) {
      return true;
    }
    if (StringHelper.isNullOrEmpty(value)) {
      return true;
    }
    return StringHelper.isPasswordValid(value);
  }

  render() {
    let { message, ...props } = { ...this.props };
    message = this.getMessage(message);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="password"
      />
    );
  }
}

export class RangeValidator extends React.Component {
  getMessage(defaultMessage, min, max) {
    const { allowDecimal } = this.props;
    let message =
      defaultMessage || `This field must have value in range of ${min}-${max}`;
    if (this.props.property) {
      message = `${this.props.property} must have value in range of ${min}-${max}`;
    }

    if (allowDecimal) {
      message += ".";
    } else {
      message += ", decimal numbers are not allowed.";
    }
    return message;
  }

  isValid() {
    const { max, min, value, allowDecimal } = this.props;
    if (value === undefined || value === null) {
      return true;
    }
    const minInt = StringHelper.toNumber(min, 0);
    const maxInt = StringHelper.toNumber(max, 0);
    const isValidRange = !isNaN(value) && value >= minInt && value <= maxInt;

    if (!allowDecimal && value % 1 !== 0) {
      return false;
    }
    
    return isValidRange;
  }

  render() {
    let { message, min, max, ...props } = { ...this.props };
    message = this.getMessage(message, min, max);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class RangeDateValidator extends React.Component {
  getMessage(defaultMessage, minDay, maxDay) {
    const formattedMinDate = formatDateTime(minDay);
    const formattedMaxDate = formatDateTime(maxDay);
    const { property } = this.props;
    let message =
      defaultMessage ||
      `This field must have value in range of ${formattedMinDate} to ${formattedMaxDate}.`;
    if (property) {
      message = `${property} must have value in range of ${formattedMinDate} to ${formattedMaxDate}.`;
    }
    return message;
  }

  isValid() {
    const { minDay, maxDay, value } = this.props;
    if (!minDay || !maxDay || !value) {
      return true;
    }
    const minInt = new Date(minDay).getTime();
    const maxInt = new Date(maxDay).getTime();
    const valueInt = new Date(value).getTime();
    return !isNaN(valueInt) && valueInt >= minInt && valueInt <= maxInt;
  }

  render() {
    let { message, minDay, maxDay, ...props } = { ...this.props };
    message = this.getMessage(message, minDay, maxDay);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class CompareDateValidator extends React.Component {
  getMessage(defaultMessage, startDate, endDate) {
    return defaultMessage || `Start date must be less than End date.`;
  }

  isValid() {
    const { startDate, endDate, value } = this.props;
    if (_.isNil(value) || _.isEmpty(value)) {
      return true;
    }

    if (_.isNil(endDate) || _.isEmpty(endDate)) {
      return true;
    }
    return (
      !isNaN(Date.parse(startDate)) &&
      Date.parse(startDate) <= Date.parse(endDate)
    );
  }

  render() {
    let { message, startDate, endDate, ...props } = { ...this.props };
    message = this.getMessage(message, startDate, endDate);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class RequiredValidator extends React.Component {
  getMessage(defaultMessage) {
    let message = defaultMessage || "This field is required.";
    if (this.props.property) {
      message = `${this.props.property} is required.`;
    }
    return message;
  }

  isValid() {
    const { value } = this.props;
    if (value === undefined || value === null) {
      return false;
    }
    if (typeof value === "string" && value === "") {
      return false;
    }
    if (typeof value === "boolean") {
      return value;
    }
    return !(Array.isArray(value) && value.length === 0);
  }

  render() {
    let { message, ...props } = { ...this.props };
    message = this.getMessage(message);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class DuplicateValidator extends React.Component {
  getMessage(defaultMessage, property) {
    return defaultMessage || `${property} was added in list.`;
  }

  isValid() {
    const { data, objectKey, value, checked } = this.props;
    if (
      _.isNil(objectKey) ||
      objectKey < 0 ||
      _.isNil(value) ||
      value < 0 ||
      data.length === 0 ||
      !checked
    ) {
      return true;
    }

    if (Object.keys(data).includes(objectKey + "")) {
      const list = data[objectKey];
      return !list.includes(value);
    }

    return true;
  }

  render() {
    let { message, property, ...props } = { ...this.props };
    message = this.getMessage(message, property);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

export class ValidUrlValidator extends React.Component {
  getMessage(defaultMessage) {
    let message = defaultMessage || "This field must have valid url.";
    if (this.props.property) {
      message = `${this.props.property} must have valid url.`;
    }
    return message;
  }

  isValid() {
    const { value } = this.props;
    if (value === undefined || value === null) {
      return true;
    }
    return StringHelper.isValidUrl(value);
  }

  render() {
    let { message, ...props } = { ...this.props };
    message = this.getMessage(message);
    return (
      <ValidatorBase
        isValid={this.isValid()}
        message={message}
        {...props}
        type="required"
      />
    );
  }
}

const Validators = {
  CompareValidator: CompareValidator,
  DateTimeFromToValidator: DateTimeFromToValidator,
  MaxLengthValidator: MaxLengthValidator,
  PasswordValidator: PasswordValidator,
  RangeValidator: RangeValidator,
  RangeDateValidator: RangeDateValidator,
  CompareDateValidator: CompareDateValidator,
  RequiredValidator: RequiredValidator,
  DuplicateValidator: DuplicateValidator,
  MinLengthValidator: MinLengthValidator,
  ValidUrlValidator: ValidUrlValidator,
};

export default Validators;
