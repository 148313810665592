import React, { useState } from "react";
import RestClient from "../../../../common/RestClient";
import { CorporateClient } from "../../../../models/CorporateClient";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import _ from "lodash";
import { toast } from "react-toastify";
import { CompanyType } from "../../../../constants/CompanyType";

export const NewClientModal = (props) => {
  const [company, setCompany] = useState(new CorporateClient());
  const { visible, onCancel, onSelect } = props;

  const onAdd = () => {
    const body = {
      ...company,
      description: "Company description",
      currentUser: AuthenticationManager.username(),
    };
    RestClient.sendPostRequest(
      `api/v1/corporate_booking_companies`,
      body,
      (response) => {
        if (onCancel) {
          onCancel();
        }
        if (onSelect) {
          onSelect(response);
        }
      },
      handleError
    );
  };

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;

    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    company[fieldName] = selectValue;
    setCompany(_.cloneDeep(company));
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        id="addCustomer"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px", overflowY: "scroll" }}
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add New Client</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row form-group">
                  <label className="form-control-label">Code:</label>
                  <input
                    className="form-control"
                    name="code"
                    onChange={onInputChange}
                    placeholder="Enter Company Code"
                  />
                </div>
                <div className="row form-group">
                  <label className="form-control-label">Name:</label>
                  <input
                    className="form-control"
                    name="name"
                    onChange={onInputChange}
                    placeholder="Enter Company Name"
                  />
                </div>
                <div className="row form-group">
                  <label className="form-control-label" htmlFor="CompType">
                    Type:
                  </label>
                  <select
                    className="form-control"
                    name="companyType"
                    valuetype="number"
                    onChange={onInputChange}
                  >
                    <option value={0}>Select Type</option>
                    {CompanyType.All.map((x) => (
                      <option key={x.value} value={x.value}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="row form-group">
                  <label className="form-control-label" htmlFor="cust">
                    Client Name:
                  </label>
                  <input
                    className="form-control"
                    name="clientName"
                    placeholder="Enter Client Name"
                    onChange={onInputChange}
                  />
                </div>
                <div className="row form-group">
                  <label className="form-control-label" htmlFor="contact">
                    Contact Number:
                  </label>
                  <input
                    className="form-control"
                    name="contactNumber"
                    type="tel"
                    placeholder="Enter Contact Number"
                    onChange={onInputChange}
                  />
                </div>
                <div className="row form-group">
                  <label className="form-control-label" htmlFor="custemail">
                    Email:
                  </label>
                  <input
                    className="form-control"
                    name="emailAddress"
                    type="email"
                    inputMode="email"
                    placeholder="Enter Email Address"
                    onChange={onInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={onAdd}>
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
