import React from 'react';

const ButtonActionDetailGroup = (props) => {
	return (
		<div className="d-flex flex-column flex-md-row justify-content-end">
			{props.children}
		</div>
	)
}

const Button = (props) => {
	const className = `${props.className || ''} mr-1 mb-1`
	return <button {...props} className={className} />
}

ButtonActionDetailGroup.Item = Button;

export default ButtonActionDetailGroup;