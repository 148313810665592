import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingCampaignsAtom } from "./Report.state";

const QUERY_KEY_REPORTING_CAMPAIGNS = "GET_REPORTING_CAMPAIGNS";

export function useReportingCampaigns() {
  const [campaigns, setCampaigns] = useRecoilState(reportingCampaignsAtom);
  useQuery(
    QUERY_KEY_REPORTING_CAMPAIGNS,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/campaigns"
      ).list();
    },
    {
      onSuccess: (response) => setCampaigns(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return campaigns;
}
