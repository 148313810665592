import { useQuery } from "react-query";
import { RestService } from "../../services/RestService";
import { useRecoilState } from "recoil";
import { reportingLocationsAtom } from "./Report.state";

const QUERY_KEY_REPORTING_LOCATIONS = "GET_REPORTING_LOCATIONS";

export function useReportingLocations() {
  const [locations, setLocations] = useRecoilState(reportingLocationsAtom);
  useQuery(
    QUERY_KEY_REPORTING_LOCATIONS,
    () => {
      return new RestService(
        "/api/v1/report_parameter_values/locations"
      ).list();
    },
    {
      onSuccess: (response) => setLocations(response.data),
      staleTime: 60 * 60 * 1000,
    }
  );
  return locations;
}
