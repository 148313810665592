export class EvoucherOrderMode {
  static View = 0;
  static Create = 1;
  static Update = 2;

  static isEditMode(mode) {
    return (
      mode === EvoucherOrderMode.Create || mode === EvoucherOrderMode.Update
    );
  }

  static isViewMode(mode) {
    return mode === EvoucherOrderMode.View;
  }
}
