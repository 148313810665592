import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../common/BreadCrumb";
import { Campaign } from "../../models/Campaign";
import {
  CampaignCategory,
  CampaignRestriction,
  DayOfWeek,
  RedemptionQuantityType,
} from "../../constants/CampaignConstants";
import _ from "lodash";
import RestClient from "../../common/RestClient";
import { TextInput } from "./TextInput";
import { TextAreaInput } from "./TextAreaInput";
import { FieldWrapper } from "./FieldWrapper";
import { DateTimeInput } from "./DateTimeInput";
import { NumberInput } from "./NumberInput";
import { SingleSelector } from "./SingleSelector";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { PreviewImageDialog } from "../common/PreviewImageDialog";
import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { CheckboxInput } from "./CheckboxInput";
import { useHistory } from "react-router-dom";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";
import { Card } from "../common/Card";
import { AddPosterModal } from "./AddPosterModal";
import Validators from "../common/Validators";

const CAMPAIGN_CODE_PREFIX = "CA-";

export const CampaignCreation = () => {
  const [instance, setInstance] = useState(
    new Campaign({
      code: CAMPAIGN_CODE_PREFIX,
    })
  );
  const [showPreview, setShowPreview] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [validations, setValidations] = useState([]);
  const [showAddingPosterModal, setShowAddingPosterModal] = useState(false);
  const history = useHistory();

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setInstance((campaign) => {
      return {
        ...campaign,
        [fieldName]: selectValue,
      };
    });
  };

  useEffect(() => {
    setInstance((campaign) => ({
      ...campaign,
      displayName: campaign.name,
    }));
  }, [instance.name]);

  const isValidated = () => {
    return validations.every((val) => val.isValid);
  };

  const onValidationStatusChange = (e) => {
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    setValidations([...validations]);
  };

  const onCloseAllModals = () => {
    setShowCancelConfirmation(false);
    setShowCreateConfirmation(false);
    setShowPreview(false);
    setShowAddingPosterModal(false);
  };

  const onClickCreate = () => {
    if (!isValidated()) {
      toast.error(validations[0].message);
      return;
    }
    setShowCreateConfirmation(true);
  };

  const onCreate = () => {
    const body = {
      ...instance,
    };
    RestClient.sendPostRequest(
      "/api/v1/campaigns",
      body,
      (response) => {
        history.push(`/campaigns/${response.id}`);
        toast.success("The campaign has been created successful.");
      },
      handleError
    );
    onCloseAllModals();
  };

  const renderBottomButtons = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-primary"
          onClick={() => onClickCreate()}
        >
          Create
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const validateCode = () => {
    const campaignCode = instance.code;
    if (
      !campaignCode.startsWith(CAMPAIGN_CODE_PREFIX) ||
      campaignCode === CAMPAIGN_CODE_PREFIX
    ) {
      toast.error(
        "Invalid campaign code! Must start with 'CA-' and be unique."
      );
      return;
    }
    setShowAddingPosterModal(true);
  };

  const onAddMedia = (imageURL) => {
    setInstance((campaign) => ({
      ...campaign,
      posterUrl: imageURL,
    }));
    onCloseAllModals();
  };

  const onEPromotionCodeChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const selectValue = e.target.checked;
    const ePromotionCode = selectValue ? instance.ePromotionCode : "";
    setInstance((inst) => {
      return {
        ...inst,
        [fieldName]: selectValue,
        ePromotionCode: ePromotionCode,
      };
    });
  };

  const renderCampaignDetails = () => {
    return (
      <div className="row">
        <div className="col">
          <Card title="Campaign Details">
            <div className=" row">
              <div className="col-md-5 mb-3" style={{ marginTop: "auto" }}>
                <label>
                  Campaign Name<span style={{ color: "red" }}>*</span>
                </label>
                <TextInput
                  name="name"
                  value={instance.name}
                  onChange={onInputChange}
                  placeholder="Enter Campaign Name"
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-5 mb-3">
                <label>
                  Campaign Duration<span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex flex-row">
                  <div className="col" style={{ paddingLeft: "0" }}>
                    <label>
                      <i style={{ fontSize: "0.8rem" }}>Start On</i>
                    </label>
                    <DateTimeInput
                      name="startOn"
                      value={instance.startOn}
                      onChange={onInputChange}
                      placeholder="Enter Start On"
                    />
                  </div>
                  <div className="col">
                    <label>
                      <i style={{ fontSize: "0.8rem" }}>End On</i>
                    </label>
                    <DateTimeInput
                      name="endOn"
                      value={instance.endOn}
                      onChange={onInputChange}
                      placeholder="Enter End On"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className=" row">
              <div className="col-md-5 mb-3">
                <label>Campaign Description</label>
                <TextAreaInput
                  name="description"
                  value={instance.description}
                  onChange={onInputChange}
                  placeholder="Enter Description"
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-5 mb-3">
                <label>
                  Terms &amp; Conditions<span style={{ color: "red" }}>*</span>
                </label>
                <TextAreaInput
                  name="termCondition"
                  value={instance.termCondition}
                  onChange={onInputChange}
                  placeholder="Enter Terms & Condition"
                />
              </div>
            </div>

            <div className=" row">
              <div className="col-md-5 mb-3">
                <label>
                  Campaign Category<span style={{ color: "red" }}>*</span>
                </label>
                <SingleSelector
                  type="number"
                  name="category"
                  onChange={onInputChange}
                  value={instance.category}
                  options={CampaignCategory.All}
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-5 mb-3">
                <label>Restriction</label>
                <SingleSelector
                  type="number"
                  name="restriction"
                  enableDefaultEntry={false}
                  onChange={onInputChange}
                  value={instance.restriction}
                  options={CampaignRestriction.All}
                />
              </div>
            </div>

            <div className=" row">
              <div className="col-md-5 mb-3">
                <label>
                  Display Weight<span style={{ color: "red" }}>*</span>
                </label>
                <NumberInput
                  name="displayWeight"
                  value={instance.displayWeight}
                  onChange={onInputChange}
                  placeholder="Enter Display Weight"
                  min={1}
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-5 mb-3">
                <label>
                  Campaign Code<span style={{ color: "red" }}>*</span>
                </label>
                <TextInput
                  name="code"
                  value={instance.code}
                  onChange={onInputChange}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={onValidationStatusChange}
                  fieldName="code"
                  isEnabled={false}
                  property="Code"
                  value={instance.code}
                ></Validators.RequiredValidator>
              </div>
            </div>
            <div className=" row">
              <div className="col-md-5 mb-3">
                <label>
                  Max Redemption Quantity Per Transaction
                  <span style={{ color: "red" }}>*</span>
                </label>
                <NumberInput
                  name="maxRedemptionQuantityPerTransaction"
                  onChange={onInputChange}
                  value={instance.maxRedemptionQuantityPerTransaction}
                />
              </div>
              <div className="col-md-1 mb-3"></div>
              <div className="col-md-5 mb-3">
                <label>
                  Poster URL<span style={{ color: "red" }}>*</span>
                </label>
                <div className="d-flex flex-row">
                  <TextInput
                    name="posterUrl"
                    value={instance.posterUrl}
                    onChange={onInputChange}
                    placeholder="Enter Poster URL"
                    additionalCssClasses="mr-3"
                  />
                  <button
                    className="btn btn-primary float-right mt-1 mt-md-0 mr-3"
                    onClick={() => validateCode()}
                  >
                    Upload
                  </button>
                  <button
                    className="btn btn-primary float-right mt-1 mt-md-0"
                    onClick={() => setShowPreview(true)}
                    disabled={_.isEmpty(instance.posterUrl)}
                  >
                    Preview
                  </button>
                </div>
              </div>
            </div>

            <div className="row">
              <FieldWrapper
                label="ePromo Code"
                input={
                  <div className="d-flex gap-12">
                    <input
                      name="hasEPromotionCode"
                      valuetype="boolean"
                      value={instance.hasEPromotionCode}
                      onChange={onEPromotionCodeChange}
                      checked={instance.hasEPromotionCode}
                      type="checkbox"
                    />
                    <TextInput
                      name="ePromotionCode"
                      value={instance.ePromotionCode}
                      onChange={onInputChange}
                      placeholder="Enter Promotion Code"
                      disable={!instance.hasEPromotionCode}
                    />
                  </div>
                }
                additionalCss="d-none"
              />

              <FieldWrapper
                label="Is Stack Campaign"
                input={
                  <CheckboxInput
                    wrapperClass="pl-0"
                    name="isStackCampaign"
                    value={instance.isStackCampaign}
                    onChange={onInputChange}
                  />
                }
                additionalCss="d-none"
              />

              <FieldWrapper
                label="Display Name"
                require={true}
                input={
                  <TextInput
                    name="displayName"
                    value={instance.displayName}
                    onChange={onInputChange}
                    placeholder="Enter Display Name"
                  />
                }
                additionalCss="d-none"
              />
            </div>

            <div className=" row">
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Redemption Quantity Per Card"
                    require={true}
                    input={
                      <NumberInput
                        name="loyaltyRedemptionQuantityPerCard"
                        onChange={onInputChange}
                        value={instance.loyaltyRedemptionQuantityPerCard}
                      />
                    }
                  />
                </div>
              )}
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Loyalty Program"
                    require={true}
                    input={
                      <TextInput
                        name="loyaltyProgram"
                        onChange={onInputChange}
                        value={instance.loyaltyProgram}
                        placeholder="Enter Loyalty Program"
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div className=" row">
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Redemption Quantity Type"
                    require={true}
                    input={
                      <SingleSelector
                        type="number"
                        name="loyaltyRedemptionQuantityType"
                        onChange={onInputChange}
                        value={instance.loyaltyRedemptionQuantityType}
                        options={RedemptionQuantityType.All}
                        placeholder="Redemption Quantity Type"
                      />
                    }
                  />
                </div>
              )}
              {instance.loyaltyRedemptionQuantityType ===
                RedemptionQuantityType.Weekly && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Reset Day"
                    require={true}
                    input={
                      <SingleSelector
                        type="number"
                        name="loyaltyCardResetDay"
                        onChange={onInputChange}
                        value={instance.loyaltyCardResetDay}
                        options={DayOfWeek.All}
                        placeholder="Reset Day"
                      />
                    }
                  />
                </div>
              )}
            </div>
          </Card>

          {showAddingPosterModal && (
            <AddPosterModal
              onCancel={() => setShowAddingPosterModal(false)}
              campaign={instance}
              onProceed={onAddMedia}
            />
          )}
        </div>
      </div>
    );
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "campaigns",
        name: "Campaigns",
      }),
      activeModule: new NavigationItem({
        identifier: "campaigns",
        name: "Create Campaign",
      }),
    });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {renderCampaignDetails()}
            {renderBottomButtons()}
          </div>
        </div>
      </div>
      <PreviewImageDialog
        url={instance.posterUrl}
        visible={showPreview}
        onClose={() => setShowPreview(false)}
      />
      <ConfirmDialog
        visible={showCreateConfirmation}
        message="Are you sure you want to create?"
        onProceed={onCreate}
        onCancel={() => setShowCreateConfirmation(false)}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        message="Are you sure you want to cancel?"
        onProceed={() => history.push("/campaigns")}
        onCancel={() => setShowCancelConfirmation(false)}
      />
    </>
  );
};
