import { TransactionRestService } from "../services/TransactionRestService";
import { toast } from "react-toastify";
import { PaymentStatus } from "../constants/TransactionConstants";
import { StringHelper } from "../common/Helpers";
import { isEmpty } from "lodash";

export const useTransactionChargedStatus = () => {
  const viewChargedStatus = async (id) => {
    const data = await new TransactionRestService().getChargedStatus(id);
    const {
      isCharged,
      maskedCreditCardNumber,
      paymentStatus,
      refundedDateTime,
      refundedBy,
    } = data;
    if (isCharged) {
      toast.success(
        isEmpty(maskedCreditCardNumber)
          ? "Transaction got charged."
          : `Transaction got charged with ${maskedCreditCardNumber} card number.`
      );
    } else {
      let message;
      switch (paymentStatus) {
        case PaymentStatus.getName(PaymentStatus.Voided):
          message = "Transaction has been voided";
          break;
        case PaymentStatus.getName(PaymentStatus.Refunded):
          const refundedOn =
            StringHelper.asDateStringToDisplayDateTimeFormat(refundedDateTime);
          message = `Transaction has been refunded on ${refundedOn} by ${refundedBy}`;
          break;
        default:
          message = `Transaction was not charged with ${maskedCreditCardNumber} card number.`;
      }
      toast.warning(message);
    }
    return isCharged;
  };
  return { viewChargedStatus };
};
