import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings
} from "../../common/NavigationSettings";
import { CustomContentType, CustomContentStatus, StatusColor } from "../../../common/Constants";

export class CustomContentListing extends BaseListing {
  getApiPath = () => "/api/v1/custom_contents";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "custom_contents",
        name: "Custom Contents",
      }),
    });
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Page Code</th>
            <th>Type</th>
            <th>Title</th>
            <th>Status</th>
            <th>Updated By</th>
            <th className="pointer" onClick={this.onColumnClick}>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>
                    {item.code}
                  </td>
                  <td>
                    {CustomContentType.getCustomContentType(item.isPage ? CustomContentType.Page : CustomContentType.Content)}
                  </td>
                  <td>
                    {item.title}
                  </td>
                  <td className={item.isActive ? StatusColor.Green : StatusColor.Red}>
                    {CustomContentStatus.getCustomContentStatus(item.isActive ? CustomContentStatus.Active : CustomContentStatus.Inactive)}
                  </td>
                  <td>
                    {item.updatedBy}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </>
    );
  }
}