import React, { Component } from "react";
import '../css/documentation.scss';

export class BlockEvoucher extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Block eVouchers</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 2 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    To block eVouchers, click a record with the <strong>Active</strong> status found in the eVoucher listing page. Navigate to this page with the left navbar.<br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/eVoucher.png" class="userImage" /><br /><br />
                                    <img src="/img/documentation-img/evouchers/ExtendeVoucherValid/eVoucherListing.png" class="userImage" />

                                </li>
                                <li class="createList">
                                    After selecting a record, you will be redirected to the View eVoucher details page. To block the eVoucher, click the ‘Block’ button found in the top right of the page.<br />
                                    <img src="/img/documentation-img/evouchers/BlockeVoucher/Blockbtn.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    Upon clicking the button, the following prompt will appear. To block, you are requested to fill in the ‘Reason for Blocking’ field. After that, click ‘Confirm’.<br />
                                    <img src="/img/documentation-img/evouchers/BlockeVoucher/blockprompt.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    If the action was successful, the 'Block' button will turn into an 'Unblock' button, and the status will be updated:<br />
                                    <img src="/img/documentation-img/evouchers/BlockeVoucher/unblockbtn.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    To unblock, you can click the 'Unblock' button and the status will be returned to an <strong>Active</strong> state and the button will be retun to 'Block'.<br />
                                    <img src="/img/documentation-img/evouchers/BlockeVoucher/Blockbtn.png" class="userImage" />

                                </li>

                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/extendEvoucherValidity" className="btn btn-primary">&laquo; Extend EVoucher Validity</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
