import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { BreadCrumb } from "../../common/BreadCrumb";
import { AllowedTiming } from "../../../constants/PricingConstants";
import { SpecialPriceService } from "../../../services/SpecialPriceService";
import { SpecialPrice } from "../../../models/SpecialPrice";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import { LocationBrand } from "../../../constants/MovieConstants";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { DateTimeInput } from "../../campaigns/DateTimeInput";
import { useServices } from "../../../hooks/useServices";
import { CustomSelect } from "../../common/CustomSelect";
import RestClient from "../../../common/RestClient";
import { useHistory } from "react-router-dom";
import { useLocationVenues } from "../../../hooks/useLocationVenues";

export const SpecialPriceCreation = () => {
  const [specialPrice, setSpecialPrice] = useState(new SpecialPrice({}));
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const specialPriceService = useMemo(() => new SpecialPriceService(), []);
  const { services } = useServices();
  const { locationVenues } = useLocationVenues();
  const [movieReleases, setMovieReleases] = useState([]);
  const history = useHistory();

  useEffect(() => {
    RestClient.sendGetRequest(
      "/api/v1/movie_releases/options",
      (response) => {
        setMovieReleases(response.data);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  const onSelectChange = (e, name) => {
    const value = e.value;
    setSpecialPrice((data) => {
      return {
        ...data,
        [name]: value,
      };
    });
  };

  const onShowConfirmDialog = () => {
    setShowConfirmModal(true);
  };

  const onProcessCancelConfirmation = () => {
    setShowCancelConfirmation(false);
  };

  const onProceedCreateConfirmation = () => {
    setShowConfirmModal(false);
    specialPriceService.post(specialPrice).then((response) => {
      const { ...results } = response.data;
      history.push(`/special_prices/${response.data.id}`);
      setSpecialPrice((prev) => ({
        ...prev,
        ...results,
      }));
      toast.success("Create special price success");
    });
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setSpecialPrice((specialPrice) => {
      return {
        ...specialPrice,
        [fieldName]: selectValue,
      };
    });
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "special-price-creation",
        name: "Create Special Price",
      }),
    });
  };

  if (!specialPrice) return <></>;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Create Special Price
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row form-group">
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Movie Release"
                            require={true}
                            input={
                              <CustomSelect
                                data={movieReleases}
                                label="name"
                                value="id"
                                onChange={(e) =>
                                  onSelectChange(e, "movieReleaseId")
                                }
                                selectedValue={specialPrice.movieReleaseId}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group my-0">
                            <div className="col-md-4">
                              <label className="form-control-label">
                                <i>
                                  Services
                                  <span className="color-red font-weight-bold">
                                    *
                                  </span>
                                  :
                                </i>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <CustomSelect
                                data={services}
                                label="name"
                                value="id"
                                onChange={(e) => onSelectChange(e, "serviceId")}
                                selectedValue={specialPrice.serviceId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <FieldWrapper
                            label="Location Venue"
                            input={
                              <CustomSelect
                                data={locationVenues}
                                label="name"
                                value="id"
                                onChange={(e) =>
                                  onSelectChange(e, "locationVenueId")
                                }
                                selectedValue={specialPrice.locationVenueId}
                                allowEmpty={true}
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="End On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="endOn"
                                value={specialPrice.endOn}
                                onChange={onInputChange}
                                placeholder="Enter End On"
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Start On"
                            require={true}
                            input={
                              <DateTimeInput
                                name="startOn"
                                value={specialPrice.startOn}
                                onChange={onInputChange}
                                placeholder="Enter Start On"
                              />
                            }
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Allowed Timing"
                            input={
                              <CustomSelect
                                data={AllowedTiming.All}
                                label="name"
                                value="value"
                                onChange={(e) =>
                                  onSelectChange(e, "allowedTiming")
                                }
                                selectedValue={specialPrice.allowedTiming}
                              />
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <FieldWrapper
                            label="Allowed Location Brands"
                            input={
                              <CustomSelect
                                data={LocationBrand.All}
                                label="name"
                                value="value"
                                onChange={(e) =>
                                  onSelectChange(e, "allowedLocationBrands")
                                }
                                selectedValue={
                                  specialPrice.allowedLocationBrands
                                }
                              />
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-end pb-3">
                <button
                  className="btn btn-success mr-1"
                  onClick={onShowConfirmDialog}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showCancelConfirmation}
        onCancel={() => onProcessCancelConfirmation(false)}
        onProceed={onProceedCreateConfirmation}
        title="Cancel confirmation"
      />
      <ConfirmDialog
        visible={showConfirmModal}
        onCancel={() => onProcessCancelConfirmation(false)}
        onProceed={onProceedCreateConfirmation}
        title="Create confirmation"
        message="Are you sure want to create special price?"
      />
    </>
  );
};
