import React, { useEffect, useState } from "react";

const maxVisiblePage = 10;
export const Paginator = (props) => {
  const [activePage, setActivePage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [endPage, setEndPage] = useState(1);
  const [maxNumOfPage, setMaxNumOfPage] = useState(1);
  const { pageSize, total, onPageIndexChange } = props;
  useEffect(() => {
    if (total <= 0) {
      return;
    }
    const pageCount = Math.ceil(total / pageSize);
    setMaxNumOfPage(pageCount);
    setStartPage(1);
    setEndPage(pageCount > maxVisiblePage ? maxVisiblePage : pageCount);
  }, [total, pageSize]);

  useEffect(() => {
    if (startPage <= activePage && activePage <= endPage) {
      return;
    }
    let newStartPage = startPage;
    if (activePage < startPage) {
      newStartPage = newStartPage - maxVisiblePage;
    } else if (activePage > endPage) {
      newStartPage = activePage;
    }
    const newEndPage = newStartPage + maxVisiblePage - 1;
    setStartPage(newStartPage);
    setEndPage(newEndPage > maxNumOfPage ? maxNumOfPage : newEndPage);
  }, [activePage, endPage, maxNumOfPage, startPage]);

  useEffect(() => {
    if (activePage !== props.pageIndex) {
      setActivePage(props.pageIndex);
    }
  }, [props.pageIndex]);

  const createPagesIndexes = () => {
    let pages = [];
    for (let index = startPage; index <= endPage; index++) {
      pages.push(index);
    }
    return pages;
  };

  const onPageClick = (e, pageIndex) => {
    e.preventDefault();
    if (pageIndex < 1 || pageIndex > maxNumOfPage) {
      return;
    }
    setActivePage(pageIndex);

    if (onPageIndexChange) {
      onPageIndexChange(pageIndex);
    }
  };

  const getIndexOfGroup = () => Math.ceil(activePage / maxVisiblePage);

  const onNextPageGroup = (e) => {
    e.preventDefault();
    let indexOfGroup = getIndexOfGroup();
    let newActivePage = indexOfGroup * maxVisiblePage + 1;
    if (newActivePage > maxNumOfPage) {
      newActivePage = maxNumOfPage;
    }
    setActivePage(newActivePage);
  };

  const onPreviousPageGroup = (e) => {
    e.preventDefault();
    let indexOfGroup = getIndexOfGroup();
    let newActivePage = (indexOfGroup - 2) * maxVisiblePage + 1;
    if (newActivePage < 1) {
      newActivePage = 1;
    }
    setActivePage(newActivePage);
  };

  const showNextPageGroup = () => {
    let indexOfGroup = getIndexOfGroup();
    let newActivePage = indexOfGroup * maxVisiblePage;
    if (newActivePage > maxNumOfPage) {
      newActivePage = maxNumOfPage;
    }
    return newActivePage < maxNumOfPage;
  };

  const showPreviousPageGroup = () => {
    let indexOfGroup = getIndexOfGroup();
    let newActivePage = (indexOfGroup - 1) * maxVisiblePage;
    return newActivePage > 1;
  };

  return (
    <>
      <ul className="pagination d-none d-md-flex">
        <li className="page-item">
          <a
            className="page-link"
            aria-label="Previous"
            href="#"
            onClick={(e) => onPageClick(e, activePage - 1)}
          >
            <span aria-hidden="true">«</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {showPreviousPageGroup() && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={onPreviousPageGroup}>
              <span> &hellip;</span>
            </a>
          </li>
        )}
        {createPagesIndexes().map((page) => (
          <li
            style={{ minWidth: "40px", minHeight: "40px" }}
            key={page}
            className={`page-item ${page === activePage ? "active" : ""}`}
          >
            <a
              className="page-link"
              style={{ textAlign: "center" }}
              href="#"
              onClick={(e) => onPageClick(e, page)}
            >
              {page}
            </a>
          </li>
        ))}
        {showNextPageGroup() && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={onNextPageGroup}>
              <span> &hellip;</span>
            </a>
          </li>
        )}

        <li className="page-item">
          <a
            className="page-link"
            aria-label="Next"
            href="#"
            onClick={(e) => onPageClick(e, activePage + 1)}
          >
            <span aria-hidden="true">»</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
      <div className="d-inline-flex d-block d-md-none">
        <select
          className="form-control float-right"
          onChange={(e) => onPageClick(e, e.target.value)}
          value={activePage}
        >
          {Array.from({ length: maxNumOfPage }).map((_, index) => {
            return (
              <option value={index + 1} key={index + 1}>
                Page {index + 1}
              </option>
            );
          })}
        </select>
      </div>
    </>
  );
};

export const PageSizeSelector = (props) => {
  const { selectors, pageSize } = props;

  const onPageSizeChange = (e) => {
    const pageSize = e.target.value;
    if (props.onPageSizeChange) {
      props.onPageSizeChange(pageSize);
    }
  };

  return (
    <select
      className="form-control"
      onChange={onPageSizeChange}
      value={pageSize}
    >
      {selectors.map((selector) => (
        <option key={selector} value={selector}>
          Showing {selector} rows
        </option>
      ))}
    </select>
  );
};
