import { SystemSettings } from "../../../../common/SystemSettings";
import { SystemSettingKeys } from "../../../../common/Constants";
import { useEffect, useState } from "react";
import { loadScript } from "../../../../common/Helpers";

const normalizeHost = (host) => {
  if (host.endsWith("/")) {
    host.substring(0, host.length - 2);
  }
  return host;
};

const buildConnection = (connection, pathName) => {
  if (connection?.baseUrl.includes(pathName)) {
    return connection;
  }
  if (connection) {
    connection.stop();
  }
  const url = SystemSettings.get(SystemSettingKeys.SignalRUrl);
  const signalRHost = normalizeHost(url);
  return new window.signalR.HubConnectionBuilder()
    .withUrl(`${signalRHost}/hub/${pathName}`, {
      skipNegotiation: true,
      transport: window.signalR.HttpTransportType.WebSockets,
    })
    .build();
};

export const useSignalR = (props) => {
  const [connection, setConnection] = useState(null);

  const { pathName } = props;

  useEffect(() => {
    if (!connection) return;
    const start = async () => {
      try {
        await connection.start();
        console.log("Hub Connected.");
      } catch (error) {
        console.log("Failed to connect to signalr hub. Retry after 10 seconds");
        setTimeout(start, 10000);
      }
    };
    start().then(() => {});
    return () => {
      connection.stop();
    };
  }, [connection]);

  useEffect(() => {
    if (!pathName) return;
    loadScript("/js/signalr.min.js").then(() => {
      setConnection((prev) => buildConnection(prev, pathName));
    });
  }, [pathName]);

  return {
    signalR: connection,
  };
};
