import React, { useCallback, useEffect } from "react";
import { FieldWrapper } from "../../FieldWrapper";
import { NumberInput } from "../../NumberInput";
import {
  QuantityLimitType,
  QuantityMechanicsType,
} from "../../../../constants/CampaignConstants";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  campaignAtom,
  campaignExtensionPropertiesSelector,
  sharedQuantityAvailabilitySelector,
} from "../../CampaignState";
import _ from "lodash";
import { QuantityLimitTypeCard } from "./QuantityLimitTypeCard";

export const SharedQuantityCard = (props) => {
  const { disabled } = props;
  const [campaign, setCampaign] = useRecoilState(campaignAtom);
  const [sharedQuantityAvailability, setSharedQuantityAvailability] =
    useRecoilState(sharedQuantityAvailabilitySelector);
  const campaignExtensionProperties = useRecoilValue(
    campaignExtensionPropertiesSelector
  );

  const isNoResetCycle =
    campaignExtensionProperties.quantityLimitType ===
    QuantityLimitType.NoResetCycle;

  const onSelectSharedQuantity = useCallback(() => {
    setCampaign((instance) => {
      return {
        ...instance,
        quantityMechanicsType: QuantityMechanicsType.SharedQuantity,
        campaignQuantityAvailability: [],
      };
    });
    setSharedQuantityAvailability(1);
  }, [setCampaign, setSharedQuantityAvailability]);

  useEffect(() => {
    if (
      _.isEmpty(campaign.campaignQuantityAvailability) &&
      campaign.quantityMechanicsType === 0
    ) {
      onSelectSharedQuantity();
    }
  }, [campaign.campaignQuantityAvailability, onSelectSharedQuantity]);

  const onInputChange = (e) => {
    const quantity = +e.target.value;
    if (quantity <= 0) {
      return;
    }
    setSharedQuantityAvailability(quantity);
  };

  const getQuantity = () => {
    if (isNoResetCycle) {
      return campaign.isUnlimited ? 0 : campaign.cumulativeQuantityLimit;
    }
    if (!_.isEmpty(sharedQuantityAvailability)) {
      return sharedQuantityAvailability[0].quantity;
    }
    if (_.isNil(campaign) || _.isEmpty(campaign.campaignQuantityAvailability)) {
      return 0;
    }
    return campaign.campaignQuantityAvailability[0].quantity;
  };

  return (
    <>
      <div>
        <input
          className="mr-1"
          type="radio"
          valuetype="number"
          name="quantityMechanicsType"
          value={QuantityMechanicsType.SharedQuantity}
          onChange={onSelectSharedQuantity}
          checked={
            QuantityMechanicsType.SharedQuantity ===
            campaignExtensionProperties.quantityMechanicsType
          }
          disabled={disabled}
        />
        <label className="form-check-label">Shared Quantity</label>
      </div>
      {campaignExtensionProperties.quantityMechanicsType ===
        QuantityMechanicsType.SharedQuantity && (
        <div className="row mt-3 mb-3">
          <div className="col-md-6">
            <QuantityLimitTypeCard disabled={disabled} />
            <FieldWrapper
              label="Quantity"
              input={
                <NumberInput
                  name="quantity"
                  min={1}
                  value={getQuantity()}
                  disabled={disabled || isNoResetCycle}
                  onChange={onInputChange}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
};
