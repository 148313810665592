import React from "react";
import { BaseListing } from "../../common/BaseListing";
import { StringHelper } from "../../../common/Helpers";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { RequestReportDetailCard } from "./RequestReportDetailCard";
import { ReportRequestStatus } from "../common/Constants";
import { RestService } from "../../../services/RestService";
import { toast } from "react-toastify";

export class RequestReportsListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      showDetailDialog: false,
      selectedRequestReport: null,
    };
  }

  getApiPath = () => "/api/v1/request_reports";

  getDefaultSort = () => "-CreatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "report_manager",
        name: "Report Manager",
      }),
      activeModule: new NavigationItem({
        identifier: "request_reports",
        name: "Requested Reports",
      }),
    });
  };

  onRowClick = (e) => {
    this.setState({ selectedRequestReport: e, showDetailDialog: true });
  };

  download = async (item) => {
    if (!item || !item.id) {
      return;
    }
    toast.info("The download process has been started. Please wait a while.");
    const response = await new RestService(
      `/api/v1/request_reports/${item.id}/download`
    ).download({});
    const { headers } = response;
    const contentDisposition = headers["content-disposition"];
    const fileName = contentDisposition
      .split(";")
      .map((x) => x.trim())
      .find((x) => x.startsWith("filename="));
    if (!fileName) {
      throw new Error("The file name is not included in the response headers.");
    }
    const blob = new Blob([response.data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName.replace("filename=", "");
    link.click();
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th onClick={this.onColumnClick} style={{ width: "25%" }}>
              Name
              <i
                className="fas fa-sort-alpha-down"
                id="Name"
                onClick={this.toggleSort}
              />
            </th>
            <th style={{ width: "30%" }}>Parameters</th>
            <th onClick={this.onColumnClick} style={{ width: "17%" }}>
              Created On
              <i
                className="fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th style={{ width: "13%" }}>Status</th>
            <th style={{ width: "15%" }} />
          </tr>
        </thead>
        <tbody id="data">
          {data &&
            data.map((item) => {
              const status = ReportRequestStatus.All.find(
                (x) => x.value === item.requestStatus
              );
              const isDisabled =
                item.requestStatus !== ReportRequestStatus.ProcessedSuccessful;
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item)}>
                  <td>{item.reportRegister.reportName}</td>
                  <td>
                    {item.requestParameters
                      .toString()
                      .replaceAll("::", ": ")
                      .split("|")
                      .map((x, index) => (
                        <span key={index}>
                          {x}
                          <br />
                        </span>
                      ))}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.requestStartedOn
                    )}
                  </td>
                  <td>
                    <span className={status.color}>{status.name}</span>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <button
                      disabled={isDisabled}
                      title={
                        isDisabled
                          ? "Cannot download the report that is not completed yet."
                          : ""
                      }
                      className="btn btn-success"
                      onClick={() => this.download(item)}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateModals() {
    const { showDetailDialog, selectedRequestReport } = this.state;
    if (showDetailDialog) {
      return (
        <RequestReportDetailCard
          onCancel={() =>
            this.setState({
              showDetailDialog: false,
              selectedRequestReport: null,
            })
          }
          onDownload={() => this.download(selectedRequestReport)}
          requestReport={selectedRequestReport}
        />
      );
    }
    super.generateModals();
  }
}
