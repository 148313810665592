import React, { useEffect, useState } from "react";
import { ServiceTicket } from "../../../../models/ServiceTicket";
import { TicketTypes } from "../../../../constants/PricingConstants";
import _ from "lodash";
import RestClient from "../../../../common/RestClient";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  serviceIdSelector,
  serviceNameSelector,
  serviceTicketsSelector,
} from "../state";
import { RowFormField } from "../../../layout/FormLayout";

export const AddTicketTypeModal = (props) => {
  const [instance, setInstance] = useState(new ServiceTicket());
  const serviceId = useRecoilValue(serviceIdSelector);
  const serviceName = useRecoilValue(serviceNameSelector);
  const [serviceTickets, setServiceTickets] = useRecoilState(
    serviceTicketsSelector
  );
  const { onCancel, visible } = props;

  useEffect(() => {
    const editable = new ServiceTicket();
    editable.printLabel = serviceName;
    setInstance(editable);
  }, [serviceName]);

  const onAdd = () => {
    if (instance.ticketType <= 0) {
      return;
    }

    let data = {
      ticketType: instance.ticketType,
      serviceId,
      printLabel: instance.printLabel,
    };
    return RestClient.sendPostRequest(
      "/api/v1/service_tickets",
      data,
      (serviceTicket) => {
        toast.success("The service ticket has been created successful");
        const tickets = _.cloneDeep(serviceTickets);
        tickets.push(serviceTicket);
        setServiceTickets(tickets);
        onCancel();
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    let value = e.target.value;
    const valueType = e.target.getAttribute("valuetype");
    const editable = _.cloneDeep(instance);
    if (_.isEqual("number", valueType) && !isNaN(+value)) {
      value = +value;
    }
    editable[fieldName] = value;
    setInstance(editable);
  };

  if (_.isNil(visible) || !visible) {
    return null;
  }

  const isEnableAddBtn = !_.isNil(instance) && instance.isValid();
  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Ticket Type
              </h5>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Ticket Type:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control"
                    valuetype="number"
                    name="ticketType"
                    onChange={onInputChange}
                  >
                    <option value="">Select a Ticket Type</option>
                    {TicketTypes.All.map((entry, index) => {
                      return (
                        <option key={index} value={entry.value}>
                          {entry.name}
                        </option>
                      );
                    })}
                  </select>
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      Print Label:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <input
                    className="form-control display-input"
                    type="text"
                    name="printLabel"
                    value={instance.printLabel}
                    onChange={onInputChange}
                  />
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  disabled={!isEnableAddBtn}
                  data-dismiss="modal"
                  onClick={onAdd}
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
