import React, { useMemo } from "react";
import { useState } from "react";
import { CustomSelect } from "../../common/CustomSelect";
import Validators from "../../common/Validators";
import { toast } from "react-toastify";
import { MovieMarathonService } from "../../../services/MovieMarathonService";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import _ from "lodash";
import { useQuery } from "react-query";

const QUERY_KEY_CONTENT_MOVIE_RELEASES = "GET_CONTENT_MOVIE_RELEASE";

export const AddMovieMarathonMappingDialog = (props) => {
  const {
    onCancel,
    onPostAdd,
    visible,
    containerMovieReleaseId,
    contentMovieReleases,
  } = props;
  const [movieSelectedId, setMovieSelectedId] = useState(null);
  const [isValidate, setIsValidate] = useState(false);
  const [validateStatus, setValidateStatus] = useState(false);
  const movieMarathonService = useMemo(() => new MovieMarathonService(), []);

  const { data: movieReleaseOptions } = useQuery(
    QUERY_KEY_CONTENT_MOVIE_RELEASES,
    async () => {
      const {data} = await new MovieReleaseService().getImpl("/options", {
        isMarathon: false,
      });
      return data.data;
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  );

  const onSelect = (param) => {
    setMovieSelectedId(param.value);
    setIsValidate(true);
  };

  const onValidationStatusChangeHandler = (param) => {
    setValidateStatus(param.isValid);
  };

  const add = () => {
    setIsValidate(true);
    if (!validateStatus) {
      return;
    }
    movieMarathonService
      .post({
        containerMovieReleaseId,
        contentMovieReleaseId: movieSelectedId,
      })
      .then(() => {
        toast.success("The content movie release has been added successful.");
        onPostAdd();
        onCancel();
      });
  };

  if ((!_.isNil(visible) && !visible) || containerMovieReleaseId <= 0) {
    return null;
  }

  const addedMovieReleaseIds = contentMovieReleases.map((x) => x.id);
  const options = (movieReleaseOptions ?? []).filter((x) =>
    !addedMovieReleaseIds.includes(x.id)
  );

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">&nbsp;Add Movie Marathon</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid text-left">
                <div className="row form-group">
                  <label className="col-4 form-control-label">
                    Film Title:
                  </label>
                  <div className="col">
                    <CustomSelect
                      data={options}
                      label="name"
                      value="id"
                      onChange={onSelect}
                      selectedValue={movieSelectedId}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={onValidationStatusChangeHandler}
                      fieldName="movieSelectedId"
                      isEnabled={isValidate}
                      property="Movie Title"
                      value={movieSelectedId}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={add}>
                Add
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
