import RestClient from "../common/RestClient";
import { toast } from "react-toastify";

export class MovieRestrictionService {
  constructor() {
    this.baseApi = "/api/v1/movie_release_restrictions";
  }

  get(id, parameters, onSuccess, onError) {
    return RestClient.sendGetRequestWithParameters(
      `${this.baseApi}/${id}`,
      parameters,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }

  getAll(parameters, onSuccess, onError) {
    return RestClient.sendGetRequestWithParameters(
      `${this.baseApi}`,
      parameters,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }

  create(restriction, onSuccess, onError) {
    let data = { ...restriction };
    return RestClient.sendPostRequest(
      `${this.baseApi}`,
      data,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  delete(restriction, onSuccess, onError) {
    return RestClient.sendDeleteRequest(
      `${this.baseApi}/${restriction.id}`,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => {
        if (onError) {
          onError(error);
        }
        console.error(error);
      }
    );
  }
}
