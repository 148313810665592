import React from "react";
import { FieldWrapper } from "../../FieldWrapper";
import { TextInput } from "../../TextInput";
import Multiselect from "multiselect-react-dropdown";
import { SeatsCondition } from "../../../../constants/CampaignConstants";
import { DateHelper } from "../../../../common/Helpers";
import { ApplicationID } from "../../../../common/Constants";
import { BundleSalesChannels } from "../CampaignBundleItem";
import { useRecoilValue } from "recoil";
import { campaignAtom } from "../../CampaignState";
import { useStackCampaigns } from "../../useStackCampaigns";

const CampaignBundleItemGeneral = (props) => {
  const { instance, locations } = props;
  const campaign = useRecoilValue(campaignAtom);
  const { stackCampaigns } = useStackCampaigns(campaign.id);

  const getSelectedCampaigns = () => {
    return (stackCampaigns ?? []).filter((x) =>
      instance.stackCodes.includes(x.name)
    );
  };

  return (
    <div className="row">
      <div className="col-md-8">
        <FieldWrapper
          label="Display Name"
          additionalCss="form-group"
          input={
            <TextInput
              name="displayName"
              value={instance.displayName}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Print Name"
          additionalCss="form-group"
          input={
            <TextInput
              name="printName"
              value={instance.printName}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Description"
          additionalCss="form-group"
          input={
            <TextInput
              name="description"
              value={instance.description}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Poster Url"
          additionalCss="form-group"
          input={
            <TextInput
              name="posterUrl"
              value={instance.posterUrl}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Display Weight"
          additionalCss="form-group"
          input={
            <TextInput
              name="displayWeight"
              value={instance.displayWeight}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Campaign Stack"
          additionalCss="form-group"
          input={
            <Multiselect
              options={stackCampaigns}
              selectedValues={getSelectedCampaigns()}
              displayValue="name"
              avoidHighlightFirstOption={true}
              disable={true}
            />
          }
        />
        <FieldWrapper
          label="Seat Condition"
          additionalCss="form-group"
          input={
            <TextInput
              name="seatCondition"
              value={SeatsCondition.getName(instance.seatCondition)}
              readOnly={true}
            />
          }
        />
        {instance.seatCondition !== SeatsCondition.NoCondition && (
          <FieldWrapper
            label=""
            additionalCss="form-group"
            input={
              <TextInput
                name="numberOfSeats"
                value={instance.numberOfSeats}
                readOnly={true}
              />
            }
          />
        )}
        <FieldWrapper
          label="Start On"
          additionalCss="form-group"
          input={
            <TextInput
              name="startOn"
              value={DateHelper.toDisplayDateTimeFormat(instance.startOn)}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="End On"
          additionalCss="form-group"
          input={
            <TextInput
              name="endOn"
              value={DateHelper.toDisplayDateTimeFormat(instance.endOn)}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Performance Start On"
          additionalCss="form-group"
          input={
            <TextInput
              name="performanceStartOn"
              value={DateHelper.toDisplayDateTimeFormat(instance.performanceStartOn)}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Performance End On"
          additionalCss="form-group"
          input={
            <TextInput
              name="performanceEndOn"
              value={DateHelper.toDisplayDateTimeFormat(instance.performanceEndOn)}
              readOnly={true}
            />
          }
        />
        <FieldWrapper
          label="Sales Channels"
          additionalCss="form-group"
          input={
            <div className="row">
              {ApplicationID.All.filter(
                (x) => BundleSalesChannels.indexOf(x.value) >= 0
              ).map((x, index) => (
                <div className="col-md-3" key={index}>
                  <input
                    className="mr-1"
                    type="checkbox"
                    value={x.value}
                    disabled={true}
                    checked={instance.appIds.indexOf(x.value) >= 0}
                  />
                  <label className="form-check-label">{x.name}</label>
                </div>
              ))}
            </div>
          }
        />
        <FieldWrapper
          label="Redemption Location"
          additionalCss="form-group"
          input={
            <div className="row">
              {locations.map((x, index) => (
                <div className="col-md-12" key={index}>
                  <input
                    className="mr-1"
                    type="checkbox"
                    value={x.id}
                    disabled={true}
                    checked={instance.locationIds.indexOf(x.id) >= 0}
                  />
                  <label className="form-check-label">{x.name}</label>
                </div>
              ))}
            </div>
          }
        />
      </div>
      <div className="col-md-4">
        <div className="row">
          <div className="col-md-12">
            <img src="img/testqrcode.png" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignBundleItemGeneral;
