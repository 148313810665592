import TransactionInformationMovie from "./TransactionInformationMovie";
import React from "react";
import { Modal } from "bootstrap";

const groupBy = (xs, key) =>
  xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

const TransactionInformationMovies = (props) => {
  const { movieInfos } = props;

  const onDisplayTicketView = (id) => new Modal(`#${id}`).show();

  if (!movieInfos || !movieInfos.length) return <></>;

  const campaigns = groupBy(movieInfos[0].tickets, "amount");

  return (
    <>
      {movieInfos
        .filter((x) => x.tickets?.length)
        .map((item, index) => (
          <TransactionInformationMovie
            tickets={item.tickets}
            campaigns={campaigns}
            onClick={() => onDisplayTicketView(item.id)}
            key={index}
          />
        ))}
    </>
  );
};

export default TransactionInformationMovies;
