export class CardMode {
  static View = 0;
  static Create = 1;
  static Update = 2;

  static isEditMode(mode) {
    return mode === CardMode.Create || mode === CardMode.Update;
  }

  static isViewMode(mode) {
    return mode === CardMode.View;
  }
}
