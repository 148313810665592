import _ from "lodash";
import { StatusColor } from "../common/Constants";

export class BookingStatus {
  static Pending = 1;
  static Completed = 2;
  static Rejected = 3;

  static All = [
    {
      name: "Pending",
      value: this.Pending,
    },
    {
      name: "Completed",
      value: this.Completed,
    },
    {
      name: "Rejected",
      value: this.Rejected,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CorporateBookingEventTime {
  static Before = 1;
  static Between = 2;
  static After = 3;

  static All = [
    {
      name: "Before 12:00pm",
      value: this.Before,
    },
    {
      name: "Between 12:00pm to 6:00pm",
      value: this.Between,
    },
    {
      name: "After 6:00pm",
      value: this.After,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CorporateBookingStatus {
  static Created = 1;
  static Confirmed = 2;
  static Cancelled = 3;

  static All = [
    {
      name: "Created",
      value: CorporateBookingStatus.Created,
    },
    {
      name: "Confirmed",
      value: CorporateBookingStatus.Confirmed,
    },
    {
      name: "Cancelled",
      value: CorporateBookingStatus.Cancelled,
    },
  ];

  static ColorMap = {
    [this.Created]: StatusColor.Gray,
    [this.Confirmed]: StatusColor.Green,
    [this.Cancelled]: StatusColor.Red,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };

  static getNameStatus = (status) => {
    const statusObject = CorporateBookingStatus.All.find(
      (x) => x.value === status
    );
    return statusObject?.name ?? "";
  };
}

export class TicketDistributionType {
  static None = 0;
  static ETickets = 1;
  static Printed = 2;
  static Mixed = 3;

  static All = [
    {
      name: "E Tickets",
      shortName: "eTicket",
      value: TicketDistributionType.ETickets,
    },
    {
      name: "Tickets Printed",
      shortName: "Printed",
      value: TicketDistributionType.Printed,
    },
    {
      name: "E Tickets / Tickets Printed",
      shortName: "eTicket / Printed",
      value: TicketDistributionType.Mixed,
    },
  ];

  static getName = (ticket) => {
    const typeObject = TicketDistributionType.All.find(
      (x) => x.value === ticket
    );
    return typeObject?.name ?? "";
  };

  static getShortName = (ticket) => {
    const typeObject = TicketDistributionType.All.find(
      (x) => x.value === ticket
    );
    return typeObject?.shortName ?? "";
  };
}

export class CorporateBookingType {
  static None = 0;
  static HallRental = 1;
  static Private = 2;
  static NonPrivate = 3;

  static All = [
    {
      name: "Hall Rental",
      value: CorporateBookingType.HallRental,
    },
    {
      name: "Private Booking",
      value: CorporateBookingType.Private,
    },
    {
      name: "Non-Private Booking",
      value: CorporateBookingType.NonPrivate,
    },
  ];

  static getName = (booking) => {
    const typeObject = CorporateBookingType.All.find(
      (x) => x.value === booking
    );
    return typeObject?.name ?? "";
  };
}

export class TicketStatus {
  static None = 0;
  static Partial = 1;
  static Completed = 2;

  static All = [
    {
      name: "Partial",
      value: TicketStatus.Partial,
    },
    {
      name: "Completed",
      value: TicketStatus.Completed,
    },
  ];

  static getColor = (status) => {
    if (status === this.Completed) {
      return "color-green";
    }
    return "color-orange";
  };

  static getName = (ticket) => {
    const typeObject = TicketStatus.All.find((x) => x.value === ticket);
    return typeObject?.name ?? "";
  };
}

export class PaymentOption {
  static None = 0;
  static PrepaidTickets = 1;
  static PrePayment = 2;
  static PostPayment = 3;

  static All = [
    {
      name: "Prepaid Tickets",
      value: PaymentOption.PrepaidTickets,
    },
    {
      name: "Pre-payment",
      value: PaymentOption.PrePayment,
    },
    {
      name: "Post-payment",
      value: PaymentOption.PostPayment,
    },
  ];

  static getName = (option) => {
    const typeObject = PaymentOption.All.find((x) => x.value === option);
    return typeObject?.name ?? "";
  };
}

export class CouponType {
  static None = 0;
  static Custom = 1;
  static Premiere = 2;
}

export class GroupCode {
  static CB_BRA = "CB-BRA";
}

export class PersonInChargeQuery {
  static Exact = 0;
  static Unassigned = 1;
  static Everyone = 2;
}
