import { showLoading } from "../../../common/Common";
import React from "react";
import RestClient from "../../../common/RestClient";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelEvoucherBatchDialog } from "./dialogs/CancelEvoucherBatchDialog";
import { ExtendBatchValidityDialog } from "./dialogs/ExtendBatchValidityDialog";
import { FileDownloadModal } from "./dialogs/FileDownloadModal";
import { EvoucherBatchInformationCard } from "./cards/EvoucherBatchInformationCard";
import {
  EVoucherBatchExtensionRequestStatus,
  EVoucherBatchStatus,
  GenericMessages,
  ObjectType,
} from "../../../common/Constants";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { EvoucherBatchMode } from "../common/EvoucherBatchMode";
import { EVoucherHistoryDialog } from "../../common/EVoucherHistoryDialog";

import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { EVoucherBatchRestService } from "../../../services/EVoucherBatchRestService";
import { EVoucherService } from "../../../services/EVoucherService";
import { EVoucherExporter } from "../common/EVoucherExporter";

export class EvoucherBatchDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherBatchOrder: null,
      evoucherBatchOrderId: "",
      isShowConfirmApproveDialog: false,
      isShowConfirmRejectDialog: false,
      isShowExtendValidityDialog: false,
      isShowConfirmCancelDialog: false,
      showEVoucherBatchHistoryDialog: false,
      isShowExportFileDialog: false,
      isExtendedValidity: false,
      evoucherExtensionBatch: null,
      isSubmitted: false,
      extensionRequestQuantity: 0,
      evoucherBatchValidations: [],
    };
    this.eVoucherService = new EVoucherService();
    this.eVoucherBatchRestService = new EVoucherBatchRestService();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherBatchOrderId = StringHelper.toNumber(id, 0);
    if (evoucherBatchOrderId > 0) {
      this.setState({ evoucherBatchOrderId }, this.refreshData);
    }
  }

  isValidated() {
    const { evoucherBatchValidations } = this.state;
    return evoucherBatchValidations.every((val) => val.isValid);
  }

  redirectToExtensionBatchDetailPage(additionalParameter) {
    this.props.history.push(
      `/evouchers/batch_extension_requests/${additionalParameter ?? ""}`
    );
  }

  refreshData() {
    const { id } = this.props.match.params;
    RestClient.sendGetRequest(
      `/api/v1/evoucher_batches/${id}`,
      (response) => {
        this.setState({ evoucherBatchOrder: response });
        this.loadEvoucherBatchExtensionRequest(response.reference);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  toggleConfirmApproveDialog = (visible) => {
    this.setState({ isShowConfirmApproveDialog: visible });
  };

  toggleConfirmRejectDialog = (visible) => {
    this.setState({ isShowConfirmRejectDialog: visible });
  };

  toggleExtendValidityDialog = (visible) => {
    this.setState({ isShowExtendValidityDialog: visible });
  };

  toggleConfirmCancelDialog = (visible) => {
    this.setState({ isShowConfirmCancelDialog: visible });
  };

  onProceedConfirmReject = (reason) => {
    const { evoucherBatchOrder } = this.state;
    const request = {
      reason,
    };
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batches/${evoucherBatchOrder.id}/reject`,
      request,
      (response) => {
        this.setState({
          evoucherBatchOrder: {
            ...this.state.evoucherBatchOrder,
            approvalReason: response.approvalReason,
            status: response.status,
          },
        });
        this.toggleConfirmRejectDialog(false);
        toast.success(GenericMessages.RejectBatchSuccessfullMessage);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  onProceedConfirmCancel = (reason) => {
    const { evoucherBatchOrder } = this.state;
    const request = {
      reason,
    };
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batches/${evoucherBatchOrder.id}/cancel`,
      request,
      (response) => {
        this.setState({
          evoucherBatchOrder: {
            ...this.state.evoucherBatchOrder,
            approvalReason: response.approvalReason,
            status: response.status,
          },
        });
        toast.success(GenericMessages.CancelBatchSuccessfullMessage);
        this.toggleConfirmCancelDialog(false);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  onProceedConfirmApprove = () => {
    this.toggleConfirmApproveDialog(false);
    const { evoucherBatchOrderId } = this.state;
    const request = {};
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batches/${evoucherBatchOrderId}/approve`,
      request,
      (response) => {
        this.setState({
          evoucherBatchOrder: {
            ...this.state.evoucherBatchOrder,
            usedQuantity: response.usedQuantity,
            status: response.status,
          },
        });
        toast.success(GenericMessages.ApproveBatchSuccessfullMessage);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  downloadEVoucherCodes = async (password) => {
    const { evoucherBatchOrder } = this.state;
    const response = await this.eVoucherBatchRestService.download(
      evoucherBatchOrder.id,
      password
    );
    showLoading(true);
    if (response.status === 200) {
      await EVoucherExporter.toProtectedZipFile(response.data, password);
      this.onShowExportFileDialog(false);
      toast.success(GenericMessages.DownloadBatchSuccessfullMessage);
    }
    showLoading(false);
  };

  loadEvoucherBatchExtensionRequestPending = () => {
    const queryParameter = {
      status: EVoucherBatchExtensionRequestStatus.Pending,
    };
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_batch_extension_requests/`,
      queryParameter,
      (response) => {
        toast.success(GenericMessages.CreationExtensionSuccessfullMessage);
      }
    );
  };

  loadEvoucherBatchExtensionRequest = (eVoucherBatchReference) => {
    const queryParameter = {
      eVoucherBatchReference,
      status: EVoucherBatchExtensionRequestStatus.Pending,
    };
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_batch_extension_requests/`,
      queryParameter,
      (response) => {
        if (response?.data?.length > 0) {
          this.setState({
            isExtendedValidity: true,
            evoucherExtensionBatch: response.data[0],
          });
        }
      }
    );
  };

  onProceedExtendValidityDialog = (extendRequest) => {
    if (!this.isValidated()) {
      this.setState({ isSubmitted: true });
      return;
    }
    RestClient.sendPostRequest(
      `/api/v1/evoucher_batch_extension_requests/`,
      extendRequest,
      (response) => {
        this.loadEvoucherBatchExtensionRequestPending();
        this.setState({
          isExtendedValidity: true,
          evoucherExtensionBatch: response,
        });
        this.toggleExtendValidityDialog(false);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  onViewHistoryClick = () => {
    this.setState({ showEVoucherBatchHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showEVoucherBatchHistoryDialog: false });
  };

  onShowExportFileDialog = (status) => {
    this.setState({
      isShowExportFileDialog: status,
    });
  };
  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher-details",
        name: "eVoucher Batch Details",
      }),
    });
  };

  render() {
    const {
      isSubmitted,
      evoucherBatchOrder,
      isShowConfirmApproveDialog,
      isShowConfirmRejectDialog,
      isShowExtendValidityDialog,
      isShowConfirmCancelDialog,
      isExtendedValidity,
      showEVoucherBatchHistoryDialog,
      isShowExportFileDialog,
    } = this.state;

    const isOwner =
      evoucherBatchOrder &&
      evoucherBatchOrder.createdBy === AuthenticationManager.username();

    const isCancellable =
      isOwner ||
      AuthenticationManager.isAuthorized(
        ResourceID.EVoucherViewBatchDetailsPageCancelBatchButton
      );
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              {evoucherBatchOrder && (
                <>
                  {evoucherBatchOrder.status === EVoucherBatchStatus.Approved &&
                    AuthenticationManager.isAuthorized(
                      ResourceID.EVoucherViewBatchDetailsDownloadButton
                    ) && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-primary"
                        onClick={() => this.onShowExportFileDialog(true)}
                      >
                        Download eVoucher Batch Codes
                      </ButtonActionDetailGroup.Item>
                    )}
                  {(evoucherBatchOrder.status ===
                    EVoucherBatchStatus.Approved ||
                    evoucherBatchOrder.status ===
                      EVoucherBatchStatus.Expired) &&
                    !isExtendedValidity &&
                    AuthenticationManager.isAuthorized(
                      ResourceID.EvoucherViewBatchDetailsExtendValidityButton
                    ) && (
                      <ButtonActionDetailGroup.Item
                        className="btn btn-primary"
                        onClick={() => this.toggleExtendValidityDialog(true)}
                      >
                        Extend Validity
                      </ButtonActionDetailGroup.Item>
                    )}
                </>
              )}
              <ButtonActionDetailGroup.Item
                id="batchHistory"
                className="btn btn-outline-secondary"
                onClick={this.onViewHistoryClick}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            <div className="row">
              <div className="col-md-12">
                {evoucherBatchOrder && (
                  <EvoucherBatchInformationCard
                    cardMode={EvoucherBatchMode.View}
                    evoucherBatchOrder={evoucherBatchOrder}
                    evoucherOrderId={evoucherBatchOrder.orderId}
                  />
                )}
              </div>
            </div>
            {evoucherBatchOrder &&
              evoucherBatchOrder.status === EVoucherBatchStatus.Pending && (
                <ButtonActionDetailGroup>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.EVoucherViewBatchDetailsPageApproveRejectButton
                  ) &&
                    !isOwner && (
                      <>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-success"
                          id="btnApprove"
                          onClick={() => this.toggleConfirmApproveDialog(true)}
                        >
                          Approve
                        </ButtonActionDetailGroup.Item>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-danger"
                          id="btnReject"
                          onClick={() => this.toggleConfirmRejectDialog(true)}
                        >
                          Reject
                        </ButtonActionDetailGroup.Item>
                      </>
                    )}
                  {isCancellable && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      id="btnReject"
                      onClick={() => this.toggleConfirmCancelDialog(true)}
                    >
                      Cancel Batch
                    </ButtonActionDetailGroup.Item>
                  )}
                </ButtonActionDetailGroup>
              )}
          </div>
        </div>
        <ConfirmDialog
          visible={isShowConfirmApproveDialog}
          onCancel={() => this.toggleConfirmApproveDialog(false)}
          onProceed={this.onProceedConfirmApprove}
          message="Are you sure you want to approve eVoucher Batch Order?"
          title="Approve eVoucher Batch Order"
        />
        {isShowConfirmRejectDialog && (
          <CancelEvoucherBatchDialog
            onCancel={() => this.toggleConfirmRejectDialog(false)}
            onProceed={this.onProceedConfirmReject}
            message="Are you sure you want to reject eVoucher Batch Order?"
            title="Reject eVoucher Batch Order"
            placeHolder="Enter reason of rejecting"
          />
        )}
        {isShowConfirmCancelDialog && (
          <CancelEvoucherBatchDialog
            onCancel={() => this.toggleConfirmCancelDialog(false)}
            onProceed={this.onProceedConfirmCancel}
            message="Are you sure you want to cancel eVoucher Batch Order?"
            title="Cancel eVoucher Batch Order"
          />
        )}
        {isShowExtendValidityDialog && (
          <ExtendBatchValidityDialog
            currentExpiredDate={evoucherBatchOrder.validityEnd}
            isSubmitted={isSubmitted}
            eVoucherBatchId={evoucherBatchOrder.id}
            onCancel={() => this.toggleExtendValidityDialog(false)}
            onProceed={this.onProceedExtendValidityDialog}
          />
        )}
        {showEVoucherBatchHistoryDialog && (
          <EVoucherHistoryDialog
            objectId={evoucherBatchOrder.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.EvoucherBatch}
            getStatusColor={EVoucherBatchStatus.getColor}
          />
        )}
        {isShowExportFileDialog && (
          <FileDownloadModal
            onSubmit={(password) => this.downloadEVoucherCodes(password)}
            onCancel={() => this.onShowExportFileDialog(false)}
          />
        )}
      </div>
    );
  }
}
