import React from 'react';

export class EvoucherQuantityPreview extends React.Component {
    render() {
        const { imageLink, titles, quantities } = this.props;
        return (
          <div className="col-xl-5">
            <div className="form-group">
              <img id="image" className="img-thumbnail mx-auto" src={imageLink} alt="Voucher" />
            </div>
            {titles && quantities && (
              <div className="row">
                <div className="col-md-4 form-group">
                  <div className="statistic__item p-1 text-center">
                    <h2 className="number">{quantities.quantityColumn1}</h2>
                    <span className="">{titles.titleColumn1}</span>
                  </div>
                </div>
                <div className="col-md-4" >
                  <div className="statistic__item p-1 text-center">
                    <h2 className="number">{quantities.quantityColumn2}</h2>
                    <span className="">{titles.titleColumn2}</span>
                  </div>
                </div>
                <div className="col-md-4" >
                  <div className="statistic__item p-1 text-center">
                    <h2 className="number">{quantities.quantityColumn1 - quantities.quantityColumn2}</h2>
                    <span className="">Total Balance</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
    }
}
