import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { serviceAtom } from "../state";

export const useServiceTimeBased = () => {
  const [service, setService] = useRecoilState(serviceAtom);

  useEffect(() => {
    if (service.performanceTimeStartOn && !service.performanceTimeEndOn) {
      setService((s) => ({ ...s, performanceTimeEndOn: "06:59" }));
      return;
    }

    if (!service.performanceTimeStartOn && service.performanceTimeEndOn) {
      setService((s) => ({ ...s, performanceTimeStartOn: "07:00" }));
    }
  }, [
    service.performanceTimeStartOn,
    service.performanceTimeEndOn,
    setService,
  ]);
};
