import React, { useEffect, useState } from "react";
import {
  IssuingBankCode,
  RedemptionQuantityType,
} from "../../../constants/CampaignConstants";
import { Days } from "../../../constants/PricingConstants";
import { toast } from "react-toastify";
import _ from "lodash";
import { CreditCardCondition } from "../../../models/CreditCardCondition";
import RestClient from "../../../common/RestClient";
import { CreditCardType } from "../../../constants/TransactionConstants";

export const EditCreditCardConditionDialog = (props) => {
  const [condition, setCondition] = useState(new CreditCardCondition());
  const [isAddBinRange, setIsAddBinRange] = useState(false);
  const [binStart, setBinStart] = useState("");
  const [binEnd, setBinEnd] = useState("");

  const { visible, campaignId, onCancel, instance, onPostUpdate } = props;

  useEffect(() => {
    if (!instance) {
      return;
    }
    setCondition(instance);
  }, [instance]);

  const onInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCondition({ ...condition, [name]: +value });
  };

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onAddClick = () => {
    const params = {
      ...condition,
      campaignId: campaignId
    };
    RestClient.sendPutRequest(
      `api/v1/campaign_validity_conditions/credit_card/${condition.id}`,
      params,
      (_) => {
        toast.success(
          "The campaign credit card condition has been updated successful."
        );
        setCondition(new CreditCardCondition());
        onPostUpdate();
      },
      handleError
    );
  };

  const onAddBin = () => {
    if (!binStart) {
      toast.error("Bin Start is required.");
      return;
    }
    condition.campaignConditionCreditCardBinRanges.push({
      binStart,
      binEnd,
    });
    setIsAddBinRange(false);
    setBinStart("");
    setBinEnd("");
  };

  const removeBin = (index) => {
    const cloned = _.cloneDeep(condition);
    cloned.campaignConditionCreditCardBinRanges.splice(index, 1);
    setCondition(cloned);
  };

  if (!visible || !instance) {
    return null;
  }

  return (
    <div className="page-container">
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        style={{
          display: "block",
          paddingRight: "14px",
          overflowY: "auto",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Valid for specific credit card</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="row form-group">
                <div className="col-3">
                  Credit Card Type:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-9">
                  <select
                    className="form-control"
                    name="creditCardType"
                    onChange={onInputChange}
                    value={condition.creditCardType}
                  >
                    <option value="">Select a Credit Card Type</option>
                    {CreditCardType.All.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-3">
                  Issuing Bank Code:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-9">
                  <select
                    className="form-control"
                    name="issuingBankCode"
                    onChange={onInputChange}
                    value={condition.issuingBankCode}
                  >
                    <option value="">Select a Issuing Bank Code</option>
                    {IssuingBankCode.All.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="row form-group ccpromo1">
                <div className="col-3">
                  Redemption Quantity Per Card:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-9">
                  <input
                    className="form-control"
                    type="number"
                    name="redemptionQuantityPerCard"
                    onChange={onInputChange}
                    value={condition.redemptionQuantityPerCard}
                  />
                </div>
              </div>
              <div className="row form-group ccpromo1">
                <div className="col-3">
                  Redemption Quantity Type:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col-9">
                  <select
                    className="form-control"
                    name="redemptionQuantityType"
                    onChange={onInputChange}
                    value={condition.redemptionQuantityType}
                  >
                    <option value="">Select a Redemption Quantity Type</option>
                    {RedemptionQuantityType.All.map((item, index) => (
                      <option value={item.value} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {condition.redemptionQuantityType ===
                RedemptionQuantityType.Weekly && (
                <div className="row form-group weeklyresetday">
                  <div className="col-3">
                    Reset Day:
                    <span className="color-red font-weight-bold">*</span>
                  </div>
                  <div className="col-9">
                    <select
                      className="form-control"
                      name="resetDay"
                      value={condition.resetDay}
                      onChange={onInputChange}
                    >
                      <option value="">Select a Reset Day</option>
                      {Days.All.map((item, index) => (
                        <option value={item.value} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {condition.campaignConditionCreditCardBinRanges.length > 0 && (
                <div className="row form-group" id="binList">
                  <div className="col-3 mt-1">Bin Ranges:</div>
                  <div className="col row">
                    {condition.campaignConditionCreditCardBinRanges.map(
                      (binRange, index) => (
                        <div
                          className="d-flex justify-content-between align-items-center col-10 mt-1"
                          key={index}
                        >
                          {binRange.binEnd ? (
                            <div className="">{`${binRange.binStart} to ${binRange.binEnd}`}</div>
                          ) : (
                            <div className="">{`${binRange.binStart}`}</div>
                          )}
                          <div className="m-r-15  mt-1">
                            <button
                              className="btn btn-sm btn-danger"
                              onClick={() => removeBin(index)}
                            >
                              <i
                                className="far fa-trash-alt"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {isAddBinRange && (
                <>
                  <div className="row form-group bin-range">
                    <div className="col-3 pr-0">
                      Credit card bin Start:
                      <span className="color-red font-weight-bold">*</span>
                    </div>
                    <div className="col">
                      <input
                        className="form-control"
                        type="number"
                        min="1"
                        name="binStart"
                        onChange={(e) => setBinStart(e.target.value)}
                        placeholder="Enter Bin Start"
                      />
                    </div>
                    <div className="col-3">Credit card bin End:</div>
                    <div className="col">
                      <input
                        className="form-control"
                        type="number"
                        min="1"
                        name="binEnd"
                        onChange={(e) => setBinEnd(e.target.value)}
                        placeholder="Enter Bin End"
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex flex-row justify-content-end form-group"
                    id="binbuttons"
                  >
                    <button
                      className="btn btn-outline-primary mr-1"
                      onClick={onAddBin}
                    >
                      Add Bin
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => setIsAddBinRange(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
              <div className="row form-group">
                <button
                  className="btn btn-outline-secondary m-l-15"
                  onClick={() => setIsAddBinRange(true)}
                >
                  Add Bin Range
                </button>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn btn-success"
                data-dismiss="modal"
                onClick={onAddClick}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  );
};
