import React, { Component } from "react";
import '../css/documentation.scss';

export class EditFilmFestivalHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Film Festival</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 8 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Film Festivals" link. This will take you to the Film Festivals Listing Page.<br />
                                    <img src="/img/documentation-img/FilmImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Film Festivals Listing Page, click on any film festival of your choice. This will take you to the View Film Festivals Details. <br />
                                    <img src="/img/documentation-img/FilmImg/list_select.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    In the Film Festivals Details Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Film Festival Title
                                        </li>
                                        <li>Film Festival Code</li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Film Festival Description
                                        </li>
                                        <li>
                                            Festival Image
                                        </li>
                                        <li>
                                            Film Festival Programmes
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Film Festival Description should be in HTML format.<br />
                                </div>
                            </li>
                                        <li class="createList">
                                            To edit the film festival listing, click on the “Edit” button in the top right corner of the page.<br />
                                            <img src="/img/documentation-img/FilmImg/view_edit.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            In Edit Film Festivals Page, you will be able to see the following fields:
                                            <ul class="unorderedList">
                                                <li>
                                                    Film Festival Title
                                                </li>
                                                <li>Film Festival Code</li>
                                                <li>
                                                    Display Start DateTime
                                                </li>
                                                <li>
                                                    Display End DateTime
                                                </li>
                                                <li>
                                                    Display Weight
                                                </li>
                                                <li>
                                                    Film Festival Description
                                                </li>
                                                <li>
                                                    Festival Image
                                                </li>
                                                <li>
                                                    Film Festival Programmes
                                                </li>

                                            </ul>
                                            <div class="alert alert-primary" role="alert">
                                                <i class="fas fa-info-circle" aria-hidden="true"></i>
                                                <strong class="noteHeader">Information:</strong><br/><br/>
                                                    - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                                    - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                                    - Film Festival Description should be in HTML format.<br />
                                                    - Film Festival Description should have a maximum of 8000 characters.
                                </div>
                            </li>
                                                <li class="createList">
                                                    To add a film festival content, click on 'Add Movie'.<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_addmovie.png" class="userImage" />
                                                </li>

                                                <li class="createList">
                                                    Select the Film Title, Sales Start Date, Sales End Date, and Film Availability. If Kinolounge is checked as a Film Availability, you will need to enter the film’s KinoLounge URL link.<br />
                                                    <img src="/img/documentation-img/FilmImg/addmovie_kinolounge.png" class="userImage" /><br /><br />
                                                    <div class="alert alert-primary" role="alert">
                                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                                        <strong class="noteHeader">Information:</strong><br /><br />
                                                        - Sales Start Date should always be earlier than Sales End Date.
                                                    </div>
                                                </li>

                                                <li class="createList">
                                                    To add the movie, click on 'Add Movie'.<br />
                                                    <img src="/img/documentation-img/FilmImg/addmovie_addmovie.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To delete a film festival content, click on the trashcan icon.<br />
                                                    <img src="/img/documentation-img/FilmImg/filmfestivalprogramme_delete.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To preview the film festival listing, click “Preview” at the bottom right corner of the page.<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_preview.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                                    <img src="/img/documentation-img/FilmImg/preview_cancelpreview.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To save your changes, click on “Save Changes” at the bottom right corner of the page. <br />
                                                    <img src="/img/documentation-img/FilmImg/edit_save.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To confirm that you would like to save your changes, click on the “Confirm” button .<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_save_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Upon successful save, a confirmation message will appear at the bottom right of the screen. <br />
                                                    <img src="/img/documentation-img/FilmImg/edit_save_successfultoaster.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To cancel all changes, click on the “Cancel” button at the bottom right corner. <br />
                                                    <img src="/img/documentation-img/FilmImg/edit_cancel.png" class="userImage" />
                                                </li>

                                                <li class="createList">
                                                    To confirm that you want to cancel all changes, click on "Confirm".<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_cancel_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To schedule a contest, click on the “Schedule” button at the bottom right corner.<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_schedule.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Select your date and time for publishing and click on 'Confirm' to schedule the publishing.<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_schedule_confirm.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    Upon successful scheduling, a confirmation message will appear at the bottom right of the screen.<br />
                                                    <img src="/img/documentation-img/FilmImg/edit_schedule_successtoaster.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    To view the scheduled film festival, follow the steps listed below.
                                                    <ol class="alphaList">
                                                        <li>
                                                            Using the menu on the left, click on "Website Management", then click on the “Film Festivals” link. This will take you to the Film Festivals Listing Page.<br />
                                                            <img src="/img/documentation-img/FilmImg/nav.png" class="userImage" />
                                                        </li>
                                                        <li>
                                                            To view scheduled film festival, click on “Scheduled Publishing” in the top right corner of the Film Festivals Listing Page.<br />
                                                            <img src="/img/documentation-img/FilmImg/list_scheduled.png" class="userImage" />
                                                        </li>
                                                        <li>
                                                            You will then be able to view your scheduled movie voucher.<br />
                                                            <img src="/img/documentation-img/FilmImg/scheduled_success_endpoint.png" class="userImage" />
                                                        </li>
                                                    </ol>
                                                </li>
                                                <li class="createList">
                                                    To view history changes, click on the "History” button, at the top right corner of the page.<br />
                                                    <img src="/img/documentation-img/FilmImg/view_history.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    A popup will appear, stating the changes made. Click on 'View Details' to see more.<br />
                                                    <img src="/img/documentation-img/FilmImg/History_modal.png" class="userImage" />
                                                </li>
                                                <li class="createList">
                                                    A pop-up will appear, stating the old and new values.<br />
                                                    <img src="/img/documentation-img/FilmImg/History_details.png" class="userImage" />
                                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/filmfestivals/CreateFilmFestivalHelp" className="btn btn-primary">&laquo; Create Film Festival</a>&nbsp;
                                <a href="/documentation/filmfestivals/ViewScheduledFilmFestivalHelp" className="btn btn-primary">View Scheduled Film Festival &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}