import React, { useState } from "react";
import { Restriction } from "../../../constants/MovieConstants";
import { MovieReleaseRestriction } from "../../../models/MovieReleaseRestriction";
import _ from "lodash";
import moment from "moment";
import { DateHelper } from "../../../common/Helpers";
import { RowFormField } from "../../layout/FormLayout";

export const AddRestrictionModal = (props) => {
  const [instance, setInstance] = useState(new MovieReleaseRestriction());

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    adjustStartOn(fieldName, selectValue);
    adjustEndOn(fieldName, selectValue);

    instance[fieldName] = selectValue;
    setInstance(_.cloneDeep(instance));
  };

  const adjustStartOn = (fieldName, selectValue) => {
    if (fieldName.toLowerCase() !== "starton") {
      return;
    }
    let startOn = new Date(selectValue);
    let endOn = new Date(instance["endOn"]);
    if (startOn < endOn) {
      return;
    }
    endOn.setDate(startOn.getDate() + 2);
    instance["endOn"] = moment(endOn).format(DateHelper.IsoDateFormat);
  };

  const adjustEndOn = (fieldName, selectValue) => {
    if (fieldName.toLowerCase() !== "endon") {
      return;
    }

    let startOn = new Date(instance["startOn"]);
    let endOn = new Date(selectValue);

    if (startOn < endOn) {
      return;
    }
    startOn.setDate(endOn.getDate() - 2);
    instance["startOn"] = moment(startOn).format(DateHelper.IsoDateFormat);
  };

  if (!props.visible) {
    return null;
  }

  const disableAddBtn = !instance.isValid();

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Movie Restriction</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={props.onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Movie Restriction:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control"
                    valuetype="number"
                    name="type"
                    onChange={onInputChange}
                  >
                    <option value="0">Select Restriction</option>
                    {Restriction.All.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      Start On:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <input
                    type="date"
                    name="startOn"
                    className="form-control"
                    onChange={onInputChange}
                    value={instance.startOn}
                  />
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      End On:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <input
                    className="form-control"
                    name="endOn"
                    type="date"
                    onChange={onInputChange}
                    value={instance.endOn}
                  />
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  data-dismiss="modal"
                  disabled={disableAddBtn}
                  onClick={() => {
                    props.onProceed(instance);
                    props.onCancel();
                    setInstance(new MovieReleaseRestriction());
                  }}
                >
                  <i className="fas fa-plus-sign" aria-hidden="true" />
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};
