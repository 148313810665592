import { RefundRequest } from "./RefundRequest";
import { PaymentCredit } from "./PaymentCredit";
import { PaymentEvoucher } from "./PaymentEvoucher";
import { PaymentPass } from "./PaymentPass";

export class Transaction {
  constructor(data) {
    this.id = data.id || 0;
    this.referenceNumber = data.referenceNumber || "";
    this.salesChannel = data.salesChannel || "";
    this.customerName = data.customerName || "";
    this.contactNumber = data.contactNumber || "";
    this.emailAddress = data.emailAddress || "";
    this.paymentType = data.paymentType || "";
    this.status = data.status || 0;
    this.grossAmount = data.grossAmount || 0;
    this.processAmount = data.processAmount || 0;
    this.orderGuid = data.orderGuid || "";
    this.creditCardMaskedNumber = data.creditCardMaskedNumber || "";
    this.amountFinal = data.amountFinal || 0;
    this.isRefund = data.isRefund || false;
    this.isTicketScanned = data.isTicketScanned || false;
    this.clientCode = data.clientCode || "";
    this.emailSentOn = data.emailSentOn || "";
    this.paymentStatus = data.paymentStatus || 0;
    this.locationId = data.locationId || 0;
    this.createdOn = data.createdOn || "";
    this.updatedOn = data.updatedOn || "";
    this.createdBy = data.createdBy || "";
    this.updatedBy = data.updatedBy || "";
    this.isCharged = data.isCharged;

    if (data.prints) {
      this.prints = data.prints;
    } else {
      this.prints = [];
    }
    if (data.refundRequests) {
      this.refundRequests = data.refundRequests.map(x => new RefundRequest(x));
    } else {
      this.refundRequests = [];
    }

    if (data.paymentCredits) {
      this.paymentCredits = data.paymentCredits.map(x => new PaymentCredit(x));
    } else {
      this.paymentCredits = [];
    }
    if (data.paymentEVouchers) {
      this.paymentEVouchers = data.paymentEVouchers.map(x => new PaymentEvoucher(x));
    } else {
      this.paymentEVouchers = [];
    }
    if (data.paymentPasses) {
      this.paymentPasses = data.paymentPasses.map(x => new PaymentPass(x));
    } else {
      this.paymentPasses = [];
    }
  }
}
