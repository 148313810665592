import React from "react";
import { GenericMessages } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { FilmFestivalDetailCard } from "./cards/FilmFestivalDetailCard";
import { ProgrammeCard } from "./cards/ProgrammeCard";
import { FilmFestivalPreview } from "./preview/FilmFestivalPreview";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class FilmFestivalCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      generalInfo: null,
      generalInfoValidations: [],
      programmeInfo: null,
      programmeInfoValidations: [],
      redirect: false,
      cachesReleases: [],
      isEdit: true,
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
    };
  }

  isValidated() {
    const { generalInfoValidations, programmeInfoValidations } = this.state;
    return (
      generalInfoValidations.every((val) => val.isValid) &&
      programmeInfoValidations.every((val) => val.isValid)
    );
  }

  setIsSubmitTrue = () => {
    this.setState({ isSubmit: true });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.isNullOrEmpty(
      model.displayStartTimeStr
    )
      ? null
      : model.displayStartTimeStr;
    const displayEndTime = StringHelper.isNullOrEmpty(model.displayEndTimeStr)
      ? null
      : model.displayEndTimeStr;
    const displayWeight = isNaN(model.displayWeight)
      ? 0
      : Number(model.displayWeight);
    const newModel = Object.assign(model, {
      displayStartTime,
      displayEndTime,
      displayWeight,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onProgrammeInfoModelChange = (model) => {
    this.setState({ programmeInfo: model });
  };

  onProgrammeInfoValidationsChange = (validations) => {
    this.setState({ programmeInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onChangeEdit = (value) => {
    this.setState({ isEdit: value });
  };

  onChangeCacheReleases = (value) => {
    this.setState({ cachesReleases: value });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo, programmeInfo } = this.state;
    const request = Object.assign({}, generalInfo, programmeInfo);

    RestClient.sendPostRequest(
      "/api/v1/film_festivals/",
      request,
      () => {
        const successMessage =
          GenericMessages.CreationSuccessfulMessage("Film Festival");
        toast.success(successMessage);
        this.setState({
          showConfirmDialog: false,
        });
        this.redirectToListingPage();
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/film_festivals/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "film_festivals",
        name: "Film Festivals",
      }),
      activeModule: new NavigationItem({
        identifier: "create-film-festival",
        name: "Create Film Festival",
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      programmeInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      isEdit,
      cachesReleases,
      generalInfoValidations,
    } = this.state;
    if (showPreview) {
      const festival = Object.assign({}, generalInfo, programmeInfo);
      return (
        <>
          <FilmFestivalPreview
            festival={festival}
            programmeInfo={cachesReleases}
          />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <FilmFestivalDetailCard
                  cardMode={cardMode}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                  isEdit={isEdit}
                />
                <ProgrammeCard
                  displayStart={generalInfo?.displayStartTime}
                  displayEnd={generalInfo?.displayEndTime}
                  cardMode={cardMode}
                  model={programmeInfo}
                  onEditableChange={this.onChangeEdit}
                  onChangeCacheReleases={this.onChangeCacheReleases}
                  isSubmit={isSubmit}
                  setIsSubmitTrue={this.setIsSubmitTrue}
                  generalInfoValidations={generalInfoValidations}
                  onModelChange={this.onProgrammeInfoModelChange}
                  onValidationsChange={this.onProgrammeInfoValidationsChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-outline-secondary mr-1"
                onClick={this.onShowPreview}
              >
                Preview
              </button>
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelCreationDialogMessage("film festival")}
          title={GenericMessages.CancelCreationDialogTitle("film festival")}
        />
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.ConfirmCreationDialogMessage(
            "film festival"
          )}
          title={GenericMessages.ConfirmCreationDialogTitle("film festival")}
        />
      </div>
    );
  }
}
