export class DaySelection {
  static OffPeak = 1;
  static Peak = 2;
  static PublicHoliday = 3;
  static EvePublicHoliday = 4;
  static BlackoutDay = 5;
  static Custom = 6;
  static All = [
    {
      name: "Off-Peak (Mon-Thu)",
      code: "WD",
      value: this.OffPeak,
    },
    {
      name: "Peak (Fri-Sun)",
      code: "WE",
      value: this.Peak,
    },
    {
      name: "Public Holiday",
      code: "PH",
      value: this.PublicHoliday,
    },
    {
      name: "Eve of Public Holiday",
      code: "EH",
      value: this.EvePublicHoliday,
    },
    {
      name: "Blackout Day",
      code: "BO",
      value: this.BlackoutDay,
    },
    {
      name: "Custom/Other",
      code: "CD",
      value: this.Custom,
    },
  ];

  static getDisplay(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.name;
    }
    return "";
  }

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }
}

export class PerformanceTypes {
  static SneakPreviews = 1;
  static GeneralScreening = 2;
  static MidnightScreening = 3;
  static SpecialScreening = 4;
  static PremiereScreening = 5;
  static All = [
    {
      name: "Sneak Previews",
      code: "SN",
      value: this.SneakPreviews,
    },
    {
      name: "General Screening",
      code: "GS",
      value: this.GeneralScreening,
    },
    {
      name: "Midnight Screening",
      code: "MS",
      value: this.MidnightScreening,
    },
    {
      name: "Special Screening",
      code: "SS",
      value: this.SpecialScreening,
    },
    {
      name: "Premiere Screening",
      code: "PS",
      value: this.PremiereScreening,
    },
  ];

  static AllExceptSpecial = this.All.filter(
    (x) => x.value !== this.SpecialScreening
  );

  static getDisplay(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.name;
    }
    return "";
  }

  static getCode(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.code;
    }
    return "";
  }
}

export class Days {
  static Sunday = 0;
  static Monday = 1;
  static Tuesday = 2;
  static Wednesday = 3;
  static Thursday = 4;
  static Friday = 5;
  static Saturday = 6;

  static All = [
    {
      name: "Sunday",
      value: this.Sunday,
    },
    {
      name: "Monday",
      value: this.Monday,
    },
    {
      name: "Tuesday",
      value: this.Tuesday,
    },
    {
      name: "Wednesday",
      value: this.Wednesday,
    },
    {
      name: "Thursday",
      value: this.Thursday,
    },
    {
      name: "Friday",
      value: this.Friday,
    },
    {
      name: "Saturday",
      value: this.Saturday,
    },
  ];

  static getDisplay(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.name;
    }
    return "";
  }

  static get(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found;
    }
    return null;
  }
}

export class TicketTypes {
  static Adult = 1;
  static Senior = 2;
  static Student = 3;
  static Child = 4;
  static SP0 = 5;
  static SP70 = 6;
  static SP75 = 7;
  static SP81 = 8;
  static SP86 = 9;
  static SP91 = 10;
  static SP10 = 11;
  static S105 = 12;
  static SP11 = 13;
  static S115 = 14;
  static SP12 = 15;
  static S125 = 16;
  static S135 = 17;
  static SP95 = 18;
  static SP65 = 19;
  

  static All = [
    {
      name: "Adult",
      code: "ADLT",
      value: this.Adult,
    },
    {
      name: "Senior",
      code: "SNCT",
      value: this.Senior,
    },
    {
      name: "Student",
      code: "STDT",
      value: this.Student,
    },
    {
      name: "Child",
      code: "CHLD",
      value: this.Child,
    },
    {
      name: "Special $0",
      code: "SP0",
      value: this.SP0,
    },
    {
      name: "Special $7.00",
      code: "SP70",
      value: this.SP70,
    },
    {
      name: "Special $7.50",
      code: "SP75",
      value: this.SP75,
    },
    {
      name: "Special $8.00",
      code: "SP81",
      value: this.SP81,
    },
    {
      name: "Special $8.50",
      code: "SP86",
      value: this.SP86,
    },
    {
      name: "Special $9.00",
      code: "SP91",
      value: this.SP91,
    },
    {
      name: "Special $10",
      code: "SP10",
      value: this.SP10,
    },
    {
      name: "Special $10.50",
      code: "S105",
      value: this.S105,
    },
    {
      name: "Special $11.00",
      code: "SP11",
      value: this.SP11,
    },
    {
      name: "Special $11.50",
      code: "S115",
      value: this.S115,
    },
    {
      name: "Special $12.00",
      code: "SP12",
      value: this.SP12,
    },
    {
      name: "Special $12.50",
      code: "S125",
      value: this.S125,
    },
    {
      name: "Special $13.50",
      code: "S135",
      value: this.S135,
    },
    {
      name: "Special $9.50",
      code: "SP95",
      value: this.SP95,
    },
    {
      name: "Special $6.50",
      code: "SP65",
      value: this.SP65,
    },
  ];

  static getDisplay(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.name;
    }
    return "";
  }

  static get(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found;
    }
    return null;
  }
}

export class ViewMode {
  static Readonly = 0;
  static Editable = 1;
}

export class UpdatePriceCriteria {
  static Replace = 1;
  static Increase = 2;
  static All = [
    {
      name: "Replace value with Fixed amount",
      value: this.Replace,
    },
    {
      name: "Increase by Specific Value (+ / -)",
      value: this.Increase,
    },
  ];
}

export class PricingUpdateMode {
  static None = 0;
  static SearchResult = 1;
  static Summary = 2;
}
export class AllowedTiming {
  static None = 0;
  static OffPeak = 1;
  static Peak = 2;
  static Sneak = 4;

  static All = [
    {
      name: "None",
      value: this.None,
    },
    {
      name: "OffPeak",
      value: this.OffPeak,
    },
    {
      name: "Peak",
      value: this.Peak,
    },
    {
      name: "Sneak / Opening",
      value: this.Sneak,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (found) {
      return found.name;
    }
    return "";
  }
}

