import React, { useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { RestService } from "../../../services/RestService";
import * as FileSaver from "file-saver";
import { DocketExportModel } from "../../../models/DocketExportModel";
import moment from "moment";
import { DocketType } from "../../../constants/ReportConstants";
import _ from "lodash";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const DateWithoutSeparator = "DDMMyyyy";

export const DailyDocketExport = () => {
  const [date, setDate] = useState("");

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "report_manager",
        name: "Report Manager",
      }),
      activeModule: new NavigationItem({
        identifier: "daily_docket_export",
        name: "Docket Export",
      }),
    });
  };

  const onDownloadShawTheatresClick = async () => {
    await download("ShawTheatres", DocketType.ShawTheatres);
  };

  const onDownloadShawApproachClick = async () => {
    await download("ShawApproach", DocketType.ShawApproach);
  };

  const makeSpaces = (numOfSpaces) => {
    const results = [];
    while (results.length < numOfSpaces) {
      results.push(``);
    }
    return results.join(``);
  };

  const getLengthOfLongestValue = (models, fieldName) => {
    if (!models || models.length === 0) {
      return 0;
    }
    const longestValue = _.maxBy(models, (x) => x[fieldName].length);
    return longestValue[fieldName].length;
  };

  const download = async (prefix, type) => {
    const response = await new RestService("/api/v1/report_export/docket").post(
      {
        type,
        date,
      }
    );
    if (!response) {
      return;
    }

    const { data } = response;
    const models = data.map((x) => new DocketExportModel(x));

    const maxPrimaryTitle = getLengthOfLongestValue(models, "primaryTitle");
    const maxMovieReleaseId = getLengthOfLongestValue(models, "movieReleaseId");
    const maxGrossAmount = getLengthOfLongestValue(models, "grossAmount");
    const maxTaxAmount = getLengthOfLongestValue(models, "taxAmount");
    const maxNetAmount = getLengthOfLongestValue(models, "netAmount");

    const lines = models.map((x) => {
      const primaryTitleLength = x.primaryTitle.length;
      const movieReleaseLength = x.movieReleaseId.length;
      const grossAmountLength = x.grossAmount.length;
      const netAmountLength = x.netAmount.length;
      const taxAmountLength = x.taxAmount.length;
      return `${x.dailyDocketCompanyAccountCode}${makeSpaces(1)}${
        x.locationVenueCode
      }${makeSpaces(4)}${x.performanceType}${makeSpaces(1)}${
        x.movieReleaseId
      }${makeSpaces(maxMovieReleaseId - movieReleaseLength)}${
        x.primaryTitle
      }${makeSpaces(maxPrimaryTitle - primaryTitleLength)}${
        x.grossAmount
      }${makeSpaces(maxGrossAmount - grossAmountLength)}${
        x.taxAmount
      }${makeSpaces(maxTaxAmount - taxAmountLength)}${x.netAmount}${makeSpaces(
        maxNetAmount - netAmountLength
      )}${x.numOfSoldSeats}${makeSpaces(4)}`;
    });
    FileSaver.saveAs(
      new Blob([lines.join("\r\n")], { type: "text/plain" }),
      `${prefix}_Dockin_${moment(new Date(date)).format(
        DateWithoutSeparator
      )}.txt`
    );
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="d-flex flex-row justify-content-end mb-3">
              <button className="btn btn-outline-secondary ml-1">
                History
              </button>
            </div>

            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body input-group ml-auto">
                    <div className="col-md-12">
                      <label className="form-control-label text-nowrap">
                        Select Performance Date:
                      </label>
                    </div>
                    <div className="col-xl-6 mb-1">
                      <input
                        className="form-control display-input"
                        type="date"
                        onChange={(e) => setDate(e.target.value)}
                        value={date || ""}
                      />
                    </div>
                    <div className="col-xl-6">
                      <ButtonActionDetailGroup>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-primary"
                          disabled={date === ""}
                          onClick={onDownloadShawTheatresClick}
                        >
                          Download Shaw Theatres
                        </ButtonActionDetailGroup.Item>
                        <ButtonActionDetailGroup.Item
                          className="btn btn-primary"
                          disabled={date === ""}
                          onClick={onDownloadShawApproachClick}
                        >
                          Download Shaw Approach
                        </ButtonActionDetailGroup.Item>
                      </ButtonActionDetailGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
