import React, { useState } from "react";
import "../../../css/images.css";
import "./styles.scss";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import { SeatSelectionMode } from "../../../../common/SeatingLayout";
import { useSeatSelection } from "./useSeatSelection";
import { useRecoilValue } from "recoil";
import { corporateBookingDetailSelector } from "../../CorporateBookingState";
import { SeatPlanCountDown } from "./SeatPlanCountDown";

export const SeatPlanComponent = (props) => {
  const { remainingCapacity, readOnly } = props;
  const corporateBookingDetail = useRecoilValue(corporateBookingDetailSelector);
  const [buttonMode, setButtonMode] = useState(SeatSelectionMode.SelectSeat);
  const { seatSelection } = useSeatSelection({
    readOnly,
  });

  const guestCount = corporateBookingDetail.guestCount;
  const selectedSeats = corporateBookingDetail.seatCodes;

  const getButtonMode = (mode) => {
    return buttonMode === mode ? SeatSelectionMode.SelectSeat : mode;
  };

  const toggleReleaseHouseSeat = () => {
    const buttonMode = getButtonMode(SeatSelectionMode.ReleaseHouseSeat);
    setButtonMode(buttonMode);
    seatSelection.setSelectionMode(buttonMode);
    let message = "Disabled house seats releasing functionality";
    if (buttonMode === SeatSelectionMode.ReleaseHouseSeat) {
      message = "Enabled house seats releasing functionality";
    }
    toast.info(message);
  };

  const toggleSelectBlockedSeat = () => {
    const buttonMode = getButtonMode(SeatSelectionMode.SelectBlockedSeat);
    setButtonMode(buttonMode);
    seatSelection.setSelectionMode(buttonMode);
    let message = "Disabled select blocked seat functionality";
    if (buttonMode === SeatSelectionMode.SelectBlockedSeat) {
      message = "Enabled select blocked seat functionality";
    }
    toast.info(message);
  };

  const toggleBlockHouseSeat = () => {
    const buttonMode = getButtonMode(SeatSelectionMode.BlockHouseSeat);
    setButtonMode(buttonMode);
    seatSelection.setSelectionMode(buttonMode);
    seatSelection.toggleHighLightBlockableHouseSeat(
      seatSelection.isBlockHouseSeatMode()
    );
    let message = "Disabled house seats blocking functionality";
    if (buttonMode === SeatSelectionMode.BlockHouseSeat) {
      message = "Enabled house seats blocking functionality";
    }
    toast.info(message);
  };

  const onAutoSelect = () => {
    if (!seatSelection) {
      return;
    }
    if (guestCount <= 0) {
      toast.error("The guest count is not specified yet.");
      return;
    }
    if (guestCount > remainingCapacity) {
      toast.error(`The guest count must be less than ${remainingCapacity}.`);
      return;
    }
    seatSelection.autoSelect(guestCount);
  };

  const onClearAll = () => {
    if (!seatSelection) {
      return;
    }
    seatSelection.clearAllSeats();
  };

  const isSelectMode = buttonMode === SeatSelectionMode.SelectSeat;
  const isBlockHouseSeatMode = buttonMode === SeatSelectionMode.BlockHouseSeat;
  const isReleaseHouseMode = buttonMode === SeatSelectionMode.ReleaseHouseSeat;
  const isBlockedSeatMode = buttonMode === SeatSelectionMode.SelectBlockedSeat;

  const totalSelectAvailableSeat = Math.min(guestCount, remainingCapacity);
  return (
    <div className="row">
      <div className="col">
        <SeatPlanCountDown readOnly={readOnly}>
          <div id="DiagramTest" />
          <div className="cinema-footer-info counter-view mt-2">
            <div className="row row-seat-actions h-100">
              <div className="col-md-8">
                <div>
                  <p>
                    Seat Count Selected: {selectedSeats.length} /{" "}
                    {totalSelectAvailableSeat}
                  </p>
                  <div className="seat-icons non-IE7 hide-1024">
                    <span className="gray">Legend</span>
                    <div className="sprite-seating-seat-curr-avail" />
                    Available
                    <div className="sprite-seating-seat-curr-select" /> Selected
                    <div className="sprite-seating-seat-curr-unavail" />{" "}
                    Unavailable
                    <div className="sprite-seating-seat-curr-hold" /> Assigned
                    <div className="sprite-seating-seat-curr-wheelchair-avail" />{" "}
                    Wheelchair
                  </div>
                </div>
              </div>
              {!readOnly && (
                <div className="col-md-4">
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className={
                        isBlockHouseSeatMode
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={toggleBlockHouseSeat}
                      disabled={isReleaseHouseMode || isBlockedSeatMode}
                    >
                      Block House Seat
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className={
                        isReleaseHouseMode
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={toggleReleaseHouseSeat}
                      disabled={isBlockHouseSeatMode || isBlockedSeatMode}
                    >
                      Release House Seat
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className={
                        isBlockedSeatMode
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={toggleSelectBlockedSeat}
                      disabled={isReleaseHouseMode || isBlockHouseSeatMode}
                    >
                      Select Blocked Seat
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={onAutoSelect}
                      disabled={!isSelectMode}
                    >
                      Auto Select
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      onClick={onClearAll}
                      disabled={!isSelectMode}
                    >
                      Clear All
                    </ButtonActionDetailGroup.Item>
                  </ButtonActionDetailGroup>
                </div>
              )}
            </div>
          </div>
        </SeatPlanCountDown>
      </div>
    </div>
  );
};
