import { RestService } from "../services/RestService";
import { AuthenticationManager } from "../common/AuthenticationManager";

export class VoucherRequestService {
    constructor() {
        this.baseApi = "/api/v1/voucher_requests";
    }

    get = async (id, onSuccess, onError) => {
        return await new RestService(`${this.baseApi}`)
            .get(id)
            .then((response) => onSuccess(response))
            .catch((error) => onError(error.message));
    }

    getAll = async (parameters, onSuccess, onError) => {
        return await new RestService(`${this.baseApi}`)
            .get(``, parameters)
            .then((response) => onSuccess(response))
            .catch((error) => onError(error.message));
    }

    update = async (bookingRequest, onSuccess, onError) => {
        return await new RestService(`${this.baseApi}`)
            .put(`${bookingRequest.id}`, {
            currentUser: AuthenticationManager.username(),
            personInCharge: bookingRequest.personInCharge
        })
            .then((response) => onSuccess(response))
            .catch((error) => onError(error.message));
    }
}