import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./MenuSidebar.scss";
import MenuList from "../MenuList";

const MenuSidebar = ({ toggleSidebar }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
    toggleSidebar(); // Propagate toggle up to adjust the layout
  };

  return (
    <div className="hide-for-mobile">
          <div className="menu-toggle" onClick={handleToggle}>
        <i className="fas fa-bars"></i>
      </div>
      
    <aside className={`menu-sidebar ${isCollapsed ? 'collapsed' : ''} d-none d-lg-block`}>
      <div className="logo">
        <Link to="/">
          <img src="img/shaw-icon-white.png" alt="Shaw Theatres" />
        </Link>
      </div>
      <MenuList />
      </aside>
    </div>
  );
};

export default MenuSidebar;
