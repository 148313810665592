import { BaseExtendValidityDialog } from "../../common/BaseExtendValidityDialog";

export class ExtendValidityDialog extends BaseExtendValidityDialog {
  constructor(props) {
    super(props);
    this.state = {
      newValidityEnd: "",
      reason: "",
      validations: [],
      isSubmitted: props.isSubmitted,
      eVoucherId: props.eVoucherId,
    };
  }

  onSubmitClick = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmitted: true });
      return;
    }
    if (this.props.onProceed) {
      const { newValidityEnd, reason } = this.state;
      this.props.onProceed({
        newValidityEnd,
        reason,
      });
    }
  };
}
