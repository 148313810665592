import React from "react";
import {
  StorageModule,
  VoucherTypeCategory,
} from "../../../../common/Constants";
import {
  DateHelper,
  ImageHelper,
  StringHelper,
  generateFileName,
} from "../../../../common/Helpers";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { CardMode } from "../../common/CardMode";
import { formatMovieVoucherCategoryName } from "../../common/WebsiteManagementConstants";
import { useUpload } from "../../../../hooks/useUpload";
import { RowFormField } from "../../../layout/FormLayout";

export class VoucherTypeDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      categoryOptions: this.createCategoryOptions(),
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      category: value.category || 0,
      categoryName: formatMovieVoucherCategoryName(value.category),
      code: value.code || "",
      description: value.description || "",
      displayEndTimeStr: value.displayEndTime || "",
      displayStartTimeStr: value.displayStartTime || "",
      name: value.name || "",
      planToPublishStr: value.planToPublish || "",
      poster: value.poster || "",
      pricingInformation: value.pricingInformation || "",
      weightDisplay: value.weightDisplay || 0,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    const { validations } = this.state;
    const validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  openFile = (e) => {
    const { model } = this.state;
    const { onModelChange, defaultValue } = this.props;
    const sourceFile = e.target.files[0];
    const fileName = generateFileName(defaultValue?.id, StorageModule.Voucher);
    const { uploadFile } = useUpload();
    uploadFile(sourceFile, fileName, StorageModule.Voucher).then((response) => {
      model.poster = `${response.data.blobStoragePath}${response.data.fileName}`;
      onModelChange(model);
    });
  };

  render() {
    return (
      <Card title="Voucher Details">
        {CardMode.isViewMode(this.state.cardMode)
          ? this.renderViewMode()
          : this.renderEditMode()}
      </Card>
    );
  }

  renderViewMode() {
    const { cardMode, model } = this.state;
    const {
      code,
      categoryName,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      pricingInformation,
      weightDisplay,
    } = model;
    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-12 col-md-6" style={{ paddingLeft: "30px" }}>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="VoucherCode">
                  Voucher Code:
                </label>
                <input
                  className="form-control display-input"
                  id="VoucherCode"
                  placeholder="Enter Promotion Code"
                  readOnly={true}
                  value={code}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="Name">
                  Voucher Program Name:
                </label>
                <input
                  className="form-control display-input"
                  id="Name"
                  placeholder="Enter Voucher Program Name"
                  readOnly={true}
                  value={name}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-11">
                <label className="form-control-label" htmlFor="PricingInfo">
                  Pricing Information:
                </label>
                <input
                  className="form-control display-input"
                  id="PricingInfo"
                  placeholder="Enter Pricing Info"
                  readOnly={true}
                  value={pricingInformation}
                />
              </div>
            </div>
            <div className="row form-group">
              <label
                className="col form-control-label text-nowrap"
                htmlFor="Category"
              >
                Voucher Category:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Category"
                  placeholder="Enter Voucher Category"
                  readOnly={true}
                  value={categoryName}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="StartDate">
                Display Start DateTime:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="StartDate"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayStartTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row form-group">
              <label className="col form-control-label" htmlFor="EndDate">
                Display End DateTime:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="EndDate"
                  placeholder={DateHelper.DisplayDateTimePlaceholder}
                  readOnly={true}
                  value={StringHelper.asDateStringToDisplayDateTimeFormat(
                    displayEndTimeStr
                  )}
                />
              </div>
            </div>
            <div className="row ">
              <label className="col form-control-label" htmlFor="Weight">
                Display Weight:
              </label>
              <div className="col-11">
                <input
                  className="form-control display-input"
                  id="Weight"
                  type="number"
                  placeholder="Enter Display Weight"
                  min="1"
                  max="100"
                  readOnly={true}
                  value={weightDisplay}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-4 mt-md-0" style={{ paddingLeft: "30px" }}>
            <div className="row col-12">
              <img
                src={ImageHelper.getPosterUrl(poster)}
                className="float-right"
                onError={ImageHelper.onPosterError}
              />
            </div>
            <div className="row form-group">
              <div className="col">
                <label className="form-control-label" htmlFor="ImgUrl">
                  Voucher Image:
                </label>
                <textarea
                  className="form-control display-input resize-none"
                  rows="2"
                  readOnly={true}
                  value={poster}
                />
              </div>
            </div>
            {CardMode.isScheduleMode(cardMode) && (
              <div className="row form-group">
                <label className="col form-control-label" htmlFor="EndDate">
                  Publish On:
                </label>
                <div className="col-11">
                  <input
                    className="form-control display-input"
                    id="PublishOn"
                    placeholder={DateHelper.DisplayDateTimePlaceholder}
                    readOnly={true}
                    value={StringHelper.asDateStringToDisplayDateTimeFormat(
                      planToPublishStr
                    )}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col" style={{ paddingLeft: "30px" }}>
            <label className="form-control-label" htmlFor="Desc">
              Voucher Description:
            </label>
            <textarea
              className="form-control m-r-15 resize-none display-input"
              maxLength="8000"
              rows="23"
              id="Desc"
              placeholder="Description"
              readOnly={true}
              value={description}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { cardMode, categoryOptions, model } = this.state;
    const {
      category,
      code,
      description,
      displayEndTimeStr,
      displayStartTimeStr,
      name,
      planToPublishStr,
      poster,
      pricingInformation,
      weightDisplay,
    } = model;
    return (
      <div className="row">
        <div className="col-md-6">
          <RowFormField
            label={
              <>
                Voucher Code:<span className="color-red">*</span>
              </>
            }
            htmlFor="VoucherCode"
          >
            <input
              id="VoucherCode"
              placeholder="Enter Voucher Code"
              className={this.getControlClassName("code")}
              readOnly={cardMode !== CardMode.Create}
              fieldname="code"
              onChange={this.onTextBoxChange}
              value={code}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="code"
              isEnabled={isSubmit}
              property="Voucher Code"
              value={code}
            />
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              maxLength="12"
              fieldName="code"
              isEnabled={isSubmit}
              property="Voucher Code"
              value={code}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Voucher Program Name:<span className="color-red">*</span>
              </>
            }
            htmlFor="Name"
          >
            <input
              id="Name"
              placeholder="Enter Voucher Program Name"
              className={this.getControlClassName("name")}
              fieldname="name"
              onChange={this.onTextBoxChange}
              value={name}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="name"
              isEnabled={isSubmit}
              property="Voucher Program Name"
              value={name}
            />
          </RowFormField>
          <RowFormField label="Pricing Information:" htmlFor="PricingInfo">
            <input
              id="PricingInfo"
              placeholder="Enter Pricing Info"
              className={this.getControlClassName("pricingInformation")}
              fieldname="pricingInformation"
              onChange={this.onTextBoxChange}
              value={pricingInformation}
            />
          </RowFormField>
          <RowFormField row="Voucher Category:" htmlFor="Category">
            <select
              id="Category"
              className={this.getControlClassName("category")}
              fieldname="category"
              onChange={this.onTextBoxChange}
              value={category}
            >
              {categoryOptions.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="category"
              isEnabled={isSubmit}
              property="Category"
              value={category}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Start DateTime:<span className="color-red">*</span>
              </>
            }
            htmlFor="StartDate"
          >
            <input
              id="StartDate"
              type="datetime-local"
              className={this.getControlClassName("displayStartTimeStr")}
              fieldname="displayStartTimeStr"
              onChange={this.onTextBoxChange}
              value={displayStartTimeStr}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="displayStartTimeStr"
              isEnabled={isSubmit}
              property="Display Start DateTime"
              value={displayStartTimeStr}
            />
          </RowFormField>
          <RowFormField label="Display End DateTime:" htmlFor="EndDate">
            <input
              id="EndDate"
              type="datetime-local"
              className={this.getControlClassName("displayEndTimeStr")}
              fieldname="displayEndTimeStr"
              onChange={this.onTextBoxChange}
              value={displayEndTimeStr}
            />
          </RowFormField>
          <RowFormField
            label={
              <>
                Display Weight:<span className="color-red">*</span>
              </>
            }
            htmlFor="Weight"
          >
            <input
              id="Weight"
              type="number"
              placeholder="Enter Display Weight"
              min="1"
              max="100"
              className={this.getControlClassName("weightDisplay")}
              fieldname="weightDisplay"
              onChange={this.onTextBoxChange}
              value={weightDisplay}
            />
            <Validators.RangeValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="weightDisplay"
              isEnabled={isSubmit}
              min="1"
              max="100"
              property="Display Weight"
              value={weightDisplay}
              allowDecimal={true}
            />
          </RowFormField>
          {CardMode.isScheduleMode(cardMode) && (
            <RowFormField label="Publish On:" htmlFor="EndDate">
              <input
                id="PublishOn"
                type="datetime-local"
                className={this.getControlClassName("planToPublishStr")}
                fieldname="planToPublishStr"
                onChange={this.onTextBoxChange}
                value={planToPublishStr}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="planToPublishStr"
                isEnabled={isSubmit}
                property="Publish On"
                value={planToPublishStr}
              />
            </RowFormField>
          )}

          <RowFormField label="Voucher Image:" htmlFor="ImgUrl">
            <input type="file" name="file" onChange={this.openFile} />
          </RowFormField>
          <div className="row form-group">
            <div className="col-11">
              <textarea
                id="ImgUrl"
                rows="4"
                placeholder="Enter Voucher Image"
                className={this.getControlClassName("poster", "resize-none")}
                fieldname="poster"
                onChange={this.onTextBoxChange}
                value={poster}
                disabled={true}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row m-0">
            <label className="form-control-label" htmlFor="Desc">
              Voucher Description:
            </label>
            <textarea
              maxLength="8000"
              rows="16"
              id="Desc"
              placeholder="Description"
              className={this.getControlClassName(
                "description",
                "m-r-15 resize-none"
              )}
              fieldname="description"
              onChange={this.onTextBoxChange}
              value={description}
            />
            <Validators.MaxLengthValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="description"
              isEnabled={isSubmit}
              maxLength="8000"
              property="Description"
              value={description}
            />
            <label className="form-control-label ml-auto m-r-15" htmlFor="Desc">
              <small>
                <span id="DescCount">{description.length}</span>/8000
              </small>
            </label>
          </div>
        </div>
      </div>
    );
  }

  createCategoryOptions() {
    return [
      { code: "", name: "Please Select" },
      { code: VoucherTypeCategory.Personal, name: "Gift/Personal Voucher" },
      { code: VoucherTypeCategory.Corporate, name: "Corporate Voucher" },
      { code: VoucherTypeCategory.Electronic, name: "E-Voucher" },
    ];
  }
}
