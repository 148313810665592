import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { MovieReleaseService } from "../../../services/MovieReleaseService";

class MovieMarathonListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
  }

  getApiPath = () => "/api/v1/movie_marathons";
  
  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "movie_marathons",
        name: "Movie Marathon",
      }),
    });
  };

  loadData(pageIndex, numberOfShowing, sortBy, searchBy) {
    this.currentPage = pageIndex;
    const pageSize =
      !numberOfShowing || numberOfShowing <= 0
        ? this.getPageSize()
        : numberOfShowing;
    let queryParameters = {
      startAt: pageIndex * pageSize,
      maxResults: pageSize,
    };
    queryParameters = this.injectSortTerm(queryParameters, sortBy);
    queryParameters = this.injectSearchTerm(queryParameters, searchBy);
    new MovieReleaseService()
      .list({ ...queryParameters, isMarathon: true })
      .then((response) => this.onConsumeResponse(response.data));
  }

  injectSearchTerm(queryParameters, searchBy) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : this.state.name;
    return super.injectSearchTerm(queryParameters, { name });
  }

  onRowClick = (id) => {
    this.props.history.push(`/movie_marathons/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Movie Marathon Title</th>
            <th>Poster</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Display Start On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayStartOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Display End On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayEndOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <tr
                  key={index}
                  onClick={() => this.onRowClick(item.id)}
                >
                  <td>{item.primaryTitle}</td>
                  <td>
                    <img
                      src={item.posterUrl}
                      width={200}
                      alt={item.primaryTitle}
                    />
                  </td>
                  <td>{item.displayWeight}</td>
                  <td>
                    {StringHelper.asDateStringToDefaultFormat(
                      item.displayStartOn
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDefaultFormat(
                      item.displayEndOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col-99 input-group ml-auto">
            <input
              type="text"
              id="input1-group2"
              name="name"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onTextBoxChange}
              value={this.state.name}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
}

export default MovieMarathonListing;
