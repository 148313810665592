import "bootstrap";
import "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./LocationPreview.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ImageHelper } from "../../../../common/Helpers";

export const LocationPreview = (props) => {
  const { location } = props;
  const [showMore, setShowMore] = useState(false);
  const [showMoreHeight, setShowMoreHeight] = useState(0);
  const showMoreContent = useRef();

  useEffect(() => {
    setTimeout(() => {
      document.querySelector("#accessRoute").click();
    }, 0)
  }, []);

  useEffect(() => {
    if (showMoreContent.current) {
      setShowMoreHeight(showMoreContent.current.scrollHeight);
    }
  }, [showMoreContent.current])

  const AccessRoute = () => {
    return (
      <div
        className="accordion accordion-3 z-depth-1-half"
        id="accordionEx1"
        role="tablist"
        aria-multiselectable="true"
        style={{ marginBottom: "30px" }}
      >
        <div className="">
          <div
            className="card-header"
            role="tab"
            id="heading4"
            style={{ background: "#135297" }}
          >
            <a
              data-toggle="collapse"
              id="accessRoute"
              data-parent="#accordionEx1"
              href="#collapse4"
              aria-expanded="false"
              aria-controls="collapse4"
              className="collapsed"
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="accordheader">
                    <h4
                      className="mb-0 mt-3 red-text"
                      style={{
                        color: "#fff",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                    >
                      <div
                        className="sprite-seating-seat-curr-wheelchair-avail"
                        style={{ paddingRight: "30px", float: "left" }}
                      />
                      <strong>Access Route For Patron in a Wheelchair </strong>
                      <i
                        className="fa fa-angle-down rotate-icon fa-2x"
                        style={{ float: "right" }}
                      />
                    </h4>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div
            id="collapse4"
            className="collapse show"
            role="tabpanel"
            aria-labelledby="heading4"
            data-parent="#accordionEx1"
            aria-expanded="false"
            style={{ background: "rgb(19, 82, 151)", height: "0" }}
          >
            <div
              className="card-body pt-0"
              style={{ padding: "15px 20px", color: "#fff" }}
              id="weelChairInfo"
              dangerouslySetInnerHTML={{
                __html: location.wheelchairAccessRoute,
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const Still = () => {
    return (
      <div className="col-lg-6 p-r-25">
        <h3 className="title">Stills</h3>
        <Carousel>
          {location.medias.map((item) => (
            <img
              src={ImageHelper.getPosterUrl(item.mediaUrl)}
              onError={ImageHelper.onPosterError}
              alt=""
              width="766"
              height="574"
            />
          ))}
        </Carousel>
      </div>
    );
  };

  const GoogleMap = () => {
    return (
      <div className="col-lg-6 p-l-25 moviedetail_slide-thumbnai">
        <h3 className="title">Theatre Location</h3>
        <div className="row">
          <div className="col-lg-12">
            <iframe
              src={location.mapLink}
              width="100%"
              height="574"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
            />
          </div>
        </div>
      </div>
    );
  };

  const EventFormat = () => {
    let data = [];
    let eventFormats = location.eventFormats ?? [];
    eventFormats.forEach((format) => {
      if (format.toUpperCase() === "IMAX")
        data.push(<div className="img-responsive sprite-mobile-nav-imax" />);
      if (format.toUpperCase() === "PREMIERE")
        data.push(
          <div className="img-responsive sprite-mobile-nav-premiere" />
        );
      if (format.toUpperCase() === "LUMIERE")
        data.push(<div className="img-responsive sprite-mobile-nav-lum" />);
      if (format.toUpperCase() === "DREAMERS")
        data.push(<div className="img-responsive sprite-mobile-nav-drem" />);
    });
    return data;
  };

  const canShowMore = showMoreHeight > 200;
  return (
    <div className="main-content location-preview">
      <div className="section__content section__content--p30">
        <div className="row background">
          <div className="col-lg-12">
            <div className="info">
              <div className="big_title">{location.name}</div>
              <div className="ss-space-20" />
              <div>
                <EventFormat />
              </div>
              <div className="ss-space-20" />
              <p className="add">{location.address}</p>
              <p />
              <ul className="list-inline func-btn m-a-0">
                <li className="p-a-0 mar-top--3">
                  <button
                    className="btn btn-primary btn-custom text-uppercase btn-buy"
                    id="getShowtimes"
                  >
                    Showtimes
                  </button>
                </li>
              </ul>
              <p />
              <div dangerouslySetInnerHTML={{ __html: location.description }} style={showMore ? null : {
                overflow: 'hidden',
                maxHeight: '200px'
              }} ref={showMoreContent} />
              {canShowMore && <>
                {!showMore && <span className="hide-for-desktop">...</span>}
                <span className="clearfix hide-for-desktop">
                  <a onClick={() => setShowMore(prev => !prev)} className="text-link text-underline pull-right"
                    style={{ cursor: 'pointer', color: '#007bff' }}>
                    {showMore ? 'Hide' : 'Show more'}
                  </a>
                </span>
              </>
              }
            </div>
          </div>
        </div>
        <div className="ss-space-40 show-for-desktop-only" />
      </div>
      <div className="section__content section__content--p30 location-identify-layout">
        <div className="row">
          <div className="col-lg-12">
            <AccessRoute />
            <div className="row">
              <Still />
              <GoogleMap />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
