import React from "react";
import { log } from "../../../common/Common";
import { EVoucherExtensionStatus } from "../../../common/Constants";
import { DateHelper, StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class EvoucherExtensionRequestListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      evoucherExtensionStatus: [],
      status: 0,
      evoucherCode: "",
    };
  }

  async componentDidMount() {
    await this.loadData(0);
    this.getEvoucherExtensionStatus();
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "extensions",
        name: "eVoucher Extension Request",
      }),
    });
  };

  getDefaultSort = () => "-UpdatedOn";

  getApiPath = () => {
    return "api/v1/evoucher_extensions";
  };

  injectSearchTerm(queryParameters) {
    const evoucherCode = StringHelper.isNullOrEmpty(this.state.evoucherCode)
      ? ""
      : this.state.evoucherCode;
    const status = this.state.status;
    return super.injectSearchTerm(queryParameters, {
      evoucherCode,
      status,
    });
  }

  onSearchInputChange(e) {
    if (e.target.value !== this.state.evoucherCode) {
      this.setState({ evoucherCode: e.target.value });
    }
  }

  onSelectStatusChange(e) {
    if (e.target.value !== this.state.status) {
      this.setState({ status: e.target.value });
    }
  }

  onRowClick = (id) => {
    this.props.history.push(`/evouchers/extensions/${id}`);
  };

  getEvoucherExtensionStatus = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_extensions/statuses",
      (response) => {
        this.setState({ evoucherExtensionStatus: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col-md-4 col-lg-6 col-xl-4 mb-1 row mx-0 align-items-center">
          <label className="form-control-label col-5" htmlFor="RequestStatus">
            Status:{" "}
          </label>
          <select
            className="form-control col-7"
            name="search"
            id="RequestStatus"
            required=""
            onChange={(e) => {
              this.onSelectStatusChange(e);
            }}
          >
            <option value="">All Status</option>
            {this.state.evoucherExtensionStatus
              ? this.state.evoucherExtensionStatus.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div className="col-md-5 input-group mb-1">
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Request Status</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={(e) => this.toggleSort(e)}
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item) => (
              <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                <td>{item.id}</td>
                <td className={EVoucherExtensionStatus.getColor(item.status)}>
                  {item.status}
                </td>
                <td>
                  {item.updatedOn &&
                    DateHelper.toDisplayDateTimeFormat(item.updatedOn)}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
