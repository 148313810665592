import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { GenericMessages } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { CareerDetailCard } from "./cards/CareerDetailCard";
import { CareerPreview } from "./preview/CareerPreview";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class CareerCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
    };
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPostRequest(
      "/api/v1/careers/",
      request,
      () => {
        const successMessage =
          GenericMessages.CreationSuccessfulMessage("Career");
        toast.success(successMessage);
        this.setState({
          showConfirmDialog: false,
        });
        this.redirectToListingPage();
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/careers/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "careers",
        name: "Careers",
      }),
      activeModule: new NavigationItem({
        identifier: "career-creation",
        name: "Create Career",
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
    } = this.state;
    if (showPreview) {
      const career = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <CareerPreview career={career} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <CareerDetailCard
                  cardMode={cardMode}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-outline-secondary mr-1"
                onClick={this.onShowPreview}
              >
                Preview
              </button>
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
          <ConfirmDialog
            visible={showCancelDialog}
            onCancel={this.onHideCancelDialog}
            onProceed={this.onProceedCancelDialog}
            message={GenericMessages.CancelCreationDialogMessage("career")}
            title={GenericMessages.CancelCreationDialogTitle("career")}
          />
          <ConfirmDialog
            visible={showConfirmDialog}
            onCancel={this.onHideConfirmDialog}
            onProceed={this.onProceedConfirmDialog}
            message={GenericMessages.ConfirmCreationDialogMessage("career")}
            title={GenericMessages.ConfirmCreationDialogTitle("career")}
          />
      </div>
    );
  }
}
