import React from "react";
import { Card } from "../../../common/Card";
import { DateHelper } from "../../../../common/Helpers";
import { EvoucherQuantityPreview } from "../../common/EvoucherQuantityPreview";
import { EvoucherProgramPreview } from "../../common/EvoucherProgramPreview";
import { EVoucherBatchExtensionRequestStatus } from "../../../../common/Constants";
import RestClient from "../../../../common/RestClient";
import { RowFormField } from "../../../layout/FormLayout";

export class EvoucherBatchInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherBatch: null,
      evoucherOrder: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.evoucherBatchId !== this.props.evoucherBatchId ||
      prevProps.isApprove !== this.props.isApprove
    ) {
      this.loadEvoucherBatch();
    }
  }

  loadEvoucherBatch() {
    const { evoucherBatchId } = this.props;
    if (evoucherBatchId && evoucherBatchId > 0) {
      RestClient.sendGetRequest(
        `/api/v1/evoucher_batches/${evoucherBatchId}`,
        (response) => {
          if (response) {
            this.setState({
              evoucherBatch: response,
            });
            RestClient.sendGetRequestWithParameters(
              `/api/v1/evoucher_orders/${response.orderId}`,
              { includes: "EVoucherProgram" },
              (data) => {
                if (data) {
                  this.setState({
                    evoucherOrder: data,
                  });
                }
              }
            );
          }
        }
      );
    }
  }

  render() {
    const { evoucherBatchExtensionRequestStatus } = this.props;
    const { evoucherBatch, evoucherOrder } = this.state;
    const quantities = {
      quantityColumn1: evoucherBatch?.totalQuantity,
      quantityColumn2: evoucherBatch?.usedQuantity,
    };
    const titles = {
      titleColumn1: "Total Batch Quantity",
      titleColumn2: "Total Redeemed",
    };
    return (
      <Card title="eVoucher Batch Details">
        {evoucherBatch && evoucherOrder && (
          <div className="details row flex-column-reverse flex-xl-row">
            <div className="col-xl-6">
              <RowFormField label="Order ID:" htmlFor="OrderId">
                <input
                  type="text"
                  id="OrderId"
                  className="form-control display-input"
                  value={evoucherOrder?.orderGuid ?? ""}
                  readOnly
                />
              </RowFormField>
              <RowFormField
                label="Total Order Quantity:"
                htmlFor="TotalQuantity"
              >
                <input
                  type="number"
                  readOnly
                  value={evoucherOrder?.totalQuantity}
                />
              </RowFormField>
              {evoucherOrder && (
                <EvoucherProgramPreview
                  evoucherProgramId={evoucherOrder?.eVoucherProgram?.id}
                />
              )}
              <RowFormField label="Batch ID:" htmlFor="BatchID">
                <input
                  className="form-control display-input"
                  type="text"
                  value={evoucherBatch?.batchId}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Validity Start:" htmlFor="StartDate">
                <input
                  className="form-control display-input"
                  type="text"
                  value={DateHelper.toDisplayDateTimeFormat(
                    evoucherBatch?.validityStart
                  )}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Expiry Date:" htmlFor="EndDate">
                <input
                  className="form-control display-input"
                  type="text"
                  value={DateHelper.toDisplayDateTimeFormat(
                    evoucherBatch?.validityEnd
                  )}
                  readOnly
                />
              </RowFormField>
              {evoucherBatchExtensionRequestStatus ===
                EVoucherBatchExtensionRequestStatus.Approved && (
                <RowFormField label="Last Redemption:" htmlFor="LastRedemption">
                  <input
                    className="form-control display-input activated used"
                    type="text"
                    value={DateHelper.toDisplayDateTimeFormat(
                      evoucherBatch?.lastRedemptionOn
                    )}
                    readOnly
                  />
                </RowFormField>
              )}
              <RowFormField label="Created By:" htmlFor="CreatedBy">
                <input
                  className="form-control display-input activated used"
                  type="text"
                  value={evoucherBatch?.createdBy}
                  readOnly
                />
              </RowFormField>
              <RowFormField label="Created On:" htmlFor="CreatedOn">
                <input
                  className="form-control display-input"
                  type="text"
                  value={DateHelper.toDisplayDateTimeFormat(
                    evoucherBatch?.createdOn
                  )}
                  readOnly
                />
              </RowFormField>
              <RowFormField
                label="Remaining duration:"
                htmlFor="RemainingDuration"
              >
                <input
                  className="form-control display-input"
                  type="text"
                  value={`${Math.ceil(evoucherBatch?.remainingDuration)} days`}
                  readOnly
                />
              </RowFormField>
            </div>
            {evoucherOrder && (
              <EvoucherQuantityPreview
                imageLink={evoucherOrder?.eVoucherProgram?.urlImage}
                quantities={quantities}
                titles={titles}
              />
            )}
          </div>
        )}
      </Card>
    );
  }
}
