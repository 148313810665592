import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import Multiselect from "multiselect-react-dropdown";
import RestClient from "../../../../common/RestClient";
import { DateHelper } from "../../../../common/Helpers";
import { TicketDistributionType } from "../../../../constants/CorporateBookingConstants";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { BreadCrumb } from "../../../common/BreadCrumb";
import { WebServiceClient } from "../WebServiceClient";
import {
  NavigationItem,
  NavigationSettings,
} from "../../../common/NavigationSettings";
import { Card } from "../../../common/Card";

const seatOptions = {
  allSeats: 0,
  selectedSeats: 1,
};

const getNavigationSettings = () => {
  return new NavigationSettings({
    parentModule: new NavigationItem({
      identifier: "corporate_bookings",
      name: "Corporate Bookings",
    }),
    activeModule: new NavigationItem({
      identifier: "print_ticket",
      name: "Print Ticket",
    }),
  });
};

export const PrintTicket = () => {
  const { id } = useParams();
  const [seatOption, setSeatOption] = useState(0);
  const [availableSeats, setAvailableSeats] = useState([]);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedSeatCodes, setSelectedSeatCodes] = useState([]);
  const [showConfirmPrint, setShowConfirmPrint] = useState(false);
  const [showConfirmCouponPrint, setShowConfirmCouponPrint] = useState(false);
  const [showConfirmRePrint, setShowConfirmRePrint] = useState(false);
  const [rePrintMessage, setRePrintMessage] = useState("");
  const [refresh, setRefresh] = useState(0);

  const webServiceClient = useMemo(() => new WebServiceClient(), []);
  webServiceClient.onSuccess(() => {
    toast.success("The print message has been sent successful.");
  });

  const handleError = useCallback((error) => {
    closeAllModals();
    toast.error(error.message);
  }, []);

  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/corporate_booking_print_and_distribute_tickets",
      {
        maxResults: 9999,
        corporateBookingId: id,
      },
      (response) => {
        setTickets(response.data);
      },
      handleError
    );
  }, [handleError, id, refresh]);

  useEffect(() => {
    RestClient.sendGetRequest(
      `/api/v1/corporate_bookings/${id}`,
      (corporateBooking) => {
        const currentAvailableSeats = _.sortBy(
          (corporateBooking.seatCodes ?? []).map((x, index) => {
            return {
              id: index,
              name: x.trim().toUpperCase(),
            };
          }),
          (x) => x.name
        ).reverse();

        setAvailableSeats(currentAvailableSeats);
        setHasCoupon(corporateBooking.hasCoupon);

        if (seatOption === seatOptions.allSeats) {
          const allSeatCodes = currentAvailableSeats.map((x) => x.name);
          setSelectedSeatCodes(allSeatCodes);
        }
      },
      handleError
    );
  }, [handleError, id, seatOption]);

  const handleMultipleSeatsSelect = (source) => {
    setSelectedSeatCodes(source.map((x) => x.name));
  };

  const handleMultipleSeatsRemove = (source) => {
    setSelectedSeatCodes(source.map((x) => x.name));
  };

  const handleSelectSeatOption = (e) => {
    const selectedSeatOption = +e.target.value;

    setSeatOption(selectedSeatOption);

    if (selectedSeatOption === seatOptions.allSeats) {
      const allSeatCodes = availableSeats.map((x) => x.name);
      setSelectedSeatCodes(allSeatCodes);
    } else {
      setSelectedSeatCodes([]);
    }
  };

  const handlePrint = (rePrint) => {
    if (!rePrint && !validateRePrintSeatCodes()) {
      return;
    }

    RestClient.sendPostRequest(
      "/api/v1/corporate_booking_print_and_distribute_tickets/prints/",
      {
        corporateBookingId: +id,
        seatCodes: selectedSeatCodes,
      },
      (response) => {
        const found = tickets.find((x) => x.id === response.id);
        if (!found) {
          tickets.push(response);
        } else {
          found.count = response.count;
        }
        setTickets([...tickets]);
        toast.success("The seat codes has been printed successful.");
        setRefresh((r) => r + 1);

        RestClient.sendGetRequestWithParameters(
          "/api/v1/corporate_booking_print_and_distribute_tickets/prints",
          {
            corporateBookingId: +id,
            seatCodes:
              seatOption === seatOptions.allSeats
                ? ""
                : selectedSeatCodes.toString(),
          },
          (response) => {
            webServiceClient.sendRequest(response);
          },
          (error) => {
            handleError(error);
          }
        );

        closeAllModals();
      },
      (error) => {
        handleError(error);
      }
    );
  };

  const handleCouponPrint = () => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/corporate_booking_print_and_distribute_tickets/prints",
      {
        corporateBookingId: +id,
        isPrintCouponsOnly: true,
      },
      (response) => {
        webServiceClient.sendRequest(response);
      },
      (error) => {
        handleError(error);
      }
    );

    closeAllModals();
  };

  const validateRePrintSeatCodes = () => {
    const printedSeatCodes = [
      ...new Set(
        tickets
          .filter(
            (x) => x.ticketDistribution === TicketDistributionType.Printed
          )
          .map((x) => x.seatCodes)
          .flat()
      ),
    ];

    const rePrintSeatCodes = printedSeatCodes.filter(
      (x) => selectedSeatCodes.indexOf(x) >= 0
    );

    if (rePrintSeatCodes && rePrintSeatCodes.length > 0) {
      const printItems = rePrintSeatCodes.join(", ");
      setShowConfirmPrint(false);
      setShowConfirmRePrint(true);
      setRePrintMessage(
        `The seat codes ${printItems} has been print already. Please confirm to print with seat code again`
      );
      return false;
    }
    return true;
  };

  const closeAllModals = () => {
    setShowConfirmPrint(false);
    setShowConfirmRePrint(false);
    setShowConfirmCouponPrint(false);
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid pb-3">
            <Card title="Print Tickets">
              <div className="col">
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="printType"
                    id="custom"
                    value={seatOptions.allSeats}
                    checked={seatOption === seatOptions.allSeats}
                    onChange={handleSelectSeatOption}
                  />
                  <label className="form-check-label" htmlFor="custom">
                    All Seats
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="printType"
                    id="default"
                    value={seatOptions.selectedSeats}
                    checked={seatOption === seatOptions.selectedSeats}
                    onChange={handleSelectSeatOption}
                  />
                  <label className="form-check-label" htmlFor="default">
                    Selected Seats
                  </label>
                </div>
                {seatOption === seatOptions.selectedSeats && (
                  <div className="col-md-3 seatDiv">
                    <label className="form-control-label">
                      Seat Codes:
                      <span
                        style={{ color: "red" }}
                        className="red font-weight-bold"
                      >
                        *
                      </span>
                    </label>
                    <Multiselect
                      options={availableSeats}
                      onSelect={handleMultipleSeatsSelect}
                      onRemove={handleMultipleSeatsRemove}
                      displayValue="name"
                      avoidHighlightFirstOption={true}
                    />
                    <br />
                  </div>
                )}
                <div className="col-md-3" style={{ height: "2.5rem" }}>
                  <button
                    className="btn btn-primary float-right"
                    id="printticketbtn"
                    data-target="#printTicketModal"
                    data-toggle="modal"
                    onClick={() => setShowConfirmPrint(true)}
                  >
                    Print Ticket
                  </button>
                </div>

                {hasCoupon && (
                  <div className="col-md-3" style={{ height: "2.5rem" }}>
                    <button
                      className="btn btn-primary float-right"
                      id="printcouponbtn"
                      data-target="#printCouponModal"
                      data-toggle="modal"
                      onClick={() => setShowConfirmCouponPrint(true)}
                    >
                      Print Coupon
                    </button>
                  </div>
                )}
              </div>
            </Card>
            <br />
            <Card title="Ticket Distributions">
              <div className="container-fluid overflow-x">
                <div className="row mb-2 min-width-768">
                  <div className="col-3">
                    <h5>Mode of Ticket</h5>
                  </div>
                  <div className="col-2">
                    <h5>Seat Codes</h5>
                  </div>
                  <div className="col-2">
                    <h5>Sent/Print Count</h5>
                  </div>
                  <div className="col-2">
                    <h5>Sent/Printed By</h5>
                  </div>
                  <div className="col-3">
                    <h5>Sent/Printed On</h5>
                  </div>
                </div>
                <br />
                {tickets.map((item) => (
                  <div className="row mb-2 min-width-768" key={item.id}>
                    <div className="col-3">
                      {TicketDistributionType.getShortName(
                        item.ticketDistribution
                      )}
                    </div>
                    <div className="col-2">{item.seatCodes.join(", ")}</div>
                    <div className="col-2">{item.count}</div>
                    <div className="col-2">{item.createdBy}</div>
                    <div className="col-3 text-nowrap">
                      {DateHelper.toDisplayDateTimeFormat(item.processedOn)}
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </div>
      </div>

      <ConfirmDialog
        message="Are you sure you want to print tickets?"
        visible={showConfirmPrint}
        title="Print Ticket"
        onProceed={() => handlePrint()}
        onCancel={() => setShowConfirmPrint(false)}
      />

      <ConfirmDialog
        message="Are you sure you want to print coupon slips?"
        visible={showConfirmCouponPrint}
        title="Print Ticket"
        onProceed={() => handleCouponPrint()}
        onCancel={() => setShowConfirmCouponPrint(false)}
      />

      <ConfirmDialog
        message={rePrintMessage}
        visible={showConfirmRePrint}
        title="Reprint Ticket"
        onProceed={() => handlePrint(true)}
        onCancel={() => {
          setShowConfirmRePrint(false);
          setRePrintMessage("");
        }}
      />
    </>
  );
};
