export class PaymentCredit {
    constructor(data) {
        this.id = data?.id ?? 0;
        this.creditCardProgram = data?.creditCardProgram ?? 0;
        this.paymentProcessType = data?.paymentProcessType ?? 0;
        this.amountFinal = data?.amountFinal ?? 0;
        this.amountCharge = data?.amountCharge ?? 0;
        this.referenceApproval = data?.referenceApproval ?? "";
        this.referenceError = data?.referenceError ?? "";
        this.referenceInvoice = data?.referenceInvoice ?? "";
        this.referenceRetrieval = data?.referenceRetrieval ?? "";
        this.paymentMerchantId = data?.paymentMerchantId ?? "";
        this.terminalReference = data?.terminalReference ?? "";
        this.isChargeback = data?.isChargeback ?? false;
        this.creditCardHashNumber = data?.creditCardHashNumber ?? "";
        this.creditCardMaskedNumber = data?.creditCardMaskedNumber ?? "";
        this.transactionId = data?.transactionId ?? 0;
    }
}