import React, { useEffect, useState } from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import RestClient from "../../../common/RestClient";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../CardMode";
import { GeneralInformationCard } from "./cards/GeneralInformationCard";
import { ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { BreadCrumb } from "../../common/BreadCrumb";
import { toast } from "react-toastify";

export const ProductDetail = ({ match: { params }, history }) => {
  const { id } = params;
  const [cardMode, setCardMode] = useState(CardMode.View);
  const [product, setProduct] = useState({
    productChoices: [],
    productAddOns: [],
  });
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [productId, setProductId] = useState(null);
  const [productValidations, setProductValidations] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
      activeModule: new NavigationItem({
        identifier: "product-creation",
        name: CardMode.isViewMode(cardMode) ? "View Product" : "Edit Product",
      }),
    });
  };

  const handleError = (e) => toast.error(e.message);

  useEffect(() => {
    setProductId(id);
    RestClient.sendGetRequestWithParameters(
      `api/v1/products/${id}`,
      { includes: 'ProductCombos.ProductContent' },
      (response) => {
        setProduct(response);
      },
      handleError
    );
  }, [id]);

  const isValidated = () => {
    return productValidations.every((val) => val.isValid);
  };

  const onProductValidationsChange = (validations) => {
    setProductValidations(validations);
  };

  const onEditProductClick = () => {
    setCardMode(CardMode.Update);
  };

  const onShowCancelDialog = () => {
    setShowCancelDialog(true);
  };

  const onShowConfirmDialog = () => {
    if (!isValidated()) {
      setIsSubmit(true);
      return;
    }
    setShowConfirmDialog(true);
  };

  const onHideCancelDialog = () => {
    setShowCancelDialog(false);
  };

  const onHideConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onProceedCancelDialog = () => {
    setCardMode(CardMode.View);
    setShowCancelDialog(false);
  };

  const onProductChange = (model) => {
    setProduct({ ...model });
  };

  const onProceedConfirmDialog = () => {
    if (!isValidated()) {
      setIsSubmit(true);
      setShowConfirmDialog(false);
      return;
    }
    const request = Object.assign(
      { },
      product
    );
    RestClient.sendPutRequest(
      `/api/v1/products/${productId}`,
      request,
      (response) => {
        toast.success("Product has been updated successfully");
        setCardMode(CardMode.View);
        setProduct(response);
        setShowConfirmDialog(false);
      },
      handleError
    );
  };
  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          {CardMode.isViewMode(cardMode) && (
            <div className="row mb-3">
              <div className="col">
                <div className="d-flex flex-row-reverse">
                <button
                    className="btn btn-outline-secondary ml-1"
                    data-target="#HistoryPopup"
                    data-toggle="modal"
                    onClick={() => setShowHistoryDialog(true)}
                  >
                    History
                  </button>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.ProductEditButtonPage
                  ) && (
                      <button
                        className="btn btn-primary"
                        value="Edit"
                        onClick={onEditProductClick}
                      >
                        <i className="fas fa-edit" /> Edit
                      </button>
                    )}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col">
              <GeneralInformationCard
                cardMode={cardMode}
                model={product}
                defaultValue={product}
                isSubmit={isSubmit}
                onModelChange={onProductChange}
                onValidationsChange={onProductValidationsChange}
              />
            </div>
          </div>
          {CardMode.isEditMode(cardMode) && (
            <div className="d-flex flex-row justify-content-end pb-3">
              <button
                className="btn btn-success mr-1"
                onClick={onShowConfirmDialog}
              >
                Save Changes
              </button>
              <button className="btn btn-danger" onClick={onShowCancelDialog}>
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
      <ConfirmDialog
        visible={showCancelDialog}
        onCancel={onHideCancelDialog}
        onProceed={onProceedCancelDialog}
        message="Are you sure you want to cancel the edit of product?"
        title="Cancel Product edit"
      />
      <ConfirmDialog
        visible={showConfirmDialog}
        onCancel={onHideConfirmDialog}
        onProceed={onProceedConfirmDialog}
        message="Are you sure you want to edit product?"
        title="Edit product"
      />
      {showHistoryDialog && (
        <HistoryDialog
          objectId={productId}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.Product}
        />
      )}
    </div>
  );
};
