import { RestService } from "./RestService";

export class PerformanceSelectorService extends RestService {
  constructor() {
    super("api/v1/performance_selectors");
  }

  applyRestrictions(body) {
    return this.postImpl("/apply_restrictions", body).then(
      (response) => response.data
    );
  }

  clearRestrictions(performanceSelectorRestrictionIds) {
    return this.postImpl("/clear_restrictions", {
      performanceSelectorRestrictionIds,
    }).then((response) => response.data);
  }
}
