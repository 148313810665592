import React from 'react';

import { StringHelper } from '../../../../common/Helpers';

import './CareerPreview.scss';

export class CareerPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const career = this.props.career || {};
        const numberOfCols = [career.responsibility, career.requirement, career.benefit].filter(col => !StringHelper.isNullOrEmpty(col)).length;
        const columnClassName = this.getColumnClassName(numberOfCols);
        return (
            <div className="main-content careers-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="heading">
                                <h2 title="Career Opportunities">Career Opportunities</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content">
                                <div className="accordion accordion-3 z-depth-1-half" role="tablist" aria-multiselectable="true">
                                    <hr className="mb-0" />
                                    <div className="card" id="careerOpportunities">
                                        <div className="card-header" role="tab">
                                            <a className="accordion-tag-custom" data-toggle="collapse" aria-expanded="true" href="#" data-bs-toggle="collapse" data-bs-target="#collapseCareerOpportunities" aria-expanded="true" aria-controls="collapseCareerOpportunities">
                                                <div className="accordheader">
                                                    <h4 className="mb-0 red-text" style={{ color: 'lightgray', paddingBottom: '16px' }}>
                                                        <i></i><strong>{career.name}</strong>  <i className={`fa fa-angle-down rotate-icon fa-2x`}></i>
                                                    </h4>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="card-body info accordion-collapse collapse show" id="collapseCareerOpportunities" aria-labelledby="careerOpportunitiesHeader" data-bs-parent="#careerOpportunities">
                                            <div className="accordion-body">
                                                <div className="tophead" dangerouslySetInnerHTML={{ __html: career.summary }}></div>
                                                <div className="pt-0">
                                                    <div className="row pre-equal">
                                                        {this.renderColumn('RESPONSIBILITIES', career.responsibility, columnClassName)}
                                                        {this.renderColumn('REQUIREMENTS', career.requirement, columnClassName)}
                                                        {this.renderColumn('BENEFITS', career.benefit, columnClassName)}
                                                    </div>
                                                </div>
                                                <div className="bottominfo" dangerouslySetInnerHTML={{ __html: career.description }}>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getColumnClassName(numOfCols) {
        const mapping = ['col-md-12', 'col-md-12', 'col-md-6', 'col-md-4'];
        return mapping[numOfCols];
    }

    renderColumn(title, html, className) {
        if (StringHelper.isNullOrEmpty(html)) {
            return null;
        }
        return (
            <div className={`${className} col-xs-12`}>
                <div className="card card-pre">
                    <div className="card-body">
                        <div className="headercard">
                            <h5 className="card-title"><strong>{title}</strong></h5>
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: html }}></div>
                        <h5 className="card-title">&nbsp;</h5>
                    </div>
                </div>
            </div>
        )
    }
}
