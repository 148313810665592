import React from "react";
import { DateHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import { Link } from "react-router-dom";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";

export class CorporateClientListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      company: [],
      search: "",
    };
  }

  async componentDidMount() {
    await RestClient.sendGetRequest(
      this.getApiPath(),
      (response) => {
        this.setState({
          statistic: response,
        });
      },
      this.handleError
    );
    await super.componentDidMount();
  }

  injectSearchTerm(queryParameters) {
    const { search } = this.state;
    return super.injectSearchTerm(queryParameters, { search });
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "corporate_clients",
        name: "Corporate Clients",
      }),
    });
  };

  getApiPath = () => "/api/v1/corporate_booking_companies";

  getDefaultSort = () => "-UpdatedOn";

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onRowClick = (id) => {
    this.props.history.push(`/corporate_clients/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Contact Person</th>
            <th>Contact Number</th>
            <th onClick={this.onColumnClick}>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>{item.clientName}</td>
                    <td>{item.contactNumber}</td>
                    <td>
                      {item.updatedOn &&
                        DateHelper.toDisplayDateTimeFormat(item.updatedOn)}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </>
    );
  }

  generateTableFilter() {
    return (
      <div className="row mb-3 justify-content-end gap-6">
        <div className="col-md-6 d-flex">
          <input
            type="text"
            id="search"
            name="search"
            placeholder="Search"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onInputChange}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" aria-hidden="true" /> Search
            </button>
          </div>
        </div>
        {AuthenticationManager.isAuthorized(
          ResourceID.CorporateClientCreateButtonPage
        ) && (
          <div className="col-12 col-md-auto width-fit pr-3">
            <Link
              className="btn btn-primary d-block"
              to={{ pathname: "/corporate_clients/create" }}
              style={{ fontSize: "1rem" }}
            >
              <i className="fas fa-plus-circle"></i> Create New Client
            </Link>
          </div>
        )}
      </div>
    );
  }
}
