import { SystemSettingKeys } from "./Constants";
import { isEmpty } from "./Common";

export class SystemSettings {
  static getSettings() {
    const value = sessionStorage.getItem(SystemSettingKeys.APP_SETTINGS);
    return isEmpty(value) ? {} : JSON.parse(value);
  }

  static get(key) {
    const settings = this.getSettings();
    return settings[key];
  }

  static set(key, value) {
    const settings = this.getSettings();
    settings[key] = value;
    sessionStorage.setItem(
      SystemSettingKeys.APP_SETTINGS,
      JSON.stringify(settings)
    );
  }

  static initial(value) {
    sessionStorage.setItem(
      SystemSettingKeys.APP_SETTINGS,
      JSON.stringify(value)
    );
  }
}
