import React, { Component } from "react";
import '../css/documentation.scss';

export class CreatePromotionHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create Promotion</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the “Promotions” link. This will take you to the Promotions Listing Page.  <br />
                                    <img src="/img/documentation-img/PromoImg/navbar.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create a promotion, click on “Create Promotion” in the top right corner of the Promotions Listing Page. <br />
                                    <img src="/img/documentation-img/PromoImg/list_createpromotion.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In Create Promotion Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Promotion Code
                                        </li>
                                        <li>
                                            Promotion Name
                                        </li>
                                        <li>
                                            Promotion Category
                                        </li>
                                        <li>
                                            Display Start DateTime
                                        </li>
                                        <li>
                                            Display End DateTime
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Promotion Image
                                        </li>
                                        <li>
                                            Promotion Description
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start DateTime should always be earlier than Display End DateTime.<br />
                                            - Promotion Description should be in HTML format.<br />
                                            - Promotion Description has a maximum of 12000 characters.
                                </div>
                            </li>
                                        <li class="createList">
                                            To preview the promotion, click on “Preview” in the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/create_preview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel the preview, click on “Cancel Preview” at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/preview_cancelpreview.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/PromoImg/create_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm".<br />
                                            <img src="/img/documentation-img/PromoImg/create_cancel_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To create the promotion, click on “Create” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/PromoImg/create_create.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to create the promotion, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/PromoImg/create_create_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful creation, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/PromoImg/create_success_toaster.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            When you return to the promotions listing page, you will see the changes made.<br />
                                            <img src="/img/documentation-img/PromoImg/create_success_endpoint.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/promotions/ViewPromotionHelp" className="btn btn-primary">&laquo; View Promotion</a>&nbsp;
                                <a href="/documentation/promotions/EditPromotionHelp" className="btn btn-primary">Edit Promotion &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}