import React from 'react';

export const CancelPreviewButton = (props) => {
    return (
        <>
          <button className="btn btn-outline-secondary mr-1 cancel-preview d-none d-lg-block" style={{
            position: "fixed",
            top: "85px",
            right: "20px",
            zIndex: 1
          }} onClick={props.onClick}>Cancel Preview</button>
          <button className="btn btn-outline-secondary mr-1 cancel-preview d-none d-md-block d-lg-none" style={{
            position: "fixed",
            top: "155px",
            right: "20px",
            zIndex: 1
          }} onClick={props.onClick}>Cancel Preview</button>
          <button className="btn btn-outline-secondary mr-1 cancel-preview d-md-none" style={{
            position: "fixed",
            top: "130px",
            right: "20px",
            zIndex: 1
          }} onClick={props.onClick}>X</button>
        </>
    )
}
