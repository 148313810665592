import React from "react";
import { log } from "../../../common/Common";
import { DateHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class EvoucherProgramListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      evoucherPrograms: [],
      programName: "",
    };
  }

  async componentDidMount() {
    await this.loadData(0);
    this.getEvoucherPrograms();
  }

  getEvoucherPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_programs/",
      (response) => {
        this.setState({ evoucherPrograms: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  getApiPath = () => "/api/v1/evoucher_programs";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "programs",
        name: "eVoucher Programs",
      }),
    });
  };

  injectSearchTerm(queryParameters) {
    const { programName } = this.state;

    return super.injectSearchTerm(queryParameters, {
      programName,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col-md-5 input-group ml-auto">
          <input
            type="text"
            name="programName"
            placeholder="Enter Program Name, Prefix"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onInputChange}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  onRowClick = (id) => {
    this.props.history.push(`/evouchers/programs/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th width="10%">Program Id</th>
            <th width="20%">Name</th>
            <th width="10%">Prefix</th>
            <th>Program Start</th>
            <th>Program End</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.prefix}</td>
                <td>{DateHelper.toDisplayDateFormat(item.programStart)}</td>
                <td>{DateHelper.toDisplayDateFormat(item.programEnd)}</td>
                <td>{DateHelper.toDisplayDateTimeFormat(item.updatedOn)}</td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
