import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import RestClient from "../../common/RestClient";
import { BreadCrumb } from "../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { CardMode } from "./cards/CardMode";
import { UserProfileCard } from "./cards/UserProfileCard";
import { ResetPasswordDialog } from "./dialogs/ResetPasswordDialog";
import { toast } from "react-toastify";

const ViewPageName = "User Profile";

export class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      profileDetail: null,
      profileDetailValidations: [],
      showResetPasswordDialog: false,
      user: null,
      userId: "",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ userId: id }, this.refreshData);
  }

  isValidated = () => {
    const { profileDetailValidations } = this.state;
    return profileDetailValidations.every((val) => val.isValid);
  };

  loadUser = (id) => {
    RestClient.sendGetRequest(
      `/api/v1/users/${id}`,
      (response) => {
        this.setState({ user: response });
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  onClearClick = () => {
    this.setState({ user: Object.assign({}, this.state.user) });
  };

  onProfileDetailModelChange = (model) => {
    const newModel = Object.assign(model, {});
    this.setState({ profileDetail: newModel });
  };

  onProfileDetailValidationsChange = (validations) => {
    this.setState({ profileDetailValidations: validations });
  };

  onHideResetPasswordDialog = () => {
    this.setState({ showResetPasswordDialog: false });
  };

  onResetPasswordDialogSuccess = () => {
    this.setState({
      showResetPasswordDialog: false,
    });
    toast.success("The password is resetted successfully");
  };

  onResetPasswordClick = () => {
    this.setState({ showResetPasswordDialog: true });
  };

  onViewHistoryClick = () => {
    this.setState({ showHistoryDialog: true });
  };

  refreshData() {
    const { userId } = this.state;
    this.loadUser(userId);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "user-managements",
        name: "User Management",
      }),
      activeModule: new NavigationItem({
        identifier: "user-profile",
        name: ViewPageName,
      }),
    });
  };

  render() {
    const { cardMode, isSubmit, showResetPasswordDialog, user, profileDetail } =
      this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row mb-3">
              <div className="col">
                <div className="d-flex flex-row-reverse">
                  {AuthenticationManager.isAuthorized(
                    ResourceID.ResetPasswordButton
                  ) && (
                    <button
                      className="btn btn-primary ml-1"
                      disabled={user ? undefined : "disabled"}
                      onClick={this.onResetPasswordClick}
                    >
                      Change Password
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <UserProfileCard
                  cardMode={cardMode}
                  defaultValue={user}
                  departmentId={profileDetail?.departmentId}
                  designationId={profileDetail?.designationId}
                  isSubmit={isSubmit}
                  onModelChange={this.onProfileDetailModelChange}
                  onValidationsChange={this.onProfileDetailValidationsChange}
                />
              </div>
            </div>
          </div>
        </div>
        {showResetPasswordDialog && user && (
          <ResetPasswordDialog
            onClose={this.onHideResetPasswordDialog}
            onSuccess={this.onResetPasswordDialogSuccess}
            username={user.username}
          />
        )}
      </div>
    );
  }
}
