import RestClient from "../common/RestClient";

export class BookingRequestService {
    constructor() {
        this.baseApi = "/api/v1/corporate_booking_requests";
    }

    get(id, onSuccess, onError) {
        return RestClient.sendGetRequest(`${this.baseApi}/${id}`,
            response => {
                if (onSuccess) {
                    onSuccess(response)
                }
            }, error => {
                if (onError) {
                    onError(error)
                }
                console.error(error);
            });
    }

    getAll(parameters, onSuccess, onError) {
        return RestClient.sendGetRequestWithParameters(`${this.baseApi}`, 
            parameters,
            response => {
                if (onSuccess) {
                    onSuccess(response)
                }
            }, error => {
                if (onError) {
                    onError(error)
                }
                console.error(error);
            });
    }
    
    update(bookingRequest, onSuccess, onError) {
        return RestClient.sendPutRequest(`${this.baseApi}/${bookingRequest.id}`, {
                personInCharge: bookingRequest.personInCharge
            },
            response => {
                if (onSuccess) {
                    onSuccess(response)
                }
            }, error => {
                if (onError) {
                    onError(error)
                }
                console.error(error);
            });
    }
}