import React from "react";
import { HistoryDialog } from "./HistoryDialog";
import { HallTransactionEnquiryKeys } from "../../constants/TransactionConstants";

export class EVoucherHistoryDialog extends HistoryDialog {
  renderHistoryDetailTableBody = () => {
    const { message } = this.state;
    const { getStatusColor } = this.props;
    return (
      <tbody>
        <tr>
          <td
            className={getStatusColor(message.oldValue)}
            style={{ whiteSpace: "unset" }}
          >
            {message.oldValue}
          </td>
          <td
            className={getStatusColor(message.newValue)}
            style={{ whiteSpace: "unset" }}
          >
            {this.isJson(message.newValue)
              ? Object.entries(JSON.parse(message.newValue)).map(
                  ([key, value]) => {
                    if (key === HallTransactionEnquiryKeys.LocationId) {
                      value = this.getLocation(value);
                    }
                    if (key === HallTransactionEnquiryKeys.TimeFrame) {
                      value = this.getTimeFrameString(value);
                    }
                    return (
                      <p>
                        <span>
                          {HallTransactionEnquiryKeys.getName(key)}:
                          {value?.toString()}
                        </span>
                      </p>
                    );
                  }
                )
              : message.newValue}
          </td>
        </tr>
      </tbody>
    );
  };
}
