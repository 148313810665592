import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ExtensionBy, ExtensionMode, PassExtensionConstrain, SearchPassMode } from "../common/Constants";
import { PassExtendSection } from "./cards/PassExtendSection";
import { PassFilterSection } from "./cards/PassFilterSection";
import { PassTableSection } from "./cards/PassTableSection";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class PassExtend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      extensionBy: ExtensionBy.None,
      extensionMode: ExtensionMode.None,
      extensionQuantity: 0,
      isDirty: false,
      isSearch: false,
      newExpiryDate: null,
      pageIndex: 1,
      pageSize: 10,
      passProgramId: "",
      quantityOfDate: 0,
      searchBy: "",
      serialNumbers: "",
      serialNumberEnd: "",
      serialNumberStart: "",
      showConfirmDialog: false,
      total: 0
    };
    this.passExtendSection = React.createRef();
  }

  extendPasses() {
    const {
      extensionBy,
      extensionMode,
      extensionQuantity,
      newExpiryDate,
      passProgramId,
      serialNumbers,
      serialNumberEnd,
      serialNumberStart,
    } = this.state;
    let request = {
      extensionMode: ExtensionMode.None,
      extensionQuantity: extensionQuantity,
      expiryDate: newExpiryDate,
      passCodes: serialNumbers,
      passCodeFrom: serialNumberStart,
      passCodeTo: serialNumberEnd,
      passProgram: passProgramId     
    };
    if (extensionBy === ExtensionBy.Date) {
      request.extensionMode = ExtensionMode.BySpecifiedDate;
    }
    if (extensionBy === ExtensionBy.Custom) {
      request.extensionMode = extensionMode;
    }

    RestClient.sendPutRequest(
      "/api/v1/passes/extend",
      request,
      () => {
        toast.success("The following passes has been extended.");
        this.setState({
          isDirty: false,
          isSearch: false,
        });
        this.loadPasses();
      },
      (error) => toast.error(error.message)
    );

    this.setState({
      showConfirmDialog: false,
    });
  }

  loadPasses = () => {
    const {
      passProgramId,
      pageIndex,
      pageSize,
      serialNumbers,
      serialNumberEnd,
      serialNumberStart,
      searchBy
    } = this.state;
    const totalPassCode = serialNumbers.split(',').length;
    if (searchBy === SearchPassMode.Single && totalPassCode > PassExtensionConstrain.MaxSinglePassCode) {
      toast.error(` A maximum of ${PassExtensionConstrain.MaxSinglePassCode} single serial numbers are allowed`);
      return;
    }
    const request = {
      passCodes: searchBy === SearchPassMode.Single ? serialNumbers : '',
      passCodeFrom: serialNumberStart,
      passCodeTo: serialNumberEnd,
      passProgramId: passProgramId || 0,
      startAt: pageSize * (pageIndex - 1),
      maxResults: pageSize,
    };
    RestClient.sendGetRequestWithParameters(
      "/api/v1/passes",
      request,
      (response) => {
        this.setState({
          data: response.data,
          total: response.total,
          isDirty: false,
          isSearch: true,
        });
      },
      (error) => toast.error(error.message)
    );
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onPassExtendChange = (model) => {
    this.setState(model);
  };

  onPassFilterChange = (model) => {
    const newState = Object.assign({ isDirty: true }, model);
    this.setState(newState);
  };

  onPassTableChange = (model) => {
    this.setState(model, this.loadPasses);
  };

  onProceedConfirmDialog = () => {
    this.extendPasses();
  };

  onSearchClick = () => {
    this.loadPasses();
  };

  onShowConfirmDialog = () => {
    this.setState({ showConfirmDialog: true });
  };

  onClear = () => {
    const newModel = {
      extensionBy: ExtensionBy.None,
      extensionMode: ExtensionMode.BySpecifiedDate,
      extensionQuantity: 0,
      newExpiryDateStr: '',
      quantityOfDate: 0
    };
    this.onPassExtendChange(newModel);
    this.passExtendSection.current.clear();
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "passes-extension",
        name: "Passes Extension",
      }),
    });
  };

  render() {
    const {
      data,
      isDirty,
      isSearch,
      pageIndex,
      pageSize,
      showConfirmDialog,
      total,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-body">
                      <PassFilterSection
                        onModelChange={this.onPassFilterChange}
                        onSearch={this.onSearchClick}
                      />
                      {isSearch && (
                        <PassTableSection
                          data={data}
                          pageIndex={pageIndex}
                          pageSize={pageSize}
                          onModelChange={this.onPassTableChange}
                          total={total}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isSearch && (
                <React.Fragment>
                  <div className="row extension">
                    <div className="col">
                      <PassExtendSection
                        onModelChange={this.onPassExtendChange}
                        ref={this.passExtendSection}
                      />
                    </div>
                  </div>
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success"
                      id="btnExtension"
                      disabled={isDirty ? "disabled" : undefined}
                      onClick={this.onShowConfirmDialog}
                    >
                      Extend
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item className="btn btn-danger" id="btnClear"
                      onClick={this.onClear}
                    >
                      Clear
                    </ButtonActionDetailGroup.Item>
                  </ButtonActionDetailGroup>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="You are requesting to extend these pass(es) by pre-defined date from existing pass expiry date. Please confirm to continue."
          title="Confirmation"
        />
      </>
    );
  }
}
