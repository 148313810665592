import React from "react";
import Validators from "../../common/Validators";
import { DateHelper } from "../../../common/Helpers";

export class BaseExtendValidityDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newValidityEnd: "",
      reason: "",
      validations: [],
      isSubmitted: props.isSubmitted,
    };
  }

  onTexboxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    this.setState({ [fieldName]: e.target.value });
    this.setState({ isSubmitted: true });
  };

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  onSubmitClick = () => {};

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      onValidationsChange([...this.state.validations]);
    }
  };

  onValidationStatusChange = (e) => {
    const { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  render() {
    const { newValidityEnd, reason, isSubmitted } = this.state;
    const { currentExpiredDate, reasonType, placeholderText } = this.props;
    return (
      <>
        <div
          className="modal fade show"
          id="extendBatchValidity"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Create Extension Request
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {currentExpiredDate && (
                  <div className="row form-group">
                    <label
                      className="col-md-5 form-control-label"
                      htmlFor="currentExpiryValue"
                    >
                      Current Expiry Date:
                    </label>
                    <div className="col-md-7">
                      <input
                        className="display-input Date"
                        id="currentExpiryValue"
                        value={DateHelper.toDisplayDateFormat(
                          currentExpiredDate
                        )}
                        readOnly
                      />
                    </div>
                  </div>
                )}
                <div className="row form-group">
                  <label
                    className="col-md-5 form-control-label"
                    htmlFor="newValidityEnd"
                  >
                    New Expiry Date:
                  </label>
                  <div className="col-md-7">
                    <input
                      className="form-control display-input"
                      type="date"
                      id="newValidityEnd"
                      value={newValidityEnd}
                      fieldname="newValidityEnd"
                      onChange={this.onTexboxChange}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldname="newValidityEnd"
                      isEnabled={isSubmitted}
                      property="Validity Start"
                      value={newValidityEnd}
                    />

                    <Validators.DateTimeFromToValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldname="newValidityEnd"
                      isEnabled={isSubmitted}
                      fromProperty="New Expiry Date"
                      fromValue={currentExpiredDate}
                      toProperty="Current Expiry Date"
                      toValue={newValidityEnd}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label
                    className="col-md-5 form-control-label"
                    htmlFor="reason"
                  >
                    {reasonType}
                    <span className="color-red">*</span>
                  </label>
                  <div className="col-md-7">
                    <textarea
                      rows="4"
                      placeholder={placeholderText}
                      id="reason"
                      fieldname="reason"
                      className="form-control"
                      onChange={this.onTexboxChange}
                      value={reason}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldname="reason"
                      isEnabled={isSubmitted}
                      property={reasonType}
                      value={reason}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onSubmitClick}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </>
    );
  }
}
