import { atom, DefaultValue, selector, useResetRecoilState } from "recoil";
import { Campaign } from "../../models/Campaign";
import { CampaignExtensionProperties } from "./availability/view-models/CampaignExtensionProperties";
import _ from "lodash";
import {
  CampaignQuantityAvailabilityItem,
  CampaignRedemptionLocation,
  CampaignSalesChannel,
} from "../../models/CampaignAvailability";
import { BundleSalesChannels } from "./bundles/CampaignBundleItem";
import { useEffect } from "react";

export const CampaignActivePanel = {
  Detail: "Campaign Detail",
  Availability: "Campaign Availability",
  ConditionRestriction: "Campaign Validity Conditions/Restrictions",
  Bundle: "Bundle",
  CustomizeTicket: "Customize Ticket",
};

export const campaignGlobalRefreshAtom = atom({
  key: "campaignGlobalRefresh",
  default: 0,
});

export const campaignActivePanelAtom = atom({
  key: "campaignActivePanel",
  default: "",
});

export const campaignAtom = atom({
  key: "campaign",
  default: new Campaign({}),
});

export const locationsAtom = atom({
  key: "locations",
  default: [],
});

export const moviesAtom = atom({
  key: "movies",
  default: [],
});

export const productsAtom = atom({
  key: "products",
  default: [],
});

export const campaignBundlesAtom = atom({
  key: "campaignBundles",
  default: [],
});

export const campaignAvailabilityAtom = atom({
  key: "campaignAvailability",
  default: [],
});

export const stackCampaignsAtom = atom({
  key: "stackCampaigns",
  default: [],
})

export const locationVenuesSelector = selector({
  key: "locationVenuesSelector",
  get: ({ get }) => {
    const locations = get(locationsAtom);
    return locations.flatMap((x) => x.locationVenues);
  },
});

export const createPerformanceConditionSelector = selector({
  key: "createPerformanceConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificPerformance: true,
      validForSpecificMovie: false,
      validForSpecificBrandFormat: false,
      validForSpecificDayTime: false,
      validForSpecificLocationVenue: false,
      validForSpecificClassification: false,
    });
  },
});

export const validForSpecificMovieSelector = selector({
  key: "createMovieConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificMovie: newValue,
    });
  },
});

export const validForSpecificBrandFormatSelector = selector({
  key: "createBrandFormatConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificBrandFormat: newValue,
    });
  },
});

export const validForSpecificDayTimeSelector = selector({
  key: "createDayTimeConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificDayTime: newValue,
    });
  },
});

export const validForSpecificLocationVenueSelector = selector({
  key: "createLocationVenueConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificLocationVenue: newValue,
    });
  },
});

export const validForSpecificClassificationSelector = selector({
  key: "createClassificationConditionSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificClassification: newValue,
    });
  },
});

export const validForSpecificCreditCardSelector = selector({
  key: "validForSpecificCreditCardSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificCreditCard: newValue,
    });
  },
});

export const validForSpecificPerformanceSelector = selector({
  key: "validForSpecificPerformanceSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificPerformance: newValue,
    });
  },
});

export const validForSpecificTicketTypeSelector = selector({
  key: "validForSpecificTicketTypeSelector",
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    set(campaignAtom, {
      ...campaign,
      validForSpecificTicketType: newValue,
    });
  },
});

export const campaignSalesChannelsSelector = selector({
  key: "campaignSalesChannelsSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    return campaign.campaignSalesChannels.map((x) => x.appId);
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    const campaign = get(campaignAtom);
    const cloned = _.cloneDeep(campaign);
    cloned.campaignSalesChannels = newValue.map(
      (x) => new CampaignSalesChannel({ appId: x, campaignId: campaign.id })
    );
    set(campaignAtom, cloned);
  },
});

export const campaignExtensionPropertiesSelector = selector({
  key: "campaignExtensionPropertiesSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    return new CampaignExtensionProperties(campaign);
  },
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    if (newValue instanceof DefaultValue) {
      return;
    }
    const cloned = _.cloneDeep(campaign);
    Object.assign(cloned, { ...newValue });
    set(campaignAtom, cloned);
  },
});

export const campaignRedemptionLocationsSelector = selector({
  key: "campaignRedemptionLocationsSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    return campaign.campaignRedemptionLocations.map((x) => x.locationId);
  },
  set: ({ get, set }, newValue) => {
    const campaign = get(campaignAtom);
    if (newValue instanceof DefaultValue) {
      return;
    }
    const cloned = _.cloneDeep(campaign);
    cloned.campaignRedemptionLocations = newValue.map(
      (x) =>
        new CampaignRedemptionLocation({
          locationId: x,
          campaignId: campaign.id,
        })
    );
    set(campaignAtom, cloned);
  },
});

export const sharedQuantityAvailabilitySelector = selector({
  key: "sharedQuantityAvailabilitySelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    return campaign.campaignQuantityAvailability;
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    const campaign = get(campaignAtom);
    const editableCampaign = _.cloneDeep(campaign);
    editableCampaign.campaignQuantityAvailability = [];
    editableCampaign.campaignQuantityAvailability.push(
      new CampaignQuantityAvailabilityItem({ quantity: newValue })
    );
    set(campaignAtom, editableCampaign);
  },
});

export const quantityAppSpecificSelector = selector({
  key: "quantityAppSpecificSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    const result = {};
    (campaign.campaignQuantityAvailability ?? []).forEach((x) => {
      result[`${x.appId}`] = x.quantity;
    });
    return result;
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    const campaign = get(campaignAtom);
    const editableCampaign = _.cloneDeep(campaign);
    editableCampaign.campaignQuantityAvailability = [];
    if (newValue <= 0) {
      return;
    }
    Object.entries(newValue).forEach(([key, value]) => {
      editableCampaign.campaignQuantityAvailability.push(
        new CampaignQuantityAvailabilityItem({
          appId: +key,
          quantity: value,
        })
      );
    });
    set(campaignAtom, editableCampaign);
  },
});

export const quantityLocationSpecificSelector = selector({
  key: "quantityLocationSpecificSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    const result = {};
    (campaign.campaignQuantityAvailability ?? [])
      .filter((x) => x.locationId > 0)
      .forEach((x) => {
        result[`${x.locationId}`] = x.quantity;
      });
    return result;
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    const campaign = get(campaignAtom);
    const editableCampaign = _.cloneDeep(campaign);
    editableCampaign.campaignQuantityAvailability = [];
    Object.entries(newValue).forEach(([key, value]) => {
      editableCampaign.campaignQuantityAvailability.push(
        new CampaignQuantityAvailabilityItem({
          locationId: +key,
          quantity: value,
        })
      );
    });
    set(campaignAtom, editableCampaign);
  },
});

export const quantityApplicationLocationSpecificSelector = selector({
  key: "quantityApplicationLocationSpecificSelector",
  get: ({ get }) => {
    const campaign = get(campaignAtom);
    const result = {};
    BundleSalesChannels.forEach((appId) => {
      const items = campaign.campaignQuantityAvailability.filter(
        (y) => y.appId === appId
      );
      if (!items || _.isEmpty(items)) {
        return;
      }
      result[`${appId}`] = {};
      items.forEach((item) => {
        if (item.locationId > 0) {
          result[`${appId}`][`${item.locationId}`] = item.quantity;
        }
      });
    });
    return result;
  },
  set: ({ set, get }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }
    const campaign = get(campaignAtom);
    const editableCampaign = _.cloneDeep(campaign);
    editableCampaign.campaignQuantityAvailability = [];
    Object.entries(newValue).forEach(([appId, locationAndQuantity]) => {
      const locationIds = Object.keys(locationAndQuantity);
      if (_.isEmpty(locationIds)) {
        editableCampaign.campaignQuantityAvailability.push(
          new CampaignQuantityAvailabilityItem({
            appId: +appId,
          })
        );
        return;
      }
      locationIds.forEach((locationId) => {
        const quantity = locationAndQuantity[locationId];
        editableCampaign.campaignQuantityAvailability.push(
          new CampaignQuantityAvailabilityItem({
            appId: +appId,
            locationId: +locationId,
            quantity,
          })
        );
      });
    });
    set(campaignAtom, editableCampaign);
  },
});

export const isSelectedPerformanceConditionsAtom = atom({
  key: "isSelectedPerformanceConditions",
  default: false,
});

export function useResetStatesOnDestroy() {
  const resetRecoilStates = [
    useResetRecoilState(productsAtom),
    useResetRecoilState(campaignActivePanelAtom),
    useResetRecoilState(campaignAtom),
    useResetRecoilState(locationsAtom),
    useResetRecoilState(campaignBundlesAtom),
    useResetRecoilState(campaignAvailabilityAtom),
    useResetRecoilState(isSelectedPerformanceConditionsAtom),
    useResetRecoilState(campaignGlobalRefreshAtom),
    useResetRecoilState(moviesAtom),
  ];

  useEffect(() => {
    _.noop();

    return () => {
      resetRecoilStates.forEach((resetState) => {
        resetState();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
