import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateEvoucherBatch extends Component {

    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create eVoucher Batch</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>17/1/2022 ▪ 2 minutes to read</p>
                        <br /><br />
                        <div className="eVoucher container-fluid">
                            <ol>
                                <li class="createList">
                                    To create an eVoucher batch, select eVouchers, then eVouchers Orders on the menu. This will direct you to the listing page.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/eVoucherOrders.png" class="userImage" /><br /><br />
                                    <img src="/img/documentation-img/evouchers/eVoucherOrder/eVoucherOrderListing.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Upon selecting an eVoucher, you will be redirected to the View eVouchers Detail page.<br />
                                </li>
                                <li class="createList">
                                    Click the ‘Create eVoucher Batch’ button on the top right of the page to start creation of the new eVoucher batch.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherBatch/Createevoucherbatch.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Upon clicking the button, you will be redirected to the Create eVoucher batch page. To create the new voucher batch, fill in the parameters for ‘Batch Quantity’, ‘Validity Start’ and ‘Expiry Date’. Then you will see 2 main action buttons.
                                    <ul class="unorderedList">
                                        <li>Submit for Approval – Creates the eVoucher Batch.</li>
                                        <li>
                                            Clear – erases all data from the current fields.<br />
                                            <img src="/img/documentation-img/evouchers/eVoucherBatch/Createevoucherbatches.png" class="userImage" />
                                        </li>
                                    </ul>
                                </li>
                                <li class="createList">
                                    Upon clicking ‘Submit for Approval’, the following confirmation prompt will appear. Clicking ‘Confirm’ will create the eVoucher Batch.<br />
                                    <img src="/img/documentation-img/evouchers/eVoucherBatch/submitapprovalprompt.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/evouchers/createCancelOrder" className="btn btn-primary">&laquo; Create/Cancel EVoucher Order</a>&nbsp;
                                <a href="/documentation/evouchers/approveCancelRejectBatch" className="btn btn-primary">Approve/Cancel/Reject Batch &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
