import React, { useState } from "react";
import { FieldWrapper } from "../../FieldWrapper";
import { TextInput } from "../../TextInput";
import { NumberInput } from "../../NumberInput";
import Multiselect from "multiselect-react-dropdown";
import { DateTimeInput } from "../../DateTimeInput";
import { SeatsCondition } from "../../../../constants/CampaignConstants";
import { ApplicationID } from "../../../../common/Constants";
import { BundleSalesChannels } from "../CampaignBundleItem";
import _ from "lodash";
import { useStackCampaigns } from "../../useStackCampaigns";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignActivePanelAtom,
  campaignAtom,
  campaignRedemptionLocationsSelector,
  campaignSalesChannelsSelector,
} from "../../CampaignState";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import { ConfirmDialog } from "../../../common/ConfirmDialog";

const CampaignBundleItemGeneralForm = (props) => {
  const { instance, onSave, onChange, locations } = props;
  const campaign = useRecoilValue(campaignAtom);
  const { stackCampaigns } = useStackCampaigns(campaign.id);
  const redemptionLocations = useRecoilValue(
    campaignRedemptionLocationsSelector
  );
  const salesChannels = useRecoilValue(campaignSalesChannelsSelector);
  const setActivePanel = useSetRecoilState(campaignActivePanelAtom);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);

  const onInputChange = (e) => {
    const editableInstance = _.cloneDeep(instance);
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    editableInstance[fieldName] = selectValue;
    onChange(editableInstance);
  };

  const getSelectedCampaigns = () => {
    return (stackCampaigns ?? []).filter((x) =>
      instance.stackCodes.includes(x.name)
    );
  };

  const onMultipleSelectorSelect = (source) => {
    onChange((bundle) => {
      return {
        ...bundle,
        stackCodes: source.map((x) => x.name),
      };
    });
  };

  const onMultipleSelectorRemove = (source) => {
    onChange((bundle) => {
      return {
        ...bundle,
        stackCodes: source.map((x) => x.name),
      };
    });
  };

  const onSelectAllSaleChannels = () => {
    onChange((d) => {
      return {
        ...d,
        appIds: BundleSalesChannels.filter((x) => salesChannels.includes(x)),
      };
    });
  };

  const onSelectSalesChannel = (e) => {
    const editableInstance = _.cloneDeep(instance);
    if (e.target.checked) {
      editableInstance.appIds.push(+e.target.value);
    } else {
      editableInstance.appIds = instance.appIds.filter(
        (x) => x !== +e.target.value
      );
    }
    onChange(editableInstance);
  };

  const onSelectAllLocation = () => {
    onChange((d) => {
      return {
        ...d,
        locationIds: locations
          .map((x) => x.id)
          .filter((x) => redemptionLocations.includes(x)),
      };
    });
  };

  const onSelectLocation = (e) => {
    const editableInstance = _.cloneDeep(instance);
    if (e.target.checked) {
      editableInstance.locationIds.push(+e.target.value);
    } else {
      editableInstance.locationIds = editableInstance.locationIds.filter(
        (x) => x !== +e.target.value
      );
    }
    onChange(editableInstance);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          <FieldWrapper
            label="Display Name"
            require={true}
            additionalCss="form-group"
            input={
              <TextInput
                name="displayName"
                value={instance.displayName}
                onChange={onInputChange}
                placeholder="Enter Display Name"
              />
            }
          />
          <FieldWrapper
            label="Print Name"
            require={true}
            additionalCss="form-group"
            input={
              <TextInput
                name="printName"
                value={instance.printName}
                onChange={onInputChange}
                placeholder="Enter Print Name"
              />
            }
          />
          <FieldWrapper
            label="Description"
            require={true}
            additionalCss="form-group"
            input={
              <TextInput
                name="description"
                value={instance.description}
                onChange={onInputChange}
              />
            }
          />
          <FieldWrapper
            label="Poster URL"
            require={true}
            additionalCss="form-group"
            input={
              <TextInput
                name="posterUrl"
                value={instance.posterUrl}
                onChange={onInputChange}
                placeholder="Enter Poster URL"
              />
            }
          />
          <FieldWrapper
            label="Display Weight"
            require={true}
            additionalCss="form-group"
            input={
              <NumberInput
                name="displayWeight"
                value={instance.displayWeight}
                onChange={onInputChange}
                placeholder="Enter Display Weight"
              />
            }
          />
          <FieldWrapper
            label="Campaign Stack"
            additionalCss="form-group"
            input={
              <Multiselect
                options={stackCampaigns}
                selectedValues={getSelectedCampaigns()}
                onSelect={onMultipleSelectorSelect}
                onRemove={onMultipleSelectorRemove}
                displayValue="name"
                avoidHighlightFirstOption={true}
              />
            }
          />
          <FieldWrapper
            label="Start On"
            require={true}
            additionalCss="form-group"
            input={
              <DateTimeInput
                name="startOn"
                value={instance.startOn}
                onChange={onInputChange}
              />
            }
          />
          <FieldWrapper
            label="End On"
            require={true}
            additionalCss="form-group"
            input={
              <DateTimeInput
                name="endOn"
                value={instance.endOn}
                onChange={onInputChange}
              />
            }
          />
          <FieldWrapper
            label="Performance Start On"
            require={true}
            additionalCss="form-group"
            input={
              <DateTimeInput
                name="performanceStartOn"
                value={instance.performanceStartOn}
                onChange={onInputChange}
              />
            }
          />
          <FieldWrapper
            label="Performance End On"
            require={true}
            additionalCss="form-group"
            input={
              <DateTimeInput
                name="performanceEndOn"
                value={instance.performanceEndOn}
                onChange={onInputChange}
              />
            }
          />
          <FieldWrapper
            label="No of Seats Condition:"
            require={true}
            additionalCss="form-group"
            input={SeatsCondition.All.map((x) => (
              <div key={x.value}>
                <input
                  className="mr-1"
                  type="radio"
                  valuetype="number"
                  name="seatCondition"
                  value={x.value}
                  checked={instance.seatCondition === x.value}
                  onChange={onInputChange}
                />
                <label className="form-check-label">{x.name}</label>
              </div>
            ))}
          />
          {instance.seatCondition !== SeatsCondition.NoCondition && (
            <FieldWrapper
              additionalCss="form-group"
              input={
                <NumberInput
                  name="numberOfSeats"
                  value={instance.numberOfSeats}
                  onChange={onInputChange}
                  placeholder="Enter Number of Seats"
                />
              }
            />
          )}

          <FieldWrapper
            label={
              <>
                <label className="form-control-label">
                  Sales Channels:
                  <span className="color-red font-weight-bold">*</span>
                </label>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onSelectAllSaleChannels}
                >
                  Select all
                </button>
              </>
            }
            additionalCss="form-group"
            input={
              <div className="row">
                {ApplicationID.All.filter(
                  (x) => BundleSalesChannels.indexOf(x.value) >= 0
                ).map((x, index) => (
                  <div className="col-md-3" key={index}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      value={x.value}
                      onChange={onSelectSalesChannel}
                      checked={
                        instance.appIds.indexOf(x.value) >= 0 &&
                        salesChannels.includes(x.value)
                      }
                      disabled={!salesChannels.includes(x.value)}
                    />
                    <label className="form-check-label">{x.name}</label>
                  </div>
                ))}
              </div>
            }
          />

          <FieldWrapper
            label={
              <>
                <label className="form-control-label">
                  Redemption Location:
                  <span className="color-red font-weight-bold">*</span>
                </label>
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={onSelectAllLocation}
                >
                  Select all
                </button>
              </>
            }
            additionalCss="form-group"
            input={
              <div className="row">
                {locations.map((x) => (
                  <div className="col-md-12" key={x.id}>
                    <input
                      className="mr-1"
                      type="checkbox"
                      value={x.id}
                      onChange={onSelectLocation}
                      checked={instance.locationIds.indexOf(x.id) >= 0}
                      disabled={!redemptionLocations.includes(x.id)}
                    />
                    <label className="form-check-label">{x.name}</label>
                  </div>
                ))}
              </div>
            }
          />
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <img src="img/testqrcode.png" />
            </div>
          </div>
        </div>
      </div>
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          onClick={() => setShowSaveConfirmation(true)}
        >
          Save
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          onClick={() => setActivePanel("")}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
      <ConfirmDialog
        visible={showSaveConfirmation}
        message="Are you sure you want to save the changes?"
        onProceed={() => {
          setShowSaveConfirmation(false);
          onSave();
        }}
        onCancel={() => setShowSaveConfirmation(false)}
      />
    </>
  );
};

export default CampaignBundleItemGeneralForm;
