import { useCallback } from "react";
import { ReportParameterValueType } from "./Constants";
import { useReportingLocations } from "../useReportingLocations";
import { useReportingCampaigns } from "../useReportingCampaigns";
import { useReportingDistributions } from "../useReportingDistributions";
import { useReportingFnbCampaigns } from "../useReportingFnbCampaigns";
import { useReportingSafraCampaigns } from "../useReportingSafraCampaigns";
import { useReportingMovieLanguages } from "../useReportingMovieLanguages";
import {
  useAdmissionTypes,
  useCreditCardTypes,
  useMonthSelections,
  useMovieFormats,
  usePerformanceTypes,
  useSystemComponents,
  useTheatreCompanyTypes,
  useVoucherProgramMediums,
  useVoucherProgramTypes,
  useYearSelections,
} from "../customHooks";
import { useReportingLocationAreaCodes } from "../useReportingLocationAreaCodes";
import {useReportingFilmFestivals} from "../useReportingFilmFestival";

export class ReportSelectionType {
  static AdmissionType = "AdmissionType";
  static CampaignCode = "CampaignCode";
  static CampaignCodeFBVG = "CampaignCodeFBVG";
  static CampaignCodeSAFRA = "CampaignCodeSAFRA";
  static CreditCardTypeCode = "CreditCardTypeCode";
  static DistributorCode = "DistributorCode";
  static FilmFestivalCode = "FilmFestivalCode";
  static EventFormatCode = "EventFormatCode";
  static LanguageCode = "LanguageCode";
  static LocationAreaPOSCode = "LocationAreaPOSCode";
  static LocationCode = "LocationCode";
  static PerformanceTypeCode = "PerformanceTypeCode";
  static SystemComponentCode = "SystemComponentCode";
  static TheatresCompanyType = "TheatresCompanyType";
  static VoucherProgramMedium = "VoucherProgramMedium";
  static VoucherProgramType = "VoucherProgramType";
  static ShowDateFromYear = "ShowDateFromYear";
  static ShowDateFromMonth = "ShowDateFromMonth";
  static ShowDateToMonth = "ShowDateToMonth";
  static ShowDateToYear = "ShowDateToYear";
}

export function useReportInputFormSchema(report) {
  const locations = useReportingLocations();
  const campaigns = useReportingCampaigns();
  const distributions = useReportingDistributions();
  const filmFestivals = useReportingFilmFestivals();
  const fnbCampaigns = useReportingFnbCampaigns();
  const safraCampaigns = useReportingSafraCampaigns();
  const languages = useReportingMovieLanguages();
  const locationAreas = useReportingLocationAreaCodes();
  const admissionTypes = useAdmissionTypes();
  const creditCardTypes = useCreditCardTypes();
  const movieFormats = useMovieFormats();
  const systemComponents = useSystemComponents();
  const performanceTypes = usePerformanceTypes();
  const theatreCompanyTypes = useTheatreCompanyTypes();
  const voucherProgramMediums = useVoucherProgramMediums();
  const voucherProgramTypes = useVoucherProgramTypes();
  const years = useYearSelections();
  const months = useMonthSelections();

  const customizeOptionField = (formItem) => {
    if (
      [
        ReportSelectionType.ShowDateFromYear,
        ReportSelectionType.ShowDateFromMonth,
        ReportSelectionType.ShowDateToYear,
        ReportSelectionType.ShowDateToMonth,
      ].includes(formItem.code)
    ) {
      formItem.valueOptionField = "id";
    }
  };

  const adaptSelection = useCallback((
    formItem,
    dataSources,
    selectionTypes,
    compareFunc
  ) => {
    if (selectionTypes.includes(formItem.code)) {
      formItem.options = dataSources;
      formItem.displayOptionField = "name";
      formItem.valueOptionField = "code";
      formItem.isSelection = true;
      formItem.compareFunc = compareFunc;
      formItem.defaultSelection = dataSources.find((x) => x.isDefault)?.code;
      customizeOptionField(formItem);
    }
    return formItem;
  }, []);

  const locationSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, locations, [ReportSelectionType.LocationCode]),
    [adaptSelection, locations]
  );

  const campaignSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, campaigns, [ReportSelectionType.CampaignCode]),
    [adaptSelection, campaigns]
  );

  const distributionSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, distributions, [
        ReportSelectionType.DistributorCode,
      ]),
    [adaptSelection, distributions]
  );

  const filmFestivalSelectionAdapter = useCallback(
      (formItem) =>
          adaptSelection(formItem, filmFestivals, [
            ReportSelectionType.FilmFestivalCode,
          ]),
      [adaptSelection, filmFestivals]
  );

  const fnbCampaignsSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, fnbCampaigns, [
        ReportSelectionType.CampaignCodeFBVG,
      ]),
    [adaptSelection, fnbCampaigns]
  );

  const safraCampaignsSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, safraCampaigns, [
        ReportSelectionType.CampaignCodeSAFRA,
      ]),

    [adaptSelection, safraCampaigns]
  );

  const movieLanguagesSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, languages, [ReportSelectionType.LanguageCode]),
    [adaptSelection, languages]
  );

  const admissionTypeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, admissionTypes, [
        ReportSelectionType.AdmissionType,
      ]),
    [adaptSelection, admissionTypes]
  );

  const movieFormatSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, movieFormats, [
        ReportSelectionType.EventFormatCode,
      ]),
    [adaptSelection, movieFormats]
  );

  const creditCardTypeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, creditCardTypes, [
        ReportSelectionType.CreditCardTypeCode,
      ]),
    [adaptSelection, creditCardTypes]
  );

  const locationAreaCodeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, locationAreas, [
        ReportSelectionType.LocationAreaPOSCode,
      ]),
    [adaptSelection, locationAreas]
  );

  const systemComponentSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, systemComponents, [
        ReportSelectionType.SystemComponentCode,
      ]),
    [adaptSelection, systemComponents]
  );

  const performanceTypeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, performanceTypes, [
        ReportSelectionType.PerformanceTypeCode,
      ]),
      [adaptSelection, performanceTypes]
  );

  const theatreCompanyTypeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, theatreCompanyTypes, [
        ReportSelectionType.TheatresCompanyType,
      ]),
    [adaptSelection, theatreCompanyTypes]
  );

  const voucherProgramTypeSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, voucherProgramTypes, [
        ReportSelectionType.VoucherProgramType,
      ]),
    [adaptSelection, voucherProgramTypes]
  );

  const voucherProgramMediumSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, voucherProgramMediums, [
        ReportSelectionType.VoucherProgramMedium,
      ]),
    [adaptSelection, voucherProgramMediums]
  );

  const showYearSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(formItem, years, [
        ReportSelectionType.ShowDateFromYear,
        ReportSelectionType.ShowDateToYear,
      ]),
    [adaptSelection, years]
  );

  const showMonthSelectionAdapter = useCallback(
    (formItem) =>
      adaptSelection(
        formItem,
        months,
        [
          ReportSelectionType.ShowDateFromMonth,
          ReportSelectionType.ShowDateToMonth,
        ],
        (x, y) => 0
      ), // To bypass the default sorting of CustomSelect,
    [adaptSelection, months]
  );
  const selectionWithDefaultOptions = (formItem, values) => {
    if (!values) {
      return formItem;
    }
    if (
      values.length > 1 &&
      formItem.type === ReportParameterValueType.String
    ) {
      formItem.options = values;
      formItem.displayOptionField = "reportParameterValueName";
      formItem.valueOptionField = "reportParameterValueCode";
    } else {
      formItem.default = values[0];
    }
    return formItem;
  };

  const toSchemas = useCallback(
    (parameterRegisters) => {
      if (!parameterRegisters) {
        return [];
      }

      return parameterRegisters.map((x) => {
        let item = {
          code: x.reportParameterCode,
          name: x.reportParameterName,
          field: x.reportParameterCode,
          type: x.reportParameterValueType,
          required: x.isMandatory,
        };

        item = selectionWithDefaultOptions(item, x.reportParameterValues);
        item = locationSelectionAdapter(item);
        item = campaignSelectionAdapter(item);
        item = distributionSelectionAdapter(item);
        item = fnbCampaignsSelectionAdapter(item);
        item = filmFestivalSelectionAdapter(item);
        item = safraCampaignsSelectionAdapter(item);
        item = movieLanguagesSelectionAdapter(item);
        item = admissionTypeSelectionAdapter(item);
        item = creditCardTypeSelectionAdapter(item);
        item = movieFormatSelectionAdapter(item);
        item = locationAreaCodeSelectionAdapter(item);
        item = systemComponentSelectionAdapter(item);
        item = performanceTypeSelectionAdapter(item);
        item = theatreCompanyTypeSelectionAdapter(item);
        item = voucherProgramTypeSelectionAdapter(item);
        item = voucherProgramMediumSelectionAdapter(item);
        item = showYearSelectionAdapter(item);
        item = showMonthSelectionAdapter(item);
        return item;
      });
    },
    [
      locationSelectionAdapter,
      campaignSelectionAdapter,
      filmFestivalSelectionAdapter,
      distributionSelectionAdapter,
      fnbCampaignsSelectionAdapter,
      safraCampaignsSelectionAdapter,
      movieLanguagesSelectionAdapter,
      admissionTypeSelectionAdapter,
      creditCardTypeSelectionAdapter,
      movieFormatSelectionAdapter,
      locationAreaCodeSelectionAdapter,
      systemComponentSelectionAdapter,
      performanceTypeSelectionAdapter,
      theatreCompanyTypeSelectionAdapter,
      voucherProgramTypeSelectionAdapter,
      voucherProgramMediumSelectionAdapter,
      showYearSelectionAdapter,
      showMonthSelectionAdapter,
    ]
  );
  return toSchemas(report?.reportParameterRegisters);
}
