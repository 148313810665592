import moment from "moment";
import { DateHelper } from "../common/Helpers";

export class MovieReleaseRestriction {
  constructor(data) {
    data = data || {};
    this.id = data.id ?? 0;
    this.movieReleaseId = data.movieReleaseId ?? 0;
    this.type = data.type ?? 0;
    this.startOn = data.startOn ?? "";
    this.endOn = data.endOn ?? "";

    const currentDate = new Date();
    if (!this.startOn) {
      currentDate.setDate(currentDate.getDate() + 1);
      this.startOn = moment(currentDate).format(DateHelper.IsoDateFormat);
    }

    if (!this.endOn) {
      currentDate.setDate(currentDate.getDate() + 2);
      this.endOn = moment(currentDate).format(DateHelper.IsoDateFormat);
    }
  }

  isValid() {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    const startOn = new Date(this.startOn);
    const endOn = new Date(this.endOn);
    return this.type > 0 &&
        endOn > startOn;
  }
}