import _ from "lodash";

import {TicketTypes} from "../constants/PricingConstants";
import { DateHelper } from "../common/Helpers";

export class LatestPrices {
  constructor() {
    this.data = [];
  }

  handleResponse(response, expectedTicketType) {
    this.data = [];
    if (_.isNil(response)) {
      return;
    }
    response.forEach((service) => {
      if (
        _.isNil(service.serviceTickets) ||
        _.isEmpty(service.serviceTickets)
      ) {
        return;
      }

      const foundTicket = service.serviceTickets.find(
        (s) => s.ticketType === expectedTicketType
      );
      if (_.isNil(foundTicket)) {
        return;
      }
      const orderedPrices = _.orderBy(
        foundTicket.serviceTicketPrices,
        (x) => x.id,
        "desc"
      );
      if (_.isEmpty(orderedPrices)) {
        return;
      }
      const latestPrice = orderedPrices[0];
      this.data.push(
        new LatestPriceItem(service, expectedTicketType, latestPrice)
      );
    });
  }
}

export class LatestPriceItem {
    constructor(service, ticketType, latestPrice) {
        this.serviceTicketPriceId = latestPrice.id;
        this.serviceCode = service.code;
        this.serviceName = service.name;
        const foundTicketType = TicketTypes.get(ticketType);
        this.ticketType = _.isNil(foundTicketType) ? "" : foundTicketType.name;
        this.latestPricing = latestPrice.salesAmount;
        this.newPricing = this.latestPricing;
        this.latestEffectiveStart = DateHelper.formatDateTimeString(latestPrice.effectiveStart);
        this.newEffectiveStart = this.latestEffectiveStart;
        this.isSelected = false;
    }
    
    setNewEffectiveStart(newEffectiveStart) {
        this.newEffectiveStart = DateHelper.formatDateTimeString(newEffectiveStart);
    }
}