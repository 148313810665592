import { useEffect, useState } from "react";
import { CacheClearingService } from "../services/CacheClearingService";

export function useCacheService() {
  const [internalGroupKeys, setInternalGroupKeys] = useState([]);
  const [externalGroupKeys, setExternalGroupKeys] = useState([]);
  useEffect(() => {
    const service = new CacheClearingService();
    service
      .getExternalGroupKeys()
      .then((response) => setExternalGroupKeys(response.data));
    service
      .getInternalGroupKeys()
      .then((response) => setInternalGroupKeys(response.data));
  }, []);
  return { internalGroupKeys, externalGroupKeys };
}
