import { useEffect, useState } from "react";
import RestClient from "../common/RestClient";
import { toast } from "react-toastify";

export function useServices(performanceType) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    try {
      const params = performanceType ? { performanceType } : {};
      RestClient.sendGetRequestWithParameters("api/v1/services", params,
        (response) => { 
          const data = response.data.map(x => {
            return { id: x.id, code: x.code, name: `${x.code} - ${x.name}` };
          });
          setServices(data);
        },
        (error) => toast.error(error.message));
    } catch (error) {
      toast.error(error.message);
    }
  }, [performanceType]);
  return { services };
}
