import RestClient from "../common/RestClient";
import { toast } from "react-toastify";

export class MovieService {
  constructor() {
    this.baseApi = "/api/v1/movies";
  }

  handleError(onError, error) {
    if (onError) {
      onError(error);
    }
    toast.error(error.message);
  }

  create(movie, onSuccess, onError) {
    let data = { ...movie };
    return RestClient.sendPostRequest(
      `${this.baseApi}`,
      data,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => this.handleError(onError, error)
    );
  }

  get(id, parameters, onSuccess, onError) {
    return RestClient.sendGetRequestWithParameters(
      `${this.baseApi}/${id}`,
      parameters,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => this.handleError(onError, error)
    );
  }

  update(movie, onSuccess, onError) {
    let data = { ...movie };
    return RestClient.sendPutRequest(
      `${this.baseApi}/${movie.id}`,
      data,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => this.handleError(onError, error)
    );
  }

  getLanguages(onSuccess, onError) {
    return RestClient.sendGetRequest(
      `${this.baseApi}/languages`,
      (response) => {
        if (onSuccess) {
          onSuccess(response);
        }
      },
      (error) => this.handleError(onError, error)
    );
  }
}