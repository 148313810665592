import React, { useEffect, useState } from "react";
import { Card } from "../../common/Card";
import { ClassificationConditionCard } from "./ClassificationConditionCard";
import { LocationHallConditionCard } from "./LocationHallConditionCard";
import { TicketTypeConditionCard } from "./TicketTypeConditionCard";
import { CreditCardConditionCard } from "./CreditCardConditionCard";
import { PerformanceConditionCard } from "./PerformanceConditionCard";
import { DayTimeConditionCard } from "./DayTimeConditionCard";
import { MovieConditionCard } from "./MovieConditionCard";
import { BrandFormatConditionCard } from "./BrandFormatConditionCard";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { CampaignActivePanel, campaignActivePanelAtom } from "../CampaignState";
import { useRecoilState } from "recoil";
import _ from "lodash";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const CampaignValidityConditionsCard = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [activePanel, setActivePanel] = useRecoilState(campaignActivePanelAtom);

  useEffect(() => {
    setIsEdit(activePanel === CampaignActivePanel.ConditionRestriction);
  }, [activePanel]);

  return (
    <Card
      title="Campaign Validity Conditions/Restrictions"
      isActive={activePanel === CampaignActivePanel.ConditionRestriction}
    >
      <ButtonActionDetailGroup>
        {activePanel !== CampaignActivePanel.ConditionRestriction &&
          AuthenticationManager.isAuthorized(
            ResourceID.CampaignsViewPageEditFeatures
          ) && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() =>
                setActivePanel(CampaignActivePanel.ConditionRestriction)
              }
              disabled={!_.isEmpty(activePanel)}
              title={
                !_.isEmpty(activePanel) &&
                activePanel !== CampaignActivePanel.ConditionRestriction
                  ? `The panel ${activePanel} is being edit. Please complete the current editing panel first.`
                  : ""
              }
            >
              <i className="fas fa-edit" /> Edit
            </ButtonActionDetailGroup.Item>
          )}
      </ButtonActionDetailGroup>
      <div className="card-body validity">
        <PerformanceConditionCard isEdit={isEdit} />

        <DayTimeConditionCard isEdit={isEdit} />

        <MovieConditionCard isEdit={isEdit} />

        <BrandFormatConditionCard isEdit={isEdit} />

        <ClassificationConditionCard isEdit={isEdit} />

        <LocationHallConditionCard isEdit={isEdit} />

        <CreditCardConditionCard isEdit={isEdit} />

        <TicketTypeConditionCard isEdit={isEdit} />
        {activePanel === CampaignActivePanel.ConditionRestriction && (
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-danger"
              onClick={() => {
                setActivePanel("");
              }}
            >
              Cancel
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        )}
      </div>
    </Card>
  );
};
