import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { KinoLoungeMovieDetailCard } from "./cards/KinoLoungeMovieDetailCard";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ScheduleConfirmationDialog } from "../../common/ScheduleConfirmationDialog";
import { ScheduledKinoLoungeMovieService } from "../../../services/ScheduledKinoLoungeMovieService";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

const EditPageName = "Edit Kinolounge Movie";
const ViewPageName = "View Kinolounge Movie";

export class KinoLoungeMovieDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      movie: null,
      movieId: "",
      generalInfo: null,
      generalInfoValidations: [],
      showHistoryDialog: false,
      showConfirmDialog: false,
      showCancelDialog: false,
      showScheduleDialog: false,
      scheduledKinoLoungeMovies: [],
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const movieId = StringHelper.toNumber(id);
    if (isNaN(id) || movieId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.loadScheduledKinoLoungeMovies();
    this.setState({ movieId }, this.refreshData);
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }


  loadScheduledKinoLoungeMovies = (id) => {
    new ScheduledKinoLoungeMovieService().get("", { kinoLoungeMovieId: id })
      .then((response) => {
        this.setState({ scheduledKinoLoungeMovies: response?.data?.data });
      });
  }

  loadKinoLoungeMovie(id) {
    RestClient.sendGetRequest(
      `/api/v1/kinolounge_movies/${id}`,
      (response) => {
        this.setState({ movie: response });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
      }
    );
  }

  onShowScheduleDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showScheduleDialog: true });
  };

  onHideScheduleDialog = () => {
    this.setState({ showScheduleDialog: false });
  };

  onProceedScheduleDialog = (e) => {
    const { generalInfo, movie } = this.state;
    const request = Object.assign(
      {
        createdBy: AuthenticationManager.username(),
        liveKinoLoungeMovieId: movie.id,
      },
      e,
      generalInfo
    );
    new ScheduledKinoLoungeMovieService().post(request)
      .then((response) => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          movie: response.data,
        });
        toast.success(GenericMessages.ScheduleChangesSuccessfulMessage);
        this.redirectToScheduleListingPage();
      }).catch((error) => {
        toast.error(error);
      }).finally(() => {
        this.setState({
          showScheduleDialog: false,
        });
      });
  };

  redirectToScheduleListingPage = () => {
    this.props.history.push(
      `/scheduled_kinolounge_movies/schedules`
    );
  }

  onEditKinoLoungeMovieClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartOn = StringHelper.toDateTimeString(model.displayStartOn);
    const displayEndOn = StringHelper.toDateTimeString(model.displayEndOn);
    const movieRuntime = StringHelper.toNumber(model.movieRuntime, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      displayStartOn,
      displayEndOn,
      movieRuntime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      movie: Object.assign({}, this.state.movie),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { movieId, generalInfo } = this.state;
    const request = Object.assign({}, generalInfo);
    RestClient.sendPutRequest(
      `/api/v1/kinolounge_movies/${movieId}`,
      request,
      (response) => {
        toast.success(GenericMessages.SaveChangesSuccessfulMessage);
        this.setState({
          cardMode: CardMode.View,
          movie: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  refreshData() {
    const { movieId } = this.state;
    this.loadKinoLoungeMovie(movieId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/kinolounge_movies/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "kinolounge_movies",
        name: "Kinolounge Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "kinolounge-movie-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      movie,
      showCancelDialog,
      showConfirmDialog,
      showHistoryDialog,
      showScheduleDialog,
      scheduledKinoLoungeMovies,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    {AuthenticationManager.isAuthorized(
                      ResourceID.KinoLoungeEditButtonPage
                    ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={movie ? undefined : "disabled"}
                          onClick={this.onEditKinoLoungeMovieClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>
                      )}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <KinoLoungeMovieDetailCard
                  cardMode={cardMode}
                  defaultValue={movie}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <div className="d-flex flex-row justify-content-end">
                <button
                  className="btn btn-success mr-1"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </button>
                <button
                  className="btn btn-primary mr-1"
                  onClick={this.onShowScheduleDialog}
                >  Schedule
                </button>
                <button
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && movie}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && movie}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showHistoryDialog && movie && (
          <HistoryDialog
            objectId={movie.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.Kinolounge}
          />
        )}
        {showScheduleDialog && movie && (
          <ScheduleConfirmationDialog
            onCancel={this.onHideScheduleDialog}
            onProceed={this.onProceedScheduleDialog}
            message={GenericMessages.ScheduleChangesDialogMessage("kinolounge movies")}
            warningMessage={GenericMessages.ScheduleWarningDialogMessage("kinolounge movies")}
            title={GenericMessages.ScheduleChangesDialogTitle}
            data={scheduledKinoLoungeMovies}
          />
        )}
      </div>
    );
  }
}
