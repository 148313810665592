import React, { useEffect, useMemo, useState } from "react";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import MovieMarathonDetailCard from "./cards/MovieMarathonDetailCard";
import { SystemSettingKeys, GenericMessages } from "../../../common/Constants";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { AddMovieMarathonMappingDialog } from "./AddMovieMarathonMappingDialog";
import { MovieFormat } from "../../../constants/MovieConstants";
import { MovieMarathonService } from "../../../services/MovieMarathonService";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { Card } from "../../common/Card";
import { toast } from "react-toastify";
import { NumberHelper } from "../../../common/Helpers";
import { SystemSettings } from "../../../common/SystemSettings";

const MovieMarathonDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [containerMovieRelease, setContainerMovieRelease] = useState({});
  const [contentMovieReleases, setContentMovieReleases] = useState([]);
  const [selectedContentId, setSelectedContentId] = useState(0);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const redirectToListingPage = useCallback(() => {
    history.push(`/movie_marathons`);
  }, [history]);

  const movieMarathonService = useMemo(() => new MovieMarathonService(), []);
  const movieReleaseService = useMemo(() => new MovieReleaseService(), []);

  useEffect(() => {
    const containerId = isNaN(id) ? 0 : Number(id);
    if (isNaN(id) || containerId <= 0) {
      redirectToListingPage();
      return;
    }
    movieReleaseService
      .get(containerId)
      .then((response) => setContainerMovieRelease(response.data));
  }, [id, redirectToListingPage, movieReleaseService]);

  useEffect(() => {
    if (!containerMovieRelease.id || containerMovieRelease.id <= 0) return;
    movieMarathonService
      .getImpl("contents", {
        containerMovieReleaseId: containerMovieRelease.id,
      })
      .then((response) => {
        setContentMovieReleases(response.data);
      });
  }, [refresh, containerMovieRelease.id, movieMarathonService]);

  const onDelete = () => {
    movieMarathonService
      .postImpl("delete", {
        containerMovieReleaseId: containerMovieRelease.id,
        contentMovieReleaseId: selectedContentId,
      })
      .then(() => {
        toast.success("The content movie release has been delete successful.");
        setRefresh((counter) => counter + 1);
        setShowDeleteModal(false);
      });
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "movie_marathons",
        name: "Movie Marathon",
      }),
      activeModule: new NavigationItem({
        identifier: `movie_marathons/${id}`,
        name: "View Movie Marathon",
      }),
    });
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <MovieMarathonDetailCard {...containerMovieRelease} />
              <Card title="Movie Marathon Content">
                <div className="d-flex flex-row justify-content-end mb-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowAddModal(true)}
                  >
                    <i className="fas fa-plus-circle" />
                    Add Movie Marathon Mapping
                  </button>
                </div>
                {contentMovieReleases &&
                  contentMovieReleases.map((item) => (
                    <div className="card" key={item.id}>
                      <div className="card-body row">
                        <div className="col-2">
                          <img
                            style={{ height: "140px" }}
                            src={item.posterUrl}
                            alt=""
                          />
                        </div>
                        <div className="col-8" id="#filmMovies">
                          <strong>
                            {item.primaryTitle} [
                            {MovieFormat.getName(item.format)}]
                          </strong>
                          <p>
                            <span>
                              <span className="sprite-legends-rating-pg mr-1 float-left" />
                            </span>
                            <span className="movie-duration">
                              | {NumberHelper.formatRuntime(item.duration)}{" "}
                            </span>
                          </p>
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item.shortSynopsis,
                            }}
                          />
                          <a
                            href={`${
                              SystemSettings.get(
                                SystemSettingKeys.PublicWebsiteUrl
                              ) || ""
                            }/movie-details/${item.movieReleaseId}`}
                            target="_blank"
                          >
                            (Details)
                          </a>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                          <div className="">
                            <button
                              className="btn btn-danger"
                              onClick={() => {
                                setSelectedContentId(item.id);
                                setShowDeleteModal(true);
                              }}
                            >
                              <i
                                className="far fa-trash-alt"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Card>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <button
              className="btn btn-danger"
              onClick={() => redirectToListingPage()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={showCancelDialog}
        onCancel={() => setShowCancelDialog(false)}
        onProceed={() => redirectToListingPage()}
        message={GenericMessages.CancelChangesDialogMessage}
        title={GenericMessages.CancelChangesDialogTitle}
      />
      <ConfirmDialog
        visible={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onProceed={onDelete}
        message={GenericMessages.DeleteMovieMarathonMappingMessage}
        title={GenericMessages.DeleteMovieMarathonMappingTitle}
      />
      <AddMovieMarathonMappingDialog
        visible={showAddModal}
        contentMovieReleases={contentMovieReleases}
        containerMovieReleaseId={containerMovieRelease.id}
        onCancel={() => setShowAddModal(false)}
        onPostAdd={() => setRefresh((counter) => counter + 1)}
      />
    </div>
  );
};

export default MovieMarathonDetail;
