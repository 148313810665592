import React from 'react';
import Validators from '../../../common/Validators';

export class RejectEvoucherBatchExtensionRequestDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmit: false,
            reason: '',
            validations: [],
        }
    }

    isValidated() {
        const { validations } = this.state;
        return validations.every(val => val.isValid);
    }

    getControlClassName(fieldName) {
        const { isSubmit, validations } = this.state;
        const isError = validations.some(val => val.fieldName === fieldName && !val.isValid);
        return isSubmit && isError ? 'form-control is-invalid' : 'form-control';
    }

    onSubmitClick = () => {
        if (!this.isValidated()) {
            this.setState({ isSubmit: true })
            return;
        }
        if (this.props.onProceed) {
            this.props.onProceed(this.state.reason);
        }
    }

    onTextBoxChange = (e) => {
        const fieldName = e.target.getAttribute('fieldname');
        this.setState({ [fieldName]: e.target.value });
    }

    onValidationStatusChange = (e) => {
        let { validations } = this.state;
        let validation = validations.find(val => val.fieldName === e.fieldName && val.type === e.type);
        if (validation) {
            validation.isValid = e.isValid;
            validation.message = e.message;
        } else {
            validations.push(e);
        }
        this.setState({ validations }, this.onValidationsChange);
    }

    render() {
        const { reason, isSubmit } = this.state;
        return (
            <>
                <div className="modal fade show" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirmation</h5>
                                <button type="button" className="close" aria-label="Close" onClick={this.props.onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <label className="form-control-label">Are you sure you want to reject?</label>
                                    <div className="row form-group pb-2 pt-3">
                                        <div className="col-md-4">
                                            <label className="form-control-label" htmlFor="Reason">Reason:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea id="Reason" rows="4" placeholder="Enter reason for rejecting request"
                                                className={this.getControlClassName('reason')} fieldname="reason"
                                                onChange={this.onTextBoxChange} value={reason} />
                                            <Validators.MinLengthValidator
                                                onValidationStatusChange={this.onValidationStatusChange}
                                                fieldName="reason"
                                                isEnabled={isSubmit}
                                                minLength="4"
                                                property="Reason for canceling request" value={reason} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={this.onSubmitClick}>Confirm</button>
                                <button type="button" className="btn btn-danger" onClick={this.props.onCancel}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"></div>
            </>
        )
    }
}
