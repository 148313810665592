import React from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import _ from "lodash";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { Performance } from "../../../models/Performance";
import {
  Classify,
  LocationBrand,
  MovieBrand,
  MovieFormat,
  MovieSubtitle,
  PerformanceStatus,
  Restriction,
  SeatingStatus,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { PerformanceRestService } from "../../../services/PerformanceRestService";
import { PerformanceTypes } from "../../../constants/PricingConstants";
import { DateHelper } from "../../../common/Helpers";
import "../../css/components/accordion.css";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { Card } from "../../common/Card";

export class PerformanceDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      instance: new Performance(),
      mode: Mode.Readonly,
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
      showHistoryDialog: false,
    };
    this.performanceService = new PerformanceRestService();
  }

  getNavigationSettings = () => {
    const { mode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "performance-details",
        name: mode === Mode.Readonly ? "View Showtimes" : "Edit Showtimes",
      }),
    });
  };

  loadData = () => {
    const { instance } = this.state;
    const id = this.props.match.params.id;
    this.performanceService
      .get(id, { includes: "Location" })
      .then((response) => {
        instance.handleResponse(response.data);
        this.setState({ instance });
      });
  };

  componentDidMount() {
    this.loadData();
  }

  onModeChange = () => {
    const { mode } = this.state;
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    this.setState({ mode: newMode });
  };

  onSubmit = () => {
    const { instance } = this.state;
    this.performanceService
      .put(instance.id, {
        status: instance.status,
        serviceId: instance.serviceId,
        isPrivate: instance.isPrivate,
      })
      .then((response) => {
        const performance = new Performance();
        performance.handleResponse(response.data);
        this.setState({
          instance: performance,
          mode: Mode.Readonly,
        });
        toast.success("The performance has been updated successful.");
      });
    this.closeAllModals();
  };

  onCancel = () => {
    this.loadData();
    this.setState({ mode: Mode.Readonly });
    this.closeAllModals();
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  renderTopButtons = () => {
    const { mode } = this.state;
    if (mode !== Mode.Readonly) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          onClick={this.onModeChange}
        >
          <i className="fas fa-edit" aria-hidden="true" />
          Update Showtime
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          data-target="#HistoryPopup"
          data-toggle="modal"
          onClick={() => this.setState({ showHistoryDialog: true })}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  renderBottomButtons = () => {
    const { mode } = this.state;
    if (mode !== Mode.Editable) {
      return null;
    }
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showSaveChangesConfirmation: true })}
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showCancelConfirmation: true })}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  closeAllModals = () => {
    this.setState({
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
    });
  };

  renderStatusComponent = () => {
    const { mode, instance } = this.state;
    if (mode === Mode.Readonly) {
      return (
        <input
          className="col form-control display-input"
          value={PerformanceStatus.getName(instance.status)}
          readOnly={true}
        />
      );
    }
    return (
      <select
        className="form-control display-input"
        valuetype="number"
        name="status"
        onChange={this.onInputChange}
        value={instance.status}
      >
        {PerformanceStatus.All.filter(
          (x) =>
            x.value === PerformanceStatus.Cancelled ||
            x.value === instance.status
        ).map((x) => (
          <option key={x.value} value={x.value}>
            {x.name}
          </option>
        ))}
      </select>
    );
  };

  renderIsPrivateComponent = () => {
    const { mode, instance } = this.state;
    if (mode === Mode.Readonly) {
      return (
        <div className="col-md-6 mb-3">
          <label className="form-control-label">Is Private?</label>
          <input
            className="col form-control display-input"
            value={instance.isPrivate ? "Yes" : "No"}
            readOnly={true}
          />
        </div>
      );
    }
    return (
      <div className="input-group col-md-6 mb-3">
        <label className="form-label mt-2 mr-3">Is Private?</label>
        <input
          className="form-input"
          valuetype="boolean"
          name="isPrivate"
          type="checkbox"
          checked={instance.isPrivate}
          onChange={this.onInputChange}
        />
      </div>
    );
  };

  renderReadonlyView = () => {
    const { instance } = this.state;
    return (
      <div className="row">
        <div className="col">
          <Card title="Showtime Information">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Id:</label>
                <input
                  className="col form-control display-input"
                  placeholder="Enter Performance Id"
                  value={instance.id}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Movie Id:</label>
                <input
                  className="col form-control display-input"
                  placeholder="Enter Movie Id"
                  value={instance.movieId}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Movie Release Id:</label>
                <input
                  className="col form-control display-input"
                  placeholder="Enter Movie Id"
                  value={instance.movieReleaseId}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Primary Title:</label>
                <input
                  className="col form-control display-input"
                  placeholder="Enter Primary Title"
                  value={instance.primaryTitle}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Secondary Title:</label>
                <input
                  className="col form-control display-input"
                  placeholder="Enter Secondary Title"
                  value={instance.secondaryTitle}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Duration:</label>
                <input
                  className="col form-control display-input"
                  type="number"
                  value={instance.duration}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Display Date:</label>
                <input
                  className="col form-control display-input"
                  value={DateHelper.formatDateTimeString(
                    instance.actualDateTime
                  )}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Display Time:</label>
                <input
                  className="col form-control display-input"
                  value={DateHelper.toDisplayTimeFormat(
                    instance.actualDateTime
                  )}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Sales Start On:</label>
                <input
                  className="col form-control display-input"
                  value={DateHelper.formatDateTimeString(instance.salesStartOn)}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Display Weight:</label>
                <input
                  className="col form-control display-input"
                  value={instance.displayWeight}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Label Print:</label>
                <input
                  className="col form-control display-input"
                  value={instance.labelPrint}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Showtime Type:</label>
                <input
                  className="col form-control display-input"
                  value={PerformanceTypes.getDisplay(instance.type)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Classification:</label>
                <input
                  className="col form-control display-input"
                  value={Classify.getName(instance.classify)}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Brand:</label>
                <input
                  className="col form-control display-input"
                  value={MovieBrand.getName(instance.movieBrand)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Format:</label>
                <input
                  className="col form-control display-input"
                  value={MovieFormat.getName(instance.movieFormat)}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Restriction:</label>
                <input
                  className="col form-control display-input"
                  value={Restriction.getName(instance.restriction)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Location:</label>
                <input
                  className="col form-control display-input"
                  value={instance.locationVenue.location.name}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Location Venue:</label>
                <input
                  className="col form-control display-input"
                  value={instance.locationVenue.name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Subtitle:</label>
                <input
                  className="col form-control display-input"
                  value={MovieSubtitle.getName(instance.movieSubtitle)}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Language:</label>
                <input
                  className="col form-control display-input"
                  value={instance.movieLanguage}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Location Venue Brand:
                </label>
                <input
                  className="col form-control display-input"
                  value={LocationBrand.getName(instance.locationVenueBrand)}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Poster Url:</label>
                <input
                  className="col form-control display-input"
                  value={instance.posterUrl}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Service Code:</label>
                <input
                  className="col form-control display-input"
                  value={instance.serviceCode}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Service ID:</label>
                <input
                  className="col form-control display-input"
                  value={instance.serviceId}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Is Midnight Showtime?
                </label>
                <input
                  className="col form-control display-input"
                  value={instance.isMidnight ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Is Layout Published?
                </label>
                <input
                  className="col form-control display-input"
                  value={instance.isLayoutPublished ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Is Opening?</label>
                <input
                  className="col form-control display-input"
                  value={instance.isOpening ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Status:</label>
                {this.renderStatusComponent()}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Is Free Stand?:</label>
                <input
                  className="col form-control display-input"
                  value={instance.isFreeStand ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Is Limited?:</label>
                <input
                  className="col form-control display-input"
                  value={instance.isLimited ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Movie Release Week:
                </label>
                <input
                  className="col form-control display-input"
                  value={instance.movieReleaseWeek}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Seating Status:</label>
                <input
                  className="col form-control display-input"
                  value={SeatingStatus.getName(instance.seatingStatus)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Seating Status Updated On
                </label>
                <input
                  className="col form-control display-input"
                  value={DateHelper.formatDateTimeString(
                    instance.seatingStatusUpdatedOn
                  )}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Published On:</label>
                <input
                  className="col form-control display-input"
                  value={DateHelper.formatDateTimeString(instance.publishedOn)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">
                  Is Vaccinated Hall?
                </label>
                <input
                  className="col form-control display-input"
                  value={instance.isVaccinatedHall ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Movie Advice:</label>
                <input
                  className="col form-control display-input"
                  value={instance.adviceName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Is Partial?</label>
                <input
                  className="col form-control display-input"
                  value={instance.isPartial ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Campaign Id:</label>
                <input
                  className="col form-control display-input"
                  value={instance.campaignId}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              {this.renderIsPrivateComponent()}
              <div className="col-md-6 mb-3">
                <label className="form-control-label">Is DTS-X:</label>
                <input
                  className="col form-control display-input"
                  value={instance.isDtsx ? "Yes" : "No"}
                  readOnly={true}
                />
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  render() {
    const {
      showSaveChangesConfirmation,
      showCancelConfirmation,
      showHistoryDialog,
      instance,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.renderTopButtons()}
              {this.renderReadonlyView()}
              {this.renderBottomButtons()}
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showSaveChangesConfirmation}
          title={GenericMessages.SaveChangesDialogTitle}
          onProceed={this.onSubmit}
          onCancel={() => this.setState({ showSaveChangesConfirmation: false })}
          message={GenericMessages.SaveChangesDialogMessage}
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          title={GenericMessages.CancelChangesDialogTitle}
          onProceed={this.onCancel}
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          message={GenericMessages.CancelChangesDialogMessage}
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId={instance.id}
            onClose={() => this.setState({ showHistoryDialog: false })}
            url="/api/v1/history_logs"
            objectType={ObjectType.Performance}
          />
        )}
      </>
    );
  }
}
