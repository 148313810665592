import React from "react";
import _ from "lodash";

export const CheckboxInput = (props) => {
  const { name, value, onChange, checked, disabled, wrapperClass } = props;

  if (!_.isNil(disabled) && disabled) {
    return (
      <div className={`form-check ${wrapperClass || ''}`}>
        <input
          type="checkbox"
          className="mr-1"
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={() => {}}
        />
      </div>
    );
  }
  return (
    <div className={`form-check ${wrapperClass || ''}`}>
      <input
        type="checkbox"
        valuetype="boolean"
        className="mr-1"
        name={name}
        value={value}
        onChange={onChange || (() => {})}
        checked={checked}
      />
    </div>
  );
};
