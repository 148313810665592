import moment from "moment";
import { DateHelper } from "../common/Helpers";

export class MovieReleaseMedia {
  constructor(data) {
    data = data || {};

    this.id = data.id ?? 0;
    this.type = data.type ?? 0;
    this.url = data.url ?? "";
    this.displayWeight = data.displayWeight ?? 1;
    this.displayStart = data.displayStart;
  }
}
