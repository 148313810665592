import { atom } from "recoil";

export const reportingLocationsAtom = atom({
  key: "reportingLocationsAtom",
  default: [],
});

export const reportingCampaignsAtom = atom({
  key: "reportingCampaignsAtom",
  default: [],
});

export const reportingFilmFestivalsAtom = atom({
  key: "reportingFilmFestivalsAtom",
  default: [],
});

export const reportingFnbCampaignsAtom = atom({
  key: "reportingFnbCampaignsAtom",
  default: [],
});

export const reportingSafraCampaignsAtom = atom({
  key: "reportingSafraCampaignsAtom",
  default: [],
});

export const reportingDistributionsAtom = atom({
  key: "reportingDistributionsAtom",
  default: [],
});

export const reportingLocationAreaCodesAtom = atom({
  key: "reportingLocationAreaCodesAtom",
  default: [],
});

export const reportingMovieLanguagesAtom = atom({
  key: "reportingMovieLanguagesAtom",
  default: [],
});
