import { AuthenticationManager } from "./AuthenticationManager";
import { log, showLoading } from "./Common";
import { StringHelper } from "./Helpers";
import { StorageKeys } from "../common/Constants";
export default class RestClient {
  static combineParameters(parametersMap) {
    if (!parametersMap || typeof parametersMap !== "object") {
      return "";
    }
    let paramStr = "";
    Object.entries(parametersMap).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        paramStr += value.reduce((acc, cur) => acc + StringHelper.format("{0}={1}&", key, cur), '');
      } else {
        paramStr += StringHelper.format("{0}={1}&", key, value);
      }
    });
    paramStr = paramStr.substring(0, paramStr.length - 1);
    return paramStr;
  }

  static sendGetRequestWithParameters(
    baseUrl,
    parametersMap,
    onSuccess,
    onError
  ) {
    let additionalParameters = this.combineParameters(parametersMap);
    let url = baseUrl;
    if (!StringHelper.isNullOrEmpty(additionalParameters)) {
      url = StringHelper.format("{0}?{1}", baseUrl, additionalParameters);
    }
    this.sendGetRequest(url, onSuccess, onError);
  }

  static sendPostRequest(url, data, onSuccess, onError) {
    let options = {
      body: JSON.stringify(data),
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "content-type": "application/json" },
      method: "POST",
      mode: "cors",
    };
    RestClient.sendRequestInner(url, options, onSuccess, onError, 0);
  }

  static sendPatchRequest(url, data, onSuccess, onError) {
    let options = {
      body: JSON.stringify(data),
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "content-type": "application/json" },
      method: "PATCH",
      mode: "cors",
    };
    RestClient.sendRequestInner(url, options, onSuccess, onError, 0);
  }

  static sendPutRequest(url, data, onSuccess, onError) {
    let options = {
      body: JSON.stringify(data),
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "content-type": "application/json" },
      method: "PUT",
      mode: "cors",
    };
    RestClient.sendRequestInner(url, options, onSuccess, onError, 0);
  }

  static sendDeleteRequest(url, onSuccess, onError) {
    let options = {
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "content-type": "application/json" },
      method: "DELETE",
      mode: "cors",
    };
    RestClient.sendRequestInner(url, options, onSuccess, onError, 0);
  }

  static sendGetRequest(url, onSuccess, onError) {
    let options = {
      cache: "no-store",
      credentials: "same-origin",
      headers: { "content-type": "application/json" },
      method: "GET",
      referrer: "no-referrer",
    };
    RestClient.sendRequestInner(url, options, onSuccess, onError, 0);
  }

  static sendRequestInner(url, options, onSuccess, onError, retryCount) {
    showLoading(true);
    if (retryCount >= 1) {
      const error = { error: "Forbidden" };
      if (onError) onError(error);
      else console.error(error);
      return;
    }
    if (AuthenticationManager.accessToken()) {
      options.headers = Object.assign(options.headers, {
        authorization: `Bearer ${AuthenticationManager.accessToken()}`,
      });
    }
    fetch(url, options)
      .then((response) => {
        showLoading(false);
        if (
          response.status === 204
        ) {
          if (onSuccess) onSuccess();
          return;
        }

        if (response.status >= 200 && response.status < 300) {
          if (options.method.toLowerCase() === "delete") {
            if (onSuccess) onSuccess();
            return;
          }
          response.json().then((jsonResponse) => {
            if (onSuccess) onSuccess(jsonResponse);
          });
          return;
        }

        if (response.status === 403) {
          RestClient.sendPostRequest(
            "/api/v1/auth/extend_session",
            { refreshToken: AuthenticationManager.refreshToken() },
            (rResponse) => {
              AuthenticationManager.saveAuthInfo(rResponse);
              RestClient.sendRequestInner(
                url,
                options,
                onSuccess,
                onError,
                retryCount + 1
              );
            },
            onError
          );
          return;
        }

        if (response.status === 401) {
          localStorage.setItem(
            StorageKeys.ReturnPath,
            window.location.pathname
          );
          window.location.href = "/login";
        }

        if (response.status === 404) {
          if (onError) {
            onError(response);
          } else {
            console.error(response);
          }
          return;
        }

        response.json().then((jsonResponse) => {
          if (onError) onError(jsonResponse);
          else console.error(jsonResponse);
        });
      })
      .catch(RestClient.handleFetchError);
  }

  static handleFetchError(e) {
    showLoading(false);
    if (e instanceof TypeError) {
      log(`Error: ${e.message}`);
    } else if (typeof e === "object") {
      log(`Error: ${JSON.stringify(e)}`);
    } else {
      log(`Error: ${e}`);
    }
  }
}
