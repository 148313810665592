import { useEffect, useState } from "react";
import RestClient from "../../common/RestClient";
import { toast } from "react-toastify";
import { CampaignStatus } from "../../constants/CampaignConstants";

export function useCampaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  useEffect(() => {
    RestClient.sendGetRequestWithParameters(
      `api/v1/campaigns`,
      { IsStackCampaign: false, includes: "CampaignBundles", status: CampaignStatus.Active },
      (response) => {
        setCampaigns(response.data);
        const options = response.data.map((x) => {
          return {
            id: x.id,
            name: `${x.id} - ${x.code} - ${x.name}`,
          };
        });
        setCampaignOptions(options);
      },
      (error) => toast.error(error.message)
    );
  }, []);

  return {
    campaigns,
    campaignOptions,
  };
}
