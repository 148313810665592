import { isNil } from "lodash";
import { CampaignCategory } from "../constants/CampaignConstants";
import { StringHelper } from "../common/Helpers";

export class MoviePromotionValidity {
  constructor(data) {
    this.id = data.id ?? 0;
    this.movieReleaseId = data.movieReleaseId ?? 0;
    this.startOn = isNil(data.startOn)
      ? ""
      : StringHelper.toDateTimeString(data.startOn);

    this.endOn = isNil(data.endOn)
      ? ""
      : StringHelper.toDateTimeString(data.endOn);
    this.createdOn = data.createdOn ?? "";
    this.campaignCategory = data.campaignCategory ?? CampaignCategory.None;
    this.movieRelease = data.movieRelease ?? {};
  }
}