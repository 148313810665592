import React from "react";
import { AuthenticationManager } from "../../common/AuthenticationManager";
import { StringHelper } from "../../common/Helpers";
import RestClient from "../../common/RestClient";
import { BreadCrumb } from "../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { ApplicationAccessCard } from "./cards/ApplicationAccessCard";
import { CardMode } from "./cards/CardMode";
import { GeneralInformationCard } from "./cards/GeneralInformationCard";
import { StaffAuthenticationCard } from "./cards/StaffAuthenticationCard";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../common/ConfirmDialog";

export class UserRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appAccessInfo: null,
      authInfo: null,
      authInfoValidations: [],
      cardMode: CardMode.Register,
      generalInfo: null,
      generalInfoValidations: [],
      user: null,
      showClearConfirmation: false,
    };
  }

  isValidated() {
    const { authInfoValidations, generalInfoValidations } = this.state;
    return (
      authInfoValidations.every((val) => val.isValid) &&
      generalInfoValidations.every((val) => val.isValid)
    );
  }

  onApplicationAccessChange = (model) => {
    this.setState({ appAccessInfo: model });
  };

  onGeneralInfoModelChange = (model) => {
    const effectiveStartDate = StringHelper.isNullOrEmpty(
      model.effectiveStartDateStr
    )
      ? null
      : model.effectiveStartDateStr;
    const effectiveEndDate = StringHelper.isNullOrEmpty(
      model.effectiveEndDateStr
    )
      ? null
      : model.effectiveEndDateStr;
    const newModel = Object.assign(model, {
      effectiveStartDate,
      effectiveEndDate,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onStaffAuthenticationModelChange = (model) => {
    const roleIds = model.roles.map((role) => role.id);
    const mappedModel = Object.assign(model, { roleIds });
    this.setState({ authInfo: mappedModel });
  };

  onStaffAuthenticationValidationsChange = (validations) => {
    this.setState({ authInfoValidations: validations });
  };

  onSubmitClick = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    const { appAccessInfo, authInfo, generalInfo } = this.state;
    const request = Object.assign(
      { },
      generalInfo,
      authInfo,
      appAccessInfo
    );
    RestClient.sendPostRequest(
      `/api/v1/users/`,
      request,
      () => {
        toast.success("User has been registered successfully");
        this.props.history.push('/users');
      },
      this.handleError
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/users/${additionalParameter ?? ""}`);
  }

  onClearClick = () => {
    this.setState({
      user: Object.assign({}, this.state.user),
      showClearConfirmation: false,
    });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "user-managements",
        name: "User Management",
      }),
      activeModule: new NavigationItem({
        identifier: "user-creation",
        name: "Create User",
      }),
    });
  };

  render() {
    const { cardMode, isSubmit, user, showClearConfirmation } = this.state;
    const generalInfo = Object.assign(
      { departmentId: "", designationId: "" },
      this.state.generalInfo
    );
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <GeneralInformationCard
                    cardMode={cardMode}
                    defaultValue={user}
                    isSubmit={isSubmit}
                    onModelChange={this.onGeneralInfoModelChange}
                    onValidationsChange={this.onGeneralInfoValidationsChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <StaffAuthenticationCard
                    cardMode={cardMode}
                    defaultValue={user}
                    departmentId={generalInfo.departmentId}
                    designationId={generalInfo.designationId}
                    isSubmit={isSubmit}
                    onModelChange={this.onStaffAuthenticationModelChange}
                    onValidationsChange={
                      this.onStaffAuthenticationValidationsChange
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <ApplicationAccessCard
                    cardMode={cardMode}
                    defaultValue={user}
                    onModelChange={this.onApplicationAccessChange}
                  />
                </div>
              </div>
              {cardMode !== CardMode.View && (
                <div id="EditButtons" className="row pb-3">
                  <div className="d-inline-flex ml-auto">
                    <button
                      className="btn btn-success mr-2"
                      onClick={this.onSubmitClick}
                    >
                      Submit
                    </button>
                    <button
                      className="btn btn-danger mr-3"
                      onClick={() =>
                        this.setState({ showClearConfirmation: true })
                      }
                    >
                      Clear
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showClearConfirmation}
          onProceed={this.onClearClick}
          message="Are you sure to clear the form?"
          onCancel={() => this.setState({ showClearConfirmation: false })}
        />
      </>
    );
  }
}
