import _ from "lodash";
import React, { useState } from "react";
import { CrewRole } from "../../../constants/MovieConstants";
import { MovieCrew } from "../../../models/MovieCrew";
import CrewSelect from "../../common/CrewSelect";
import { MovieCrewService } from "../../../services/MovieCrewService";
import { RowFormField } from "../../layout/FormLayout";

export const AddCrewModal = (props) => {
  const [instance, setInstance] = useState(new MovieCrew());

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    setInstance(_.cloneDeep(instance));
  };

  const onSelect = (event) => {
    setInstance((data) => ({ ...data, ...event, name: event.label }));
  };

  const createMovieCrew = async () => {
    const { isNew, name } = instance;
    if (isNew) {
      const { data } = await new MovieCrewService().post({
        name,
      });
      return data;
    }
    return { id: 0, name: instance.name };
  };

  const onAdd = async () => {
    const crew = await createMovieCrew();
    if (!crew) {
      return;
    }
    props.onProceed({
      ...instance,
      crew,
    });
    props.onCancel();
    setInstance(new MovieCrew());
  };

  if (!props.visible) {
    return null;
  }

  const disableAddBtn = !instance.name || instance.role <= 0;

  return (
    <>
      <div
        className="modal fade show"
        id="CrewModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: "block", paddingRight: "17px" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Movie Crew
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={props.onCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflow: "unset" }}>
              <div className="container-fluid">
                <RowFormField
                  label={
                    <span>
                      Movie Crew:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <CrewSelect onChange={onSelect} selectedValue={instance.id} />
                </RowFormField>
                <RowFormField
                  label={
                    <span>
                      Crew Role:<span style={{ color: "red" }}>*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control"
                    name="role"
                    valuetype="number"
                    onChange={onInputChange}
                  >
                    <option value={0}>Select Movie Crew Role</option>
                    {CrewRole.All.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-primary"
                  disabled={disableAddBtn}
                  onClick={onAdd}
                  data-dismiss="modal"
                >
                  <i className="fas fa-plus-sign" aria-hidden="true" />
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={props.onCancel}
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
