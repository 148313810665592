import _ from "lodash";
import { StatusColor } from "../common/Constants";

export class CampaignCategory {
  static Other = 1;
  static CreditCard = 2;
  static Safra = 3;
  static ExternalLoyaltyCard = 4;
  static Product = 5;

  static All = [
    {
      name: "Other",
      value: this.Other,
    },
    {
      name: "Credit Card",
      value: this.CreditCard,
    },
    {
      name: "Safra",
      value: this.Safra,
    },
    {
      name: "External Loyalty Card",
      value: this.ExternalLoyaltyCard,
    },
    {
      name: "Product",
      value: this.Product
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class CampaignStatus {
  static Draft = 1;
  static Active = 2;
  static Expired = 3;
  static Blocked = 4;
  static FullyRedeemed = 5;
  static NotAvailable = 6;

  static ColorMap = {
    [this.Draft]: StatusColor.Gray,
    [this.Active]: StatusColor.Green,
    [this.Blocked]: StatusColor.Gray,
    [this.Expired]: StatusColor.Orange,
    [this.FullyRedeemed]: StatusColor.Green,
    [this.NotAvailable]: StatusColor.Gray,
  };

  static getColor = (status) => {
    const defaultColor = StatusColor.Orange;
    const map = this.ColorMap;

    if (!status) {
      return defaultColor;
    }
    const value = map[status];
    if (!value) {
      return defaultColor;
    }
    return value;
  };

  static All = [
    {
      name: "Draft",
      value: this.Draft,
    },
    {
      name: "Active",
      value: this.Active,
    },
    {
      name: "Blocked",
      value: this.Blocked,
    },
    {
      name: "Expired",
      value: this.Expired,
    },
    {
      name: "Fully Redeemed",
      value: this.FullyRedeemed,
    },
    {
      name: "Not Available",
      value: this.NotAvailable,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    return found?.name ?? "";
  }
}

export class SeatsCondition {
  static NoCondition = 1;
  static ValidForMinimumNumber = 2;
  static ValidForSpecificNumber = 3;

  static All = [
    {
      name: "No seat condition",
      value: this.NoCondition,
    },
    {
      name: "Valid for minimum number of seats",
      value: this.ValidForMinimumNumber,
    },
    {
      name: "Valid for specific number of seats",
      value: this.ValidForSpecificNumber,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    return found?.name ?? "";
  }
}

export class DiscountMode {
  static Fixed = 1;
  static Discount = 2;

  static All = [
    {
      name: "Fixed",
      value: this.Fixed,
    },
    {
      name: "Discount",
      value: this.Discount,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    return found?.name ?? "";
  }
}

export class IssuingBankCode {
  static BOC = 1;
  static CUP = 2;
  static DBS = 3;
  static ICBC = 4;
  static MAY = 5;
  static NETS = 6;
  static OCBC = 7;
  static SINO = 8;
  static UOB = 9;

  static All = [
    {
      name: "Bank of China",
      value: this.BOC,
    },
    {
      name: "China Union Pay",
      value: this.CUP,
    },
    {
      name: "DBS Bank",
      value: this.DBS,
    },
    {
      name: "Industrial & Commercial Bank of China",
      value: this.ICBC,
    },
    {
      name: "MayBank",
      value: this.MAY,
    },
    {
      name: "Network For Electronic Transfers(Singapore)",
      value: this.NETS,
    },
    {
      name: "OCBC",
      value: this.OCBC,
    },
    {
      name: "SinoPay Singapore",
      value: this.SINO,
    },
    {
      name: "United Overseas Bank",
      value: this.UOB,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class RedemptionQuantityType {
  static Daily = 1;
  static Weekly = 2;
  static Monthly = 3;

  static All = [
    {
      name: "Daily",
      value: this.Daily,
    },
    {
      name: "Weekly",
      value: this.Weekly,
    },
    {
      name: "Monthly",
      value: this.Monthly,
    },
  ];

  static getName(value) {
    const found = this.All.find((x) => x.value === value);
    if (_.isNil(found)) {
      return "";
    }
    return found.name;
  }
}

export class QuantityLimitType {
  static Daily = 1;
  static Weekly = 2;
  static Monthly = 3;
  static NoResetCycle = 4;

  static All = [
    {
      name: "Daily",
      value: QuantityLimitType.Daily,
    },
    {
      name: "Weekly",
      value: QuantityLimitType.Weekly,
    },
    {
      name: "Monthly",
      value: QuantityLimitType.Monthly,
    },
    {
      name: "No Reset Cycle",
      value: QuantityLimitType.NoResetCycle,
    },
  ];

  static getName = (name) => {
    const statusObject = QuantityLimitType.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class DayOfWeek {
  static Sunday = 0;
  static Monday = 1;
  static Tuesday = 2;
  static Wednesday = 3;
  static Thursday = 4;
  static Friday = 5;
  static Saturday = 6;

  static All = [
    {
      name: "Monday",
      value: DayOfWeek.Monday,
    },
    {
      name: "Tuesday",
      value: DayOfWeek.Tuesday,
    },
    {
      name: "Wednesday",
      value: DayOfWeek.Wednesday,
    },
    {
      name: "Thursday",
      value: DayOfWeek.Thursday,
    },
    {
      name: "Friday",
      value: DayOfWeek.Friday,
    },
    {
      name: "Saturday",
      value: DayOfWeek.Saturday,
    },
    {
      name: "Sunday",
      value: DayOfWeek.Sunday,
    },
  ];

  static getName = (name) => {
    const statusObject = DayOfWeek.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };

  static getNextDayName = (value) => {
    if (value === DayOfWeek.Saturday) {
      return DayOfWeek.getName(DayOfWeek.Sunday);
    }
    const dayOfWeekName =
      value === DayOfWeek.Sunday
        ? DayOfWeek.getName(DayOfWeek.Monday)
        : DayOfWeek.All.find((x) => x.value === value + 1)?.name;
    return dayOfWeekName ?? "";
  };
}

export class CumulativeQuantityType {
  static Limited = 1;
  static Unlimited = 2;

  static All = [
    {
      name: "Limited",
      value: this.Limited,
    },
    {
      name: "Unlimited",
      value: this.Unlimited,
    },
  ];
}

export class QuantityMechanicsType {
  static NoSpecificMechanics = 0;
  static SharedQuantity = 1;
  static LocationSpecificQuantity = 2;
  static ApplicationSpecificQuantity = 3;
  static LocationApplicationSpecificQuantity = 4;

  static All = [
    {
      name: "Shared Quantity",
      value: QuantityMechanicsType.SharedQuantity,
    },
    {
      name: "Location Specific Quantity",
      value: QuantityMechanicsType.LocationSpecificQuantity,
    },
    {
      name: "Application Specific Quantity",
      value: QuantityMechanicsType.ApplicationSpecificQuantity,
    },
    {
      name: "Location & Application Specific Quantity",
      value: QuantityMechanicsType.LocationApplicationSpecificQuantity,
    },
  ];

  static getName = (name) => {
    const statusObject = QuantityLimitType.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class SpecialDays {
  static PublicHolidays = 1;
  static EveofPublicHolidays = 2;
  static BlackoutHolidays = 3;
  static SubstituteHolidays = 4;

  static All = [
    {
      name: "Public Holidays",
      value: SpecialDays.PublicHolidays,
    },
    {
      name: "Eve of Public Holidays",
      value: SpecialDays.EveofPublicHolidays,
    },
    {
      name: "Blackout Holidays",
      value: SpecialDays.BlackoutHolidays,
    },
    {
      name: "Substitute Holidays",
      value: SpecialDays.SubstituteHolidays,
    },
  ];

  static getName = (name) => {
    const statusObject = QuantityLimitType.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class SpecialShows {
  static OpeningShows = 1;
  static MidnightShows = 2;

  static All = [
    {
      name: "Opening Shows",
      value: SpecialShows.OpeningShows,
    },
    {
      name: "Midnight Shows",
      value: SpecialShows.MidnightShows,
    },
  ];

  static getName = (name) => {
    const statusObject = QuantityLimitType.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class Others {
  static CrossSales = 1;
  static AdvanceSales = 2;

  static All = [
    {
      name: "Cross Sales",
      value: Others.CrossSales,
    },
    {
      name: "Advance Sales",
      value: Others.AdvanceSales,
    }
  ];

  static getName = (name) => {
    const statusObject = QuantityLimitType.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class CampaignRestriction {
  static NoRestrictions = 0;
  static NoPromotions = 1;
  static NoFoodAndBeverage = 2;
  static NoPromotionsAndFoodAndBeverage = 3;

  static All = [
    {
      name: "No Restrictions",
      value: CampaignRestriction.NoRestrictions,
    },
    {
      name: "No Promotions",
      value: CampaignRestriction.NoPromotions,
    },
    {
      name: "No Food and Beverage",
      value: CampaignRestriction.NoFoodAndBeverage,
    },
    {
      name: "No Promotions and Food and Beverage",
      value: CampaignRestriction.NoPromotionsAndFoodAndBeverage,
    },
  ];

  static getName = (name) => {
    const statusObject = CampaignRestriction.All.find((x) => x.value === name);
    return statusObject?.name ?? "";
  };
}

export class CouponTitleType {
  static UserDefined = 1;
  static MovieTitle = 2;
  static AppendMovieTitle = 3;
  static PrependMovieTitle = 4;

  static All = [
    {
      name: "Custom",
      value: this.UserDefined,
      code: "UD",
    },
    {
      name: "Movie Title",
      value: this.MovieTitle,
      code: "MT",
    },
    {
      name: "Append Movie Title",
      value: this.AppendMovieTitle,
      code: "APPD",
    },
    {
      name: "Prepend Movie Title",
      value: this.PrependMovieTitle,
      code: "PPD",
    },
  ];

  static generateTitleExample(coupon) {
    let title = coupon.title;
    if (coupon.couponTitleType === this.PrependMovieTitle) {
      title = `Avengers ${title}`;
    }

    if (coupon.couponTitleType === this.AppendMovieTitle) {
      title = `${title} Avengers`;
    }

    if (coupon.couponTitleType === this.MovieTitle) {
      title = "Avengers";
    }

    return title;
  }
}
