import {
  CreditCardProgram,
  PaymentStatus,
  TransactionStatus,
} from "../../../../constants/TransactionConstants";
import { StringHelper } from "../../../../common/Helpers";
import React from "react";
import { ApplicationID, YesNoOptions } from "../../../../common/Constants";
import { useLocations } from "../../TransactionState";

const TPOS = ApplicationID.getName(ApplicationID.TPOS);
const PWSM = ApplicationID.getName(ApplicationID.PWSM);
const GPAY = ApplicationID.getName(ApplicationID.GPAY);
const TKOS = ApplicationID.getName(ApplicationID.TKOS);

const TransactionDetailGeneralInformation = (props) => {
  const locations = useLocations();
  const { transaction } = props;

  const renderBooleanLabel = (value) => {
    return value
      ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
      : YesNoOptions.getYesNoOptions(YesNoOptions.No);
  };

  const getIsChargedText = () => {
    const { isCharged } = transaction;
    if (isCharged) return "Yes";
    if (isCharged === false) return "No";
    return "Please click ‘View Charged Status’ to update this field.";
  };

  const isChargedStatusVisible = () => {
    const { paymentCredits } = transaction;
    const [paymentCredit] = paymentCredits;
    if (!paymentCredit) return false;
    return (
      transaction.salesChannel === PWSM ||
      transaction.salesChannel === GPAY ||
      paymentCredit.creditCardProgram === CreditCardProgram.ENets ||
      paymentCredit.creditCardProgram === CreditCardProgram.PayNowHsbc
    );
  };

  const grossAmount = (transaction.grossAmount ?? 0).toFixed(6);
  const processAmount = (transaction.processAmount ?? 0).toFixed(6);
  const location = locations.find((x) => x.id === transaction.locationId);

  return (
    <>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="ReferenceNumber">
            Reference Number:
          </label>
          <input
            className="form-control display-input"
            type="text"
            id="ReferenceNumber"
            value={transaction.referenceNumber}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="GrossAmt">
            Gross Amount:
          </label>
          <input
            className="form-control display-input"
            type="text"
            id="SalesChannel"
            value={`$${grossAmount}`}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="SalesChannel">
            Sales Channel:
          </label>
          <input
            className="form-control display-input"
            type="text"
            id="SalesChannel"
            value={transaction.salesChannel}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="TransactionAmt">
            Process Amount:
          </label>
          <input
            className="form-control display-input"
            type="text"
            id="SalesChannel"
            value={`$${processAmount}`}
            disabled
          />
        </div>
      </div>
      <div className="row">
        {transaction.salesChannel !== GPAY &&
          transaction.salesChannel !== PWSM && (
            <div className="col-md-6 mb-3">
              <label className="form-control-label" htmlFor="LocationName">
                Location Name:
              </label>
              <input
                className="form-control display-input"
                type="text"
                id="LocationName"
                value={location?.name ?? ""}
                disabled
              />
            </div>
          )}
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="Status">
            Status:
          </label>
          <input
            className={`form-control display-input ${TransactionStatus.getColor(
              transaction.status
            )}`}
            type="text"
            value={TransactionStatus.getName(transaction.status)}
            disabled
          />
        </div>
      </div>
      <div className="row">
        {transaction.salesChannel !== GPAY &&
          transaction.salesChannel !== PWSM && (
            <div className="col-md-6 mb-3">
              <label className="form-control-label" htmlFor="StationName">
                Station Name:
              </label>
              <input
                className="form-control display-input"
                type="text"
                id="StationName"
                value={transaction.clientCode}
                disabled
              />
            </div>
          )}
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="PrintCount">
            Payment Status:
          </label>
          <input
            className={`form-control display-input ${PaymentStatus.getColor(
              transaction.paymentStatus
            )}`}
            type="datetime"
            value={PaymentStatus.getName(transaction.paymentStatus)}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="Time">
            Created On:
          </label>
          <input
            className="form-control display-input"
            type="datetime"
            id="TransTime"
            value={StringHelper.asDateStringToDisplayDateTimeFormat(
              transaction.createdOn
            )}
            disabled
          />
        </div>
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="Time">
            Updated On:
          </label>
          <input
            className="form-control display-input"
            type="datetime"
            value={StringHelper.asDateStringToDisplayDateTimeFormat(
              transaction.updatedOn
            )}
            disabled
          />
        </div>
      </div>
      <div className="row">
        {transaction.salesChannel !== TPOS &&
          transaction.salesChannel !== TKOS && (
            <div className="col-md-6 mb-3">
              <label className="form-control-label" htmlFor="Time">
                Email Sent On:
              </label>
              <input
                className="form-control display-input"
                type="datetime"
                id="TransTime"
                value={StringHelper.asDateStringToDisplayDateTimeFormat(
                  transaction.emailSentOn
                )}
                disabled
              />
            </div>
          )}
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="Time">
            QR Code/Tickets Scanned:
          </label>
          <input
            className="form-control display-input"
            type="datetime"
            value={renderBooleanLabel(transaction.isTicketScanned)}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <label className="form-control-label" htmlFor="Time">
            Ticket Printed:
          </label>
          <input
            className="form-control display-input"
            type="datetime"
            value={renderBooleanLabel(
              transaction.prints?.some((x) => x.isTicketPrinted)
            )}
            disabled
          />
        </div>
        {isChargedStatusVisible() && (
          <div className="col-md-6 mb-3">
            <label className="form-control-label" htmlFor="Time">
              Is Charged:
            </label>
            <p className="text-black">{getIsChargedText()}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default TransactionDetailGeneralInformation;
