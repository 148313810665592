import React from 'react';
import { StringHelper } from '../../../../common/Helpers';

export const PremiereCardPreview = (props) => {
    const { pass } = props;
    return (
        <div className="card hide-card" id="PremierePass" style={{ backgroundColor: '#5b005b', borderRadius: '20px' }}>
            <div className="card-body pb-1">
                <div className="d-flex flex-row justify-content-center">
                    <img data-src="/icons/shaw-icon-white.png" />
                </div>
                <div className="text-center my-2">
                    <h1 style={{ fontWeight: '200', fontSize: '44px' }} className="text-warning">Premiere</h1>
                </div>
                <div className="row text-light">
                    <div className="col">
                        <label className="row form-control-label m-0 Name" style={{ fontSize: '12px' }}>{pass.name}</label>
                    </div>
                </div>
                <div className="row text-light">
                    <div className="col">
                        <label className="form-control-label m-0 PassCode float-left" style={{ fontSize: '12px' }}>{pass.code}</label><label className="form-control-label float-left PassType2" style={{ fontSize: '12px' }}>/SHAW</label>
                        <label className="form-control-label my-0 float-right ExpiryDate" style={{ fontSize: '12px' }}>{StringHelper.asDateStringToMembershipCardDateFormat(pass.expiryDateStr)}</label><label className="form-control-label my-0 float-right mr-1" style={{ fontSize: '12px' }}>EXP</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
