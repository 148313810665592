import React, { useEffect, useState } from "react";
import RestClient from "../../../common/RestClient";
import { LocationBrand, MovieFormat } from "../../../constants/MovieConstants";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  campaignGlobalRefreshAtom,
  isSelectedPerformanceConditionsAtom,
  validForSpecificBrandFormatSelector,
} from "../CampaignState";
import { FormatConditionDialog } from "../dialogs/FormatConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const BrandFormatConditionCard = (props) => {
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const isSelectedPerformance = useRecoilValue(
    isSelectedPerformanceConditionsAtom
  );
  const globalRefresh = useRecoilValue(campaignGlobalRefreshAtom);
  const validForSpecificBrandFormat = useSetRecoilState(
    validForSpecificBrandFormatSelector
  );

  const { isEdit } = props;

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificBrandFormat);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/brand_formats",
      { campaignId: campaign.id },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh, globalRefresh]);

  const onSingleDelete = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/brand_formats/${selectedId}`,
      () => {
        toast.success(
          "The brand and format condition has been deleted successful."
        );
        setRefresh((r) => r + 1);
        validForSpecificBrandFormat(data.length > 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onCreate = (locationBrand, movieFormat) => {
    const params = {
      brand: locationBrand,
      format: movieFormat,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/brand_formats",
      params,
      (_) => {
        setRefresh((r) => r + 1);
        validForSpecificBrandFormat(true);
        toast.success(
          "The campaign brand format condition has been created successful."
        );
      },
      handleError
    );
    setShowCreateDialog(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_brand_format_conditions`,
      () => {
        toast.success(
          "All brand format conditions have been deleted successful."
        );
        setRefresh((ref) => ref + 1);
        validForSpecificBrandFormat(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">
          Brand &amp; Format Condition
        </label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="BrandCondition"
              id="BrandConditionRbNo"
              value="No"
              checked={!showTable}
              onClick={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label className="form-check-label" htmlFor="BrandConditionRbNo">
              No brand &amp; format restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={isSelectedPerformance || !isEdit}
              className="mr-1"
              type="radio"
              name="BrandCondition"
              id="BrandConditionRbYes"
              value="Yes"
              checked={showTable}
              onClick={() => setShowTable(true)}
            />
            <label className="form-check-label" htmlFor="BrandConditionRbYes">
              Valid for specific Brand &amp; Format
            </label>
            <button
              type="button"
              id="BrandConditionBtn"
              name="BrandCondition"
              data-toggle="modal"
              data-target="#BrandConditionModal"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th>Brand & Format</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {`${LocationBrand.getName(
                        item.brand
                      )} - ${MovieFormat.getName(item.format)}`}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setSelectedId(item.id);
                          setShowDeleteConfirmation(true);
                        }}
                        disabled={!isEdit}
                        className="btn btn-sm btn-danger"
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onSingleDelete}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <FormatConditionDialog
        visible={showCreateDialog}
        onConfirm={onCreate}
        onCancel={() => setShowCreateDialog(false)}
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
    </>
  );
};
