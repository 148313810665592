import React from "react";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import { NumberHelper } from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import { CardMode } from "../../common/CardMode";
import { PromotionCardMovieDialog } from "./PromotionCardMovieDialog";
import { MovieFormat, MovieBrand } from "../../../../constants/MovieConstants";
import { toast } from "react-toastify";
import { getMovieClassifyCssClassById } from "../../../../common/Common";
import { SystemSettings } from "../../../../common/SystemSettings";
import { SystemSettingKeys } from "../../../../common/Constants";

export class PromotionCardMovie extends React.Component {
  constructor(props) {
    super(props);
    this.refreshCache = false;
    const { model, movieIds, movieDict } = this.mergeDefaultValue(
      props.model,
      props.listMovieReleases
    );
    this.state = {
      movieModel: null,
      cachedReleases: [],
      cardMode: CardMode.View,
      isDirty: false,
      model,
      movieIds,
      movieDict,
      movieOptions: [],
      selectedMovieIndex: -1,
      selectedReleaseIndex: -1,
      showDeleteDialog: false,
      showMovieDialog: false,
      validations: [],
      isEdit: true,
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    this.refreshCache = true;
    this.loadMovieOptions();
  }

  componentDidUpdate() {
    if (this.refreshCache) {
      this.refreshCache = false;
      const movieIds = this.state.model.promotionMovies?.map(
        (movie) => movie.movieId
      );
      if (movieIds.length > 0) {
        this.loadMovieReleases(movieIds);
      }
    }
  }

  onChangePromotionMovies(promotionMovies) {
    const movieIds = [];
    const movieDict = {};

    promotionMovies.map((movie) => {
      movieIds.push(movie.movieId);
      movieDict[movie.movieId] = [];
      movie.selectedMovieReleases.map((movieRelease) => {
        movieDict[movie.movieId].push(movieRelease.id);
      });
    });
    this.setState({ movieIds, movieDict });
  }

  getControlClassName(fieldName) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""}`;
  }

  getRelease(movieId) {
    const release = this.state.cachedReleases.find(
      (ev) => ev.movieId === movieId
    );
    return (
      release || {
        classifyCode: "G",
        name: "Loading...",
        plotSummary: "",
        movieId: "",
        poster: "",
        duration: 0,
      }
    );
  }

  isCachedRelease(code) {
    return (
      this.state.cachedReleases.findIndex((evt) => evt.movieId === code) > -1
    );
  }

  loadMovieReleases(ids) {
    const movieIds = (ids || []).join(",");
    const { onEditableChange, onChangeCacheReleases } = this.props;
    RestClient.sendGetRequestWithParameters(
      `/api/v1/movie_releases/summary`,
      {
        movieIds,
      },
      (response) => {
        let { cachedReleases } = this.state;
        response.data.forEach((item) => {
          if (
            cachedReleases.findIndex((evt) => evt.movieId === item.movieId) ===
            -1
          ) {
            cachedReleases.push(item);
          }
        });
        onEditableChange(cachedReleases.length <= 0);
        onChangeCacheReleases(this.state.model.promotionMovies);
        this.setState({ cachedReleases });
      }
    );
  }

  loadMovieOptions() {
    RestClient.sendGetRequest("/api/v1/movies", (response) => {
      this.setState({ movieOptions: response.data });
    });
  }

  mergeDefaultValue(defaultValue, listMovieReleases) {
    const value = defaultValue || {};
    const model = {
      promotionMovies: value.promotionMovies || [],
    };
    if (listMovieReleases && listMovieReleases.length) {
      listMovieReleases.forEach((release) => {
        const movie = model.promotionMovies.find(
          (promotion) => promotion.movieId === release.movieId
        );
        if (movie) {
          movie.selectedMovieReleases.push(release);
        } else {
          model.promotionMovies.push({
            movieId: release.movieId,
            selectedMovieReleases: [release],
          });
        }
      });
    }
    const movieIds = [];
    const movieDict = {};

    model.promotionMovies.forEach((movie) => {
      movieIds.push(movie.movieId);
      movieDict[movie.movieId] = [];
      movie.selectedMovieReleases.forEach((movieRelease) => {
        movieDict[movie.movieId].push(movieRelease.id);
      });
    });
    return { model, movieIds, movieDict };
  }

  onHideDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  };

  onHideMovieDialog = () => {
    this.setState({ showMovieDialog: false });
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onProceedDeleteDialog = () => {
    const {
      model,
      selectedMovieIndex: movieIndex,
      selectedReleaseIndex: releaseIndex,
    } = this.state;
    const { onEditableChange, onChangeCacheReleases } = this.props;
    let { promotionMovies } = model;
    let movieReleaseEmptyFlag = false;
    promotionMovies.map((movie, index1) => {
      if (movieIndex === index1) {
        let deleteReleaseIndex = -1;
        movie.selectedMovieReleases.map((release, index2) => {
          if (releaseIndex === index2) {
            deleteReleaseIndex = index2;
          }
        });

        if (deleteReleaseIndex >= 0) {
          movie.selectedMovieReleases.splice(deleteReleaseIndex, 1);
          if (movie.selectedMovieReleases?.length === 0) {
            movieReleaseEmptyFlag = true;
          }
        }
      }
    });

    if (movieReleaseEmptyFlag) {
      promotionMovies.splice(movieIndex, 1);
    }
    onEditableChange(promotionMovies.length <= 0);
    onChangeCacheReleases(promotionMovies);
    this.onChangePromotionMovies(promotionMovies);
    this.setState(
      {
        model: { ...this.state.model, promotionMovies: promotionMovies },
        selectedReleaseIndex: -1,
        showDeleteDialog: false,
      },
      this.onModelChange
    );
  };

  onProceedMovieDialog = (model) => {
    if (!this.isCachedRelease(model.movieId)) {
      this.loadMovieReleases([model.movieId]);
    }
    const parentModel = this.state.model;
    const { onEditableChange, onChangeCacheReleases } = this.props;
    parentModel.promotionMovies.push(model);
    parentModel.promotionMovies.length > 0
      ? onEditableChange(false)
      : onEditableChange(true);
    onChangeCacheReleases(parentModel.promotionMovies);
    this.onChangePromotionMovies(parentModel.promotionMovies);
    this.setState(
      { model: parentModel, showMovieDialog: false },
      this.onModelChange
    );
  };

  onShowDeleteDialog = (index1, index2) => {
    this.setState({
      selectedMovieIndex: index1,
      selectedReleaseIndex: index2,
      showDeleteDialog: true,
    });
  };

  onShowMovieDialog = () => {
    const check = this.props.promotionDetailValidations.every(
      (val) => val.isValid
    );
    if (check) {
      this.setState({ showMovieDialog: true });
    } else {
      toast.error("Please complete the promotion details form.");
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  onPromotionCardMovieChange = (model) => {
    this.setState({ movieModel: model });
  };

  shouldComponentUpdate = (nextProps) => {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      this.refreshCache = true;
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  };

  render() {
    const {
      cardMode,
      model,
      movieOptions,
      showDeleteDialog,
      showMovieDialog,
      movieDict,
    } = this.state;
    const { displayStart, displayEnd } = this.props;
    return (
      <React.Fragment>
        <Card title="Movies">
          {CardMode.isEditMode(cardMode) && (
            <div className="d-flex flex-row justify-content-end mb-3">
              <button
                className="btn btn-primary"
                onClick={this.onShowMovieDialog}
              >
                <i className="fas fa-plus-circle" />
                Add Movie
              </button>
            </div>
          )}
          <div className="row">
            <div className="col" id="filmMovies">
              {model.promotionMovies.map((movie, index1) =>
                movie.selectedMovieReleases.map((item, index2) =>
                  this.renderPromotionMovies(
                    movie,
                    item?.brand,
                    item?.format,
                    index1,
                    index2
                  )
                )
              )}
            </div>
          </div>
        </Card>
        {showDeleteDialog && (
          <ConfirmDialog
            message="Are you sure to delete this promotion movie?"
            title="Confirmation"
            visible={showDeleteDialog}
            onCancel={this.onHideDeleteDialog}
            onProceed={this.onProceedDeleteDialog}
          />
        )}
        {showMovieDialog && (
          <PromotionCardMovieDialog
            displayStart={displayStart}
            displayEnd={displayEnd}
            movieOptions={movieOptions}
            movieDict={movieDict}
            onCancel={this.onHideMovieDialog}
            onProceed={this.onProceedMovieDialog}
            onModelChange={this.onPromotionCardMovieChange}
          />
        )}
      </React.Fragment>
    );
  }

  renderPromotionMovies(promotionMovie, brand, format, index1, index2) {
    const { cardMode } = this.state;
    const event = this.getRelease(promotionMovie.movieId);
    const newKey = Number(index1 + 1 + "" + index2);
    return (
      <div className="card" key={newKey} id={newKey}>
        <div className="card-body row">
          <div className="row col">
            <div className="col-12 col-md-3 col-xl-2 mb-3 mb-md-0" style={{ marginRight: "-54px" }}>
              <img style={{ height: "140px" }} src={event.poster} />
            </div>
            <div className="col-12 col-md-9 col-xl-6" id="#filmMovies">
              <strong>{event.name}</strong>
              <p>
              <span>
                <span
                  className={`${getMovieClassifyCssClassById(
                    event.classify
                  )} mr-1 float-left`}
                />
              </span>
                <span className="movie-duration">
                | {NumberHelper.formatRuntime(event.duration)}{" "}
              </span>
              </p>
              <div
                className="mt-2"
                dangerouslySetInnerHTML={{ __html: event.plotSummary }}
              />
              <a
                href={`${SystemSettings.get(SystemSettingKeys.PublicWebsiteUrl) || ""
                }/movie-details/${event.movieReleaseId}`}
                target="_blank"
              >
                (Details)
              </a>
            </div>
            <div className="col">
              <label className="form-control-label pl-0 col-5">Brand:</label>
              <label className="form-check-label pl-0 col-6">
                {MovieBrand.getName(brand)}
              </label>
              <label className="form-control-label pl-0 col-5">Format:</label>
              <label className="form-check-label pl-0 col-6">
                {MovieFormat.getName(format)}
              </label>
            </div>
          </div>
          {CardMode.isEditMode(cardMode) && (
            <div className="p-r-15">
              <button
                className="btn btn-danger float-right"
                onClick={() => this.onShowDeleteDialog(index1, index2)}
              >
                <i className="far fa-trash-alt" aria-hidden="true" />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
