import React from "react";
import { StatusColor, VoucherExtensionStatus } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class VoucherExtensionRequestListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      voucherExtensionStatuses: [],
      status: VoucherExtensionStatus.All,
      serialNumber: "",
    };
  }

  async componentDidMount() {
    await this.loadData(0);
    this.getVoucherExtensionStatuses();
  }

  getVoucherExtensionStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/voucher_extensions/statuses",
      (response) => {
        this.setState({ voucherExtensionStatuses: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getApiPath = () => "/api/v1/voucher_extensions";

  getDefaultSort = () => "-CreatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher_extensions",
        name: "Voucher Extension Listing",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const { status, serialNumber } = this.state;
    return super.injectSearchTerm(queryParameters, {
      serialNumber: serialNumber ?? "",
      status,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getStatusColor = (status) => {
    if (status === VoucherExtensionStatus.Pending) return StatusColor.Orange;
    if (status === VoucherExtensionStatus.Approved) return StatusColor.Green;
    if (status === VoucherExtensionStatus.Rejected) return StatusColor.Red;
    return StatusColor.Gray;
  };

  onRowClick = (id) => {
    this.props.history.push(`/vouchers/extensions/requests/${id}`);
  };

  generateExtendedComponents() {
    const { data, status, voucherExtensionStatuses } = this.state;
    const pendingCount = data?.filter(
      (item) => item.status === VoucherExtensionStatus.Pending
    ).length;
    const completedCount = data?.filter(
      (item) => item.status === VoucherExtensionStatus.Approved
    ).length;
    const rejectedCount = data?.filter(
      (item) => item.status === VoucherExtensionStatus.Rejected
    ).length;
    return (
      <>
        <div className="row">
          <div className="col-md-4">
            <div className="statistic__item">
              <h2 className="number">{pendingCount}</h2>
              <span className="desc">Pending</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="statistic__item">
              <h2 className="number">{completedCount}</h2>
              <span className="desc">Approved</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="statistic__item">
              <h2 className="number">{rejectedCount}</h2>
              <span className="desc">Rejected</span>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-2 pt-1 mb-1">
            <label className="form-control-label" htmlFor="TransStatus">
              Extension Status:
            </label>
          </div>
          <div className="col-md-3 mb-1 col-xl-2">
            <select
              className="form-control"
              id="RegisterSelect"
              name="status"
              value={status}
              onChange={this.onInputChange}
            >
              {voucherExtensionStatuses &&
                voucherExtensionStatuses.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-5 input-group ml-auto mb-1">
            <input
              type="text"
              id="input1-group2"
              name="serialNumber"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" /> Search
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Extension Type</th>
            <th>Voucher Range</th>
            <th>Status</th>
            <th>
              Created On
              <i
                id="CreatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td>{item.id}</td>
                <td>{item.extensionCriteria}</td>
                <td>{item.serialNumber}</td>
                <td className={this.getStatusColor(item.status)}>
                  {VoucherExtensionStatus.getVoucherExtensionStatus(
                    item.status
                  )}
                </td>
                <td>
                  {StringHelper.asDateStringToDefaultFormat(item.createdOn)}
                </td>
                <td>
                  {StringHelper.asDateStringToDefaultFormat(item.updatedOn)}
                </td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }
}
