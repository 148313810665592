import React from "react";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import _ from "lodash";
import {
  VoucherRequestStatus,
  PersonInChargeQuery,
  GroupCode,
} from "../../../constants/VoucherRequestConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { ColumnFormField } from "../../layout/FormLayout";

export class VoucherRequestListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      users: [],
      statistic: {
        pending: 0,
        completed: 0,
        rejected: 0,
      },
      criteria: {
        search: "",
        status: 0,
        personInCharge: "",
        personInChargeQuery: 0,
      },
      voucherTypeOptions: [],
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "voucher_requests",
        name: "Voucher Requests",
      }),
    });
  };

  getApiPath = () => "/api/v1/voucher_requests";

  getDefaultSort = () => "-CreatedOn";

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const criteria = this.state.criteria;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    criteria[fieldName] = selectValue;
    this.setState({ criteria });
  };

  onRowClick = (id) => {
    this.props.history.push(`voucher_requests/${id}`);
  };

  loadUsers = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/user_groups`,
      { code: GroupCode.CB_BRA },
      (response) => {
        const users = response.data.map((x) => x.name);
        this.setState({ users });
      },
      this.handleError
    );
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>PIC</th>
            <th onClick={this.onColumnClick}>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              const status = VoucherRequestStatus.getName(item.status);
              return (
                <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    <span>{item.voucherTypeName}</span>
                  </td>
                  <td>
                    <span className={this.getStatusStyle(status)}>
                      {_.capitalize(status)}
                    </span>
                  </td>
                  <td>{item.personInCharge}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  async componentDidMount() {
    await RestClient.sendGetRequest(
      this.getApiPath() + "/statistic",
      (response) => {
        this.setState({
          statistic: response,
        });
        this.loadUsers();
      },
      this.handleError
    );
    RestClient.sendGetRequest(
      "api/v1/voucher_types",
      (response) => {
        this.setState({
          voucherTypeOptions: response.data,
        });
      },
      this.handleError
    );
    await super.componentDidMount();
  }

  injectSearchTerm(queryParameters) {
    const { criteria } = this.state;
    return super.injectSearchTerm(queryParameters, { ...criteria });
  }

  generateExtendedComponents() {
    const { statistic } = this.state;
    return (
      <div className="row">
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.pending}</h2>
            <span className="desc">Pending Requests</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.completed}</h2>
            <span className="desc">Completed Requests</span>
          </div>
        </div>
        <div className="col-md-4">
          <div className="statistic__item">
            <h2 className="number">{statistic.rejected}</h2>
            <span className="desc">Rejected Requests</span>
          </div>
        </div>
      </div>
    );
  }

  onChangePersonInCharge = (e) => {
    const criteria = this.state.criteria;
    const value = e.target.value;
    if (Number.isNaN(+value)) {
      criteria.personInCharge = value;
      criteria.personInChargeQuery = PersonInChargeQuery.Everyone;
    } else {
      criteria.personInCharge = "";
      criteria.personInChargeQuery = +value;
    }
    this.setState({ criteria });
    this.search();
  };

  generateTableFilter() {
    const { users, voucherTypeOptions } = this.state;
    return (
      <div className="row mb-3">
        <ColumnFormField label="Request Status:" htmlFor="Status">
          <select
            className="form-control"
            name="status"
            id="Status"
            valuetype="number"
            required
            onChange={async (e) => {
              this.onInputChange(e);
              await this.search();
            }}
          >
            <option value={0}>All Status</option>
            {VoucherRequestStatus.All.map((x) => (
              <option value={x.value} key={x.value}>
                {x.name}
              </option>
            ))}
          </select>
        </ColumnFormField>
        <ColumnFormField label="Person In Charge:" htmlFor="personInCharge">
          <select
            className="form-control"
            name="personInCharge"
            id="personInCharge"
            required
            onChange={this.onChangePersonInCharge}
          >
            <option value={PersonInChargeQuery.Everyone}>Everyone</option>
            <option value={PersonInChargeQuery.Unassigned}>Unassigned</option>
            {users.map((user, index) => (
              <option value={user} key={index}>
                {user}
              </option>
            ))}
          </select>
        </ColumnFormField>
        <ColumnFormField label="Voucher Type:" htmlFor="voucherTypeId">
          <select
            className="form-control"
            name="voucherTypeId"
            id="voucherTypeId"
            onChange={this.onInputChange}
          >
            <option value="0">All Type</option>
            {voucherTypeOptions.map((option) => (
              <option value={option.id} key={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        </ColumnFormField>
        <ColumnFormField label="&nbsp;">
          <div className="d-flex">
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </div>
        </ColumnFormField>
      </div>
    );
  }

  getStatusStyle(status) {
    status = status ?? "";
    const map = {
      pending: "color-orange",
      rejected: "color-red",
    };
    if (!status) return "color-gray";
    let normalizedStatus = status.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  }
}
