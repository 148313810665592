import React, { useRef } from "react";
import MenuList from "../MenuList";
import "./MobileHeader.scss";

const MobileHeader = () => {
  const burgerButton = useRef(null);
  const onMenuClick = () => {
    if (burgerButton.current) {
      burgerButton.current.click();
    }
  };

  return (
    <header className="header-mobile d-block d-lg-none">
      <div className="header-mobile__bar">
        <div className="container-fluid">
          <div className="header-mobile-inner">
            <a className="logo" href="index.html">
              <img src="img/shaw-icon-white.png" alt="ShawTheatres" />
            </a>
            <button
              className="hamburger hamburger--slider"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="btn-burger-menu"
              ref={burgerButton}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <nav className="collapse navbar-mobile" id="navbarNav">
        <MenuList onMenuClick={onMenuClick} />
      </nav>
    </header>
  );
};

export default MobileHeader;