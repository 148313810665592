import React, { useEffect, useMemo, useState } from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import _ from "lodash";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ViewMode } from "../../../constants/PricingConstants";
import { SurchargeService } from "../../../services/SurchargeService";
import { Surcharge } from "../../../models/Surcharge";
import { ApplicationID, ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { HistoryDialog } from "../../common/HistoryDialog";
import { AddSurchargePricingModal } from "./AddSurchargePricingModal";
import { SurchargePricingItem } from "./SurchargePricingItem";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const SurchargeDetails = ({ match: { params } }) => {
  const { id } = params;
  const [viewMode, setViewMode] = useState(ViewMode.Readonly);
  const [showAddPricingModal, setShowAddPricingModal] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [surcharge, setSurcharge] = useState(new Surcharge({}));
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);

  const surchargeService = useMemo(() => new SurchargeService(), []);

  useEffect(() => {
    getSurcharge();
  }, [id, surchargeService, viewMode]);

  const getSurcharge = () => {
    surchargeService.get(id, (response) => {
      setSurcharge(new Surcharge(response));
    });
  };

  const onAddPricing = (addedPricing) => {
    setSurcharge((instance) => {
      instance.surchargePrices = surcharge.surchargePrices ?? [];
      instance.surchargePrices.push(addedPricing);
      return instance;
    });
  };

  const onRemovePrice = (selectedPrice) => {
    if (selectedPrice) {
      let cloned = _.cloneDeep(surcharge);
      cloned.surchargePrices = cloned.surchargePrices.filter(
        (x) => x.id !== selectedPrice.id
      );
      setSurcharge(cloned);
    }
  };

  const onProcessUpdateSurcharge = () => {
    setShowUpdateConfirmation(false);
    setViewMode(ViewMode.Readonly);
    const params = {
      name: surcharge.name,
      appId: +surcharge.appId,
    };
    surchargeService.update(surcharge.id, params, (response) => {
      const { surchargePrices, ...results } = response;
      setSurcharge((prev) => ({
        ...prev,
        ...results,
      }));
      toast.success("Update surcharge information success");
    });
  };

  const onCancelUpdateSurcharge = () => {
    setShowUpdateConfirmation(false);
    getSurcharge();
  };

  const onProcessCancelConfirmation = () => {
    setViewMode(ViewMode.Readonly);
    setShowCancelConfirmation(false);
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setSurcharge((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onEdit = () => {
    setViewMode(ViewMode.Editable);
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "staff-pass-details",
        name:
          viewMode === ViewMode.Readonly ? "View Surcharge" : "Edit Surcharge",
      }),
    });
  };

  if (_.isNil(surcharge)) {
    return <></>;
  }
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="container-fluid">
              {viewMode === ViewMode.Readonly && (
                <ButtonActionDetailGroup>
                  {AuthenticationManager.isAuthorized(
                    ResourceID.ServiceSurchargeEditButtonPage
                  ) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success"
                      onClick={onEdit}
                    >
                      <i className="fas fa-edit" aria-hidden="true" /> Edit
                    </ButtonActionDetailGroup.Item>
                  )}
                  <ButtonActionDetailGroup.Item
                    className="btn btn-outline-secondary"
                    data-target="#HistoryPopup"
                    data-toggle="modal"
                    onClick={() => setShowHistoryDialog(true)}
                  >
                    History
                  </ButtonActionDetailGroup.Item>
                </ButtonActionDetailGroup>
              )}
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      Surcharge Details
                      <i
                        className="fas fa-chevron-down float-right zoom-1_5 info"
                        style={{ display: "none" }}
                        aria-hidden="true"
                      />
                      <i
                        className="fas fa-chevron-up float-right zoom-1_5 info"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="card-body info">
                      <div className="row mb-3">
                        <div className="col-md-4 col-xl-3 mb-2">
                          Name:{" "}
                          <input
                            className="form-control display-input"
                            value={surcharge.name}
                            id="SurchargeName"
                            name="name"
                            readOnly={viewMode === ViewMode.Readonly}
                            onChange={onInputChange}
                          />
                        </div>
                        <div className="col-md-4 col-xl-3">
                          Sales Channel:{" "}
                          {viewMode === ViewMode.Readonly ? (
                            <p className="text-black">
                              {
                                ApplicationID.SaleChannels.find(
                                  (x) => x.value === surcharge.appId
                                )?.name
                              }
                            </p>
                          ) : (
                            <select
                              id="SalesChannel"
                              className="form-control display-input"
                              name="appId"
                              value={surcharge.appId}
                              onChange={onInputChange}
                            >
                              <option value="">Select Sale Channel</option>
                              {ApplicationID.SaleChannels.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        {viewMode === ViewMode.Editable && (
                          <div className="col flex-grow-0">
                            &nbsp;
                            <button
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#PricingModal"
                              onClick={() => {
                                setShowAddPricingModal(true);
                              }}
                            >
                              Add Pricing
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <label>Pricing details:</label>
                          {(surcharge.surchargePrices ?? []).map(
                            (item, index) => (
                              <SurchargePricingItem
                                key={index}
                                data={item}
                                viewMode={viewMode}
                                onRemove={() => onRemovePrice(item)}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewMode === ViewMode.Editable && (
                <div
                  id="EditButtons"
                  className="d-flex justify-content-end pb-3"
                >
                  <div className="d-inline-flex ml-auto">
                    <button
                      className="btn btn-success mr-2"
                      onClick={() => setShowUpdateConfirmation(true)}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => setShowCancelConfirmation(true)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AddSurchargePricingModal
        surchargeId={id}
        visible={showAddPricingModal}
        onCancel={() => setShowAddPricingModal(false)}
        onProceed={onAddPricing}
      />
      {showHistoryDialog && (
        <HistoryDialog
          objectId={surcharge.id}
          onClose={() => setShowHistoryDialog(false)}
          url="/api/v1/history_logs"
          objectType={ObjectType.Surcharge}
        />
      )}
      <ConfirmDialog
        visible={showCancelConfirmation}
        onCancel={() => setShowCancelConfirmation(false)}
        onProceed={() => onProcessCancelConfirmation()}
        title="Cancel confirmation"
      />
      <ConfirmDialog
        visible={showUpdateConfirmation}
        onCancel={() => onCancelUpdateSurcharge()}
        onProceed={() => onProcessUpdateSurcharge()}
        title="Update confirmation"
        message="Are you sure want to update Surcharge information?"
      />
    </>
  );
};
