import React, { Component } from "react";
import '../css/documentation.scss';

export class ActivateDeactivateUser extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid" >
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Activate/Deactivate A User</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 3 minutes to read</p>
                        <br /><br />
                        <div class="createUser container-fluid">
                            <ol>
                                <li class="createList">
                                    Click on <strong>'User'</strong> link from main left side menu. This will bring you to User Listing Page. <br />
                                    <img src="/img/documentation-img/users/Users_Menu.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    From the user listing page, you can select a ‘record’ from the User listing table.<br />
                                    <img src="/img/documentation-img/users/Select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    Upon clicking a record from the table, you will be redirected to the ‘User Details page’.<br />
                                    <img src="/img/documentation-img/users/userDetails.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To Activate or Deactivate a User, click either the ‘Activate’ or ‘Deactivate’ button found in the top right of the User Details page.<br />
                                    <img src="/img/documentation-img/users/Activate.png" class="userImage" />&nbsp;&nbsp;
                                    <img src="/img/documentation-img/users/Deactivate.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    After clicking the button, a confirmation prompt will appear. Click Yes to confirm the activation or deactivation of the user.<br />
                                    <img src="/img/documentation-img/users/activatePrompt.png" class="userImage" />
                                    <img src="/img/documentation-img/users/deactivatePrompt.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    A message prompt will show once the changes are made successfully. <br />
                                    <img src="/img/documentation-img/users/activateMsg.png" class="userImage" />&nbsp;&nbsp;
                                    <img src="/img/documentation-img/users/deactivateMsg.png" class="userImage" />
                                </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/users/updateUser" className="btn btn-primary">&laquo; Update User</a>&nbsp;
                                <a href="/documentation/users/resetUser" className="btn btn-primary">Reset User &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}