import React, { useEffect, useState } from "react";
import { TicketingApplication } from "../../../constants/PerformanceConstants";
import PerformanceSelector from "./PerformanceSelector";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { MovieFormat } from "../../../constants/MovieConstants";
import { PerformanceRestService } from "../../../services/PerformanceRestService";
import { toast } from "react-toastify";
import { CustomSelect } from "../../common/CustomSelect";

const defaultCriteria = { movieId: 0, locationId: 0, movieReleaseId: 0 };

const PerformanceRestrictionModal = (props) => {
  const [movieReleases, setMovieReleases] = useState([]);
  const [criteria, setCriteria] = useState(defaultCriteria);
  const [selectedApplication, setSelectedApplication] = useState([]);

  const convertFormValue = (form) => {
    const { isValidForCrossSales, ...criteria } = Object.fromEntries(
      new FormData(form)
    );
    return {
      isValidForCrossSales: !!isValidForCrossSales,
      allowedTicketingApplications: selectedApplication.reduce(
        (a, b) => a + b,
        0
      ),
      criteria,
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.persist();
    const form = e.target;
    const request = convertFormValue(form);
    new PerformanceRestService().applySaleRestrictions(request).then((response) => {
      toast.success(response.data.message);
      props.onClose();
      form.reset();
    });
  };

  const getMovieReleases = (movieId) => {
    new MovieReleaseService()
      .list({ movieId })
      .then(({ data: response }) => {
        const movieRelease = response.data.map((x) => ({
          code: x.id,
          name: `${x.primaryTitle} - ${MovieFormat.getName(x.format)}`,
        }));
        setMovieReleases(movieRelease);
      })
      .catch(() => setMovieReleases([]));
  };

  const onSelectChange = (e) => {
    const type = e.target.name;
    const value = e.target.value;
    const newValue = { [type]: value };
    if (type === "movieId") {
      getMovieReleases(value);
      newValue.movieReleaseId = 0;
    }
    setCriteria((prev) => ({ ...prev, ...newValue }));
  };

  useEffect(() => {
    if (!props.show) {
      setCriteria(defaultCriteria);
      setMovieReleases([]);
      setSelectedApplication([]);
    }
  }, [props.show]);

  if (!props.show) return <></>;

  const { locationSelectors, movieSelectors } = props;

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Performance Restrictions
              </h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={props.onClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form onSubmit={onSubmit}>
              <div className="modal-body">
                <div className="px-3">
                  <span className="font-weight-bold">Criteria</span>
                  <div className="row mb-3">
                    <PerformanceSelector
                      selectorName="Movie"
                      eventSelectors={movieSelectors}
                      onSelectChange={(e) => onSelectChange(e)}
                      name="movieId"
                      value={criteria.movieId}
                    />
                    <PerformanceSelector
                      selectorName="Location"
                      eventSelectors={locationSelectors}
                      onSelectChange={(e) => onSelectChange(e)}
                      name="locationId"
                      value={criteria.locationId}
                    />
                    <PerformanceSelector
                      selectorName="Movie Release"
                      eventSelectors={movieReleases}
                      onSelectChange={(e) => onSelectChange(e)}
                      name="movieReleaseId"
                      value={criteria.movieReleaseId}
                    />
                  </div>
                  <span className="font-weight-bold">Restriction Settings</span>
                  <div className="row">
                    <div className="col-12 d-flex align-items-center mb-2 row mx-0">
                      <label className="form-control-label col-12 col-md-6 col-lg-3">
                        Ticketing Applications:
                      </label>
                      <div className="col-12 col-md-6 col-lg-9 px-0">
                        <CustomSelect
                          data={TicketingApplication.All.filter((x) => x.value)}
                          label="name"
                          value="value"
                          isMulti={true}
                          onChange={(e) =>
                            setSelectedApplication(e.map((x) => x.value))
                          }
                          selectedValue={selectedApplication}
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex">
                      <label className="form-control-label col-12 col-md-6 col-lg-3">
                        Is Valid For Cross Sales:
                      </label>
                      <div className="form-check col-3">
                        <input
                          className="form-check-input"
                          name="isValidForCrossSales"
                          type="checkbox"
                          value="true"
                          defaultChecked={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={
                    criteria.movieId === 0 &&
                    criteria.locationId === 0 &&
                    criteria.movieReleaseId === 0
                  }
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};

export default PerformanceRestrictionModal;
