import React, { useState } from "react";
import { Classify } from "../../../constants/MovieConstants";
import Validators from "../../common/Validators";
import _ from "lodash";

export const ClassificationConditionDialog = (props) => {
  const [multiCondition, setMultiCondition] = useState([]);
  const [validations, setValidations] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { onConfirm, onCancel, visible } = props;
  const onInputCheckChange = (e) => {
    const conditions = [...multiCondition];
    if (e.target.checked) {
      conditions.push(parseInt(e.target.value));
    } else {
      const index = conditions.findIndex(
        (item) => item === parseInt(e.target.value)
      );
      if (index >= 0) {
        conditions.splice(index, 1);
      }
    }
    setMultiCondition(conditions);
  };

  const isValidated = () => {
    return validations.every((val) => val.isValid);
  };

  const onAddClick = () => {
    if (!isValidated()) {
      setIsSubmitted(true);
      return;
    }
    onConfirm(multiCondition);
    setMultiCondition([]);
  };

  const onValidationStatusChange = (e) => {
    const clonedValidations = [...validations];
    let validation = clonedValidations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      clonedValidations.push(e);
    }
    setValidations(clonedValidations);
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        id="ClassifyConditionModal"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Valid for specific classification
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="row">
                <div className="col-3">
                  Classify:
                  <span className="v font-weight-bold" style={{ color: "red" }}>
                    *
                  </span>
                </div>
                <div className="col-8" id="ClassifyCodeSelection">
                  {Classify.All.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="ClassificationCondition"
                          value={item.value}
                          onChange={onInputCheckChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="cbClassifyCode0"
                        >
                          {item.name}
                        </label>
                      </div>
                    </div>
                  ))}
                  <Validators.RequiredValidator
                    onValidationStatusChange={onValidationStatusChange}
                    fieldName="classify"
                    isEnabled={isSubmitted}
                    property="Classify"
                    value={multiCondition}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-danger"
                data-dismiss="modal"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn btn-success"
                data-dismiss="modal"
                onClick={onAddClick}
              >
                Add Condition
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
