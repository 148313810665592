import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType, CustomContentStatus } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { CustomContentDetailCard } from "./cards/CustomContentDetailCard";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

const EditPageName = "Edit Custom Content";
const ViewPageName = "View Custom Content";

export class CustomContentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      customContent: null,
      customContentId: "",
      customContentDetail: null,
      customContentDetailValidations: [],
      showHistoryDialog: false,
      showCancelDialog: false,
      showDeactiveDialog: false,
      showConfirmDialog: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const customContentId = StringHelper.toNumber(id);
    if (isNaN(id) || customContentId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ customContentId }, this.refreshData);
  }

  isValidated() {
    const { customContentDetailValidations } = this.state;
    return customContentDetailValidations.every((val) => val.isValid);
  }

  handleSuccess = (message) => {
    toast.success(message);
  };

  loadCustomContent(id) {
    RestClient.sendGetRequest(
      `/api/v1/custom_contents/${id}`,
      (response) => {
        this.setState({ customContent: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  onShowDeactiveDialog = () => {
    this.setState({ showDeactiveDialog: true });
  };

  onHideDeactiveDialog = () => {
    this.setState({ showDeactiveDialog: false });
  };

  onProceedDeactiveDialog = () => {
    const { customContentId, customContentDetail } = this.state;
    customContentDetail.isActive = customContentDetail.isActive ? false : true;
    const request = Object.assign(
      customContentDetail
    );
    RestClient.sendPutRequest(
      `/api/v1/custom_contents/${customContentId}`,
      request,
      (response) => {
        const message =
          response.isActive
            ? "Custom Content has been activated successfully."
            : "Custom Content has been deactivated successfully.";
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          customContent: response,
          showDeactiveDialog: false,
        });
        toast.success(message);
      },
      (error) => {
        this.setState({ showDeactiveDialog: false });
        toast.error(error);
      }
    );
  };


  onEditCustomContentClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onCustomContentDetailModelChange = (model) => {
    this.setState({ customContentDetail: model });
  };

  onCustomContentDetailValidationsChange = (validations) => {
    this.setState({ customContentDetailValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      customContent: Object.assign({}, this.state.customContent),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { customContentId, customContentDetail } = this.state;
    const request = Object.assign(
      {},
      customContentDetail
    );
    RestClient.sendPutRequest(
      `/api/v1/custom_contents/${customContentId}`,
      request,
      (response) => {
        this.setState({
          cardMode: CardMode.View,
          customContent: response,
          isSubmit: false,
          showConfirmDialog: false,
        });
        toast.success(GenericMessages.PublishChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({ showConfirmDialog: false });
        this.handleError(error);
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  refreshData() {
    const { customContentId } = this.state;
    this.loadCustomContent(customContentId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/custom_contents/${additionalParameter ?? ""}`);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "custom_contents",
        name: "Custom Contents",
      }),
      activeModule: new NavigationItem({
        identifier: "custom_content_details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  render() {
    const {
      cardMode,
      customContent,
      isSubmit,
      customContentDetail,
      showCancelDialog,
      showConfirmDialog,
      showDeactiveDialog,
      showHistoryDialog,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-1"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    { customContent?.isPage &&
                      AuthenticationManager.isAuthorized(
                        ResourceID.CustomContentActivateDeactivateButton
                      ) && (
                        <>
                          {customContentDetail && customContentDetail.isActive ? (
                            <button
                              className="btn btn-danger ml-1"
                              onClick={this.onShowDeactiveDialog}
                            >
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="btn btn-success ml-1"
                              onClick={this.onShowDeactiveDialog}
                            >
                              Activate
                            </button>
                          )}
                        </>)}
                    {
                      AuthenticationManager.isAuthorized(
                        ResourceID.CustomContentEditButton
                      ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={customContent ? undefined : "disabled"}
                          onClick={this.onEditCustomContentClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>)
                    }
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <CustomContentDetailCard
                  cardMode={cardMode}
                  defaultValue={customContent}
                  isSubmit={isSubmit}
                  model={customContentDetail}
                  onModelChange={this.onCustomContentDetailModelChange}
                  onValidationsChange={this.onCustomContentDetailValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <div className="d-flex flex-row justify-content-end">
                <button
                  className="btn btn-success mr-1"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </button>
                <button
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && customContent}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && customContent}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showDeactiveDialog && customContentDetail}
          onCancel={this.onHideDeactiveDialog}
          onProceed={this.onProceedDeactiveDialog}
          message={customContentDetail && customContentDetail.isActive ? "Are you sure you want to deactivate custom content ?" : "Are you sure you want to activate custom content ?"}
          title={customContentDetail && customContentDetail.isActive ? "Deactivate" : "Activate"}
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId={customContent.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.CustomContent}
          />
        )}
      </div>
    );
  }
}
