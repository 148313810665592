import React, { Component } from "react";
import '../css/documentation.scss';

export class EditCustomContentHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Edit Custom Content</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 4 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Custom Content " link. This will take you to the Custom Content Listing Page.   <br />
                                    <img src="/img/documentation-img/CCImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Custom Content Listing Page, click on any custom content listing of your choice. This will take you to the View Custom Content Details. <br />
                                    <img src="/img/documentation-img/CCImg/list_select.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To edit the custom content listing, click on the “Edit” button in the top right corner of the page.<br />
                                    <img src="/img/documentation-img/CCImg/view_edit.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    In the Edit Custom Content Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            ID
                                        </li>
                                        <li>
                                            Status
                                        </li>
                                        <li>
                                            Title
                                        </li>
                                        <li>
                                            Page Code
                                        </li>
                                        <li>
                                            Page Type
                                        </li>
                                        <li>
                                            Content
                                        </li>

                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Content should be in HTML format.
                                </div>
                            </li>
                                        <li class="createList">
                                            To save your changes, click on “Save Changes” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/CCImg/edit_save.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you would like to save your changes, click on the “Confirm” button.<br />
                                            <img src="/img/documentation-img/CCImg/edit_save_save.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            Upon successful save, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/CCImg/save_success.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To cancel all changes, click on the “Cancel” button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/CCImg/edit_cancel.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To confirm that you want to cancel all changes, click on "Confirm". <br />
                                            <img src="/img/documentation-img/CCImg/edit_cancel_confirm.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            To view history changes, click on the "History” button, at the top right corner of the page.<br />
                                            <img src="/img/documentation-img/CCImg/view_history.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A popup will appear, stating the changes made. Click on "view details” to see more or “X” to close the popup.<br />
                                            <img src="/img/documentation-img/CCImg/history_viewdetails.png" class="userImage" />
                                        </li>
                                        <li class="createList">
                                            A pop-up will appear, stating the old and new values. Click Back button to close this page.<br />
                                            <img src="/img/documentation-img/CCImg/history_details.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/customcontent/EditCustomContentHelp" className="btn btn-primary">&laquo; Edit Custom Content</a>&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}