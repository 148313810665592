import React from "react";
import { ProgrammeAvailabilitiesEnum } from "../../../../common/Constants";
import { StringHelper } from "../../../../common/Helpers";
import Validators from "../../../common/Validators";
import { CustomSelect } from "../../../common/CustomSelect";
import { RowFormField } from "../../../layout/FormLayout";

export class ProgrammeCardMovieDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentAvailability: ProgrammeAvailabilitiesEnum.Cinema,
      isDirty: false,
      isSubmit: false,
      kinoLoungeUrl: "",
      movieReleaseId: "",
      salesStartTimeStr: "",
      salesEndTimeStr: "",
      displayWeight: 125,
      validations: [],
    };
  }

  buildModel() {
    const {
      kinoLoungeUrl,
      movieReleaseId,
      salesStartTimeStr,
      salesEndTimeStr,
      contentAvailability,
      displayWeight,
    } = this.state;
    const salesEndTime = StringHelper.isNullOrEmpty(salesEndTimeStr)
      ? null
      : salesEndTimeStr;
    const salesStartTime = StringHelper.isNullOrEmpty(salesStartTimeStr)
      ? null
      : salesStartTimeStr;
    return {
      kinoLoungeUrl,
      movieReleaseId,
      contentAvailability,
      salesEndTime,
      salesStartTime,
      displayWeight,
    };
  }

  getControlClassName(fieldName) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) && this.state.isSubmit;
    return `form-control ${isError ? "is-invalid" : ""}`;
  }

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  onRadioChange = (e) => {
    this.setState({ isDirty: true, contentAvailability: +e.target.value });
  };

  onProceedClick = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    const model = this.buildModel();
    if (this.props.onProceed) {
      this.props.onProceed(model);
    }
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    this.setState({ isDirty: true, [fieldName]: value });
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  onSelectMovie = (e) => {
    this.setState({ isDirty: true, movieReleaseId: e.value });
  };

  render() {
    const releaseOptions = this.props.releaseOptions || [];
    const { displayStart, displayEnd } = this.props;
    const {
      contentAvailability,
      isSubmit,
      kinoLoungeUrl,
      movieReleaseId,
      salesStartTimeStr,
      salesEndTimeStr,
      displayWeight,
    } = this.state;
    return (
      <>
        <div
          className="modal fade show"
          id="resetPassword"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Add Movie
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid text-left">
                  <RowFormField label="Film Title:">
                    <CustomSelect
                      data={releaseOptions}
                      label="name"
                      value="id"
                      onChange={this.onSelectMovie}
                      selectedValue={movieReleaseId}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="movieReleaseId"
                      isEnabled={isSubmit}
                      property="Film Title"
                      value={movieReleaseId}
                    />
                  </RowFormField>
                  <RowFormField label="Sales Start Date:">
                    <input
                      type="datetime-local"
                      id="SaleStart"
                      placeholder="Enter Sale Start Date"
                      name="addFilm"
                      className={this.getControlClassName("salesStartTimeStr")}
                      fieldname="salesStartTimeStr"
                      onChange={this.onTextBoxChange}
                      value={salesStartTimeStr}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="salesStartTimeStr"
                      isEnabled={isSubmit}
                      property="Sales Start Time"
                      value={salesStartTimeStr}
                    />
                    <Validators.RangeDateValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="salesStartTimeStrRange"
                      isEnabled={isSubmit}
                      minDay={displayStart}
                      maxDay={displayEnd}
                      property="Sales Start Time"
                      value={salesStartTimeStr}
                    />
                  </RowFormField>
                  <RowFormField label="Sales End Date:">
                    <input
                      type="datetime-local"
                      id="SaleEnd"
                      placeholder="Enter Sale End Date"
                      name="addFilm"
                      className={this.getControlClassName("salesEndTimeStr")}
                      fieldname="salesEndTimeStr"
                      onChange={this.onTextBoxChange}
                      value={salesEndTimeStr}
                    />
                    <Validators.RequiredValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="salesEndTimeStr"
                      isEnabled={isSubmit}
                      property="Sales End Time"
                      value={salesEndTimeStr}
                    />
                    <Validators.RangeDateValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="salesEndTimeStrRange"
                      isEnabled={isSubmit}
                      minDay={
                        salesStartTimeStr ? salesStartTimeStr : displayStart
                      }
                      maxDay={displayEnd}
                      property="Sales End Time"
                      value={salesEndTimeStr}
                    />
                  </RowFormField>
                  <RowFormField label="Display Weight:">
                    <input
                      id="Weight"
                      type="number"
                      placeholder="Enter Display Weight"
                      min="1"
                      max="125"
                      className={this.getControlClassName("displayWeight")}
                      fieldname="displayWeight"
                      onChange={this.onTextBoxChange}
                      onKeyDown={this.onKeyPressDisplayWeight}
                      value={displayWeight}
                      readOnly={false}
                    />
                    <Validators.RangeValidator
                      onValidationStatusChange={this.onValidationStatusChange}
                      fieldName="displayWeight"
                      isEnabled={isSubmit}
                      min="1"
                      max="125"
                      property="Display Weight"
                      value={displayWeight}
                      allowDecimal={true}
                    />
                  </RowFormField>
                  <div className="row form-group">
                    <label className="col-4 text-nowrap form-control-label">
                      Film Availability:
                    </label>
                    <div className="col-md-7">
                      <div className="form-check">
                        <div className="checkbox">
                          <label htmlFor="Cinema" className="form-check-label">
                            <input
                              type="radio"
                              name="contentAvailability"
                              value={ProgrammeAvailabilitiesEnum.Cinema}
                              className="mr-2"
                              fieldname="enableInCinema"
                              checked={
                                contentAvailability ===
                                ProgrammeAvailabilitiesEnum.Cinema
                              }
                              onChange={this.onRadioChange}
                            />
                            Cinema
                          </label>
                        </div>
                        <div className="checkbox">
                          <label htmlFor="Kino" className="form-check-label ">
                            <input
                              type="radio"
                              name="contentAvailability"
                              value={ProgrammeAvailabilitiesEnum.KinoLounge}
                              className="mr-2"
                              checked={
                                contentAvailability ===
                                ProgrammeAvailabilitiesEnum.KinoLounge
                              }
                              onChange={this.onRadioChange}
                            />
                            KinoLounge
                          </label>
                        </div>
                      </div>
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldName="contentAvailability"
                        isEnabled={isSubmit}
                        property="Film Availability"
                        value={contentAvailability}
                      />
                    </div>
                  </div>
                  {contentAvailability ===
                    ProgrammeAvailabilitiesEnum.KinoLounge && (
                    <div className="row form-group kino">
                      <label
                        className="col-4 form-control-label text-nowrap"
                        htmlFor="KinoLink"
                      >
                        KinoLounge Link:
                      </label>
                      <div className="col-7">
                        <input
                          id="KinoLink"
                          placeholder="Enter KinoLounge Link"
                          name="addFilm"
                          className={this.getControlClassName("kinoLoungeUrl")}
                          fieldname="kinoLoungeUrl"
                          onChange={this.onTextBoxChange}
                          value={kinoLoungeUrl}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onProceedClick}
                >
                  Add Movie
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show" />
      </>
    );
  }
}
