import RestClient from "../common/RestClient";

export class UserService {
    constructor() {
        this.baseApi = "/api/v1/users";
    }

    getAll(parameters, onSuccess, onError) {
        return RestClient.sendGetRequestWithParameters(
            `${this.baseApi}`, 
            parameters,
            response => {
                if (onSuccess) {
                    onSuccess(response)
                }
            }, error => {
                if (onError) {
                    onError(error)
                    return;
                }
                console.error(error);
            });
    }
}