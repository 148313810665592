import React, { useEffect, useState } from "react";
import {
  IssuingBankCode,
  RedemptionQuantityType,
} from "../../../constants/CampaignConstants";
import { Days } from "../../../constants/PricingConstants";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import { EditCreditCardConditionDialog } from "../dialogs/EditCreditCardConditionDialog";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  validForSpecificCreditCardSelector,
} from "../CampaignState";
import { NewCreditCardConditionDialog } from "../dialogs/NewCreditCardConditionDialog";
import { CreditCardType } from "../../../constants/TransactionConstants";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const CreditCardConditionCard = (props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [activeInstance, setActiveInstance] = useState(null);
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const validForSpecificCreditCard = useSetRecoilState(
    validForSpecificCreditCardSelector
  );
  const { isEdit } = props;

  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificCreditCard);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/credit_card",
      {
        campaignId: campaign.id,
        includes: "CampaignConditionCreditCardBinRanges",
      },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh]);

  const onDeleteElement = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/credit_card/${selectedId}`,
      () => {
        toast.success("The credit card condition has been deleted successful.");
        setRefresh((r) => r + 1);
        validForSpecificCreditCard(data.length > 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_credit_card_conditions`,
      () => {
        toast.success(
          "All credit card conditions have been deleted successful."
        );
        setRefresh((ref) => ref + 1);
        validForSpecificCreditCard(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Credit Card Condition</label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="CreditCardCondition"
              id="CreditCardConditionRbNo"
              value="No"
              checked={!showTable}
              onChange={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label
              className="form-check-label"
              htmlFor="CreditCardConditionRbNo"
            >
              No Credit Card restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="CreditCardCondition"
              id="CreditCardConditionRbYes"
              value="Yes"
              checked={showTable}
              onChange={() => setShowTable(true)}
            />
            <label
              className="form-check-label"
              htmlFor="CreditCardConditionRbYes"
            >
              Valid for specific credit card
            </label>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th style={{ width: "15%" }}>Credit Card Type</th>
              <th style={{ width: "15%" }}>Issuing Bank</th>
              <th style={{ width: "10%" }}>Reset Day</th>
              <th style={{ width: "20%" }}>Redemption Quantity Per Card</th>
              <th style={{ width: "15%" }}>Redemption Quantity Type</th>
              <th style={{ width: "15%" }}>Bin Range/s</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((conditionCreditCard, index) => (
                <tr key={index}>
                  <td>
                    {CreditCardType.getName(conditionCreditCard.creditCardType)}
                  </td>
                  <td>
                    {IssuingBankCode.getName(
                      conditionCreditCard.issuingBankCode
                    )}
                  </td>
                  <td>{Days.getDisplay(conditionCreditCard.resetDay)}</td>
                  <td>{conditionCreditCard.redemptionQuantityPerCard}</td>
                  <td>
                    {RedemptionQuantityType.getName(
                      conditionCreditCard.redemptionQuantityType
                    )}
                  </td>
                  <td>
                    {conditionCreditCard.campaignConditionCreditCardBinRanges.map(
                      (x) => {
                        if (!x.binEnd || x.binEnd === 0) {
                          return <div key={x.binStart}>{x.binStart}</div>;
                        }
                        return (
                          <div
                            key={x.binStart}
                          >{`${x.binStart} to ${x.binEnd}`}</div>
                        );
                      }
                    )}
                  </td>
                  <td>
                    <div>
                      <button
                        className="btn btn-sm btn-success mr-2"
                        onClick={() => {
                          setActiveInstance(conditionCreditCard);
                          setShowEditModal(true);
                        }}
                        disabled={!isEdit}
                      >
                        <i className="fas fa-pen" />
                      </button>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          setShowDeleteConfirmation(true);
                          setSelectedId(conditionCreditCard.id);
                        }}
                        disabled={!isEdit}
                      >
                        <i className="far fa-trash-alt" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </BaseTableCondition>
      )}
      <NewCreditCardConditionDialog
        visible={showCreateDialog}
        campaignId={campaign.id}
        onPostUpdate={() => {
          setRefresh((r) => r + 1);
          validForSpecificCreditCard(true);
          setShowCreateDialog(false);
        }}
        onCancel={() => setShowCreateDialog(false)}
      />
      <EditCreditCardConditionDialog
        campaignId={campaign.id}
        instance={activeInstance}
        visible={showEditModal}
        onPostUpdate={() => {
          setShowEditModal(false);
          setRefresh((r) => r + 1);
        }}
        onCancel={() => setShowEditModal(false)}
      />
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onDeleteElement}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
    </>
  );
};
