import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SeatSelection } from "../../../../common/SeatingLayout";
import { LayoutRestService } from "../../../../services/LayoutRestService";
import { useRecoilState } from "recoil";
import { corporateBookingAtom } from "../../CorporateBookingState";
import { loadScript } from "../../../../common/Helpers";
import { useSignalR } from "./useSignalR";

export const useSeatSelection = (props) => {
  const [seatSelection, setSeatSelection] = useState(null);
  const [corporateBooking, setCorporateBooking] =
    useRecoilState(corporateBookingAtom);

  const { readOnly } = props;
  const { guestCount, seatCodes } = corporateBooking.corporateBookingDetails;
  const { performanceId } = corporateBooking;

  const { signalR } = useSignalR({
    pathName: performanceId ? `seating?performanceId=${performanceId}` : null,
  });

  const canHoldSeat = useCallback(() => {
    if (!seatCodes) {
      return true;
    }
    return seatCodes.length < guestCount;
  }, [seatCodes, guestCount]);

  const onLoad = useCallback(
    (performanceId) => {
      if (!performanceId) return;
      if (typeof window.$ === "function" && typeof window.ej !== "undefined") {
        new LayoutRestService()
          .list({
            performanceId: performanceId,
            maxResults: 99999,
          })
          .then(({ data: results }) => {
            if (!results.data.length) {
              toast.error("No layout config for this performance.");
              return;
            }
            if (seatSelection) {
              seatSelection.destroy();
            }
            setSeatSelection(
              new SeatSelection("DiagramTest", performanceId, results.data)
            );
          });
      }
    },
    [seatSelection]
  );

  useEffect(() => {
    if (!seatSelection) return;
    seatSelection.build(seatCodes, !!corporateBooking.id);
    seatSelection.readOnly = () => !!readOnly;
    seatSelection.onSeatChange = onSeatChange;
    seatSelection.canHoldSeat = canHoldSeat;
    // Dependencies with only seatSelection to prevent infinite loop due to seating layout initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatSelection]);

  useEffect(() => {
    if (!seatSelection) {
      return;
    }
    seatSelection.readOnly = () => readOnly;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readOnly]);

  useEffect(() => {
    if (!seatSelection) {
      return;
    }
    seatSelection.canHoldSeat = canHoldSeat;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canHoldSeat]);

  useEffect(() => {
    if (seatSelection && signalR) {
      signalR.on("OnSeatChange", (event) => {
        seatSelection.onNotifySeatChanges(event);
      });
    }
    return () => {
      if (signalR) {
        signalR.off("OnSeatChange");
      }
    };
  }, [seatSelection, signalR]);

  useEffect(() => {
    if (!performanceId) return;
    Promise.all([
      loadScript("/js/jquery.js"),
      loadScript("/js/ej.tooltip.diagram.all.min.js"),
      loadScript("/js/ej2.diagram.min.js"),
    ]).then(() => {
      // Delay to wait ej2.diagram.min.js init
      setTimeout(() => onLoad(performanceId), 0);
    });
    // Dependencies with only performanceId to prevent infinite loop due to seating layout initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceId]);

  const onSeatChange = useCallback(
    ({ selectedItems: selectedSeats }) => {
      if (selectedSeats.length > guestCount) {
        toast.error(
          `The selected seats exceeded the guest count ${guestCount}.`
        );
      }
      setCorporateBooking((prev) => ({
        ...prev,
        corporateBookingDetails: {
          ...prev.corporateBookingDetails,
          seatCodes: selectedSeats.map((x) => x.seatCode),
        },
      }));
    },
    [guestCount, setCorporateBooking]
  );

  useEffect(() => {
    if (!seatSelection) return;
    seatSelection.onSeatChange = onSeatChange;
  }, [seatSelection, guestCount, onSeatChange]);

  useEffect(() => {
    return () => {
      if (seatSelection) {
        seatSelection.releaseAllSeatsInSession();
        seatSelection.destroy();
      }
      window.beforunload = null;
    };
  }, [seatSelection]);

  return { seatSelection };
};
