import React from "react";
import { Card } from "../../../common/Card";
import {
  Days,
  DaySelection,
  PerformanceTypes,
  ViewMode,
} from "../../../../constants/PricingConstants";
import {
  LocationBrand,
  MovieFormat,
} from "../../../../constants/MovieConstants";
import { modeAtom, serviceAtom } from "../state";
import { useRecoilState, useRecoilValue } from "recoil";
import { RowFormField } from "../../../layout/FormLayout";
import _ from "lodash";

export const ServicePanelCard = () => {
  const [service, setService] = useRecoilState(serviceAtom);
  const mode = useRecoilValue(modeAtom);
  const isCustomDayOfWeek =
    service && service.daySelection === DaySelection.Custom;

  if (!service) {
    return null;
  }

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectedValue = e.target.value;
    if (_.isEqual("boolean", valueType)) {
      selectedValue = e.target.checked;
    }

    setService((prev) => ({
      ...prev,
      [fieldName]: selectedValue,
    }));
  };

  return (
    <div>
      <Card title="Service Information" wrapperCss="info row">
        <div className="col">
          <RowFormField label="Service Name:">
            <input
              className="form-control display-input"
              name="name"
              type="text"
              value={service.name}
              readOnly={mode === ViewMode.Readonly}
              onChange={onInputChange}
            />
          </RowFormField>
          <RowFormField label="Day of week">
            <input
              className="form-control display-input"
              name="name"
              type="text"
              value={DaySelection.getDisplay(service.daySelection)}
              readOnly={true}
            />
          </RowFormField>
          {isCustomDayOfWeek && (
            <>
              <RowFormField label="Start Day:">
                <input
                  className="form-control display-input"
                  name="name"
                  type="text"
                  value={Days.getDisplay(service.customDayTime?.startDay)}
                  readOnly={true}
                />
              </RowFormField>
              <RowFormField label="Start Time:">
                <input
                  className="form-control display-input"
                  name="name"
                  type="text"
                  value={service.customDayTime?.startTime}
                  readOnly={true}
                />
              </RowFormField>
              <RowFormField label="End Day:">
                <input
                  className="form-control display-input"
                  name="name"
                  type="text"
                  value={Days.getDisplay(service.customDayTime?.endDay)}
                  readOnly={true}
                />
              </RowFormField>
              <RowFormField label="End Time:">
                <input
                  className="form-control display-input"
                  name="name"
                  type="text"
                  value={service.customDayTime?.endTime}
                  readOnly={true}
                />
              </RowFormField>
            </>
          )}
        </div>
        <div className="col">
          <RowFormField label="Location Brand:">
            <input
              className="form-control display-input"
              name="name"
              type="text"
              value={LocationBrand.getName(service.ticketBrand)}
              readOnly={true}
            />
          </RowFormField>
          <RowFormField label="Movie Format:">
            <input
              className="form-control display-input"
              name="name"
              type="text"
              value={MovieFormat.getName(service.ticketFormat)}
              readOnly={true}
            />
          </RowFormField>
          <RowFormField label="Showtime Type:">
            <input
              className="form-control display-input"
              name="name"
              type="text"
              value={PerformanceTypes.getDisplay(service.performanceType)}
              readOnly={true}
            />
          </RowFormField>
          <RowFormField label="Performance Time Start On:">
            <input
              className="form-control display-input"
              name="performanceTimeStartOn"
              type="time"
              readOnly={true}
              value={service.performanceTimeStartOn}
              onChange={onInputChange}
            />
          </RowFormField>
          <RowFormField label="Performance Time End On:">
            <input
              className="form-control display-input"
              name="performanceTimeEndOn"
              type="time"
              readOnly={true}
              value={service.performanceTimeEndOn}
              onChange={onInputChange}
            />
          </RowFormField>
          <RowFormField label="Is Active:">
            <input
              className="form-check-input"
              name="isActive"
              valuetype="boolean"
              type="checkbox"
              disabled={mode === ViewMode.Readonly}
              checked={service.isActive}
              onChange={onInputChange}
            />
          </RowFormField>
        </div>
        <div className="col">
          <div className="row form-group">
            <label className="col-12 form-control-label">Service Code:</label>
            <div
              className="col m-l-15 px-3 text-center mr-3"
              style={{
                backgroundColor: "grey",
                paddingTop: "35px",
                paddingBottom: "35px",
                minWidth: "240px",
              }}
            >
              <h3 className="text-white text-nowrp">{service.code}</h3>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
