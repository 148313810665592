import React from "react";
import { BaseListing } from "../../common/BaseListing";
import { DateHelper } from "../../../common/Helpers";
import { Link } from "react-router-dom";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { CustomSelect } from "../../common/CustomSelect";
import { isEmpty } from "../../../common/Common";
import { MovieFormat } from "../../../constants/MovieConstants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { CampaignCategory } from "../../../constants/CampaignConstants";

export class MoviePromotionValidityListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      search: "",
      movieReleaseSelectors: [],
      movieReleaseId: 0,
      campaignCategory: 0,
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-promotion-validity",
        name: "Movie Promotion Validity",
      }),
    });
  };

  getApiPath = () => "/api/v1/movie_promotions_validity";

  getDefaultSort = () => "-CreatedOn";

  injectSearchTerm(queryParameters) {
    const { movieReleaseId, campaignCategory } =
      this.state;
    const request = {
      includes: "MovieRelease",
    };
    request.movieReleaseId = movieReleaseId;
    request.campaignCategory = campaignCategory;
    return super.injectSearchTerm(queryParameters, request);
  }

  onSelectChange = async (e, name) => {
    const value = isEmpty(e.value) ? 0 : e.value;
    this.setState({ [name]: value }, () => {
      const {
        movieReleaseId,
        campaignCategory,
      } = this.state;
      const params = {
        movieReleaseId,
        campaignCategory,
      };
      const normalized = Object.fromEntries(
        Object.entries(params).filter(([, v]) => {
          if (!Number.isNaN(+v)) {
            return +v > 0;
          }
          return !isEmpty(v);
        })
      );
      const additionalParams = new URLSearchParams(normalized).toString();
      this.props.history.push(`movie-promotion-validity?${additionalParams}`);
      this.resetPageBounds();
      this.loadData(0);
    });
  };

  componentDidMount() {
    this.loadMovieReleaseSelectors();
  }

  getQueryParamValue = (queryParam) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(queryParam);
  };
  parseQueryParamValue = (queryParam, selectors) => {
    const value = this.getQueryParamValue(queryParam);
    const found = selectors.filter((x) => x.Id === value);
    if (!found) {
      return "";
    }
    return value;
  };

  parseId = (value) => {
    if (!value) {
      return 0;
    }
    return parseInt(value);
  };

  loadMovieReleaseSelectors = () => {
    RestClient.sendGetRequest(
      "/api/v1/movie_releases/options",
      (response) => {
        const movieReleaseId = this.parseId(
          this.parseQueryParamValue("movieReleaseId", response.data)
        );
        this.setState(
          {
            movieReleaseSelectors: response.data,
            movieReleaseId,
          },
          () => this.loadData(0)
        );
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>ID</th>
            <th>Movie Release</th>
            <th>Start On</th>
            <th>End On</th>
            <th>Campaign Category</th>
            <th>
              Created On
              <i
                className="fas fa-sort-alpha-down"
                id="CreatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.id}</td>
                  <td>
                    {item.movieRelease.primaryTitle}-{item.movieRelease.id}-$
                    {MovieFormat.getName(item.movieRelease.format)}
                  </td>
                  <td>{DateHelper.formatDateTimeString(item.startOn)}</td>
                  <td>{DateHelper.formatDateTimeString(item.endOn)}</td>
                  <td>
                    {CampaignCategory.getName(
                      item.campaignCategory
                    )}
                  </td>
                  <td>{DateHelper.formatDateTimeString(item.createdOn)}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item>
          <Link
            className="btn btn-primary d-block"
            to="/movie-promotion-validity/create"
            style={{ fontSize: "1rem" }}
          >
            <i className="fas fa-plus-circle" /> Create New Movie Promotion Validity
          </Link>
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  }

  generateTableFilter() {
    const {
      movieReleaseSelectors,
      movieReleaseId,
      campaignCategory,
    } = this.state;
    return (
      <>
        <div className="row mb-3">
          <div className="col-md-6 form-group">
            <FieldWrapper
              label="Movie Release"
              input={
                <CustomSelect
                  data={movieReleaseSelectors}
                  label="name"
                  value="id"
                  onChange={(e) => this.onSelectChange(e, "movieReleaseId")}
                  selectedValue={movieReleaseId}
                  allowEmpty={true}
                />
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 form-group">
            <FieldWrapper
              label="Campaign Category"
              input={
                <CustomSelect
                  data={CampaignCategory.All}
                  label="name"
                  value="value"
                  onChange={(e) =>
                    this.onSelectChange(e, "campaignCategory")
                  }
                  selectedValue={campaignCategory}
                />
              }
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col row" />
          <div className="col-md-6 input-group ml-auto">
            <input
              type="text"
              id="input1-group2"
              name="name"
              placeholder="Enter key word"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.search}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
