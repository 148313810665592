import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { StaffDetailCard } from "./cards/StaffDetailCard";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { DefaultDate } from "../../../common/Constants";

export class PassCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      generalInfo: null,
      generalInfoValidations: [],
      redirect: false,
      showCancelDialog: false,
      showConfirmDialog: false,
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "passes",
        name: "Passes",
      }),
      activeModule: new NavigationItem({
        identifier: "create",
        name: "Create Pass",
      }),
    });
  };

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  onGeneralInfoModelChange = (model) => {
    const startDate = StringHelper.toDateTimeString(model.startDateStr);
    const expiryDate = StringHelper.toDateTimeString(model.expiryDateStr || DefaultDate.getExpiryDateDefault(model.startDateStr));
    const newModel = Object.assign(model, { startDate, expiryDate });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo } = this.state;
    const request = Object.assign({}, generalInfo);
    RestClient.sendPostRequest(
      "/api/v1/passes/",
      request,
      () => {
        toast.success("Pass have been created successfully.");
        this.setState({
          showConfirmDialog: false,
        });
        this.redirectToListingPage();
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/passes/${additionalParameter ?? ""}`);
  }

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <StaffDetailCard
                  cardMode={cardMode}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-success"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </ButtonActionDetailGroup.Item>
              <ButtonActionDetailGroup.Item
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message="Are you sure you want to cancel the creation of pass?"
          title="Cancel pass creation"
        />
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message="Are you sure you want to create pass?"
          title="Create pass"
        />
      </div>
    );
  }
}
