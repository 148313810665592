import React from "react";

const Loader = () => {
  return (
    <div>
      <div
        style={{
          zIndex: 2048,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: "rgba(255, 255, 255, 0.5)",
          filter: "blur(5px)",
          transition: "filter 0.5s ease-out",
        }}
      />
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2048,
        }}
      >
        <img
          src="./template/vendor/lightbox2/src/images/loading.gif"
          alt="Loading..."
        />
        Loading...
      </div>
    </div>
  );
};

export default Loader;