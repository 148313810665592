import React, { useEffect, useState } from "react";
import RestClient from "../../../common/RestClient";
import { ApplicationID } from "../../../common/Constants";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { useRecoilState } from "recoil";
import { Campaign } from "../../../models/Campaign";
import { Card } from "../../common/Card";

import {
  CampaignActivePanel,
  campaignActivePanelAtom,
  campaignAtom,
  campaignBundlesAtom,
} from "../CampaignState";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { toast } from "react-toastify";
import _ from "lodash";
import { CampaignBundle } from "../../../models/CampaignBundle";
import { NewCampaignBundleProductModal } from "./products/NewCampaignBundleProductModal";
import { CampaignBundleProductItem } from "./products/CampaignBundleProductItem";
import { NewCampaignBundleTicketModal } from "./tickets/NewCampaignBundleTicketModal";
import { CampaignBundleTicketItem } from "./tickets/CampaignBundleTicketItem";
import { NewCampaignBundleCouponModal } from "./coupons/NewCampaignBundleCouponModal";
import { CampaignBundleCouponItem } from "./coupons/CampaignBundleCouponItem";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import CampaignBundleItemGeneralForm from "./generals/CampaignBundleItemGeneralForm";
import CampaignBundleItemGeneral from "./generals/CampaignBundleItemGeneral";
import { DateHelper } from "../../../common/Helpers";
import useVisibleModal from "../../../hooks/useVisibleModal";

export const BundleSalesChannels = [
  ApplicationID.PWSM,
  ApplicationID.TPOS,
  ApplicationID.TKOS,
  ApplicationID.GPAY,
];

const modalAction = {
  active: "active",
  deactive: "deactive",
  duplicate: "duplicate",
  newProduct: "newProduct",
  newTicket: "newTicket",
  newCoupon: "newCoupon",
};

export const CampaignBundleItem = (props) => {
  const [instance, setInstance] = useState(new CampaignBundle({}));
  const { visibleState, changeVisibleState } = useVisibleModal({
    action: modalAction,
  });
  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const [activePanel, setActivePanel] = useRecoilState(campaignActivePanelAtom);
  const [campaign, setCampaign] = useRecoilState(campaignAtom);

  const { campaignBundle, idx, locations, services, onDuplicateSuccess } =
    props;

  useEffect(() => {
    setInstance(campaignBundle);
  }, [campaignBundle]);

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onSave = () => {
    RestClient.sendPutRequest(
      `/api/v1/campaign_bundles/${instance.id}`,
      { ...instance, currentUser: AuthenticationManager.username() },
      (response) => {
        const newBundle = new CampaignBundle(response);
        const changeableCampaignBundles = _.cloneDeep(campaignBundles);
        const index = changeableCampaignBundles.findIndex(
          (x) => x.id === instance.id
        );
        if (index < 0) {
          return;
        }
        changeableCampaignBundles[index] = newBundle;
        setInstance(newBundle);
        setCampaignBundles(changeableCampaignBundles);
        getCampaignBundleInfor();
        setActivePanel("");
        toast.success("The campaign bundle has been updated successful.");
      },
      handleError
    );
  };

  const getCampaignBundleInfor = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/campaigns/${campaign.id}`,
      {
        includes: [
          "CampaignSalesChannelsValidity",
          "CampaignRedemptionLocationValidity",
          "CampaignQuantityAvailability",
          "CampaignBundles",
          "CampaignBundles.CampaignBundleProducts",
          "CampaignBundles.CampaignBundleTickets",
          "CampaignBundles.CampaignBundleCoupons",
          "CampaignBundles.CampaignBundleSalesChannelsValidity",
          "CampaignBundles.CampaignBundleRedemptionLocationValidity",
        ].join(","),
      },
      (response) => {
        const instance = new Campaign(response);
        setCampaign(instance);
        setCampaignBundles(instance.campaignBundles);
      },
      handleError
    );
  };

  const onActivate = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundles/${instance.id}/activate`,
      {},
      () => {
        const cloned = _.cloneDeep(campaignBundles);
        cloned.forEach((x) => {
          if (x.id === instance.id) {
            x.isActive = true;
          }
        });
        setCampaignBundles(cloned);
        toast.success("The campaign bundle has been activated successful.");
      },
      handleError
    );
    changeVisibleState(modalAction.active, false);
  };

  const onDeactivate = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundles/${instance.id}/deactivate`,
      {},
      () => {
        const cloned = _.cloneDeep(campaignBundles);
        cloned.forEach((x) => {
          if (x.id === instance.id) {
            x.isActive = false;
          }
        });
        setCampaignBundles(cloned);
        toast.success("The campaign bundle has been deactivated successful.");
      },
      handleError
    );
    changeVisibleState(modalAction.deactive, false);
  };

  const onDuplicate = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundles/${instance.id}/duplicate`,
      {},
      (response) => {
        setCampaignBundles((prev) => [...prev, new CampaignBundle(response)]);
        toast.success("The campaign bundle has been duplicate successful.");
        onDuplicateSuccess();
      },
      handleError
    );
    changeVisibleState(modalAction.duplicate, false);
  };

  const activeID = CampaignActivePanel.Bundle + idx;

  const bundleTimeHeader = `${DateHelper.toDisplayDateTimeFormat(
    instance.startOn
  )} - ${DateHelper.toDisplayDateTimeFormat(instance.endOn)}`;

  return (
    <Card
      idx={idx}
      title={
        <div className="d-flex justify-content-between flex-column flex-md-row w-100">
          <span>Bundle Details ({instance.printName})</span>
          <span>{bundleTimeHeader}</span>
        </div>
      }
      isActive={activePanel === activeID}
      collapsible={true}
    >
      <div className="card-body">
        <div className="card card-body">
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => changeVisibleState(modalAction.duplicate, true)}
            >
              <i className="fas fa-plus" aria-hidden="true" /> Duplicate
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => changeVisibleState(modalAction.newProduct, true)}
            >
              <i className="fas fa-plus" aria-hidden="true" /> Product
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => changeVisibleState(modalAction.newTicket, true)}
            >
              <i className="fas fa-plus" aria-hidden="true" /> Ticket
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => changeVisibleState(modalAction.newCoupon, true)}
            >
              <i className="fas fa-plus" aria-hidden="true" /> Coupon
            </ButtonActionDetailGroup.Item>
            {activePanel !== activeID && (
              <>
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  onClick={() => setActivePanel(activeID)}
                  disabled={!_.isEmpty(activePanel)}
                  title={
                    !_.isEmpty(activePanel) && activePanel !== activeID
                      ? `The panel ${activePanel} is being edit. Please complete the current editing panel first.`
                      : ""
                  }
                >
                  <i className="fas fa-pen" aria-hidden="true" /> Edit
                </ButtonActionDetailGroup.Item>
                {!instance.isActive && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    onClick={() => changeVisibleState(modalAction.active, true)}
                  >
                    Activate
                  </ButtonActionDetailGroup.Item>
                )}
                {instance.isActive && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    onClick={() =>
                      changeVisibleState(modalAction.deactive, true)
                    }
                  >
                    Deactivate
                  </ButtonActionDetailGroup.Item>
                )}
              </>
            )}
          </ButtonActionDetailGroup>
          {activePanel !== activeID ? (
            <CampaignBundleItemGeneral
              instance={instance}
              locations={locations}
            />
          ) : (
            <CampaignBundleItemGeneralForm
              instance={instance}
              locations={locations}
              onSave={onSave}
              onChange={setInstance}
            />
          )}
        </div>
        {_.orderBy(instance.campaignBundleProducts, ["id"], ["desc"]).map(
          (bundleProduct, index) => (
            <CampaignBundleProductItem key={index} instance={bundleProduct} />
          )
        )}
        {_.orderBy(instance.campaignBundleTickets, ["id"], ["desc"]).map(
          (bundleTicket, index) => (
            <CampaignBundleTicketItem
              parentidx={idx}
              idx={index}
              key={index}
              instance={bundleTicket}
            />
          )
        )}
        {_.orderBy(instance.campaignBundleCoupons, ["id"], ["desc"]).map(
          (bundleCoupon, index) => (
            <CampaignBundleCouponItem
              parentidx={idx}
              idx={index}
              key={index}
              instance={bundleCoupon}
            />
          )
        )}
      </div>
      <NewCampaignBundleProductModal
        visible={visibleState.newProduct}
        campaignBundleId={instance.id}
        onCancel={() => changeVisibleState(modalAction.newProduct, false)}
      />
      <NewCampaignBundleTicketModal
        visible={visibleState.newTicket}
        campaignBundleId={instance.id}
        services={services}
        onCancel={() => changeVisibleState(modalAction.newTicket, false)}
      />
      <NewCampaignBundleCouponModal
        visible={visibleState.newCoupon}
        campaignBundleId={instance.id}
        onCancel={() => changeVisibleState(modalAction.newCoupon, false)}
      />
      <ConfirmDialog
        visible={visibleState.active}
        message="Are you sure you want to activate the campaign bundle?"
        onProceed={onActivate}
        onCancel={() => changeVisibleState(modalAction.active, false)}
      />
      <ConfirmDialog
        visible={visibleState.deactive}
        message="Are you sure you want to deactivate the campaign bundle?"
        onProceed={onDeactivate}
        onCancel={() => changeVisibleState(modalAction.deactive, false)}
      />
      <ConfirmDialog
        visible={visibleState.duplicate}
        message="Are you sure you want to duplicate the campaign bundle?"
        onProceed={onDuplicate}
        onCancel={() => changeVisibleState(modalAction.duplicate, false)}
      />
    </Card>
  );
};
