import { useEffect, useState } from "react";
import RestClient from "../common/RestClient";
import { toast } from "react-toastify";

export function useLocationVenues() {
  const [locationVenues, setLocationVenues] = useState([]);
  useEffect(() => {
    RestClient.sendGetRequest(
      "/api/v1/locations/venues",
      (response) => {
        setLocationVenues(response.data)
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }, []);

  return {
    locationVenues,
  };
}
