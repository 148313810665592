import { useCallback, useMemo } from "react";
import RestClient from "../../common/RestClient";
import { toast } from "react-toastify";
import { CampaignStatus } from "../../constants/CampaignConstants";
import { useRecoilState } from "recoil";
import { stackCampaignsAtom } from "./CampaignState";

export const useStackCampaigns = (exceptCampaignId) => {
  const [stackCampaigns, setStackCampaigns] = useRecoilState(stackCampaignsAtom);

  const loadStackCampaign = useCallback(() => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/campaigns`,
      { isStackCampaign: true, status: CampaignStatus.Active },
      (response) => {
        const allStackCampaigns = response.data.map((x) => {
          return {
            id: x.id,
            name: x.code,
          };
        });
        setStackCampaigns(allStackCampaigns);
      },
      (error) => toast.error(error.message)
    );
  }, [setStackCampaigns]);

  const stackCampaignsFiltered = useMemo(() => {
    if (!Number.isNaN(+exceptCampaignId)) {
      return stackCampaigns.filter((x) => x.id !== +exceptCampaignId);
    }
    return stackCampaigns;
  }, [stackCampaigns, exceptCampaignId]);

  return {
    stackCampaigns: stackCampaignsFiltered,
    loadStackCampaign,
  };
};
