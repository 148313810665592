import React from 'react';
import _ from 'lodash'

export class WarningDialog extends React.Component {

    render() {
        let { message, title, visible, onCancel } = this.props;
        if(!_.isNil(visible) && !visible) {
            return null;
        }
        message = message || 'Warning message';
        title = title || 'Warning'
        return (
            <>
                <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" 
                     aria-hidden="true" style={{ display: 'block' }}>
                    <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">{title}</h5>
                                <button type="button" className="close" aria-label="Close" onClick={onCancel}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {message}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" onClick={onCancel}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show"/>
            </>
        )
    }
}