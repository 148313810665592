import React, { useEffect, useMemo, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { ScheduledKinoLoungeMovieService } from "../../../services/ScheduledKinoLoungeMovieService";
import _ from "lodash";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {
  DateHelper,
  ImageHelper,
  StringHelper,
} from "../../../common/Helpers";
import { useHistory } from "react-router-dom";
import { Classify } from "../../../constants/MovieConstants";
import { RowFormField } from "../../layout/FormLayout";
import { toast } from "react-toastify";

const detailPageName = "View Scheduled KinoLounge Movie";
const editPageName = "Edit Scheduled KinoLounge Movie";

export const ScheduledKinoLoungeMovieDetail = ({ match: { params } }) => {
  const { id } = params;
  const [mode, setMode] = useState(CardMode.ScheduleView);
  const [scheduledKinoLoungeMovie, setScheduledKinoLoungeMovie] = useState(null);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const scheduledKinoLoungeMovieService = useMemo(() => new ScheduledKinoLoungeMovieService(), []);
  const history = useHistory();

  useEffect(() => {
    if (isNaN(id)) {
      redirectToListingPage();
      return;
    }
    getScheduledKinoLoungeMovie()
  }, [scheduledKinoLoungeMovieService, id]);


  const redirectToListingPage = (additionalParameter) => {
    history.push(`/scheduled_kinolounge_movies/${additionalParameter ?? ""}`);
  }

  const getScheduledKinoLoungeMovie = () => {
    scheduledKinoLoungeMovieService.get(id).then((response) => {
      setScheduledKinoLoungeMovie(response.data);
    });
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("type");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setScheduledKinoLoungeMovie((instance) => {
      return { ...instance, [fieldName]: selectValue };
    });
  };

  const update = () => {
    setShowUpdateConfirmation(false);
    setMode(CardMode.ScheduleView);
    const params = {
      ...scheduledKinoLoungeMovie
    };
    scheduledKinoLoungeMovieService.put(id, params).then((response) => {
      if (response) {
        toast.success('Update scheduled kino lounge movie information success.');
      }
    })
    getScheduledKinoLoungeMovie();
  };

  const cancel = () => {
    setShowUpdateConfirmation(false);
    getScheduledKinoLoungeMovie(id);
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "scheduled_kinolounge_movies",
        name: "Scheduled Kinolounge Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "scheduled-kinolounge-movies",
        name: mode === CardMode.ScheduleView ? detailPageName : editPageName,
      }),
    });
  };

  const onIsCollectionChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const value = e.target.checked;
    setScheduledKinoLoungeMovie((instance) => {
      return { ...instance, [fieldName]: value };
    });
  };

  const onProceedDelete = () => {
    scheduledKinoLoungeMovieService.delete(id).then((response) => {
      if (response) {
        toast.success("The scheduled kino lounge movie has been deleted successful.");
        redirectToListingPage("schedules");
      }
    })
    setShowDeleteModal(false);
  }

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onCancel = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {mode == CardMode.ScheduleView &&
              <div className="d-flex flex-row justify-content-end pb-3">
                <button
                  className="btn btn-success mr-1 ml-2 mb-2"
                  onClick={() => setMode(CardMode.ScheduleUpdate)}
                >
                  <i className="fas fa-edit" aria-hidden="true" />
                  Edit
                </button>
                <button
                  className="btn btn-danger ml-2 mb-2"
                  value="Delete Schedule"
                  disabled={scheduledKinoLoungeMovie ? undefined : "disabled"}
                  onClick={onDelete}
                > Delete Schedule
                </button>
              </div>}
            <div className="row">
              <div className="col">
                {scheduledKinoLoungeMovie && <div className="row">
                  <div className="col-md-6">
                    <RowFormField label="Is Collection:" htmlFor="checkbox1">
                      <input
                        type="checkbox"
                        name="isCollection"
                        id="isCollection"
                        className="form-check-input checkbox1"
                        fieldname="isCollection"
                        onChange={onIsCollectionChange}
                        checked={scheduledKinoLoungeMovie.isCollection}
                        disabled={mode == CardMode.ScheduleView}
                      />
                    </RowFormField>
                    {!scheduledKinoLoungeMovie.isCollection && (
                      <RowFormField label="Movie ID:">
                        <input
                          className="form-control display-input"
                          type="text"
                          name="movieId"
                          placeholder="Enter Movie ID"
                          onChange={onInputChange}
                          readOnly={mode == CardMode.ScheduleView}
                          value={scheduledKinoLoungeMovie.movieId}
                        />
                      </RowFormField>
                    )}
                    <RowFormField label="Movie Title:">
                      <input
                        className="form-control display-input"
                        type="text"
                        name="movieTitle"
                        placeholder="Enter Movie Title"
                        onChange={onInputChange}
                        readOnly={mode == CardMode.ScheduleView}
                        value={scheduledKinoLoungeMovie.movieTitle}
                      />
                    </RowFormField>

                    <RowFormField label="Display Start On:">
                      <input
                        className="form-control display-input"
                        type="datetime-local"
                        name="displayStartOn"
                        onChange={onInputChange}
                        readOnly={mode == CardMode.ScheduleView}
                        placeholder={DateHelper.DisplayDateTimePlaceholder}
                        value={StringHelper.toDateTimeString(scheduledKinoLoungeMovie.displayStartOn)}
                      />
                    </RowFormField>
                    <RowFormField label="Display End On:">
                      <input
                        className="form-control display-input"
                        type="datetime-local"
                        name="displayEndOn"
                        readOnly={mode == CardMode.ScheduleView}
                        onChange={onInputChange}
                        placeholder={DateHelper.DisplayDateTimePlaceholder}

                        value={StringHelper.toDateTimeString(scheduledKinoLoungeMovie.displayEndOn)}
                      />
                    </RowFormField>
                    {!scheduledKinoLoungeMovie.isCollection && (
                      <>
                        <RowFormField label="Movie Runtime:">
                          <input
                            className="form-control display-input"
                            type="text"
                            name="duration"
                            placeholder="Enter Movie Runtime"
                            onChange={onInputChange}
                            readOnly={mode == CardMode.ScheduleView}
                            value={scheduledKinoLoungeMovie.duration}
                          />
                        </RowFormField>
                        <RowFormField label="Movie Rating:" htmlFor="Rating">
                          <select
                            className="form-control display-input"
                            id="Rating"
                            type="number"
                            name="classify"
                            onChange={onInputChange}
                            disabled={mode == CardMode.ScheduleView}
                            value={scheduledKinoLoungeMovie.classify}
                          >
                            {Classify.All.map((x) => (
                              <option key={x.code} value={x.value}>
                                {x.name}
                              </option>
                            ))}
                          </select>
                        </RowFormField>
                      </>
                    )}
                    <RowFormField label="Display Weight:">
                      <input
                        className="form-control display-input"
                        type="text"
                        name="displayWeight"
                        placeholder="Enter Display Weight"
                        onChange={onInputChange}
                        readOnly={mode == CardMode.ScheduleView}
                        value={scheduledKinoLoungeMovie.displayWeight}
                      />
                    </RowFormField>
                    {scheduledKinoLoungeMovie.isCollection && (
                      <RowFormField label="Collection Description:">
                        <textarea
                          className="form-control display-input resize-none"
                          rows="6"
                          name="collectionDescription"
                          placeholder="Enter Description"
                          maxlength="250"
                          readOnly={mode == CardMode.ScheduleView}
                          onChange={onInputChange}
                          value={scheduledKinoLoungeMovie.collectionDescription}
                        />
                      </RowFormField>
                    )}
                  </div>
                  <div className="col-md-6">
                    {mode == CardMode.ScheduleView &&
                      <div className="row form-group">
                        <div className="col-4">
                          <img
                            className="posterImg"
                            src={ImageHelper.getPosterUrl(scheduledKinoLoungeMovie.posterUrl)}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </div>
                      </div>
                    }
                    <div className="row form-group">
                      <label className="col-4 form-control-label">Poster Url:</label>
                      <div className="col-8 pl-0">
                        <textarea
                          className="form-control display-input resize-none"
                          rows="5"
                          placeholder="Enter Poster Url"
                          readOnly={mode == CardMode.ScheduleView}
                          name="posterUrl"
                          type="text"
                          value={scheduledKinoLoungeMovie.posterUrl ?? ""}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <label className="col-4 form-control-label">
                        {scheduledKinoLoungeMovie.isCollection ? "Collection Url" : "Movie Url"}:
                      </label>
                      <div className="col-8 pl-0">
                        <textarea
                          className="form-control display-input resize-none"
                          rows="5"
                          placeholder="Enter Description"
                          readOnly={mode == CardMode.ScheduleView}
                          name="movieUrl"
                          type="text"
                          value={scheduledKinoLoungeMovie.movieUrl ?? ""}
                          onChange={onInputChange}
                        />
                      </div>
                    </div>
                    <RowFormField label="Plan To Published On:">
                      <input
                        className="form-control display-input"
                        type="datetime-local"
                        name="planToPublish"
                        readOnly={mode == CardMode.ScheduleView}
                        onChange={onInputChange}
                        placeholder={DateHelper.DisplayDateTimePlaceholder}
                        value={StringHelper.toDateTimeString(scheduledKinoLoungeMovie.planToPublish)}
                      />
                    </RowFormField>
                  </div>
                </div>}
                {mode === CardMode.ScheduleUpdate && (
                  <div id="EditButtons" className="d-flex justify-content-end pb-3">
                    <div className="d-inline-flex ml-auto">
                      <button
                        className="btn btn-success mr-2"
                        onClick={() => setShowUpdateConfirmation(true)}
                      >
                        Save Changes
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => setShowCancelConfirmation(true)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelConfirmation}
          onCancel={() => setShowCancelConfirmation(false)}
          onProceed={() => {
            setMode(CardMode.ScheduleView);
            setShowCancelConfirmation(false);
          }}
          title="Cancel confirmation"
        />
        <ConfirmDialog
          visible={showUpdateConfirmation}
          onCancel={() => cancel()}
          onProceed={() => update()}
          title="Update confirmation"
          message="Are you sure want to update scheduled kino lounge movie information?"
        />
        <ConfirmDialog
          visible={showDeleteModal}
          message={"Are you sure you want to delete this scheduled kino lounge movie?"}
          onProceed={onProceedDelete}
          onCancel={onCancel}
        />
      </div>
    </>
  );

}