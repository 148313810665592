export class CreditCardCondition {
  constructor() {
    this.creditCardType = 0;
    this.issuingBankCode = "";
    this.redemptionQuantityPerCard = "";
    this.redemptionQuantityType = "";
    this.resetDay = 0;
    this.campaignConditionCreditCardBinRanges = [];
  }
}
