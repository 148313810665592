import React from "react";
import ProgramInformationCard from "./cards/ProgramInformationCard";
import ProgramMechanicsCard from "./cards/ProgramMechanicsCard";
import { StringHelper } from "../../../common/Helpers";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class EvoucherProgramDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherProgramId: "",
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherProgramId = StringHelper.toNumber(id, 0);
    if (evoucherProgramId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ evoucherProgramId }, this.refreshData);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/evouchers/programs/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evoucher-program-details",
        name: "eVoucher Program Details",
      }),
    });
  };

  render() {
    const { evoucherProgramId } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {evoucherProgramId && (
                  <ProgramInformationCard
                    evoucherProgramId={evoucherProgramId}
                  />
                )}
              </div>
              <div className="col-md-12">
                {evoucherProgramId && (
                  <ProgramMechanicsCard evoucherProgramId={evoucherProgramId} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
