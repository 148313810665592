import React, { useState } from "react";
import { Pricing } from "../../../../models/Pricing";
import _ from "lodash";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import RestClient from "../../../../common/RestClient";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { activeServiceTicketIdAtom, serviceTicketsSelector } from "../state";
import { RowFormField } from "../../../layout/FormLayout";

export const AddPricingModal = (props) => {
  const [instance, setInstance] = useState(new Pricing());
  const serviceTicketId = useRecoilValue(activeServiceTicketIdAtom);
  const [serviceTickets, setServiceTickets] = useRecoilState(
    serviceTicketsSelector
  );
  const { onCancel, visible } = props;

  const onAdd = () => {
    let data = {
      effectiveStart: instance.effectiveStart,
      serviceTicketId,
      salesAmount: instance.salesAmount,
    };
    return RestClient.sendPostRequest(
      "/api/v1/service_ticket_prices",
      data,
      (serviceTicketPrice) => {
        toast.success("The service ticket price has been created successful");
        const editableServiceTickets = _.cloneDeep(serviceTickets);
        const serviceTicket = editableServiceTickets.find(
          (x) => x.id === serviceTicketId
        );

        if (serviceTicket) {
          if (!serviceTicket.serviceTicketPrices) {
            serviceTicket.serviceTicketPrices = [];
          }
          serviceTicket.serviceTicketPrices.push(serviceTicketPrice);
          setServiceTickets(editableServiceTickets);
        }

        onCancel();
      },
      (error) => toast.error(error.message)
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const editable = _.cloneDeep(instance);
    let value = e.target.value;
    if (value && "number" === valueType && !isNaN(+value)) {
      value = +value;
    }
    editable[fieldName] = value;
    editable.onChange();
    setInstance(editable);
  };

  if (_.isNil(visible) || !visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        id="AddPricingModal"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Add Pricing
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <RowFormField label="Effective Start:">
                  <input
                    className="form-control"
                    type="datetime-local"
                    value={instance.effectiveStart}
                    name="effectiveStart"
                    onChange={onInputChange}
                  />
                </RowFormField>
                <RowFormField label="Sales Amount:">
                  <input
                    className="form-control"
                    type="number"
                    min="1"
                    placeholder="Enter Sales Amount"
                    valuetype="number"
                    value={instance.salesAmount}
                    name="salesAmount"
                    onChange={onInputChange}
                  />
                </RowFormField>
                <RowFormField label="Price Print Code:">
                  {instance.pricePrintCode}
                </RowFormField>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onAdd}
                  data-dismiss="modal"
                >
                  Add
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
