import React from "react";
import { Card } from "../../../common/Card";
import { Days } from "../../../../constants/PricingConstants";
import {
  LocationBrand,
  MovieBrand,
  MovieFormat,
} from "../../../../constants/MovieConstants";
import { YesNoOptions } from "../../../../common/Constants";
import { CompanyType } from "../../../../constants/CompanyType";
import { ColumnFormField } from "../../../layout/FormLayout";
import { SpecialDayType } from "../../../../constants/VoucherContants";

const renderBooleanLabel = (value) => {
  return value
    ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
    : YesNoOptions.getYesNoOptions(YesNoOptions.No);
};

export const PassProgramMechanismCard = (props) => {
  const { defaultValue } = props;
  return (
    <Card title="Pass Program Mechanics">
      <div className="card card-body">
        <div className="row form-group">
          <div className="col-md-6">
            <label htmlFor="code" className="form-control-label">
              Max Quantity Per Year
            </label>
            <input
              type="text"
              id="code"
              className="form-control display-input"
              readOnly={true}
              value={defaultValue?.maxQuantityPerYear || ""}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="type" className="form-control-label">
              Max Quantity Per Showtimes:
            </label>
            <input
              type="text"
              id="type"
              value="-"
              className="form-control display-input"
              readOnly={true}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6">
            <label htmlFor="code" className="form-control-label">
              Max Quantity Per Month
            </label>
            <input
              type="text"
              id="code"
              className="form-control display-input"
              readOnly={true}
              value={defaultValue?.maxQuantityPerMonth || ""}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="code" className="form-control-label">
              Max Quantity Per Transaction
            </label>
            <input
              type="text"
              id="code"
              className="form-control display-input"
              readOnly={true}
              value={defaultValue?.maxQuantityPerTransaction || ""}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6">
            <label htmlFor="code" className="form-control-label">
              Max Quantity Per Movie
            </label>
            <input
              type="text"
              id="code"
              className="form-control display-input"
              readOnly={true}
              value={defaultValue?.maxQuantityPerMovie || ""}
            />
          </div>
        </div>
      </div>
      <div className="card card-body">
        <div className="row form-group">
          <ColumnFormField
            label="Is Valid for all Day and Time?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20 }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllDayTime}
            />
            {defaultValue?.passDayTimeValidities.length > 0 && (
              <ul className="col-6" style={{ paddingLeft: "30px" }}>
                {defaultValue?.passDayTimeValidities.map(
                  (passDayTime, index) => (
                    <li key={index} value={passDayTime.day}>
                      {Days.getDisplay(passDayTime.day)} (
                      {passDayTime.startTime} - {passDayTime.endTime})
                    </li>
                  )
                )}
              </ul>
            )}
          </ColumnFormField>
          <ColumnFormField label="Is Valid for all Companies?" htmlFor="code">
            <input
              style={{ width: 20, height: 20, marginLeft: ".5rem" }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllCompanies}
            />
            <ul className="pl-4">
              {defaultValue?.passCompanyValidities.map((item, index) => (
                <li key={index} value={item.companyId}>
                  {CompanyType.getName(item.companyId)}
                </li>
              ))}
            </ul>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField
            label="Is Valid for all Movie Brands and Formats?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20 }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllMovieBrandsAndFormats}
            />
            <ul className="pl-4">
              {defaultValue?.passProgramMovieBrandAndFormatValidity?.map(
                (item, index) => (
                  <li key={index} value={item.movieFormat}>
                    {MovieFormat.getName(item.movieFormat)} -{" "}
                    {MovieBrand.getName(item.movieBrand)}
                  </li>
                )
              )}
            </ul>
          </ColumnFormField>
          <ColumnFormField
            label="Is Valid for all Location Venues?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20, marginLeft: ".5rem" }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllLocationVenues}
            />
            <ul className="pl-4">
              {defaultValue?.passLocationVenueValidities.map((item, index) => (
                <li key={index} value={item.locationVenueBrand}>
                  {LocationBrand.getName(item.locationVenueBrand)}
                </li>
              ))}
            </ul>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField
            label="Is Valid for Special Day Time?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20, marginLeft: ".5rem" }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.passSpecialDayTimeValidities?.length}
            />
            <ul className="pl-4">
              {defaultValue?.passSpecialDayTimeValidities.map((item, index) => (
                <li key={index}>
                  {SpecialDayType.getName(item.specialDayType)} (
                  {item.startTime} - {item.endTime})
                </li>
              ))}
            </ul>
          </ColumnFormField>
          <ColumnFormField
            label="Is Valid for all Ticket Types?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20 }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllTicketTypes}
            />
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField
            label="Is Valid for all Movie Releases?"
            htmlFor="code"
          >
            <input
              style={{ width: 20, height: 20 }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllMovieReleases}
            />
          </ColumnFormField>
          <ColumnFormField
            htmlFor="code"
            label="Is Valid for all Restrictions?"
          >
            <input
              style={{ width: 20, height: 20 }}
              type="radio"
              id="questions"
              value="-"
              readOnly={true}
              checked={!!defaultValue?.isValidForAllRestrictions}
            />
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField label="Is valid for public holiday?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForPublicHoliday)}
            </div>
          </ColumnFormField>
          <ColumnFormField label="Is valid for public holiday eve?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForPublicHolidayEve)}
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField label="Is valid for blackout day?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForBlackoutDay)}
            </div>
          </ColumnFormField>
          <ColumnFormField label="Is valid for opening show?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForOpeningShow)}
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField label="Is valid for midnight show?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForMidnightShow)}
            </div>
          </ColumnFormField>
          <ColumnFormField label="Is valid for advance booking?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForAdvanceBooking)}
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField label="Is valid for special show?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForSpecialShow)}
            </div>
          </ColumnFormField>
          <ColumnFormField label="Is valid for film festival?">
            <div>
              {renderBooleanLabel(!!defaultValue?.isValidForFilmFestival)}
            </div>
          </ColumnFormField>
        </div>
        <div className="row form-group">
          <ColumnFormField label="Is valid for marathon?">
            <div>{renderBooleanLabel(!!defaultValue?.isValidForMarathon)}</div>
          </ColumnFormField>
        </div>
      </div>
    </Card>
  );
};
