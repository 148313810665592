import React from "react";
import { PassRedemptionMode } from "../../../../common/Constants";
import { DateHelper, StringHelper } from "../../../../common/Helpers";
import { Card } from "../../../common/Card";
import { formatPassRedemptionMode } from "../../common/Constants";
import { RowFormField } from "../../../layout/FormLayout";

export class PassUsageDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false,
      model: this.mergeDefaultValue(props.defaultValue),
    };
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      locationCode: value.locationCode || "",
      locationVenueCode: value.locationVenueCode || "",
      movieTitle: value.movieTitle || "",
      numberOfTickets: value.numberOfTickets || 0,
      passCode: value.passCode || "",
      passName: value.passName || "",
      performanceDateTime: value.performanceDateTime || null,
      redemptionCounter: value.redemptionCounter || "",
      redemptionLocation: value.redemptionLocation || "",
      redemptionMode: value.redemptionMode || PassRedemptionMode.NotSpecified,
      transactionReference: value.transactionReference || "",
      usedOn: value.usedOn || null,
    };
  }

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (newState) {
      this.setState(newState);
    }
    return newState === null;
  }

  render() {
    const { model } = this.state;
    const {
      locationCode,
      locationVenueCode,
      movieTitle,
      numberOfTickets,
      passCode,
      passName,
      performanceDateTime,
      redemptionCounter,
      redemptionLocation,
      redemptionMode,
      transactionReference,
      usedOn,
    } = model;
    return (
      <Card title="Pass Program Details">
        <div className="details row">
          <div className="col-md-6">
            <RowFormField label="Pass Code:" htmlFor="Code">
              <input
                className="form-control display-input"
                id="Code"
                type="text"
                readOnly={true}
                value={passCode}
              />
            </RowFormField>
            <RowFormField label="Name:" htmlFor="Name">
              <input
                className="form-control display-input"
                id="Name"
                type="text"
                readOnly={true}
                value={passName}
              />
            </RowFormField>
            <RowFormField label="Movie Title:" htmlFor="Title">
              <input
                className="form-control display-input"
                id="Title"
                type="text"
                readOnly={true}
                value={movieTitle}
              />
            </RowFormField>
            <RowFormField label="Movie DateTime:" htmlFor="Datetime">
              <input
                className="form-control display-input"
                id="Datetime"
                placeholder={DateHelper.DisplayDateTimePlaceholder}
                readOnly={true}
                value={StringHelper.asDateStringToDisplayDateTimeFormat(
                  performanceDateTime
                )}
              />
            </RowFormField>
            <RowFormField label="Movie Location:" htmlFor="Location">
              <input
                className="form-control display-input"
                id="Location"
                type="text"
                readOnly={true}
                value={redemptionLocation}
              />
            </RowFormField>
            <RowFormField label="Movie Location Hall:" htmlFor="Hall">
              <input
                className="form-control display-input"
                id="Hall"
                type="text"
                readOnly={true}
                value={locationVenueCode}
              />
            </RowFormField>
          </div>
          <div className="col-md-6">
            <RowFormField label="Used On:" htmlFor="UsedOn">
              <input
                className="form-control display-input"
                id="UsedOn"
                placeholder={DateHelper.DisplayDateTimePlaceholder}
                readOnly={true}
                value={StringHelper.asDateStringToDisplayDateTimeFormat(usedOn)}
              />
            </RowFormField>
            <RowFormField label="Redemption Mode:" htmlFor="RedemptionMode">
              <input
                className="form-control display-input"
                type="text"
                id="RedemptionMode"
                readOnly={true}
                value={formatPassRedemptionMode(redemptionMode)}
              />
            </RowFormField>
            {redemptionMode === PassRedemptionMode.Counter && (
              <React.Fragment>
                <RowFormField
                  label="Redemption Location:"
                  htmlFor="RedemptionLocation"
                >
                  <input
                    className="form-control display-input"
                    type="text"
                    id="RedemptionLocation"
                    readOnly={true}
                    value={locationCode}
                  />
                </RowFormField>
                <RowFormField
                  label="Redemption Counter:"
                  htmlFor="RedemptionCounter"
                >
                  <input
                    className="form-control display-input"
                    type="text"
                    id="RedemptionCounter"
                    readOnly={true}
                    value={redemptionCounter}
                  />
                </RowFormField>
              </React.Fragment>
            )}
            <RowFormField label="Transaction Reference:" htmlFor="Reference">
              <input
                className="form-control display-input"
                id="Reference"
                type="text"
                readOnly={true}
                value={transactionReference}
              />
            </RowFormField>
            <RowFormField label="No Of Ticket in Transaction:" htmlFor="Ticket">
              <input
                className="form-control display-input"
                id="Ticket"
                type="text"
                readOnly={true}
                value={numberOfTickets}
              />
            </RowFormField>
          </div>
        </div>
      </Card>
    );
  }
}
