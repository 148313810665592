import React from "react";
import Validators from "../../../common/Validators";

export class ExtendPassDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expiryDate: "",
      validate: {
        isValid: false,
        message: "",
      },
      isSubmit: false,
    };
  }

  onProceedClick = () => {
    if (!this.isValidate()) {
      this.setState({ isSubmit: true });
      return;
    }
    const { expiryDate } = this.state;
    const e = Object.assign(
      {},
      { expiryDate: expiryDate === "" ? null : expiryDate }
    );
    if (this.props.onProceed) {
      this.props.onProceed(e);
    }
  };

  onExpiryDateChange = (e) => {
    this.setState({ expiryDate: e.target.value });
  };

  onValidationStatusChange = (e) => {
    const { validate } = this.state;
    validate.isValid = e.isValid;
    validate.message = e.message;
    this.setState({ validate });
  };

  isValidate() {
    return this.state.validate.isValid;
  }

  render() {
    let { message, title } = this.props;
    message = message || "Are you sure to perform this action?";
    title = title || "Confirmation";
    const { expiryDate } = this.state;
    return (
      <React.Fragment>
        <div
          className="modal fade show"
          id="resetPassword"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={this.props.onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <div className="row form-group p-l-15">
                    <label className="form-control-label mr-2">
                      Expiry Date<span className="color-red">*</span>:
                    </label>
                    <div>
                      <input
                        className="col form-control"
                        type="date"
                        fieldName="expiryDate"
                        onChange={this.onExpiryDateChange}
                        value={expiryDate}
                      />
                      <Validators.RequiredValidator
                        onValidationStatusChange={this.onValidationStatusChange}
                        fieldName="expiryDate"
                        isEnabled={this.state.isSubmit}
                        property="Expiry Date"
                        value={expiryDate}
                      ></Validators.RequiredValidator>
                    </div>
                  </div>
                  {message && <div>{message}</div>}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.onProceedClick}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.props.onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </React.Fragment>
    );
  }
}
