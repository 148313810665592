import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { DateHelper } from "../../../common/Helpers";
import { CorporateBookingStatus, TicketStatus } from "../../../constants/CorporateBookingConstants";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class CorporateBookingListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  componentDidMount() {
    this.loadData(0);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
    });
  };

  getApiPath = () => "api/v1/corporate_bookings";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters) {
    const { search } = this.state;
    return super.injectSearchTerm(queryParameters, { search: search });
  }

  onInputChange = (e) => {
    this.setState({ search: e.target.value });
  };

  onRowClick = (id) => {
    this.props.history.push(`/corporate_bookings/${id}`);
  };
  onCreateClick = () => {
    this.props.history.push("/corporate_bookings/create");
  };

  onSearchClick = () => {
    this.search();
  };

  generateTableFilter = () => {
    return (
      <>
        <div className="row mb-3 justify-content-end gap-6">
          <div className="col-md-6 d-flex">
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search"
              className="form-control"
              onKeyDown={this.onKeyDownSearchInput}
              onChange={this.onInputChange}
            />
            <div className="input-group-btn">
              <button className="btn btn-primary" onClick={this.onSearchClick}>
                <i className="fa fa-search" aria-hidden="true" /> Search
              </button>
            </div>
          </div>
          {AuthenticationManager.isAuthorized(
            ResourceID.CorporateBookingCreateButtonPage
          ) && (
              <div className="width-fit col-12 col-md-auto">
                <button
                  className="btn btn-primary w-100"
                  onClick={this.onCreateClick}
                >
                  <i className="fas fa-plus-circle" aria-hidden="true" /> Create
                  Corporate Bookings
                </button>
              </div>
            )}
        </div>
      </>
    );
  };

  generateTableContent = () => {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th>Booking Reference</th>
            <th>Location Hall</th>
            <th>
              Movie Date And Time
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
            <th>Film Title</th>
            <th>Booking Status</th>
            <th>Tickets Printed</th>
            <th>
              Updated On
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => {
              return (
                <tr key={index} onClick={(e) => this.onRowClick(item.id)}>
                  <td>{item.bookingReference}</td>
                  <td>{item.locationHall}</td>
                  <td>
                    {DateHelper.toDisplayDateTimeFormat(item.eventDateAndTime)}
                  </td>
                  <td>{item.moviePrimaryTitle}</td>
                  <td className={CorporateBookingStatus.getColor(item.status)}>
                    {CorporateBookingStatus.getNameStatus(item.status)}
                  </td>
                  <td>{TicketStatus.getName(item.ticketStatus)}</td>
                  <td>{DateHelper.toDisplayDateTimeFormat(item.updatedOn)}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  };
}
