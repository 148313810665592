import React from "react";

const TransactionInformationMovie = (props) => {
  const { tickets, campaigns, onClick } = props;
  
  const ticket = tickets[0];
  const seatCodes = tickets.map((x) => x.seatList);

  return (
    <div className="card">
      <div className="card-body card-block">
        <div className="row m-0">
          <div className="col-md-4 mr-auto">
            <label className="form-control-label row" id="Title">
              {ticket.movieName}
            </label>
            <label className="form-control-label row" id="DateTime">
              {ticket.showDate} {ticket.showTime}
            </label>
            <label className="form-control-label row" id="Hall">
              {ticket.hallName}
            </label>
          </div>
          <div className="col-md-4 px-0">
            <div className="d-flex flex-row justify-content-start justify-content-md-end">
              <label className="form-control-label mr-2" id="Seats">
                Seat {(seatCodes ?? []).join(",")}
                <a
                  className="ml-2"
                  onClick={() => onClick()}
                  style={{ color: "#0062cc" }}
                >
                  Tickets
                </a>
              </label>
            </div>
            {Object.entries(campaigns).map(([key, _]) => (
              <div className="d-flex flex-row justify-content-md-end" key={key}>
                <label className="form-control-label" id="Promo">
                  {key}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionInformationMovie;
