import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthenticationManager } from '../../common/AuthenticationManager';
import { UserAction, ApplicationID } from '../../common/Constants';
import { UserService } from "../../services/UserService";
import { Link } from 'react-router-dom';
import _ from "lodash";
import { toast } from "react-toastify";
import RestClient from '../../common/RestClient';

export class DesktopHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loggedOut: false,
      showUserMenu: false
    };
    this.userService = new UserService();
  }

  componentDidMount() {
    this.loadUser();
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    RestClient.sendPostRequest('/api/v1/user_audit_logs', { userAction: UserAction.Logout, appId: ApplicationID.INCP}, () => {})
    AuthenticationManager.clearAuthInfo();
    this.setState({ loggedOut: true });
  }

  loadUser = () => {
    this.userService.getAll(
      { Query: AuthenticationManager.username() },
      (response) => {
        const users = response.data;
        if (!_.isEmpty(users) && users.length > 0) {
          const user = users[0];
          this.setState({ user });
        }
      },
      this.handleError
    );
  };

  handleError = (error) => {
    toast.error(error.message);
  };

  handleClick = (e) => {
    e.preventDefault();
  };

  onToggleUserMenu = () => {
    this.setState({ showUserMenu: !this.state.showUserMenu });
  }

  render() {
    const { loggedOut, showUserMenu, user } = this.state;
    if (loggedOut) {
      return (
        <Redirect to="/" />
      )
    }
    const profile = AuthenticationManager.profile();
    return (
      <header className="header-desktop">
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="header-wrap">
              <div className="header-button">
                <div className="noti-wrap">
                  <div className="noti__item">
                  <Link to={`/documentation/`}><i className="zmdi zmdi-pin-help iconHelp"></i></Link>
                    </div>
                </div>
                <div className="account-wrap">
                  <div className={`account-item clearfix js-item-menu ${showUserMenu ? 'show-dropdown' : ''}`} onClick={this.onToggleUserMenu}>
                    <div className="image">
                      <img src="img/emptyProfile.jpg" alt="Prof" />
                    </div>
                    <div className="content">
                      <a className="js-acc-btn" href="#" onClick={this.handleClick}>{profile.firstName}</a>
                    </div>
                    <div className="account-dropdown js-dropdown">
                      <div className="info clearfix">
                        <div className="image">
                          <a href="#" onClick={this.handleClick}>
                            <img src="img/emptyProfile.jpg" alt={`${profile.firstName} ${profile.lastName}`} />
                          </a>
                        </div>
                        <div className="content">
                          <h5 className="name">
                            <a href="#">{profile.firstName} {profile.middleName} {profile.lastName}</a>
                          </h5>
                          <span className="email">{profile.email}</span>
                        </div>
                      </div>
                      <div className="account-dropdown__body">
                        <div className="account-dropdown__item">
                          <Link to={`/users/profile/${user?.id}`}>
                            <i className="zmdi zmdi-account"></i>Account
                          </Link>
                        </div>
                      </div>
                      <div className="account-dropdown__footer">
                        <a href="#" onClick={this.onLogoutClick}>
                          <i className="zmdi zmdi-power"></i>Logout
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
