export class CorporateClient {
    constructor() {
        this.id = 0;
        this.name = '';
        this.emailAddress = '';
        this.contactNumber = '';
        this.code = '';
        this.description = '';
        this.clientName = '';
        this.usedTicketQuantity = 0;
        this.remainingTicketQuantity = 0;
        this.ticketTypeId = 0;
        this.prepaidTicketQuantity = 0;
        this.companyType = 0;
    }
    
    handleResponse(data) {
        this.id = data.id;
        this.name = data.name;
        this.emailAddress = data.emailAddress;
        this.contactNumber = data.contactNumber;
        this.clientName = data.clientName;
        this.code = data.code ?? '';
        this.description = data.description ?? '';
        this.usedTicketQuantity = data.usedTicketQuantity ?? '';
        this.remainingTicketQuantity = data.remainingTicketQuantity ?? '';;
        this.ticketTypeId = data.ticketTypeId ?? '';
        this.prepaidTicketQuantity = data.prepaidTicketQuantity ?? '';
        this.companyType = data.companyType;
    }
}