import React from "react";
import { DateTimeHelper } from "../../../common/Common";

const PerformanceSelector = (props) => {
	const {selectorName, onSelectChange, name, value, eventSelectors} = props;
	
	return (
		<div className="input-group col-12 col-md-6 col-xl-4 align-items-center mb-2">
			<label className="form-label mb-md-0 col-12 col-md-4 col-xl-3" htmlFor="Status">
				{selectorName}
			</label>
			<select
				className="form-control col-auto mx-3 mx-md-0"
				name={name}
				value={value ?? ""}
				onChange={onSelectChange}
			>
				<option value={name === "date" ? "" : 0}>All {selectorName}</option>
				{(eventSelectors || []).map((x) => (
					<option value={x.code} key={x.code}>
						{selectorName === "Dates"
							? DateTimeHelper.getLongDateTime(new Date(x.code))
							: x.name}
					</option>
				))}
			</select>
		</div>
	);
}

export default PerformanceSelector;