import { BaseListing } from "../../common/BaseListing";
import { SearchDropdown } from "../../common/SearchDropdown";
import React from "react";
import { DateHelper } from "../../../common/Helpers";
import { MovieFormat } from "../../../constants/MovieConstants";
import RestClient from "../../../common/RestClient";
import { PerformanceSelectorType } from "../../../common/Constants";
import { PerformanceStatus } from "../../../constants/MovieConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { getSelectors, isEmpty } from "../../../common/Common";
import { toast } from "react-toastify";
import _ from "lodash";
import PerformanceRestrictionModal from "./PerformanceRestrictionModal";
import PerformanceSelector from "./PerformanceSelector";

export class PerformanceListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      dateSelectors: [],
      movieSelectors: [],
      locationSelectors: [],
      performanceStatusSelectors: [],
      movieId: 0,
      locationId: 0,
      date: "",
      performanceStatus: 0,
      isPrivate: false,
      isPartial: false,
      showPerformanceTicketing: false
    };
  }

  componentDidMount() {
    this.loadSelectors();
  }

  loadMovieSelectors = () => {
    return new Promise((resolve) => {
      RestClient.sendGetRequestWithParameters(
        `api/v1/performances/movie_selectors`,
        {},
        (response) => {
          const movieSelectors = response.map((x) => ({
            name: x.name,
            code: x.id,
          }));
          resolve(movieSelectors);
        },
        (error) => {
          resolve([]);
          toast.error(error.message);
        }
      );
    });
  };

  loadSelectors = () => {
    RestClient.sendGetRequestWithParameters(
      `api/v1/performances/selectors`,
      {},
      async (response) => {
        const dateSelectors = getSelectors(
          response,
          PerformanceSelectorType.Date
        );
        const locationSelectors = getSelectors(
          response,
          PerformanceSelectorType.Location
        );
        let performanceStatusSelectors = [];
        PerformanceStatus.All.forEach((x) =>
          performanceStatusSelectors.push({
            name: x.name,
            code: x.value.toString(),
          })
        );
        const movieSelectors = await this.loadMovieSelectors();
        const date = this.parseQueryParamValue("date", dateSelectors);
        const movieId = this.parseId(
          this.parseQueryParamValue("movieId", movieSelectors)
        );
        const locationId = this.parseId(
          this.parseQueryParamValue("locationId", locationSelectors)
        );
        const performanceStatus = this.parseId(
          this.parseQueryParamValue(
            "performanceStatus",
            performanceStatusSelectors
          )
        );
        const isPrivate = this.getQueryParamValue("isPrivate");
        const isPartial = this.getQueryParamValue("isPartial");

        this.setState(
          {
            dateSelectors,
            movieSelectors,
            locationSelectors,
            date,
            locationId,
            movieId,
            performanceStatus,
            performanceStatusSelectors,
            isPrivate,
            isPartial,
          },
          () => this.loadData(0)
        );
      },
      (error) => toast.error(error.message)
    );
  };

  parseId = (value) => {
    if (!value) {
      return 0;
    }
    return parseInt(value);
  };

  getQueryParamValue = (queryParam) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(queryParam);
  };

  parseQueryParamValue = (queryParam, selectors) => {
    const value = this.getQueryParamValue(queryParam);
    const found = selectors.filter((x) => x.code === value);
    if (!found) {
      return "";
    }
    return value;
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "performances",
        name: "Showtimes",
      }),
    });
  };

  getApiPath = () => "/api/v1/performances";

  getDefaultSort = () => "-ActualDateTime";

  injectSearchTerm(queryParameters) {
    const {
      search,
      movieId,
      locationId,
      date,
      performanceStatus,
      isPartial,
      isPrivate,
    } = this.state;
    const request = {
      includes: "Location",
    };

    if (!isNaN(+search)) {
      request.id = +search;
    } else {
      request.primaryTitle = search;
    }

    request.date = date;
    request.movieId = movieId;
    request.locationId = locationId;
    request.status = performanceStatus;
    request.isPartial = isPartial ? isPartial : null;
    request.isPrivate = isPrivate ? isPrivate : null;
    return super.injectSearchTerm(queryParameters, request);
  }

  generateTableContent() {
    const { data } = this.state;
    return (
      <>
        <thead>
          <tr>
            <th style={{ width: "6%" }}>Id</th>
            <th style={{ width: "15%" }}>Movie Release Title</th>
            <th style={{ width: "15%" }}>Location</th>
            <th onClick={this.onColumnClick}>
              Show Timing
              <i
                className="fas fa-sort-alpha-down"
                id="ActualDateTime"
                onClick={this.toggleSort}
              />
            </th>
            <th style={{ width: "10%" }}>Movie Format</th>
            <th style={{ width: "10%" }}>Status</th>
            <th>Sales Start Date</th>
            <th onClick={this.onColumnClick}>
              Updated On
              <i
                className="fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody id="data">
          {data &&
            data.map((item) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.id}</td>
                  <td>{item.primaryTitle}</td>
                  <td>{item.locationVenue.location.name}</td>
                  <td>
                    {DateHelper.formatDateTimeString(item.actualDateTime)}
                  </td>
                  <td>{MovieFormat.getName(item.movieFormat)}</td>
                  <td>
                    <span
                      style={{ color: PerformanceStatus.getColor(item.status) }}
                    >
                      {PerformanceStatus.getName(item.status)}
                    </span>
                  </td>
                  <td className="datetime">
                    {DateHelper.formatDateTimeString(item.salesStartOn)}
                  </td>
                  <td className="datetime">
                    {DateHelper.formatDateTimeString(item.updatedOn)}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }
  
  generateModals = () => {
    const { showPerformanceTicketing, movieSelectors, locationSelectors } = this.state;
    return <>
      <PerformanceRestrictionModal
        show={showPerformanceTicketing}
        movieSelectors={movieSelectors}
        locationSelectors={locationSelectors}
        onClose={() => this.setState({ showPerformanceTicketing: false })}
      />
    </>
  }

  generateTableFilter() {
    const {
      search,
      movieSelectors,
      locationSelectors,
      performanceStatusSelectors,
      date,
      movieId,
      locationId,
      performanceStatus,
      isPrivate,
      isPartial,
    } = this.state;
    return (
      <>
        <div className="row mb-3 justify-content-center">
          <div className="input-group col-12 col-md-6 col-xl-4 align-items-center mb-2">
            <label className="form-label mb-md-0 col-12 col-md-4 col-xl-3" htmlFor="Status">
				      Start On: 
			      </label>
            <input
              type="date"
              name="date"
              onChange={this.onSelectChange}
              value={date}
            />
          </div>
          <SearchDropdown label="Movies" data={movieSelectors} onSelectChange={this.setValues} />
          <PerformanceSelector
            selectorName="Theatres"
            eventSelectors={locationSelectors}
            onSelectChange={this.onSelectChange}
            name="locationId"
            value={locationId}
          />
          <PerformanceSelector
            selectorName="Status"
            eventSelectors={performanceStatusSelectors}
            onSelectChange={this.onSelectChange}
            name="performanceStatus"
            value={performanceStatus}
          />
          <div className="col-12 col-md-6 col-xl-4 d-flex mb-2">
            <div className="input-group col-6 align-items-center">
              <label className="form-label m-0 mr-3">Is Private</label>
              <input
                className="form-input"
                valuetype="boolean"
                type="checkbox"
                name="isPrivate"
                onClick={this.onSelectChange}
                defaultValue={isPrivate}
              />
            </div>
            <div className="input-group col-6 align-items-center mb-2">
              <label className="form-label m-0 mr-3">Is Partial</label>
              <input
                className="form-input"
                valuetype="boolean"
                type="checkbox"
                name="isPartial"
                onClick={this.onSelectChange}
                value={isPartial}
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-2">
            <div className="input-group px-3 px-md-0">
              <input
                type="text"
                id="input1-group2"
                name="search"
                placeholder="Search"
                className="form-control"
                onKeyDown={this.onKeyDownSearchInput}
                onChange={this.onTextBoxChange}
                defaultValue={search}
              />
              <div className="input-group-btn">
                <button className="btn btn-primary" onClick={this.search}>
                  <i className="fa fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 d-flex justify-content-center justify-content-md-end">
            <button type="button" className="btn btn-success btn-update-performance-restriction" onClick={() => this.setState({ showPerformanceTicketing: true })}>
              <i className="fas fa-edit" aria-hidden="true" /> Update Performance Restrictions
            </button>
          </div>
        </div>
      </>
    );
  }

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setValues = (key, value) => {
    this.setState({ [key]: value }, () => {
      const {
        date,
        movieId,
        locationId,
        performanceStatus,
        isPrivate,
        isPartial,
      } = this.state;
      const params = {
        date,
        movieId,
        locationId,
        performanceStatus,
        isPrivate,
        isPartial,
      };
      const normalized = Object.fromEntries(
        Object.entries(params).filter(([, v]) => {
          if (!Number.isNaN(+v)) {
            return +v > 0;
          }

          return !isEmpty(v);
        })
      );
      const additionalParams = new URLSearchParams(normalized).toString();
      this.props.history.push(`/performances?${additionalParams}`);
      this.resetPageBounds();
      this.loadData(0);
    });
  }
  
  onSelectChange = async (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    this.setValues(fieldName, selectValue);
  };
}
