import React, { useState } from "react";
import { RegistrationType } from "../../../common/Constants";
import { ObjectHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { ActivationDialog } from "../../common/ActivationDialog";
import Validators from "../../common/Validators";
import { BreadCrumb } from "../../common/BreadCrumb";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { VoucherStatus } from "../../../constants/VoucherContants";

export const VoucherActivation = () => {
  const [model, setModel] = useState({
    serialNumber: "",
    serialNumberStart: "",
    serialNumberEnd: "",
    validityStart: "",
  });
  const [registrationType, setRegistrationType] = useState(
    RegistrationType.Individual
  );
  const [validations, setValidations] = useState([]);
  const [showActivationDialog, setShowActivationDialog] = useState(false);
  const [showClearConfirmDialog, setShowClearConfirmDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [isShowActivationButton, setIsShowActivationButton] = useState(false);
  const onRegistrationTypeChange = (e) => {
    setRegistrationType(e.target.value);
    resetForm();
  };

  const onInputChange = (e) => {
    setModel({ ...model, [e.target.name]: e.target.value });
  };

  const isValidated = () => {
    const isValidityStartValid = validations.some(
      (val) =>
        val.fieldName ===
          ObjectHelper.getPropertyName(model, model.validityStart) &&
        val.isValid
    );
    if (registrationType === RegistrationType.Individual) {
      return (
        validations.some(
          (val) =>
            val.fieldName ===
              ObjectHelper.getPropertyName(model, model.serialNumber) &&
            val.isValid
        ) && isValidityStartValid
      );
    }
    return (
      validations.some(
        (val) =>
          val.fieldName ===
            ObjectHelper.getPropertyName(model, model.serialNumberStart) &&
          val.isValid
      ) &&
      validations.some(
        (val) =>
          val.fieldName ===
            ObjectHelper.getPropertyName(model, model.serialNumberEnd) &&
          val.isValid
      ) &&
      isValidityStartValid
    );
  };

  const onValidationStatusChange = (e) => {
    let newValidations = validations;
    let validation = newValidations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      newValidations.push(e);
    }
    setValidations(newValidations);
  };

  const onShowActivationDialog = () => {
    if (!isValidated()) {
      setIsSubmit(true);
      return;
    }
    RestClient.sendPostRequest(
      "api/v1/vouchers/validate",
      { ...model },
      (response) => {
        setMessage(
          `You are activating ${response} voucher. Please click 'Activate' button to proceed.`
        );
        setIsShowActivationButton(false);
        setShowActivationDialog(true);
      },
      (err) => {
        toast.error(err.message);
        setIsShowActivationButton(true);
      }
    );
  };

  const getControlClassName = (fieldName) => {
    const isError =
      isSubmit &&
      validations.some((val) => val.fieldName === fieldName && !val.isValid);
    return `form-control ${isError ? "is-invalid" : ""}`;
  };

  const onProceedActivationDialog = () => {
    RestClient.sendPostRequest(
      "api/v1/vouchers/activate",
      { ...model },
      (response) => {
        const nonActiveSerialNumbers = response
          .filter((item) => item.status !== VoucherStatus.Activated)
          .map((item) => item.serialNumber);
        if (nonActiveSerialNumbers.length) {
          toast.error(
            `Cannot active voucher serial number ${nonActiveSerialNumbers.join(
              ","
            )}. Only voucher with status register can active`
          );
        } else {
          toast.success(
            "The vouchers will be Activated shortly. Please check in a few minutes"
          );
        }
      },
      (err) => {
        handleError(err);
      }
    );
    setShowActivationDialog(false);
  };

  const onHideActivationDialog = () => {
    setShowActivationDialog(false);
  };

  const onShowConfirmClearDialog = () => {
    setShowClearConfirmDialog(true);
  };

  const onProceedClearDialog = () => {
    resetForm();
    onHideConfirmClearDialog();
  };

  const onHideConfirmClearDialog = () => {
    setShowClearConfirmDialog(false);
  };

  const resetForm = () => {
    setModel({
      ...model,
      serialNumber: "",
      serialNumberStart: "",
      serialNumberEnd: "",
      validityStart: "",
    });
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "activation",
        name: "Activation",
      }),
    });
  };

  const handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  return (
    <div className="main-content">
      <BreadCrumb navigationSettings={getNavigationSettings()} />
      <div className="section__content section__content--p30">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <div className="row form-group">
                    <div className="col-md-4">
                      <label className="form-control-label" htmlFor="SNumber">
                        Activation Type:
                      </label>
                    </div>
                    <div className="col-md-6">
                      <select
                        className="form-control"
                        id="ActivateSelect"
                        onChange={onRegistrationTypeChange}
                        value={registrationType}
                      >
                        <option value={RegistrationType.Individual}>
                          Activate Single Voucher
                        </option>
                        <option value={RegistrationType.Multiple}>
                          Activate Voucher By Batch
                        </option>
                      </select>
                    </div>
                  </div>
                  {registrationType === RegistrationType.Individual && (
                    <div className="row form-group Serial Single">
                      <div className="col-md-4">
                        <label className="form-control-label" htmlFor="SNumber">
                          Serial Number:
                        </label>
                      </div>
                      <div className="col-md-6">
                        <input
                          className={getControlClassName("serialNumber")}
                          placeholder="Enter Serial Number"
                          id="SNumber"
                          name="serialNumber"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="To add additional single serial number, it must be comma separated. A maximum of 10 single serial numbers are allowed."
                          value={model.serialNumber}
                          onChange={onInputChange}
                          required
                        />
                        <Validators.RequiredValidator
                          onValidationStatusChange={onValidationStatusChange}
                          fieldName="serialNumber"
                          isEnabled={isSubmit}
                          property="Serial Number"
                          value={model.serialNumber}
                        />
                      </div>
                    </div>
                  )}

                  {registrationType === RegistrationType.Multiple && (
                    <div>
                      <div className="row form-group Serial Multi">
                        <div className="col-md-4">
                          <label
                            className="form-control-label"
                            htmlFor="StartSN"
                          >
                            Starting Serial Number:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            className={getControlClassName("serialNumberStart")}
                            placeholder="Enter Starting Serial Number"
                            id="StartSN"
                            name="serialNumberStart"
                            value={model.serialNumberStart}
                            onChange={onInputChange}
                            required
                          />
                          <Validators.RequiredValidator
                            onValidationStatusChange={onValidationStatusChange}
                            fieldName="serialNumberStart"
                            isEnabled={isSubmit}
                            property="Starting Serial Number"
                            value={model.serialNumberStart}
                          />
                        </div>
                      </div>
                      <div className="row form-group Serial Multi">
                        <div className="col-md-4">
                          <label className="form-control-label" htmlFor="EndSN">
                            Ending Serial Number:
                          </label>
                        </div>
                        <div className="col-md-6">
                          <input
                            className={getControlClassName("serialNumberEnd")}
                            placeholder="Enter Ending Serial Number"
                            id="EndSN"
                            name="serialNumberEnd"
                            value={model.serialNumberEnd}
                            onChange={onInputChange}
                            required
                          />
                          <Validators.RequiredValidator
                            onValidationStatusChange={onValidationStatusChange}
                            fieldName="serialNumberEnd"
                            isEnabled={isSubmit}
                            property="Ending Serial Number"
                            value={model.serialNumberEnd}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <label
                      htmlFor="ValidityStart"
                      className="col-md-4 form-check-label "
                    >
                      Validity Start:
                    </label>
                    <div className="col-md-6">
                      <input
                        className="form-control mr-1 ValidityStart"
                        type="datetime-local"
                        id="validityStart"
                        placeholder="Enter Validity Start Date"
                        value={model.validityStart}
                        name="validityStart"
                        onChange={onInputChange}
                      />
                      <Validators.RequiredValidator
                        onValidationStatusChange={onValidationStatusChange}
                        fieldName="validityStart"
                        isEnabled={isSubmit}
                        property="Validity Start"
                        value={model.validityStart}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-end">
            <button
              className="btn btn-success mr-1"
              type="submit"
              id="btnActivate"
              data-toggle="modal"
              data-target="#Confirm"
              onClick={onShowActivationDialog}
            >
              Activate
            </button>
            <button
              className="btn btn-danger"
              id="btnClear"
              onClick={onShowConfirmClearDialog}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
      <ActivationDialog
        visible={showActivationDialog}
        onCancel={onHideActivationDialog}
        onProceed={onProceedActivationDialog}
        message={message}
        isShowActivationButton={isShowActivationButton}
        title="Activate Voucher"
      />
      <ConfirmDialog
        visible={showClearConfirmDialog}
        message={"Are you sure want to clear data?"}
        onProceed={onProceedClearDialog}
        onCancel={onHideConfirmClearDialog}
      />
    </div>
  );
};
