import React, { useState } from "react";
import _ from "lodash";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { useParams } from "react-router-dom";
import RestClient from "../../../common/RestClient";
import { FieldWrapper } from "../FieldWrapper";
import { TextInput } from "../TextInput";
import { SeatsCondition } from "../../../constants/CampaignConstants";
import { BundleSalesChannels } from "./CampaignBundleItem";
import { ApplicationID } from "../../../common/Constants";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  locationsAtom,
  campaignBundlesAtom,
  campaignAtom,
  campaignSalesChannelsSelector,
  campaignRedemptionLocationsSelector,
} from "../CampaignState";
import { CampaignBundle } from "../../../models/CampaignBundle";
import { NumberInput } from "../NumberInput";
import { DateTimeInput } from "../DateTimeInput";
import { toast } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import { useStackCampaigns } from "../useStackCampaigns";
import { Card } from "../../common/Card";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export const NewCampaignBundle = (props) => {
  const [instance, setInstance] = useState(new CampaignBundle({}));
  const locations = useRecoilValue(locationsAtom);
  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);
  const { onCancel } = props;
  const { id } = useParams();
  const campaign = useRecoilValue(campaignAtom);
  const salesChannels = useRecoilValue(campaignSalesChannelsSelector);
  const redemptionLocations = useRecoilValue(
    campaignRedemptionLocationsSelector
  );
  const { stackCampaigns } = useStackCampaigns(campaign.id);

  const onInputChange = (e) => {
    const changedInstance = _.cloneDeep(instance);
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }
    changedInstance[fieldName] = selectValue;
    setInstance(changedInstance);
  };
  const onMultipleSelectorSelect = (source) => {
    setInstance((bundle) => {
      return {
        ...bundle,
        stackCodes: source.map((x) => x.name),
      };
    });
  };

  const onMultipleSelectorRemove = (source) => {
    setInstance((bundle) => {
      return {
        ...bundle,
        stackCodes: source.map((x) => x.name),
      };
    });
  };

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  const isValidDate = (date) => !isNaN(new Date(date).getTime());

  const onSave = () => {
    if (isNaN(+id)) {
      return;
    }
    let errorMessage = "";
    if (!isValidDate(instance.startOn)) {
      errorMessage = "Start On is required.";
    }
    if (!isValidDate(instance.endOn)) {
      errorMessage = "End On is required.";
    }
    if (errorMessage) {
      handleError({ message: errorMessage });
      return;
    }
    const body = {
      ...instance,
      campaignId: +id,
      currentUser: AuthenticationManager.username(),
    };
    RestClient.sendPostRequest(
      "/api/v1/campaign_bundles",
      body,
      (response) => {
        const newCampaignBundles = _.cloneDeep(campaignBundles);
        newCampaignBundles.push(new CampaignBundle(response));
        setInstance(new CampaignBundle({}));
        setCampaignBundles(newCampaignBundles);
        onCancel();
        toast.success("The campaign bundle has been created successful.");
      },
      handleError
    );
  };

  const onSelectSalesChannel = (e) => {
    if (e.target.checked) {
      instance.appIds.push(+e.target.value);
    } else {
      instance.appIds = instance.appIds.filter((x) => x !== +e.target.value);
    }
    setInstance(_.cloneDeep(instance));
  };

  const onSelectLocation = (e) => {
    if (e.target.checked) {
      instance.locationIds.push(+e.target.value);
    } else {
      instance.locationIds = instance.locationIds.filter(
        (x) => x !== +e.target.value
      );
    }
    setInstance(_.cloneDeep(instance));
  };

  const getSelectedCampaigns = () => {
    return (stackCampaigns ?? []).filter((x) =>
      instance.stackCodes.includes(x.name)
    );
  };

  const onSelectAllLocation = () => {
    setInstance((d) => {
      return {
        ...d,
        locationIds: locations
          .map((x) => x.id)
          .filter((x) => redemptionLocations.includes(x)),
      };
    });
  };

  const onSelectAllSaleChannels = () => {
    setInstance((d) => {
      return {
        ...d,
        appIds: BundleSalesChannels.filter((x) => salesChannels.includes(x)),
      };
    });
  };

  return (
    <div className="card mb-3">
      <Card title="New Bundle">
        <div className="card card-body">
          <div className="row">
            <div className="col-md-8">
              <FieldWrapper
                label="Display Name"
                require={true}
                additionalCss="form-group"
                input={
                  <TextInput
                    name="displayName"
                    value={instance.displayName}
                    onChange={onInputChange}
                    placeholder="Enter Display Name"
                  />
                }
              />
              <FieldWrapper
                label="Print Name"
                require={true}
                additionalCss="form-group"
                input={
                  <TextInput
                    name="printName"
                    value={instance.printName}
                    onChange={onInputChange}
                    placeholder="Enter Print Name"
                  />
                }
              />
              <FieldWrapper
                label="Description"
                additionalCss="form-group"
                input={
                  <TextInput
                    name="description"
                    value={instance.description}
                    onChange={onInputChange}
                  />
                }
              />
              <FieldWrapper
                label="Poster URL"
                additionalCss="form-group"
                input={
                  <TextInput
                    name="posterUrl"
                    value={instance.posterUrl}
                    onChange={onInputChange}
                    placeholder="Enter Poster URL"
                  />
                }
              />
              <FieldWrapper
                label="Display Weight"
                require={true}
                additionalCss="form-group"
                input={
                  <NumberInput
                    name="displayWeight"
                    value={instance.displayWeight}
                    onChange={onInputChange}
                    placeholder="Enter Display Weight"
                  />
                }
              />
              <FieldWrapper
                label="Campaign Stack"
                additionalCss="form-group"
                input={
                  <Multiselect
                    options={stackCampaigns}
                    selectedValues={getSelectedCampaigns()}
                    onSelect={onMultipleSelectorSelect}
                    onRemove={onMultipleSelectorRemove}
                    displayValue="name"
                    avoidHighlightFirstOption={true}
                  />
                }
              />
              <FieldWrapper
                label="Start On"
                require={true}
                additionalCss="form-group"
                input={
                  <DateTimeInput
                    name="startOn"
                    value={instance.startOn}
                    onChange={onInputChange}
                  />
                }
              />
              <FieldWrapper
                label="End On"
                require={true}
                additionalCss="form-group"
                input={
                  <DateTimeInput
                    name="endOn"
                    value={instance.endOn}
                    onChange={onInputChange}
                  />
                }
              />
              <FieldWrapper
                label="Performance Start On"
                require={true}
                additionalCss="form-group"
                input={
                  <DateTimeInput
                    name="performanceStartOn"
                    value={instance.performanceStartOn}
                    onChange={onInputChange}
                  />
                }
              />
              <FieldWrapper
                label="Performance End On"
                require={true}
                additionalCss="form-group"
                input={
                  <DateTimeInput
                    name="performanceEndOn"
                    value={instance.performanceEndOn}
                    onChange={onInputChange}
                  />
                }
              />
              <FieldWrapper
                label="No of Seats Condition:"
                require={true}
                additionalCss="form-group"
                input={SeatsCondition.All.map((x) => {
                  return (
                    <div className="" key={x.value}>
                      <input
                        className="mr-1"
                        type="radio"
                        valuetype="number"
                        name="seatCondition"
                        value={x.value}
                        checked={instance.seatCondition === x.value}
                        onChange={onInputChange}
                      />
                      <label className="form-check-label">{x.name}</label>
                    </div>
                  );
                })}
              />
              {instance.seatCondition !== SeatsCondition.NoCondition && (
                <FieldWrapper
                  additionalCss="form-group"
                  input={
                    <div className="">
                      <NumberInput
                        name="numberOfSeats"
                        value={instance.numberOfSeats}
                        onChange={onInputChange}
                        placeholder="Enter Number of Seats"
                      />
                    </div>
                  }
                />
              )}

              <FieldWrapper
                label={
                  <>
                    <label className="form-control-label">
                      Sales Channels:
                      <span className="color-red font-weight-bold">*</span>
                    </label>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={onSelectAllSaleChannels}
                    >
                      Select all
                    </button>
                  </>
                }
                require={true}
                additionalCss="form-group"
                input={
                  <div className="row">
                    {ApplicationID.All.filter(
                      (x) => BundleSalesChannels.indexOf(x.value) >= 0
                    ).map((x, index) => {
                      return (
                        <div className="col-md-3" key={x.value}>
                          <div className="">
                            <input
                              className="mr-1"
                              type="checkbox"
                              value={x.value}
                              defaultChecked={instance.appIds.includes(x.value)}
                              onClick={onSelectSalesChannel}
                              disabled={!salesChannels.includes(x.value)}
                            />
                            <label className="form-check-label">{x.name}</label>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                }
              />

              <FieldWrapper
                label={
                  <>
                    <label className="form-control-label">
                      Redemption Location:
                      <span className="color-red font-weight-bold">*</span>
                    </label>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={onSelectAllLocation}
                    >
                      Select all
                    </button>
                  </>
                }
                require={true}
                additionalCss="form-group"
                input={
                  <div className="row">
                    {locations.map((x) => (
                      <div className="col-md-12" key={x.id}>
                        <div className="">
                          <input
                            className="mr-1"
                            type="checkbox"
                            value={x.id}
                            checked={instance.locationIds.includes(x.id)}
                            onChange={onSelectLocation}
                            disabled={!redemptionLocations.includes(x.id)}
                          />
                          <label className="form-check-label">{x.name}</label>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <img src="img/testqrcode.png" />
                </div>
              </div>
            </div>
          </div>
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item
              className="btn btn-success"
              onClick={onSave}
            >
              Save
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-danger"
              onClick={onCancel}
            >
              Cancel
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </div>
      </Card>
    </div>
  );
};
