import React from 'react';
import { ImageHelper } from '../../common/Helpers'

export const PreviewImageDialog = (props) => {
    let { url, visible, onClose } = props;
    if(!visible) {
        return null;
    }
    return (
        <>
            <div className="modal fade show" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content mt-5">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Preview Image</h5>
                            <button type="button" className="close" aria-label="Close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div id="PreviewContent" className="container-fluid">
                                <img id="Image" className="img-thumbnail" onError={ImageHelper.onPosterError} src={ImageHelper.getPosterUrl(url)} alt={url} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
}
