import _ from "lodash";

export class PerformanceSelectorsType {
	static Date = 0;
	static Movie = 1;
	static Location = 2;
	
	static All = [
		{name: 'Date',value: this.Date},
		{name: 'Movie', value: this.Movie},
		{name: 'Location', value: this.Location}
	]

	static getName(value) {
		const found = this.All.find((x) => x.value === value);
		if (_.isNil(found)) {
			return "";
		}
		return found.name;
	}
}