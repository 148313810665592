import {UpdatePriceCriteria} from "../constants/PricingConstants";
import moment from "moment";

export class PriceUpdateModel {
    constructor() {
        this.updateType = 0;
        this.salesAmount = 0;
        this.effectiveStart = moment(new Date()).format("yyyy-MM-DDTHH:mm");
    }
    
    isValid() {
        if(this.updateType === UpdatePriceCriteria.Replace && this.salesAmount <= 0){
            return false;
        }
        return this.updateType > 0 && new Date(this.effectiveStart) >= new Date();
    }
}