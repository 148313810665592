import React from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { MovieReleaseService } from "../../../services/MovieReleaseService";
import { Movie } from "../../../models/Movie";
import { MovieRelease } from "../../../models/MovieRelease";
import {
  Classify,
  Country,
  CrewRole,
  MovieCategory,
  MovieFormat,
  MovieOrigin,
  MovieReleaseLabel,
  MovieSubtitle,
  Restriction,
} from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { MovieService } from "../../../services/MovieService";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";
import { AddConsumerAdviceModal } from "../modals/AddConsumerAdviceModal";
import { AddLanguageModal } from "../modals/AddLanguageModal";
import { AddGenreModal } from "../modals/AddGenreModal";
import { AddRestrictionModal } from "../modals/AddRestrictionModal";
import { MovieRestrictionService } from "../../../services/MovieRestrictionService";
import { MovieReleaseSelectionModal } from "../medias/MovieReleaseSelectionModal";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { Card } from "../../common/Card";
import { MovieGenre, Languages } from "../../../constants/MovieConstants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { ColumnFormField } from "../../layout/FormLayout";
import MovieDetailCard from "./MovieDetailCard";

export class MovieReleaseCreation extends React.Component {
  constructor() {
    super();
    this.state = {
      movie: new Movie(),
      instance: new MovieRelease(),
      restrictions: [],
      showAddAdviceModal: false,
      showAddLanguage: false,
      showAddGenre: false,
      showAddRestriction: false,
      showCancelConfirmation: false,
      showClearConfirmation: false,
      showCreateConfirmation: false,
      showDuplicationModal: false,
      mode: Mode.Readonly,
      movieReleases: [],
    };
    this.movieReleaseService = new MovieReleaseService();
  }

  componentDidMount() {
    const { instance } = this.state;
    const id = this.props.match.params.id;

    new MovieService().get(
      id,
      { includes: "Company,MovieReleases" },
      (response) => {
        const movie = new Movie(response);
        const movieReleaseLabel = movie.primaryTitle.substring(
          0,
          MovieReleaseLabel.Length
        );
        instance.labelDisplay = _.isEmpty(instance.labelDisplay)
          ? movieReleaseLabel
          : instance.labelDisplay;
        instance.labelPrint = _.isEmpty(instance.labelPrint)
          ? movieReleaseLabel
          : instance.labelPrint;
        this.setState({ instance, movie });
      },
      (movieError) => {
        toast.success(movieError.message);
      }
    );
  }

  getMovieRelease = (id) => {
    this.movieReleaseService
      .get(id, { includes: "MovieReleaseRestrictions" })
      .then((response) => {
        const instance = new MovieRelease(response.data);
        this.setState({
          mode: Mode.Readonly,
          instance,
          restrictions: instance.movieReleaseRestrictions,
        });
      });
    this.setState({ showDuplicationModal: false });
  };

  onModeChange = () => {
    const id = this.props.match.params.id;
    const { mode } = this.state;
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    if (newMode === Mode.Editable) {
      this.movieReleaseService.list({ movieId: id }).then((response) => {
        this.setState({
          movieReleases: response.data.data,
        });
      });
    }
    this.setState({ mode: newMode });
  };

  createRestrictions = async (movieReleaseId) => {
    const { instance, restrictions } = this.state;
    await Promise.all(
      restrictions.map((restriction) => {
        return new Promise((resolve) => {
          restriction.movieReleaseId = movieReleaseId;
          new MovieRestrictionService().create(
            restriction,
            (_) => {
              toast.success(`The movie has been created successful.`);
              instance.movieReleaseRestrictions.push(restriction);
              return resolve();
            },
            (error) => {
              toast.success(error.message);
              return resolve();
            }
          );
        });
      })
    );
    this.setState({ instance });
  };

  onSubmit = async () => {
    const { instance } = this.state;
    const errorMsg = instance.validate();
    if (errorMsg) {
      toast.error(errorMsg);
    } else {
      instance.movieId = this.props.match.params.id;
      const response = await this.movieReleaseService.post(instance);
      const { data } = response || {};
      if (data?.id) {
        await this.createRestrictions(data.id);
        this.props.history.push(`/movies/${instance.movieId}`);
        toast.success("The movie release has been created successful.");
      }
    }
    this.setState({ showCreateConfirmation: false });
  };

  onClear = () => {
    this.setState({
      instance: new MovieRelease(),
      restrictions: [],
      showClearConfirmation: false,
    });
  };

  onCancelChanges = () => {
    this.props.history.push(`/movies/${this.props.match.params.id}`);
    this.setState({ showCancelConfirmation: false });
  };

  onAddAdvice = (advice) => {
    const { instance } = this.state;
    instance.adviceName = advice.adviceName;
    instance.adviceOnTicket = advice.adviceOnTicket;
    this.setState({ instance });
  };

  onRemoveAdvice = () => {
    const { instance } = this.state;
    instance.adviceName = "";
    instance.adviceOnTicket = "";
    this.setState({ instance });
  };

  renderConsumerAdvice = () => {
    const { instance } = this.state;
    const hasAdvice =
      !_.isEmpty(instance.adviceName) || !_.isEmpty(instance.adviceOnTicket);
    return (
      <div className="card mb-3">
        <div className="card-body Consumer Advice-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Consumer Advice</span>
            {!hasAdvice && (
              <button
                className="ml-auto btn btn-sm btn-success"
                onClick={() => this.setState({ showAddAdviceModal: true })}
                data-toggle="modal"
              >
                Add
              </button>
            )}
          </div>
          {hasAdvice && (
            <div className="card card-body bg-light mb-1 py-1">
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">Consumer Advice:</label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {instance.adviceName}
                  </label>
                </div>
              </div>
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">Advice On Ticket: </label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {instance.adviceOnTicket}
                  </label>
                </div>
                <div className="col-md-2 py-1">
                  <button
                    className="float-right btn btn-sm btn-danger"
                    onClick={this.onRemoveAdvice}
                  >
                    <i className="fas fa-trash-alt" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  onAddRestriction = (restrictionData) => {
    const { restrictions } = this.state;
    const found = restrictions.find(
      (x) =>
        x.startOn === restrictionData.startOn &&
        x.endOn === restrictionData.endOn &&
        x.type === restrictionData.type
    );
    if (!_.isNil(found)) {
      return;
    }
    restrictions.push(restrictionData);
    this.setState({ restrictions });
  };

  onRemoveRestriction = (data) => {
    let { restrictions } = this.state;
    restrictions = restrictions.filter((x) => x !== data);
    this.setState({ restrictions });
  };

  renderMovieRestrictions = () => {
    const { restrictions } = this.state;
    return (
      <div className="card mb-3">
        <div className="card-body Movie Restriction-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Movie Restriction</span>
            <button
              className="ml-auto btn btn-sm btn-success"
              onClick={() => this.setState({ showAddRestriction: true })}
              data-toggle="modal"
            >
              Add
            </button>
          </div>
          {restrictions.map((x, index) => (
            <div key={index} className="card card-body bg-light mb-1 py-1">
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">Restriction:</label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {Restriction.getName(x.type)}
                  </label>
                </div>
              </div>
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">Start On:</label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {DateHelper.toDisplayDateFormat(x.startOn)}
                  </label>
                </div>
              </div>
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">End On:</label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {DateHelper.toDisplayDateFormat(x.endOn)}
                  </label>
                </div>
                <div className="col-md-2 py-1">
                  <button
                    className="float-right btn btn-sm btn-danger"
                    onClick={() => this.onRemoveRestriction(x)}
                  >
                    <i className="fas fa-trash-alt" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  onAddLanguage = (language) => {
    const { instance } = this.state;

    let languageArray = [];
    if (instance.language && instance.language.length > 0) {
      languageArray = (instance.language ?? "").split(",").map((x) => x.trim());
    }
    const found = languageArray.find((x) => x === language);
    if (!_.isNil(found)) {
      return;
    }
    languageArray.push(language);
    instance.language = languageArray.join(", ");
    this.setState({ instance });
  };

  onRemoveLanguage = (language) => {
    const { instance } = this.state;
    instance.language = instance.language
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x !== language)
      .join(", ");
    this.setState({ instance });
  };

  renderMovieLanguage = () => {
    const { instance } = this.state;
    const languages = (instance.language ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <div className="card mb-3">
        <div className="card-body Movie Language-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>
              Movie Language<span className="color-red">*</span>
            </span>
            <button
              className="ml-auto btn btn-sm btn-success"
              onClick={() => this.setState({ showAddLanguage: true })}
              data-toggle="modal"
            >
              Add
            </button>
          </div>
          {languages.map((x, index) => {
            return (
              <div key={index} className="card card-body bg-light mb-1 py-1">
                <div className="row pb-0 mb-0">
                  <div className="col-md-4 pb-0 mb-0">
                    <label className="text pb-0 mb-0">Language:</label>
                  </div>
                  <div className="col-md-6 pb-0 mb-0">
                    <label className="text-black pb-0 mb-0">
                      {Languages.getName(+x)}
                    </label>
                  </div>
                  <div className="col-md-2 py-1">
                    <button
                      className="float-right btn btn-sm btn-danger"
                      onClick={() => this.onRemoveLanguage(x.trim())}
                    >
                      <i className="fas fa-trash-alt" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  onAddGenre = (genre) => {
    const { instance } = this.state;
    const genreArray = (instance.genre ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    const found = genreArray.find((x) => x === genre);
    if (!_.isNil(found)) {
      return;
    }
    genreArray.push(genre);
    instance.genre = genreArray.join(", ");
    this.setState({ instance });
  };

  onRemoveGenre = (genre) => {
    const { instance } = this.state;
    instance.genre = instance.genre
      .split(",")
      .map((x) => x.trim())
      .filter((x) => x !== genre)
      .join(", ");
    this.setState({ instance });
  };

  renderMovieGenre = () => {
    const { instance } = this.state;
    const genres = (instance.genre ?? "")
      .split(",")
      .map((x) => x.trim())
      .filter((x) => !_.isEmpty(x));
    return (
      <div className="card mb-3">
        <div className="card-body Movie Genre-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Movie Genre</span>
            <button
              className="ml-auto btn btn-sm btn-success"
              onClick={() => this.setState({ showAddGenre: true })}
              data-toggle="modal"
            >
              Add
            </button>
          </div>
          {genres.map((x, index) => (
            <div key={index} className="card card-body bg-light mb-1 py-1">
              <div className="row pb-0 mb-0">
                <div className="col-md-4 pb-0 mb-0">
                  <label className="text pb-0 mb-0">Genre:</label>
                </div>
                <div className="col-md-6 pb-0 mb-0">
                  <label className="text-black pb-0 mb-0">
                    {MovieGenre.getName(+x)}
                  </label>
                </div>
                <div className="col-md-2 py-1">
                  <button
                    className="float-right btn btn-sm btn-danger"
                    onClick={() => this.onRemoveGenre(x.trim())}
                  >
                    <i className="fas fa-trash-alt" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderMovieSection = () => {
    const { movie } = this.state;
    return (
      <MovieDetailCard movie={movie}>{this.renderMovieCrew()}</MovieDetailCard>
    );
  };

  renderMovieCrew = () => {
    const { movie } = this.state;
    return (
      <div className="card mb-3">
        <div className="card-body Movie Crew-0">
          <div className="row mx-0 mb-3">
            <span style={{ fontSize: "20px" }}>Movie Crew</span>
          </div>
          {!_.isNil(movie.crews) &&
            movie.crews.map((x, index) => {
              return (
                <div key={index} className="card card-body bg-light mb-1 py-1">
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">{x.name}</label>
                    </div>
                  </div>
                  <div className="row pb-0 mb-0">
                    <div className="col-md-4 pb-0 mb-0">
                      <label className="text pb-0 mb-0">Crew Role:</label>
                    </div>
                    <div className="col-md-6 pb-0 mb-0">
                      <label className="text-black pb-0 mb-0">
                        {CrewRole.getName(x.role)}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = selectValue.toString() === "true";
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  renderMovieReleaseEditableSection = () => {
    const { instance } = this.state;
    const header =
      instance.format <= 0 && instance.format <= 0
        ? "Movie Format | Classification"
        : `${MovieFormat.getName(instance.format)} | ${Classify.getName(
            instance.classify
          )}`;
    return (
      <Card title="Movie Release">
        <Card title={header}>
          <div id="movieReleaseDv">
            <div className="card mb-0">
              <div className="card-body bg-light film01">
                <div className="card card-body">
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Movie Code:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter Movie Code"
                        name="releaseExternalCode"
                        onChange={this.onInputChange}
                        value={instance.releaseExternalCode}
                      />
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          Film Code:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter Film Code"
                        name="eventReleaseCode"
                        onChange={this.onInputChange}
                        value={instance.eventReleaseCode}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Movie Format:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        valuetype="number"
                        name="format"
                        value={instance.format}
                        onChange={this.onInputChange}
                      >
                        <option key={0} value={0}>
                          Select Movie Format
                        </option>
                        {MovieFormat.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          Movie Subtitle:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        valuetype="number"
                        name="subtitle"
                        value={instance.subtitle}
                        onChange={this.onInputChange}
                      >
                        <option key={0} value={0}>
                          Select Movie Subtitle
                        </option>
                        {MovieSubtitle.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Classification:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        valuetype="number"
                        name="classify"
                        value={instance.classify}
                        onChange={this.onInputChange}
                      >
                        <option value="0">Select Classification</option>
                        {Classify.All.map((x) => (
                          <option
                            key={x.value}
                            value={x.value}
                            defaultValue={instance.classify}
                          >
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          Movie Release Country:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <select
                        className="form-control"
                        valuetype="number"
                        name="movieReleaseCountry"
                        onChange={this.onInputChange}
                        value={instance.movieReleaseCountry}
                      >
                        <option value="0">Select Movie Release Country</option>
                        {Country.All.map((x) => (
                          <option key={x.value} value={x.value}>
                            {x.name}
                          </option>
                        ))}
                      </select>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Display Weight:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter Display Weight"
                        name="displayWeight"
                        onChange={this.onInputChange}
                        value={instance.displayWeight}
                      />
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          HomePage Display Weight:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter HomePage Display Weight"
                        name="homePageDisplayWeight"
                        value={instance.homePageDisplayWeight}
                        onChange={this.onInputChange}
                      ></input>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Label Display:
                          <span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter Label Display"
                        name="labelDisplay"
                        onChange={this.onInputChange}
                        value={instance.labelDisplay}
                      />
                    </ColumnFormField>
                    <ColumnFormField
                      label={
                        <span>
                          Label Print:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        placeholder="Enter Label Print"
                        name="labelPrint"
                        onChange={this.onInputChange}
                        value={instance.labelPrint}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField
                      label={
                        <span>
                          Release Date:<span className="color-red">*</span>
                        </span>
                      }
                    >
                      <input
                        className="form-control"
                        type="date"
                        name="releaseDate"
                        onChange={this.onInputChange}
                        value={instance.releaseDate}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Release date confirmed?">
                      <div className="form-check">
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isReleaseDateConfirmed"
                            valuetype="boolean"
                            value={true}
                            checked={instance.isReleaseDateConfirmed}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label mr-2">Yes</label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isReleaseDateConfirmed"
                            valuetype="boolean"
                            value={false}
                            checked={!instance.isReleaseDateConfirmed}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </div>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Display Start On:">
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="displayStartOn"
                        onChange={this.onInputChange}
                        value={instance.displayStartOn}
                      />
                    </ColumnFormField>
                    <ColumnFormField label="Display End On:">
                      <input
                        className="form-control"
                        type="datetime-local"
                        name="displayEndOn"
                        onChange={this.onInputChange}
                        value={instance.displayEndOn}
                      />
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Shaw Exclusive?">
                      <label>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isShawExclusive"
                            valuetype="boolean"
                            value={true}
                            checked={instance.isShawExclusive}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label mr-2">Yes</label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isShawExclusive"
                            valuetype="boolean"
                            value={false}
                            checked={!instance.isShawExclusive}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </label>
                    </ColumnFormField>
                    <ColumnFormField label="Visible to public?">
                      <label>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isPublic"
                            valuetype="boolean"
                            value={true}
                            checked={instance.isPublic}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label mr-2">Yes</label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isPublic"
                            valuetype="boolean"
                            value={false}
                            checked={!instance.isPublic}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </label>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Is Marathon movie?">
                      <label>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isMarathon"
                            valuetype="boolean"
                            value={true}
                            checked={instance.isMarathon}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label mr-2">Yes</label>
                        </div>
                        <div className="form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="isMarathon"
                            valuetype="boolean"
                            value={false}
                            checked={!instance.isMarathon}
                            onChange={this.onInputChange}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </label>
                    </ColumnFormField>
                  </div>
                  <div className="row">
                    <ColumnFormField label="Rating Notice:">
                      <textarea
                        className="form-control"
                        placeholder="Enter Rating Notice"
                        name="ratingNotice"
                        onChange={this.onInputChange}
                        value={instance.ratingNotice}
                      />
                    </ColumnFormField>
                  </div>
                </div>
              </div>
              <div className="card-body bg-light">
                <div className="row">
                  <div className="col-md-6">
                    {this.renderConsumerAdvice()}
                    {this.renderMovieRestrictions()}
                  </div>
                  <div className="col-md-6">
                    {this.renderMovieLanguage()}
                    {this.renderMovieGenre()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Card>
    );
  };

  renderBottomButtons = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          onClick={() => this.setState({ showCreateConfirmation: true })}
          data-target="#confirmModal"
        >
          Create Movie Release
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger mr-1"
          data-toggle="modal"
          onClick={() => this.setState({ showClearConfirmation: true })}
          data-target="#cancelModal"
        >
          Clear
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          onClick={() => this.setState({ showCancelConfirmation: true })}
          data-target="#cancelModal"
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  onDuplicateButtonClick = (e) => {
    this.onModeChange();
    this.setState({ showDuplicationModal: true });
  };

  renderTopButtons = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-primary"
          data-toggle="modal"
          onClick={() => this.onDuplicateButtonClick()}
          data-target="#DuplicateModal"
        >
          Duplicate Movie Release
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "movies",
        name: "Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "movie-release-creation",
        name: "Create Movie Release",
      }),
    });
  };

  render() {
    const {
      showClearConfirmation,
      showCancelConfirmation,
      showAddAdviceModal,
      showAddLanguage,
      showAddGenre,
      showAddRestriction,
      showCreateConfirmation,
      showDuplicationModal,
      movieReleases,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.renderTopButtons()}
              {this.renderMovieSection()}
              {this.renderMovieReleaseEditableSection()}
              {this.renderBottomButtons()}
            </div>
          </div>
        </div>
        <AddConsumerAdviceModal
          visible={showAddAdviceModal}
          onCancel={() => this.setState({ showAddAdviceModal: false })}
          onProceed={(advice) => this.onAddAdvice(advice)}
        />
        <AddLanguageModal
          visible={showAddLanguage}
          onCancel={() => this.setState({ showAddLanguage: false })}
          onProceed={(language) => this.onAddLanguage(language)}
        />
        <AddGenreModal
          visible={showAddGenre}
          onCancel={() => this.setState({ showAddGenre: false })}
          onProceed={(subtitle) => this.onAddGenre(subtitle)}
        />
        <AddRestrictionModal
          visible={showAddRestriction}
          onCancel={() => this.setState({ showAddRestriction: false })}
          onProceed={(data) => this.onAddRestriction(data)}
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          message="Are you sure to cancel?"
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          onProceed={this.onCancelChanges}
        />
        <ConfirmDialog
          visible={showClearConfirmation}
          message="Are you sure to clear?"
          onCancel={() => this.setState({ showClearConfirmation: false })}
          onProceed={this.onClear}
        />
        <ConfirmDialog
          visible={showCreateConfirmation}
          message="Are you sure to create movie release?"
          onCancel={() => this.setState({ showCreateConfirmation: false })}
          onProceed={this.onSubmit}
        />
        {showDuplicationModal && (
          <MovieReleaseSelectionModal
            title="Load Movie Release"
            onProceed={this.getMovieRelease}
            movieReleases={movieReleases}
            onCancel={() => this.setState({ showDuplicationModal: false })}
          />
        )}
      </>
    );
  }
}
