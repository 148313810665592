import React from "react";
import { AuthenticationManager } from "../../../common/AuthenticationManager";
import { GenericMessages } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { CardMode } from "../common/CardMode";
import { KinoLoungeMovieDetailCard } from "./cards/KinoLoungeMovieDetailCard";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";

export class KinoLoungeMovieCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
    };
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  onGeneralInfoModelChange = (model) => {
    const displayStartOn = StringHelper.toDateTimeString(
      model.displayStartOn
    );
    const displayEndOn = StringHelper.toDateTimeString(model.displayEndOn);
    const movieRuntime = StringHelper.toNumber(model.movieRuntime, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      displayStartOn,
      displayEndOn,
      movieRuntime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onProceedCancelDialog = () => {
    this.redirectToListingPage();
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPostRequest(
      "/api/v1/kinolounge_movies/",
      request,
      () => {
        const successMessage =
          GenericMessages.CreationSuccessfulMessage("Kinolounge movie");
        toast.success(successMessage);
        this.setState({
          showConfirmDialog: false,
        });
        this.redirectToListingPage();
      },
      (errorResponse) => {
        toast.error(errorResponse.message);
        this.setState({
          showConfirmDialog: false,
        });
      }
    );
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/kinolounge_movies/${additionalParameter ?? ""}`);
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "kinolounge_movies",
        name: "Kinolounge Movies",
      }),
      activeModule: new NavigationItem({
        identifier: "kinolounge-movie-creation",
        name: "Create Kinolounge Movie",
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <KinoLoungeMovieDetailCard
                  cardMode={cardMode}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelCreationDialogMessage(
            "Kinolounge movie"
          )}
          title={GenericMessages.CancelCreationDialogTitle(
            "Kinolounge movie"
          )}
        />
        <ConfirmDialog
          visible={showConfirmDialog}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.ConfirmCreationDialogMessage(
            "Kinolounge movie"
          )}
          title={GenericMessages.ConfirmCreationDialogTitle(
            "Kinolounge movie"
          )}
        />
      </div>
    );
  }
}
