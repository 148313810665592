import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { toast } from "react-toastify";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { Card } from "../../common/Card";
import Validators from "../../common/Validators";
import { CardMode } from "./CardMode";

const RowFormFieldLayout = (props) => {
  const { button } = props;
  let label = "";

  if (props.label) {
    label = `${props.label}:`;
  }

  if (props.required && props.label) {
    label = (
      <>
        {props.label}
        {props.required && (
          <span className="color-red font-weight-bold">*</span>
        )}
        :
      </>
    );
  }

  return (
    <div className="row mb-2" id={props.id}>
      <label
        htmlFor={props.htmlFor}
        className="form-control-label col-md-3 col-xl-2"
      >
        {label}
      </label>
      <div className="col-md-5 col-xl-4 height-fit">{props.children}</div>
      {button && (
        <div className="d-inline-flex height-fit col-3 py-1 py-md-0">
          <button
            className={button.className}
            onClick={button.onClick}
            disabled={button.disabled}
          >
            {button.label}
          </button>
        </div>
      )}
    </div>
  );
};

const ErrorFormFieldLayout = (props) => {
  const wrapperClass = props.isError ? "row" : "row d-none";
  return (
    <div className={wrapperClass}>
      <label className="form-control-label col-md-3 col-lg-2 m-0" />
      <div className="col-md-5 col-lg-4">{props.children}</div>
    </div>
  );
};

export class StaffAuthenticationModel {
  constructor(data) {
    this.confirmPassword = "";
    this.password = "";
    this.roles = data.roles ?? [];
    this.username = data.username ?? "";
  }
}

export const StaffAuthenticationCard = (props) => {
  const [model, setModel] = useState(new StaffAuthenticationModel({}));
  const [roleOptions, setRoleOptions] = useState([]);
  const [currentDefaultRoleOptions, setCurrentDefaultRoleOptions] = useState(
    []
  );
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validations, setValidations] = useState([]);
  const cardModeRef = useRef();

  const { designationId, departmentId, defaultValue, cardMode, onModelChange } =
    props;

  useEffect(() => {
    cardModeRef.current = cardMode;
  }, [cardMode]);

  useEffect(() => {
    setModel(new StaffAuthenticationModel(defaultValue ?? {}));
  }, [defaultValue]);

  useEffect(() => {
    onModelChange(model);
  }, [model, onModelChange]);

  const isFieldError = (fieldName) => {
    return validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
  };

  const getControlClassName = (fieldName) => {
    const isError = isFieldError(fieldName);
    const isEnabled = cardMode !== CardMode.View && isSubmit;
    return isEnabled && isError ? "form-control is-invalid" : "form-control";
  };

  const handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  useEffect(() => {
    const request = {};
    request.designationId = designationId;
    if (!AuthenticationManager.isAuthorized(ResourceID.AccessAllDepartments)) {
      request.departmentId = AuthenticationManager.departmentId();
    }
    RestClient.sendGetRequestWithParameters(
      "/api/v1/roles/options",
      request,
      (response) => {
        setRoleOptions(response.data);
        setSelectedRoleId("");
        if (CardMode.isEditMode(cardModeRef.current)) {
          setRolesModel(response.defaults);
        }
      },
      handleError
    );
  }, [departmentId, designationId]);

  const setRolesModel = (defaultRoles) => {
    const rolesSelected = model.roles;
    const currentDefaultRoleId = new Set(
      currentDefaultRoleOptions.map((role) => role.id)
    );
    const nextDefaultRoleId = new Set(defaultRoles.map((role) => role.id));
    const customRole = rolesSelected.filter(
      (role) =>
        !currentDefaultRoleId.has(role.id) && !nextDefaultRoleId.has(role.id)
    );
    setCurrentDefaultRoleOptions(defaultRoles);
    setModel((instance) => ({
      ...instance,
      roles: [...defaultRoles, ...customRole],
    }));
  };

  const getRoleName = (roleId) => {
    const roleOption = roleOptions.find((option) => option.id === roleId);
    return roleOption === null ? roleId : roleOption.name;
  };

  const onAddRoleClick = () => {
    if (StringHelper.isNullOrEmpty(selectedRoleId)) {
      return;
    }
    const roleName = getRoleName(selectedRoleId);
    const newRoles = [
      ...model.roles,
      {
        id: selectedRoleId,
        name: roleName,
      },
    ];
    setModel((newModel) => {
      return {
        ...newModel,
        roles: newRoles,
      };
    });
    setSelectedRoleId("");
  };

  const onGeneratePasswordClick = () => {
    const password = StringHelper.randomPassword(12);
    setModel((newModel) => {
      return {
        ...newModel,
        confirmPassword: "",
        password,
      };
    });
    setShowPassword(true);
  };

  const onRoleOptionChange = (e) => {
    setSelectedRoleId(e.target.value);
  };

  const onRemoveRoleClick = (roleId) => {
    const newRoles = model.roles.filter((role) => role.id !== roleId);
    const newModel = Object.assign({}, model, { roles: newRoles });
    setModel(newModel);
  };

  const onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, model, {
      [fieldName]: value,
    });
    setModel(newModel);
  };

  const onToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onValidationsChange = () => {
    const { onValidationsChange } = props;
    if (onValidationsChange) {
      onValidationsChange([...validations]);
    }
  };

  const onValidationStatusChange = (e) => {
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    setValidations(validations);
    onValidationsChange();
  };

  const { isSubmit } = props;
  const roles = (model.roles ?? []).map((role) => role.id);
  const newRoleOptions = roleOptions.filter(
    (option) => roles.indexOf(option.id) === -1
  );
  return (
    <Card title="Staff Authentication">
      <RowFormFieldLayout
        id="AddStaffAuth"
        htmlFor="UserId"
        label="User Login Id"
        required
      >
        <input
          type="text"
          id="UserId"
          className={getControlClassName("username")}
          placeholder="Enter login ID"
          readOnly={cardMode === CardMode.View || cardMode === CardMode.Update}
          fieldname="username"
          onChange={onTextBoxChange}
          minLength={1}
          maxLength={16}
          value={model.username}
          autoComplete="off"
        />
        <Validators.RequiredValidator
          onValidationStatusChange={onValidationStatusChange}
          fieldName="username"
          property="User Login Id"
          value={model.username}
          isEnabled={isSubmit}
        />
      </RowFormFieldLayout>
      {cardMode === CardMode.Register && (
        <React.Fragment>
          <RowFormFieldLayout
            htmlFor="Password"
            label="Password"
            required
            button={{
              className: "btn btn-secondary",
              onClick: onGeneratePasswordClick,
              label: "Generate Password",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              id="Password"
              className={getControlClassName("password")}
              placeholder="Enter Password"
              fieldname="password"
              onChange={onTextBoxChange}
              value={model.password}
              autoComplete="new-password"
            />
            <i
              id="eyeIcon"
              className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
              onClick={onToggleShowPassword}
            />
          </RowFormFieldLayout>
          <ErrorFormFieldLayout isError={isFieldError("password")}>
            <Validators.RequiredValidator
              onValidationStatusChange={onValidationStatusChange}
              fieldName="password"
              isEnabled={isSubmit}
              property="Password"
              value={model.password}
            />
            <Validators.PasswordValidator
              onValidationStatusChange={onValidationStatusChange}
              fieldName="password"
              isEnabled={isSubmit}
              property="Password"
              value={model.password}
            />
          </ErrorFormFieldLayout>
          <RowFormFieldLayout
            label="Confirm Password"
            htmlFor="ConfirmPassword"
            required
          >
            <input
              type={showPassword ? "text" : "password"}
              id="ConfirmPassword"
              className={getControlClassName("confirmPassword")}
              placeholder="Enter Confirm Password"
              fieldname="confirmPassword"
              onChange={onTextBoxChange}
              value={model.confirmPassword}
              autoComplete="off"
            />
            <i
              id="eyeIcon2"
              className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
              onClick={onToggleShowPassword}
            />
          </RowFormFieldLayout>
          <ErrorFormFieldLayout isError={isFieldError("confirmPassword")}>
            <Validators.CompareValidator
              onValidationStatusChange={onValidationStatusChange}
              fieldName="confirmPassword"
              isEnabled={isSubmit}
              property="Confirm Password"
              value={model.confirmPassword}
              valueToCompare={model.password}
            />
          </ErrorFormFieldLayout>
        </React.Fragment>
      )}
      <RowFormFieldLayout
        htmlFor="StaffRole"
        label="Staff Role"
        button={{
          className: "btn btn-primary",
          disabled: CardMode.isViewMode(cardMode) ? "disabled" : undefined,
          onClick: onAddRoleClick,
          label: "Add",
        }}
        required
      >
        <select
          id="StaffRole"
          disabled={CardMode.isViewMode(cardMode) ? "disabled" : undefined}
          className={getControlClassName("selectedRoleId")}
          fieldname="selectedRoleId"
          onChange={onRoleOptionChange}
          value={selectedRoleId}
        >
          <option value="">Select a Role</option>
          {newRoleOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <Validators.RequiredValidator
          onValidationStatusChange={onValidationStatusChange}
          fieldName="selectedRoleId"
          isEnabled={isSubmit}
          property="Staff Role"
          value={model.roles}
        />
        <div id="StaffRoles" className="mt-2">
          {model.roles.map((role) => (
            <div className="row mb-1" key={role.id}>
              <div className="col-10 col-md-12 pl-4">
                <span className="text-wrap">{role.name}</span>
                {CardMode.isEditMode(cardMode) && (
                  <button
                    className="btn btn-sm btn-clear red-hover ml-2"
                    onClick={() => onRemoveRoleClick(role.id)}
                  >
                    <i className="far fa-trash-alt" aria-hidden="true" />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </RowFormFieldLayout>
    </Card>
  );
};
