import React from "react";
import { BreadCrumb } from "../common/BreadCrumb";
import { Mode } from "../../constants/Mode";
import _ from "lodash";
import { BookingRequestService } from "../../services/BookingRequestService";
import { CorporateBookingRequest } from "../../models/CorporateBookingRequest";
import { StringHelper } from "../../common/Helpers";
import {
  BookingStatus,
  CorporateBookingEventTime,
  GroupCode,
} from "../../constants/CorporateBookingConstants";
import { Strings } from "../../common/Common";
import RestClient from "../../common/RestClient";
import {
  AuthenticationManager,
  ResourceID,
} from "../../common/AuthenticationManager";
import { ConfirmDialog } from "../common/ConfirmDialog";
import {
  GenericMessages,
  ObjectType,
  PersonInCharge,
} from "../../common/Constants";
import { BookingRejectModal } from "./BookingRejectModal";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { HistoryDialog } from "../common/HistoryDialog";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";
import { Card } from "../common/Card";

export class BookingRequestDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      instance: new CorporateBookingRequest(),
      users: [],
      mode: Mode.Readonly,
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
      showCompleteConfirmation: false,
      showRejectConfirmation: false,
      showHistoryDialog: false,
    };
    this.bookingRequestService = new BookingRequestService();
  }

  getNavigationSettings = () => {
    const { mode } = this.state;
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "corporate_bookings",
        name:
          mode === Mode.Editable
            ? "Edit Booking Request"
            : "Booking Request Details",
      }),
    });
  };

  loadData = () => {
    const { instance } = this.state;
    const id = this.props.match.params.id;
    this.bookingRequestService.get(
      id,
      (response) => {
        instance.handleResponse(response);
        this.setState({ instance });
        this.loadUsers();
      },
      this.handleError
    );
  };

  loadUsers = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/user_groups`,
      { code: GroupCode.CB_BRA },
      (response) => {
        const users = response.data.map((x) => x.name);
        this.setState({ users });
      },
      this.handleError
    );
  };

  componentDidMount() {
    this.loadData();
  }

  onModeChange = () => {
    const { mode } = this.state;
    const newMode = mode === Mode.Readonly ? Mode.Editable : Mode.Readonly;
    this.setState({ mode: newMode });
  };

  onSubmit = () => {
    const { instance } = this.state;
    this.bookingRequestService.update(
      instance,
      (response) => {
        instance.handleResponse(response);
        this.closeAllModals();
        this.setState({
          instance,
          mode: Mode.Readonly,
        });
        toast.success(GenericMessages.SaveChangesSuccessfulMessage);
      },
      this.handleError
    );
  };

  onCancel = () => {
    this.loadData();
    this.setState({ mode: Mode.Readonly });
    this.closeAllModals();
  };

  onComplete = () => {
    const { instance } = this.state;
    RestClient.sendPostRequest(
      `/api/v1/corporate_booking_requests/${instance.id}/complete`,
      {},
      (response) => {
        instance.handleResponse(response);
        this.setState({ instance });
        toast.success("The booking request has been completed successful.");
      },
      this.handleError
    );
    this.closeAllModals();
  };

  onReject = (reason) => {
    if (_.isEmpty(reason)) {
      return;
    }
    const { instance } = this.state;
    RestClient.sendPostRequest(
      `/api/v1/corporate_booking_requests/${instance.id}/reject`,
      {
        reason,
      },
      (response) => {
        instance.handleResponse(response);
        this.setState({ instance });
        toast.success("The booking request has been rejected successful.");
      },
      this.handleError
    );
    this.closeAllModals();
  };

  onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    const instance = this.state.instance;
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      instance[object][field] = selectValue;
    } else {
      instance[fieldName] = selectValue;
    }
    this.setState({ instance });
  };

  renderTopButtons = () => {
    const { instance } = this.state;
    return (
      <ButtonActionDetailGroup>
        {instance.status === BookingStatus.Pending &&
          AuthenticationManager.isAuthorized(
            ResourceID.BookingRequestEditButtonPage
          ) && (
            <ButtonActionDetailGroup.Item
              className="btn btn-success mr-1"
              onClick={this.onModeChange}
            >
              <i className="fas fa-edit" aria-hidden="true" />
              Edit
            </ButtonActionDetailGroup.Item>
          )}
        <ButtonActionDetailGroup.Item
          className="btn btn-outline-secondary"
          onClick={() => this.setState({ showHistoryDialog: true })}
        >
          History
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  renderBottomButtons = () => {
    const { instance } = this.state;
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success mr-1"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showSaveChangesConfirmation: true })}
          disabled={_.isEmpty(instance.personInCharge)}
        >
          Save changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#confirmModal"
          onClick={() => this.setState({ showCancelConfirmation: true })}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  closeAllModals = () => {
    this.setState({
      showSaveChangesConfirmation: false,
      showCancelConfirmation: false,
      showCompleteConfirmation: false,
      showRejectConfirmation: false,
    });
  };

  getCssClassMapping = (actionName) => {
    const map = {
      complete: "btn btn-success float-right",
      reject: "btn btn-danger",
    };
    const defaultCss = "btn btn-primary";
    if (Strings.isNullOrEmpty(actionName)) {
      return defaultCss;
    }
    let value = map[actionName];
    return value ? value : defaultCss;
  };

  handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  isPersonInCharge = (currentUser) => {
    const { instance } = this.state;
    return (
      instance.personInCharge !== currentUser ||
      instance.personInCharge !== PersonInCharge.Unassigned
    );
  };

  getActionName = (action) => {
    const lastIndexOfSlash = action.lastIndexOf("/");
    const actionName = action.substring(lastIndexOfSlash + 1, action.length);
    return actionName.replaceAll("_", " ");
  };

  generateActions = () => {
    const { instance } = this.state;

    if (
      !instance.actions ||
      !AuthenticationManager.isAuthorized(
        ResourceID.BookingRequestCompleteRejectButton
      )
    ) {
      return [];
    }

    const actions = instance.actions;
    const isPersonInCharge = this.isPersonInCharge(
      AuthenticationManager.username()
    );
    return (
      <ButtonActionDetailGroup>
        {actions
          .map((action) => this.getActionName(action))
          .map((actionName, index) => (
            <ButtonActionDetailGroup.Item
              className={this.getCssClassMapping(actionName)}
              style={{ textTransform: "capitalize" }}
              key={index}
              onClick={() => {
                if (actionName.toLowerCase() === "complete") {
                  this.setState({ showCompleteConfirmation: true });
                } else {
                  this.setState({ showRejectConfirmation: true });
                }
              }}
              disabled={!isPersonInCharge}
              title={isPersonInCharge ? "" : "You are not person in charge"}
            >
              {actionName}
            </ButtonActionDetailGroup.Item>
          ))}
      </ButtonActionDetailGroup>
    );
  };

  getStatusStyle(status) {
    status = status ?? "";
    const map = {
      pending: "color-orange",
      rejected: "color-red",
    };
    if (!status) return "color-gray";
    let normalizedStatus = status.toLowerCase();
    return map[normalizedStatus] ? map[normalizedStatus] : "color-green";
  }

  renderForm = () => {
    const { instance, mode, users } = this.state;
    const status = BookingStatus.getName(instance.status);
    return (
      <div className="row">
        <div className="col">
          <Card title="Booking Request Details">
            <div className="row">
              <div className="col-md-6 mb-3">
                <label
                  className="form-control-label"
                  htmlFor="preferredTheatre"
                >
                  Preferred Theatre:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={instance.preferredTheatre}
                  id="preferredTheatre"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Salutation">
                  Name:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={instance.name}
                  id="Salutation"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="FirstHall">
                  First Choice:
                </label>
                <input
                  className="form-control display-input"
                  id="FirstHall"
                  value={instance.firstTheatre}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="EventDate">
                  Event Date:
                </label>
                <input
                  className="form-control display-input"
                  value={StringHelper.asDateStringToDisplayDateFormat(
                    instance.eventDate
                  )}
                  id="EventDate"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="SecondHall">
                  Second Choice:
                </label>
                <input
                  className="form-control display-input"
                  id="SecondHall"
                  value={instance.secondTheatre}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="ContactNo">
                  Contact Number:
                </label>
                <input
                  className="form-control display-input"
                  id="ContactNo"
                  type="tel"
                  placeholder="Contact Number"
                  value={instance.contactNumber}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="EventStart">
                  Event Start Time:
                </label>
                <input
                  className="form-control display-input"
                  type="text"
                  value={CorporateBookingEventTime.getName(
                    instance.eventStartTime
                  )}
                  id="EventStart"
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="EventDate">
                  Email Address:
                </label>
                <input
                  className="form-control display-input"
                  id="EventDate"
                  value={instance.emailAddress}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Address">
                  Address:
                </label>
                <input
                  className="form-control display-input"
                  id="Address"
                  type="text"
                  placeholder="Address"
                  value={instance.companyAddress}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="CompanyName">
                  Company Name:
                </label>
                <input
                  className="form-control display-input"
                  id="CompanyName"
                  type="text"
                  placeholder="Company Name"
                  value={instance.companyName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Postal">
                  Postal Code:
                </label>
                <input
                  className="form-control display-input"
                  id="Postal"
                  type="tel"
                  value={instance.postalCode}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="FilmTitle">
                  Film Title:
                </label>
                <input
                  className="form-control display-input"
                  id="FilmTitle"
                  value={instance.moviePrimaryTitle}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Status">
                  Status:
                </label>
                <input
                  className={`form-control display-input ${this.getStatusStyle(
                    status
                  )}`}
                  id="Status"
                  type="text"
                  value={status}
                  readOnly={true}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="NoOfGuest">
                  No of Guests:
                </label>
                <input
                  className="form-control display-input"
                  id="NoOfGuest"
                  type="number"
                  placeholder="Number of Guest"
                  value={instance.numberOfGuests}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="InCharge">
                  Person In Charge:
                </label>
                {mode === Mode.Editable && (
                  <select
                    className="form-control"
                    id="InCharge"
                    name="personInCharge"
                    value={instance.personInCharge}
                    onChange={this.onInputChange}
                  >
                    <option value="">Select Person In Charge</option>
                    {users.map((user, index) => {
                      return (
                        <option value={user} key={index}>
                          {user}
                        </option>
                      );
                    })}
                  </select>
                )}
                {mode === Mode.Readonly && (
                  <input
                    className="form-control display-input"
                    id="InCharge"
                    type="text"
                    value={instance.personInCharge}
                    readOnly={true}
                  />
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Comments">
                  Comments:
                </label>
                <input
                  className="form-control display-input"
                  id="Comments"
                  type="text"
                  value={instance.comment}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-control-label" htmlFor="Salutation">
                  Salutation:
                </label>
                <input
                  className="form-control display-input"
                  id="Salutation"
                  type="text"
                  value={instance.salutation ?? ""}
                  readOnly={true}
                />
              </div>
              {instance.status === BookingStatus.Rejected && (
                <div className="col-md-6 mb-3">
                  <label className="form-control-label" htmlFor="Reason">
                    Reason for rejection:
                  </label>
                  <textarea
                    className="form-control display-input"
                    id="Reason"
                    value={instance.reasonForRejection}
                    readOnly={true}
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  render() {
    const {
      mode,
      instance,
      showSaveChangesConfirmation,
      showCancelConfirmation,
      showCompleteConfirmation,
      showRejectConfirmation,
      showHistoryDialog,
    } = this.state;
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {mode === Mode.Readonly && this.renderTopButtons()}
              {this.renderForm()}
              {mode === Mode.Readonly && this.generateActions()}
              {mode === Mode.Editable && this.renderBottomButtons()}
            </div>
          </div>
        </div>
        <ConfirmDialog
          visible={showSaveChangesConfirmation}
          title={GenericMessages.SaveChangesDialogTitle}
          onProceed={this.onSubmit}
          onCancel={() => this.setState({ showSaveChangesConfirmation: false })}
          message="Are you sure you want to save changes?"
        />
        <ConfirmDialog
          visible={showCancelConfirmation}
          title={GenericMessages.CancelChangesDialogTitle}
          onProceed={this.onCancel}
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          message={GenericMessages.CancelChangesDialogMessage}
        />
        <ConfirmDialog
          visible={showCompleteConfirmation}
          title="Complete Booking Request"
          onProceed={this.onComplete}
          onCancel={() => this.setState({ showCompleteConfirmation: false })}
          message="Are you sure you want to complete the request?"
        />
        <BookingRejectModal
          visible={showRejectConfirmation}
          onProceed={this.onReject}
          onCancel={() => this.setState({ showRejectConfirmation: false })}
          message="Are you sure you want to reject the request?"
        />
        {showHistoryDialog && (
          <HistoryDialog
            objectId={instance.id}
            onClose={() => this.setState({ showHistoryDialog: false })}
            url="/api/v1/history_logs"
            objectType={ObjectType.CorporateBookingRequest}
          />
        )}
      </>
    );
  }
}
