import { RestService } from "./RestService";

export class EVoucherService extends RestService {
  constructor() {
    super("api/v1/evouchers");
  }

  block(payload) {
    return this.postImpl(`block`, payload);
  }

  unblock(payload) {
    return this.postImpl(`unblock`, payload);
  }

  download(payload) {
    return this.postImpl("download", payload);
  }
}
