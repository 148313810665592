import moment from "moment";
import { roundAmount } from "../common/Constants";
import { DateHelper } from "../common/Helpers";
import {
  CouponTitleType,
  DiscountMode,
  SeatsCondition,
} from "../constants/CampaignConstants";

export class CampaignBundle {
  constructor(data) {
    this.id = data.id ?? 0;
    this.displayName = data.displayName ?? "";
    this.printName = data.printName ?? "";
    this.description = data.description ?? "";
    this.posterUrl = data.posterUrl ?? "";
    this.displayWeight = data.displayWeight ?? 100;
    this.stackCodes = data?.stackCodes ?? [];
    this.seatCondition = data.seatCondition ?? SeatsCondition.NoCondition;
    this.numberOfSeats = data.numberOfSeats ?? 0;
    this.isActive = data.isActive ?? false;
    this.startOn = data.startOn ?? this.getDefaultStartOn();
    this.endOn = data.endOn ?? this.getDefaultEndOn();
    this.performanceStartOn =
      data.performanceStartOn ?? this.getDefaultPerformanceStarOn();
    this.performanceEndOn =
      data.performanceEndOn ?? this.getDefaultPerformanceEndOn();

    this.locationIds = this.getLocationIds(data);
    this.appIds = this.getAppIds(data);
    this.campaignBundleTickets = this.convertBundleTickets(data);
    this.campaignBundleProducts = this.convertBundleProducts(data);
    this.campaignBundleCoupons = this.convertBundleCoupons(data);
  }

  getAppIds(data) {
    if (data.campaignBundleSalesChannelValidity) {
      return data.campaignBundleSalesChannelValidity.map((x) => x.appId);
    }
    return [];
  }

  getLocationIds(data) {
    if (data.campaignBundleRedemptionLocationValidity) {
      return data.campaignBundleRedemptionLocationValidity.map(
        (x) => x.locationId
      );
    }
    return [];
  }

  convertBundleTickets(data) {
    if (data.campaignBundleTickets) {
      return data.campaignBundleTickets.map((x) => new CampaignBundleTicket(x));
    }
    return [];
  }

  convertBundleProducts(data) {
    if (data.campaignBundleProducts) {
      return data.campaignBundleProducts.map(
        (x) => new CampaignBundleProduct(x)
      );
    }
    return [];
  }

  convertBundleCoupons(data) {
    if (data.campaignBundleCoupons) {
      return data.campaignBundleCoupons.map((x) => new CampaignBundleCoupon(x));
    }
    return [];
  }

  getDefaultStartOn() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return moment(currentDate).format(DateHelper.IsoDateTimeFormat);
  }

  getDefaultEndOn() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    return moment(currentDate).format(DateHelper.IsoDateTimeFormat);
  }

  getDefaultPerformanceStarOn() {
    const date = new Date(1900, 7, 3);
    return moment(date).format(DateHelper.IsoDateTimeFormat);
  }

  getDefaultPerformanceEndOn() {
    const date = new Date(9999, 7, 3);
    return moment(date).format(DateHelper.IsoDateTimeFormat);
  }
}

export class CampaignBundleCoupon {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignBundleId = data.campaignBundleId ?? 0;
    this.title = data.title ?? "";
    this.description = data.description ?? "";
    this.termCondition = data.termCondition ?? "";
    this.quantity = data.quantity ?? 0;
    this.couponTitleType = data.couponTitleType || CouponTitleType.UserDefined;
    this.labelDisplay = data.labelDisplay || "Coupon Slip";
  }
}

export class CampaignBundleProduct {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignBundleId = data.campaignBundleId ?? 0;
    this.productId = data.productId ?? 0;
    this.productName = data.productName ?? "";
    this.productCategory = data.productCategory ?? 0;
    this.quantity = data.quantity ?? 0;
    this.salesAmount = data.salesAmount ?? 0;
    this.discountAmount = data.discountAmount ?? 0;
    this.reimbursementAmount = data.reimbursementAmount ?? 0;
    this.docketAmount = data.docketAmount ?? 0;
    this.includeCoupon = data.includeCoupon ?? false;
  }

  calculate(salesAmount) {
    if (salesAmount < 0) {
      return;
    }
    this.salesAmount = salesAmount;
  }
}

export class CampaignBundleTicket {
  constructor(data) {
    this.id = data.id ?? 0;
    this.campaignBundleId = data.campaignBundleId ?? 0;
    this.serviceId = data.serviceId ?? 0;
    this.serviceCode = data.serviceCode ?? 0;
    this.ticketType = data.ticketType ?? 0;
    this.quantity = data.quantity ?? 0;
    this.discountMode = data.discountMode ?? DiscountMode.Fixed;
    this.salesAmount = data.salesAmount ?? 0;
    this.discountAmount = data.discountAmount ?? 0;
    this.reimbursementAmount = data.reimbursementAmount ?? 0;
    this.docketAmount = data.docketAmount ?? 0;
  }

  calculate(salesAmount) {
    if (salesAmount < 0) {
      return;
    }
    this.salesAmount = roundAmount(salesAmount);
  }
}
