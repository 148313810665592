import React, { useState } from "react";
import _ from "lodash";

export const SearchDropdown = (props) => {
  const { label, onSelectChange, data: dataProps } = props;

  const [options, setOptions] = useState([]);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  function handleSearch(e) {
    setOptions(dataProps.filter(data => _.includes(data.name.toLowerCase(), e.target.value.toLowerCase())));

    if (!isOptionsVisible) setIsOptionsVisible(true);
  }

  function handleClick(name, id) {
    onSelectChange(name, id);

    setIsOptionsVisible(false);
  }

  return (
    <div className="input-group col-12 col-md-6 col-xl-4 align-items-center mb-2">
      <label
        className="form-label mb-md-0 col-12 col-md-4 col-xl-3"
        htmlFor="Status"
      >
        {label}
      </label>

      <div
        className="form-control col-auto mx-3 mx-md-0"
        style={{ position: "relative" }}
      >
        <input type="text" onChange={handleSearch} />
        {isOptionsVisible && (
          <div
            style={{
              transform: "translate(-10px, 15px)",
              position: "absolute",
              maxHeight: "400px",
              width: "100%",
              overflow: "scroll",
              zIndex: 10,
              background: "white",
            }}
          >
            {
              options.map((option) => (
                <p
                  key={option.code + option.name}
                  style={{ padding: "5px 10px" }}
                  onClick={() => handleClick("movieId", option.code)}
                >
                  {option.name}
                </p>
              ))
            }
          </div>
        )}
      </div>
    </div>
  );
};
