import React, { useEffect, useState } from "react";
import { TicketTypes } from "../../../constants/PricingConstants";
import RestClient from "../../../common/RestClient";
import { toast } from "react-toastify";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  campaignAtom,
  validForSpecificTicketTypeSelector,
} from "../CampaignState";
import { TicketTypeConditionDialog } from "../dialogs/TicketTypeConditionDialog";
import { BaseTableCondition } from "../common/BaseTableCondition";

export const TicketTypeConditionCard = (props) => {
  const campaign = useRecoilValue(campaignAtom);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] =
    useState(false);
  const [data, setData] = useState([]);
  const validForSpecificTicketType = useSetRecoilState(
    validForSpecificTicketTypeSelector
  );

  const { isEdit } = props;
  const handleError = (error) => {
    toast.error(error.message);
  };

  useEffect(() => {
    setShowTable(campaign.validForSpecificTicketType);
  }, [campaign]);

  useEffect(() => {
    if (campaign.id === 0) {
      return;
    }
    RestClient.sendGetRequestWithParameters(
      "api/v1/campaign_validity_conditions/ticket_type",
      { campaignId: campaign.id },
      (response) => {
        setData(response.data);
      },
      handleError
    );
  }, [campaign.id, refresh]);

  const onDeleteElement = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/ticket_type/${selectedId}`,
      () => {
        validForSpecificTicketType(data.length > 1);
        toast.success("The ticket type condition has been deleted successful.");
        setRefresh((r) => r + 1);
      },
      handleError
    );
    setShowDeleteConfirmation(false);
  };

  const onCreate = (ticketTypes) => {
    const params = {
      ticketTypes: ticketTypes,
      campaignId: campaign.id,
    };
    RestClient.sendPostRequest(
      "api/v1/campaign_validity_conditions/ticket_type",
      params,
      (_) => {
        validForSpecificTicketType(true);
        toast.success(
          "The campaign ticket type condition has been created successful."
        );
        setRefresh((r) => r + 1);
      },
      handleError
    );
    setShowCreateDialog(false);
  };

  const onDeleteAll = () => {
    RestClient.sendDeleteRequest(
      `api/v1/campaign_validity_conditions/${campaign.id}/all_ticket_type_conditions`,
      () => {
        toast.success(
          "All ticket type conditions have been deleted successful."
        );
        setRefresh((ref) => ref + 1);
        validForSpecificTicketType(false);
      },
      handleError
    );
    setShowDeleteAllConfirmation(false);
  };

  return (
    <>
      <div className="form-group row">
        <label className="col-sm-2 col-form-label">Ticket Type Condition</label>
        <div className="col-sm-6">
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="TicketCondition"
              id="TicketConditionRbNo"
              value="No"
              checked={!showTable}
              onChange={() => {
                setShowTable(false);
                if (data && data.length > 0) {
                  setShowDeleteAllConfirmation(true);
                }
              }}
            />
            <label className="form-check-label" htmlFor="TicketConditionRbNo">
              No ticket type restriction
            </label>
          </div>
          <div className="">
            <input
              disabled={!isEdit}
              className="mr-1"
              type="radio"
              name="TicketCondition"
              id="TicketConditionRbYes"
              value="Yes"
              checked={showTable}
              onChange={() => setShowTable(true)}
            />
            <label className="form-check-label" htmlFor="TicketConditionRbYes">
              Valid for specific ticket type
            </label>
            <button
              type="button"
              className="btn btn-sm btn-outline-primary float-right"
              disabled={!showTable || !isEdit}
              onClick={() => setShowCreateDialog(true)}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
      {showTable && (
        <BaseTableCondition>
          <thead>
            <tr>
              <th>Ticket Type</th>
              <th style={{ width: "10%" }} />
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <tr key={item.id}>
                <td>{TicketTypes.getDisplay(item.ticketType)}</td>
                <td>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => {
                      setShowDeleteConfirmation(true);
                      setSelectedId(item.id);
                    }}
                    disabled={!isEdit}
                  >
                    <i className="far fa-trash-alt" aria-hidden="true" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </BaseTableCondition>
      )}
      <ConfirmDialog
        visible={showDeleteConfirmation}
        onProceed={onDeleteElement}
        onCancel={() => setShowDeleteConfirmation(false)}
        message="Are you sure to delete the condition?"
      />
      <TicketTypeConditionDialog
        visible={showCreateDialog}
        onConfirm={onCreate}
        onCancel={() => setShowCreateDialog(false)}
      />
      <ConfirmDialog
        visible={showDeleteAllConfirmation}
        onProceed={onDeleteAll}
        onCancel={() => {
          setShowDeleteAllConfirmation(false);
          setShowTable(true);
        }}
        message="All the added conditions will be deleted?"
      />
    </>
  );
};
