import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { GenericMessages, ObjectType } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BreadCrumb } from "../../common/BreadCrumb";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ScheduleConfirmationDialog } from "../../common/ScheduleConfirmationDialog";
import { ScheduledLocationService } from "../../../services/ScheduledLocationService";
import { CancelPreviewButton } from "../common/CancelPreviewButton";
import { CardMode } from "../common/CardMode";
import { LocationDetailCard } from "./cards/LocationDetailCard";
import { LocationPreview } from "./preview/LocationPreview";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

const ViewPageName = "View Location";
const EditPageName = "Edit Location";

export class LocationDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.View,
      location: null,
      locationId: "",
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showPreview: false,
      showScheduleDialog: false,
      showHistoryDialog: false,
      scheduledLocations: []
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const locationId = StringHelper.toNumber(id, 0);
    if (isNaN(id) || locationId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.loadScheduledLocations(id);
    this.setState({ locationId }, this.refreshData);
  }

  loadScheduledLocations = (id) => {
    new ScheduledLocationService().get("",{ locationId: id })
      .then((response) => {
        this.setState({ scheduledLocations: response?.data?.data });
      });
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  loadLocation(id) {
    RestClient.sendGetRequest(
      `/api/v1/locations/${id}`,
      (response) => {
        this.setState({ location: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  onEditLocationClick = () => {
    this.setState({ cardMode: CardMode.Update });
  };

  onGeneralInfoModelChange = (model) => {
    const displayStartTime = StringHelper.toDateTimeString(
      model.displayStartTimeStr
    );
    const displayEndTime = StringHelper.toDateTimeString(
      model.displayEndTimeStr
    );
    const category = StringHelper.toNumber(model.category, 0);
    const weightDisplay = StringHelper.toNumber(model.weightDisplay, 0);
    const newModel = Object.assign(model, {
      category,
      displayStartTime,
      displayEndTime,
      weightDisplay,
    });
    this.setState({ generalInfo: newModel });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHidePreview = () => {
    this.setState({ showPreview: false });
  };

  onHideScheduleDialog = () => {
    this.setState({ showScheduleDialog: false });
  };

  onProceedCancelDialog = () => {
    this.setState({
      cardMode: CardMode.View,
      location: Object.assign({}, this.state.location),
      showCancelDialog: false,
    });
  };

  onProceedConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo, locationId } = this.state;
    const request = Object.assign(
      {},
      generalInfo
    );
    RestClient.sendPutRequest(
      `/api/v1/locations/${locationId}`,
      request,
      (response) => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          location: response,
          showConfirmDialog: false,
        });
        this.handleSuccess(GenericMessages.PublishChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({
          showConfirmDialog: false,
        });
        this.handleError(error);
      }
    );
  };

  onProceedScheduleDialog = (e) => {
    const { generalInfo, locationId } = this.state;
    const request = Object.assign(
      {
        createdBy: AuthenticationManager.username(),
        liveLocationId: locationId,
      },
      e,
      generalInfo
    );
    RestClient.sendPostRequest(
      `/api/v1/scheduled_locations/`,
      request,
      (response) => {
        this.setState({
          cardMode: CardMode.View,
          isSubmit: false,
          location: response,
          showScheduleDialog: false,
        });
        this.loadScheduledLocations(locationId);
        this.handleSuccess(GenericMessages.ScheduleChangesSuccessfulMessage);
      },
      (error) => {
        this.setState({
          showScheduleDialog: false,
        });
        this.handleError(error);
      }
    );
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowPreview = () => {
    this.setState({ showPreview: true });
  };

  onShowScheduleDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showScheduleDialog: true });
  };

  redirectToListingPage(additionalParameter) {
    this.props.history.push(`/locations/${additionalParameter ?? ""}`);
  }

  refreshData() {
    const { locationId } = this.state;
    this.loadLocation(locationId);
  }

  onHistoryDialogClose = () => {
    this.setState({ showHistoryDialog: false });
  };

  getNavigationSettings = () => {
    const { cardMode } = this.state;
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "locations",
        name: "Locations",
      }),
      activeModule: new NavigationItem({
        identifier: "location-details",
        name: CardMode.isViewMode(cardMode) ? ViewPageName : EditPageName,
      }),
    });
  };

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  handleSuccess = (message) => {
    toast.success(message);
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      location,
      showCancelDialog,
      showConfirmDialog,
      showPreview,
      showScheduleDialog,
      showHistoryDialog,
      scheduledLocations
    } = this.state;
    if (showPreview) {
      const previewModel = Object.assign(
        {},
        generalInfo
      );
      return (
        <>
          <LocationPreview location={previewModel} />
          <CancelPreviewButton onClick={this.onHidePreview} />
        </>
      );
    }
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {CardMode.isViewMode(cardMode) && (
              <div className="row mb-3">
                <div className="col">
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="btn btn-outline-secondary ml-2"
                      onClick={() => this.setState({ showHistoryDialog: true })}
                    >
                      History
                    </button>
                    {AuthenticationManager.isAuthorized(
                      ResourceID.LocationEditButtonPage
                    ) && (
                        <button
                          className="btn btn-primary"
                          value="Edit"
                          disabled={location ? undefined : "disabled"}
                          onClick={this.onEditLocationClick}
                        >
                          <i className="fas fa-edit"></i> Edit
                        </button>
                      )}
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <LocationDetailCard
                  cardMode={cardMode}
                  defaultValue={location}
                  isSubmit={isSubmit}
                  model={generalInfo}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>
            {CardMode.isEditMode(cardMode) && (
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={this.onShowPreview}
                >
                  Preview
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-success"
                  onClick={this.onShowConfirmDialog}
                >
                  Save Changes
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  onClick={this.onShowScheduleDialog}
                >
                  Schedule
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  className="btn btn-danger"
                  onClick={this.onShowCancelDialog}
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showCancelDialog && location}
          onCancel={this.onHideCancelDialog}
          onProceed={this.onProceedCancelDialog}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelChangesDialogTitle}
        />
        <ConfirmDialog
          visible={showConfirmDialog && location}
          onCancel={this.onHideConfirmDialog}
          onProceed={this.onProceedConfirmDialog}
          message={GenericMessages.SaveChangesDialogMessage}
          title={GenericMessages.SaveChangesDialogTitle}
        />
        {showScheduleDialog && location && (
          <ScheduleConfirmationDialog
            onCancel={this.onHideScheduleDialog}
            onProceed={this.onProceedScheduleDialog}
            message={GenericMessages.ScheduleChangesDialogMessage("location")}
            warningMessage={GenericMessages.ScheduleWarningDialogMessage("location")}
            title={GenericMessages.ScheduleChangesDialogTitle}
            data={scheduledLocations}
          />
        )}
        {showHistoryDialog && (
          <HistoryDialog
            objectId={location.id}
            onClose={this.onHistoryDialogClose}
            url="/api/v1/history_logs"
            objectType={ObjectType.Location}
          />
        )}
      </div>
    );
  }
}
