import React, { useState } from "react";
import { CampaignConditionDateTime } from "../../../models/CampaignCondtion";
import { DayOfWeek } from "../../../constants/CampaignConstants";
import _ from "lodash";
import {toast} from "react-toastify";
export const DayTimeConditionDialog = (props) => {
  const [dateTimeCondition, setDateTimeCondition] = useState(
    new CampaignConditionDateTime()
  );
  const [isValidForFullBusinessDay, setIsValidForFullBusinessDay] =
    useState(false);
  const [chosenDay, setChosenDay] = useState(0);
  const { onCancel, onConfirm, visible } = props;
  const onAddClick = () => {
    if(!validateValue()) return;
    onConfirm(dateTimeCondition, isValidForFullBusinessDay);
    setChosenDay(0);
    setIsValidForFullBusinessDay(false);
  };
  
  const validateValue = () => {
    if (!isValidForFullBusinessDay) {
      const { timeEnd, timeStart } = dateTimeCondition;
      if (!timeStart || !timeEnd) {
        toast.error('Start Time and End Time is required!');
        return false;
      }
      const dateString = new Date().toLocaleDateString();
      const startTimeDate = new Date(`${dateString} ${timeStart}`);
      const endTimeDate = new Date(`${dateString} ${timeEnd}`);
      if (startTimeDate.getTime() >= endTimeDate.getTime()) {
        toast.error('Start time must before End time');
        return false;
      }
    }
    return true;
  }

  const onCancelClick = () =>{
    onCancel();
    setDateTimeCondition(new CampaignConditionDateTime());
    setIsValidForFullBusinessDay(false);
  }

  const onInputDateChange = (e) => {
    e.persist();
    if(isNaN(+e.target.value)) {
      dateTimeCondition.dayOfWeek = -1;
    } else {
      dateTimeCondition.dayOfWeek = +e.target.value;
    }
    setChosenDay(dateTimeCondition.dayOfWeek);
  };

  const onInputTimeStartChange = (e) => {
    e.persist();
    setDateTimeCondition((date) => {
      return {
        ...date,
        timeStart: e.target.value,
      };
    });
  };

  const onInputTimeEndChange = (e) => {
    e.persist();
    setDateTimeCondition((date) => {
      return {
        ...date,
        timeEnd: e.target.value,
      };
    });
  };

  if (!_.isNil(visible) && !visible) {
    return null;
  }

  return (
    <div
      className="modal fade show"
      id="DayAndTimeConditionModal"
      role="dialog"
      style={{ paddingRight: "17px", display: "block" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Configure Valid Day And Time
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body pb-5">
            <div className="row form-group">
              <div className="col-3">
                Day of Week:
                <span className="color-red font-weight-bold">*</span>
              </div>
              <div className="col">
                <select
                  className="form-control"
                  id="DayOfWeek"
                  name="dayOfWeek"
                  onChange={(e) => onInputDateChange(e)}
                >
                  <option value="">Choose a day</option>
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                  <option value="0">Sunday</option>
                </select>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-3">
                Condition Validity:
                <span className="color-red font-weight-bold">*</span>
              </div>
              <div className="col">
                <div className="form-check-inline form-check">
                  <label
                    htmlFor="rbSpecificTime"
                    className="form-check-label mr-3"
                  >
                    <input
                      type="radio"
                      id="rbSpecificTime"
                      name="TimeCondition"
                      value="#SpecificTime"
                      className="form-check-input"
                      defaultChecked
                      onChange={() => setIsValidForFullBusinessDay(false)}
                    />
                    Valid for Specific Time
                  </label>
                  <label htmlFor="rbBuisnessDay" className="form-check-label ">
                    <input
                      type="radio"
                      id="rbBuisnessDay"
                      name="TimeCondition"
                      value="#BusinessDay"
                      className="form-check-input"
                      onChange={() => setIsValidForFullBusinessDay(true)}
                    />
                    Valid for Full Business Day
                  </label>
                </div>
              </div>
            </div>
            {!isValidForFullBusinessDay && (
              <div className="row form-group TimeCondition" id="SpecificTime">
                <div className="col-3">
                  Start Time:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col pr-0">
                  <input
                    className="form-control"
                    type="time"
                    id="ConditionStart"
                    name="timeStart"
                    onChange={onInputTimeStartChange}
                  />
                </div>
                <div className="col-3">
                  End Time:
                  <span className="color-red font-weight-bold">*</span>
                </div>
                <div className="col pl-0">
                  <input
                    className="form-control"
                    type="time"
                    id="ConditionEnd"
                    name="timeEnd"
                    onChange={onInputTimeEndChange}
                  />
                </div>
              </div>
            )}
            {isValidForFullBusinessDay && (
              <div className="row form-group TimeCondition" id="BusinessDay">
                <div className="offset-3 col">
                  Valid from{" "}
                  <span id="Day1">{DayOfWeek.getName(chosenDay)}</span> 7:00 AM
                  to 11:59 PM and{" "}
                  <span id="Day2">{DayOfWeek.getNextDayName(chosenDay)}</span>{" "}
                  12:00 AM to 6:59 AM
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn btn-danger"
              data-dismiss="modal"
              onClick={onCancelClick}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn btn-success"
              onClick={onAddClick}
            >
              Add Condition
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
