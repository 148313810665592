import React from "react";
import MenuSidebar from "./layout/MenuSidebar";
import "./css/main.scss";
import MobileHeader from "./layout/MobileHeader";

export const Navigation = ({ toggleSidebar }) => {
  return (
    <>
      <MobileHeader />
      <MenuSidebar toggleSidebar={toggleSidebar} />
    </>
  );
};