import React from "react";

const TransactionInformationProducts = (props) => {
  const { products } = props;

  if (!products || products.length === 0) return <></>;

  return (
    <div className="card mb-0">
      <div className="card-body card-block">
        <div className="row m-0">
          <div className="col-12">
            {products.map((item, index) => {
              return (
                <label
                  key={index}
                  className="form-control-label row"
                  id="AddonName"
                >
                  {item.quantity} x {item.productName}
                  <span
                    style={{ fontSize: "13px", paddingTop: "5px" }}
                    className="ml-2"
                  >
                    [{item.productName}]
                  </span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionInformationProducts;
