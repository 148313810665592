import React from "react";
import { SearchBy } from "../../../common/Constants";
import { DateHelper, StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { VoucherStatus } from "../../../constants/VoucherContants";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { RowFormField } from "../../layout/FormLayout";

export default class VoucherListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
      serialNumber: "",
      serialNumberStart: "",
      serialNumberEnd: "",
      validityEndFrom: "",
      validityEndTo: "",
      validityStartFrom: "",
      validityStartTo: "",
      status: 0,
      programId: 0,
      voucherStatuses: [],
      voucherPrograms: [],
      searchBy: SearchBy.SingleSerialNumber,
    };
  }

  async componentDidMount() {
    await this.loadData(0);
    this.getVoucherStatuses();
    this.getVoucherPrograms();
  }

  getVoucherStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/vouchers/status_dropdowns",
      (response) => {
        this.setState({ voucherStatuses: response });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getVoucherPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/vouchers/program_dropdowns",
      (response) => {
        this.setState({ voucherPrograms: response.data });
      },
      (error) => {
        this.handleError(error);
      }
    );
  };

  getApiPath = () => "/api/v1/vouchers";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : `${this.state.name}`;
    const serialNumber = StringHelper.isNullOrEmpty(this.state.serialNumber)
      ? ""
      : `${this.state.serialNumber}`;
    const serialNumberStart = StringHelper.isNullOrEmpty(
      this.state.serialNumberStart
    )
      ? ""
      : `${this.state.serialNumberStart}`;
    const serialNumberEnd = StringHelper.isNullOrEmpty(
      this.state.serialNumberEnd
    )
      ? ""
      : `${this.state.serialNumberEnd}`;
    Object.assign(queryParameters, { includes: "VoucherProgram" });
    var validityEndToModify = "";
    var validityStartToModify = "";

    if (
      !this.state.validityEndTo.isNullOrEmpty &&
      this.state.validityEndTo.length > 0
    ) {
      var endToDate = new Date(this.state.validityEndTo);
      endToDate.setDate(endToDate.getDate() + 1);
      validityEndToModify = DateHelper.toIsoDateFormat(endToDate);
    }

    if (
      !this.state.validityStartTo.isNullOrEmpty &&
      this.state.validityStartTo.length > 0
    ) {
      var startToDate = new Date(this.state.validityStartTo);
      startToDate.setDate(startToDate.getDate() + 1);
      validityStartToModify = DateHelper.toIsoDateFormat(startToDate);
    }

    return super.injectSearchTerm(queryParameters, {
      name,
      serialNumber,
      serialNumberStart,
      serialNumberEnd,
      validityEndFrom: this.state.validityEndFrom,
      validityEndTo: validityEndToModify,
      validityStartFrom: this.state.validityStartFrom,
      validityStartTo: validityStartToModify,
      status: this.state.status,
      programId: this.state.programId,
    });
  }

  onSearchByChange = (e) => {
    if (this.state.searchBy !== e.target.value) {
      this.setState({ searchBy: e.target.value });
      this.setState({
        serialNumber: "",
        serialNumberStart: "",
        serialNumberEnd: "",
        validityEndFrom: "",
        validityEndTo: "",
        validityStartFrom: "",
        validityStartTo: "",
      });
    }
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onEnter = async (e) => {
    if (e.keyCode === 13) {
      await this.search();
    }
  };

  generateExtendedComponents() {
    const { voucherStatuses, voucherPrograms, searchBy } = this.state;
    return (
      <React.Fragment>
        <div className="row form-group">
          <label className="form-control-label d-inline-flex text-nowrap col-12 col-xl-1">
            Search By:
          </label>
          <div className="col-md-9 col-xl-8">
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="SingleSN"
                    name="SearchBy"
                    value={SearchBy.SingleSerialNumber}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    checked={searchBy === SearchBy.SingleSerialNumber}
                  />
                  Single Serial Number
                </>
              }
              htmlFor="SingleSN"
            >
              <input
                className="form-control SingleSN"
                id="SingleText"
                type="text"
                placeholder="Enter Single Serial Number"
                value={this.state.serialNumber}
                onKeyDown={this.onEnter}
                name="serialNumber"
                onChange={this.onInputChange}
                disabled={this.state.searchBy !== SearchBy.SingleSerialNumber}
              />
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="SNRange"
                    name="SearchBy"
                    value={SearchBy.SerialNumberRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    checked={searchBy === SearchBy.SerialNumberRange}
                  />
                  Serial Number Range
                </>
              }
              htmlFor="SNRange"
            >
              <div className="d-flex justify-content-between">
                <input
                  className="form-control SNRange"
                  type="text"
                  id="SNStart"
                  placeholder="Serial Start"
                  value={this.state.serialNumberStart}
                  name="serialNumberStart"
                  onChange={this.onInputChange}
                  disabled={this.state.searchBy !== SearchBy.SerialNumberRange}
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control SNRange"
                  type="text"
                  id="SNEnd"
                  placeholder="Serial End"
                  value={this.state.serialNumberEnd}
                  name="serialNumberEnd"
                  onChange={this.onInputChange}
                  disabled={this.state.searchBy !== SearchBy.SerialNumberRange}
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
            <RowFormField
              htmlFor="DateRange"
              label={
                <>
                  <input
                    type="radio"
                    id="DateRange"
                    name="SearchBy"
                    value={SearchBy.ValidityEndDateRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                    onKeyDown={this.onEnter}
                  />
                  Validity End Date Range
                </>
              }
            >
              <div className="row mx-0 justify-content-between">
                <input
                  className="form-control DateStart"
                  type="date"
                  id="DateStart"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityEndFrom}
                  name="validityEndFrom"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityEndDateRange
                  }
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control DateStart"
                  type="date"
                  id="DateEnd"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityEndTo}
                  name="validityEndTo"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityEndDateRange
                  }
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
            <RowFormField
              label={
                <>
                  <input
                    type="radio"
                    id="DateRange2"
                    name="SearchBy"
                    value={SearchBy.ValidityStartDateRange}
                    className="mr-1"
                    onChange={this.onSearchByChange}
                  />
                  Validity Start Date Range
                </>
              }
              htmlFor="DateRange2"
            >
              <div className="row mx-0 justify-content-between">
                <input
                  className="form-control"
                  type="date"
                  id="DateStart2"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityStartFrom}
                  name="validityStartFrom"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityStartDateRange
                  }
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
                <input
                  className="form-control"
                  type="date"
                  id="DateEnd2"
                  placeholder="Enter Serial Number Start Range"
                  value={this.state.validityStartTo}
                  name="validityStartTo"
                  onChange={this.onInputChange}
                  disabled={
                    this.state.searchBy !== SearchBy.ValidityStartDateRange
                  }
                  onKeyDown={this.onEnter}
                  style={{ width: "49%" }}
                />
              </div>
            </RowFormField>
          </div>
          <div className="col-md-3 ml-auto">
            <div className="form-group">
              <label className="form-control-label">Status:</label>
              <select
                className="form-control"
                id="SelectStatus"
                name="status"
                onChange={this.onInputChange}
                required
              >
                <option value={0}>All Status</option>
                {voucherStatuses &&
                  voucherStatuses.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-control-label text-nowrap">
                Voucher Program:
              </label>
              <select
                className="form-control mt-2"
                id="SelectVoucher"
                disabled={this.state.searchBy === SearchBy.SerialNumberRange}
                name="programId"
                onChange={this.onInputChange}
                required
              >
                <option value={0}>All Voucher</option>
                {voucherPrograms &&
                  voucherPrograms.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end flex-row mb-2">
          <button
            className="btn btn-primary"
            onClick={this.search}
            id="btnSearch"
          >
            <i className="fa fa-search"></i>Search
          </button>
        </div>
      </React.Fragment>
    );
  }

  getColor = (status) => {
    if (status === VoucherStatus.Registered) {
      return "color-orange";
    }

    if (status === VoucherStatus.Activated) {
      return "color-green";
    }

    return "color-red";
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>
              Serial Number{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="SerialNumber"
                onClick={this.toggleSort}
              />
            </th>
            <th>Voucher Program</th>
            <th>
              Validity Start{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityStart"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Validity End{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="ValidityEnd"
                onClick={this.toggleSort}
              />
            </th>
            <th>Status</th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item, index) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.serialNumber}</td>
                  <td>{item.voucherProgram?.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateFormat(
                      item.validityStart
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateFormat(
                      item.validityEnd
                    )}
                  </td>
                  <td>
                    <span className={this.getColor(item.status)}>
                      {VoucherStatus.getName(item.status)}
                    </span>
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }
}
