import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ScheduledFilmFestivalListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
  }

  getApiPath = () => "/api/v1/scheduled_film_festivals";

  getDefaultSort = () => "+PublishedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      parentModule: new NavigationItem({
        identifier: "film_festivals",
        name: "Film Festivals",
      }),
      activeModule: new NavigationItem({
        identifier: "scheduled_film_festivals",
        name: "Scheduled Film Festival",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const name = StringHelper.isNullOrEmpty(this.state.name)
      ? ""
      : this.state.name;
    return super.injectSearchTerm(queryParameters, { name });
  }

  onRowClick = (id) => {
    this.props.history.push(`/film_festivals/schedules/${id}`);
  };

  onTextBoxChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Film Festival Title</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>Display Weight</th>
            <th>Updated On</th>
            <th>
              Published On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="PublishedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
                return (
                  <tr key={index} onClick={() => this.onRowClick(item.id)}>
                    <td>{item.name}</td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(
                        item.displayStartTime
                      )}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(
                        item.displayEndTime
                      )}
                    </td>
                    <td>{item.displayWeight}</td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(item.updatedOn)}
                    </td>
                    <td>
                      {StringHelper.asDateStringToDefaultFormat(
                        item.publishedOn
                      )}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </React.Fragment>
    );
  }
}
