import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class CareerListing extends BaseListing {
  getApiPath = () => "/api/v1/careers";

  getDefaultSort = () => "-UpdatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      rootModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "careers",
        name: "Careers",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/careers/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th>Career Title</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th className="pointer" onClick={this.onColumnClick}>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="WeightDisplay"
                onClick={this.toggleSort}
              />
            </th>
            <th className="pointer" onClick={this.onColumnClick}>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndTime
                    )}
                  </td>
                  <td>{item.weightDisplay}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.CareerCreateButtonPage
      ) && (
        <div className="d-flex flex-row justify-content-end mb-3">
          <Link
            className="btn btn-primary"
            to="/careers/create"
            style={{ fontSize: "1rem" }}
          >
            <i className="fa fa-plus-circle"></i> Create New Career
          </Link>
        </div>)
    );
  }
}
