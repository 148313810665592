import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { BaseListing } from "../../common/BaseListing";
import _ from "lodash";
import {
  Days,
  DaySelection,
  PerformanceTypes,
  PricingUpdateMode,
  TicketTypes,
  UpdatePriceCriteria,
} from "../../../constants/PricingConstants";
import { SearchServiceCriteria } from "../../../models/SearchServiceCriteria";
import { LatestPrices } from "../../../models/LatestPrices";
import { PriceUpdateModal } from "./PriceUpdateModal";
import { ServicesRestService } from "../../../services/ServicesRestService";
import { PriceUpdateSummary } from "./PriceUpdateSummary";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { GenericMessages } from "../../../common/Constants";
import { LocationBrand, MovieFormat } from "../../../constants/MovieConstants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ColumnFormField } from "../../layout/FormLayout";

export class PriceUpdateListing extends BaseListing {
  constructor(props) {
    super(props);
    this.state = {
      searchCriteria: new SearchServiceCriteria(),
      prices: new LatestPrices(),
      isSelectAll: false,
      showUpdateModal: false,
      showPublishConfirmation: false,
      showCancelConfirmation: false,
      mode: PricingUpdateMode.None,
      publishPricingUpdateMessage: GenericMessages.PublishPricingUpdateMessage,
    };
    this.pricingService = new ServicesRestService();
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "pricing",
        name: "Pricing",
      }),
      activeModule: new NavigationItem({
        identifier: "price",
        name: "Price",
      }),
    });
  };

  getApiPath = () => "/api/v1/services";

  getDefaultSort = () => "-UpdatedOn";

  async componentDidMount() {}

  onInputChange = (e) => {
    const { searchCriteria } = this.state;
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }
    searchCriteria[fieldName] = selectValue;
    this.setState({ searchCriteria });
  };

  getTicketTypeNames = (service) => {
    if (_.isNil(service) || _.isNil(service.serviceTickets)) {
      return "";
    }
    return service.serviceTickets
      .map((x) => TicketTypes.getDisplay(x.ticketType))
      .join(", ");
  };

  search = () => {
    const { searchCriteria } = this.state;
    if (searchCriteria.ticketType <= 0) {
      return;
    }
    this.setState({ mode: PricingUpdateMode.SearchResult, isSelectAll: false });
    this.loadData(0);
  };

  onUpdatePrice = (updatePriceData) => {
    const { prices } = this.state;
    if (_.isNil(updatePriceData)) {
      return;
    }

    prices.data
      .filter((x) => x.isSelected)
      .forEach((x) => {
        if (updatePriceData.updateType === UpdatePriceCriteria.Replace) {
          x.newPricing = updatePriceData.salesAmount;
        } else {
          x.newPricing = x.latestPricing + updatePriceData.salesAmount;
        }
        x.setNewEffectiveStart(updatePriceData.effectiveStart);
      });

    this.setState({
      prices,
      mode: PricingUpdateMode.Summary,
      updatePriceData,
    });
    this.closeAllModal();
  };

  onPublish = () => {
    const { prices, updatePriceData } = this.state;
    let bodyRequest = { ...updatePriceData };
    bodyRequest.serviceTicketPriceIds = prices.data
      .filter((x) => x.isSelected)
      .map((x) => x.serviceTicketPriceId);
    this.pricingService.updatePrice(bodyRequest).then(() => {
      toast.success("The pricing has been updated successfully.");
      this.setState({
        mode: PricingUpdateMode.None,
      });
    });
    this.closeAllModal();
  };

  onShowPublishConfirmation = () => {
    const { prices } = this.state;
    const selectedPrices = prices.data.filter((x) => x.isSelected).length;
    const publishPricingUpdateMessage = `${GenericMessages.PublishPricingUpdateMessage} ${selectedPrices}`;
    this.setState({
      showPublishConfirmation: true,
      publishPricingUpdateMessage,
    });
  };

  onCancel = () => {
    const { searchCriteria } = this.state;
    searchCriteria.reset();

    this.setState({
      searchCriteria,
      mode: PricingUpdateMode.None,
    });
    this.closeAllModal();
  };

  closeAllModal = () => {
    this.setState({
      showUpdateModal: false,
      showPublishConfirmation: false,
      showCancelConfirmation: false,
    });
  };

  generateExtendedComponents() {
    const { searchCriteria } = this.state;
    const enableSearchBtn = searchCriteria.ticketType > 0;
    return (
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header">
              Pricing Plan
              <i
                className="fas fa-chevron-down float-right zoom-1_5 plan"
                style={{ display: "none" }}
                aria-hidden="true"
              />
              <i
                className="fas fa-chevron-up float-right zoom-1_5 plan"
                aria-hidden="true"
              />
            </div>
            <div className="card-body plan">
              <div className="row form-group">
                <ColumnFormField
                  label={
                    <span>
                      Location Brand:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control disableDrop"
                    valuetype="number"
                    name="ticketBrand"
                    onChange={this.onInputChange}
                    value={searchCriteria.ticketBrand}
                  >
                    <option value="0">All Location Brand</option>
                    {LocationBrand.All.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </ColumnFormField>
                <ColumnFormField
                  label={
                    <span>
                      Day of Week:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control disableDrop"
                    valuetype="number"
                    name="daySelection"
                    onChange={this.onInputChange}
                  >
                    <option value="0">All Day Selection</option>
                    {DaySelection.All.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </ColumnFormField>
              </div>
              <div className="row form-group">
                <ColumnFormField
                  label={
                    <span>
                      Movie Format:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control disableDrop"
                    valuetype="number"
                    name="ticketFormat"
                    value={searchCriteria.ticketFormat}
                    onChange={this.onInputChange}
                  >
                    <option value="0">All Movie Format</option>
                    {LocationBrand.getMovieFormats(
                      searchCriteria.ticketBrand
                    ).map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </ColumnFormField>
                {searchCriteria.daySelection === DaySelection.Custom && (
                  <ColumnFormField label="Start Day:">
                    <select
                      className="form-control"
                      name="startDay"
                      valuetype="number"
                      onChange={this.onInputChange}
                      value={searchCriteria.startDay}
                    >
                      {Days.All.map((entry, index) => (
                        <option key={index} value={entry.value}>
                          {entry.name}
                        </option>
                      ))}
                    </select>
                  </ColumnFormField>
                )}
              </div>
              <div className="row form-group">
                <ColumnFormField
                  label={
                    <span>
                      Ticket Type:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control disableDrop"
                    name="ticketType"
                    valuetype="number"
                    value={searchCriteria.ticketType}
                    onChange={this.onInputChange}
                  >
                    <option value="0">Select Ticket Type</option>
                    {TicketTypes.All.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </ColumnFormField>
                {searchCriteria.daySelection === DaySelection.Custom && (
                  <ColumnFormField label="Start Time:">
                    <input
                      className="form-control display-input"
                      type="time"
                      name="startTime"
                      onChange={this.onInputChange}
                      value={searchCriteria.startTime}
                    />
                  </ColumnFormField>
                )}
              </div>
              <div className="row form-group">
                <ColumnFormField
                  label={
                    <span>
                      Showtime Type:<span className="color-red">*</span>
                    </span>
                  }
                >
                  <select
                    className="form-control disableDrop"
                    valuetype="number"
                    value={searchCriteria.performanceType}
                    name="performanceType"
                    onChange={this.onInputChange}
                  >
                    <option value="0">All Showtime Type</option>
                    {PerformanceTypes.AllExceptSpecial.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </ColumnFormField>
                {searchCriteria.daySelection === DaySelection.Custom && (
                  <ColumnFormField label="End Day:">
                    <select
                      className="form-control"
                      name="endDay"
                      valuetype="number"
                      onChange={this.onInputChange}
                      value={searchCriteria.endDay}
                    >
                      {Days.All.map((entry, index) => (
                        <option key={index} value={entry.value}>
                          {entry.name}
                        </option>
                      ))}
                    </select>
                  </ColumnFormField>
                )}
              </div>
              <div className="row form-group">
                <ColumnFormField className="offset-1 col-4" />

                {searchCriteria.daySelection === DaySelection.Custom && (
                  <ColumnFormField label="End Time:">
                    <input
                      className="form-control display-input"
                      type="time"
                      name="endTime"
                      onChange={this.onInputChange}
                      value={searchCriteria.endTime}
                    />
                  </ColumnFormField>
                )}
              </div>
              <div className="d-flex p-r-5 flex-row justify-content-end input-group mb-3">
                <button
                  className="btn btn-primary"
                  onClick={this.search}
                  disabled={!enableSearchBtn}
                >
                  <i className="fas fa-search" aria-hidden="true" /> Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  generateModals = () => {
    const {
      showUpdateModal,
      showPublishConfirmation,
      showCancelConfirmation,
      publishPricingUpdateMessage,
    } = this.state;
    return (
      <>
        {showUpdateModal && (
          <PriceUpdateModal
            onCancel={() => this.setState({ showUpdateModal: false })}
            onUpdate={this.onUpdatePrice}
          />
        )}
        <ConfirmDialog
          visible={showCancelConfirmation}
          onCancel={() => this.setState({ showCancelConfirmation: false })}
          onProceed={this.onCancel}
          message={GenericMessages.CancelChangesDialogMessage}
          title={GenericMessages.CancelPricingDialogTitle}
        />
        <ConfirmDialog
          visible={showPublishConfirmation}
          onCancel={() => this.setState({ showPublishConfirmation: false })}
          onProceed={this.onPublish}
          message={publishPricingUpdateMessage}
          title={GenericMessages.PublishPricingDialogTitle}
        />
      </>
    );
  };

  generateBottomButtons = () => {
    const { mode } = this.state;
    if (mode === PricingUpdateMode.Summary) {
      return (
        <div className="flex-row justify-content-end pb-3 action-btn d-flex">
          <button
            className="btn btn-success mr-1"
            data-toggle="modal"
            onClick={this.onShowPublishConfirmation}
            data-target="#confirmModal"
          >
            Publish Price Updates
          </button>
          <button
            className="btn btn-danger"
            data-toggle="modal"
            data-target="#cancelModal"
            onClick={() => this.setState({ showCancelConfirmation: true })}
          >
            Cancel
          </button>
        </div>
      );
    }
    return null;
  };

  generateMainGrid() {
    const { mode, prices } = this.state;
    if (mode === PricingUpdateMode.None) {
      return null;
    }

    if (mode === PricingUpdateMode.SearchResult) {
      return super.generateMainGrid();
    }
    const summaryData = prices.data.filter((x) => x.isSelected);
    return <PriceUpdateSummary data={summaryData} />;
  }

  generatePagination = () => {
    const { prices } = this.state;
    if (!prices || _.isEmpty(prices.data)) {
      return null;
    }
    const enableUpdatePricing = prices.data.some((x) => x.isSelected);
    return (
      <div className="flex-row justify-content-end mb-3 d-flex">
        {AuthenticationManager.isAuthorized(
          ResourceID.ServicePriceUpdateCreateButtonPage
        ) && (
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#UpdateModal"
            disabled={!enableUpdatePricing}
            onClick={() => this.setState({ showUpdateModal: true })}
          >
            Update Pricing
          </button>
        )}
      </div>
    );
  };

  onSelectAll = (e) => {
    const { prices } = this.state;
    const isSelectAll = e.target.checked;
    prices.data.forEach((price) => {
      price.isSelected = isSelectAll;
    });

    this.setState({ prices, isSelectAll });
  };

  onSelect = (e, item) => {
    const { prices } = this.state;
    item.isSelected = !item.isSelected;
    const isSelectAll = prices.data.every((x) => x.isSelected);
    this.setState({ prices, isSelectAll });
  };

  generateTableContent() {
    const { prices, isSelectAll } = this.state;
    if (!prices || _.isEmpty(prices.data)) {
      return null;
    }
    return (
      <>
        <thead>
          <tr>
            <th style={{ width: "38px" }}>
              <input
                type="checkbox"
                onChange={this.onSelectAll}
                checked={isSelectAll}
              />
            </th>
            <th>Service Code</th>
            <th>Service Name</th>
            <th>Ticket Type</th>
            <th>Latest Pricing</th>
            <th>Latest Effective Start</th>
          </tr>
        </thead>
        <tbody>
          {prices.data.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <input
                    type="checkbox"
                    value={item.isSelected}
                    checked={item.isSelected}
                    onChange={(e) => this.onSelect(e, item)}
                  />
                </td>
                <td>{item.serviceCode}</td>
                <td>{item.serviceName}</td>
                <td>{item.ticketType}</td>
                <td>${item.latestPricing.toFixed(6)}</td>
                <td>{item.latestEffectiveStart}</td>
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }

  injectSearchTerm(queryParameters, searchBy) {
    return super.injectSearchTerm(
      queryParameters,
      this.createSearchParameters()
    );
  }

  createSearchParameters() {
    let data = { ...this.state.searchCriteria };
    if (data.daySelection !== DaySelection.Custom) {
      delete data.startDay;
      delete data.endDay;
      delete data.startTime;
      delete data.endTime;
    }
    data.includes = "ServiceTickets,ServiceTickets.ServiceTicketPrices";
    return data;
  }

  onConsumeResponse = (response) => {
    const { prices, searchCriteria } = this.state;
    if (
      _.isNil(response) ||
      _.isNil(response.data) ||
      _.isEmpty(response.data)
    ) {
      this.setState({
        data: [],
        total: null,
      });
      toast.warning("Not found any service matches search criteria.");
      return;
    }
    super.onConsumeResponse(response);
    prices.handleResponse(response.data, searchCriteria.ticketType);
    this.setState({ prices });
  };
}
