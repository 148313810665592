import React, { Component } from "react";
import '../css/documentation.scss';

export class CreateKinoloungeHelp extends Component {
    render() {
        return (
            <div className="main-content">
                <div className="section__content section__content--p30 createContent">
                    <div className="container-fluid">
                        <h1 style={{ fontSize: '36px', marginTop: '30px' }}>How To Create Kinolounge</h1>
                        <p className="dateFormat" style={{ fontSize: '14px', color: 'grey' }}>18/11/2021 ▪ 4 minutes to read</p>
                        <br /><br />
                        <div class="webMgmt container-fluid">
                            <ol>
                                <li class="createList">
                                    Using the menu on the left, click on "Website Management", then click on the "Kinolounge" link. This will take you to the Kinolounge Listing Page.<br />
                                    <img src="/img/documentation-img/KinoImg/nav.png" class="userImage" />
                                </li>
                                <li class="createList">
                                    To create a kinolounge listing, click on “Create New Kinolounge Movie” in the top right corner of the Kinolounge Listing Page. <br />
                                    <img src="/img/documentation-img/KinoImg/list_create.png" class="userImage" />
                                </li>

                                <li class="createList">
                                    In Create Kinolounge Page, you will be able to see the following fields:
                                    <ul class="unorderedList">
                                        <li>
                                            Is Collection
                                        </li>
                                        <li>
                                            Movie ID (only when Is Collection is <strong>not</strong> checked)
                                        </li>
                                        <li>
                                            Movie Title (only when Is Collection is <strong>not</strong> checked)
                                        </li>
                                        <li>
                                            Display Start On
                                        </li>
                                        <li>
                                            Display End On
                                        </li>
                                        <li>
                                            Movie Runtime (only when Is Collection is <strong>not</strong> checked)
                                        </li>
                                        <li>
                                            Movie Rating (only when Is Collection is <strong>not</strong> checked)
                                        </li>
                                        <li>
                                            Display Weight
                                        </li>
                                        <li>
                                            Collection Description(Only shown when Is Collection is checked)
                                        </li>
                                        <li>
                                            Poster URL
                                        </li>
                                        <li>
                                            Movie URL (only when Is Collection is <strong>not</strong> checked)
                                        </li>
                                        <li>
                                            Collection URL (only shown when Is Collection is checked)
                                        </li>
                                        <li>
                                            Title (only shown when Is Collection is checked)
                                        </li>
                                    </ul>
                                    <div class="alert alert-primary" role="alert">
                                        <i class="fas fa-info-circle" aria-hidden="true"></i>
                                        <strong class="noteHeader">Information:</strong><br/><br/>
                                            - Display weight determines how the item will be displayed. The lower the display weight, the higher it will place on the website.<br />
                                            - Display Start On should always be earlier than Display End On.<br />
                                            - If Is Collection is unchecked, there will be no Title, Collection Description and Collection URL fields.<br />
                                            - Collection Description should be in HTML format.<br />
                                            - Collection Description should have a maximum of 250 characters
                                </div>
                            </li>
                                <li class="createList">
                                            To fetch movie data, type in your Movie ID and click on 'Fetch'.<br />
                                            <img src="/img/documentation-img/KinoImg/create_isNotCollection_Fetch.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To preview the poster image, click on 'Preview'.<br />
                                            <img src="/img/documentation-img/KinoImg/create_isNotCollection_preview.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To close the preview, click on the 'X' at the top right corner.<br />
                                            <img src="/img/documentation-img/KinoImg/preview_close.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To cancel all changes, click on the 'Cancel' button at the bottom right corner.<br />
                                            <img src="/img/documentation-img/KinoImg/create_cancel.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To confirm that you want to cancel all changes, click on 'Confirm'.<br />
                                            <img src="/img/documentation-img/KinoImg/create_cancel_confirm.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To create the kinolounge listing, click on “Create” at the bottom right corner of the page.<br />
                                            <img src="/img/documentation-img/KinoImg/create_create.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            To confirm that you would like to create the kinolounge listing, click on the 'Confirm' button.<br />
                                            <img src="/img/documentation-img/KinoImg/create_create_confirm.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            Upon successful creation, a confirmation message will appear at the bottom right of the screen.<br />
                                            <img src="/img/documentation-img/KinoImg/create_success.png" class="userImage" />
                                        </li>
                                <li class="createList">
                                            When you return to the kinolounge listing page, you will see the changes made.<br />
                                            <img src="/img/documentation-img/KinoImg/create_success_endpoint.png" class="userImage" />
                                        </li>
                            </ol>
                            <div className="text-right">
                                <a href="/documentation/kinolounge/ViewKinoloungeHelp" className="btn btn-primary">&laquo; View Kinolounge</a>&nbsp;
                                <a href="/documentation/kinolounge/EditKinoloungeHelp" className="btn btn-primary">Edit Kinolounge &raquo;</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        );
    }
}