import React from "react";
import RestClient from "../../../common/RestClient";
import { CardMode } from "../CardMode";
import { GeneralInformationCard } from "./cards/GeneralInformationCard";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { BreadCrumb } from "../../common/BreadCrumb";
import { toast } from "react-toastify";

export class ProductCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: CardMode.Create,
      generalInfo: null,
      generalInfoValidations: [],
      showCancelDialog: false,
      showConfirmDialog: false,
      showClearDialog: false
    };
  }

  isValidated() {
    const { generalInfoValidations } = this.state;
    return generalInfoValidations.every((val) => val.isValid);
  }

  onGeneralInfoModelChange = (model) => {
    this.setState({ generalInfo: model });
  };

  onGeneralInfoValidationsChange = (validations) => {
    this.setState({ generalInfoValidations: validations });
  };

  onHideCancelDialog = () => {
    this.setState({ showCancelDialog: false });
  };

  onHideConfirmDialog = () => {
    this.setState({ showConfirmDialog: false });
  };

  onHideClearDialog = () => {
    this.setState({ showClearDialog: false });
  }

  onProceedClearDialog = () => {
    this.setState({
      generalInfo: {},
      generalInfoValidations: [],
    });
    this.onHideClearDialog();
  }

  onProceedCancelDialog = () => {
    this.props.history.push("/products");
  };

  onShowCancelDialog = () => {
    this.setState({ showCancelDialog: true });
  };

  onShowConfirmDialog = () => {
    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
      return;
    }
    this.setState({ showConfirmDialog: true });
  };

  onShowClearDialog = () => {
    this.setState({ showClearDialog: true });
  }

  onProceedConfirmDialog = () => {
    if (!this.isValidated) {
      this.setState({ isSubmit: true, showConfirmDialog: false });
      return;
    }
    const { generalInfo } = this.state;
    const request = Object.assign(
      { },
      { ...generalInfo }
    );
    RestClient.sendPostRequest(
      "/api/v1/products/",
      request,
      () => {
        toast.success("Product has been created successfully");
        this.props.history.push("/products");
      },
      (error) => {
        toast.error(error.message);
      }
    );
    this.setState({
      showConfirmDialog: false,
    });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
      activeModule: new NavigationItem({
        identifier: "products",
        name: "Products",
      }),
    });
  };

  render() {
    const {
      cardMode,
      isSubmit,
      generalInfo,
      showCancelDialog,
      showConfirmDialog,
      showClearDialog
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <GeneralInformationCard
                  cardMode={cardMode}
                  defaultValue={generalInfo}
                  model={generalInfo}
                  isSubmit={isSubmit}
                  onModelChange={this.onGeneralInfoModelChange}
                  onValidationsChange={this.onGeneralInfoValidationsChange}
                />
              </div>
            </div>

            <div className="d-flex flex-row justify-content-end pb-3">
              <button
                className="btn btn-success mr-1"
                onClick={this.onShowConfirmDialog}
              >
                Create
              </button>
              <button
                className="btn btn-danger mr-1"
                onClick={this.onShowClearDialog}
              >
                Clear
              </button>
              <button
                className="btn btn-danger"
                onClick={this.onShowCancelDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {showCancelDialog && (
          <ConfirmDialog
            onCancel={this.onHideCancelDialog}
            onProceed={this.onProceedCancelDialog}
            message="Are you sure you want to cancel the creation of product?"
            title="Cancel Product creation"
          />
        )}
        {showConfirmDialog && (
          <ConfirmDialog
            onCancel={this.onHideConfirmDialog}
            onProceed={this.onProceedConfirmDialog}
            message="Are you sure you want to create product?"
            title="Create product"
          />
        )}
        {showClearDialog && (
          <ConfirmDialog
            onCancel={this.onHideClearDialog}
            onProceed={this.onProceedClearDialog}
            message="Are you sure you want to clear all information?"
            title="Confirm"
          />
        )}
      </div>
    );
  }
}
