import React from "react";
import { StringHelper } from "../../../../common/Helpers";
import { BaseListing } from "../../../common/BaseListing";
import RestClient from "../../../../common/RestClient";
import _ from "lodash";
import {
  NavigationItem,
  NavigationSettings,
} from "../../../common/NavigationSettings";
import { VoucherProgramType } from "../../../../common/Constants";

export class ExternalVoucherUsageListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      serialNumber: "",
      status: 0,
      voucherProgramId: 0,
      voucherPrograms: [],
    };
  }

  getApiPath = () => "/api/v1/voucher_usages/externals";

  getDefaultSort = () => "-UsedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "externals",
        name: "External Voucher Usage Listing",
      }),
    });
  };

  async componentDidMount() {
    await super.componentDidMount();
    this.getVoucherPrograms();
  }

  getVoucherPrograms = () => {
    RestClient.sendGetRequestWithParameters(
      "/api/v1/vouchers/program_dropdowns",
      { type: VoucherProgramType.External },
      ({ data }) => {
        this.setState({ voucherPrograms: data });
      },
      this.handleError
    );
  };

  onRowClick = (id) => {
    this.props.history.push(`external_usages/${id}`);
  };

  onEnter = async (e) => {
    if (e.keyCode === 13) {
      await this.search();
    }
  };

  injectSearchTerm(queryParameters, searchBy) {
    let { serialNumber, status, voucherProgramId } = this.state;
    Object.assign(queryParameters, { includes: "VoucherProgram" });
    return super.injectSearchTerm(queryParameters, {
      serialNumber,
      status,
      voucherProgramId:
        _.isNil(voucherProgramId) || _.isEmpty(voucherProgramId)
          ? 0
          : voucherProgramId,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateExtendedComponents() {
    const { voucherPrograms, serialNumber } = this.state;
    return (
      <>
        <div className="row form-group">
          <label className="form-control-label col-md-2">Search By:</label>
          <div className="col-md-4 col-xl-3">
            <input
              className="form-control SingleSN col"
              type="text"
              placeholder="Enter Single Serial Number"
              value={serialNumber}
              name="serialNumber"
              onChange={this.onInputChange}
              onKeyDown={this.onEnter}
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="form-control-label col-md-2">Voucher Program:</label>
          <div className="col-md-4 col-xl-3">
            <select
              className="form-control"
              name="voucherProgramId"
              onChange={this.onInputChange}
              required
            >
              <option value={0}>All Voucher</option>
              {voucherPrograms &&
                voucherPrograms.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-6 col-xl-5">
            <button
              className="btn btn-primary float-right"
              onClick={this.search}
            >
              <i className="fa fa-search" /> Search
            </button>
          </div>
        </div>
      </>
    );
  }

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th onClick={this.onColumnClick}>
              Serial Number{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="SerialNumber"
                onClick={this.toggleSort}
              />
            </th>
            <th>Voucher Program</th>
            <th onClick={this.onColumnClick}>
              Used On{" "}
              <i
                className="fas fa-sort-alpha-down"
                id="UsedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data?.length &&
            this.state.data.map((item) => {
              return (
                <tr key={item.id} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.serialNumber}</td>
                  <td>{item.voucherProgram?.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.usedOn
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  }
}
