import {
  PassDiscountProgram,
  PassDiscountType,
  PassProgramStatus,
  PassProgramType,
  PassRedemptionMode,
  PassStatus,
} from "../../../common/Constants";

export class ExtensionBy {
  static None = 0;
  static Date = 1;
  static Custom = 2;
}

export class ExtensionMode {
  static None = 0;
  static BySpecifiedDate = 1;
  static ByNumberOfDays = 2;
  static ByNumberOfMonths = 3;
  static ByNumberOfYears = 4;
}

export class SearchPassMode {
  static None = 0;
  static Single = 1;
  static Range = 2;
}

export class PassExtensionConstrain {
  static MaxSinglePassCode = 10;
}

export function formatPassProgramType(type) {
  const typeMapping = {
    [PassProgramType.None]: "None",
    [PassProgramType.External]: "External",
    [PassProgramType.Internal]: "Internal",
  };
  return typeMapping[type];
}

export function formatPassProgramStatus(statusCode) {
  const statusMappings = {
    [PassProgramStatus.Active]: "Active",
    [PassProgramStatus.Expired]: "Locked",
    [PassProgramStatus.Inactive]: "Deactivated",
  };
  return statusMappings[statusCode];
}

export function formatPassStatus(statusCode) {
  const statusMappings = {
    [PassStatus.Active]: "Active",
    [PassStatus.Expired]: "Expired",
    [PassStatus.Inactive]: "Deactivated",
  };
  return statusMappings[statusCode];
}

export function formatPassStatusClassName(statusCode) {
  const statusMappings = {
    [PassStatus.Active]: "color-green",
    [PassStatus.Expired]: "color-pink-red",
    [PassStatus.Inactive]: "color-pink-red",
  };
  return statusMappings[statusCode];
}

export function formatPassRedemptionMode(mode) {
  const statusMappings = {
    [PassRedemptionMode.NotSpecified]: "Invalid",
    [PassRedemptionMode.Counter]: "Counter",
    [PassRedemptionMode.Online]: "Online",
  };
  return statusMappings[mode];
}

export function formatPassDiscountType(type) {
  const typeMapping = {
    [PassDiscountType.PassComplimentary]: "Full",
    [PassDiscountType.PassPartial]: "Partial",
  };
  return typeMapping[type];
}

export function formatDiscountProgram(type) {
  const typeMapping = {
    [PassDiscountProgram.None]: "None",
    [PassDiscountProgram.StaffPass]: "Staff Pass",
  };
  return typeMapping[type];
}
