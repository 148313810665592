import React, { Component } from 'react';
import { HeaderMobileDocumentation } from "./HeaderMobileDocumentation";
import { MenuSidebarDocumentation } from "./MenuSidebarDocumentation";
import './css/main.css';
export class NavigationDocumentation extends Component {
    render() {
        return (
            <React.Fragment>
                <HeaderMobileDocumentation />
                <MenuSidebarDocumentation />
            </React.Fragment>
        )
    }
}