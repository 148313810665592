import React, {useState} from "react"
import _ from 'lodash' 

export const BookingRejectModal = (props) => {
    
    const [reason, setReason] = useState('');
    
    if(!props.visible) {
        return null;
    }
    
    return (
        <>
            <div className="modal fade show" id="CrewModal" role="dialog" aria-labelledby="exampleModalCenterTitle"
                 style={{display: "block", paddingRight: "17px"}}>
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Reject Booking Request</h5>
                            <button type="button" className="close" data-dismiss="modal" 
                                    onClick={props.onCancel} aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row form-group">
                                <div className="col">
                                    <label className="form-control-label" htmlFor="Reason">Reason for rejection:</label>
                                    <textarea 
                                        className="form-control" 
                                        id="Reason"      
                                        placeholder="Please enter reason"
                                        onChange={e => setReason(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-success" 
                                onClick={() => props.onProceed(reason)}
                                disabled={_.isEmpty(reason)}
                            >Submit</button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={props.onCancel}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"/>
        </>
    )
}