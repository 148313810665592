import React from "react";
import {
  DefaultDate,
  PassDiscountProgram,
  PassMembershipCardType,
} from "../../../../common/Constants";
import { DateHelper, StringHelper } from "../../../../common/Helpers";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { DisneyCardPreview } from "../../common/card-preview/DisneyCardPreview";
import { GenericCardPreview } from "../../common/card-preview/GenericCardPreview";
import { PremiereCardPreview } from "../../common/card-preview/PremiereCardPreview";
import { StaffCardPreview } from "../../common/card-preview/StaffCardPreview";
import { VipCardPreview } from "../../common/card-preview/VipCardPreview";
import { CardMode } from "../../common/CardMode";
import {
  formatDiscountProgram,
  formatPassStatus,
  formatPassStatusClassName,
} from "../../common/Constants";
import _ from "lodash";
import { RowFormField } from "../../../layout/FormLayout";

export class StaffDetailCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedPrograms: [],
      cardMode: CardMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      programOptions: [],
      validations: [],
    };
  }

  componentDidMount() {
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
    this.loadPassProgramOptions();
  }

  componentDidUpdate() {
    if (this.refreshCache) {
      this.refreshCache = false;
      const passProgramId = this.state.model.passProgramId;
      if (!StringHelper.isNullOrEmpty(passProgramId)) {
        this.loadPassProgramDetails([passProgramId]);
      }
    }
  }

  isCachedProgram = (id) => {
    return this.state.cachedPrograms.findIndex((x) => x.id === id) > -1;
  };

  getControlClassName = (fieldName, defaultClass) => {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      CardMode.isEditMode(this.state.cardMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  };

  getProgram = (passProgramId) => {
    const program = this.state.cachedPrograms.find(
      (prog) => prog.id === Number(passProgramId)
    );
    return (
      program || {
        code: "",
        labelPrint: "",
        isForStaff: false,
        name: "",
        membershipCardType: 0,
        prefix: "",
      }
    );
  };

  loadNextPassCode = (passProgramId) => {
    RestClient.sendGetRequest(
      `/api/v1/passes/new_pass_code?passProgramId=${passProgramId}`,
      (response) => {
        const newModel = Object.assign({}, this.state.model, {
          code: `${response.prefix}${response.serial}`,
        });
        this.setState({ isDirty: true, model: newModel }, this.onModelChange);
      }
    );
  };

  loadPassProgramDetails = (ids) => {
    const idStr = (ids || []).join(",");
    RestClient.sendGetRequest(
      `/api/v1/pass_programs/summary/?id=${idStr}`,
      (response) => {
        const { cachedPrograms } = this.state;
        response.data.forEach((item) => {
          if (cachedPrograms.findIndex((x) => x.id === item.id) === -1) {
            cachedPrograms.push(item);
          }
        });
        this.setState({ cachedPrograms }, () =>
          this.turnOffStaffEmailValidation(ids[0])
        );
      }
    );
  };

  loadPassProgramOptions = () => {
    const staffOnly = this.props.staffOnly || false;
    RestClient.sendGetRequestWithParameters(
      "/api/v1/pass_programs/options",
      { staffOnly },
      (response) => {
        this.setState({ programOptions: response });
      }
    );
  };

  mergeDefaultValue = (defaultValue) => {
    const value = defaultValue || {};
    return {
      code: value.code || "",
      expiryDateStr: value.expiryDate || "",
      isForStaff: value.isForStaff || false,
      name: value.name || "",
      passProgramId: value.passProgramId || "",
      staffEmail: value.staffEmail || "",
      startDateStr: value.startDate || "",
      status: value.status || 0,
      discountProgram: value.discountProgram || 0,
    };
  };

  onModelChange = () => {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  };

  onProgramTypeChange = (e) => {
    this.onTextBoxChange(e);
    const programId = e.target.value;
    if (!programId) {
      return;
    }
    this.loadNextPassCode(programId);
    if (!this.isCachedProgram(programId)) {
      this.loadPassProgramDetails([programId]);
    } else {
      this.turnOffStaffEmailValidation(programId);
    }
  };

  turnOffStaffEmailValidation = (programId) => {
    const { cachedPrograms, validations } = this.state;
    const program = cachedPrograms.find((program) => program.id === +programId);
    if (program && !program.isForStaff) {
      this.setState({
        validations: validations.filter(
          (field) => field.fieldName !== "staffEmail"
        ),
      });
    }
  };

  onStartDateChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    let expiryDateStr = "";

    if (!_.isNaN(value)) {
      expiryDateStr = DefaultDate.getExpiryDateDefault(value);
    }

    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
      expiryDateStr: expiryDateStr || "",
    });

    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value =
      fieldName === "discountProgram" ? Number(e.target.value) : e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      this.refreshCache = true;
      newState = Object.assign(
        {},
        {
          isDirty: false,
          model,
        }
      );
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  render() {
    const renderMethods = {
      [CardMode.View]: this.renderViewMode.bind(this),
      [CardMode.Create]: this.renderCreateMode.bind(this),
      [CardMode.Update]: this.renderEditMode.bind(this),
    };
    return (
      <Card title="Pass Details">{renderMethods[this.state.cardMode]()}</Card>
    );
  }

  renderViewMode() {
    const { model } = this.state;
    const {
      code,
      expiryDateStr,
      name,
      passProgramId,
      staffEmail,
      startDateStr,
      status,
      discountProgram,
    } = model;
    const program = this.getProgram(passProgramId);
    return (
      <div className="row">
        <div className="col-xl-6">
          <RowFormField label="Pass Code:" htmlFor="PassCode">
            <input
              className="form-control display-input"
              id="PassCode"
              type="text"
              placeholder="Enter Pass Code"
              readOnly={true}
              value={code}
            />
          </RowFormField>
          <RowFormField label="Name:" htmlFor="Name">
            <input
              className="form-control display-input"
              id="Name"
              type="text"
              placeholder="Enter Name"
              readOnly={true}
              value={name}
            />
          </RowFormField>
          <RowFormField label="Start Date:" htmlFor="StartDate">
            <input
              className="form-control display-input"
              id="StartDate"
              placeholder={DateHelper.DisplayDatePlaceholder}
              readOnly={true}
              value={StringHelper.asDateStringToDisplayDateFormat(startDateStr)}
            />
          </RowFormField>
          <RowFormField label="Expiry Date:" htmlFor="ExpiryDate">
            <input
              className="form-control display-input"
              id="ExpiryDate"
              placeholder={DateHelper.DisplayDatePlaceholder}
              readOnly={true}
              value={StringHelper.asDateStringToDisplayDateFormat(
                expiryDateStr
              )}
            />
          </RowFormField>
          {model.isForStaff && (
            <RowFormField label="Staff Email:" htmlFor="Email">
              <input
                className="form-control display-input"
                id="Email"
                type="email"
                placeholder="Enter Email"
                readOnly={true}
                value={staffEmail}
              />
            </RowFormField>
          )}
          <RowFormField label="Status:" htmlFor="Status">
            <input
              className={`form-control display-input ${formatPassStatusClassName(
                status
              )}`}
              id="Status"
              type="text"
              readOnly={true}
              value={formatPassStatus(status)}
            />
          </RowFormField>
          <RowFormField label="Program Type:" htmlFor="ProgID">
            <input
              className="form-control display-input"
              id="ProgramType"
              type="text"
              placeholder="Enter ID"
              readOnly={true}
              value={program.name}
            />
          </RowFormField>
          <RowFormField label="Discount Program:" htmlFor="DiscountProgram">
            <input
              className="form-control display-input"
              id="DiscountProgram"
              type="text"
              readOnly={true}
              value={formatDiscountProgram(discountProgram)}
            />
          </RowFormField>
        </div>
        <div className="offset-2 col-xl-6">
          {this.renderMembershipCardPreview(model, program)}
        </div>
      </div>
    );
  }

  renderCreateMode() {
    const { isSubmit } = this.props;
    const { model, programOptions } = this.state;
    const {
      code,
      expiryDateStr,
      name,
      passProgramId,
      staffEmail,
      startDateStr,
      discountProgram,
    } = model;
    const program = this.getProgram(passProgramId);
    return (
      <React.Fragment>
        <div className="details row">
          <div className="col-xl-6">
            <RowFormField
              label={
                <>
                  Program Type<span className="color-red">*</span>:
                </>
              }
              htmlFor="Type"
            >
              <select
                id="Type"
                className={this.getControlClassName("passProgramId")}
                fieldname="passProgramId"
                onChange={this.onProgramTypeChange}
                value={passProgramId}
              >
                <option value="">Select Program Type</option>
                {programOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="passProgramId"
                isEnabled={isSubmit}
                property="Pass Program Type"
                value={passProgramId}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Pass Code<span className="color-red">*</span>:
                </span>
              }
              htmlFor="PassCode"
            >
              <input
                type="text"
                id="PassCode"
                className={this.getControlClassName("code")}
                fieldname="code"
                placeholder="Enter Pass Code"
                onChange={this.onTextBoxChange}
                value={code}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="code"
                isEnabled={isSubmit}
                property="Pass Code"
                value={code}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Name<span className="color-red">*</span>:
                </span>
              }
              htmlFor="Name"
            >
              <input
                type="text"
                id="Name"
                placeholder="Enter Name"
                className={this.getControlClassName("name")}
                fieldname="name"
                onChange={this.onTextBoxChange}
                value={name}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="name"
                isEnabled={isSubmit}
                property="Name"
                value={name}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Start Date<span className="color-red">*</span>:
                </span>
              }
              htmlFor="StartDate"
            >
              <input
                type="date"
                id="StartDate"
                className={this.getControlClassName("startDateStr")}
                fieldname="startDateStr"
                onChange={this.onStartDateChange}
                value={startDateStr}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="startDateStr"
                isEnabled={isSubmit}
                property="Start Date"
                value={startDateStr}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Expiry Date:
                </span>
              }
              htmlFor="ExpiryDate"
            >
              <input
                type="date"
                id="ExpiryDate"
                className={this.getControlClassName("expiryDateStr")}
                fieldname="expiryDateStr"
                onChange={this.onTextBoxChange}
                value={expiryDateStr}
              />
              <Validators.DateTimeFromToValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="expiryDateStr"
                isEnabled={isSubmit}
                fromProperty="New Expiry Date"
                fromValue={startDateStr}
                toProperty="Start Date"
                toValue={expiryDateStr}
              />
            </RowFormField>
            {program.isForStaff && (
              <RowFormField
                label={
                  <span>
                    Staff Email<span className="color-red">*</span>:
                  </span>
                }
                htmlFor="Email"
              >
                <input
                  placeholder="Enter Staff Email"
                  type="email"
                  id="Email"
                  className={this.getControlClassName("staffEmail")}
                  fieldname="staffEmail"
                  onChange={this.onTextBoxChange}
                  value={staffEmail}
                />
                <Validators.RequiredValidator
                  onValidationStatusChange={this.onValidationStatusChange}
                  fieldName="staffEmail"
                  isEnabled={isSubmit}
                  property="Staff Email"
                  value={expiryDateStr}
                />
              </RowFormField>
            )}
            <RowFormField label="Discount Program:" htmlFor="DiscountProgram">
              <select
                id="Type"
                className={this.getControlClassName("discountProgram")}
                fieldname="discountProgram"
                value={discountProgram}
                onChange={this.onTextBoxChange}
              >
                <option
                  key={PassDiscountProgram.None}
                  value={PassDiscountProgram.None}
                >
                  Select Program Type
                </option>
                <option
                  key={PassDiscountProgram.StaffPass}
                  value={PassDiscountProgram.StaffPass}
                >
                  {formatDiscountProgram(PassDiscountProgram.StaffPass)}
                </option>
              </select>
            </RowFormField>
          </div>
          <div className="col-xl-6">
            {this.renderMembershipCardPreview(model, program)}
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEditMode() {
    const { isSubmit } = this.props;
    const { model, programOptions } = this.state;
    const {
      code,
      expiryDateStr,
      name,
      passProgramId,
      staffEmail,
      startDateStr,
      status,
      discountProgram,
    } = model;
    const program = this.getProgram(passProgramId);
    return (
      <div className="card-body details row">
        <div className="col-xl-6">
          <RowFormField label="Pass Code:" htmlFor="PassCode">
            <input
              className="form-control display-input"
              id="PassCode"
              type="text"
              placeholder="Enter Pass Code"
              readOnly={true}
              value={code}
            />
          </RowFormField>
          <RowFormField label="Program Type:" htmlFor="ProgID">
            <select
              id="Type"
              className={this.getControlClassName("passProgramId")}
              fieldname="passProgramId"
              onChange={this.onProgramTypeChange}
              value={passProgramId}
            >
              <option value="">Select Program Type</option>
              {programOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="passProgramId"
              isEnabled={isSubmit}
              property="Pass Program Type"
              value={passProgramId}
            />
          </RowFormField>
          <RowFormField label="Name:" htmlFor="Name">
            <input
              id="Name"
              type="text"
              placeholder="Enter Name"
              className={this.getControlClassName("name")}
              fieldname="name"
              onChange={this.onTextBoxChange}
              value={name}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="name"
              isEnabled={isSubmit}
              property="Name"
              value={name}
            />
          </RowFormField>
          <RowFormField label="Start Date:" htmlFor="StartDate">
            <input
              id="StartDate"
              type="date"
              className={this.getControlClassName("startDateStr")}
              fieldname="startDateStr"
              onChange={this.onStartDateChange}
              value={StringHelper.asDateStringToIsoDateFormat(startDateStr)}
            />
            <Validators.RequiredValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="startDateStr"
              isEnabled={isSubmit}
              property="Start Date"
              value={startDateStr}
            />
          </RowFormField>
          <RowFormField label="Expiry Date:" htmlFor="ExpiryDate">
            <input
              id="ExpiryDate"
              type="date"
              className={this.getControlClassName("expiryDateStr")}
              fieldname="expiryDateStr"
              onChange={this.onTextBoxChange}
              value={StringHelper.asDateStringToIsoDateFormat(expiryDateStr)}
            />
            <Validators.DateTimeFromToValidator
              onValidationStatusChange={this.onValidationStatusChange}
              fieldName="expiryDateStr"
              isEnabled={isSubmit}
              fromProperty="New Expiry Date"
              fromValue={startDateStr}
              toProperty="Start Date"
              toValue={expiryDateStr}
            />
          </RowFormField>
          {program.isForStaff && (
            <RowFormField label="Staff Email:" htmlFor="Email">
              <input
                className="form-control display-input"
                id="Email"
                type="email"
                fieldName="staffEmail"
                placeholder="Enter Email"
                onChange={this.onTextBoxChange}
                value={staffEmail}
              />
            </RowFormField>
          )}
          <RowFormField label="Status:" htmlFor="Status">
            <input
              className={`form-control display-input ${formatPassStatusClassName(
                status
              )}`}
              id="Status"
              type="text"
              readOnly={true}
              value={formatPassStatus(status)}
            />
          </RowFormField>
          <RowFormField label="Discount Program:" htmlFor="DiscountProgram">
            <select
              id="Type"
              className={this.getControlClassName("discountProgram")}
              fieldname="discountProgram"
              value={discountProgram}
              onChange={this.onTextBoxChange}
            >
              <option
                key={PassDiscountProgram.None}
                value={PassDiscountProgram.None}
              >
                Select Program Type
              </option>
              <option
                key={PassDiscountProgram.StaffPass}
                value={PassDiscountProgram.StaffPass}
              >
                {formatDiscountProgram(PassDiscountProgram.StaffPass)}
              </option>
            </select>
          </RowFormField>
        </div>
        <div className="col-xl-4">
          {this.renderMembershipCardPreview(model, program)}
        </div>
      </div>
    );
  }

  renderMembershipCardPreview(pass, program) {
    return (
      <React.Fragment>
        {program.membershipCardType === PassMembershipCardType.Disney && (
          <DisneyCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Generic && (
          <GenericCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Premiere && (
          <PremiereCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Staff && (
          <StaffCardPreview program={program} pass={pass} />
        )}
        {program.membershipCardType === PassMembershipCardType.Vip && (
          <VipCardPreview program={program} pass={pass} />
        )}
      </React.Fragment>
    );
  }
}
