import React from "react";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import {VoucherProgramType} from "../../../common/Constants";

export default class VoucherProgramListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      name: "",
    };
  }

  getApiPath = () => "/api/v1/vouchers/programs";

  getDefaultSort = () => "-CreatedOn";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "vouchers",
        name: "Vouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "programs",
        name: "Programs",
      }),
    });
  };

  injectSearchTerm(queryParameters, searchBy) {
    const name = this.state.name || "";
    return super.injectSearchTerm(queryParameters, { name });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  generateExtendedComponents() {
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-end mb-2">
          <div className="col-md-3 px-0">
            <input
              className="form-control search"
              name="name"
              id="SearchTxt"
              type="Text"
              onChange={this.onInputChange}
              onKeyDown={this.onKeyDownSearchInput}
              value={this.state.name}
            />
          </div>
          <button className="btn btn-primary search" onClick={this.search}>
            <i className="fas fa-search" /> Search
          </button>
        </div>
      </React.Fragment>
    );
  }

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Code</th>
            <th>Name</th>
            <th>Type</th>
            <th>Program Start</th>
            <th>Program End</th>
            <th>
              Created On{" "}
              <i
                id="CreatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody id="data">
          {this.state.data &&
            this.state.data.map((item) => (
              <tr key={item.id} onClick={(e) => this.onRowClick(item.id)}>
                <td>{item.code}</td>
                <td>{item.name}</td>
                <td>{VoucherProgramType.getName(item.type)}</td>
                <td className="datetime">
                  {StringHelper.asDateStringToDefaultFormat(
                    item.effectiveStart
                  )}
                </td>
                <td className="datetime">
                  {StringHelper.asDateStringToDefaultFormat(item.effectiveEnd)}
                </td>
                <td className="datetime">
                  {StringHelper.asDateStringToDefaultFormat(item.createdOn)}
                </td>
              </tr>
            ))}
        </tbody>
      </React.Fragment>
    );
  }
}
