import React from "react";
import { ImageHelper } from "../../../../common/Helpers";
import "./FilmFestivalPreview.scss";
import { ProgrammeCardPreview } from "./ProgrammeCardPreview";
export class FilmFestivalPreview extends React.Component {
  render() {
    const { festival, programmeInfo } = this.props;
    return (
      <div className="main-content film-festival-content">
        <div className="container m-lg-3">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="content-wrap p-d row">
              <div className="col-md-4 col-xs-12">
                <div className="hidden-title">
                  <h2 title={festival.name}>{festival.name}</h2>
                </div>
                <div className="block-img">
                  <a className="image-overlay-box cboxElement" href={ImageHelper.getPosterUrl(festival.poster)}>
                    <img src={festival.poster} alt={festival.name} onError={ImageHelper.noImageUrl} className="img-responsive gm-loaded gm-observing gm-observing-cb" />
                  </a>
                </div>
              </div>
              <div className="col-md-8 col-xs-12">
                <div className="block-tablet-desktop show-for-desktop-only">
                  <h2 title={festival.name}>{festival.name}</h2>
                </div>
                <div className="col-right">
                  <div className="">
                    <div>Code: {festival.code}</div>
                    <div className="desc"
                      style={{ "word-break": "break-word" }}
                      dangerouslySetInnerHTML={{ __html: festival.description }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {programmeInfo && programmeInfo.map((programme, index) => (
            <ProgrammeCardPreview programmes={programme} key={index} />
          ))}
        </div>
      </div>
    );
  }
}
