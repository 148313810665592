import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ReportRegister } from "../../../models/ReportRegister";
import { InputForm } from "./InputForm";
import { useReportInputFormSchema } from "./useReportInputFormSchema";
import { RestService } from "../../../services/RestService";
import { isEmpty, isNull } from "../../../common/Common";
import { CustomSelect } from "../../common/CustomSelect";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

class ReportFormat {
  static PDF = "PDF";
  static EXCEL = "EXC";
  static All = [
    {
      display: "Pdf",
      value: this.PDF,
    },
    {
      display: "Excel",
      value: this.EXCEL,
    },
  ];
}

export const ReportRegisterDetailModal = (props) => {
  const [parameters, setParameters] = useState({});
  const [report, setReport] = useState({});
  const [format, setFormat] = useState(ReportFormat.PDF);
  const { id, onCancel } = props;

  const schemas = useReportInputFormSchema(report);
  const reportRegisterService = useMemo(
    () => new RestService("/api/v1/report_registers"),
    []
  );
  const agentRequestService = useMemo(
    () => new RestService("/api/v1/request_reports"),
    []
  );

  useEffect(() => {
    reportRegisterService
      .get(id, {
        includes:
          "ReportParameterRegisters,ReportParameterRegisters.ReportParameterValues",
      })
      .then((response) => {
        setReport(new ReportRegister(response.data));
      })
      .catch((error) => toast.error(error.message));
  }, [reportRegisterService, id]);

  const onInputFormChange = (data) => {
    setParameters(data);
  };

  const validateMandatoryParameters = () => {
    const { reportParameterRegisters } = report;
    const mandatoryParameters = reportParameterRegisters.filter(
      (x) => x.isMandatory
    );
    if (isEmpty(mandatoryParameters)) {
      return true;
    }
    for (const mandatoryParameter of mandatoryParameters) {
      if (!(mandatoryParameter.reportParameterCode in parameters)) {
        toast.error(
          `The parameter '${mandatoryParameter.reportParameterName}' is required.`
        );
        return false;
      }

      const value = parameters[mandatoryParameter.reportParameterCode];
      if (isNull(value) || isEmpty(value)) {
        toast.error(
          `The value of parameter '${mandatoryParameter.reportParameterName}' is required.`
        );
        return false;
      }
    }
    return true;
  };

  const request = () => {
    if (!validateMandatoryParameters()) {
      return;
    }

    agentRequestService
      .post({
        reportRegisterId: id,
        parameters,
        format,
      })
      .then((response) => {
        if (response) {
          toast.success("The report has been requested successful.");
          onCancel();
        }
      });
  };

  return (
    <>
      <div
        id={id}
        className="modal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Report Details</h5>
              <button
                type="button"
                className="close"
                aria-label="Close"
                data-bs-dismiss="modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ overflowY: "scroll", minHeight: "60vh" }}>
              <div className="container-fluid">
                <div className="row form-group">
                  <label className="col form-control-label text-nowrap">
                    Report Name:
                  </label>
                  <div className="col-12">
                    <input
                      className="form-control display-input"
                      readOnly={true}
                      value={report?.reportName ?? ""}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <label className="col form-control-label text-nowrap">
                    Format:
                  </label>
                  <div className="col-12">
                    <CustomSelect
                      data={ReportFormat.All}
                      label="display"
                      value="value"
                      selectedValue={format}
                      compareFunc={(x, y) => 1}
                      onChange={(e) => setFormat(e.value)}
                    />
                  </div>
                </div>
                <InputForm schemas={schemas} onChange={onInputFormChange} />
              </div>
            </div>
            <div className="modal-footer d-block">
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  type="button"
                  className="btn btn-primary"
                  onClick={request}
                >
                  <i className="fas fa-chart-bar" aria-hidden="true" /> Request
                  Report
                </ButtonActionDetailGroup.Item>
                <ButtonActionDetailGroup.Item
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
