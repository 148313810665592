export class PaymentEvoucher {
	constructor(data) {
		this.id = data?.id ?? 0;
		this.transactionId = data?.transactionId ?? 0;
		this.amountCollect = data?.amountCollect ?? 0;
		this.eVoucherProgramId = data?.eVoucherProgramId ?? 0;
		this.eVoucherCode = data?.eVoucherCode ?? 0;
		this.orderTicketId = data?.orderTicketId ?? 0;
		this.eVoucherProgram = null;
	}
}