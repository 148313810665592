import React from "react";
import { EmailPreview } from "./EmailPreview";
import RestClient from "../../../../common/RestClient";
import { DateHelper, StringHelper } from "../../../../common/Helpers";
import _ from "lodash";
import { EmailRecipient } from "../models/EmailRecipient";
import { ConfirmDialog } from "../../../common/ConfirmDialog";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import {
  NavigationItem,
  NavigationSettings,
} from "../../../common/NavigationSettings";
import { BreadCrumb } from "../../../common/BreadCrumb";
import { toast } from "react-toastify";
import { HistoryMessageTypes, ObjectType } from "../../../../common/Constants";
import { TicketDistributionType } from "../../../../constants/CorporateBookingConstants";
import Validators from "../../../common/Validators";
import { ColumnFormField } from "../../../layout/FormLayout";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import { Card } from "../../../common/Card";
import { CustomSelect } from "../../../common/CustomSelect";

export class ETicketDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      corporateBooking: null,
      performance: null,
      uploadedFileName: "",
      recipients: [],
      availableSeats: [],
      tickets: [],
      invalidSeatCodes: [],
      draftRecipient: new EmailRecipient(),
      emailSubject: "",
      resendWarningMessage: "",
      sendMailMessage: "",
      showEmailPreview: false,
      showEmailSendingConfirmation: false,
      showEmailResendConfirmation: false,
      showAddRecipientConfirmation: false,
      isSending: false,
      isSubmit: false,
      isValidSendMail: false,
      validations: [],
    };
    this.fileUploadInput = React.createRef();

    this.personalContactColumns = {
      Name: "A",
      Email: "B",
      PhoneNumber: "C",
    };
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "distribute-eticket",
        name: "Distribute E-Ticket",
      }),
    });
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    RestClient.sendGetRequest(
      `/api/v1/corporate_bookings/${id}`,
      (corporateBooking) => {
        const availableSeats = (corporateBooking.seatCodes ?? []).map(
          (x, index) => {
            return {
              id: index,
              name: x.trim().toUpperCase(),
            };
          }
        );
        this.setState({
          corporateBooking,
          availableSeats,
          emailSubject: corporateBooking.bookingReference,
        });
        if (corporateBooking.performanceId <= 0) {
          return;
        }
        RestClient.sendGetRequest(
          `/api/v1/performances/${corporateBooking.performanceId}?includes=Location`,
          (performance) => {
            this.setState({ performance });
          },
          this.handleError
        );
      },
      this.handleError
    );

    RestClient.sendGetRequestWithParameters(
      `/api/v1/corporate_booking_print_and_distribute_tickets`,
      {
        maxResults: 9999,
        corporateBookingId: id,
      },
      (response) => {
        this.setState({ tickets: response.data });
      },
      this.handleError
    );
  }

  onSendEmail = () => {
    this.closeAllModals();

    const { id } = this.props.match.params;
    let { emailSubject, recipients, tickets } = this.state;

    recipients = this.getUniqueRecipients(recipients);

    const eTickets = recipients.map((recipient) => {
      return {
        emailSubject: emailSubject,
        patronName: recipient.patronName,
        emailAddress: recipient.emailAddress,
        phoneNumber: recipient.phoneNumber,
        seatCodes: recipient.seatCodes.map((x) => x.name),
      };
    });

    RestClient.sendPostRequest(
      "/api/v1/corporate_booking_print_and_distribute_tickets/etickets",
      {
        corporateBookingId: +id,
        eTickets,
      },
      (response) => {
        response.forEach((item) => {
          const existingTicket = tickets.find((x) => x.id === item.id);
          if (existingTicket) {
            tickets = tickets.filter((x) => x.id !== existingTicket.id);
          } else {
            tickets.push(item);
          }
        });

        this.setState({ tickets, recipients });

        this.generateHistoryLog(
          id,
          ObjectType.CorporateBooking,
          HistoryMessageTypes.DistributeTicket
        );
      },
      this.handleError
    );
  };

  onValidateEmail = () => {
    const { recipients } = this.state;
    const uniqueRecipients = this.getUniqueRecipients(recipients);

    if (!this.isValidated()) {
      this.setState({ isSubmit: true });
    } else if (recipients.length <= 0) {
      this.setState({
        isValidSendMail: false,
        sendMailMessage: "Please add recipient to send email",
        showEmailResendConfirmation: false,
      });
    } else if (recipients.length > uniqueRecipients.length) {
      this.setState({
        isValidSendMail: false,
        sendMailMessage:
          "The recipients contain duplicate email addresses and seat codes",
        showEmailResendConfirmation: false,
      });
    } else {
      this.setState({
        isValidSendMail: true,
        sendMailMessage:
          "Validation is successful. You may now send the emails.",
        showEmailResendConfirmation: false,
      });
    }
  };

  handleError = (error) => {
    toast.error(error.message);
  };

  getUniqueRecipients = () => {
    let { recipients } = this.state;

    return Object.values(
      recipients.reduce((a, c) => {
        a[c.emailAddress + "|" + c.seatCodes] = c;
        return a;
      }, {})
    );
  };

  generateHistoryLog(objectId, objectType, messageType) {
    const request = Object.assign({
      objectId: objectId,
      objectType: objectType,
      messageId: messageType,
    });
    RestClient.sendPostRequest(`/api/v1/history_logs`, request);
  }

  closeAllModals = () => {
    this.setState({
      showEmailPreview: false,
      showEmailSendingConfirmation: false,
      showAddRecipientConfirmation: false,
    });
  };

  onAddRecipient = () => {
    let { draftRecipient, tickets } = this.state;
    if (!draftRecipient.isValid()) {
      return;
    }

    const sentSeatCodes = [
      ...new Set(
        tickets
          .filter(
            (x) => x.ticketDistribution === TicketDistributionType.ETickets
          )
          .map((x) => x.seatCodes)
          .flat()
      ),
    ];

    const reSendSeatCodes = sentSeatCodes.filter(
      (x) => draftRecipient.seatCodes.map((x) => x.name).indexOf(x) >= 0
    );

    if (reSendSeatCodes && reSendSeatCodes.length > 0) {
      const sentItems = reSendSeatCodes.map((x) => x).join(", ");
      const resendWarningMessage = `The seat codes ${sentItems} has been sent already. Please confirm to send an email with seat code again`;
      this.setState({
        resendWarningMessage,
        showAddRecipientConfirmation: true,
      });
      return;
    }
    this.addRecipient();
  };

  addSeatCodesToRecipient = (
    recipient,
    ws,
    index,
    seatColumns,
    availableSeats
  ) => {
    const newInvalidSeatCodes = [];
    let remainingAvailableSeats = availableSeats;
    for (const seatColumn of seatColumns) {
      const cell = ws[`${seatColumn}${index}`];
      if (!cell) {
        continue;
      }
      const seatCode = cell["v"];
      if (!seatCode) {
        continue;
      }
      const foundAvailableSeat = remainingAvailableSeats.find(
        (x) => x.name === seatCode
      );
      if (foundAvailableSeat) {
        recipient.seatCodes.push(foundAvailableSeat);
        remainingAvailableSeats = remainingAvailableSeats.filter(
          (x) => x !== foundAvailableSeat
        );
      } else {
        newInvalidSeatCodes.push(seatCode);
      }
    }
    return {
      newInvalidSeatCodes,
      remainingAvailableSeats,
    };
  };

  getWorksheet = (event) => {
    const binaryData = event.target.result;
    const wb = XLSX.read(binaryData, { type: "binary" });
    const sheet = wb.SheetNames[0];
    return wb.Sheets[sheet];
  };

  getColumnsAndMaxRowIndex = (worksheet) => {
    const keys = _.keys(worksheet).filter((x) => !x.startsWith("!"));
    let columns = [];
    const indexes = [];
    keys.forEach((x) => {
      const row = +x.match(/\d+/)[0];
      const column = x.replace(row, "");
      indexes.push(row);
      columns.push(column);
    });
    return {
      columns: _.uniqBy(columns, (x) => x),
      maxRowIndex: _.max(indexes),
    };
  };

  onUploadExcelFile = (e) => {
    const files = e.target.files;
    const file = files[0];
    if (_.isEmpty(files)) {
      return;
    }
    this.setState({ uploadedFileName: file.name });
    const reader = new FileReader();
    reader.onload = (event) => {
      let { availableSeats, recipients } = this.state;
      const worksheet = this.getWorksheet(event);
      let { columns, maxRowIndex } = this.getColumnsAndMaxRowIndex(worksheet);
      const personalColumns = _.values(this.personalContactColumns);
      const seatColumns = columns.filter((x) => personalColumns.indexOf(x) < 0);
      let invalidSeatCodes = [];
      const invalidEmails = [];
      const invalidPhoneNumber = [];
      for (let index = 2; index <= maxRowIndex; index++) {
        const patronName =
          worksheet[`${this.personalContactColumns.Name}${index}`]["v"];
        const emailAddress =
          worksheet[`${this.personalContactColumns.Email}${index}`]["v"];
        if (!StringHelper.isEmail(emailAddress)) {
          invalidEmails.push(emailAddress);
          continue;
        }
        const phoneNumber =
          worksheet[`${this.personalContactColumns.PhoneNumber}${index}`]["v"];
        if (!StringHelper.toNumber(phoneNumber)) {
          invalidPhoneNumber.push(phoneNumber);
          continue;
        }
        const recipient = new EmailRecipient({
          patronName,
          emailAddress,
          phoneNumber: `${phoneNumber}`,
        });
        const { newInvalidSeatCodes, remainingAvailableSeats } =
          this.addSeatCodesToRecipient(
            recipient,
            worksheet,
            index,
            seatColumns,
            availableSeats,
            invalidSeatCodes
          );
        invalidSeatCodes = invalidSeatCodes.concat(newInvalidSeatCodes);
        availableSeats = remainingAvailableSeats;
        if (_.isEmpty(recipient.seatCodes) || !_.isEmpty(invalidSeatCodes)) {
          continue;
        }
        if (_.isEmpty(recipient.emailAddress) || !_.isEmpty(invalidEmails)) {
          continue;
        }
        if (
          _.isEmpty(recipient.phoneNumber) ||
          !_.isEmpty(invalidPhoneNumber)
        ) {
          continue;
        }
        recipient.id = recipients.length + 1;
        recipients.push(_.cloneDeep(recipient));
      }

      if (
        _.isEmpty(invalidSeatCodes) &&
        _.isEmpty(invalidEmails) &&
        _.isEmpty(invalidPhoneNumber)
      ) {
        this.setState({
          recipients,
          availableSeats,
        });
      }
      const warning = this.makeAlert(
        invalidSeatCodes,
        invalidEmails,
        invalidPhoneNumber
      );
      if (warning) {
        toast.warning(warning);
      }
    };
    reader.readAsBinaryString(file);
  };

  makeAlert = (invalidSeatCodes, invalidEmails, invalidPhoneNumber) => {
    if (!_.isEmpty(invalidEmails)) {
      return `The email address ${invalidEmails.join(", ")} are invalid.`;
    }

    if (!_.isEmpty(invalidSeatCodes)) {
      return `The seat code ${invalidSeatCodes.join(", ")} are invalid.`;
    }

    if (!_.isEmpty(invalidPhoneNumber)) {
      return `The phone number ${invalidPhoneNumber.join(", ")} are invalid.`;
    }

    return null;
  };

  downloadTemplate = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet([
      { Name: "", Email: "", Phone: "", Seat1: "", Seat2: "" },
    ]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "ETicket_Distribution_Template.xlsx");
  };

  addRecipient = () => {
    let { availableSeats, draftRecipient, recipients } = this.state;
    draftRecipient.id = recipients.length + 1;
    recipients.push(_.cloneDeep(draftRecipient));
    const addedItems = draftRecipient.seatCodes.map((x) => x.id);

    this.setState({
      draftRecipient: new EmailRecipient(),
      recipients,
      availableSeats: availableSeats.filter(
        (x) => addedItems.indexOf(x.id) < 0
      ),
    });
    this.closeAllModals();
  };

  onRecipientChange = (e) => {
    const { draftRecipient } = this.state;
    const fieldName = e.target.getAttribute("name");
    draftRecipient[fieldName] = e.target.value;
    this.setState({ draftRecipient });
  };

  onRemoveRecipient = (item) => {
    let { recipients, availableSeats } = this.state;
    availableSeats = availableSeats.concat(item.seatCodes);
    recipients = recipients.filter((x) => x.id !== item.id);
    this.setState({
      recipients,
      availableSeats: _.sortBy(availableSeats, (x) => x.id),
    });
  };

  onMultipleSelectorSelect = (source) => {
    const { draftRecipient } = this.state;
    draftRecipient.seatCodes = source.map(({ label, value }) => ({
      name: label,
      id: value,
    }));
    this.setState({ draftRecipient });
  };

  onResend = (recipient) => {
    let { tickets } = this.state;
    const found = tickets.find((x) => x === recipient);
    found.isSending = true;
    RestClient.sendPostRequest(
      `/api/v1/corporate_booking_print_and_distribute_tickets/${recipient.id}/send_email`,
      {},
      (response) => {
        found.count = response.count;
        found.processedOn = response.processedOn;
        found.isSending = false;
        this.setState({ tickets });
      },
      (error) => {
        this.handleError(error);
        found.isSending = false;
        this.setState({ tickets });
      }
    );
  };

  isValidated() {
    const { validations } = this.state;
    return validations.every((val) => val.isValid);
  }

  getControlClassName(fieldName) {
    const isError = this.state.validations.some(
      (val) => val.fieldName === fieldName && !val.isValid
    );
    return this.state.isSubmit && isError
      ? "form-control is-invalid"
      : "form-control";
  }

  onValidationStatusChange = (e) => {
    const { validations } = this.state;
    const validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations });
  };

  renderShowTimeInfo = () => {
    const { corporateBooking, performance, emailSubject, isSubmit } =
      this.state;
    const locationVenue = performance?.locationVenue;
    return (
      <Card title="Showtime Information">
        <div className="text-left showtime-info">
          <div className="row">
            <ColumnFormField label="Event Date &amp; Time:">
              <input
                className="form-control form-readonly"
                value={DateHelper.toDisplayDateTimeFormat(
                  performance?.actualDateTime
                )}
                readOnly
              />
            </ColumnFormField>
            <ColumnFormField
              label={
                <span>
                  Email Subject:
                  <span className="color-red font-weight-bold">*</span>
                </span>
              }
              htmlFor="Subject"
            >
              <input
                className={this.getControlClassName("emailSubject")}
                name="emailSubject"
                type="text"
                value={emailSubject}
                placeholder="Enter Email Subject"
                onChange={(e) =>
                  this.setState({ emailSubject: e.target.value })
                }
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="emailSubject"
                isEnabled={isSubmit}
                property="Email Subject"
                value={emailSubject}
              />
            </ColumnFormField>
          </div>
          <div className="row">
            <ColumnFormField
              label="Hall &amp; Location:"
              htmlFor="HallLocation"
            >
              <input
                className="form-control form-readonly"
                value={`${locationVenue?.name} - ${locationVenue?.location?.name}`}
                readOnly
              />
            </ColumnFormField>
            <div className="col-md-6">
              <ButtonActionDetailGroup>
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  data-toggle="modal"
                  onClick={() => this.setState({ showEmailPreview: true })}
                  data-target="#emailPreview"
                >
                  Preview Email
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            </div>
          </div>
          <div className="row">
            <ColumnFormField label="No of Guest:">
              <label className="form-control-label d-block" id="Guest">
                {corporateBooking?.guestCount}
              </label>
            </ColumnFormField>
          </div>
        </div>
      </Card>
    );
  };

  renderEmailRecipients = () => {
    const {
      draftRecipient,
      recipients,
      availableSeats,
      emailSubject,
      uploadedFileName,
    } = this.state;

    return (
      <Card title="Email Recipients">
        <div className="container-fluid overflow-x">
          <ButtonActionDetailGroup>
            <input
              ref={this.fileUploadInput}
              type="file"
              className="d-none"
              onChange={this.onUploadExcelFile}
            />
            {_.isEmpty(uploadedFileName) ? (
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                onClick={() => this.fileUploadInput.current.click()}
              >
                <i className="fas fa-upload" aria-hidden="true" /> Upload
              </ButtonActionDetailGroup.Item>
            ) : (
              <ButtonActionDetailGroup.Item
                className="btn btn-primary"
                onClick={() => {
                  this.setState({ uploadedFileName: "" });
                  this.fileUploadInput.current.value = "";
                }}
              >
                <i className="far fa-trash-alt" /> {uploadedFileName}
              </ButtonActionDetailGroup.Item>
            )}

            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={this.downloadTemplate}
            >
              <i className="fas fa-download" aria-hidden="true" />
              Download Excel Template
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-success"
              onClick={this.onValidateEmail}
            >
              Validate
            </ButtonActionDetailGroup.Item>
            <ButtonActionDetailGroup.Item
              className="btn btn-success"
              onClick={() =>
                this.setState({ showEmailSendingConfirmation: true })
              }
              title={
                _.isEmpty(emailSubject) ? "Email subject is required." : ""
              }
              disabled={!this.state.isValidSendMail}
            >
              Send Email
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
          <div className="row mb-2 pl-0 min-width-768">
            <div className="col-3">
              <h5>Patron Name</h5>
            </div>
            <div className="col-3">
              <h5>Email Address</h5>
            </div>
            <div className="col-2">
              <h5>Phone Number</h5>
            </div>
            <div className="col-3">
              <h5>Seat Codes</h5>
            </div>
          </div>
          {recipients &&
            recipients.map((x, index) => {
              return (
                <div className="row form-group min-width-768" key={index}>
                  <div className="col-3">{x.patronName}</div>
                  <div className="col-3">{x.emailAddress}</div>
                  <div className="col-2">{x.phoneNumber}</div>
                  <div className="col-3">
                    {x.seatCodes.map((x) => x.name).join(", ")}
                  </div>
                  <div className="col-1">
                    <button
                      className="btn btn-danger"
                      onClick={() => this.onRemoveRecipient(x)}
                    >
                      <i className="far fa-trash-alt" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              );
            })}
          <div className="row form-group min-width-768">
            <div className="col-3">
              <input
                className="form-control"
                type="text"
                name="patronName"
                value={draftRecipient.patronName}
                placeholder="Enter patron"
                onChange={this.onRecipientChange}
              />
            </div>
            <div className="col-3">
              <input
                className="form-control"
                type="email"
                name="emailAddress"
                value={draftRecipient.emailAddress}
                placeholder="Enter Email"
                onChange={this.onRecipientChange}
              />
            </div>
            <div className="col-2">
              <input
                className="form-control"
                type="tel"
                name="phoneNumber"
                value={draftRecipient.phoneNumber}
                placeholder="Enter Phone Number"
                onChange={this.onRecipientChange}
              />
            </div>
            <div className="col-3">
              <CustomSelect
                data={availableSeats}
                label="name"
                value="id"
                onChange={this.onMultipleSelectorSelect}
                selectedValue={draftRecipient.seatCodes.map(({ id }) => id)}
                isMulti={true}
                closeMenuOnSelect={false}
                blurInputOnSelect={false}
              />
            </div>
            <div className="col-1">
              <button
                className="btn btn-success"
                disabled={!draftRecipient.isValid()}
                onClick={this.onAddRecipient}
              >
                <i className="fas fa-plus-circle" aria-hidden="true" /> Add
              </button>
            </div>
          </div>
        </div>
      </Card>
    );
  };

  renderTicketDistributions = () => {
    const { tickets } = this.state;
    return (
      <Card title="Ticket Distributions">
        <div className="container-fluid overflow-x">
          <div className="row mb-2 min-width-1024">
            <div className="col-1">
              <h5>Mode of Ticket</h5>
            </div>
            <div className="col-2">
              <h5>Patron Name</h5>
            </div>
            <div className="col-2">
              <h5>Email Address</h5>
            </div>
            <div className="col-1">
              <h5>Phone Number</h5>
            </div>
            <div className="col-2">
              <h5>Seat Codes</h5>
            </div>
            <div className="col-1">
              <h5>Sent/Print Count</h5>
            </div>
            <div className="col-2">
              <h5>Sent/Printed On</h5>
            </div>
            <div className="col-1" />
          </div>
          {tickets.map((x, index) => (
            <div className="row form-group min-width-1024" key={index}>
              <div className="col-1">
                <span>
                  {TicketDistributionType.getShortName(x.ticketDistribution)}
                </span>
              </div>
              <div className="col-2">
                <span>{x.patronName}</span>
              </div>
              <div className="col-2">
                <span>{x.emailAddress}</span>
              </div>
              <div className="col-1">
                <span>{x.phoneNumber}</span>
              </div>
              <div className="col-2">
                <span>{x.seatCodes.join(", ")}</span>
              </div>
              <div className="col-1">
                <span>{x.count}</span>
              </div>
              <div className="col-2">
                <span>{DateHelper.toDisplayDateTimeFormat(x.processedOn)}</span>
              </div>
              <div className="col-1">
                {x.ticketDistribution === TicketDistributionType.ETickets && (
                  <button
                    className="btn btn-primary ml-3"
                    disabled={x.isSending}
                    onClick={() => this.onResend(x)}
                  >
                    Resend
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </Card>
    );
  };

  render() {
    const {
      showEmailPreview,
      emailSubject,
      performance,
      corporateBooking,
      resendWarningMessage,
      sendMailMessage,
      showEmailSendingConfirmation,
      showEmailResendConfirmation,
      showAddRecipientConfirmation,
      isValidSendMail,
      recipients,
    } = this.state;

    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {sendMailMessage && (
                <div
                  className={`alert ${
                    isValidSendMail ? "alert-success" : "alert-danger"
                  } alert-dismissible fade show`}
                  role="alert"
                >
                  {sendMailMessage}
                </div>
              )}
              {this.renderShowTimeInfo()}
              {this.renderEmailRecipients()}
              {this.renderTicketDistributions()}
            </div>
          </div>
        </div>
        <EmailPreview
          visible={showEmailPreview}
          subject={emailSubject}
          corporateBooking={corporateBooking}
          performance={performance}
          onClose={() => this.setState({ showEmailPreview: false })}
        />
        <ConfirmDialog
          message={`You are about to send an email to these ${recipients.length} of patrons.`}
          title="Email Sending Confirmation"
          visible={showEmailSendingConfirmation}
          onProceed={this.onSendEmail}
          onCancel={() =>
            this.setState({ showEmailSendingConfirmation: false })
          }
        />
        <ConfirmDialog
          message="Are you sure you want to send an email to those recipients?"
          title="Email Resend Confirmation"
          visible={showEmailResendConfirmation}
          onProceed={() => {
            this.onSendEmail();
            this.setState({ isValidSendMail: true });
          }}
          onCancel={() => this.setState({ showEmailResendConfirmation: false })}
        />
        <ConfirmDialog
          message={resendWarningMessage}
          title="Add Recipient Confirmation"
          visible={showAddRecipientConfirmation}
          onProceed={this.addRecipient}
          onCancel={() =>
            this.setState({ showAddRecipientConfirmation: false })
          }
        />
      </>
    );
  }
}
