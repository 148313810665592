import React, { useState } from "react";
import { cloneDeep, isEqual } from "lodash";
import { CampaignBundleCoupon } from "../../../../models/CampaignBundle";
import { NumberInput } from "../../NumberInput";
import { FieldWrapper } from "../../FieldWrapper";
import RestClient from "../../../../common/RestClient";
import { AuthenticationManager } from "../../../../common/AuthenticationManager";
import { campaignBundlesAtom } from "../../CampaignState";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import { TextInput } from "../../TextInput";
import { SingleSelector } from "../../SingleSelector";
import { CouponTitleType } from "../../../../constants/CampaignConstants";
import CreatableSelect from "react-select/creatable";

const exampleTitle = "Movie Title, EX: Avengers";

const defaultOptionLabelDisplay = [
  { label: "Coupon Slip", value: "Coupon Slip" },
  { label: "Redemption Slip", value: "Redemption Slip" },
];

export const NewCampaignBundleCouponModal = (props) => {
  const [instance, setInstance] = useState(new CampaignBundleCoupon({}));
  const { campaignBundleId, visible, onCancel } = props;

  const [campaignBundles, setCampaignBundles] =
    useRecoilState(campaignBundlesAtom);

  const handleError = (error) => {
    console.error(error);
    toast.error(error.message);
  };

  const onCreate = () => {
    RestClient.sendPostRequest(
      `/api/v1/campaign_bundle_coupons`,
      {
        ...instance,
        campaignBundleId,
        currentUser: AuthenticationManager.username(),
      },
      (response) => {
        const changeableCampaignBundles = cloneDeep(campaignBundles);
        const foundBundle = changeableCampaignBundles.find(
          (x) => x.id === campaignBundleId
        );
        foundBundle.campaignBundleCoupons.push(
          new CampaignBundleCoupon(response)
        );
        setInstance(new CampaignBundleCoupon({}));
        setCampaignBundles(changeableCampaignBundles);
        onCancel();
        toast.success(
          "The campaign bundle coupon has been created successful."
        );
      },
      handleError
    );
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    if (fieldName === "couponTitleType") {
      if (selectValue === CouponTitleType.MovieTitle) {
        instance.title = exampleTitle;
      } else if (instance.couponTitleType === CouponTitleType.MovieTitle) {
        instance.title = "";
      }
    }

    instance[fieldName] = selectValue;
    setInstance(cloneDeep(instance));
  };

  const onLabelDisplayChange = (label) => {
    setInstance((prev) => ({
      ...prev,
      labelDisplay: label,
    }));
  };

  if (!visible) {
    return null;
  }

  return (
    <>
      <div
        className="modal fade show"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ paddingRight: "17px", display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Coupon</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={onCancel}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body pb-5">
              <div className="container-fluid">
                <FieldWrapper
                  label="Title"
                  require={
                    instance.couponTitleType !== CouponTitleType.MovieTitle
                  }
                  additionalCss="form-group"
                  input={
                    <TextInput
                      name="title"
                      value={instance.title}
                      onChange={onInputChange}
                      readOnly={
                        instance.couponTitleType === CouponTitleType.MovieTitle
                      }
                    />
                  }
                />
                <FieldWrapper
                  label="Example Title"
                  additionalCss="form-group"
                  input={CouponTitleType.generateTitleExample(instance)}
                />
                <FieldWrapper
                  label="Title Type"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <SingleSelector
                      type="number"
                      options={CouponTitleType.All}
                      name="couponTitleType"
                      value={instance.couponTitleType}
                      onChange={onInputChange}
                      enableDefaultEntry={false}
                    />
                  }
                />
                <FieldWrapper
                  label="Label Display"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <CreatableSelect
                      options={defaultOptionLabelDisplay}
                      value={{
                        label: instance.labelDisplay,
                        value: instance.labelDisplay,
                      }}
                      onChange={({ value }) => onLabelDisplayChange(value)}
                      onCreateOption={onLabelDisplayChange}
                    />
                  }
                />
                <FieldWrapper
                  label="Description"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <TextInput
                      name="description"
                      value={instance.description}
                      onChange={onInputChange}
                    />
                  }
                />
                <FieldWrapper
                  label="Term & Condition"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <TextInput
                      name="termCondition"
                      value={instance.termCondition}
                      onChange={onInputChange}
                      placeholder="Enter Terms & Conditions"
                    />
                  }
                />
                <FieldWrapper
                  label="Quantity"
                  require={true}
                  additionalCss="form-group"
                  input={
                    <NumberInput
                      name="quantity"
                      value={instance.quantity}
                      onChange={onInputChange}
                      min={1}
                    />
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn btn-success"
                onClick={onCreate}
              >
                Create
              </button>
              <button
                type="button"
                className="btn btn btn-danger"
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </>
  );
};
