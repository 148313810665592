import React from "react";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import {
  ApplicationID,
  YesNoOptions,
  LocationBrandsEnum,
} from "../../../../common/Constants";
import { LocationBrand } from "../../../../constants/MovieConstants";
import { Days } from "../../../../constants/PricingConstants";
import { CompanyType } from "../../../../constants/CompanyType";
import { MovieReleaseService } from "../../../../services/MovieReleaseService";
import _ from "lodash";
import { Link } from "react-router-dom";

export default class ProgramMechanicsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      programMechanics: null,
      movies: [],
    };
  }

  componentDidMount() {
    const { evoucherProgramId } = this.props;
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_programs/${evoucherProgramId}/mechanics`,
      {
        includes: `EVoucherProgramSalesAppValidities,
         EVoucherProgramRedeemBrandValidities,
         EVoucherProgramRedeemAppValidities,
         EVoucherProgramRedeemMovieValidities,
         EVoucherProgramRedeemPerformanceValidities,
         EVoucherProgramSalesLocationValidities,
         EVoucherProgramRedeemLocationValidities,
         EVoucherProgramRedeemDistributorValidities,
         EVoucherProgramRedeemDaysValidities`,
      },
      (response) => {
          this.setState({ programMechanics: response });
        const movieReleaseIds =
          response.eVoucherProgramRedeemMovieValidities.map(
            (movie) => movie.movieReleaseId
          );
        if (movieReleaseIds.length) {
          this.getMovies(movieReleaseIds);
        }
      }
    );
  }

  getMovies(movieReleaseIds) {
    new MovieReleaseService()
      .list({ movieReleaseIds: movieReleaseIds.join(","), includes: "Movie" })
      .then(({ data: res }) => {
        const movies = _.uniqBy(
          res.data.map((x) => x.movie),
          (x) => x.id
        );
        this.setState({ movies });
      });
  }

  renderViewModeFormControl(label, value) {
    return (
      <div className="col-md-6">
        <label className="col-12 form-control-label">{label}:</label>
        <div className="col-12 color-black">
          <span>{value}</span>
        </div>
      </div>
    );
  }

  renderViewModeFieldsetControl(label, value) {
    return (
      <div className="col-md-6">
        <fieldset className="card card-body pt-0 mb-0">
          <legend className="col-auto h6">{label}</legend>
          <ul id="LocationOrApp" className="ml-md-2 color-black pl-3">
            {value}
          </ul>
        </fieldset>
      </div>
    );
  }

  renderBooleanLabel(value) {
    return value
      ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
      : YesNoOptions.getYesNoOptions(YesNoOptions.No);
  }

  renderViewMode() {
    const { programMechanics, movies } = this.state;
    return (
      <>
        <div className="card mt-3">
          <div className="card-body">
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Batch Quantity",
                programMechanics.batchQuantity
              )}
              {this.renderViewModeFormControl(
                "Sales Amount",
                programMechanics.salesAmount?.toFixed(6)
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Validity Duration",
                programMechanics.validityDuration
              )}
              {this.renderViewModeFormControl(
                "Ticket Docket Amount",
                programMechanics.ticketDocketAmount?.toFixed(6)
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Max Redemption Quantity",
                programMechanics.maxRedemptionQuantity
              )}
              {this.renderViewModeFormControl(
                "F&B Docket Amount",
                programMechanics.fnBDocketAmount?.toFixed(6)
              )}
            </div>
          </div>
        </div>
        <div className="card mt-3" id="hiddenCard">
          <div className="card-body">
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Is valid for F&B?",
                this.renderBooleanLabel(programMechanics.isValidForFnB)
              )}
              {this.renderViewModeFormControl(
                "Is valid for ticket?",
                this.renderBooleanLabel(programMechanics.isValidForTicket)
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Is valid for public holiday?",
                this.renderBooleanLabel(
                  programMechanics.isValidForPublicHoliday
                )
              )}
              {this.renderViewModeFormControl(
                "Is valid for public holiday eve?",
                this.renderBooleanLabel(
                  programMechanics.isValidForPublicHolidayEve
                )
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFormControl(
                "Is valid for blackout day?",
                this.renderBooleanLabel(programMechanics.isValidForBlackoutDay)
              )}
              {this.renderViewModeFormControl(
                "Is valid for opening show?",
                this.renderBooleanLabel(programMechanics.isValidForOpeningShow)
              )}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body bg-light">
            <div className="row form-group">
              {this.renderViewModeFieldsetControl(
                "Is valid for sales in all apps?",
                programMechanics.isValidForSalesInAllApps
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramSalesAppValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.appId}>
                          {ApplicationID.getName(app.appId)}
                        </li>
                      )
                    )
              )}
              {this.renderViewModeFieldsetControl(
                "Is valid for sales in all locations?",
                programMechanics.isValidForSalesInAllLocations
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramSalesLocationValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.locationId}>
                          {LocationBrand.getName(app.locationId)}
                        </li>
                      )
                    )
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all apps?",
                programMechanics.isValidForRedeemInAllApps
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemAppValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.appId}>
                          {ApplicationID.getName(app.appId)}
                        </li>
                      )
                    )
              )}
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all locations?",
                programMechanics.isValidForRedeemInAllLocations
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemLocationValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.locationId}>
                          {LocationBrand.getName(app.locationId)}
                        </li>
                      )
                    )
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all brands?",
                programMechanics.isValidForRedeemInAllBrands
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemBrandValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.appId}>
                          {LocationBrandsEnum.getLocationBrand(app.appId)}
                        </li>
                      )
                    )
              )}
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all distributor?",
                programMechanics.isValidForRedeemInAllDistributors
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemDistributorValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.companyId}>
                          {CompanyType.getName(app.companyId)}
                        </li>
                      )
                    )
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all movies?",
                programMechanics.isValidForRedeemInAllMovies
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : movies.map((movie) => (
                      <li key={movie.id}>
                        <Link to={`/movies/${movie.id}`}>
                          {movie.primaryTitle}{" "}
                          {movie.secondaryTitle
                            ? `(${movie.secondaryTitle})`
                            : ""}
                        </Link>
                      </li>
                    ))
              )}
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all days?",
                programMechanics.isValidForRedeemInAllDays
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemDaysValidityResponse?.map(
                      (app, index) => (
                        <li key={index} value={app.day}>
                          {Days.getName(app.day)}
                        </li>
                      )
                    )
              )}
            </div>
            <div className="row form-group">
              {this.renderViewModeFieldsetControl(
                "Is valid for redeem in all showtime?",
                programMechanics.isValidForRedeemInAllPerformances
                  ? YesNoOptions.getYesNoOptions(YesNoOptions.Yes)
                  : programMechanics.eVoucherProgramRedeemPerformanceValidities?.map(
                      (app, index) => (
                        <li key={index} value={app.performanceId}>
                          {Days.getName(app.performanceId)}
                        </li>
                      )
                    )
              )}
              {this.renderViewModeFieldsetControl(
                "Is valid for special movies?",
                this.renderBooleanLabel(programMechanics.isValidForSpecialMovie)
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { programMechanics } = this.state;
    return (
      programMechanics && (
        <Card title="Program Mechanics">{this.renderViewMode()}</Card>
      )
    );
  }
}
