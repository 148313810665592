import { useEffect, useState } from "react";
import { LayoutRestService } from "../../services/LayoutRestService";
import { SeatStatus } from "../../common/SeatingLayout";

export function useLayoutLiveLives(performanceId) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!performanceId || performanceId <= 0) {
      return;
    }
    new LayoutRestService().get("", { performanceId }).then((response) => {
      setData(response.data.data);
    });
  }, [performanceId]);

  const isSeatAvailable = (layoutElement) => {
    const isNormalSeatAvailable =
      !layoutElement.isHouseSeat &&
      layoutElement.elementStatusCodeCurrent === SeatStatus.Available;

    const isHouseSeatAvailable =
      layoutElement.isHouseSeat &&
      [SeatStatus.Available, SeatStatus.Blocked].includes(
        layoutElement.elementStatusCodeCurrent
      );

    return isNormalSeatAvailable || isHouseSeatAvailable;
  };

  const remainingCapacity = data.filter(isSeatAvailable).length;
  return { data, remainingCapacity };
}
